import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import "./PublicPage.css";
import "react-toastify/dist/ReactToastify.css";
import stripHtml from "string-strip-html";
import urlParser from "js-video-url-parser";
import { withRouter } from "react-router-dom";
import CountryData from "./CountryData.json";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { setAlert } from "../../actions/alert";
import { Helmet } from "react-helmet";

import "moment/locale/fr";
import "moment/locale/pt";
import "moment/locale/it";
import "moment/locale/id";
import "moment/locale/es";
import "moment/locale/hi";
import "moment/locale/de";
import "moment/locale/tr";
import "moment/locale/zh-cn";

import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBInput,
  MDBBtn,
  MDBSelect,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCardTitle,
  MDBScrollbar,
  MDBJumbotron
} from "mdbreact";

import {
  getPublicPageURL,
  createMessage,
  reportUser,
  setLoadingButtonMessage,
  setLoadingButtonAppointment,
  setLoadingButtonReport
} from "../../actions/publicpage";
const currentTimezone = moment.tz.guess() || "America/New_York";

const PublicPageURL = ({
  setAlert,
  switchMode,
  history,
  match,
  getPublicPageURL,
  createMessage,
  reportUser,
  setLoadingButtonMessage,
  setLoadingButtonAppointment,
  setLoadingButtonReport,
  publicpage: {
    publicPageURL,
    newMessage,
    report,
    loading,
    loadingButtonMessage,
    loadingButtonAppointment,
    loadingButtonReport
  },
  location
}) => {
  const [formData, setFormData] = useState({
    countryOptions: CountryData,
    currencyOptions: [
      {
        text: "€",
        value: "EUR"
      },
      {
        text: "$",
        value: "USD"
      },
      {
        text: "Mex$",
        value: "MXN"
      },
      {
        text: "R$",
        value: "BRL"
      },
      {
        text: "Rp",
        value: "IDR"
      },
      {
        text: "₺",
        value: "TRY"
      },
      {
        text: "₹",
        value: "INR"
      },
      {
        text: "CA$",
        value: "CAD"
      }
    ],

    message1:
      "Hello! I would like to learn more about your tutoring services. I have added you on Skype.",
    message2:
      "Hello! I wish to reseve an in-person tutoring session with you. What are your availabilities?",
    message3:
      "Hello! I have reserved a tutor session on your calendar. I look forward to learning with you.",
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: true,
    mode: "URL",
    modalSendMessage: false,
    newMessage: {
      name: "",
      email: "",
      message: "",
      date: "",
      seen: false
    },
    reportUser: {
      subject: "",
      body: ""
    },
    scrollToDate: new Date().setHours(8, 0, 0),
    selectedSlots: [],
    modalRequestClass: false,
    modalReportUser: false,
    timezones: [],
    selectedTimezone: currentTimezone,
    locale: "en",
    checkedTerms: false,
    checkedTermsAppointment: false,
    modalVideoTutorial: false
  });

  const localizer = momentLocalizer(moment);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getPublicPageURL({ uniqueURL: match.params.id });
  }, [getPublicPageURL, match.params.id]);

  useEffect(() => {
    if (i18n.language === "fr") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "fr"
        };
      });
    } else if (i18n.language === "pt") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "pt"
        };
      });
    } else if (i18n.language === "it") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "it"
        };
      });
    } else if (i18n.language === "id") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "id"
        };
      });
    } else if (i18n.language === "es") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "es"
        };
      });
    } else if (i18n.language === "hi") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "hi"
        };
      });
    } else if (i18n.language === "de") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "de"
        };
      });
    } else if (i18n.language === "tr") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "tr"
        };
      });
    } else if (i18n.language === "zh_CN") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "zh-cn"
        };
      });
    } else {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "en"
        };
      });
    }
  }, [i18n.language]);

  const findCountry = () => {
    const find = formData.countryOptions.find(
      country => country.value === publicPageURL.country
    );
    if (find) {
      return find.text;
    }
  };

  const findCurrency = () => {
    const find = formData.currencyOptions.find(
      currency => currency.value === publicPageURL.currency
    );
    if (find) {
      return find.text;
    }
  };

  const facebookURLReplace = () => {
    const hrefgood = publicPageURL.facebook.replace(/(^\w+:|^)\/\//, "");
    if (hrefgood) {
      return hrefgood;
    }
  };
  const instagramURLReplace = () => {
    const hrefgood = publicPageURL.instagram.replace(/(^\w+:|^)\/\//, "");
    if (hrefgood) {
      return hrefgood;
    }
  };
  const twitterURLReplace = () => {
    const hrefgood = publicPageURL.twitter.replace(/(^\w+:|^)\/\//, "");
    if (hrefgood) {
      return hrefgood;
    }
  };
  const linkedInURLReplace = () => {
    const hrefgood = publicPageURL.linkedIn.replace(/(^\w+:|^)\/\//, "");
    if (hrefgood) {
      return hrefgood;
    }
  };

  const embedYoutube = () => {
    const emb = urlParser.parse(publicPageURL.youtube);
    if (typeof emb !== "undefined") {
      const embUrl = urlParser.create({
        videoInfo: emb,
        format: "embed"
      });

      return (
        <MDBCol sm="12">
          <div className="embed-responsive embed-responsive-16by9 mb-1 publicPageYoutube">
            <iframe
              title="Embeds Page"
              className="embed-responsive-item"
              src={embUrl}
              allowFullScreen
            />
          </div>
        </MDBCol>
      );
    }
  };

  const onChange = e =>
    setFormData({
      ...formData,
      newMessage: {
        ...formData.newMessage,
        [e.target.name]: e.target.value
      }
    });

  const onChange2 = e =>
    setFormData({
      ...formData,
      reportUser: {
        ...formData.reportUser,
        [e.target.name]: e.target.value
      }
    });

  const onClick1 = () => {
    setFormData({
      ...formData,
      checked1: true,
      checked2: false,
      checked3: false,
      checked4: false,
      newMessage: {
        ...formData.newMessage,
        message: formData.message1
      }
    });
  };
  const onClick2 = () => {
    setFormData({
      ...formData,
      checked1: false,
      checked2: true,
      checked3: false,
      checked4: false,
      newMessage: {
        ...formData.newMessage,
        message: formData.message2
      }
    });
  };
  const onClick3 = () => {
    setFormData({
      ...formData,
      checked1: false,
      checked2: false,
      checked3: true,
      checked4: false,
      newMessage: {
        ...formData.newMessage,
        message: formData.message3
      }
    });
  };
  const onClick4 = () => {
    setFormData({
      ...formData,
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: true,
      newMessage: {
        ...formData.newMessage,
        message: ""
      }
    });
  };

  // Send Messages to the tutor

  const sendMessage = () => {
    if (formData.checkedTerms === false) {
      setAlert(t("terms.39"), "error");
    } else {
      setLoadingButtonMessage(true);
      const date = new Date();
      createMessage({
        ...formData.newMessage,
        uniqueURL: match.params.id,
        date: date,
        language: i18n.language
      });
    }
  };
  const sendMessageWithAppointment = () => {
    if (formData.checkedTermsAppointment === false) {
      setAlert(t("terms.39"), "error");
    } else {
      const date = new Date();

      const newArray = formData.selectedSlots.map((item, index) => {
        const start1 = moment(item.start, "x").format("YYYY MM DD hh:mm a");

        const start2 = moment
          .tz(start1, "YYYY MM DD hh:mm a", formData.selectedTimezone)
          .valueOf();
        const end1 = moment(item.end, "x").format("YYYY MM DD hh:mm a");

        const end2 = moment
          .tz(end1, "YYYY MM DD hh:mm a", formData.selectedTimezone)
          .valueOf();
        return {
          start: start2,
          end: end2,
          availabilityId: item._id
        };
      });
      setLoadingButtonAppointment(true);
      createMessage({
        ...formData.newMessage,
        uniqueURL: match.params.id,
        date: date,
        appointment: newArray,
        language: i18n.language
      });
    }
  };

  // Send report about user

  const sendReport = async () => {
    await setLoadingButtonReport(true);
    reportUser({
      ...formData.reportUser,
      language: i18n.language,
      uniqueURL: match.params.id
    });
  };

  // Close Report Modal Upon Backend response

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        modalReportUser: false,
        reportUser: {
          subject: "",
          body: ""
        }
      };
    });
  }, [report]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        modalSendMessage: false,
        modalRequestClass: false,
        selectedSlots: [],
        newMessage: {
          name: "",
          email: "",
          message: "",
          date: "",
          seen: false
        },
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: true
      };
    });
  }, [newMessage]);

  const formats = {
    // dateFormat: "dd",
    timeGutterFormat: "h a"
  };

  const date = new Date().getFullYear();

  const getDefaultDate = () => {
    const newDate = moment(new Date()).valueOf();
    const start1 = moment(newDate, "x")
      .tz(formData.selectedTimezone)
      .format("YYYY MM DD hh:mm a");

    const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();
    return start2;
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const find = formData.selectedSlots.find(item => item._id === event._id);

    if (find) {
      let newStyle = {
        backgroundColor: "#9c45eb",
        color: "white",
        borderRadius: "0px",
        fontFamily: "quickSand",
        borderColor: "black"
      };
      return {
        style: newStyle
      };
    } else if (event.title === "Available") {
      let newStyle = {
        backgroundColor: "#aed581 ",
        color: "black",
        borderRadius: "0px",
        fontFamily: "quickSand",
        borderColor: "black"
      };
      return {
        style: newStyle
      };
    } else if (event.title === "Pending") {
      let newStyle = {
        visibility: "hidden"
      };
      return {
        style: newStyle
      };
    }
  };

  const getEvents = () => {
    const newArray = publicPageURL.availability.map((item, index) => {
      const start1 = moment(item.start, "x")
        .tz(formData.selectedTimezone)
        .format("YYYY MM DD hh:mm a");
      const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();
      const end1 = moment(item.end, "x")
        .tz(formData.selectedTimezone)
        .format("YYYY MM DD hh:mm a");
      const end2 = moment(end1, "YYYY MM DD hh:mm a").toDate();

      return {
        ...item,
        start: start2,
        end: end2
      };
    });
    return newArray;
  };

  const handleEvent = event => {
    const find = formData.selectedSlots.find(item => item._id === event._id);

    if (find) {
      const filter = formData.selectedSlots.filter(
        item => item._id !== event._id
      );
      setFormData({
        ...formData,
        selectedSlots: filter
      });
    } else {
      setFormData({
        ...formData,
        selectedSlots: [event]
      });
    }
  };

  const selectTimezone = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        selectedTimezone: value[0]
      });
    }
  };
  const onChangeCheckTerms = () => {
    setFormData({
      ...formData,
      checkedTerms: !formData.checkedTerms
    });
  };
  const onChangeCheckTermsAppointment = () => {
    setFormData({
      ...formData,
      checkedTermsAppointment: !formData.checkedTermsAppointment
    });
  };

  useEffect(() => {
    if (
      formData.modalSendMessage === true ||
      formData.modalRequestClass === true
    ) {
      document.body.style.cssText = "overflow-y: hidden !important;";
    } else {
      document.body.style.overflowY = "";
    }
  }, [formData.modalSendMessage, formData.modalRequestClass]);

  useEffect(() => {
    const timezones = moment.tz.names().map((label, index) => {
      if (label === formData.selectedTimezone) {
        return {
          text: label,
          index: index,
          value: label,
          checked: true
        };
      } else {
        return {
          text: label,
          index: index,
          value: label,
          checked: false
        };
      }
    });
    setFormData(prevState => {
      return {
        ...prevState,
        timezones: timezones
      };
    });
  }, [formData.selectedTimezone]);

  const calendarLanguages = {
    allDay: t("calendar.10"),
    previous: t("calendar.9"),
    next: t("calendar.8"),
    today: t("calendar.7"),
    month: t("calendar.6"),
    week: t("calendar.5"),
    day: t("calendar.4"),
    agenda: t("calendar.3"),
    date: t("calendar.1"),
    time: t("calendar.2"),
    noEventsInRange: t("calendar.11")
  };

  const modalVideoTutorial = () => {
    setFormData({
      ...formData,
      modalVideoTutorial: !formData.modalVideoTutorial
    });
  };

  const findOnline = publicPageURL.methods.find(item => item === "Online");
  const findInPerson = publicPageURL.methods.find(item => item === "In-Person");

  const titleActivated =
    findOnline && findInPerson && i18n.language === "hi"
      ? `${publicPageURL.city} ${t("meta.18hi")} ${publicPageURL.name} ${t(
          "meta.19hi"
        )}`
      : findOnline && findInPerson
      ? `${t("meta.3")} ${publicPageURL.name} ${t("meta.11tr")} ${t(
          "meta.3half"
        )} ${t("meta.5")} ${publicPageURL.city}${t("meta.12tr")} ${t("meta.6")}`
      : findOnline && i18n.language === "hi"
      ? `${t("meta.16hi")} ${publicPageURL.name} ${t("meta.17hi")}`
      : findOnline
      ? `${t("meta.3")} ${publicPageURL.name} ${t("meta.3half")} ${t(
          "meta.4"
        )} ${t("meta.10tr")}`
      : findInPerson && i18n.language === "hi"
      ? `${publicPageURL.city} ${t("meta.14hi")} ${publicPageURL.name} ${t(
          "meta.15hi"
        )}`
      : findInPerson
      ? `${t("meta.3")} ${publicPageURL.name} ${t("meta.3half")} ${t(
          "meta.5"
        )} ${t("meta.8tr")} ${publicPageURL.city}${t("meta.9tr")}`
      : `${t("meta.3")} ${publicPageURL.name} ${t("meta.3half")} ${t(
          "meta.7tr"
        )} ${t("meta.13hi")}  `;

  const descriptionActivated = publicPageURL.description;

  // before return
  return loading ? (
    <Spinner></Spinner>
  ) : publicPageURL.activatePublicPage === false ? (
    <Fragment>
      <MDBContainer className="mt-5 text-center">
        <MDBRow>
          <MDBCol md="10" lg="10" xl="10" sm="12" className="mt-5 mx-auto">
            <MDBJumbotron className="mt-5">
              <MDBCardBody color="blue">
                <MDBRow center mt-5>
                  <p className="quickSand h3">{t("publicPageUrl.1")}</p>
                </MDBRow>
              </MDBCardBody>
            </MDBJumbotron>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  ) : publicPageURL.activatePublicPage === "none" ? (
    <Fragment>
      <MDBContainer className="mt-5 text-center">
        <MDBRow>
          <MDBCol md="10" lg="10" xl="10" sm="12" className="mt-5 mx-auto">
            <MDBJumbotron className="mt-5">
              <MDBCardBody color="blue">
                <MDBRow center mt-5>
                  <p className="quickSand h3">
                    <i className="fas fa-exclamation-triangle" />
                    {t("publicPageUrl.2")}
                  </p>
                </MDBRow>
              </MDBCardBody>
            </MDBJumbotron>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{titleActivated}</title>
        <meta name="description" content={descriptionActivated} />
        <meta property="og:title" content={titleActivated} />
        <meta property="og:description" content={descriptionActivated} />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link
          rel="canonical"
          href={`https://www.topscholar.io/tutors/${match.params.id}`}
        />
      </Helmet>
      <MDBCol size="12">
        <MDBRow className="mt-5">
          <MDBCol lg="4" md="12" className="col-12">
            <MDBCard
              className="URL-card text-center mb-4 socialPublicPageCard mt-5"
              style={{ height: "auto" }}
            >
              <div className="middleVertical mt-2">
                <img
                  style={{ width: "175px" }}
                  src={publicPageURL.avatar.url}
                  alt=""
                  className="rounded-circle z-depth-1-half "
                />
              </div>
              <MDBCardBody>
                <MDBCardTitle>
                  <p className="anticDidone h3 text-black middleVertical">
                    {publicPageURL.name}
                  </p>
                </MDBCardTitle>
                <p className="nanumText h5 text-black middleVertical">
                  {publicPageURL.city}, {findCountry()}
                </p>
                <p className="h5 nanumText dark-grey-text">
                  {publicPageURL.rate} {findCurrency()}
                  {t("publicPageUrl.3")}
                </p>
                <ul className="list-unstyled d-flex justify-content-center mb-0 pb-0 pt-2 list-inline">
                  <li
                    className={
                      publicPageURL.facebook !== ""
                        ? "list-inline-item"
                        : "d-none"
                    }
                  >
                    <a
                      href={`https://${facebookURLReplace()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MDBBtn size="medium" floating social="fb">
                        {" "}
                        <MDBIcon fab icon="facebook-f" />{" "}
                      </MDBBtn>
                    </a>
                  </li>
                  <li
                    className={
                      publicPageURL.twitter !== ""
                        ? "list-inline-item"
                        : "d-none"
                    }
                  >
                    <a
                      href={`https://${twitterURLReplace()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MDBBtn size="medium" floating social="tw">
                        {" "}
                        <MDBIcon fab icon="twitter" />{" "}
                      </MDBBtn>
                    </a>
                  </li>
                  <li
                    className={
                      publicPageURL.linkedIn !== ""
                        ? "list-inline-item"
                        : "d-none"
                    }
                  >
                    <a
                      href={`https://${linkedInURLReplace()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MDBBtn size="medium" floating social="li">
                        <MDBIcon fab icon="linkedin-in" />
                      </MDBBtn>
                    </a>
                  </li>
                  <li
                    className={
                      publicPageURL.instagram !== ""
                        ? "list-inline-item"
                        : "d-none"
                    }
                  >
                    <a
                      href={`https://${instagramURLReplace()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MDBBtn
                        size="medium"
                        floating
                        social="ins"
                        color="pink lighten-3"
                      >
                        <MDBIcon fab icon="instagram" />
                      </MDBBtn>
                    </a>
                  </li>
                </ul>
                <div className="ql-editor" id="fixMargin">
                  <p className="ptsansText mt-3 h6">
                    {stripHtml(publicPageURL.description)}
                  </p>
                </div>
                <MDBBtn
                  className="sendMessageButtonColor"
                  size="sm"
                  rounded
                  onClick={() => {
                    setFormData({
                      ...formData,
                      modalSendMessage: true,
                      checkedTerms: false
                    });
                  }}
                >
                  {t("publicPageUrl.4")}

                  <MDBIcon icon="paper-plane" className="ml-2 " />
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="1" md="12" className="col-12"></MDBCol>
          <MDBCol lg="6" md="12" className="col-12 mt-3 mr-5 marginOnMobileURL">
            <MDBRow className="middleVertical">
              <div>
                <p className="livvicFont h3 text-black middleVertical mt-4">
                  {t("publicPageUrl.5")}
                </p>
              </div>
            </MDBRow>
            <MDBRow center>
              <MDBCol size="6">
                <MDBSelect
                  search
                  className="quickSand black-text font-weight-bold   mt-3"
                  options={formData.timezones}
                  labelClass="black-text font-weight-bold quickSand"
                  label={t("publicPageUrl.6")}
                  outline
                  size="sm"
                  getValue={selectTimezone}
                />

                <p className="text-center quickSand mx-1">
                  <MDBIcon className="green-text" icon="square" />{" "}
                  {t("publicPageUrl.30")}
                </p>
              </MDBCol>
              <div
                // touchStart={changeCssScroll}
                // touchMove={changeCssScroll}
                // className="mobileCalendarScroll customScroll"
                className="customScrollColorCalendar"
                // id="test100"
                style={{ overflowX: "auto" }}
              >
                <div
                  style={{ minWidth: "30rem" }}
                  className="customScroll px-3"
                >
                  <Calendar
                    culture={formData.locale}
                    localizer={localizer}
                    // defaultDate={getDefaultDate()}
                    defaultView="week"
                    views={["week", "day", "month", "agenda"]}
                    events={getEvents()}
                    style={{
                      height: "25rem",
                      fontFamily: "Quicksand",
                      minWidth: "30rem"
                    }}
                    onSelectEvent={event => handleEvent(event)}
                    formats={formats}
                    getNow={getDefaultDate}
                    // showMultiDayTimes={false}

                    // scrollToTime={getStartDate()}
                    eventPropGetter={eventStyleGetter}
                    messages={calendarLanguages}
                  />
                </div>
              </div>
              <div className="w-100"></div>
              {formData.selectedSlots.length === 1 ? (
                <MDBBtn
                  onClick={() => {
                    setFormData({
                      ...formData,
                      modalRequestClass: true,
                      checkedTermsAppointment: false
                    });
                  }}
                  color="danger"
                >
                  {t("publicPageUrl.7")}
                </MDBBtn>
              ) : null}
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="4" md="12" className="col-12 marginOnMobileURL">
            <div className={publicPageURL.youtube !== "" ? "" : "d-none"}>
              <p className="livvicFont h3 text-black middleVertical mx-3 mb-4">
                {t("publicPageUrl.8")}
              </p>
            </div>
            <MDBRow>{embedYoutube()}</MDBRow>
          </MDBCol>
          <MDBCol lg="7" md="12" className="col-12">
            <MDBCard
              wide
              style={{ height: "auto" }}
              className="publicPageCard2 socialPublicPageCard summaryCardMarginTop"
            >
              <MDBCardHeader className="view view-cascade sendMessageButtonColor middleVertical">
                <p className="h5 quickSand mx-2 paragraphColor text-center white-text">
                  {t("publicPageUrl.9")}
                </p>
              </MDBCardHeader>
              <MDBCardBody cascade className="mt-1">
                <MDBRow
                  center
                  className={
                    (publicPageURL.years > 0 && publicPageURL.degree === "") ||
                    (publicPageURL.years === 0 && publicPageURL.degree !== "")
                      ? "mb-4 middleVertical"
                      : publicPageURL.years === 0 && publicPageURL.degree === ""
                      ? "d-none"
                      : publicPageURL.years > 0 && publicPageURL.degree !== ""
                      ? "mb-4"
                      : "mb-4"
                  }
                >
                  <MDBCol
                    sm="6"
                    className={publicPageURL.years > 0 ? "col-12" : "d-none"}
                  >
                    <MDBRow center>
                      <MDBIcon
                        icon="briefcase"
                        className="publicPageIconColor"
                        size="md"
                      />
                      <p className="nanumText h5 text-black middleVertical ml-2">
                        {t("publicPageUrl.10")}
                      </p>
                    </MDBRow>
                    <MDBRow center>
                      <MDBCol className="col"></MDBCol>
                      <MDBCol className="col-md-auto col-12">
                        <p className="crimsonText h4 text-black text-center">
                          {publicPageURL.years}
                          {publicPageURL.years > 1
                            ? t("publicPageUrl.11")
                            : t("publicPageUrl.11half")}
                        </p>
                      </MDBCol>
                      <MDBCol className="col"></MDBCol>
                    </MDBRow>
                  </MDBCol>

                  <MDBCol
                    sm="6"
                    className={
                      publicPageURL.degree !== "" ? "col-12" : "d-none"
                    }
                  >
                    <MDBRow center>
                      <MDBIcon
                        icon="graduation-cap"
                        className="publicPageIconColor"
                        size="md"
                      />
                      <p className="nanumText h5 text-black middleVertical ml-2">
                        {t("publicPageUrl.12")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol className="col"></MDBCol>
                      <MDBCol className="col-md-auto col-12">
                        <p className="crimsonText h4 text-black text-center">
                          {publicPageURL.degree}
                        </p>
                      </MDBCol>
                      <MDBCol className="col"></MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                <MDBRow
                  center
                  className={
                    (publicPageURL.certification !== "" &&
                      publicPageURL.lastExperience === "") ||
                    (publicPageURL.certification === "" &&
                      publicPageURL.lastExperience !== "")
                      ? "mb-4 middleVertical"
                      : publicPageURL.certification === "" &&
                        publicPageURL.lastExperience === ""
                      ? "d-none"
                      : publicPageURL.certification !== "" &&
                        publicPageURL.lastExperience !== ""
                      ? "mb-4"
                      : "mb-4"
                  }
                >
                  <MDBCol
                    sm="6"
                    className={
                      publicPageURL.certification !== "" ? "col-12" : "d-none"
                    }
                  >
                    <MDBRow center>
                      <MDBIcon
                        icon="scroll"
                        className="publicPageIconColor"
                        size="md"
                      />
                      <p className="nanumText h5 text-black middleVertical ml-2">
                        {t("publicPageUrl.13")}
                      </p>
                    </MDBRow>
                    <MDBRow center>
                      <MDBCol className="col"></MDBCol>
                      <MDBCol className="col-md-auto col-12">
                        <p className="crimsonText h4 text-black text-center">
                          {publicPageURL.certification}
                        </p>
                      </MDBCol>
                      <MDBCol className="col"></MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol
                    sm="6"
                    className={
                      publicPageURL.lastExperience !== "" ? "col-12" : "d-none"
                    }
                  >
                    <MDBRow center>
                      <MDBIcon
                        icon="school"
                        className="publicPageIconColor"
                        size="md"
                      />
                      <p className="nanumText h5 text-black middleVertical ml-2">
                        {t("publicPageUrl.14")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol className="col"></MDBCol>
                      <MDBCol className="col-md-auto col-12">
                        <p className="crimsonText h4 text-black text-center">
                          {publicPageURL.lastExperience}
                        </p>
                      </MDBCol>
                      <MDBCol className="col"></MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                <MDBRow
                  center
                  className={
                    (publicPageURL.levels.length !== 0 &&
                      publicPageURL.methods.length === 0) ||
                    (publicPageURL.levels.length === 0 &&
                      publicPageURL.methods.length !== 0)
                      ? "mb-4 middleVertical"
                      : publicPageURL.levels.length === 0 &&
                        publicPageURL.methods.length === 0
                      ? "d-none"
                      : publicPageURL.levels.length !== 0 &&
                        publicPageURL.methods.length !== 0
                      ? "mb-4"
                      : "mb-4"
                  }
                >
                  <MDBCol
                    sm="6"
                    className={
                      publicPageURL.levels.length !== 0 ? "col-12" : "d-none"
                    }
                  >
                    <MDBRow center>
                      <MDBIcon
                        icon="comments"
                        className="publicPageIconColor"
                        size="md"
                      />
                      <p className="nanumText h5 text-black middleVertical ml-2">
                        {t("publicPageUrl.15")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol className="col"></MDBCol>
                      <MDBCol className="col-md-auto col-12">
                        <ul className="crimsonText h4 text-black list-inline text-center levelsCommas">
                          {publicPageURL.levels.map((item, index) => {
                            return (
                              <li key={index} className="list-inline-item">
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </MDBCol>
                      <MDBCol className="col"></MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol
                    sm="6"
                    className={
                      publicPageURL.methods.length !== 0 ? "col-12" : "d-none"
                    }
                  >
                    <MDBRow center>
                      <MDBIcon
                        icon="globe"
                        className="publicPageIconColor"
                        size="md"
                      />
                      <p className="nanumText h5 text-black middleVertical ml-2">
                        {t("publicPageUrl.16")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol className="col"></MDBCol>
                      <MDBCol className="col-md-auto col-12">
                        <ul className="crimsonText h4 text-black list-inline text-center levelsCommas">
                          {publicPageURL.methods.map((item, index) => {
                            const translate =
                              item === "Online"
                                ? t("teachingMethods.1")
                                : t("teachingMethods.2");
                            return (
                              <li key={index} className="list-inline-item">
                                {translate}
                              </li>
                            );
                          })}
                        </ul>
                      </MDBCol>
                      <MDBCol className="col"></MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      {/* Send Message Modal */}

      <MDBContainer>
        <MDBModal
          isOpen={formData.modalSendMessage}
          toggle={() => {
            setFormData({
              ...formData,
              modalSendMessage: false
            });
          }}
          fullHeight
          position="top"
          // cascading
          size="lg"
        >
          <MDBModalHeader
            toggle={() => {
              setFormData({
                ...formData,
                modalSendMessage: false
              });
            }}
            className="messageBtnPublicPage quickSand white-text"
          >
            {t("publicPageUrl.17")}
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <MDBInput
                type="text"
                label={t("publicPageUrl.18")}
                name="name"
                className="quickSand text-black hintColor"
                value={formData.newMessage.name}
                onChange={e => onChange(e)}
                required
              />
              <MDBInput
                type="email"
                label={t("publicPageUrl.19")}
                name="email"
                className="quickSand text-black hintColor"
                value={formData.newMessage.email}
                onChange={e => onChange(e)}
                required
              />
              <MDBCol className="mb-1">
                <MDBInput
                  label={formData.message1}
                  type="radio"
                  id="radio1"
                  labelClass="text-black quickSand"
                  onClick={onClick1}
                  checked={formData.checked1}
                />
              </MDBCol>

              <MDBCol className="mb-1">
                <MDBInput
                  label={formData.message2}
                  type="radio"
                  id="radio2"
                  labelClass="text-black quickSand"
                  onClick={onClick2}
                  checked={formData.checked2}
                />
              </MDBCol>
              <MDBCol className="mb-1">
                <MDBInput
                  label={formData.message3}
                  type="radio"
                  id="radio3"
                  labelClass="text-black quickSand"
                  onClick={onClick3}
                  checked={formData.checked3}
                />
              </MDBCol>
              <MDBCol className="mb-1">
                <MDBInput
                  label={t("publicPageUrl.20")}
                  type="radio"
                  id="radio4"
                  labelClass="text-black quickSand"
                  onClick={onClick4}
                  checked={formData.checked4}
                />
              </MDBCol>
              <MDBInput
                type="textarea"
                label={t("publicPageUrl.21")}
                name="message"
                className="quickSand text-black hintColor"
                value={formData.newMessage.message}
                onChange={e => onChange(e)}
                required
                rows={10}
              />
            </div>
            <div className="mb-4" style={{ height: "25rem" }}>
              <MDBScrollbar>
                <p className="font-weight-bold h5 monserratFont mb-3 cookieMarginsMobile">
                  {t("findtutorsterms.1")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.2")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.3")}
                </p>
                <p className="quickSand cookieFont cookieMarginsMobile">
                  {t("findtutorsterms.4")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.5")}
                </p>
                <p className="quickSand cookieFont cookieMarginsMobile">
                  {t("findtutorsterms.6")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.7")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.8")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.9")}
                </p>
                <p className="quickSand cookieFont cookieMarginsMobile">
                  {t("findtutorsterms.10")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.11")}{" "}
                  <a href="https://stripe.com/docs/disputes/connect">
                    {" "}
                    stripe.com/docs/disputes/connect
                  </a>
                </p>
                <p className="quickSand cookieFont cookieMarginsMobile">
                  {t("findtutorsterms.12")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.13")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.14")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.15")}
                </p>
              </MDBScrollbar>
            </div>

            <MDBInput
              onChange={onChangeCheckTerms}
              type="checkbox"
              checked={formData.checkedTerms}
              id="10"
              required
              label={t("terms.39")}
              labelClass="font-weight-bold"
            ></MDBInput>
          </MDBModalBody>
          <MDBModalFooter center>
            <MDBBtn
              outline
              color="secondary"
              onClick={() => {
                setFormData({
                  ...formData,
                  modalSendMessage: false
                });
              }}
            >
              {t("publicPageUrl.22")}
            </MDBBtn>
            <MDBBtn
              className="messageBtnPublicPage"
              onClick={sendMessage}
              disabled={loadingButtonMessage}
            >
              {loadingButtonMessage === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              {t("publicPageUrl.23")}

              <MDBIcon icon="paper-plane" className="ml-1 " />
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>

      {/* Send Message Modal Appointment */}

      <MDBContainer>
        <MDBModal
          isOpen={formData.modalRequestClass}
          toggle={() =>
            setFormData({
              ...formData,
              modalRequestClass: false
            })
          }
          fullHeight
          position="top"
          // cascading
          size="lg"
        >
          <MDBModalHeader
            toggle={() => {
              setFormData({
                ...formData,
                modalRequestClass: false
              });
            }}
            className="messageBtnPublicPage QuickSand white-text"
          >
            {t("publicPageUrl.24")}
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <MDBInput
                type="text"
                label={t("publicPageUrl.25")}
                name="name"
                className="quickSand text-black hintColor"
                value={formData.newMessage.name}
                onChange={e => onChange(e)}
                required
              />
              <MDBInput
                type="email"
                label={t("publicPageUrl.26")}
                name="email"
                className="quickSand text-black hintColor"
                value={formData.newMessage.email}
                onChange={e => onChange(e)}
                required
              />

              <p className="text-center quickSand h5-responsive">
                {t("publicPageUrl.27")}
              </p>

              {formData.selectedSlots.map((item, index) => {
                const start1 = moment(item.start, "x").format(
                  "YYYY MM DD hh:mm a"
                );

                const start2 = moment
                  .tz(start1, "YYYY MM DD hh:mm a", formData.selectedTimezone)
                  .valueOf();
                const end1 = moment(item.end, "x").format("YYYY MM DD hh:mm a");

                const end2 = moment
                  .tz(end1, "YYYY MM DD hh:mm a", formData.selectedTimezone)
                  .valueOf();
                return (
                  <p className="quickSand">
                    {index + 1}:{" "}
                    {moment(start2, "x")
                      .tz(formData.selectedTimezone)
                      .format("llll")}{" "}
                    -{" "}
                    {moment(end2, "x")
                      .tz(formData.selectedTimezone)
                      .format("llll")}
                  </p>
                );
              })}

              <MDBCol className="mb-1">
                <MDBInput
                  label={formData.message3}
                  type="radio"
                  id="radio3"
                  labelClass="text-black quickSand"
                  onClick={onClick3}
                  checked={formData.checked3}
                />
              </MDBCol>
              <MDBCol className="mb-1">
                <MDBInput
                  label={t("publicPageUrl.28")}
                  type="radio"
                  id="radio4"
                  labelClass="text-black quickSand"
                  onClick={onClick4}
                  checked={formData.checked4}
                />
              </MDBCol>
              <MDBInput
                type="textarea"
                label={t("publicPageUrl.29")}
                name="message"
                className="quickSand text-black hintColor"
                value={formData.newMessage.message}
                onChange={e => onChange(e)}
                required
                rows={10}
              />
            </div>
            <div className="mb-4" style={{ height: "25rem" }}>
              <MDBScrollbar>
                <p className="font-weight-bold h5 monserratFont mb-3 cookieMarginsMobile">
                  {t("findtutorsterms.1")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.2")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.3")}
                </p>
                <p className="quickSand cookieFont cookieMarginsMobile">
                  {t("findtutorsterms.4")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.5")}
                </p>
                <p className="quickSand cookieFont cookieMarginsMobile">
                  {t("findtutorsterms.6")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.7")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.8")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.9")}
                </p>
                <p className="quickSand cookieFont cookieMarginsMobile">
                  {t("findtutorsterms.10")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.11")}{" "}
                  <a href="https://stripe.com/docs/disputes/connect">
                    {" "}
                    stripe.com/docs/disputes/connect
                  </a>
                </p>
                <p className="quickSand cookieFont cookieMarginsMobile">
                  {t("findtutorsterms.12")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.13")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.14")}
                </p>
                <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                  {t("findtutorsterms.15")}
                </p>
              </MDBScrollbar>
            </div>

            <MDBInput
              onChange={onChangeCheckTermsAppointment}
              type="checkbox"
              checked={formData.checkedTermsAppointment}
              id="11"
              required
              label={t("terms.39")}
              labelClass="font-weight-bold"
            ></MDBInput>
          </MDBModalBody>
          <MDBModalFooter center>
            <MDBBtn
              outline
              color="secondary"
              onClick={() => {
                setFormData({
                  ...formData,
                  modalRequestClass: false
                });
              }}
            >
              {t("publicPageUrl.22")}
            </MDBBtn>
            <MDBBtn
              disabled={loadingButtonAppointment}
              className="messageBtnPublicPage"
              onClick={sendMessageWithAppointment}
            >
              {loadingButtonAppointment === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              {t("publicPageUrl.23")}

              <MDBIcon icon="paper-plane" className="ml-1 " />
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>

      {/* Footer  */}

      <MDBCard className="cardFooter">
        <MDBCol sm="12">
          <MDBRow className="middleVertical">
            <MDBCol xl="1" lg="1" md="1" sm="4" className="col-12">
              <MDBRow className="middleVertical">
                <MDBIcon
                  style={{ cursor: "pointer" }}
                  className="color1"
                  onClick={modalVideoTutorial}
                  icon="info-circle"
                ></MDBIcon>
              </MDBRow>
            </MDBCol>
            <MDBCol xl="10" lg="9" md="8" sm="4" className="col-12">
              {" "}
              <p className="text-center publicPageFooter quickSand">
                &copy; {date} Copyright:{" "}
                <a
                  href="https://topscholar.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="publicPageFooterColor"
                >
                  Topscholar OÜ
                </a>
              </p>
            </MDBCol>
            <MDBCol xl="1" lg="2" md="3" sm="4" className="col-12">
              <p
                className="quickSand mt-3 text-center footerHover publicPageFooterColor2"
                onClick={() => {
                  setFormData({
                    ...formData,
                    modalReportUser: true
                  });
                }}
              >
                {t("report.1")}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBCard>

      {/* Report User Modal  */}

      <MDBContainer>
        <MDBModal
          isOpen={formData.modalReportUser}
          toggle={() => {
            setFormData({
              ...formData,
              modalReportUser: false
            });
          }}
          cascading
        >
          <MDBModalHeader
            toggle={() => {
              setFormData({
                ...formData,
                modalReportUser: false
              });
            }}
            className="messageBtnPublicPage white-text"
          >
            {t("report.1")}
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <MDBInput
                type="text"
                label={t("report.2")}
                name="subject"
                className="quickSand text-black hintColor"
                value={formData.reportUser.subject}
                onChange={e => onChange2(e)}
                required
              />
              <MDBInput
                type="textarea"
                label={t("report.3")}
                name="body"
                className="quickSand text-black hintColor"
                value={formData.reportUser.body}
                onChange={e => onChange2(e)}
                required
                rows={10}
              />
            </div>
          </MDBModalBody>
          <MDBModalFooter center>
            <MDBBtn
              outline
              color="secondary"
              onClick={() => {
                setFormData({
                  ...formData,
                  modalReportUser: false
                });
              }}
            >
              {t("publicPageUrl.22")}
            </MDBBtn>
            <MDBBtn
              className="messageBtnPublicPage"
              onClick={sendReport}
              disabled={loadingButtonReport}
            >
              {loadingButtonReport === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              {t("publicPageUrl.23")}

              <MDBIcon icon="paper-plane" className="ml-1 " />
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          className="modal-notify text-white"
          isOpen={formData.modalVideoTutorial}
          toggle={modalVideoTutorial}
          size="fluid"
        >
          <MDBModalHeader
            className="text-center montserratFont h4 documentationModal"
            titleClass="w-100"
            tag="p"
          >
            How to use this page
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <MDBRow>
              <MDBCol className="col-6">
                <MDBCard style={{ height: "28rem" }}>
                  <p className="text-center krubFont footerFont">
                    Find Tutors Profile Overview
                  </p>
                  <div className="embed-responsive embed-responsive-1by1 py-3">
                    <iframe
                      style={{ height: "25rem" }}
                      title="Find Tutors Profile Overview"
                      className="embed-responsive-item"
                      src="https://www.youtube.com/embed/4t7axX2_1ac?rel=0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol className="col-6">
                <MDBCard style={{ height: "28rem" }}>
                  <p className="text-center krubFont footerFont">
                    Report a Tutor
                  </p>
                  <div className="embed-responsive embed-responsive-1by1 py-3">
                    <iframe
                      style={{ height: "25rem" }}
                      title="Report a tutor"
                      className="embed-responsive-item"
                      src="https://www.youtube.com/embed/hvM8gSwC-zc?rel=0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter className="justify-content-center">
            <MDBBtn className="documentationModal" onClick={modalVideoTutorial}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

PublicPageURL.propTypes = {};

const mapStateToProps = state => ({
  publicpage: state.publicpage
});

export default withRouter(
  connect(mapStateToProps, {
    getPublicPageURL,
    createMessage,
    reportUser,
    setLoadingButtonMessage,
    setLoadingButtonAppointment,
    setLoadingButtonReport,
    setAlert
  })(PublicPageURL)
);
