import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./customizables.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
  MDBScrollbar
} from "mdbreact";

import {
  updateEval,
  getSpecificExamEvaluations,
  deleteCusExamEval,
  clearCustomizablesReducer,
  setLoadingButtonCustomizablesEval
} from "../../actions/customizables";

const CorrectedExams = ({
  customizables: {
    examEvalList,
    cusExamEvalUpdate,
    cusExamEvalDeleted,
    loadingButtonCustomizablesEval
  },
  getSpecificExamEvaluations,
  clearCustomizablesReducer,
  updateEval,
  currentExam,
  closeModalFunc,
  deleteCusExamEval,
  setLoadingButtonCustomizablesEval
}) => {
  const [formData, setFormData] = useState({
    examEvalList: [],
    studentList: [],
    showCorrection: false,
    currentCorrectExam: {
      grade: "",
      components: [],
      studentId: {
        name: ""
      },
      answerValue: {
        multipleChoice: 5,
        checkBox: 5,
        matching: 6,
        longAnswer: 5,
        totalNumerator: [],
        totalDenominator: []
      }
    },
    listScore: [],
    matchingNumerator: [],
    matchingDenominator: "",
    modalDeleteExam: false
  });

  // useEffect(() => {
  //   if (cusExamEvalUpdate) {
  //     const newArray = formData.examEvalList.map((item, index) => {
  //       if (item._id === cusExamEvalUpdate._id) {
  //         return cusExamEvalUpdate;
  //       } else {
  //         return item;
  //       }
  //     });

  //     setFormData({
  //       ...formData,
  //       examEvalList: newArray
  //     });
  //   }
  // }, [cusExamEvalUpdate]);

  useEffect(() => {
    const component = formData.currentCorrectExam.components.map(
      (item, index) => {
        if (item.type === "multipleChoice") {
          if (item.studentAnswer === item.answer) {
            return {
              _id: item._id,
              type: "multipleChoice",
              numeratorValue:
                formData.currentCorrectExam.answerValue.multipleChoice,
              denominatorValue:
                formData.currentCorrectExam.answerValue.multipleChoice
            };
          } else {
            return {
              _id: item._id,
              type: "multipleChoice",
              numeratorValue: 0,
              denominatorValue:
                formData.currentCorrectExam.answerValue.multipleChoice
            };
          }
        } else if (item.type === "checkAllBoxes") {
          const newArray = item.studentAnswer.map((itemb, index) => {
            const filter = item.answer.filter(item2 => itemb === item2);

            if (filter.length !== 0) {
              return "right";
            } else {
              return "wrong";
            }
          });

          const find = newArray.find(item => item === "wrong");

          if (item.studentAnswer.length !== item.answer.length) {
            return {
              _id: item._id,
              type: "checkBox",
              numeratorValue: 0,
              denominatorValue: formData.currentCorrectExam.answerValue.checkBox
            };
          } else if (find === "wrong") {
            return {
              _id: item._id,
              type: "checkBox",
              numeratorValue: 0,
              denominatorValue: formData.currentCorrectExam.answerValue.checkBox
            };
          } else {
            return {
              _id: item._id,
              type: "checkBox",
              numeratorValue: formData.currentCorrectExam.answerValue.checkBox,
              denominatorValue: formData.currentCorrectExam.answerValue.checkBox
            };
          }
        } else if (item.type === "matching") {
          let matchArray = [];
          if (item.studentAnswer.matching1.length < 2) {
            matchArray.push(0);
          } else if (
            item.match1.match1b === item.studentAnswer.matching1[1].value
          ) {
            matchArray.push(1);
          } else {
            matchArray.push(0);
          }

          if (item.studentAnswer.matching2.length < 2) {
            matchArray.push(0);
          } else if (
            item.match2.match2b === item.studentAnswer.matching2[1].value
          ) {
            matchArray.push(1);
          } else {
            matchArray.push(0);
          }

          if (item.studentAnswer.matching3.length < 2) {
            matchArray.push(0);
          } else if (
            item.match3.match3b === item.studentAnswer.matching3[1].value
          ) {
            matchArray.push(1);
          } else {
            matchArray.push(0);
          }

          if (item.studentAnswer.matching4.length < 2) {
            matchArray.push(0);
          } else if (
            item.match4.match4b === item.studentAnswer.matching4[1].value
          ) {
            matchArray.push(1);
            // setFormData({
            //   ...formData,
            //   matchingNumerator:[...formData.matchingNumerator, 1]
            // })
          } else {
            matchArray.push(0);
            // setFormData({
            //   ...formData,
            //   matchingNumerator:[...formData.matchingNumerator, 0]
            // })
          }

          if (item.studentAnswer.matching5.length < 2) {
            matchArray.push(0);
          } else if (
            item.match5.match5b === item.studentAnswer.matching5[1].value
          ) {
            matchArray.push(1);
            // setFormData({
            //   ...formData,
            //   matchingNumerator:[...formData.matchingNumerator, 1]
            // })
          } else {
            matchArray.push(0);
            // setFormData({
            //   ...formData,
            //   matchingNumerator:[...formData.matchingNumerator, 0]
            // })
          }

          if (item.studentAnswer.matching6.length < 2) {
            matchArray.push(0);
          } else if (
            item.match6.match6b === item.studentAnswer.matching6[1].value
          ) {
            matchArray.push(1);
            // setFormData({
            //   ...formData,
            //   matchingNumerator:[...formData.matchingNumerator, 1]
            // })
          } else {
            matchArray.push(0);
            // setFormData({
            //   ...formData,
            //   matchingNumerator:[...formData.matchingNumerator, 0]
            // })
          }

          matchArray = matchArray.reduce((a, b) => a + b, 0);
          matchArray =
            (matchArray / 6) * formData.currentCorrectExam.answerValue.matching;
          return {
            _id: item._id,
            type: "matching",
            numeratorValue: matchArray,
            denominatorValue: formData.currentCorrectExam.answerValue.matching
          };
        } else if (item.type === "textArea") {
          return {
            _id: item._id,
            type: "textArea",
            numeratorValue: item.grade ? item.grade : 0,
            denominatorValue:
              item.grade !== ""
                ? formData.currentCorrectExam.answerValue.longAnswer
                : 0
          };
        } else {
          return null;
        }
      }
    );
    setFormData(prevState => {
      return {
        ...prevState,
        listScore: component
      };
    });
  }, [formData.currentCorrectExam, formData.currentCorrectExam.answerValue]);

  const deleteExamFunc = exam => {
    deleteCusExamEval({
      _id: exam._id
    });
  };

  const deleteModalOpen = item => {
    setFormData({
      ...formData,
      modalDeleteExam: !formData.modalDeleteExam,
      toDelete: item
    });
  };
  const deleteModalClose = () => {
    setFormData({
      ...formData,
      modalDeleteExam: !formData.modalDeleteExam,
      toDelete: ""
    });
  };

  useEffect(() => {
    if (cusExamEvalDeleted) {
      setFormData(prevState => {
        return {
          ...prevState,
          toDelete: "",
          modalDeleteExam: false
        };
      });

      clearCustomizablesReducer();
    }
  }, [cusExamEvalDeleted, clearCustomizablesReducer]);

  useEffect(() => {
    getSpecificExamEvaluations({ _id: currentExam.examId });
  }, [
    getSpecificExamEvaluations,
    cusExamEvalUpdate,
    cusExamEvalDeleted,
    currentExam.examId
  ]);

  useEffect(() => {
    const newArray = currentExam.students.map((item, index) => {
      if (!item._id) {
        return "remove";
      } else {
        const filter = examEvalList.filter(
          item2 => item2.studentId._id === item._id
        );
        if (filter.length !== 0) {
          return "remove";
        } else {
          return item;
        }
      }
    });

    const filter2 = newArray.filter(item => item !== "remove");

    setFormData(prevState => {
      return {
        ...prevState,
        studentList: filter2,
        examEvalList: examEvalList
      };
    });
  }, [currentExam.students, examEvalList]);

  const getStatus = item => {
    if (item.status === "evaluated") {
      return "evaluated";
    } else if (item.duration === 0) {
      return "No duration";
    } else {
      const status =
        (item.duration * 60 - (moment().unix() - item.dateExamStarted)) * 1000;
      return status < 0 ? "Completed" : "Ongoing";
    }
  };

  const showCorrection = exam => {
    setFormData({
      ...formData,
      showCorrection: !formData.showCorrection,
      currentCorrectExam: {
        ...formData.currentCorrectExam,
        author: exam.author,
        components: exam.components,
        date: exam.date,
        dateExamStarted: exam.dateExamStarted,
        description: exam.description,
        duration: exam.duration,
        englishLevel: exam.englishLevel,
        examId: exam.examId,
        name: exam.name,
        status: exam.status,
        studentId: exam.studentId,
        userTutor: exam.userTutor,
        _id: exam._id,
        answerValue: exam.answerValue
          ? exam.answerValue
          : formData.currentCorrectExam.answerValue
      }
    });
  };

  const onChangeComponent = (index, e) => {
    const newArray = formData.currentCorrectExam.components.map(
      (item, index2) => {
        if (index === index2) {
          return {
            ...item,
            [e.target.name]: e.target.value
          };
        } else {
          return item;
        }
      }
    );
    setFormData({
      ...formData,
      currentCorrectExam: {
        ...formData.currentCorrectExam,
        components: newArray
      }
    });
  };

  const radioPick = (nr, index) => {
    const newArray = formData.currentCorrectExam.components.map(
      (item, index2) => {
        if (index === index2) {
          return {
            ...item,
            answer: nr
          };
        } else {
          return item;
        }
      }
    );
    setFormData({
      ...formData,
      currentCorrectExam: {
        ...formData.currentCorrectExam,
        components: newArray
      }
    });
  };

  const removeComponent = component => {
    const filter = formData.currentCorrectExam.components.filter(
      item => item._id !== component._id
    );
    setFormData({
      ...formData,
      currentCorrectExam: {
        ...formData.currentCorrectExam,
        components: filter
      }
    });
  };

  const checkPicks = (nr, index) => {
    const newArray = formData.currentCorrectExam.components.map(
      (item, index2) => {
        if (index === index2) {
          const filter = item.answer.filter(item2 => item2 === nr);
          if (filter.length !== 0) {
            const filter2 = item.answer.filter(item2 => item2 !== nr);
            return {
              ...item,
              answer: filter2
            };
          } else {
            return {
              ...item,
              answer: [...item.answer, nr]
            };
          }
        } else {
          return item;
        }
      }
    );
    setFormData({
      ...formData,
      currentCorrectExam: {
        ...formData.currentCorrectExam,
        components: newArray
      }
    });
  };

  // checkboxes
  const checkboxAnswer = (nr, index) => {
    const filter = formData.currentCorrectExam.components[index].answer.filter(
      item => item === nr
    );
    if (filter.length !== 0) {
      return true;
    } else return false;
  };
  // checkboxes
  const checkboxAnswerStudent = (nr, index) => {
    const filter = formData.currentCorrectExam.components[
      index
    ].studentAnswer.filter(item => item === nr);
    if (filter.length !== 0) {
      return true;
    } else return false;
  };

  const onChangeComponentMatch = (index, match, e) => {
    const newArray = formData.currentCorrectExam.components.map(
      (item, index2) => {
        if (index === index2) {
          return {
            ...item,
            [match]: {
              ...item[match],
              [e.target.name]: e.target.value
            }
          };
        } else {
          return item;
        }
      }
    );
    setFormData({
      ...formData,
      currentCorrectExam: {
        ...formData.currentCorrectExam,
        components: newArray
      }
    });
  };

  const correctMultipleChoice = component => {
    if (component.studentAnswer === component.answer) {
      return <MDBIcon className="green-text" size="2x" icon="check" />;
    } else {
      return <MDBIcon className="blue-text" size="2x" icon="times" />;
    }
  };
  const correctMultipleChoice2 = component => {
    if (component.studentAnswer === component.answer) {
      return "green lighten-4";
    } else {
      return "light-blue lighten-4";
    }
  };

  const correctMatching = (component, match, matching) => {
    if (match === "match1") {
      if (component.studentAnswer.matching1.length < 2) {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      } else if (
        component.match1.match1b === component.studentAnswer.matching1[1].value
      ) {
        return <MDBIcon className="green-text" size="2x" icon="check" />;
      } else {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      }
    } else if (match === "match2") {
      if (component.studentAnswer.matching2.length < 2) {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      } else if (
        component.match2.match2b === component.studentAnswer.matching2[1].value
      ) {
        return <MDBIcon className="green-text" size="2x" icon="check" />;
      } else {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      }
    } else if (match === "match3") {
      if (component.studentAnswer.matching3.length < 2) {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      } else if (
        component.match3.match3b === component.studentAnswer.matching3[1].value
      ) {
        return <MDBIcon className="green-text" size="2x" icon="check" />;
      } else {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      }
    } else if (match === "match4") {
      if (component.studentAnswer.matching4.length < 2) {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      } else if (
        component.match4.match4b === component.studentAnswer.matching4[1].value
      ) {
        return <MDBIcon className="green-text" size="2x" icon="check" />;
      } else {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      }
    } else if (match === "match5") {
      if (component.studentAnswer.matching5.length < 2) {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      } else if (
        component.match5.match5b === component.studentAnswer.matching5[1].value
      ) {
        return <MDBIcon className="green-text" size="2x" icon="check" />;
      } else {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      }
    } else if (match === "match6") {
      if (component.studentAnswer.matching6.length < 2) {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      } else if (
        component.match6.match6b === component.studentAnswer.matching6[1].value
      ) {
        return <MDBIcon className="green-text" size="2x" icon="check" />;
      } else {
        return <MDBIcon className="blue-text" size="2x" icon="times" />;
      }
    }
  };
  const correctMatching2 = (component, match, matching) => {
    if (match === "match1") {
      if (component.studentAnswer.matching1.length < 2) {
        return "light-blue lighten-4";
      } else if (
        component.match1.match1b === component.studentAnswer.matching1[1].value
      ) {
        return "green lighten-4";
      } else {
        return "light-blue lighten-4";
      }
    } else if (match === "match2") {
      if (component.studentAnswer.matching2.length < 2) {
        return "light-blue lighten-4";
      } else if (
        component.match2.match2b === component.studentAnswer.matching2[1].value
      ) {
        return "green lighten-4";
      } else {
        return "light-blue lighten-4";
      }
    } else if (match === "match3") {
      if (component.studentAnswer.matching3.length < 2) {
        return "light-blue lighten-4";
      } else if (
        component.match3.match3b === component.studentAnswer.matching3[1].value
      ) {
        return "green lighten-4";
      } else {
        return "light-blue lighten-4";
      }
    } else if (match === "match4") {
      if (component.studentAnswer.matching4.length < 2) {
        return "light-blue lighten-4";
      } else if (
        component.match4.match4b === component.studentAnswer.matching4[1].value
      ) {
        return "green lighten-4";
      } else {
        return "light-blue lighten-4";
      }
    } else if (match === "match5") {
      if (component.studentAnswer.matching5.length < 2) {
        return "light-blue lighten-4";
      } else if (
        component.match5.match5b === component.studentAnswer.matching5[1].value
      ) {
        return "green lighten-4";
      } else {
        return "light-blue lighten-4";
      }
    } else if (match === "match6") {
      if (component.studentAnswer.matching6.length < 2) {
        return "light-blue lighten-4";
      } else if (
        component.match6.match6b === component.studentAnswer.matching6[1].value
      ) {
        return "green lighten-4";
      } else {
        return "light-blue lighten-4";
      }
    }
  };
  const correctCheckBox = component => {
    const newArray = component.studentAnswer.map((item, index) => {
      const filter = component.answer.filter(item2 => item === item2);

      if (filter.length !== 0) {
        return "right";
      } else {
        return "wrong";
      }
    });

    const find = newArray.find(item => item === "wrong");

    if (component.studentAnswer.length !== component.answer.length) {
      return <MDBIcon className="blue-text" size="2x" icon="times" />;
    } else if (find === "wrong") {
      return <MDBIcon className="blue-text" size="2x" icon="times" />;
    } else {
      return <MDBIcon className="green-text" size="2x" icon="check" />;
    }
  };
  const correctCheckBox2 = component => {
    const newArray = component.studentAnswer.map((item, index) => {
      const filter = component.answer.filter(item2 => item === item2);

      if (filter.length !== 0) {
        return "right";
      } else {
        return "wrong";
      }
    });

    const find = newArray.find(item => item === "wrong");

    if (component.studentAnswer.length !== component.answer.length) {
      return "light-blue lighten-4";
    } else if (find === "wrong") {
      return "light-blue lighten-4";
    } else {
      return "green lighten-4";
    }
  };

  const onChangeValue = e => {
    let val = parseInt(e.target.value);
    if (isNaN(val)) {
      setFormData({
        ...formData,
        currentCorrectExam: {
          ...formData.currentCorrectExam,

          answerValue: {
            ...formData.currentCorrectExam.answerValue,
            [e.target.name]: ""
          }
        }
      });
    } else {
      if (val < 1) {
        setFormData({
          ...formData,
          currentCorrectExam: {
            ...formData.currentCorrectExam,

            answerValue: {
              ...formData.currentCorrectExam.answerValue,
              [e.target.name]: 0
            }
          }
        });
      }

      if (val) {
        setFormData({
          ...formData,
          currentCorrectExam: {
            ...formData.currentCorrectExam,

            answerValue: {
              ...formData.currentCorrectExam.answerValue,
              [e.target.name]: val
            }
          }
        });
      }
    }
  };

  // const totalMark = () => {
  //   setFormData({
  //     ...formData,
  //     answerValue:{
  //       ...formData.answerValue,
  //       totalMark:
  //     }
  //   })
  // }

  // useEffect(()=>{
  //   setFormData({
  //     ...formData,
  //     currentCorrectExam:{
  //       ...formData.currentCorrectExam,
  //       grade: totalMark()
  //     }
  //   },[formData.listScore, formData.currentCorrectExam.answerValue])

  // useEffect(()=>{
  //   setFormData({
  //         ...formData,
  //         currentCorrectExam:{
  //           ...formData.currentCorrectExam,
  //           grade: totalMark()
  //         }})
  // },[formData.listScore])

  const totalMark = () => {
    let numeratorArray = formData.listScore.map((item, index) => {
      if (item.numeratorValue === "") {
        return 0;
      } else {
        return item.numeratorValue;
      }
    });

    let denominatorArray = formData.listScore.map((item, index) => {
      if (item.denominatorValue === "") {
        return 0;
      } else {
        return item.denominatorValue;
      }
    });

    numeratorArray = numeratorArray.reduce((a, b) => a + b, 0);
    denominatorArray = denominatorArray.reduce((a, b) => a + b, 0);

    return parseFloat(((numeratorArray / denominatorArray) * 100).toFixed(0));
  };
  const totalMarkFrac = () => {
    let numeratorArray = formData.listScore.map((item, index) => {
      if (item.numeratorValue === "") {
        return 0;
      } else {
        return item.numeratorValue;
      }
    });

    let denominatorArray = formData.listScore.map((item, index) => {
      if (item.denominatorValue === "") {
        return 0;
      } else {
        return item.denominatorValue;
      }
    });

    numeratorArray = numeratorArray.reduce((a, b) => a + b, 0);
    denominatorArray = denominatorArray.reduce((a, b) => a + b, 0);

    return `${parseFloat(numeratorArray.toFixed(2))} / ${denominatorArray}`;
  };

  const onChangeTextArea = (e, index) => {
    const newArray = formData.currentCorrectExam.components.map(
      (item, index2) => {
        if (index2 === index) {
          let val = parseInt(e.target.value);
          if (isNaN(val)) {
            return {
              ...item,
              [e.target.name]: ""
            };
          } else {
            if (val < 1) {
              return {
                ...item,
                [e.target.name]: 0
              };
            } else if (val) {
              return {
                ...item,
                [e.target.name]: val
              };
            } else {
              return null;
            }
          }
        } else {
          return item;
        }
      }
    );

    setFormData({
      ...formData,
      currentCorrectExam: {
        ...formData.currentCorrectExam,
        components: newArray
      }
    });
  };

  const backModal = () => {
    setFormData({
      ...formData,
      currentCorrectExam: {
        components: [],
        studentId: {
          name: ""
        },
        answerValue: {
          multipleChoice: 5,
          checkBox: 5,
          matching: 6,
          longAnswer: 5,
          totalNumerator: [],
          totalDenominator: []
        }
      },

      listScore: [],
      matchingNumerator: [],
      matchingDenominator: "",
      showCorrection: !formData.showCorrection
    });
  };
  const closeModal = () => {
    setFormData({
      examEvalList: [],
      studentList: [],
      showCorrection: false,
      currentCorrectExam: {
        components: [],
        studentId: {
          name: ""
        },
        answerValue: {
          multipleChoice: 5,
          checkBox: 5,
          matching: 6,
          longAnswer: 5,
          totalNumerator: [],
          totalDenominator: []
        }
      },

      listScore: [],
      matchingNumerator: [],
      matchingDenominator: ""
    });
    closeModalFunc();
  };

  const saveEvaluation = () => {
    setLoadingButtonCustomizablesEval(true);
    updateEval({
      ...formData.currentCorrectExam,
      status: "evaluated",
      grade: isNaN(totalMark()) === true ? 0 : totalMark()
    });
  };

  // before return
  return (
    <Fragment>
      <MDBRow center>
        <MDBContainer
          className={
            formData.modalDeleteExam === true
              ? "text-center mx-3 my-3 "
              : "d-none"
          }
          id="whitebackgroundCus"
        >
          <p>Confirm Delete</p>
          <p>
            If you would like to delete this student exam / evaluation please
            click on the 'confirm' button below.
          </p>

          <MDBBtn onClick={deleteModalClose} size="sm" color="dark">
            Close
          </MDBBtn>
          <MDBBtn
            onClick={() => deleteExamFunc(formData.toDelete)}
            size="sm"
            color="danger"
          >
            Confirm
          </MDBBtn>
        </MDBContainer>
      </MDBRow>
      <MDBRow className="pb-5" center>
        <MDBContainer
          className={formData.showCorrection === true ? "" : "d-none"}
        >
          {/* Left Side */}
          <MDBRow>
            <MDBCol md="9">
              <MDBContainer>
                <MDBCard className="mx-4 my-4">
                  <MDBRow center className="mx-3 ">
                    <MDBCol>
                      <div
                        style={{ height: "5rem" }}
                        className="rounded-top middleVertical"
                      >
                        <p className="livvicFont cyanText examCorrectFont">
                          {formData.currentCorrectExam.studentId.name}'s Test
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <div className="customizablesScroll">
                    <div style={{ overflowY: "auto" }}>
                      <MDBCardBody>
                        <MDBCol>
                          <div style={{ height: "30rem" }}>
                            {formData.currentCorrectExam.components.map(
                              (item, index) => {
                                if (item.type === "multipleChoice") {
                                  return (
                                    <Fragment key={item._id}>
                                      <MDBRow
                                        id={`multipleChoice${index}`}
                                        className="pb-5 mb-5  z-depth-2"
                                      >
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="12"
                                        >
                                          {/* Question */}
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="question"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].question
                                            }
                                            label="Question"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponent(index, e)
                                            }
                                          />
                                        </MDBCol>

                                        <MDBCol
                                          className="text-center"
                                          size="6"
                                        >
                                          {/* Option 1 and 2 */}
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice1"
                                                type="text"
                                                value={
                                                  formData.currentCorrectExam
                                                    .components[index].choice1
                                                }
                                                label="Choice 1"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                            <div className="w-100"></div>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice2"
                                                type="text"
                                                value={
                                                  formData.currentCorrectExam
                                                    .components[index].choice2
                                                }
                                                label="Choice 2"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                        {/* Option 2 and 3 */}
                                        <MDBCol
                                          className="text-center"
                                          size="6"
                                        >
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice3"
                                                type="text"
                                                value={
                                                  formData.currentCorrectExam
                                                    .components[index].choice3
                                                }
                                                label="Choice 3"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                            <div className="w-100"></div>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice4"
                                                type="text"
                                                value={
                                                  formData.currentCorrectExam
                                                    .components[index].choice4
                                                }
                                                label="Choice 4"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                        {/* Answer */}

                                        <MDBCol
                                          className="text-center  "
                                          size="12"
                                        >
                                          <p className="text-center h5 mt-2 livvicFont">
                                            Answer
                                          </p>
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  radioPick(1, index)
                                                }
                                                checked={
                                                  formData.currentCorrectExam
                                                    .components[index]
                                                    .answer === 1
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio1-${index}`}
                                                label="1"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  radioPick(2, index)
                                                }
                                                checked={
                                                  formData.currentCorrectExam
                                                    .components[index]
                                                    .answer === 2
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio2-${index}`}
                                                label="2"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  radioPick(3, index)
                                                }
                                                checked={
                                                  formData.currentCorrectExam
                                                    .components[index]
                                                    .answer === 3
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio3-${index}`}
                                                label="3"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  radioPick(4, index)
                                                }
                                                checked={
                                                  formData.currentCorrectExam
                                                    .components[index]
                                                    .answer === 4
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio4-${index}`}
                                                label="4"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>

                                        <MDBCol
                                          className={`${correctMultipleChoice2(
                                            item
                                          )} text-center`}
                                          size="12"
                                        >
                                          <p className="text-center h5 mt-2 livvicFont">
                                            Student's Answer
                                          </p>
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                disabled
                                                onClick={() =>
                                                  radioPick(1, index)
                                                }
                                                checked={
                                                  formData.currentCorrectExam
                                                    .components[index]
                                                    .studentAnswer === 1
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio1-${index}`}
                                                label="1"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                disabled
                                                onClick={() =>
                                                  radioPick(2, index)
                                                }
                                                checked={
                                                  formData.currentCorrectExam
                                                    .components[index]
                                                    .studentAnswer === 2
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio2-${index}`}
                                                label="2"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                disabled
                                                onClick={() =>
                                                  radioPick(3, index)
                                                }
                                                checked={
                                                  formData.currentCorrectExam
                                                    .components[index]
                                                    .studentAnswer === 3
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio3-${index}`}
                                                label="3"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                disabled
                                                onClick={() =>
                                                  radioPick(4, index)
                                                }
                                                checked={
                                                  formData.currentCorrectExam
                                                    .components[index]
                                                    .studentAnswer === 4
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio4-${index}`}
                                                label="4"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                          <MDBRow>
                                            <MDBCol className="mr-auto">
                                              <MDBIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  removeComponent(item)
                                                }
                                                className="red-text"
                                                size="1x"
                                                icon="trash"
                                              />
                                            </MDBCol>
                                            <MDBCol className="auto">
                                              {correctMultipleChoice(item)}
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                      </MDBRow>
                                    </Fragment>
                                  );
                                } else if (item.type === "checkAllBoxes") {
                                  return (
                                    <Fragment key={item._id}>
                                      <MDBRow
                                        id={`checkAllBoxes${index}`}
                                        className="pb-5 mb-5  z-depth-2"
                                      >
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="12"
                                        >
                                          {/* Question */}
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="question"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].question
                                            }
                                            label="Question"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponent(index, e)
                                            }
                                          />
                                        </MDBCol>

                                        <MDBCol
                                          className="text-center"
                                          size="6"
                                        >
                                          {/* Option 1 and 2 */}
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice1"
                                                type="text"
                                                value={
                                                  formData.currentCorrectExam
                                                    .components[index].choice1
                                                }
                                                label="Choice 1"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                            <div className="w-100"></div>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice2"
                                                type="text"
                                                value={
                                                  formData.currentCorrectExam
                                                    .components[index].choice2
                                                }
                                                label="Choice 2"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                        {/* Option 2 and 3 */}
                                        <MDBCol
                                          className="text-center"
                                          size="6"
                                        >
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice3"
                                                type="text"
                                                value={
                                                  formData.currentCorrectExam
                                                    .components[index].choice3
                                                }
                                                label="Choice 3"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                            <div className="w-100"></div>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice4"
                                                type="text"
                                                value={
                                                  formData.currentCorrectExam
                                                    .components[index].choice4
                                                }
                                                label="Choice 4"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                        {/* Answer */}

                                        <MDBCol
                                          className="text-center  "
                                          size="12"
                                        >
                                          <p className="text-center h5 mt-2 livvicFont">
                                            Answer
                                          </p>
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  checkPicks(1, index)
                                                }
                                                checked={checkboxAnswer(
                                                  1,
                                                  index
                                                )}
                                                type="checkbox"
                                                id={`checkbox1-${index}`}
                                                label="1"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  checkPicks(2, index)
                                                }
                                                checked={checkboxAnswer(
                                                  2,
                                                  index
                                                )}
                                                type="checkbox"
                                                id={`checkbox2-${index}`}
                                                label="2"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  checkPicks(3, index)
                                                }
                                                checked={checkboxAnswer(
                                                  3,
                                                  index
                                                )}
                                                type="checkbox"
                                                id={`checkbox3-${index}`}
                                                label="3"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  checkPicks(4, index)
                                                }
                                                checked={checkboxAnswer(
                                                  4,
                                                  index
                                                )}
                                                type="checkbox"
                                                id={`checkbox4-${index}`}
                                                label="4"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                          </MDBRow>

                                          <MDBCol
                                            className={`${correctCheckBox2(
                                              item
                                            )} text-center`}
                                            size="12"
                                          >
                                            <p className="text-center h5 mt-2 livvicFont">
                                              Student's Answer
                                            </p>
                                            <MDBRow>
                                              <MDBCol>
                                                <MDBInput
                                                  disabled
                                                  checked={checkboxAnswerStudent(
                                                    1,
                                                    index
                                                  )}
                                                  type="checkbox"
                                                  id={`checkbox1-${index}`}
                                                  label="1"
                                                  labelClass="livvicFont"
                                                />
                                              </MDBCol>
                                              <MDBCol>
                                                <MDBInput
                                                  disabled
                                                  checked={checkboxAnswerStudent(
                                                    2,
                                                    index
                                                  )}
                                                  type="checkbox"
                                                  id={`checkbox2-${index}`}
                                                  label="2"
                                                  labelClass="livvicFont"
                                                />
                                              </MDBCol>
                                              <MDBCol>
                                                <MDBInput
                                                  disabled
                                                  checked={checkboxAnswerStudent(
                                                    3,
                                                    index
                                                  )}
                                                  type="checkbox"
                                                  id={`checkbox3-${index}`}
                                                  label="3"
                                                  labelClass="livvicFont"
                                                />
                                              </MDBCol>
                                              <MDBCol>
                                                <MDBInput
                                                  disabled
                                                  checked={checkboxAnswerStudent(
                                                    4,
                                                    index
                                                  )}
                                                  type="checkbox"
                                                  id={`checkbox4-${index}`}
                                                  label="4"
                                                  labelClass="livvicFont"
                                                />
                                              </MDBCol>
                                              <MDBCol
                                                className="text-center"
                                                size="12"
                                              >
                                                <MDBRow>
                                                  <MDBCol className="mr-auto">
                                                    <MDBIcon
                                                      style={{
                                                        cursor: "pointer"
                                                      }}
                                                      onClick={() =>
                                                        removeComponent(item)
                                                      }
                                                      className="red-text"
                                                      size="1x"
                                                      icon="trash"
                                                    />
                                                  </MDBCol>
                                                  <MDBCol className="auto">
                                                    {correctCheckBox(item)}
                                                  </MDBCol>
                                                </MDBRow>
                                              </MDBCol>
                                            </MDBRow>
                                          </MDBCol>
                                        </MDBCol>
                                      </MDBRow>
                                    </Fragment>
                                  );
                                } else if (item.type === "textArea") {
                                  return (
                                    <Fragment key={item._id}>
                                      <MDBRow
                                        id={`textArea${index}`}
                                        className="pb-5 mb-5  z-depth-2"
                                      >
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="12"
                                        >
                                          {/* Question */}
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="question"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].question
                                            }
                                            label="Question"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponent(index, e)
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="12"
                                        >
                                          {/* Question */}
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="answer"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].answer
                                            }
                                            label="Answer (optional)"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponent(index, e)
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center yellow lighten-4"
                                          size="12"
                                        >
                                          <p className="text-center h5 mt-2 livvicFont">
                                            Student's Answer
                                          </p>
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="answer"
                                            rows={5}
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].studentAnswer
                                            }
                                          />
                                          <MDBRow className="middleVertical">
                                            <MDBCol className="mr-auto ">
                                              <MDBIcon
                                                style={{
                                                  cursor: "pointer"
                                                }}
                                                onClick={() =>
                                                  removeComponent(item)
                                                }
                                                className="red-text"
                                                size="1x"
                                                icon="trash"
                                              />
                                            </MDBCol>
                                            <MDBCol className="auto ">
                                              <MDBInput
                                                onChange={e =>
                                                  onChangeTextArea(e, index)
                                                }
                                                className="inputWidth text-center center-block "
                                                name="grade"
                                                label="Grade"
                                                labelClass="livvicFont"
                                                type="text"
                                                value={
                                                  formData.currentCorrectExam
                                                    .components[index].grade
                                                }
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                      </MDBRow>
                                    </Fragment>
                                  );
                                } else if (item.type === "matching") {
                                  return (
                                    <Fragment key={item._id}>
                                      <MDBRow
                                        id={`matching${index}`}
                                        className="pb-5 mb-5  z-depth-2"
                                      >
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="12"
                                        >
                                          {/* Question */}
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="question"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].question
                                            }
                                            label="Question"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponent(index, e)
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match1a"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match1
                                                .match1a
                                            }
                                            label="Match 1a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match1",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match1b"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match1
                                                .match1b
                                            }
                                            label="Match 1b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match1",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match2a"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match2
                                                .match2a
                                            }
                                            label="Match 2a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match2",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match2b"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match2
                                                .match2b
                                            }
                                            label="Match 2b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match2",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match3a"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match3
                                                .match3a
                                            }
                                            label="Match 3a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match3",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match3b"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match3
                                                .match3b
                                            }
                                            label="Match 3b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match3",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match4a"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match4
                                                .match4a
                                            }
                                            label="Match 4a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match4",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match4b"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match4
                                                .match4b
                                            }
                                            label="Match 4b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match4",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>

                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match5a"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match5
                                                .match5a
                                            }
                                            label="Match 5a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match5",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match5b"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match5
                                                .match5b
                                            }
                                            label="Match 5b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match5",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match6a"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match6
                                                .match6a
                                            }
                                            label="Match 6a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match6",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match6b"
                                            type="textarea"
                                            value={
                                              formData.currentCorrectExam
                                                .components[index].match6
                                                .match6b
                                            }
                                            label="Match 6b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match6",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <div className=" pb-1">
                                          <MDBCol
                                            size="12"
                                            className="customizablesMarginBottom"
                                          >
                                            <p className="text-center h5 mt-2 livvicFont">
                                              Student's Answer
                                            </p>
                                          </MDBCol>

                                          <MDBCol size="12">
                                            <MDBRow center>
                                              <MDBCol
                                                size="5"
                                                className={`${correctMatching2(
                                                  item,
                                                  "match1",
                                                  "matching1"
                                                )} mx-1 my-2 border border-dark`}
                                              >
                                                {formData.currentCorrectExam.components[
                                                  index
                                                ].studentAnswer.matching1.map(
                                                  (item, index) => {
                                                    return (
                                                      <p
                                                        key={index}
                                                        className="h5-responsive livvicFont"
                                                      >
                                                        {item.value}{" "}
                                                      </p>
                                                    );
                                                  }
                                                )}

                                                {correctMatching(
                                                  item,
                                                  "match1",
                                                  "matching1"
                                                )}
                                              </MDBCol>

                                              <MDBCol
                                                size="5"
                                                className={`${correctMatching2(
                                                  item,
                                                  "match2",
                                                  "matching2"
                                                )} mx-1 my-2 border border-dark`}
                                              >
                                                {formData.currentCorrectExam.components[
                                                  index
                                                ].studentAnswer.matching2.map(
                                                  (item, index) => {
                                                    return (
                                                      <p
                                                        key={index}
                                                        className="h5-responsive livvicFont"
                                                      >
                                                        {item.value}{" "}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                                {correctMatching(
                                                  item,
                                                  "match2",
                                                  "matching2"
                                                )}
                                              </MDBCol>

                                              <MDBCol
                                                size="5"
                                                className={`${correctMatching2(
                                                  item,
                                                  "match3",
                                                  "matching3"
                                                )} mx-1 my-2 border border-dark`}
                                              >
                                                {formData.currentCorrectExam.components[
                                                  index
                                                ].studentAnswer.matching3.map(
                                                  (item, index) => {
                                                    return (
                                                      <p
                                                        key={index}
                                                        className="h5-responsive livvicFont"
                                                      >
                                                        {item.value}{" "}
                                                      </p>
                                                    );
                                                  }
                                                )}

                                                {correctMatching(
                                                  item,
                                                  "match3",
                                                  "matching3"
                                                )}
                                              </MDBCol>

                                              <MDBCol
                                                size="5"
                                                className={`${correctMatching2(
                                                  item,
                                                  "match4",
                                                  "matching4"
                                                )} mx-1 my-2 border border-dark`}
                                              >
                                                {formData.currentCorrectExam.components[
                                                  index
                                                ].studentAnswer.matching4.map(
                                                  (item, index) => {
                                                    return (
                                                      <p
                                                        key={index}
                                                        className="h5-responsive livvicFont"
                                                      >
                                                        {item.value}{" "}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                                {correctMatching(
                                                  item,
                                                  "match4",
                                                  "matching4"
                                                )}
                                              </MDBCol>

                                              <MDBCol
                                                size="5"
                                                className={`${correctMatching2(
                                                  item,
                                                  "match5",
                                                  "matching5"
                                                )} mx-1 my-2 border border-dark`}
                                              >
                                                {formData.currentCorrectExam.components[
                                                  index
                                                ].studentAnswer.matching5.map(
                                                  (item, index) => {
                                                    return (
                                                      <p
                                                        key={index}
                                                        className="h5-responsive livvicFont"
                                                      >
                                                        {item.value}{" "}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                                {correctMatching(
                                                  item,
                                                  "match5",
                                                  "matching5"
                                                )}
                                              </MDBCol>

                                              <MDBCol
                                                size="5"
                                                className={`${correctMatching2(
                                                  item,
                                                  "match6",
                                                  "matching6"
                                                )} mx-1 my-2 border border-dark`}
                                              >
                                                {formData.currentCorrectExam.components[
                                                  index
                                                ].studentAnswer.matching6.map(
                                                  (item, index) => {
                                                    return (
                                                      <p
                                                        key={index}
                                                        className="h5-responsive livvicFont"
                                                      >
                                                        {item.value}{" "}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                                {correctMatching(
                                                  item,
                                                  "match6",
                                                  "matching6"
                                                )}
                                              </MDBCol>
                                            </MDBRow>
                                          </MDBCol>

                                          <MDBCol
                                            className="text-center"
                                            size="12"
                                          >
                                            <MDBRow center>
                                              <MDBIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  removeComponent(item)
                                                }
                                                className="red-text"
                                                size="1x"
                                                icon="trash"
                                              />
                                            </MDBRow>
                                          </MDBCol>
                                        </div>
                                      </MDBRow>
                                    </Fragment>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )}
                          </div>
                        </MDBCol>
                      </MDBCardBody>
                    </div>
                  </div>
                </MDBCard>
              </MDBContainer>
            </MDBCol>

            {/* Right Side */}
            <MDBCol md="3">
              <div className="white mt-4 rounded">
                <p className="text-center h5 mt-2 livvicFont cyanText pt-2">
                  Points Attributed
                </p>
                <MDBListGroup>
                  <MDBListGroupItem className="noBorder">
                    <MDBRow className="middleVertical">
                      <MDBCol>
                        <p className="livvicFont cyanText">Multiple Choice</p>
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          name="multipleChoice"
                          type="text"
                          value={
                            formData.currentCorrectExam.answerValue
                              .multipleChoice
                          }
                          onChange={onChangeValue}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="middleVertical">
                      <MDBCol>
                        <p className="livvicFont cyanText">
                          Check All the Boxes
                        </p>
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          name="checkBox"
                          type="text"
                          value={
                            formData.currentCorrectExam.answerValue.checkBox
                          }
                          onChange={onChangeValue}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="middleVertical">
                      <MDBCol>
                        <p className="livvicFont cyanText">Long Answer</p>
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          name="longAnswer"
                          type="text"
                          value={
                            formData.currentCorrectExam.answerValue.longAnswer
                          }
                          onChange={onChangeValue}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="middleVertical">
                      <MDBCol>
                        <p className="livvicFont cyanText">Matching</p>
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          name="matching"
                          type="text"
                          value={
                            formData.currentCorrectExam.answerValue.matching
                          }
                          onChange={onChangeValue}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="middleVertical">
                      <MDBCol>
                        <p className="livvicFont cyanText">Total Mark</p>
                      </MDBCol>

                      <MDBCol>
                        <p>{isNaN(totalMark()) === true ? 0 : totalMark()} %</p>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="middleVertical">
                      <MDBCol>
                        <p className="livvicFont cyanText">Total Mark</p>
                      </MDBCol>

                      <MDBCol>
                        {" "}
                        <p>{totalMarkFrac()} </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBListGroupItem>
                </MDBListGroup>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        {/* Table */}
        <MDBContainer
          className={formData.showCorrection === true ? "d-none" : ""}
        >
          <MDBRow className="mt-3">
            <MDBCol size="12">
              <MDBCard
                className="my-5  mx-auto "
                style={{ fontWeight: 300, maxWidth: "90%" }}
              >
                <div className="rounded-top cyanBackground lighten-3 text-center py-2">
                  <MDBRow className="pt-3">
                    <MDBCol className="text-center livvicFont" size="3">
                      <p className="text-white">Student</p>
                    </MDBCol>

                    <MDBCol className="text-center livvicFont" size="3">
                      <p className="text-white">Status</p>
                    </MDBCol>
                    <MDBCol className="text-center livvicFont" size="3">
                      <p className="text-white">Grade</p>
                    </MDBCol>
                    <MDBCol className="text-center livvicFont" size="3">
                      <p className="text-white">Delete</p>
                    </MDBCol>
                  </MDBRow>
                </div>
                {formData.examEvalList.map((item, index) => {
                  return (
                    <MDBRow key={item._id} className="pt-1">
                      <MDBCol className="text-center" size="3">
                        {/* <img
                        src={item.image.urls.thumb}
                        alt="thumbnail"
                        className=" customImage2"
                      /> */}
                        <MDBRow>
                          <MDBCol className="mr-auto">
                            <p
                              style={{ cursor: "pointer" }}
                              className="livvicFont fontBold "
                              onClick={
                                getStatus(item) === "Ongoing"
                                  ? null
                                  : () => showCorrection(item)
                              }
                            >
                              {item.studentId.name}
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>

                      <MDBCol className="text-center middleVertical" size="3">
                        <p className="livvicFont fontBold">{getStatus(item)}</p>
                      </MDBCol>
                      <MDBCol className="text-center middleVertical" size="3">
                        <p
                          className="livvicFont fontBold"
                          style={{ cursor: "pointer" }}
                        >
                          {item.grade ? item.grade + " %" : ""}
                        </p>
                      </MDBCol>
                      <MDBCol className="text-center middleVertical" size="3">
                        <p
                          onClick={() => deleteModalOpen(item)}
                          className="livvicFont fontBold red-text"
                          style={{ cursor: "pointer" }}
                        >
                          Delete
                        </p>
                      </MDBCol>

                      <div className=" divHeight cyanBackground lighten-3 text-center py-1">
                        {" "}
                      </div>
                    </MDBRow>
                  );
                })}
                {formData.studentList.map((item, index) => {
                  return (
                    <MDBRow key={item._id} className="pt-1">
                      <MDBCol className="text-center" size="3">
                        {/* <img
                        src={item.image.urls.thumb}
                        alt="thumbnail"
                        className=" customImage2"
                      /> */}
                        <MDBRow>
                          <MDBCol className="mr-auto">
                            <p
                              style={{ cursor: "pointer" }}
                              className="livvicFont fontBold "
                            >
                              {item.name}
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>

                      <MDBCol className="text-center middleVertical" size="3">
                        <p className="livvicFont fontBold">Not Started</p>
                      </MDBCol>
                      <MDBCol className="text-center middleVertical" size="3">
                        <p
                          className="livvicFont fontBold"
                          style={{ cursor: "pointer" }}
                        ></p>
                      </MDBCol>

                      <div className=" divHeight cyanBackground lighten-3 text-center py-1">
                        {" "}
                      </div>
                    </MDBRow>
                  );
                })}
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        {/* End of Table */}
        <MDBModalFooter className="cyanBackground">
          {formData.edit === true && <MDBBtn color="danger">Delete</MDBBtn>}

          {formData.showCorrection === true ? (
            <MDBBtn
              onClick={saveEvaluation}
              color="green darken-1"
              disabled={loadingButtonCustomizablesEval}
            >
              {loadingButtonCustomizablesEval === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Save Evaluation
            </MDBBtn>
          ) : null}
          {formData.showCorrection === true ? (
            <MDBBtn onClick={backModal} color="blue">
              Back
            </MDBBtn>
          ) : (
            <MDBBtn onClick={closeModal} color="blue">
              Close
            </MDBBtn>
          )}
        </MDBModalFooter>
      </MDBRow>
    </Fragment>
  );
};

CorrectedExams.propTypes = {};

const mapStateToProps = state => ({
  customizables: state.customizables,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    getSpecificExamEvaluations,
    updateEval,
    deleteCusExamEval,
    clearCustomizablesReducer,
    setLoadingButtonCustomizablesEval
  })(CorrectedExams)
);
