import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "./PublicPage.css";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
import Spinner from "../layout/Spinner";
import io from "socket.io-client";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBJumbotron
} from "mdbreact";

import { verifyEmail, clearUpdatedMessage } from "../../actions/publicpage";

const currentTimezone = moment.tz.guess() || "America/New_York";

const url = io("/messages/student", {
  transports: ["websocket"]
});
const Appointment = ({
  switchMode,
  history,
  match,
  getPublicPageURL,
  createMessage,
  publicpage: {
    messageObject,
    emailVerify,
    emailVerifyLoading,
    updateMsgAlert
  },
  location,
  verifyEmail,
  clearUpdatedMessage
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    verifyEmail({
      messageId: match.params.id
    });
  }, [verifyEmail, match.params.id]);

  useEffect(() => {
    if (updateMsgAlert) {
      url.emit("updateLastedPublicpageMsg", {
        userTutor: updateMsgAlert.userTutor,
        latestPublicpageMsg: updateMsgAlert.latestPublicpageMsg
      });

      clearUpdatedMessage();
    }
  }, [updateMsgAlert, clearUpdatedMessage]);

  // before return
  return emailVerifyLoading === true ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Appointment</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 text-center">
        <MDBRow>
          <MDBCol md="10" lg="10" xl="10" sm="12" className="mt-5 mx-auto">
            <MDBJumbotron className="mt-5">
              <MDBCardBody color="blue">
                <h1 className="x-large appointmentFontColor railwayFont">
                  {t("appointments.1")}
                </h1>
                {emailVerify === "verified" ? (
                  <p className="quickSand2">
                    <MDBIcon
                      icon="paper-plane"
                      size="md"
                      className="mr-2 appointmentFontColor"
                    />
                    {t("appointments.2")}
                  </p>
                ) : emailVerify === "denied" ? (
                  <p className="quickSand2">
                    <MDBIcon
                      icon="times"
                      size="md"
                      className="mr-2 appointmentFontColor2"
                    />
                    {t("appointments.3")}
                  </p>
                ) : emailVerify === "awaiting" ? (
                  <p className="quickSand2">
                    <MDBIcon
                      icon="hourglass-start"
                      size="md"
                      className="mr-2 appointmentFontColor"
                    />
                    {t("appointments.4")}
                  </p>
                ) : emailVerify === "confirmed" ? (
                  <p className="quickSand2">
                    <MDBIcon
                      icon="check"
                      size="md"
                      className="mr-2 appointmentFontColor"
                    />
                    {t("appointments.5")}
                  </p>
                ) : emailVerify === "late" ? (
                  <p className="quickSand2">
                    <MDBIcon
                      icon="calendar-times"
                      size="md"
                      className="mr-2 appointmentFontColor2"
                    />
                    {t("appointments.6")}
                  </p>
                ) : emailVerify === "taken" ? (
                  <p className="quickSand2">
                    <MDBIcon
                      icon="user-times"
                      size="md"
                      className="mr-2 appointmentFontColor2"
                    />
                    {t("appointments.7")}
                  </p>
                ) : null}
                <MDBRow center>
                  {messageObject.appointment.map((item, index) => {
                    const start1 = moment(item.start, "x").format(
                      "YYYY MM DD hh:mm a"
                    );

                    const start2 = moment
                      .tz(start1, "YYYY MM DD hh:mm a", currentTimezone)
                      .valueOf();
                    const end1 = moment(item.end, "x").format(
                      "YYYY MM DD hh:mm a"
                    );

                    const end2 = moment
                      .tz(end1, "YYYY MM DD hh:mm a", currentTimezone)
                      .valueOf();
                    return (
                      <p>
                        {moment(start2, "x")
                          .tz(currentTimezone)
                          .format("llll")}{" "}
                        -{" "}
                        {moment(end2, "x")
                          .tz(currentTimezone)
                          .format("llll")}
                      </p>
                    );
                  })}
                </MDBRow>
              </MDBCardBody>
            </MDBJumbotron>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

Appointment.propTypes = {};

const mapStateToProps = state => ({
  publicpage: state.publicpage
});

export default withRouter(
  connect(mapStateToProps, { verifyEmail, clearUpdatedMessage })(Appointment)
);
