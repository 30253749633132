import React, { Fragment, useEffect, useState } from "react";
import sum from "lodash/sum";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import urlParser from "js-video-url-parser";
import parse from "html-react-parser";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  createComment,
  deleteComment,
  likeComment,
  setLoadingButtonComment
} from "../../../actions/trading";
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput
} from "mdbreact";

const GameView = ({
  history,
  mode,
  gameContent,
  createComment,
  match,
  setLoadingButtonComment,
  trading: { comments, loadingButtonComment },
  auth: { user },
  deleteComment,
  likeComment
}) => {
  const [formData, setFormData] = useState({
    individualGame: {
      title: "",
      numberOfStudents: "",
      level: "",
      instructions: "",
      materials: "",
      duration: "",
      youtube: [],
      links: [],
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      },
      date: ""
    },
    showCommentButton: false,
    comment: ""
  });

  useEffect(() => {
    if (gameContent) {
      setFormData(prevState => {
        return {
          ...prevState,
          individualGame: {
            ...prevState.individualGame,
            title: gameContent.title,
            numberOfStudents: gameContent.numberOfStudents,
            level: gameContent.level,
            instructions: gameContent.instructions,
            materials: gameContent.materials,
            duration: gameContent.duration,
            youtube: gameContent.youtube,
            links: gameContent.links,
            userTutor: gameContent.userTutor,
            date: gameContent.date,
            description: gameContent.description
          }
        };
      });
    }
  }, [gameContent]);

  const createCommentFunc = () => {
    setLoadingButtonComment(true);
    createComment({
      date: moment(new Date()).valueOf(),
      text: formData.comment,
      gameId: match.params.id,
      module: "game"
    });
  };

  const onChangeComment = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const showCommentButton = () => {
    setFormData({
      ...formData,
      showCommentButton: true
    });
  };

  const likeFarFunc = item => {
    const find = item.thumbsUp.find(item3 => item3.user === user._id);
    if (find) {
      if (find.score === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const dislikeFarFunc = item => {
    const find = item.thumbsUp.find(item3 => item3.user === user._id);
    if (find) {
      if (find.score === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        comment: "",
        showCommentButton: false
      };
    });
  }, [comments]);

  const viewProfile = item => {
    history.push(`/trading/profile/${formData.individualGame.userTutor._id}`);
  };

  const viewProfile2 = item => {
    history.push(`/trading/profile/${item}`);
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{formData.individualGame.title}</title>
        <meta
          name="description"
          content={formData.individualGame.description}
        />
        <meta property="og:title" content={formData.individualGame.title} />
        <meta
          property="og:description"
          content={formData.individualGame.description}
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link
          rel="canonical"
          href={`https://www.topscholar.io/trading/game/${match.params.id}`}
        />
      </Helmet>
      <MDBRow className="middleVertical5 mx-2">
        <MDBCol sm="6">
          <MDBRow center>
            <MDBCol>
              <p className="quickSand mt-3 duruSans h5 text-center text-white">
                {formData.individualGame.title}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol sm="6">
          <MDBRow
            center
            className="middleVertical tradingNameHover"
            onClick={() =>
              viewProfile({ _id: formData.individualGame.userTutor._id })
            }
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ width: "35px" }}
              src={formData.individualGame.userTutor.avatar.url}
              alt=""
              className="rounded-circle z-depth-1-half"
            />
            <p className="mt-3 quickSand titleFontSizeTrading text-black text-white pl-2">
              Published by {formData.individualGame.userTutor.screenName}
            </p>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <hr />
      <MDBRow>
        <p className="mt-1 tradingFontSize3  text-white pl-2 mb-3 ml-2">
          Description:
          {formData.individualGame.description}
        </p>
      </MDBRow>
      <MDBContainer className=" modalBottomMargin rounded backgroundTradingGame">
        <MDBContainer>
          <MDBRow className="pt-5">
            <MDBCol md="4" className="mr-auto text-center">
              <p className="h5 pb-1 montserratFont">
                Level:{" "}
                <span className="gameFontSize4">
                  <strong>
                    {formData.individualGame.level
                      ? formData.individualGame.level
                      : null}
                  </strong>
                </span>
              </p>
            </MDBCol>
            <MDBCol
              md="4"
              className={
                formData.individualGame.numberOfStudents !== "text-center"
                  ? ""
                  : "d-none"
              }
            >
              <p className="h5 pb-1 montserratFont">
                Number of Students:{" "}
                <span className="gameFontSize4">
                  <strong>{formData.individualGame.numberOfStudents}</strong>
                </span>
              </p>
            </MDBCol>
            <MDBCol md="4" className="text-center">
              <p className="h5 pb-1 montserratFont">
                Duration:{" "}
                <span className="gameFontSize4">
                  <strong>{formData.individualGame.duration}</strong>
                </span>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <hr />
        <MDBContainer>
          <MDBRow className="row1">
            <MDBCol
              lg="12"
              className={
                formData.individualGame.instructions !== ""
                  ? "col-12"
                  : "d-none"
              }
            >
              <p className="pb-1 montserratFont gameFontSize">Instructions</p>
              <div className="ql-editor " id="fixMargin">
                <p
                  className="gameFontSize3 black-text"
                  dangerouslySetInnerHTML={{
                    __html: formData.individualGame.instructions
                  }}
                ></p>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="row1">
            <MDBCol
              lg="12"
              className={
                formData.individualGame.materials !== "" ? "col-12" : "d-none"
              }
            >
              <p className="pb-1 montserratFont gameFontSize">
                Game Materials Required
              </p>
              <div className="ql-editor " id="fixMargin">
                <p
                  dangerouslySetInnerHTML={{
                    __html: formData.individualGame.materials
                  }}
                  className="gameFontSize3 black-text"
                ></p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <hr />
        <Fragment>
          <MDBContainer>
            <MDBRow>
              <MDBCol
                md="12"
                className={
                  formData.individualGame.youtube.length !== 0
                    ? "mb-4"
                    : "d-none"
                }
              >
                <Fragment>
                  <p className="pb-1 text-center montserratFont gameFontSize">
                    Youtube Videos
                  </p>
                </Fragment>
              </MDBCol>
              {formData.individualGame.youtube.map((item, index) => {
                const emb = urlParser.parse(item.url);
                if (typeof emb === "undefined") {
                  return null;
                } else {
                  const embUrl = urlParser.create({
                    videoInfo: emb,
                    format: "embed"
                  });

                  return (
                    <MDBCol key={index} md="6" className="mb-4">
                      <div className="embed-responsive embed-responsive-16by9 mb-1">
                        <iframe
                          title="Embeds Page"
                          className="embed-responsive-item"
                          src={embUrl}
                          allowFullScreen
                        />
                      </div>
                    </MDBCol>
                  );
                }
              })}
            </MDBRow>
            <MDBRow>
              <MDBCol
                md="12"
                className={
                  formData.individualGame.links.length !== 0 ? "mb-4" : "d-none"
                }
              >
                <p className="text-center pb-1 montserratFont gameFontSize">
                  Website Links
                </p>
                {formData.individualGame.links.map((item, index) => {
                  const hrefgood = item.url.replace(/(^\w+:|^)\/\//, "");

                  return (
                    <ul key={index} className="fa-ul text-center quickSand">
                      <li className="list-inline-item">
                        <i className="fa-li fa fa-link" />

                        <a
                          href={`http://${hrefgood}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <span className="gameFontSize3">{item.title}</span>
                        </a>
                      </li>
                    </ul>
                  );
                })}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </Fragment>
      </MDBContainer>
      <MDBRow className="mt-2" center>
        <p className=" duruSans h5 text-center text-white">Comments</p>
      </MDBRow>
      <MDBRow center>
        <MDBCol size="12">
          <MDBInput
            style={{ overflowY: "auto" }}
            name="comment"
            type="textarea"
            value={formData.comment}
            label="Post a new comment"
            labelClass="livvicFont text-white"
            className="text-white"
            onChange={onChangeComment}
            onClick={showCommentButton}
          />
        </MDBCol>
        <MDBCol
          size="12"
          className={
            formData.showCommentButton === false ? "d-none" : "text-right"
          }
        >
          <MDBBtn
            onClick={() => {
              setFormData({
                ...formData,
                showCommentButton: false,
                comment: ""
              });
            }}
            outline
          >
            Cancel
          </MDBBtn>
          <MDBBtn disabled={loadingButtonComment} onClick={createCommentFunc}>
            {loadingButtonComment === true && (
              <div className="spinner-grow spinner-grow-sm" />
            )}
            Comment
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <hr />
      {comments.map((item, index) => {
        const thumb = item.thumbsUp.map((item2, index2) => {
          return item2.score;
        });
        let summation = sum(thumb);

        summation = summation <= 0 ? null : summation;

        return (
          <MDBRow className="mt-1 mb-4" key={item._id}>
            <MDBCol sm="4" md="2" className="col-4">
              <div className="label">
                <img
                  style={{ width: "35px", cursor: "pointer" }}
                  src={item.userTutor.avatar.url}
                  alt=""
                  className="rounded-circle z-depth-1-half"
                  onClick={() => viewProfile2(item.userTutor._id)}
                />
              </div>
            </MDBCol>
            <MDBCol sm="8" md="10" className="col-8">
              <div className="">
                <MDBRow className="middleVertical5">
                  <p
                    style={{ margin: "0rem", cursor: "pointer" }}
                    className="quickSand text-white"
                    onClick={() => viewProfile2(item.userTutor._id)}
                  >
                    {item.userTutor.screenName}
                  </p>

                  <div
                    className={
                      item.userTutor._id === user._id
                        ? "ml-auto mr-5"
                        : "d-none"
                    }
                  >
                    <MDBIcon
                      onClick={() => deleteComment({ commentId: item._id })}
                      style={{ cursor: "pointer" }}
                      className="text-danger"
                      icon="trash-alt"
                    />
                  </div>
                </MDBRow>
                <MDBRow>
                  <p style={{ marginBottom: "2px" }}>
                    {moment(item.date, "x").fromNow()}
                  </p>
                </MDBRow>
                <MDBRow>
                  <p
                    style={{ margin: "0rem" }}
                    className="text-white quickSand mb-1"
                  >
                    {item.text}
                  </p>
                </MDBRow>
                <MDBRow>
                  <p className="text-info font-weight-bold mr-2">{summation}</p>

                  <MDBIcon
                    style={{ cursor: "pointer" }}
                    className="mr-2 text-info"
                    far={likeFarFunc(item)}
                    icon="thumbs-up"
                    onClick={() => {
                      likeComment({ score: 1, commentId: item._id });
                    }}
                  />

                  <MDBIcon
                    style={{ cursor: "pointer" }}
                    className="text-info"
                    far={dislikeFarFunc(item)}
                    icon="thumbs-down "
                    onClick={() => {
                      likeComment({ score: -1, commentId: item._id });
                    }}
                  />
                </MDBRow>
              </div>
            </MDBCol>
          </MDBRow>
        );
      })}
    </Fragment>
  );
};

GameView.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    createComment,
    deleteComment,
    likeComment,
    setLoadingButtonComment
  })(GameView)
);
