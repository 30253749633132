import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBCard,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn
} from "mdbreact";
import GameData from "./GameData.js";

const Games = () => {
  const [formData, setFormData] = useState({
    bottomShow: false
  });

  const props2 = useSpring({
    opacity:
      formData.bottomShow === false
        ? 0
        : 1 || formData.bottomShow === true
        ? 1
        : 0,

    config: { duration: 600 }
  });

  const bottomShowFunc = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        bottomShow: !prevState.bottomShow
      };
    });
  };

  //test11
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Games</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className=" justify-content-center align-items-center mt-5 mb-5">
        <MDBRow className="pt-5 mt-3" center>
          <GameData />
        </MDBRow>
      </MDBContainer>
      {formData.bottomShow === false ? (
        <div className="removeUl hideBottom">
          <MDBBtn
            floating
            color="color-one"
            icon="info-circle"
            style={{ bottom: "24px", right: "24px" }}
            onClick={bottomShowFunc}
            size="sm"
            className="btnFixed"
          >
            <MDBIcon icon="info-circle"></MDBIcon>
          </MDBBtn>
        </div>
      ) : null}
      <animated.div style={props2}>
        {formData.bottomShow === true ? (
          <div className="float-wrap text-center z-depth-1-half">
            <MDBCard style={{ height: "16rem" }}>
              <MDBIcon
                onClick={bottomShowFunc}
                style={{ cursor: "pointer" }}
                size="2x"
                className="text-right mainColor bottomButton mr-1 mt-1"
                icon="window-close"
              />
              <MDBRow center>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Creating a game
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Creating a game"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/4JKneRkf0LA"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Modifying a game
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Modifying a game"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/U6Wxpnm8Cd8"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        ) : null}
      </animated.div>
    </Fragment>
  );
};

export default withRouter(connect(null, {})(Games));
