import { REMOVE_ALERT, ALERT_ERROR, ALERT_SUCCESS } from "./types";

export const setAlert = (msg, alertType) => dispatch => {
  if (alertType === "error") {
    dispatch({
      type: ALERT_ERROR,
      payload: { msg }
    });
  } else if (alertType === "success") {
    dispatch({
      type: ALERT_SUCCESS,
      payload: { msg }
    });
  }
  // I changed it from 5200 to 1
  setTimeout(() => dispatch({ type: REMOVE_ALERT }), 1);
};
