import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ACTIVATE_USER,
  RESEND_TOKEN,
  PASSWORD_UPDATE,
  RESET_PASSWORD,
  MESSAGE_ERROR,
  UPDATE_USER,
  DELETE_USER,
  SEND_CONTACT,
  TRADING_SAVE_DESCRIPTION,
  UPDATE_AVATAR,
  START_TRIAL,
  LATEST_CHAT_MSG,
  LATEST_PUBLICPAGE_MSG,
  UPDATE_MESSAGE_LIST,
  CONNECT_STRIPE_CHECKOUT,
  CREATE_CODES,
  GET_CREDENTIALS,
  SET_LOADING_BUTTON_REGISTRATION,
  NOTIFICATION_SEEN,
  DISCONNECT_STRIPE,
  PAYMENT_ERROR_DISCONNECT_STRIPE,
  WELCOME_MODAL_CLOSE,
  SET_LOADING_UPLOAD_AVATAR,
  SET_LOADING_DELETE_ACCOUNT,
  CREATE_BLOG,
  GET_BLOG,
  GET_BLOGS,
  RESET_BLOG_LOADER
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("topscholarusertoken"),
  isAuthenticated: null,
  loading: true,
  user: {
    _id: "",
    name: "",
    monthlyScores: [],
    score: "",
    description: "",
    screenName: "",
    avatar: {
      document_id: "",
      document_type: "",
      url: ""
    },
    active: null,
    stripe_user_id: "",
    subscribed: null,
    stripeStatus: "active",
    trialStart: "",
    trialEnd: "",
    freeTrialStart: null,
    defaultTimezone: "America/New_York",
    country: "",
    invoiceLink: "",
    current_period_start: "",
    current_period_end: "",
    welcomeModal: false,
    admin: false,
    previousSubscription: false
  },
  activate: null,
  resendToken: null,
  resetToken: null,
  updatePassword: null,
  error: {},
  updateUser: "",
  delete: "",
  contactForm: "",
  latestChatMsg: {
    msg: "",
    date: "",
    seen: "",
    studentId: ""
  },
  latestPublicpageMsg: {
    msg: "",
    date: "",
    seen: "",
    msgId: ""
  },
  latestNotificationMsg: {
    message: "",
    title: "",
    image: "",
    seen: ""
  },
  loadingUserTutor: true,
  createCode: "",
  createBlogVar: "",
  loadingButtonRegistration: false,
  loadingUpdateAvatar: false,
  loadingDeleteAccount: false,
  blog: {
    _id: "",
    text: "",
    image: "",
    url: "",
    headerText1: "",
    headerText2: "",
    headerText3: "",
    header1: "",
    header2: "",
    header3: ""
  },
  blogs: [],
  loadingBlog: true,
  loadingBlogList: true,
  initialPageNumber: 1,
  initialNumberOfPages: 1
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      // momentTimezone.tz.setDefault("America/Halifax");
      // if (payload.defaultTimezone) {
      //   momentTimezone.tz.setDefault(payload.defaultTimezone);
      // }
      const payload2 = {
        ...payload,
        defaultTimezone: payload.defaultTimezone
          ? payload.defaultTimezone
          : "America/New_York"
      };
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        loadingUserTutor: false,
        user: payload2,
        latestChatMsg: payload.latestChatMsg
          ? payload.latestChatMsg
          : {
              msg: "",
              date: "",
              seen: "",
              studentId: ""
            },
        latestPublicpageMsg: payload.latestPublicpageMsg
          ? payload.latestPublicpageMsg
          : {
              msg: "",
              date: "",
              seen: "",
              msgId: ""
            },
        latestNotificationMsg: payload.latestNotificationMsg
          ? payload.latestNotificationMsg
          : {
              message: "",
              image: "",
              seen: "",
              title: ""
            }
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        ...payload,

        loadingButtonRegistration: false
      };
    case LOGIN_SUCCESS:
      localStorage.removeItem("topscholarstudenttoken");
      localStorage.setItem("topscholarusertoken", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("topscholarusertoken");
      return {
        ...state,
        token: null,
        isAuthenticated: null,
        loading: false,
        user: {
          _id: "",
          name: "",
          monthlyScores: [],
          score: "",
          description: "",
          screenName: "",
          avatar: {
            document_id: "",
            document_type: "",
            url: ""
          },
          active: null,
          stripe_user_id: "",
          subscribed: null,
          stripeStatus: "active",
          trialStart: "",
          trialEnd: "",
          freeTrialStart: null,
          defaultTimezone: "America/New_York",
          country: "",
          invoiceLink: "",
          current_period_start: "",
          current_period_end: "",
          welcomeModal: false,
          admin: false,
          previousSubscription: false
        },
        activate: null,
        resendToken: null,
        resetToken: null,
        updatePassword: null,
        error: {},
        updateUser: "",
        delete: "",
        contactForm: "",
        latestChatMsg: {
          msg: "",
          date: "",
          seen: "",
          studentId: ""
        },
        latestPublicpageMsg: {
          msg: "",
          date: "",
          seen: "",
          msgId: ""
        },
        latestNotificationMsg: {
          message: "",
          title: "",
          image: "",
          seen: ""
        },
        loadingUserTutor: true,
        createCode: "",
        createBlogVar: "",
        loadingButtonRegistration: false,
        loadingUpdateAvatar: false,
        loadingDeleteAccount: false,
        blog: {
          _id: "",
          text: "",
          image: "",
          url: ""
        },
        blogs: [],
        loadingBlog: true
      };
    case ACTIVATE_USER:
      return {
        ...state,
        activate: payload,
        loading: false,
        user: {
          ...state.user,
          active: true
        }
      };
    case MESSAGE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        loadingButtonRegistration: false,
        loadingDeleteAccount: false,
        loadingBlog: false
      };
    case RESET_BLOG_LOADER:
      return {
        ...state,
        loadingBlog: payload
      };
    case RESEND_TOKEN:
      return {
        ...state,
        resendToken: payload,
        loading: false
      };
    case PASSWORD_UPDATE:
      return {
        ...state,
        updatePassword: payload,
        loading: false
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetToken: payload,
        loading: false
      };
    case NOTIFICATION_SEEN:
      return {
        ...state,
        latestNotificationMsg: payload.latestNotificationMsg
          ? payload.latestNotificationMsg
          : {
              message: "",
              image: "",
              seen: "",
              title: ""
            }
      };
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          name: payload.user.name,
          screenName: payload.user.screenName,
          defaultTimezone: payload.user.defaultTimezone,
          country: payload.user.country,
          email: payload.user.email
        }
      };
    case DELETE_USER:
      return {
        ...state,
        delete: payload,
        loadingDeleteAccount: false
      };
    case SEND_CONTACT:
      return {
        ...state,
        contactForm: payload
      };

    case TRADING_SAVE_DESCRIPTION:
      return {
        ...state,
        user: {
          ...state.user,
          description: payload.description
        }
      };
    case UPDATE_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: payload.avatar
        },
        loadingUpdateAvatar: false
      };
    case DISCONNECT_STRIPE:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload
        }
      };
    case START_TRIAL:
      return {
        ...state,
        user: {
          ...state.user,
          trialEnd: payload.trialEnd,
          trialStart: payload.trialStart,
          freeTrialStart: true,
          screenName: payload.screenName,
          defaultTimezone: payload.defaultTimezone
        }
      };
    case LATEST_CHAT_MSG:
      return {
        ...state,
        latestChatMsg: payload
      };
    case LATEST_PUBLICPAGE_MSG:
      return {
        ...state,
        latestPublicpageMsg: payload
      };
    case UPDATE_MESSAGE_LIST:
      return {
        ...state,
        latestPublicpageMsg: payload.latestPublicpageMsg
          ? payload.latestPublicpageMsg
          : state.latestPublicpageMsg
      };
    case CONNECT_STRIPE_CHECKOUT:
      return {
        ...state,
        user: {
          ...state.user,
          stripeCustomerId: payload.stripeCustomerId
        }
      };
    case CREATE_CODES:
      return {
        ...state,
        createCode: payload.codes
      };
    case CREATE_BLOG:
      return {
        ...state,
        createBlogVar: payload.blog
      };
    case GET_CREDENTIALS:
      return {
        ...state,
        user: {
          ...state.user,
          stripe_user_id: payload.stripe_user_id
        }
      };
    case SET_LOADING_BUTTON_REGISTRATION:
      return {
        ...state,
        loadingButtonRegistration: payload
      };
    case PAYMENT_ERROR_DISCONNECT_STRIPE:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user
        }
      };
    case WELCOME_MODAL_CLOSE:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user
        }
      };
    case SET_LOADING_UPLOAD_AVATAR:
      return {
        ...state,
        loadingUpdateAvatar: payload
      };
    case SET_LOADING_DELETE_ACCOUNT:
      return {
        ...state,
        loadingDeleteAccount: payload
      };
    case GET_BLOG:
      return {
        ...state,
        blog: payload,
        loadingBlog: false
      };
    case GET_BLOGS:
      return {
        ...state,
        blogs: payload.blogs,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber,
        loadingBlogList: false
      };

    default:
      return state;
  }
}
