import React, { Fragment, useEffect, useState } from "react";
import io from "socket.io-client";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./canvas.css";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

import { Stage, Layer, Line } from "react-konva";
import { HuePicker } from "react-color";

import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBModal,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBBtnFixedItem,
  MDBBtnFixed,
  MDBCardHeader
} from "mdbreact";

import {
  makeCanvas,
  getCanvasList,
  updateCanvas,
  deleteCanvas
} from "../../../actions/canvas";

const url = io("/canvas/student", {
  transports: ["websocket"]
});

const CanvasDash = ({ students: { user } }) => {
  const [formData, setFormData] = useState({
    draws: [],
    canvasList: [],
    _id: "",
    historyRedo: [],
    historyClear: [],
    points: {
      coordinates: [],
      color: "",
      mode: "",
      strokewidth: ""
    },

    newCanvas: true,
    color: "#9c45eb",
    huepicker: true,
    mode: "brush",
    strokewidth: 5,
    // end of canvas
    name: "",
    openNewCanvas: false,
    buttonStyle: {
      transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
      opacity: "0"
    },
    stageWidth: window.innerWidth,
    stageHeight: window.innerHeight
  });

  const [formData2, setFormData2] = useState({
    points2: {
      coordinates: [],
      color: "",
      mode: "",
      strokewidth: ""
    }
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      if (user._id) {
        url.emit("askServer", user._id);
      }
    }
  }, [user]);

  // Canvas functionality
  const handleMouseDown = e => {
    if (e.type === "touchstart") {
      setTimeout(function() {
        if (e.evt.touches.length > 1) {
          return true;
        } else if (e.evt.changedTouches.length === 1) {
          e.evt.preventDefault();
          e.evt.stopPropagation();
          if (formData.points.coordinates.length === 0) {
            const { x, y } = e.target.getStage().getPointerPosition();
            setFormData({
              ...formData,
              points: {
                coordinates: [...formData.points.coordinates, x, y],
                mode: formData.mode,
                color: formData.color,
                strokewidth: formData.strokewidth,
                new: true
              }
            });
          }
        }
      }, 0);
    } else {
      if (formData.points.coordinates.length === 0) {
        const { x, y } = e.target.getStage().getPointerPosition();
        setFormData({
          ...formData,
          points: {
            coordinates: [...formData.points.coordinates, x, y],
            mode: formData.mode,
            color: formData.color,
            strokewidth: formData.strokewidth,
            new: true
          }
        });
      }
    }
  };
  const handleMouseMove = e => {
    if (e.type === "touchmove") {
      if (e.evt.touches.length > 1) {
        return true;
      } else if (e.evt.changedTouches.length === 1) {
        e.evt.preventDefault();
        e.evt.stopPropagation();
        if (formData.points.coordinates.length !== 0) {
          const { x, y } = e.target.getStage().getPointerPosition();

          setFormData({
            ...formData,
            points: {
              ...formData.points,
              coordinates: [...formData.points.coordinates, x, y]
            }
          });
        }
      }
    } else {
      if (formData.points.coordinates.length !== 0) {
        const { x, y } = e.target.getStage().getPointerPosition();

        setFormData({
          ...formData,
          points: {
            ...formData.points,
            coordinates: [...formData.points.coordinates, x, y]
          }
        });
      }
    }
  };

  const handleMouseUp = async e => {
    if (e.type === "touchend") {
      if (e.evt.touches.length > 1) {
        return true;
      } else if (e.evt.changedTouches.length === 1) {
        const scale = formData.stageWidth / 1000;
        const newArray2 = formData.points.coordinates.map((item2, index2) => {
          return item2 / scale;
        });
        await url.emit("updateDraws", {
          points: {
            color: formData.points.color,
            coordinates: newArray2,
            mode: formData.points.mode,
            strokewidth: formData.points.strokewidth
          },
          studentId: user._id
        });
        setFormData({
          ...formData,
          draws: [...formData.draws, formData.points],
          points: {
            coordinates: [],
            color: "",
            mode: "",
            strokewidth: ""
          },
          historyClear: []
        });
      }
    } else {
      const scale = formData.stageWidth / 1000;
      const newArray2 = formData.points.coordinates.map((item2, index2) => {
        return item2 / scale;
      });
      await url.emit("updateDraws", {
        points: {
          color: formData.points.color,
          coordinates: newArray2,
          mode: formData.points.mode,
          strokewidth: formData.points.strokewidth
        },
        studentId: user._id
      });
      setFormData({
        ...formData,
        draws: [...formData.draws, formData.points],
        points: {
          coordinates: [],
          color: "",
          mode: "",
          strokewidth: ""
        },
        historyClear: []
      });
    }
  };

  const mouseout = () => {
    if (formData.points.coordinates.length !== 0) {
      url.emit("updateDraws", {
        points: { ...formData.points, new: true },
        studentId: user._id
      });
      setFormData({
        ...formData,
        draws: [...formData.draws, formData.points],
        points: {
          coordinates: [],
          color: "",
          mode: "",
          strokewidth: ""
        },
        historyClear: []
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mouseout", mouseout);
    return () => {
      document.removeEventListener("mouseout", mouseout);
    };
  });

  const handleChange = (color, event) => {
    setFormData({ ...formData, color: color.hex });
  };

  const toggleEraser = () => {
    setFormData({
      ...formData,
      mode: formData.mode === "eraser" ? "brush" : "eraser",
      strokewidth: formData.mode === "brush" ? 17 : 5
    });
  };

  const handleUndo = () => {
    if (formData.historyClear.length !== 0) {
      url.emit("undoServer", { studentId: user._id });
      setFormData({
        ...formData,
        draws: formData.historyClear,
        historyClear: []
      });
    } else {
      url.emit("undoServer", { studentId: user._id });
      setFormData({
        ...formData,
        draws: formData.draws.slice(0, -1),
        historyRedo: [
          ...formData.historyRedo,
          formData.draws[formData.draws.length - 1]
        ]
      });
    }
  };
  const handleRedo = () => {
    url.emit("redoServer", { studentId: user._id });
    setFormData({
      ...formData,
      draws: [
        ...formData.draws,
        formData.historyRedo[formData.historyRedo.length - 1]
      ],
      historyRedo: formData.historyRedo.slice(0, -1)
    });
  };

  const clear = () => {
    url.emit("clearServer", { studentId: user._id });
    setFormData({
      ...formData,
      draws: [],
      historyClear: formData.draws,
      historyRedo: []
    });
  };

  const closeOpenCanvas = () => {
    setFormData({
      ...formData,
      openNewCanvas: false,
      draws: [],
      historyRedo: [],
      historyClear: [],
      points: {
        coordinates: [],
        color: "",
        mode: "",
        strokewidth: ""
      },
      color: "#9c45eb",
      huepicker: false,
      mode: "brush",
      name: "",
      _id: ""
    });
  };

  // Show Canvas

  const modalShowCanvas = item => {
    setFormData({
      ...formData,
      _id: item._id,
      openNewCanvas: true,
      name: item.name
    });

    url.emit("joinRoomStudent", { canvasId: item._id });
  };

  const onHover = () => {
    setFormData({
      ...formData,
      buttonStyle: {
        transform: "scaleY(1) scaleX(1) translateY(0) translateX(0)",
        opacity: "1"
      }
    });
  };

  const onMouseLeave = () => {
    setFormData({
      ...formData,
      buttonStyle: {
        transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
        opacity: "0"
      }
    });
  };

  const joinRoom = dataCanvas => {
    const filter = dataCanvas.studentIds.filter(item => item === user._id);

    if (filter.length !== 0) {
      setFormData({
        ...formData,
        canvasList: [{ name: dataCanvas.canvasName, _id: dataCanvas.canvasId }]
      });
    }
  };

  useEffect(() => {
    url.on("invite", joinRoom);

    return () => {
      url.off("invite", joinRoom);
    };
  });
  const dataCanvas = dataCanvas => {
    setFormData({
      ...formData,
      draws: dataCanvas
    });
  };

  useEffect(() => {
    url.on("draws", dataCanvas);

    return () => {
      url.off("draws", dataCanvas);
    };
  });

  const updateCanvasDraws = dataCanvas => {
    if (dataCanvas.studentId !== user._id) {
      setFormData({
        ...formData,
        draws: [
          ...formData.draws,
          { ...dataCanvas.points, coordinates: dataCanvas.points.coordinates }
        ]
      });
    }
  };

  useEffect(() => {
    url.on("receiveDraws", updateCanvasDraws);

    return () => {
      url.off("receiveDraws", updateCanvasDraws);
    };
  });

  const clearClient = dataCanvas => {
    if (dataCanvas.studentId !== user._id) {
      setFormData({
        ...formData,
        historyClear: formData.draws,
        draws: [],
        historyRedo: []
      });
    }
  };

  useEffect(() => {
    url.on("clearClient", clearClient);

    return () => {
      url.off("clearClient", clearClient);
    };
  });

  const undoClient = dataCanvas => {
    if (dataCanvas.studentId !== user._id) {
      if (formData.historyClear.length !== 0) {
        setFormData({
          ...formData,
          draws: formData.historyClear,
          historyClear: []
        });
      } else {
        setFormData({
          ...formData,
          draws: formData.draws.slice(0, -1),
          historyRedo: [
            ...formData.historyRedo,
            formData.draws[formData.draws.length - 1]
          ]
        });
      }
    }
  };

  useEffect(() => {
    url.on("undoClient", undoClient);

    return () => {
      url.off("undoClient", undoClient);
    };
  });

  const redoClient = dataCanvas => {
    if (dataCanvas.studentId !== user._id) {
      setFormData({
        ...formData,
        draws: [
          ...formData.draws,
          formData.historyRedo[formData.historyRedo.length - 1]
        ],
        historyRedo: formData.historyRedo.slice(0, -1)
      });
    }
  };

  useEffect(() => {
    url.on("redoClient", redoClient);

    return () => {
      url.off("redoClient", redoClient);
    };
  });

  useEffect(() => {
    if (user) {
      const scale = formData.stageWidth / 1000;
      const newArray2 = formData.points.coordinates.map((item2, index2) => {
        return item2 / scale;
      });

      url.emit("pointsServer", {
        points: {
          color: formData.points.color,
          coordinates: newArray2,
          mode: formData.points.mode,
          strokewidth: formData.points.strokewidth
        },
        studentId: user._id
      });
    }
  }, [formData.points, formData.stageWidth, user]);

  const pointsClient = dataCanvas => {
    if (dataCanvas.studentId !== user._id) {
      setFormData2({
        ...formData2,
        points2: dataCanvas.points
      });
    }
  };

  const points2Scale = () => {
    const scale = formData.stageWidth / 1000;

    const newArray = formData2.points2.coordinates.map((item2, index2) => {
      return item2 * scale;
    });

    return newArray;
  };

  useEffect(() => {
    url.on("pointsClient", pointsClient);

    return () => {
      url.off("pointsClient", pointsClient);
    };
  });
  const endShareClient = dataCanvas => {
    setFormData({
      ...formData,
      canvasList: [],
      openNewCanvas: false,
      draws: [],
      historyRedo: [],
      historyClear: [],
      points: {
        coordinates: [],
        color: "",
        mode: "",
        strokewidth: ""
      },
      color: "#9c45eb",
      huepicker: false,
      mode: "brush",
      name: "",
      _id: ""
    });
  };

  useEffect(() => {
    url.on("endShareClient", endShareClient);

    return () => {
      url.off("endShareClient", endShareClient);
    };
  });

  useEffect(() => {
    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  });

  const checkSize = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    const newArray = formData.draws.map((item, index) => {
      const scale = formData.stageWidth / 1000;
      const newArray2 = item.coordinates.map((item2, index2) => {
        if (item.new) {
          return item2 / scale;
        } else {
          return item2;
        }
      });
      return {
        color: item.color,
        coordinates: newArray2,
        mode: item.mode,
        strokewidth: item.strokewidth
      };
    });

    setFormData({
      ...formData,
      stageWidth: width,
      stageHeight: height,
      draws: newArray
    });
  };

  useEffect(() => {
    if (formData.openNewCanvas === true) {
      document.body.style.cssText = "overflow-y: hidden !important;";
    } else {
      document.body.style.overflowY = "";
    }
  }, [formData.openNewCanvas]);

  return user.view && user.view.whiteboard === false ? (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student Whiteboard</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className=" justify-content-center align-items-center mt-5 mb-5">
        <MDBRow className="pt-5 mt-3" center>
          <MDBCard narrow>
            <MDBCardHeader className="view view-cascade  primary-color d-flex justify-content-between align-items-center py-2 mx-4 mb-1 ">
              <MDBCol className="text-center">
                <p className="h5 quickSand white-text mx-2">
                  {t("studentDashboard.13")}
                </p>
              </MDBCol>
            </MDBCardHeader>
            <MDBCardBody cascade>
              <p className="quickSand black-text h5">{t("studentLessons.2")}</p>
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  ) : (
    <Fragment>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow className="mt-3 " center>
          <MDBCol md="12">
            <MDBCard className="mt-5 bgcc">
              <MDBRow center className="mt-4 mx-1">
                {formData.canvasList.length === 0 && (
                  <p className="text-white text-center quickSand">
                    {t("studentCanvas.1")}
                  </p>
                )}
                {formData.canvasList.map((item, index) => {
                  return (
                    <MDBCol
                      key={index}
                      md="auto"
                      lg="auto"
                      sm="auto"
                      className=" mt-3  mx-md-0 col-auto mx-1"
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => modalShowCanvas(item)}
                      >
                        <MDBCard className=" z-depth-1  my-auto">
                          <MDBCardBody>
                            <p className="CarterOne h4-responsive text-center text-pink  my-auto">
                              {item.name.charAt(0).toUpperCase() +
                                item.name.slice(1)}
                            </p>
                          </MDBCardBody>
                        </MDBCard>
                      </div>
                    </MDBCol>
                  );
                })}
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {/* Canvas Main Modal */}
      <div className="canvasModal">
        <MDBContainer>
          <MDBModal
            isOpen={formData.openNewCanvas}
            toggle
            className="modal-full"
          >
            <div className="modal-full modal-content ">
              <MDBRow center>
                <MDBCol>
                  <div style={{ backgroundColor: "white" }}>
                    {/* <MDBScrollbar className="scrollHeight"> */}
                    <Stage
                      className={
                        formData.mode === "brush" ? "custom-cur" : "eraser-cur"
                      }
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                      onTouchStart={handleMouseDown}
                      onTouchMove={handleMouseMove}
                      onTouchEnd={handleMouseUp}
                      width={formData.stageWidth}
                      height={formData.stageWidth}
                    >
                      <Layer>
                        {formData.draws.map((item, index) => {
                          const scale = formData.stageWidth / 1000;

                          const newArray = item.coordinates.map(
                            (item2, index2) => {
                              if (item.new) {
                                return item2;
                              } else {
                                return item2 * scale;
                              }
                            }
                          );
                          return (
                            <Line
                              key={index}
                              points={newArray}
                              strokeWidth={item.strokewidth}
                              fill={item.color}
                              stroke={item.color}
                              globalCompositeOperation={
                                item.mode === "brush"
                                  ? "source-over"
                                  : "destination-out"
                              }
                            />
                          );
                        })}
                        <Line
                          points={formData.points.coordinates}
                          fill={formData.color}
                          stroke={formData.color}
                          strokeWidth={formData.strokewidth}
                          globalCompositeOperation={
                            formData.mode === "brush"
                              ? "source-over"
                              : "destination-out"
                          }
                        />
                        <Line
                          points={points2Scale()}
                          fill={formData2.points2.color}
                          stroke={formData2.points2.color}
                          strokeWidth={formData2.points2.strokewidth}
                          globalCompositeOperation={
                            formData2.points2.mode === "brush"
                              ? "source-over"
                              : "destination-out"
                          }
                        />
                      </Layer>
                    </Stage>
                    {/* </MDBScrollbar> */}
                  </div>
                </MDBCol>
              </MDBRow>
              {/* float buttons */}
              <MDBBtnFixed
                onMouseMove={onHover}
                onMouseLeave={onMouseLeave}
                floating
                color="green darken-1"
                icon="expand-arrows-alt"
                style={{ bottom: "100px", right: "24px" }}
              >
                <MDBBtnFixedItem
                  buttonStyle={formData.buttonStyle}
                  color="blue"
                  icon="file"
                  onClick={clear}
                />
                <MDBBtnFixedItem
                  buttonStyle={formData.buttonStyle}
                  color="green"
                  icon={formData.mode === "brush" ? "eraser" : "paint-brush"}
                  onClick={toggleEraser}
                />
                <MDBBtnFixedItem
                  buttonStyle={formData.buttonStyle}
                  color={
                    formData.historyRedo.length === 0
                      ? "deep-purple lighten-3"
                      : "deep-purple"
                  }
                  icon="redo"
                  onClick={handleRedo}
                  disabled={formData.historyRedo.length === 0 ? true : false}
                />
                <MDBBtnFixedItem
                  buttonStyle={formData.buttonStyle}
                  color={
                    formData.draws.length === 0 &&
                    formData.historyClear.length === 0
                      ? "red lighten-3"
                      : "red"
                  }
                  icon="undo"
                  onClick={handleUndo}
                  disabled={
                    formData.draws.length === 0 &&
                    formData.historyClear.length === 0
                      ? true
                      : false
                  }
                />
              </MDBBtnFixed>
              <div className="topcorner">
                <HuePicker
                  color={formData.color}
                  onChange={handleChange}
                ></HuePicker>
              </div>
              <MDBModalFooter className="green darken-1 ">
                <MDBBtn color="blue" onClick={closeOpenCanvas}>
                  {t("studentCanvas.2")}
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </MDBContainer>
        {/* Modal Confirmation delete */}
      </div>
    </Fragment>
  );
};

CanvasDash.propTypes = {};

const mapStateToProps = state => ({
  canvas: state.canvas,
  students: state.students
});

export default withRouter(
  connect(mapStateToProps, {
    makeCanvas,
    getCanvasList,
    updateCanvas,
    deleteCanvas
  })(CanvasDash)
);
