export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const SET_LOADING_BUTTON_REGISTRATION =
  "SET_LOADING_BUTTON_REGISTRATION";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_LESSONS = "GET_LESSONS";
export const GET_LESSONS_ALL = "GET_LESSONS_ALL";
export const GET_LESSONS_PUSH = "GET_LESSONS_PUSH";
export const GET_LESSON = "GET_LESSON";
export const CLEAR_LESSONS = "CLEAR_LESSONS";
export const LESSONS_ERROR = "LESSONS_ERROR";
export const LESSONS_DELETE = "LESSONS_DELETE";
export const ADD_LESSONS = "ADD_LESSONS";
export const SEARCH_LESSONS = "SEARCH_LESSONS";
export const SET_PAGINATION_MODE_LESSONS = "SET_PAGINATION_MODE_LESSONS";
export const SET_LOADING_BUTTON_LESSONS = "SET_LOADING_BUTTON_LESSONS";
export const CLEAR_LESSONS_SEARCH = "CLEAR_LESSONS_SEARCH";
export const SEARCH_TUTOR = "SEARCH_TUTOR";
export const SET_PAGINATION_MODE_TUTOR = "SET_PAGINATION_MODE_TUTOR";
export const CLEAR_TUTOR_SEARCH = "CLEAR_TUTOR_SEARCH";
export const GET_CLASSES = "GET_CLASSES";
export const SEARCH_TOPICS = "SEARCH_TOPICS";
export const SET_PAGINATION_MODE_TOPICS = "SET_PAGINATION_MODE_TOPICS";
export const SET_LOADING_BUTTON_TOPICS = "SET_LOADING_BUTTON_TOPICS";
export const SET_LOADING_BUTTON_REMINDERS = "SET_LOADING_BUTTON_REMINDERS";
export const CLEAR_TOPICS_SEARCH = "CLEAR_TOPICS_SEARCH";
export const ADD_TOPIC = "ADD_TOPIC";
export const REMOVE_TOPIC = "REMOVE_TOPIC";
export const TOPICS_ERROR = "TOPICS_ERROR";
export const GET_TOPICS = "GET_TOPICS";
export const GET_TOPIC = "GET_TOPIC";
export const TOPICS_DELETE = "TOPICS_DELETE";
export const UPDATE_LESSON = "UPDATE_LESSON";
export const UPDATE_TOPIC = "UPDATE_TOPIC";
export const ADD_STUDENT = "ADD_STUDENT";
export const STUDENT_ERROR = "STUDENT_ERROR";
export const ADD_GROUP = "ADD_GROUP";
export const GROUP_ERROR = "GROUP_ERROR";
export const GET_STUDENTS = "GET_STUDENTS";
export const GET_STUDENTS_SELECT = "GET_STUDENTS_SELECT";
export const SEARCH_STUDENTS = "SEARCH_STUDENTS";
export const SET_PAGINATION_MODE_STUDENTS = "SET_PAGINATION_MODE_STUDENTS";
export const SET_LOADING_BUTTON_STUDENTS = "SET_LOADING_BUTTON_STUDENTS";
export const SET_LOADING_BUTTON_DELETE_STUDENT =
  "SET_LOADING_BUTTON_DELETE_STUDENT";
export const CLEAR_STUDENTS_SEARCH = "CLEAR_STUDENTS_SEARCH";
export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_SELECT = "GET_GROUPS_SELECT";
export const GET_STUDENT = "GET_STUDENT";
export const GET_GROUP = "GET_GROUP";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const SEARCH_GROUPS = "SEARCH_GROUPS";
export const CLEAR_GROUPS_SEARCH = "CLEAR_GROUPS_SEARCH";
export const SET_PAGINATION_MODE_GROUPS = "SET_PAGINATION_MODE_GROUPS";
export const SET_LOADING_BUTTON_GROUPS = "SET_LOADING_BUTTON_GROUPS";
export const STUDENTS_DELETE = "STUDENTS_DELETE";
export const GROUPS_DELETE = "GROUPS_DELETE";
export const GET_STUDENTEXAMEVALUATIONS = "GET_STUDENTEXAMEVALUATIONS";
export const GET_STUDENTHOMEWORKEVALUATIONS = "GET_STUDENTHOMEWORKEVALUATIONS";
export const SET_LOADING_BUTTON_EVALUATIONS = "SET_LOADING_BUTTON_EVALUATIONS";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const CREATE_PAYMENTINTENT = "CREATE_PAYMENTINTENT";
export const ACCESS = "ACCESS";
export const GET_CARDS = "GET_CARDS";
export const GET_CARD = "GET_CARD";
export const UPDATE_CARD = "UPDATE_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const MAKE_CARDDEFAULT = "MAKE_CARDDEFAULT";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const RESEND_TOKEN = "RESEND_TOKEN";
export const PASSWORD_UPDATE = "PASSWORD_UPDATE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const MESSAGE_ERROR = "MESSAGE_ERROR";
export const GET_REMINDERS = "GET_REMINDERS";
export const GET_REMINDER = "GET_REMINDER";
export const UPDATE_REMINDER = "UPDATE_REMINDER";
export const DELETE_REMINDER = "DELETE_REMINDER";
export const REMINDER_ERROR = "REMINDER_ERROR";
export const CREATE_REMINDER = "CREATE_REMINDER";
export const DOCUMENT_DELETE = "DOCUMENT_DELETE";
export const UPLOAD_FALSE = "UPLOAD_FALSE";
export const UPLOAD_FALSELESSON = "UPLOAD_FALSELESSON";
export const CLEAR_STUDENT_MESSAGE = "CLEAR_STUDENT_MESSAGE";
export const CLEAR_EXAM_MESSAGE = "CLEAR_EXAM_MESSAGE";
export const CLEAR_HOMEWORK_MESSAGE = "CLEAR_HOMEWORK_MESSAGE";
export const UPDATE_USER = "UPDATE_USER";
export const START_TRIAL = "START_TRIAL";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const DELETE_USER = "DELETE_USER";
export const SEND_CONTACT = "SEND_CONTACT";
export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_ERROR = "ALERT_ERROR";
export const STUDENT_LOGIN_SUCCESS = "STUDENT_LOGIN_SUCCESS";
export const STUDENT_LOGIN_FAIL = "STUDENT_LOGIN_FAIL";
export const STUDENT_AUTH_ERROR = "STUDENT_AUTH_ERROR";
export const STUDENT_LOGOUT = "STUDENT_LOGOUT";
export const STUDENT_REGISTER_FAIL = "STUDENT_REGISTER_FAIL";
export const STUDENT_USER_LOADED = "STUDENT_USER_LOADED";
export const GET_STUDENT_LESSONS = "GET_STUDENT_LESSONS";
export const STUDENT_RESET_PASSWORD = "STUDENT_RESET_PASSWORD";
export const STUDENT_PASSWORD_UPDATE = "STUDENT_PASSWORD_UPDATE";
export const UPDATE_PREFERENCES = "UPDATE_PREFERENCES";
export const TOPSCHOLARCONNECT = "TOPSCHOLARCONNECT";
export const GET_SEEN = "GET_SEEN";
export const GET_SEENSTUDENT = "GET_SEENSTUDENT";
export const MAKE_DECK = "MAKE_DECK";
export const DECK_ERROR = "DECK_ERROR";
export const GET_DECKS = "GET_DECKS";
export const SEARCH_DECKS = "SEARCH_DECKS";
export const SEARCH_CARDS = "SEARCH_CARDS";
export const CLEAR_DECKS_SEARCH = "CLEAR_DECKS_SEARCH";
export const SET_LOADING_BUTTON_DECKS = "SET_LOADING_BUTTON_DECKS";
export const CLEAR_CARDS_SEARCH = "CLEAR_CARDS_SEARCH";
export const UPDATE_DECK = "UPDATE_DECK";
export const DELETE_DECK = "DELETE_DECK";
export const SET_PAGINATION_MODE_DECKS = "SET_PAGINATION_MODE_DECKS";
export const SET_PAGINATION_MODE_CARDS = "SET_PAGINATION_MODE_CARDS";
export const SET_LOADING_BUTTON_CARDS = "SET_LOADING_BUTTON_CARDS";
export const MAKE_CARD = "MAKE_CARD";
export const UPDATE_DECK_CARDS = "UPDATE_DECK_CARDS";
export const UPDATE_FLASHCARD = "UPDATE_FLASHCARD";
export const UPDATE_SPACED = "UPDATE_SPACED";
export const MAKE_DECK_STUDENT = "MAKE_DECK_STUDENT";
export const SEARCH_DECKS_STUDENTS = "SEARCH_DECKS_STUDENTS";
export const CLEAR_DECKS_SEARCH_STUDENTS = "CLEAR_DECKS_SEARCH_STUDENTS";
export const SET_PAGINATION_MODE_DECKS_STUDENTS =
  "SET_PAGINATION_MODE_DECKS_STUDENTS";
export const DECK_ERROR_STUDENT = "DECK_ERROR_STUDENT";
export const GET_DECKS_STUDENT = "GET_DECKS_STUDENT";
export const GET_CARDS_STUDENT = "GET_CARDS_STUDENT";
export const SEARCH_CARDS_STUDENTS = "SEARCH_CARDS_STUDENTS";
export const CLEAR_CARDS_SEARCH_STUDENTS = "CLEAR_CARDS_SEARCH_STUDENTS";
export const SET_PAGINATION_MODE_CARDS_STUDENTS =
  "SET_PAGINATION_MODE_CARDS_STUDENTS";
export const SET_LOADING_BUTTON_DECKS_STUDENTS =
  "SET_LOADING_BUTTON_DECKS_STUDENTS";
export const SET_LOADING_BUTTON_CARDS_STUDENTS =
  "SET_LOADING_BUTTON_CARDS_STUDENTS";
export const UPDATE_DECK_STUDENT = "UPDATE_DECK_STUDENT";
export const UPDATE_CARD_STUDENT = "UPDATE_CARD_STUDENT";
export const DELETE_DECK_STUDENT = "DELETE_DECK_STUDENT";
export const MAKE_CARD_STUDENT = "MAKE_CARD_STUDENT";
export const UPDATE_DECK_CARDS_STUDENT = "UPDATE_DECK_CARDS_STUDENT";
export const UPDATE_SPACED_STUDENT = "UPDATE_SPACED_STUDENT";
export const DELETE_CANVAS = "DELETE_CANVAS";
export const CANVAS_ERROR = "CANVAS_ERROR";
export const MAKE_CANVAS = "MAKE_CANVAS";
export const GET_CANVASLIST = "GET_CANVASLIST";
export const UPDATE_CANVAS = "UPDATE_CANVAS";
export const SEARCH_CANVAS = "SEARCH_CANVAS";
export const CLEAR_CANVAS_SEARCH = "CLEAR_CANVAS_SEARCH";
export const SET_LOADING_BUTTON_CANVAS = "SET_LOADING_BUTTON_CANVAS";
export const SET_PAGINATION_MODE_CANVAS = "SET_PAGINATION_MODE_CANVAS";
export const DELETE_CARD_STUDENT = "DELETE_CARD_STUDENT";
export const GET_FLASHCARDS = "GET_FLASHCARDS";
export const DELETE_FLASHCARD = "DELETE_FLASHCARD";
export const SHARE_DECK = "SHARE_DECK";
export const GET_SHARED_DECKS_STUDENT = "GET_SHARED_DECKS_STUDENT";
export const CUSTOMIZABLES_ERROR = "CUSTOMIZABLES_ERROR";
export const MAKE_CUSTOMIZABLE = "MAKE_CUSTOMIZABLE";
export const GET_CUS_EXAM_LIST = "GET_CUS_EXAM_LIST";
export const UPDATE_CUSTOMIZABLE = "UPDATE_CUSTOMIZABLE";
export const DELETE_CUSTOMIZABLE = "DELETE_CUSTOMIZABLE";
export const SEARCH_CUSTOMIZABLES = "SEARCH_CUSTOMIZABLES";
export const SET_PAGINATION_MODE_CUSTOMIZABLES =
  "SET_PAGINATION_MODE_CUSTOMIZABLES";
export const CLEAR_CUSTOMIZABLES_SEARCH = "CLEAR_CUSTOMIZABLES_SEARCH";
export const SET_LOADING_BUTTON_CUSTOMIZABLES =
  "SET_LOADING_BUTTON_CUSTOMIZABLES";
export const SET_LOADING_BUTTON_DELETE_CUSTOMIZABLES =
  "SET_LOADING_BUTTON_DELETE_CUSTOMIZABLES";
export const SET_LOADING_BUTTON_CUSTOMIZABLES_EVAL =
  "SET_LOADING_BUTTON_CUSTOMIZABLES_EVAL";
export const GET_CUSEXAM_STUDENT = "GET_CUSEXAM_STUDENT";
export const MAKE_CUSEXAM_EVAL = "MAKE_CUSEXAM_EVAL";
export const GET_CUSEXAM_EVAL = "GET_CUSEXAM_EVAL";
export const UPDATE_CUSEXAM_EVAL = "UPDATE_CUSEXAM_EVAL";
export const GET_CUS_EXAM_EVALUATIONS = "GET_CUS_EXAM_EVALUATIONS";
export const UPDATE_CUSEXAM_EVAL_TEACHER = "UPDATE_CUSEXAM_EVAL_TEACHER";
export const DELETE_CUSTOMIZABLE_EVAL = "DELETE_CUSTOMIZABLE_EVAL";
export const GET_GAMES = "GET_GAMES";
export const GAMES_DELETE = "GAMES_DELETE";
export const GAMES_ERROR = "GAMES_ERROR";
export const UPDATE_GAME = "UPDATE_GAME";
export const ADD_GAMES = "ADD_GAMES";
export const SEARCH_GAMES = "SEARCH_GAMES";
export const SET_PAGINATION_MODE_GAMES = "SET_PAGINATION_MODE_GAMES";
export const SET_LOADING_BUTTON_GAMES = "SET_LOADING_BUTTON_GAMES";
export const CLEAR_GAMES_SEARCH = "CLEAR_GAMES_SEARCH";
export const TOPIC_PUSH = "TOPIC_PUSH";
export const CLEAR_LESSON = "CLEAR_LESSON";
export const GET_ALL_CUS_EVALS = " GET_ALL_CUS_EVALS";
export const MAKE_EVALUATION = " MAKE_EVALUATION";
export const EVALUATION_ERROR = " EVALUATION_ERROR";
export const GET_EVALUATIONS = " GET_EVALUATIONS";
export const DELETE_EVALUATION = " DELETE_EVALUATION";
export const UPDATE_EVALUATION = " UPDATE_EVALUATION";
export const CLEAR_CANVAS = " CLEAR_CANVAS";
export const CLEAR_FLASHCARD = " CLEAR_FLASHCARD";
export const CLEAR_CUSTOMIZABLES = " CLEAR_CUSTOMIZABLES";
export const CLEAR_GAMES = " CLEAR_GAMES";
export const CLEAR_STUDENT = " CLEAR_STUDENT";
export const TRADING_GET_LESSONS = " TRADING_GET_LESSONS";
export const TRADING_ERROR = " TRADING_ERROR";
export const TRADING_GET_TOPICS = " TRADING_GET_TOPICS";
export const TRADING_GET_EXAMS = " TRADING_GET_EXAMS";
export const TRADING_GET_GAMES = " TRADING_GET_GAMES";
export const TRADING_GET_DECKS = " TRADING_GET_DECKS";
export const PUBLISH_LESSON_O = " PUBLISH_LESSON_O";
export const TRADING_GET_PUBLISHED_LESSONS = " TRADING_GET_PUBLISHED_LESSONS";
export const TRADING_GET_PUBLISHED_LESSON = " TRADING_GET_PUBLISHED_LESSON";
export const PUBLISH_TOPIC_O = " PUBLISH_TOPIC_O";
export const PUBLISH_EXAM_O = " PUBLISH_EXAM_O";
export const PUBLISH_GAME_O = " PUBLISH_GAME_O";
export const PUBLISH_DECK_O = " PUBLISH_DECK_O";
export const SET_LOADING_BUTTON_PUBLISH = "SET_LOADING_BUTTON_PUBLISH";
export const TRADING_GET_PUBLISHED_TOPICS = " TRADING_GET_PUBLISHED_TOPICS";
export const TRADING_GET_PUBLISHED_EXAMS = " TRADING_GET_PUBLISHED_EXAMS";
export const TRADING_GET_PUBLISHED_GAMES = " TRADING_GET_PUBLISHED_GAMES";
export const TRADING_GET_PUBLISHED_DECKS = " TRADING_GET_PUBLISHED_DECKS";
export const TRADING_GET_PUBLISHED_DECK = " TRADING_GET_PUBLISHED_DECK";
export const TRADING_GET_PUBLISHED_TOPIC = " TRADING_GET_PUBLISHED_TOPIC";
export const TRADING_GET_PUBLISHED_EXAM = " TRADING_GET_PUBLISHED_EXAM";
export const TRADING_GET_PUBLISHED_GAME = " TRADING_GET_PUBLISHED_GAME";
export const TRADING_LESSON_SEARCH = " TRADING_LESSON_SEARCH";
export const TRADING_TOPIC_SEARCH = " TRADING_TOPIC_SEARCH";
export const TRADING_EXAM_SEARCH = " TRADING_EXAM_SEARCH";
export const TRADING_GAME_SEARCH = " TRADING_GAME_SEARCH";
export const TRADING_DECK_SEARCH = " TRADING_DECK_SEARCH";
export const TRADING_CURRICULUM_SEARCH = " TRADING_CURRICULUM_SEARCH";
export const CLEAR_SEARCH = " CLEAR_SEARCH";
export const TRADING_LESSON_FILTER = " TRADING_LESSON_FILTER";
export const TRADING_TOPIC_FILTER = " TRADING_TOPIC_FILTER";
export const TRADING_EXAM_FILTER = " TRADING_EXAM_FILTER";
export const TRADING_GAME_FILTER = " TRADING_GAME_FILTER";
export const TRADING_DECK_FILTER = " TRADING_DECK_FILTER";
export const TRADING_CURRICULUM_FILTER = " TRADING_CURRICULUM_FILTER";
export const SET_PAGINATION_MODE = " SET_PAGINATION_MODE";
export const TOPIC_RATING = " TOPIC_RATING";
export const LESSON_RATING = " LESSON_RATING";
export const EXAM_RATING = " EXAM_RATING";
export const GAME_RATING = " GAME_RATING";
export const DECK_RATING = " DECK_RATING";
export const SET_LOADING_BUTTON_RATING = " SET_LOADING_BUTTON_RATING";
export const DOWNLOAD_TOPIC = " DOWNLOAD_TOPIC";
export const DOWNLOAD_LESSON = " DOWNLOAD_LESSON";
export const DOWNLOAD_EXAM = " DOWNLOAD_EXAM";
export const DOWNLOAD_GAME = " DOWNLOAD_GAME";
export const DOWNLOAD_DECK = " DOWNLOAD_DECK";
export const SET_LOADING_BUTTON_DOWNLOAD = " SET_LOADING_BUTTON_DOWNLOAD";
export const GET_MAIN = " GET_MAIN";
export const GET_USER_MODULES = " GET_USER_MODULES";
export const CLEAR_FILTER = " CLEAR_FILTER";
export const TRADING_TOP_USERS = " TRADING_TOP_USERS";
export const TRADING_GET_PUBLICPROFILE = " TRADING_GET_PUBLICPROFILE";
export const TRADING_SAVE_DESCRIPTION = " TRADING_SAVE_DESCRIPTION";
export const TRADING_GET_FOLLOW = " TRADING_GET_FOLLOW";
export const TRADING_FOLLOW_USER = " TRADING_FOLLOW_USER";
export const TRADING_UNFOLLOW_USER = " TRADING_UNFOLLOW_USER";
export const SET_LOADING_BUTTON_FOLLOW = " SET_LOADING_BUTTON_FOLLOW";
export const UPDATE_AVATAR = " UPDATE_AVATAR";
export const TRADING_GET_EDITED_LESSONS = " TRADING_GET_EDITED_LESSONS";
export const TRADING_GET_EDITED_TOPICS = " TRADING_GET_EDITED_TOPICS";
export const TRADING_GET_EDITED_EXAMS = " TRADING_GET_EDITED_EXAMS";
export const TRADING_GET_EDITED_GAMES = " TRADING_GET_EDITED_GAMES";
export const TRADING_GET_EDITED_DECKS = " TRADING_GET_EDITED_DECKS";
export const TRADING_GET_EDITED_LESSONS_USER =
  " TRADING_GET_EDITED_LESSONS_USER";
export const TRADING_GET_EDITED_TOPICS_USER = " TRADING_GET_EDITED_TOPICS_USER";
export const TRADING_GET_EDITED_EXAMS_USER = " TRADING_GET_EDITED_EXAMS_USER";
export const TRADING_GET_EDITED_GAMES_USER = " TRADING_GET_EDITED_GAMES_USER";
export const TRADING_GET_EDITED_DECKS_USER = " TRADING_GET_EDITED_DECKS_USER";
export const TRADING_GET_PUBLISHED_DECKS_EDITED =
  "TRADING_GET_PUBLISHED_DECKS_EDITED";
export const TRADING_GET_PUBLISHED_LESSONS_EDITED =
  "TRADING_GET_PUBLISHED_LESSONS_EDITED";
export const TRADING_GET_PUBLISHED_TOPICS_EDITED =
  "TRADING_GET_PUBLISHED_TOPICS_EDITED";
export const TRADING_GET_PUBLISHED_EXAMS_EDITED =
  "TRADING_GET_PUBLISHED_EXAMS_EDITED";
export const TRADING_GET_PUBLISHED_GAMES_EDITED =
  "TRADING_GET_PUBLISHED_GAMES_EDITED";
export const SET_LOADING_BUTTON_PUBLISH_EDITED =
  "SET_LOADING_BUTTON_PUBLISH_EDITED";
export const SET_LOADING_BUTTON_REPORT_TRADING =
  "SET_LOADING_BUTTON_REPORT_TRADING";
export const TRADING_GET_COMMENTS = "TRADING_GET_COMMENTS";
export const TRADING_CREATE_COMMENT = "TRADING_CREATE_COMMENT";
export const TRADING_UPDATE_COMMENT = "TRADING_UPDATE_COMMENT";
export const TRADING_DELETE_COMMENT = "TRADING_DELETE_COMMENT";
export const TRADING_LIKE_COMMENT = "TRADING_LIKE_COMMENT";
export const SET_LOADING_BUTTON_COMMENT = "SET_LOADING_BUTTON_COMMENT";
export const TRADING_GET_FOLLOW_PUBLIC = "TRADING_GET_FOLLOW_PUBLIC";
export const GET_CUS_EXAM = "GET_CUS_EXAM";
export const CURRICULUM_ERROR = "CURRICULUM_ERROR";
export const MAKE_CURRICULUM = "MAKE_CURRICULUM";
export const GET_CURRICULUMLIST = "GET_CURRICULUMLIST";
export const UPDATE_CURRICULUM = "UPDATE_CURRICULUM";
export const DELETE_CURRICULUM = "DELETE_CURRICULUM";
export const SEARCH_CURRICULUMS = " SEARCH_CURRICULUMS";
export const CLEAR_CURRICULUMS_SEARCH = " CLEAR_CURRICULUMS_SEARCH";
export const SET_LOADING_BUTTON_CURRICULUMS = " SET_LOADING_BUTTON_CURRICULUMS";
export const SET_PAGINATION_MODE_CURRICULUMS =
  " SET_PAGINATION_MODE_CURRICULUMS";
export const TRADING_GET_CURRICULUMS = "TRADING_GET_CURRICULUMS";
export const PUBLISH_CURRICULUM_O = "PUBLISH_CURRICULUM_O";
export const TRADING_GET_PUBLISHED_CURRICULUMS =
  "TRADING_GET_PUBLISHED_CURRICULUMS";
export const TRADING_GET_PUBLISHED_CURRICULUM =
  "TRADING_GET_PUBLISHED_CURRICULUM";
export const DOWNLOAD_CURRICULUM = "DOWNLOAD_CURRICULUM";
export const CURRICULUM_RATING = "CURRICULUM_RATING";
export const CREATE_PUBLICPAGE = "CREATE_PUBLICPAGE";
export const GET_PUBLICPAGE = "GET_PUBLICPAGE";
export const GET_PUBLICPAGE_URL = "GET_PUBLICPAGE_URL";
export const UPDATE_PUBLICPAGE = "UPDATE_PUBLICPAGE";
export const SET_LOADING_BUTTON_PUBLICPAGE = "SET_LOADING_BUTTON_PUBLICPAGE";
export const SET_LOADING_BUTTON_DEACTIVATE = "SET_LOADING_BUTTON_DEACTIVATE";
export const SET_LOADING_BUTTON_APPOINTMENT = "SET_LOADING_BUTTON_APPOINTMENT";
export const SET_LOADING_BUTTON_RESPONSE = "SET_LOADING_BUTTON_RESPONSE";
export const PUBLICPAGE_ERROR = "PUBLICPAGE_ERROR";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const REPORT_USER = "REPORT_USER";
export const REPORT_USER_TRADING = "REPORT_USER_TRADING";
export const GET_MESSAGE_LIST = "GET_MESSAGE_LIST";
export const UPDATE_MESSAGE_LIST = "UPDATE_MESSAGE_LIST";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const SEARCH_MESSAGE = "SEARCH_MESSAGE";
export const CLEAR_MESSAGE_SEARCH = "CLEAR_MESSAGE_SEARCH";
export const SET_PAGINATION_MODE_MESSAGE = "SET_PAGINATION_MODE_MESSAGE";
export const SET_LOADING_BUTTON_MESSAGE = "SET_LOADING_BUTTON_MESSAGE";
export const SET_LOADING_BUTTON_REPORT = "SET_LOADING_BUTTON_REPORT";
export const FILTER_TUTOR = "FILTER_TUTOR";
export const CLEAR_FILTER_TUTOR = "CLEAR_FILTER_TUTOR";
export const GET_CREDENTIALS = "GET_CREDENTIALS";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const GET_LIST_CHARGES = "GET_LIST_CHARGES";
export const GET_LIST_INVOICES = "GET_LIST_INVOICES";
export const GET_LIST_CUSTOMERS = "GET_LIST_CUSTOMERS";
export const GET_PAYMENT_CUSTOMER = "GET_PAYMENT_CUSTOMER";
export const GET_LIST_INVOICES_INDIVIDUAL = "GET_LIST_INVOICES_INDIVIDUAL";
export const GET_LIST_CHARGES_INDIVIDUAL = "GET_LIST_CHARGES_INDIVIDUAL";
export const CONNECT_STRIPE_CHECKOUT = "CONNECT_STRIPE_CHECKOUT";
export const CONNECT_STRIPE_NEWINVOICE = "CONNECT_STRIPE_NEWINVOICE";
export const CONNECT_STRIPE_INVOICE = "CONNECT_STRIPE_INVOICE";
export const CONNECT_STRIPE_PAYMENTINTENT = "CONNECT_STRIPE_PAYMENTINTENT";
export const CONNECT_STRIPE_GETSTUDENTS = "CONNECT_STRIPE_GETSTUDENTS";
export const CONNECT_STRIPE_REFUND = "CONNECT_STRIPE_REFUND";
export const CONNECT_STRIPE_GET_SUBSCRIPTION =
  "CONNECT_STRIPE_GET_SUBSCRIPTION";
export const CONNECT_STRIPE_GET_SUBSCRIPTION_LIST =
  "CONNECT_STRIPE_GET_SUBSCRIPTION_LIST";
export const SEARCH_PAYMENTS = "SEARCH_PAYMENTS";
export const SET_PAGINATION_MODE_PAYMENTS = "SET_PAGINATION_MODE_PAYMENTS";
export const CLEAR_PAYMENTS_SEARCH = "CLEAR_PAYMENTS_SEARCH";
export const GET_TUTOR_LIST = "GET_TUTOR_LIST";
export const CONNECT_STRIPE_GET_TAXRATES = "CONNECT_STRIPE_GET_TAXRATES";
export const CONNECT_STRIPE_CREATE_TAXRATE = "CONNECT_STRIPE_CREATE_TAXRATE";
export const CONNECT_STRIPE_UPDATE_TAXRATE = " CONNECT_STRIPE_UPDATE_TAXRATE";
export const CONNECT_STRIPE_GET_PLANS = " CONNECT_STRIPE_GET_PLANS";
export const CONNECT_STRIPE_CREATE_PLAN = " CONNECT_STRIPE_CREATE_PLAN";
export const CONNECT_STRIPE_UPDATE_PLAN = " CONNECT_STRIPE_UPDATE_PLAN";
export const CONNECT_STRIPE_DELETE_PLAN = " CONNECT_STRIPE_DELETE_PLAN";
export const CONNECT_STRIPE_PLANS_SELECT = " CONNECT_STRIPE_PLANS_SELECT";
export const PAYMENTS_LOADING_TRUE = " PAYMENTS_LOADING_TRUE";
export const CONNECT_STRIPE_TAXRATES_SELECT = " CONNECT_STRIPE_TAXRATES_SELECT";
export const CONNECT_STRIPE_CREATE_SUBSCRIPTION =
  " CONNECT_STRIPE_CREATE_SUBSCRIPTION";
export const GET_CONNECT_BALANCE = " GET_CONNECT_BALANCE";
export const GET_CONNECT_BALANCE_TRANSACTIONS =
  " GET_CONNECT_BALANCE_TRANSACTIONS";
export const GET_IP_COUNTRY = " GET_IP_COUNTRY";
export const LESSONS_CALENDAR = " LESSONS_CALENDAR";
export const PUBLICPAGE_EMAIL_VERIFY = " PUBLICPAGE_EMAIL_VERIFY";
export const PUBLICPAGE_APPOINTMENT_ACCEPT = " PUBLICPAGE_APPOINTMENT_ACCEPT";
export const PUBLICPAGE_APPOINTMENT_REJECT = " PUBLICPAGE_APPOINTMENT_REJECT";
export const PUBLICPAGE_EMAILBLOCK_ADD = " PUBLICPAGE_EMAILBLOCK_ADD";
export const PUBLICPAGE_EMAILBLOCK_REMOVE = " PUBLICPAGE_EMAILBLOCK_REMOVE";
export const PUBLICPAGE_VERIFYEMAIL_MESSAGE = " PUBLICPAGE_VERIFYEMAIL_MESSAGE";
export const PUBLICPAGE_CONNECT_REQUEST = " PUBLICPAGE_CONNECT_REQUEST";
export const PUBLICPAGE_ACTIVATE = " PUBLICPAGE_ACTIVATE";
export const PUBLICPAGE_DEACTIVATE = "PUBLICPAGE_DEACTIVATE";
export const TOPSCHOLARCONNECT_GET_TEACHERS = " TOPSCHOLARCONNECT_GET_TEACHERS";
export const ACTIVATE_PORTAL_2 = " ACTIVATE_PORTAL_2";
export const LATEST_CHAT_MSG = " LATEST_CHAT_MSG";
export const GET_STUDENTS_CHAT = " GET_STUDENTS_CHAT";
export const LATEST_CHAT_MSG_STUDENT = " LATEST_CHAT_MSG_STUDENT";
export const CLEAR_UPDATED_MESSAGE = " CLEAR_UPDATED_MESSAGE";
export const LATEST_PUBLICPAGE_MSG = " LATEST_PUBLICPAGE_MSG";
export const CLEAR_STUDENTREQUESTS = " CLEAR_STUDENTREQUESTS";
export const CLEAR_PUBLISHED_MODULE = " CLEAR_PUBLISHED_MODULE";
export const SEND_NOTIFICATION = " SEND_NOTIFICATION";
export const NOTIFICATION_ERROR = " NOTIFICATION_ERROR";
export const SET_LOADING_BUTTON_NOTIFICATIONS =
  "SET_LOADING_BUTTON_NOTIFICATIONS";
export const DEFERRED_PROMPT = "DEFERRED_PROMPT";
export const RESET_STUDENT_LOGIN = "RESET_STUDENT_LOGIN";
export const CONNECT_STRIPE_CHECKOUT_UPDATE = "CONNECT_STRIPE_CHECKOUT_UPDATE";
export const CONNECT_STRIPE_CHECKOUT_UPDATE_DEFAULT =
  "CONNECT_STRIPE_CHECKOUT_UPDATE_DEFAULT";
export const CREATE_CODES = "CREATE_CODES";
export const END_TRIAL = "END_TRIAL";
export const NOTIFICATION_SEEN = "NOTIFICATION_SEEN";
export const DISCONNECT_STRIPE = "DISCONNECT_STRIPE";
export const PAYMENT_ERROR_DISCONNECT_STRIPE =
  "PAYMENT_ERROR_DISCONNECT_STRIPE";
export const WELCOME_MODAL_CLOSE = "WELCOME_MODAL_CLOSE";
export const SET_LOADING_BUTTON_SEND_INVOICE =
  "SET_LOADING_BUTTON_SEND_INVOICE";
export const SET_LOADING_BUTTON_CREATE_SUBSCRIPTION =
  "SET_LOADING_BUTTON_CREATE_SUBSCRIPTION";
export const SET_LOADING_BUTTON_ADD_TAXRATE = "SET_LOADING_BUTTON_ADD_TAXRATE";
export const SET_LOADING_BUTTON_ADD_PLAN = "SET_LOADING_BUTTON_ADD_PLAN";
export const SET_LOADING_UPLOAD_AVATAR = "SET_LOADING_UPLOAD_AVATAR";
export const SET_LOADING_DELETE_LESSON = "SET_LOADING_DELETE_LESSON";
export const SET_LOADING_DELETE_TOPIC = "SET_LOADING_DELETE_TOPIC";
export const SET_LOADING_BUTTON_DELETE_GROUP =
  "SET_LOADING_BUTTON_DELETE_GROUP";
export const SET_LOADING_DELETE_ACCOUNT = "SET_LOADING_DELETE_ACCOUNT";
export const SET_LOADING_DELETE_DECK = "SET_LOADING_DELETE_DECK";
export const SET_LOADING_DELETE_CARD = "SET_LOADING_DELETE_CARD";
export const SET_LOADING_DELETE_CANVAS = "SET_LOADING_DELETE_CANVAS";
export const SET_LOADING_DELETE_GAME = "SET_LOADING_DELETE_GAME";
export const SET_LOADING_DELETE_CURRICULUM = "SET_LOADING_DELETE_CURRICULUM";
export const SET_LOADING_DELETE_CARD_STUDENT =
  "SET_LOADING_DELETE_CARD_STUDENT";
export const SET_LOADING_DELETE_DECK_STUDENT =
  "SET_LOADING_DELETE_DECK_STUDENT";
export const SET_LOADING_REFUND = "SET_LOADING_REFUND";
export const CLEAR_NEW_INVOICE_VAR = "CLEAR_NEW_INVOICE_VAR";
export const CREATE_BLOG = "CREATE_BLOG";
export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOG = "GET_BLOG";
export const RESET_BLOG_LOADER = "RESET_BLOG_LOADER";
export const CLEAR_DOCUMENT_DELETE = "CLEAR_DOCUMENT_DELETE";
