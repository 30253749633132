import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import "./PublicPage.css";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import CountryData from "./CountryData.json";
import Spinner from "../layout/Spinner";

import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBCardHeader,
  MDBSelect,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from "mdbreact";

import {
  findTutors,
  searchTutorsFunction,
  setPaginationModeTutors,
  clearSearchReducerTutors,
  filterTutors,
  clearFilterReducer
} from "../../actions/publicpage";

const FindTutors = ({
  findTutors,
  setPaginationModeTutors,
  clearSearchReducerTutors,
  searchTutorsFunction,
  filterTutors,
  clearFilterReducer,
  publicpage: {
    tutorList,
    initialNumberOfPagesTutor,
    initialPageNumberTutor,
    pageNumberTutor,
    numberOfPagesTutor,
    paginationModeTutor,
    searchTutor,
    filterPreferences,
    tutorFilter,
    keywordsTutor,
    loadingTutorList
  }
}) => {
  const [formData, setFormData] = useState({
    countryOptions: CountryData,
    searchValue: "",
    searchValueCity: "",
    tutorList: [],
    searchTutor: [],
    pageNumberTutor: 1,
    levels: "",
    methods: "",
    country: "",
    levelOptions: [
      {
        text: "A1",
        value: "A1"
      },
      {
        text: "A2",
        value: "A2"
      },
      {
        text: "B1",
        value: "B1"
      },
      {
        text: "B2",
        value: "B2"
      },
      {
        text: "C1",
        value: "C1"
      },
      {
        text: "C2",
        value: "C2"
      }
    ],
    teachingOptions: [
      {
        text: "Online",
        value: "Online"
      },
      {
        text: "In-Person",
        value: "In-Person"
      }
    ],
    currencyOptions: [
      {
        text: "€",
        value: "EUR"
      },
      {
        text: "$",
        value: "USD"
      },
      {
        text: "Mex$",
        value: "MXN"
      },
      {
        text: "R$",
        value: "BRL"
      },
      {
        text: "Rp",
        value: "IDR"
      },
      {
        text: "₺",
        value: "TRY"
      },
      {
        text: "₹",
        value: "INR"
      },
      {
        text: "CA$",
        value: "CAD"
      }
    ],
    modalVideoTutorial: false,
    mode: ""
  });

  const { t, i18n } = useTranslation();

  const date = new Date().getFullYear();

  useEffect(() => {
    findTutors({ initialPageNumberTutor: "first", activatePublicPage: true });
  }, [findTutors]);

  useEffect(() => {
    if (tutorList) {
      setFormData(prevState => {
        return {
          ...prevState,
          tutorList: tutorList
        };
      });
    }
  }, [tutorList]);

  const onChangeSearch = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

  const findCountry = selected => {
    const find = formData.countryOptions.find(
      country => country.value === selected.country
    );
    if (find) {
      return find.text;
    }
  };

  const findCurrency = selected => {
    const find = formData.currencyOptions.find(
      currency => currency.value === selected.currency
    );
    if (find) {
      return find.text;
    }
  };

  const onKeyPressEnter = e => {
    if (e.key === "Enter") {
      tutorsSearchFunc();
    }
  };

  // Filtering

  const setFilterLevels = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        levels: value
      });
    } else {
      setFormData({
        ...formData,
        levels: ""
      });
    }
  };

  const setFilterMethods = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        methods: value
      });
    } else {
      setFormData({
        ...formData,
        methods: ""
      });
    }
  };

  const setFilterCountry = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        country: value[0]
      });
    } else {
      setFormData({
        ...formData,
        country: ""
      });
    }
  };

  const filterTutorsFunc = () => {
    searchFirst("filter");
    filterTutors({
      levels: formData.levels,
      methods: formData.methods,
      country: formData.country,
      keywords:
        formData.searchValue !== "" && formData.searchValueCity === ""
          ? formData.searchValue
          : formData.searchValue === "" && formData.searchValueCity !== ""
          ? formData.searchValueCity
          : formData.searchValue !== "" && formData.searchValueCity !== ""
          ? formData.searchValue.concat(" ", formData.searchValueCity)
          : "",
      pageNumberTutor: formData.pageNumberTutor,
      activatePublicPage: true
    });
    setPaginationModeTutors("filter");
  };

  useEffect(() => {
    setFormData(prevState => {
      const newArray =
        filterPreferences.levels === ""
          ? prevState.levelOptions.map((item, index) => {
              return {
                ...item,
                checked: false
              };
            })
          : prevState.levelOptions;
      const newArray2 =
        filterPreferences.methods === ""
          ? prevState.teachingOptions.map((item, index) => {
              return {
                ...item,
                checked: false
              };
            })
          : prevState.teachingOptions;
      const newArray3 = prevState.countryOptions.map((item, index) => {
        if (item.value === filterPreferences.country) {
          return {
            ...item,
            checked: true
          };
        } else {
          return {
            ...item,
            checked: false
          };
        }
      });
      return {
        ...prevState,
        levels: filterPreferences.levels,
        methods: filterPreferences.methods,
        country: filterPreferences.country,
        levelOptions: newArray,
        teachingOptions: newArray2,
        countryOptions: newArray3
      };
    });
  }, [filterPreferences]);

  // Searching

  // Function when searching before filtering

  const searchFirst = value => {
    if (formData.mode === "" && value === "search") {
      setFormData({
        ...formData,
        mode: "search"
      });
    } else if (formData.mode === "" && value === "filter") {
      setFormData({
        ...formData,
        mode: "filter"
      });
    } else if (formData.mode === "search" && value === "filter") {
      setFormData({
        ...formData,
        mode: "search"
      });
    } else if (formData.mode === "filter" && value === "search") {
      setFormData({
        ...formData,
        mode: "filter"
      });
    }
  };

  const tutorsSearchFunc = () => {
    // clearFilterReducer();
    searchFirst("search");
    searchTutorsFunction({
      activatePublicPage: true,
      keywordsTutor:
        formData.searchValue !== "" && formData.searchValueCity === ""
          ? formData.searchValue
          : formData.searchValue === "" && formData.searchValueCity !== ""
          ? formData.searchValueCity
          : formData.searchValue !== "" && formData.searchValueCity !== ""
          ? formData.searchValue.concat(" ", formData.searchValueCity)
          : "",
      levels: formData.levels,
      methods: formData.methods,
      country: formData.country,
      pageNumberTutor: formData.pageNumberTutor
    });

    setPaginationModeTutors("search");
  };

  const clearTutorsFunc = () => {
    setFormData({
      ...formData,
      searchValue: "",
      searchValueCity: "",
      searchTutor: [],
      mode: ""
    });
    clearSearchReducerTutors();
    clearFilterReducer();
    setPaginationModeTutors("date");
    return tutorList;
  };

  useEffect(() => {
    if (searchTutor) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchTutor: searchTutor
        };
      });
    }
  }, [searchTutor]);

  const searchTutorsMap = () => {
    if (formData.mode === "search") {
      if (
        formData.searchTutor === "No Results" ||
        tutorFilter === "No Results"
      ) {
        return [];
      } else if (tutorFilter.length !== 0) {
        return tutorFilter;
      } else if (formData.searchTutor.length !== 0) {
        return formData.searchTutor;
      } else {
        return tutorList;
      }
    } else if (formData.mode === "filter") {
      if (
        formData.searchTutor === "No Results" ||
        tutorFilter === "No Results"
      ) {
        return [];
      } else if (formData.searchTutor.length !== 0) {
        return formData.searchTutor;
      } else if (tutorFilter.length !== 0) {
        return tutorFilter;
      } else {
        return tutorList;
      }
    } else {
      return tutorList;
    }
  };

  const isActive1 = page => {
    if (initialPageNumberTutor === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumberTutor === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationTutors = () => {
    if (paginationModeTutor === "date") {
      findTutors({ initialPageNumberTutor: "first", activatePublicPage: true });
    } else if (paginationModeTutor === "search") {
      searchTutorsFunction({
        pageNumberTutor: "first",
        keywordsTutor:
          formData.searchValue !== "" && formData.searchValueCity === ""
            ? formData.searchValue
            : formData.searchValue === "" && formData.searchValueCity !== ""
            ? formData.searchValueCity
            : formData.searchValue !== "" && formData.searchValueCity !== ""
            ? formData.searchValue.concat(" ", formData.searchValueCity)
            : "",
        levels: formData.levels,
        methods: formData.methods,
        country: formData.country,
        activatePublicPage: true
      });
    } else if (paginationModeTutor === "filter") {
      filterTutors({
        pageNumberTutor: "first",
        levels: filterPreferences.levels,
        methods: filterPreferences.methods,
        country: filterPreferences.country,
        keywords:
          formData.searchValue !== "" && formData.searchValueCity === ""
            ? formData.searchValue
            : formData.searchValue === "" && formData.searchValueCity !== ""
            ? formData.searchValueCity
            : formData.searchValue !== "" && formData.searchValueCity !== ""
            ? formData.searchValue.concat(" ", formData.searchValueCity)
            : "",
        activatePublicPage: true
      });
    }
  };

  const previousPaginationTutors = () => {
    if (paginationModeTutor === "date") {
      findTutors({
        initialPageNumberTutor: initialPageNumberTutor - 1,
        activatePublicPage: true
      });
    } else if (paginationModeTutor === "search") {
      searchTutorsFunction({
        pageNumberTutor: pageNumberTutor - 1,
        keywordsTutor:
          formData.searchValue !== "" && formData.searchValueCity === ""
            ? formData.searchValue
            : formData.searchValue === "" && formData.searchValueCity !== ""
            ? formData.searchValueCity
            : formData.searchValue !== "" && formData.searchValueCity !== ""
            ? formData.searchValue.concat(" ", formData.searchValueCity)
            : "",
        levels: formData.levels,
        methods: formData.methods,
        country: formData.country,
        activatePublicPage: true
      });
    } else if (paginationModeTutor === "filter") {
      filterTutors({
        pageNumberTutor: pageNumberTutor - 1,
        levels: filterPreferences.levels,
        methods: filterPreferences.methods,
        country: filterPreferences.country,
        keywords:
          formData.searchValue !== "" && formData.searchValueCity === ""
            ? formData.searchValue
            : formData.searchValue === "" && formData.searchValueCity !== ""
            ? formData.searchValueCity
            : formData.searchValue !== "" && formData.searchValueCity !== ""
            ? formData.searchValue.concat(" ", formData.searchValueCity)
            : "",
        activatePublicPage: true
      });
    }
  };

  const currentPaginationTutors = index => {
    if (paginationModeTutor === "date") {
      findTutors({
        initialPageNumberTutor: index + 1,
        activatePublicPage: true
      });
    } else if (paginationModeTutor === "search") {
      searchTutorsFunction({
        pageNumberTutor: index + 1,
        keywordsTutor:
          formData.searchValue !== "" && formData.searchValueCity === ""
            ? formData.searchValue
            : formData.searchValue === "" && formData.searchValueCity !== ""
            ? formData.searchValueCity
            : formData.searchValue !== "" && formData.searchValueCity !== ""
            ? formData.searchValue.concat(" ", formData.searchValueCity)
            : "",
        levels: formData.levels,
        methods: formData.methods,
        country: formData.country,
        activatePublicPage: true
      });
    } else if (paginationModeTutor === "filter") {
      filterTutors({
        pageNumberTutor: index + 1,
        levels: filterPreferences.levels,
        methods: filterPreferences.methods,
        country: filterPreferences.country,
        keywords:
          formData.searchValue !== "" && formData.searchValueCity === ""
            ? formData.searchValue
            : formData.searchValue === "" && formData.searchValueCity !== ""
            ? formData.searchValueCity
            : formData.searchValue !== "" && formData.searchValueCity !== ""
            ? formData.searchValue.concat(" ", formData.searchValueCity)
            : "",
        activatePublicPage: true
      });
    }
  };

  const nextPaginationTutors = () => {
    if (paginationModeTutor === "date") {
      findTutors({
        initialPageNumberTutor: initialPageNumberTutor + 1,
        activatePublicPage: true
      });
    } else if (paginationModeTutor === "search") {
      searchTutorsFunction({
        pageNumberTutor: pageNumberTutor + 1,
        keywordsTutor:
          formData.searchValue !== "" && formData.searchValueCity === ""
            ? formData.searchValue
            : formData.searchValue === "" && formData.searchValueCity !== ""
            ? formData.searchValueCity
            : formData.searchValue !== "" && formData.searchValueCity !== ""
            ? formData.searchValue.concat(" ", formData.searchValueCity)
            : "",
        levels: formData.levels,
        methods: formData.methods,
        country: formData.country,
        activatePublicPage: true
      });
    } else if (paginationModeTutor === "filter") {
      filterTutors({
        pageNumberTutor: pageNumberTutor + 1,
        levels: filterPreferences.levels,
        methods: filterPreferences.methods,
        country: filterPreferences.country,
        keywords:
          formData.searchValue !== "" && formData.searchValueCity === ""
            ? formData.searchValue
            : formData.searchValue === "" && formData.searchValueCity !== ""
            ? formData.searchValueCity
            : formData.searchValue !== "" && formData.searchValueCity !== ""
            ? formData.searchValue.concat(" ", formData.searchValueCity)
            : "",
        activatePublicPage: true
      });
    }
  };

  const lastPaginationTutors = () => {
    if (paginationModeTutor === "date") {
      findTutors({ initialPageNumberTutor: "last", activatePublicPage: true });
    } else if (paginationModeTutor === "search") {
      searchTutorsFunction({
        pageNumberTutor: "last",
        keywordsTutor:
          formData.searchValue !== "" && formData.searchValueCity === ""
            ? formData.searchValue
            : formData.searchValue === "" && formData.searchValueCity !== ""
            ? formData.searchValueCity
            : formData.searchValue !== "" && formData.searchValueCity !== ""
            ? formData.searchValue.concat(" ", formData.searchValueCity)
            : "",
        levels: formData.levels,
        methods: formData.methods,
        country: formData.country,
        activatePublicPage: true
      });
    } else if (paginationModeTutor === "filter") {
      filterTutors({
        pageNumberTutor: "last",
        levels: filterPreferences.levels,
        methods: filterPreferences.methods,
        country: filterPreferences.country,
        keywords:
          formData.searchValue !== "" && formData.searchValueCity === ""
            ? formData.searchValue
            : formData.searchValue === "" && formData.searchValueCity !== ""
            ? formData.searchValueCity
            : formData.searchValue !== "" && formData.searchValueCity !== ""
            ? formData.searchValue.concat(" ", formData.searchValueCity)
            : "",
        activatePublicPage: true
      });
    }
  };

  useEffect(() => {
    if (i18n.language) {
      if (i18n.language === "fr") {
        setFormData(prevState => {
          const newArray = prevState.teachingOptions.map((item, index) => {
            if (item.value === "Online") {
              return {
                ...item,
                text: "En ligne"
              };
            } else if (item.value === "In-Person") {
              return {
                ...item,
                text: "En Personne"
              };
            } else {
              return {
                ...item,
                text: "Les Deux"
              };
            }
          });
          return {
            ...prevState,
            teachingOptions: newArray
          };
        });
      } else if (i18n.language === "pt") {
        setFormData(prevState => {
          const newArray = prevState.teachingOptions.map((item, index) => {
            if (item.value === "Online") {
              return {
                ...item,
                text: "Online"
              };
            } else if (item.value === "In-Person") {
              return {
                ...item,
                text: "Pessoalmente"
              };
            } else {
              return {
                ...item,
                text: "Ambos"
              };
            }
          });
          return {
            ...prevState,
            teachingOptions: newArray
          };
        });
      } else if (i18n.language === "it") {
        setFormData(prevState => {
          const newArray = prevState.teachingOptions.map((item, index) => {
            if (item.value === "Online") {
              return {
                ...item,
                text: "Online"
              };
            } else if (item.value === "In-Person") {
              return {
                ...item,
                text: "Di persona"
              };
            } else {
              return {
                ...item,
                text: "Entrambi"
              };
            }
          });
          return {
            ...prevState,
            teachingOptions: newArray
          };
        });
      } else if (i18n.language === "id") {
        setFormData(prevState => {
          const newArray = prevState.teachingOptions.map((item, index) => {
            if (item.value === "Online") {
              return {
                ...item,
                text: "Online"
              };
            } else if (item.value === "In-Person") {
              return {
                ...item,
                text: "Bertemu langsung"
              };
            } else {
              return {
                ...item,
                text: "Keduanya"
              };
            }
          });
          return {
            ...prevState,
            teachingOptions: newArray
          };
        });
      } else if (i18n.language === "es") {
        setFormData(prevState => {
          const newArray = prevState.teachingOptions.map((item, index) => {
            if (item.value === "Online") {
              return {
                ...item,
                text: "En línea"
              };
            } else if (item.value === "In-Person") {
              return {
                ...item,
                text: "En persona"
              };
            } else {
              return {
                ...item,
                text: "Ambos"
              };
            }
          });
          return {
            ...prevState,
            teachingOptions: newArray
          };
        });
      } else if (i18n.language === "hi") {
        setFormData(prevState => {
          const newArray = prevState.teachingOptions.map((item, index) => {
            if (item.value === "Online") {
              return {
                ...item,
                text: "ऑनलाइन"
              };
            } else if (item.value === "In-Person") {
              return {
                ...item,
                text: "खुद निजी रूप से"
              };
            } else {
              return {
                ...item,
                text: "दोनों"
              };
            }
          });
          return {
            ...prevState,
            teachingOptions: newArray
          };
        });
      } else if (i18n.language === "de") {
        setFormData(prevState => {
          const newArray = prevState.teachingOptions.map((item, index) => {
            if (item.value === "Online") {
              return {
                ...item,
                text: "Online"
              };
            } else if (item.value === "In-Person") {
              return {
                ...item,
                text: "Persönlich"
              };
            } else {
              return {
                ...item,
                text: "Beides"
              };
            }
          });
          return {
            ...prevState,
            teachingOptions: newArray
          };
        });
      } else if (i18n.language === "tr") {
        setFormData(prevState => {
          const newArray = prevState.teachingOptions.map((item, index) => {
            if (item.value === "Online") {
              return {
                ...item,
                text: "Çevrimiçi"
              };
            } else if (item.value === "In-Person") {
              return {
                ...item,
                text: "Yüz yüze"
              };
            } else {
              return {
                ...item,
                text: "İkisi de"
              };
            }
          });
          return {
            ...prevState,
            teachingOptions: newArray
          };
        });
      } else if (i18n.language === "zh_CN") {
        setFormData(prevState => {
          const newArray = prevState.teachingOptions.map((item, index) => {
            if (item.value === "Online") {
              return {
                ...item,
                text: "在线"
              };
            } else if (item.value === "In-Person") {
              return {
                ...item,
                text: "当面"
              };
            } else {
              return {
                ...item,
                text: "两者均要"
              };
            }
          });
          return {
            ...prevState,
            teachingOptions: newArray
          };
        });
      } else {
        setFormData(prevState => {
          const newArray = prevState.teachingOptions.map((item, index) => {
            if (item.value === "Online") {
              return {
                ...item,
                text: "Online"
              };
            } else if (item.value === "In-Person") {
              return {
                ...item,
                text: "In-Person"
              };
            }
          });
          return {
            ...prevState,
            teachingOptions: newArray
          };
        });
      }
    }
  }, [i18n.language]);

  const modalVideoTutorial = () => {
    setFormData({
      ...formData,
      modalVideoTutorial: !formData.modalVideoTutorial
    });
  };

  return loadingTutorList ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("meta.1")}</title>
        <meta name="description" content={t("meta.2")} />
        <meta property="og:title" content={t("meta.1")} />
        <meta property="og:description" content={t("meta.2")} />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/findtutors" />
      </Helmet>
      <MDBContainer className="findTeacherBackground">
        <MDBCol size="12" className="hideOverflow">
          <MDBRow center className="mt-5">
            <p className="railwayFont h3-responsive mx-1 text-center publicPageIconColor">
              {t("findTutors.1")}
            </p>
          </MDBRow>
          <MDBRow center>
            <p className="quickSand h5 text-black">
              {t("findTutors.2")}{" "}
              <MDBIcon
                style={{ cursor: "pointer" }}
                className="color1"
                onClick={modalVideoTutorial}
                icon="info-circle"
              ></MDBIcon>
            </p>{" "}
          </MDBRow>
          <MDBCard narrow className="searchAreaCard middleVertical">
            <MDBCardHeader
              cascade="true"
              className="view view-cascade mt-3 mb-1 searchAreaCardHeader dropDownCountries"
            >
              <MDBRow>
                <MDBCol sm="6" className="col-12">
                  {" "}
                  <MDBRow className="middleVertical">
                    <MDBCol lg="1" md="1" sm="12"></MDBCol>
                    <MDBCol lg="2" md="2" sm="12">
                      <MDBIcon
                        icon="search"
                        size="lg"
                        className="publicPageIconColor"
                      />
                    </MDBCol>
                    <MDBCol lg="6" md="6" sm="12">
                      <MDBInput
                        name="searchValue"
                        className="quickSand mt-3"
                        label={t("findTutors.3")}
                        labelClass="black-text quickSand"
                        type="text"
                        containerClass="active-pink active-pink-2 mt-0 mb-3"
                        value={formData.searchValue}
                        onChange={onChangeSearch}
                        onKeyPress={onKeyPressEnter}
                      />
                    </MDBCol>
                    <MDBCol lg="2" md="2" sm="12"></MDBCol>
                    <MDBCol lg="1" md="1" sm="12">
                      <p className="h2 publicPageIconColor font-weight-bold spacerPublicPage">
                        |
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol sm="6" className="col-12 ">
                  <MDBRow className="middleVertical">
                    <MDBCol lg="2" md="2" sm="12">
                      <MDBIcon
                        icon="map-marker-alt"
                        size="lg"
                        className="publicPageIconColor"
                      />
                    </MDBCol>
                    <MDBCol lg="6" md="4" sm="12">
                      <MDBInput
                        name="searchValueCity"
                        className="quickSand mt-3 px-0"
                        label={t("findTutors.4")}
                        labelClass="black-text quickSand"
                        type="text"
                        containerClass="active-pink active-pink-2 mt-0 mb-3"
                        value={formData.searchValueCity}
                        onChange={onChangeSearch}
                        onKeyPress={onKeyPressEnter}
                      />
                    </MDBCol>
                    <MDBCol lg="1" md="1" sm="12"></MDBCol>
                    <MDBCol lg="2" md="4" sm="12">
                      <MDBBtn
                        size="md"
                        className="sendMessageButtonColor quickSand"
                        onClick={tutorsSearchFunc}
                      >
                        Search
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBCardHeader>
            <MDBRow className="searchAreaRow middleVertical ">
              <MDBCol sm="12" lg="1" md="1" className="col-12"></MDBCol>
              <MDBCol sm="12" lg="3" md="3" className="col-12">
                <div className="statusInput quickSand">
                  <MDBSelect
                    options={formData.levelOptions}
                    getValue={setFilterLevels}
                    color="secondary"
                    label={t("findTutors.5")}
                    labelClass="publicPageIconColor"
                    multiple
                  />
                </div>
              </MDBCol>
              <MDBCol sm="12" lg="3" md="3" className="col-12">
                <div className="statusInput quickSand">
                  <MDBSelect
                    options={formData.teachingOptions}
                    getValue={setFilterMethods}
                    color="secondary"
                    label={t("findTutors.6")}
                    labelClass="publicPageIconColor"
                    multiple
                  />
                </div>
              </MDBCol>
              <MDBCol sm="12" lg="3" md="3" className="col-12">
                <div className="dropDownCountries statusInput quickSand">
                  <MDBSelect
                    label={t("findTutors.7")}
                    type="text"
                    className="px-0 dropDownCountries"
                    labelClass="publicPageIconColor"
                    color="secondary"
                    options={formData.countryOptions}
                    getValue={setFilterCountry}
                    search
                  />
                </div>
              </MDBCol>
              <MDBCol lg="2" md="2" sm="6" className="col-6">
                <p
                  className="quickSand h5 publicPageIconColor middleVertical mt-3 clearAllHover"
                  style={{ cursor: "pointer" }}
                  onClick={clearTutorsFunc}
                >
                  {t("findTutors.8")}
                </p>
              </MDBCol>
            </MDBRow>

            <MDBRow center>
              <MDBBtn
                size="sm"
                rounded
                className="filterButtonColor quickSand"
                onClick={filterTutorsFunc}
              >
                {t("findTutors.9")}
              </MDBBtn>
            </MDBRow>
          </MDBCard>

          <Fragment>
            <MDBRow
              center
              className="findTutorRow"
              style={{ minHeight: "57rem" }}
            >
              <div
                className={
                  formData.searchTutor === "No Results" ? "mt-2" : "d-none"
                }
              >
                <p className="quickSand h4 black-text text-center">
                  {t("findTutors.10")}
                </p>
              </div>
              {/* Tutors Center */}

              {searchTutorsMap().map((item, index) => {
                return (
                  <MDBCol
                    key={item._id}
                    lg="3"
                    md="6"
                    sm="6"
                    className="col-12 pl-4"
                  >
                    <Link
                      to={{
                        pathname: `/tutors/${item.uniqueURL}`
                      }}
                      target="_blank"
                      style={{ color: "#000" }}
                    >
                      <MDBCard
                        style={{ cursor: "pointer" }}
                        className="findTutorCard findTutorHover findTutorCardMobile "
                      >
                        <MDBCardBody>
                          <div style={{ minHeight: "310px" }}>
                            <MDBRow>
                              <MDBCol size="4"></MDBCol>
                              <MDBCol size="4">
                                <MDBRow
                                  center
                                  className="mb-4 mt-1 middleVertical5"
                                >
                                  <img
                                    style={{ width: "120px" }}
                                    src={item.avatar.url}
                                    alt=""
                                    className="rounded-circle z-depth-1-half"
                                  />
                                </MDBRow>
                              </MDBCol>
                              <MDBCol size="4"></MDBCol>
                            </MDBRow>
                            <MDBRow center>
                              <p className="text-black halantText h4 text-truncate">
                                {item.name}
                              </p>
                            </MDBRow>
                            <MDBRow center className="inlineCountry">
                              <p className="text-black quickSand findTutorCardQuickSandFont mr-1 text-truncate">
                                {`${item.city}, ${findCountry(item)}`}
                              </p>
                              <p className="text-black quickSand findTutorCardQuickSandFont"></p>
                            </MDBRow>
                            <MDBRow center>
                              <ul className="crimsonText h4 text-black list-inline text-center levelsCommas">
                                {item.levels.map((item2, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className="list-inline-item"
                                    >
                                      {item2}
                                    </li>
                                  );
                                })}
                              </ul>
                            </MDBRow>
                            <MDBRow center>
                              <ul className="crimsonText h4 text-black list-inline text-center levelsCommas">
                                {item.methods.map((item2, index) => {
                                  //  const method= item2==="In-Person" ? "In-Person" : item2=== "Online" ? "Online" : item2==="Both" ? "Both" : null

                                  const methodLanguage =
                                    i18n.language === "fr" &&
                                    item2 === "In-Person"
                                      ? "En Personne"
                                      : i18n.language === "fr" &&
                                        item2 === "Online"
                                      ? "En ligne"
                                      : i18n.language === "pt" &&
                                        item2 === "In-Person"
                                      ? "Pessoalmente"
                                      : i18n.language === "pt" &&
                                        item2 === "Online"
                                      ? "Online"
                                      : i18n.language === "it" &&
                                        item2 === "In-Person"
                                      ? "Di persona"
                                      : i18n.language === "it" &&
                                        item2 === "Online"
                                      ? "Online"
                                      : i18n.language === "id" &&
                                        item2 === "In-Person"
                                      ? "Bertemu langsung"
                                      : i18n.language === "id" &&
                                        item2 === "Online"
                                      ? "Online"
                                      : i18n.language === "es" &&
                                        item2 === "In-Person"
                                      ? "En persona"
                                      : i18n.language === "es" &&
                                        item2 === "Online"
                                      ? "En línea"
                                      : i18n.language === "hi" &&
                                        item2 === "In-Person"
                                      ? "खुद निजी रूप से"
                                      : i18n.language === "hi" &&
                                        item2 === "Online"
                                      ? "ऑनलाइन"
                                      : i18n.language === "de" &&
                                        item2 === "In-Person"
                                      ? "Persönlich"
                                      : i18n.language === "de" &&
                                        item2 === "Online"
                                      ? "Online"
                                      : i18n.language === "tr" &&
                                        item2 === "In-Person"
                                      ? "Yüz yüze"
                                      : i18n.language === "tr" &&
                                        item2 === "Online"
                                      ? "Çevrimiçi"
                                      : i18n.language === "zh_CN" &&
                                        item2 === "In-Person"
                                      ? "当面"
                                      : i18n.language === "zh_CN" &&
                                        item2 === "Online"
                                      ? "在线"
                                      : item2;

                                  return (
                                    <li
                                      key={index}
                                      className="list-inline-item"
                                    >
                                      {methodLanguage}
                                    </li>
                                  );
                                })}
                              </ul>
                            </MDBRow>
                          </div>
                          <MDBRow className="middleVertical">
                            <p className="text-black quickSand findTutorCardQuickSandFont2 ">
                              {item.rate} {findCurrency(item)}
                              {t("findTutors.11")}
                            </p>
                          </MDBRow>
                        </MDBCardBody>
                      </MDBCard>
                    </Link>
                  </MDBCol>
                );
              })}
            </MDBRow>
          </Fragment>

          {/* Pagination Tutors Medium Size*/}

          <div className="mx-4 my-5 middleVertical paginationMessagesMedium">
            <MDBRow>
              <MDBCol>
                <MDBPagination circle>
                  <MDBPageItem onClick={firstPaginationTutors}>
                    <MDBPageNav className="page-link">
                      <span>First</span>
                    </MDBPageNav>
                  </MDBPageItem>
                  <MDBPageItem onClick={previousPaginationTutors}>
                    <MDBPageNav className="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </MDBPageNav>
                  </MDBPageItem>
                  {paginationModeTutor === "date"
                    ? [...Array(initialNumberOfPagesTutor)].map(
                        (item, index) => {
                          if (index + 1 === initialPageNumberTutor) {
                            return (
                              <MDBPageItem
                                key={index}
                                active={isActive1(index + 1)}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor - 1) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor - 2) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor - 3) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor + 1) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor + 2) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor + 3) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else {
                            return null;
                          }
                        }
                      )
                    : [...Array(numberOfPagesTutor)].map((item, index) => {
                        if (index + 1 === pageNumberTutor) {
                          return (
                            <MDBPageItem
                              key={index}
                              active={isActive2(index + 1)}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor - 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor - 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor - 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor + 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor + 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor + 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                  <MDBPageItem onClick={nextPaginationTutors}>
                    <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                  </MDBPageItem>
                  <MDBPageItem onClick={lastPaginationTutors}>
                    <MDBPageNav className="page-link">Last</MDBPageNav>
                  </MDBPageItem>
                </MDBPagination>
              </MDBCol>
            </MDBRow>
          </div>

          {/* Pagination Tutors Small Size*/}

          <div className="mx-4 my-5 middleVertical paginationMessagesSmall">
            <MDBRow>
              <MDBCol>
                <MDBPagination circle size="sm">
                  <MDBPageItem onClick={firstPaginationTutors}>
                    <MDBPageNav className="page-link">
                      <span>First</span>
                    </MDBPageNav>
                  </MDBPageItem>
                  <MDBPageItem onClick={previousPaginationTutors}>
                    <MDBPageNav className="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </MDBPageNav>
                  </MDBPageItem>
                  {paginationModeTutor === "date"
                    ? [...Array(initialNumberOfPagesTutor)].map(
                        (item, index) => {
                          if (index + 1 === initialPageNumberTutor) {
                            return (
                              <MDBPageItem
                                key={index}
                                active={isActive1(index + 1)}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor - 1) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor - 2) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor - 3) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor + 1) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor + 2) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumberTutor + 3) {
                            return (
                              <MDBPageItem
                                key={index}
                                onClick={() => currentPaginationTutors(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else {
                            return null;
                          }
                        }
                      )
                    : [...Array(numberOfPagesTutor)].map((item, index) => {
                        if (index + 1 === pageNumberTutor) {
                          return (
                            <MDBPageItem
                              key={index}
                              active={isActive2(index + 1)}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor - 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor - 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor - 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor + 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor + 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === pageNumberTutor + 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTutors(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                  <MDBPageItem onClick={nextPaginationTutors}>
                    <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                  </MDBPageItem>
                  <MDBPageItem onClick={lastPaginationTutors}>
                    <MDBPageNav className="page-link">Last</MDBPageNav>
                  </MDBPageItem>
                </MDBPagination>
              </MDBCol>
            </MDBRow>
          </div>
          {/* Footer  */}

          <MDBCard className="cardFooterFindTutors">
            <MDBCol sm="12">
              <MDBRow className="middleVertical">
                <MDBCol xl="1" lg="1" md="1" sm="4" className="col-12"></MDBCol>
                <MDBCol xl="10" lg="9" md="8" sm="4" className="col-12">
                  {" "}
                  <p className="text-center publicPageFooter quickSand">
                    &copy; {date} Copyright:{" "}
                    <a
                      href="https://topscholar.io"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="publicPageFooterColor"
                    >
                      Topscholar OÜ
                    </a>
                  </p>
                </MDBCol>
                <MDBCol xl="1" lg="2" md="3" sm="4" className="col-12">
                  <Link to="/findtutorsterms">
                    <p className="quickSand mt-3 text-center footerHover publicPageFooterColor2">
                      Terms of usage
                    </p>
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBCard>
        </MDBCol>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          className="modal-notify modal-info text-white "
          isOpen={formData.modalVideoTutorial}
          toggle={modalVideoTutorial}
          size="lg"
        >
          <MDBModalHeader
            className="text-center montserratFont h4 documentationModal"
            titleClass="w-100"
            tag="p"
          >
            Find Tutors Tutorial
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <div className="embed-responsive embed-responsive-16by9 py-3">
              <iframe
                style={{ height: "100%", width: "100%" }}
                title="Find Tutors Profile Overview"
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/Fkbw5yGFSDU?rel=0"
                allowFullScreen
              ></iframe>
            </div>
          </MDBModalBody>
          <MDBModalFooter className="justify-content-center">
            <MDBBtn
              className="quickSand"
              color="primary"
              onClick={modalVideoTutorial}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

FindTutors.propTypes = {};

const mapStateToProps = state => ({
  publicpage: state.publicpage
});

export default withRouter(
  connect(mapStateToProps, {
    findTutors,
    searchTutorsFunction,
    setPaginationModeTutors,
    clearSearchReducerTutors,
    filterTutors,
    clearFilterReducer
  })(FindTutors)
);
