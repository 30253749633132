import React, { Fragment, useEffect } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { activate } from "../../actions/auth";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask
} from "mdbreact";
import "./Login.css";
import Footer from "../layout/Footer";
import LoginForm from "./LoginForm";

const Activate = ({ activate, match, auth, logout }) => {
  useEffect(() => {
    activate(match.params.id);
  }, [activate, match]);

  if (auth.activate) {
    if (auth.activate.activate === false) {
      return <Redirect to="/activation" />;
    } else {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Activate</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="classic-form-page" id="login">
        <MDBMask
          className="d-flex justify-content-center align-items-center"
          overlay="gradient"
        >
          <MDBContainer className="mt-3 mb-5">
            <MDBRow className="flex-center pt-5 mt-3">
              <MDBCol md="10" lg="6" xl="5" sm="12" className="mt-5 mx-auto">
                <MDBCard>
                  <MDBCardBody>
                    <div className="form-header color-one">
                      <p className="loginRegisterTitle h2">Login</p>
                    </div>
                    {auth.activate === null ? (
                      ""
                    ) : auth.activate.success === true ? (
                      <p>{auth.activate.message}</p>
                    ) : (
                      <p>
                        {auth.activate.message} Follow this link to resend the
                        confirmation link: . If you have already confirmed your
                        account, please log in.
                        <Link to="/activation">
                          Resend confirmaiton link
                        </Link>{" "}
                      </p>
                    )}

                    <LoginForm activateProp="activateProp" />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
        <Footer />
      </div>
    </Fragment>
  );
};

Activate.propTypes = {
  auth: PropTypes.object.isRequired,
  activate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, { activate })(Activate));
