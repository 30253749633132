import axios from "axios";
import { setAlert } from "./alert";

import {
  CREATE_PUBLICPAGE,
  CREATE_MESSAGE,
  REPORT_USER,
  GET_MESSAGE_LIST,
  UPDATE_MESSAGE_LIST,
  GET_PUBLICPAGE,
  GET_PUBLICPAGE_URL,
  UPDATE_PUBLICPAGE,
  DELETE_MESSAGE,
  SEARCH_MESSAGE,
  CLEAR_MESSAGE_SEARCH,
  SET_PAGINATION_MODE_MESSAGE,
  SET_LOADING_BUTTON_MESSAGE,
  GET_TUTOR_LIST,
  SEARCH_TUTOR,
  SET_PAGINATION_MODE_TUTOR,
  CLEAR_TUTOR_SEARCH,
  FILTER_TUTOR,
  CLEAR_FILTER_TUTOR,
  PUBLICPAGE_ERROR,
  PUBLICPAGE_EMAIL_VERIFY,
  PUBLICPAGE_APPOINTMENT_ACCEPT,
  PUBLICPAGE_APPOINTMENT_REJECT,
  SET_LOADING_BUTTON_PUBLICPAGE,
  SET_LOADING_BUTTON_DEACTIVATE,
  SET_LOADING_BUTTON_APPOINTMENT,
  SET_LOADING_BUTTON_RESPONSE,
  SET_LOADING_BUTTON_REPORT,
  PUBLICPAGE_EMAILBLOCK_ADD,
  PUBLICPAGE_EMAILBLOCK_REMOVE,
  PUBLICPAGE_VERIFYEMAIL_MESSAGE,
  PUBLICPAGE_CONNECT_REQUEST,
  PUBLICPAGE_ACTIVATE,
  PUBLICPAGE_DEACTIVATE,
  CLEAR_UPDATED_MESSAGE,
  LATEST_PUBLICPAGE_MSG,
  CLEAR_STUDENTREQUESTS
} from "./types";

// Create Public Page

export const createPublicPage = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  let formData2 = new FormData();
  formData2.append("avatar", formData.avatar);
  formData2.append("name", formData.name);
  formData2.append("country", formData.country);
  formData2.append("city", formData.city);
  formData2.append("levels", JSON.stringify(formData.levels));
  formData2.append("degree", JSON.stringify(formData.degree));
  formData2.append("certification", JSON.stringify(formData.certification));
  formData2.append("lastExperience", JSON.stringify(formData.lastExperience));
  formData2.append("description", formData.description);
  formData2.append("methods", JSON.stringify(formData.methods));
  formData2.append("years", JSON.stringify(formData.years));
  formData2.append("rate", formData.rate);
  formData2.append("currency", formData.currency);
  formData2.append("facebook", JSON.stringify(formData.facebook));
  formData2.append("youtube", JSON.stringify(formData.youtube));
  formData2.append("instagram", JSON.stringify(formData.instagram));
  formData2.append("twitter", JSON.stringify(formData.twitter));
  formData2.append("linkedIn", JSON.stringify(formData.linkedIn));
  formData2.append(
    "activatePublicPage",
    JSON.stringify(formData.activatePublicPage)
  );
  formData2.append("uniqueURL", formData.uniqueURL);
  formData2.append("availability", JSON.stringify(formData.availability));

  try {
    const res = await axios.post("/api/publicpage", formData2, config);

    dispatch({
      type: CREATE_PUBLICPAGE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get public page

export const getPublicPage = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post("/api/publicpage/get", formData, config);

    dispatch({
      type: GET_PUBLICPAGE,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update Public Page

export const updatePublicPage = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  let formData2 = new FormData();
  formData2.append("avatar", formData.avatar);
  formData2.append("name", formData.name);
  formData2.append("country", formData.country);
  formData2.append("city", formData.city);
  formData2.append("levels", JSON.stringify(formData.levels));
  formData2.append("degree", JSON.stringify(formData.degree));
  formData2.append("certification", JSON.stringify(formData.certification));
  formData2.append("lastExperience", JSON.stringify(formData.lastExperience));
  formData2.append("description", formData.description);
  formData2.append("methods", JSON.stringify(formData.methods));
  formData2.append("years", JSON.stringify(formData.years));
  formData2.append("rate", formData.rate);
  formData2.append("currency", formData.currency);
  formData2.append("facebook", JSON.stringify(formData.facebook));
  formData2.append("youtube", JSON.stringify(formData.youtube));
  formData2.append("instagram", JSON.stringify(formData.instagram));
  formData2.append("twitter", JSON.stringify(formData.twitter));
  formData2.append("linkedIn", JSON.stringify(formData.linkedIn));
  formData2.append("uniqueURL", formData.uniqueURL);
  formData2.append("availability", JSON.stringify(formData.availability));

  try {
    const res = await axios.post(`/api/publicpage/update`, formData2, config);

    dispatch({
      type: UPDATE_PUBLICPAGE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get public page

export const getPublicPageURL = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post(
      `/api/publicpage/tutors/${formData.uniqueURL}`,
      formData,
      config
    );

    dispatch({
      type: GET_PUBLICPAGE_URL,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get public page url for connect

export const getPublicPageURLConnect = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post(
      `/api/publicpage/connect/availability`,
      formData,
      config
    );

    dispatch({
      type: GET_PUBLICPAGE_URL,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create a new message to send the tutor

export const createMessage = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post("/api/publicpage/messages", formData, config);

    dispatch({
      type: CREATE_MESSAGE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all the messages

export const getMessageList = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/publicpage/messages/get`,
      formData,
      config
    );

    dispatch({
      type: GET_MESSAGE_LIST,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update Message
export const updateMessage = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/publicpage/messages/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_MESSAGE_LIST,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete message

export const deleteMessage = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.delete(
      `/api/publicpage/messages/${formData._id}`,
      config
    );

    dispatch({
      type: DELETE_MESSAGE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Message

export const searchMessageFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/messages/search",
      formData,
      config
    );

    dispatch({
      type: SEARCH_MESSAGE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Send Report User

export const reportUser = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post("/api/publicpage/report", formData, config);

    dispatch({
      type: REPORT_USER,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Message

export const setPaginationModeMessage = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_MESSAGE,
    payload: formData
  });
};

// Clear Message Search Results

export const clearMessageSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_MESSAGE_SEARCH
  });
};

// Find all the public pages in find tutor

export const findTutors = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post("/api/publicpage/find", formData, config);

    dispatch({
      type: GET_TUTOR_LIST,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Message

export const searchTutorsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/find/search",
      formData,
      config
    );

    dispatch({
      type: SEARCH_TUTOR,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Tutors

export const setPaginationModeTutors = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_TUTOR,
    payload: formData
  });
};

// Clear Message Search Results

export const clearSearchReducerTutors = () => async dispatch => {
  dispatch({
    type: CLEAR_TUTOR_SEARCH
  });
};

// Filter Tutors

export const filterTutors = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/find/filter",
      formData,
      config
    );

    dispatch({
      type: FILTER_TUTOR,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// verify email for appointment

export const verifyEmail = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/appointment/verify",
      formData,
      config
    );

    dispatch({
      type: PUBLICPAGE_EMAIL_VERIFY,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// tutor accepts appointment

export const sendAccept = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/appointment/accept",
      formData,
      config
    );

    dispatch({
      type: PUBLICPAGE_APPOINTMENT_ACCEPT,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// tutor accepts appointment

export const sendReject = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/appointment/reject",
      formData,
      config
    );

    dispatch({
      type: PUBLICPAGE_APPOINTMENT_REJECT,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// ADD BLOCK EMAIL

export const addBlockEmail = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/blockedemails/add",
      formData,
      config
    );

    dispatch({
      type: PUBLICPAGE_EMAILBLOCK_ADD,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// REMOVE BLOCK EMAIL

export const removeBlockEmail = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/blockedemails/remove",
      formData,
      config
    );

    dispatch({
      type: PUBLICPAGE_EMAILBLOCK_REMOVE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Verify email for messages

export const verifyEmailMessage = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/message/verify",
      formData,
      config
    );

    dispatch({
      type: PUBLICPAGE_VERIFYEMAIL_MESSAGE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// send teacher a class request (Connect)

export const classRequestConnect = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/connect/appointment/request",
      formData,
      config
    );

    dispatch({
      type: PUBLICPAGE_CONNECT_REQUEST,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// deactivate public page

export const deactivatePublicPage = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/publicpage/deactivate",
      formData,
      config
    );

    dispatch({
      type: PUBLICPAGE_DEACTIVATE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// activate public page

export const activatePublicPage = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/publicpage/activate", formData, config);

    dispatch({
      type: PUBLICPAGE_ACTIVATE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PUBLICPAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Loading Button Public Page Save/Update

export const setLoadingButtonPublicPage = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_PUBLICPAGE,
    payload: formData
  });
};

// Set Loading Button Public Page Send Message

export const setLoadingButtonMessage = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_MESSAGE,
    payload: formData
  });
};

// Set Loading Button Public Page Report User

export const setLoadingButtonReport = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_REPORT,
    payload: formData
  });
};

// Set Loading Button Public Page Appointment

export const setLoadingButtonAppointment = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_APPOINTMENT,
    payload: formData
  });
};

// Set Loading Button Public Page Response

export const setLoadingButtonResponse = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_RESPONSE,
    payload: formData
  });
};

// Set Loading Button Public Page Deactivate

export const setLoadingButtonDeactivate = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_DEACTIVATE,
    payload: formData
  });
};

// Clear Filter

export const clearFilterReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_FILTER_TUTOR
  });
};
// Clear updatedMessage

export const clearUpdatedMessage = () => async dispatch => {
  dispatch({
    type: CLEAR_UPDATED_MESSAGE
  });
};
export const clearStudentRequests = () => async dispatch => {
  dispatch({
    type: CLEAR_STUDENTREQUESTS
  });
};
// export const setConnectRequestStatus = () => async dispatch => {
//   dispatch({
//     type: SET_CONNECT_REQUEST_STATUS
//   });
// };

export const latestPublicpageMsgStudentFunc = formData => async dispatch => {
  dispatch({
    type: LATEST_PUBLICPAGE_MSG,
    payload: formData
  });
};
