import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBNav,
  MDBRow
} from "mdbreact";

const FAQ = () => {
  const [formData, setFormData] = useState({
    collapse1: false,
    collapse2: false,
    collapse3: false,
    collapse4: false,
    collapse5: false,
    collapse6: false,
    collapse7: false,
    collapse8: false,
    collapse9: false,
    collapse10: false
  });

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: FAQ</title>

        <meta
          name="description"
          content="Topscholar' most frequently asked questions."
        />
        <meta property="og:title" content="Topscholar: FAQ" />
        <meta
          property="og:description"
          content="Topscholar' most frequently asked questions."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/faq" />
      </Helmet>
      <MDBRow className="flex-center pt-5 mt-3 mb-3">
        <div className="white-text pt-5">
          <img
            src={
              "https://res.cloudinary.com/unnamed/image/upload/v1579464269/Landing/Untitled_design_10_jhzdzs.png"
            }
            className="img-responsive"
            width="100%"
            height="auto"
            alt=""
          />
        </div>
      </MDBRow>
      <MDBRow>
        <MDBContainer className="pb-5">
          <MDBContainer
            className="accordion md-accordion accordion-3 z-depth-1-half "
            style={{ backgroundColor: "#fff", padding: "0" }}
          >
            <MDBNav className="justify-content-center pt-3" />
            <h2 className="text-center text-uppercase purple-text py-4 px-3">
              FAQ's{" "}
            </h2>

            <hr className="mb-0" />

            <MDBCard>
              <MDBCollapseHeader
                onClick={() =>
                  setFormData({ ...formData, collapse1: !formData.collapse1 })
                }
                tag="h3"
                tagClassName="purple-text d-flex justify-content-between align-items-center"
              >
                What is Topscholar Tutor App?
                <MDBIcon
                  icon={formData.collapse1 === true ? "angle-up" : "angle-down"}
                  className="purple-text"
                  size="2x"
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={formData.collapse1}>
                <MDBCardBody className="pt-0">
                  <p className="affiliateText4">
                    Topscholar Tutor App is a web application developed as a
                    content management system and interactive teaching tool,
                    employed by English tutors to enhance their teaching
                    experience. It serves as an organizational tool,
                    communication medium and payment processing platform as
                    well. It has everything a tutor needs to create a
                    sustainable teaching business.
                  </p>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
            <MDBCard>
              <MDBCollapseHeader
                onClick={() =>
                  setFormData({ ...formData, collapse2: !formData.collapse2 })
                }
                tag="h3"
                tagClassName="purple-text d-flex justify-content-between align-items-center"
              >
                How much does it cost?
                <MDBIcon
                  icon={formData.collapse2 === true ? "angle-up" : "angle-down"}
                  className="purple-text"
                  size="2x"
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={formData.collapse2}>
                <MDBCardBody className="pt-0">
                  <p className="affiliateText4">
                    Topscholar Tutor App is completely free to use. There are no
                    costs for using our service.
                  </p>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
            {/* <MDBCard>
              <MDBCollapseHeader
                onClick={() =>
                  setFormData({ ...formData, collapse3: !formData.collapse3 })
                }
                tag="h3"
                tagClassName="purple-text d-flex justify-content-between align-items-center"
              >
                How does the subscription work?
                <MDBIcon
                  icon={formData.collapse3 === true ? "angle-up" : "angle-down"}
                  className="purple-text"
                  size="2x"
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={formData.collapse3}>
                <MDBCardBody className="pt-0">
                  <p className="affiliateText4">
                    You will be charged at the beginning of every billing cycle
                    for the usage of the application. This charge is
                    automatically billed to your default credit card. You can
                    cancel the subscription at any time, and this will mean you
                    will not be charged when the next billing cycle comes due.
                  </p>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard> */}
            {/* <MDBCard>
              <MDBCollapseHeader
                onClick={() =>
                  setFormData({ ...formData, collapse4: !formData.collapse4 })
                }
                tag="h3"
                tagClassName="purple-text d-flex justify-content-between align-items-center"
              >
                Can I get a refund if I forget to cancel my subscription in
                time?{" "}
                <MDBIcon
                  icon={formData.collapse4 === true ? "angle-up" : "angle-down"}
                  className="purple-text"
                  size="2x"
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={formData.collapse4}>
                <MDBCardBody className="pt-0">
                  <p className="affiliateText4">
                    Unfortunately, we do not offer any refunds at this time. You
                    will always be warned 3 days before your subscription comes
                    due that you are about to be charged again.
                  </p>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard> */}
            {/* <MDBCard>
              <MDBCollapseHeader
                onClick={() =>
                  setFormData({ ...formData, collapse5: !formData.collapse5 })
                }
                tag="h3"
                tagClassName="purple-text d-flex justify-content-between align-items-center"
              >
                What happens when I cancel my subscription?{" "}
                <MDBIcon
                  icon={formData.collapse5 === true ? "angle-up" : "angle-down"}
                  className="purple-text"
                  size="2x"
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={formData.collapse5}>
                <MDBCardBody className="pt-0">
                  <p className="affiliateText4">
                    Once you cancel your subscription, you will no longer have
                    access to parts of the website at the end of your billing
                    cycle. You can still access your profile information so that
                    you can renew your subscription. After 4 months of
                    cancelling your subscription, your profile will be deleted.
                  </p>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard> */}
            <MDBCard>
              <MDBCollapseHeader
                onClick={() =>
                  setFormData({ ...formData, collapse6: !formData.collapse6 })
                }
                tag="h3"
                tagClassName="purple-text d-flex justify-content-between align-items-center"
              >
                Can I delete my account?{" "}
                <MDBIcon
                  icon={formData.collapse6 === true ? "angle-up" : "angle-down"}
                  className="purple-text"
                  size="2x"
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={formData.collapse6}>
                <MDBCardBody className="pt-0">
                  <p className="affiliateText4">
                    Yes, you can delete your account. This will result in you
                    losing all your data.
                  </p>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
            <MDBCard>
              <MDBCollapseHeader
                onClick={() =>
                  setFormData({ ...formData, collapse7: !formData.collapse7 })
                }
                tag="h3"
                tagClassName="purple-text d-flex justify-content-between align-items-center"
              >
                Can I upload documents for my lesson planning?{" "}
                <MDBIcon
                  icon={formData.collapse7 === true ? "angle-up" : "angle-down"}
                  className="purple-text"
                  size="2x"
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={formData.collapse7}>
                <MDBCardBody className="pt-0">
                  <p className="affiliateText4">
                    Topscholar allows you to upload one document per topic, with
                    a maximum file size of 10 mb. The file types accepted
                    include PDF, images, and videos. One can also link Youtube
                    videos for easy access during lessons.
                  </p>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
            <MDBCard>
              <MDBCollapseHeader
                onClick={() =>
                  setFormData({ ...formData, collapse8: !formData.collapse8 })
                }
                tag="h3"
                tagClassName="purple-text d-flex justify-content-between align-items-center"
              >
                I have forgotten my password, what can I do?{" "}
                <MDBIcon
                  icon={formData.collapse8 === true ? "angle-up" : "angle-down"}
                  className="purple-text"
                  size="2x"
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={formData.collapse8}>
                <MDBCardBody className="pt-0">
                  <p className="affiliateText4">
                    On the login page, there is a reset password link, which
                    when clicked will send an email to your account’s email.
                    This email will include steps to follow to reset your
                    password.
                  </p>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
            <MDBCard>
              <MDBCollapseHeader
                onClick={() =>
                  setFormData({ ...formData, collapse9: !formData.collapse9 })
                }
                tag="h3"
                tagClassName="purple-text d-flex justify-content-between align-items-center"
              >
                How do I charge my students for my tutoring?{" "}
                <MDBIcon
                  icon={formData.collapse9 === true ? "angle-up" : "angle-down"}
                  className="purple-text"
                  size="2x"
                />
              </MDBCollapseHeader>
              <MDBCollapse isOpen={formData.collapse9}>
                <MDBCardBody className="pt-0">
                  <p className="affiliateText4">
                    Topscholar allows you to connect your Stripe account with
                    our platform. Through our app and Stripe you can send
                    invoices to your students by email. In the email there is a
                    secure SCA-compliant payment link. You can keep track of
                    your invoices in our Payment module. In order to use Stripe
                    you need to be a resident in one of the Stripe available
                    countries found at{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={"https://stripe.com/global"}
                    >
                      stripe.com/global.
                    </a>{" "}
                    Unlike other companies, Topscholar does not take a fee when
                    you charge your students. We believe that tutors should keep
                    the fruits of their labour. However, our payment processor{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={"https://stripe.com/pricing"}
                    >
                      Stripe does charge a fee.
                    </a>
                  </p>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
          </MDBContainer>
        </MDBContainer>
      </MDBRow>
    </Fragment>
  );
};

export default withRouter(FAQ);
