import axios from "axios";
import { setAlert } from "./alert";

import {
  CUSTOMIZABLES_ERROR,
  MAKE_CUSTOMIZABLE,
  GET_CUS_EXAM_LIST,
  GET_CUS_EXAM,
  UPDATE_CUSTOMIZABLE,
  DELETE_CUSTOMIZABLE,
  GET_CUS_EXAM_EVALUATIONS,
  UPDATE_CUSEXAM_EVAL_TEACHER,
  DELETE_CUSTOMIZABLE_EVAL,
  GET_ALL_CUS_EVALS,
  CLEAR_CUSTOMIZABLES,
  SEARCH_CUSTOMIZABLES,
  SET_PAGINATION_MODE_CUSTOMIZABLES,
  CLEAR_CUSTOMIZABLES_SEARCH,
  SET_LOADING_BUTTON_CUSTOMIZABLES,
  SET_LOADING_BUTTON_CUSTOMIZABLES_EVAL,
  SET_LOADING_BUTTON_DELETE_CUSTOMIZABLES
} from "./types";

// Create a customizable exam

export const makeCustomizableExam = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/customizables/exam", formData, config);

    dispatch({
      type: MAKE_CUSTOMIZABLE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMIZABLES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all customizable exams
export const getExamList = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/customizables/get`, formData, config);

    dispatch({
      type: GET_CUS_EXAM_LIST,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMIZABLES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get the customizable exam evaluations for a particular exam

export const getSpecificExamEvaluations = formData => async dispatch => {
  try {
    const res = await axios.get(`/api/cusexameval/teacher/${formData._id}`);

    dispatch({
      type: GET_CUS_EXAM_EVALUATIONS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMIZABLES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update Exam
export const updateCusExam = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/customizables/exam/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_CUSTOMIZABLE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMIZABLES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Exam
export const getIndividualExam = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.get(
      `/api/customizables/exam/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: GET_CUS_EXAM,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMIZABLES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// delete customizable exam

export const deleteCusExam = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.delete(
      `/api/customizables/exam/${formData._id}`,
      config
    );

    dispatch({
      type: DELETE_CUSTOMIZABLE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMIZABLES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// / update customizable exam evaluation

export const updateEval = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      `/api/cusexameval/teacher/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_CUSEXAM_EVAL_TEACHER,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMIZABLES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// delete customizable exam EVALUATION

export const deleteCusExamEval = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.delete(`/api/cusexameval/${formData._id}`, config);

    dispatch({
      type: DELETE_CUSTOMIZABLE_EVAL,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMIZABLES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all customizable exam evaluations for the student

export const getEvals = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post(
      `/api/cusexameval/individualstudent`,
      formData,
      config
    );

    dispatch({
      type: GET_ALL_CUS_EVALS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMIZABLES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const clearCustomizablesReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_CUSTOMIZABLES
  });
};

// Post Search Results Exams

export const searchExamsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/customizables/search", formData, config);

    dispatch({
      type: SEARCH_CUSTOMIZABLES,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMIZABLES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Games

export const setPaginationModeExams = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_CUSTOMIZABLES,
    payload: formData
  });
};

// Set Loading Button Customizables

export const setLoadingButtonCustomizables = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_CUSTOMIZABLES,
    payload: formData
  });
};

// Set Loading Button Customizables

export const setLoadingButtonDeleteCustomizables = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_DELETE_CUSTOMIZABLES,
    payload: formData
  });
};

// Set Loading Button Customizables Evaluations

export const setLoadingButtonCustomizablesEval = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_CUSTOMIZABLES_EVAL,
    payload: formData
  });
};

// Clear Lesson Search Results

export const clearExamsSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_CUSTOMIZABLES_SEARCH
  });
};
