import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { register, setLoadingButtonRegistration } from "../../actions/auth";
import PropTypes from "prop-types";
import { MDBInput, MDBBtn, MDBSelect } from "mdbreact";
import { withRouter } from "react-router-dom";
import CountryData from "../publicPage/CountryData.json";
import { useTranslation } from "react-i18next";

const Register = ({
  setAlert,
  register,
  history,
  setLoadingButtonRegistration,
  auth: { loadingButtonRegistration }
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    screenName: "",
    countryOptions: CountryData,
    selectedCountry: ""
    // affiliate: ""
  });
  const { t } = useTranslation();

  const { name, email, password, password2 } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwords do not match", "error");
    } else {
      await setLoadingButtonRegistration(true);
      register(
        {
          name,
          email,
          password,
          country: formData.selectedCountry
          // eslint-disable-next-line no-undef
          // affiliate: Rewardful.referral
        },
        history
      );
    }
  };

  useEffect(() => {
    setFormData(prevState => {
      const newArray = prevState.countryOptions.map((item, index) => {
        return {
          ...item,
          checked: false
        };
      });
      return {
        ...prevState,
        countryOptions: newArray
      };
    });
  }, []);

  const handleSelectChangeCountry = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        selectedCountry: value[0]
      });
    }
  };

  return (
    <Fragment>
      <form onSubmit={e => onSubmit(e)}>
        <div className="registerForm">
          <MDBInput
            label={t("landing.5")}
            icon="user"
            labelClass="white-text"
            iconClass="white-text"
            type="text"
            name="name"
            value={name}
            onChange={onChange}
            required
            className="text-white"
          />
        </div>
        <div className="form-group registerForm">
          <MDBInput
            label={t("landing.6")}
            icon="envelope"
            labelClass="white-text"
            iconClass="white-text"
            type="email"
            name="email"
            value={email}
            onChange={e => onChange(e)}
            required
            className="text-white"
          />
        </div>
        <div className="form-group registerForm">
          <MDBInput
            label={t("landing.7")}
            icon="lock"
            labelClass="white-text"
            iconClass="white-text"
            type="password"
            name="password"
            value={password}
            onChange={e => onChange(e)}
            required
            className="text-white"
          />
        </div>
        <div className="form-group registerForm">
          <MDBInput
            label={t("landing.8")}
            icon="check"
            labelClass="white-text"
            iconClass="white-text"
            type="password"
            name="password2"
            value={password2}
            onChange={e => onChange(e)}
            required
            className="text-white"
          />
        </div>
        <div className="form-group">
          <MDBSelect
            label={t("landing.9")}
            className="text-white"
            labelClass="white-text"
            options={formData.countryOptions}
            getValue={handleSelectChangeCountry}
            search
          />
        </div>

        <div className="text-center mt-4">
          <MDBBtn
            outline
            color="white"
            type="submit"
            value="Register"
            disabled={loadingButtonRegistration}
          >
            {loadingButtonRegistration === true && (
              <div className="spinner-grow spinner-grow-sm" />
            )}
            {t("landing.11")}
          </MDBBtn>
        </div>
      </form>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  setAlert,
  register,
  setLoadingButtonRegistration
})(withRouter(Register));
