import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import TradingMain from "../TradingMain";
import {
  getPublishedDeck,
  downloadDeck,
  getPublishedEditedDecks,
  setLoadingButtonDownload
} from "../../../actions/trading";

const Deck = ({
  history,
  mode,
  match,
  getPublishedDeck,
  downloadDeck,
  setLoadingButtonDownload,
  trading: { publishedDeck },
  getPublishedEditedDecks,
  block
}) => {
  const [formData, setFormData] = useState({
    mode: "deck",
    block: false
  });

  useEffect(() => {
    getPublishedDeck(match.params.id);
  }, [getPublishedDeck, match]);

  const downloadDeckFunc = () => {
    setLoadingButtonDownload(true);
    downloadDeck({
      name: publishedDeck.name,
      description: publishedDeck.description,
      cards: publishedDeck.cards,
      englishLevel: publishedDeck.englishLevel,
      tradingId: publishedDeck._id
    });
  };

  useEffect(() => {
    getPublishedEditedDecks({ originalId: match.params.id });
  }, [getPublishedEditedDecks, match]);

  useEffect(() => {
    if (publishedDeck) {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "deck"
        };
      });
    }
  }, [publishedDeck]);

  useEffect(() => {
    if (block === true || block === false) {
      setFormData(prevState => {
        return {
          ...prevState,
          block: block
        };
      });
    }
  }, [block]);

  return (
    <Fragment>
      <TradingMain
        downloadDeckFunc={downloadDeckFunc}
        deckContent={publishedDeck}
        url={formData.mode}
        block={formData.block}
      ></TradingMain>
    </Fragment>
  );
};

Deck.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    getPublishedDeck,
    downloadDeck,
    getPublishedEditedDecks,
    setLoadingButtonDownload
  })(Deck)
);
