import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";
import "./flashcards.css";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import recordar from "recordar";
import { orderBy } from "natural-orderby";
import moment from "moment";
import uniqWith from "lodash/uniqWith";

import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBSelect,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
  MDBCardTitle,
  MDBCardText,
  MDBPageItem,
  MDBPageNav,
  MDBPagination,
  MDBCardHeader
} from "mdbreact";

import {
  makeDeck,
  getDecks,
  getCards,
  updateDeck,
  deleteDeck,
  makeCard,
  saveCardsToDeck,
  updateCard,
  updateSpaced,
  deleteCard,
  getSharedDecks,
  clearStudentReducer,
  searchDecksFunction,
  clearDecksSearchReducer,
  setPaginationModeDecks,
  searchCardsFunction,
  clearCardsSearchReducer,
  setPaginationModeCards,
  setLoadingButtonDecks,
  setLoadingButtonCards,
  setLoadingDeleteCard,
  setLoadingDeleteDeck
} from "../../../actions/students";

const FlashCards = ({
  clearStudentReducer,
  makeDeck,
  getDecks,
  getCards,
  updateDeck,
  deleteDeck,
  deleteCard,
  makeCard,
  updateCard,
  updateSpaced,
  saveCardsToDeck,
  getSharedDecks,
  searchDecksFunction,
  setPaginationModeDecks,
  clearDecksSearchReducer,
  searchCardsFunction,
  clearCardsSearchReducer,
  setPaginationModeCards,
  setLoadingButtonDecks,
  setLoadingButtonCards,
  setLoadingDeleteCard,
  setLoadingDeleteDeck,
  students: {
    user,
    decks,
    cards,
    loading,
    newDeck,
    newCard,
    updatedDeck,
    deletedDeck,
    deletedCard,
    updatedDeckCards,
    updatedCard,
    sharedDecks,
    searchDecks,
    searchCards,
    initialPageNumberDecks,
    initialNumberOfPagesDecks,
    numberOfPagesDecks,
    pageNumberDecks,
    initialPageNumberCards,
    initialNumberOfPagesCards,
    numberOfPagesCards,
    pageNumberCards,
    paginationModeDecks,
    paginationModeCards,
    loadingButtonCards,
    loadingButtonDecks,
    loadingDeleteCard,
    loadingDeleteDeck
  }
}) => {
  const [formData, setFormData] = useState({
    decks: [],
    filteredDecks: [],
    cards: [],
    filteredCards: [],
    englishLevelFilter: [],
    searchValue: "",
    searchValueCards: "",
    pageNumberDecks: 1,
    pageNumberCards: 1,
    searchDecks: [],
    searchCards: [],
    toggleOption: false,
    toggleNewCard: false,
    toggleNewDeck: false,
    toggleShowDeck: false,
    toggleShowIndividualDeck: false,
    toggleEditDeck: false,
    toggleShowCard: false,
    toggleShowIndividualCard: false,
    modalCreateDeck: false,
    modalDeleteDeck: false,
    modalDeleteCard: false,
    modalPlayDeck: false,
    endOfDeck: false,
    flipped: false,
    flashCardMode: "normal",
    englishLevel: [],
    sharedDecksArray: [],
    currentPracticeCard: {
      name: "",
      front: "",
      back: "",
      flipped: false,
      _id: ""
    },
    checked: {
      add: false,
      remove: false
    },
    currentDeck: {
      name: "",
      id: "",
      englishLevel: [],
      description: "",
      cards: [
        {
          name: "",
          front: "",
          back: "",
          answers: [{ type: "", time: "" }],
          score: "",
          _id: ""
        }
      ]
    },
    newDeck: {
      name: "",
      author: "",
      englishLevel: [],
      teacherId: "",
      cards: [],
      description: ""
    },
    newCard: {
      name: "",
      front: "",
      back: ""
    },
    optionLevel: [
      {
        text: "A1",
        value: "A1"
      },
      {
        text: "A2",
        value: "A2"
      },
      {
        text: "B1",
        value: "B1"
      },
      {
        text: "B2",
        value: "B2"
      },
      {
        text: "C1",
        value: "C1"
      },
      {
        text: "C2",
        value: "C2"
      },
      {
        text: "No Level",
        value: "No Level"
      }
    ],
    optionLevelNewDeck: [
      {
        text: "A1",
        value: "A1"
      },
      {
        text: "A2",
        value: "A2"
      },
      {
        text: "B1",
        value: "B1"
      },
      {
        text: "B2",
        value: "B2"
      },
      {
        text: "C1",
        value: "C1"
      },
      {
        text: "C2",
        value: "C2"
      },
      {
        text: "No Level",
        value: "No Level"
      }
    ],
    playMode: ""
  });

  const { t, i18n } = useTranslation();

  // Initialize variables

  useEffect(() => {
    getDecks({ initialPageNumberDecks: "first" });
  }, [getDecks, updatedCard, deletedCard]);

  useEffect(() => {
    if (user.sharedDecks.length !== 0) {
      getSharedDecks(user.sharedDecks);
    }
  }, [user, getSharedDecks]);

  useEffect(() => {
    if (sharedDecks) {
      setFormData(prevState => {
        return {
          ...prevState,
          sharedDecksArray: sharedDecks
        };
      });
    }
  }, [sharedDecks]);

  useEffect(() => {
    if (formData.toggleShowIndividualDeck === true) {
      setFormData(prevState => {
        const find = decks.find(item => item._id === prevState.currentDeck._id);
        return {
          ...prevState,
          decks: decks,
          filteredDecks: decks,
          currentDeck: find
        };
      });
    } else {
      setFormData(prevState => {
        return {
          ...prevState,
          decks: decks,
          filteredDecks: decks
        };
      });
    }
  }, [decks, formData.toggleShowIndividualDeck]);

  useEffect(() => {
    getCards({ initialPageNumberCards: "first" });
  }, [getCards]);

  useEffect(() => {
    if (cards.length !== 0) {
      setFormData(prevState => {
        return {
          ...prevState,
          cards: cards,
          filteredCards: cards
        };
      });
    }
  }, [cards]);

  useEffect(() => {
    if (newDeck) {
      setFormData(prevState => {
        const newArray = prevState.optionLevelNewDeck.map((item, index) => {
          return { ...item, checked: false };
        });
        return {
          ...prevState,
          decks: [...prevState.decks, newDeck],
          filteredDecks: [...prevState.decks, newDeck],
          newDeck: {
            ...prevState.newDeck,
            name: "",
            author: "",
            englishLevel: [],
            cards: [],
            description: ""
          },
          optionLevelNewDeck: newArray
        };
      });

      clearStudentReducer();
    }
  }, [newDeck, clearStudentReducer]);
  useEffect(() => {
    if (newCard) {
      if (newCard.add === true) {
        setFormData(prevState => {
          return {
            ...prevState,
            // cards: [...prevState.cards, newCard],
            // filteredCards: [...prevState.filteredCards, newCard],
            currentDeck: {
              ...prevState.currentDeck,
              cards: [...prevState.currentDeck.cards, newCard]
            },
            newCard: {
              name: "",
              front: "",
              back: ""
            }
          };
        });

        clearStudentReducer();
      } else if (newCard.add === false) {
        setFormData(prevState => {
          return {
            ...prevState,
            // cards: [...prevState.cards, newCard],
            // filteredCards: [...prevState.filteredCards, newCard],
            newCard: {
              name: "",
              front: "",
              back: ""
            }
          };
        });

        clearStudentReducer();
      }
    }
  }, [newCard, clearStudentReducer]);

  useEffect(() => {
    if (updatedDeck) {
      setFormData(prevState => {
        const newArray = prevState.decks.map((item, index) => {
          if (item._id === updatedDeck._id) {
            return updatedDeck;
          } else return item;
        });
        const newCurrentDeck = decks.find(deck => {
          return deck._id === prevState.currentDeck._id;
        });

        return {
          ...prevState,
          decks: newArray,
          filteredDecks: newArray,
          currentDeck: newCurrentDeck
        };
      });

      clearStudentReducer();
    }
  }, [updatedDeck, clearStudentReducer, decks]);
  useEffect(() => {
    if (updatedCard) {
      setFormData(prevState => {
        const newArray = prevState.cards.map((item, index) => {
          if (item._id === updatedCard._id) {
            return updatedCard;
          } else return item;
        });
        return {
          ...prevState,
          cards: newArray,
          filteredCards: newArray
        };
      });

      clearStudentReducer();
    }
  }, [updatedCard, clearStudentReducer]);
  useEffect(() => {
    if (updatedDeckCards) {
      setFormData(prevState => {
        const newArray = prevState.decks.map((item, index) => {
          if (item._id === updatedDeckCards._id) {
            return updatedDeckCards;
          } else return item;
        });
        return {
          ...prevState,
          decks: newArray,
          filteredDecks: newArray
        };
      });

      clearStudentReducer();
    }
  }, [updatedDeckCards, clearStudentReducer]);

  useEffect(() => {
    const newDecks = async () => {
      setFormData(prevState => {
        const newArray = prevState.decks.filter(
          deck => deck._id !== deletedDeck
        );
        return {
          ...prevState,
          decks: newArray,
          filteredDecks: newArray,
          currentDeck: {
            name: "",
            id: "",
            englishLevel: [],
            description: "",
            cards: [
              {
                name: "",
                front: "",
                back: ""
              }
            ]
          },
          modalDeleteDeck: false,
          toggleEditDeck: false,
          newDeck: {
            ...prevState.newDeck,
            name: "",
            author: "",
            englishLevel: [],
            cards: [],
            description: ""
          }
        };
      });

      clearStudentReducer();
    };

    if (deletedDeck) {
      newDecks();
    }
  }, [deletedDeck, clearStudentReducer]);
  useEffect(() => {
    const newCards = async () => {
      setFormData(prevState => {
        const newArray = prevState.cards.filter(
          card => card._id !== deletedCard
        );
        return {
          ...prevState,
          cards: newArray,
          filteredCards: newArray,
          currentCard: {
            name: "",
            front: "",
            back: ""
          },
          modalDeleteCard: false,
          toggleNewCard: false,
          toggleShowIndividualCard: false,
          newCard: {
            name: "",
            front: "",
            back: ""
          }
        };
      });
    };

    if (deletedCard) {
      newCards();
    }
  }, [deletedCard]);

  // Deck Search
  const deckSearch = event =>
    setFormData({ ...formData, searchValue: event.target.value });
  const cardSearch = event =>
    setFormData({ ...formData, searchValueCards: event.target.value });

  const handleSelectChangeLanding = value => {
    setFormData({
      ...formData,
      englishLevel: value
    });
  };

  useEffect(() => {
    const checked = formData.optionLevel.filter(item => item.checked === true);

    let newArray = [];

    if (checked.length !== 0) {
      decks.forEach(x => {
        x.englishLevel.forEach(h => {
          const nn = formData.englishLevel.filter(item => item === h);
          if (nn.length !== 0) {
            newArray.push(x);

            return x;
          }
        });
      });

      const newArray2 = uniqWith(newArray, "_id");

      setFormData(prevState => {
        return {
          ...prevState,
          englishLevelFilter: newArray2,
          decks: newArray2,
          filteredDecks: newArray2,
          searchValue: ""
        };
      });
    } else {
      setFormData(prevState => {
        return {
          ...prevState,
          englishLevelFilter: [],
          decks: decks,
          filteredDecks: decks
        };
      });
    }
  }, [formData.englishLevel, formData.optionLevel, decks]);

  // Create Decks/Cards Toggle
  const modalCreateDeck = () => {
    const newArray = formData.optionLevel.map((item, index) => {
      return {
        ...item,
        checked: false
      };
    });
    setFormData({
      ...formData,
      modalCreateDeck: !formData.modalCreateDeck,
      decks: decks,
      filteredDecks: decks,
      optionLevel: newArray,
      searchValue: "",
      newDeck: {
        ...formData.newDeck,
        teacherId: user.userTutor
      }
    });
  };

  const toggleOptionVar = formData.toggleOption === true ? "" : "d-none";

  // New Card

  const createCard = async value => {
    await setLoadingButtonCards(true);
    if (value.add === true) {
      const newCard = {
        name: formData.newCard.name,
        front: formData.newCard.front,
        back: formData.newCard.back,
        add: true,
        teacherId: user.userTutor
      };
      makeCard({ ...newCard, language: i18n.language });
    } else if (value.add === false) {
      const newCard = {
        name: formData.newCard.name,
        front: formData.newCard.front,
        back: formData.newCard.back,
        add: false,
        teacherId: user.userTutor
      };
      makeCard({ ...newCard, language: i18n.language });
    }
  };

  const toggleNewCard = () => {
    setFormData({
      ...formData,
      toggleNewCard: !formData.toggleNewCard,
      toggleShowIndividualCard: false,
      newCard: {
        name: "",
        front: "",
        back: "",
        teacherId: user.userTutor
      },
      toggleShowCard: false
    });
  };
  const toggleNewCardVar = formData.toggleNewCard === true ? "mb-5" : "d-none";

  const onChangeNewCard = e =>
    setFormData({
      ...formData,
      newCard: {
        ...formData.newCard,
        [e.target.name]: e.target.value
      }
    });

  // New Deck

  const handleSelectChange = value => {
    setFormData({
      ...formData,
      newDeck: {
        ...formData.newDeck,
        englishLevel: value
      }
    });
  };

  const createDeck = async () => {
    await setLoadingButtonDecks(true);
    makeDeck({ ...formData.newDeck, language: i18n.language });
  };
  const updateDeckFunc = async () => {
    await setLoadingButtonDecks(true);
    updateDeck({ ...formData.newDeck, language: i18n.language });
  };
  const saveCardsToDeckFunc = async () => {
    saveCardsToDeck({ ...formData.currentDeck, language: i18n.language });
  };

  const toggleNewDeck = () => {
    const newArray = formData.optionLevelNewDeck.map((item, index) => {
      return { ...item, checked: false };
    });
    setFormData({
      ...formData,
      toggleNewDeck: !formData.toggleNewDeck,
      toggleShowIndividualDeck: false,
      toggleShowDeck: false,
      toggleEditDeck: false,
      optionLevelNewDeck: newArray,
      newDeck: {
        name: "",
        author: "",
        englishLevel: [],
        teacherId: user.userTutor,
        cards: [],
        description: ""
      }
    });
  };
  const toggleNewDeckVar = formData.toggleNewDeck === true ? "" : "d-none";

  const onChangeNewDeck = e =>
    setFormData({
      ...formData,
      newDeck: {
        ...formData.newDeck,
        [e.target.name]: e.target.value
      }
    });

  // Edit Decks

  const toggleShowDeck = () => {
    const newArray = formData.optionLevelNewDeck.map((item, index) => {
      return { ...item, checked: false };
    });
    setFormData({
      ...formData,
      toggleShowDeck: !formData.toggleShowDeck,
      toggleShowIndividualDeck: false,
      toggleNewDeck: false,
      toggleEditDeck: false,
      optionLevelNewDeck: newArray,
      newDeck: {
        ...formData.newDeck,
        name: "",
        author: "",
        englishLevel: [],
        cards: [],
        description: ""
      }
    });
  };
  const toggleEditDeck = () => {
    const newArray = formData.optionLevelNewDeck.map((item, index) => {
      const curr = formData.currentDeck.englishLevel.filter(
        value => value === item.value
      );
      if (curr.length !== 0) {
        return { ...item, checked: true };
      } else {
        return item;
      }
    });

    setFormData({
      ...formData,
      toggleShowIndividualDeck: false,
      toggleEditDeck: !formData.toggleEditDeck,
      optionLevelNewDeck: newArray,
      newDeck: formData.currentDeck
    });
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        checked: {
          add: false,
          remove: false
        }
      };
    });
  }, [formData.toggleShowIndividualDeck]);

  const showIndividualDeck = deck => {
    setFormData({
      ...formData,
      toggleShowDeck: false,
      toggleEditDeck: false,
      toggleShowIndividualDeck: true,
      currentDeck: {
        name: deck.name,
        _id: deck._id,
        englishLevel: deck.englishLevel,
        cards: deck.cards,
        description: deck.description
      }
    });
  };

  const toggleShowDeckVar = formData.toggleShowDeck === true ? "" : "d-none";
  const toggleShowIndividualDeckVar =
    formData.toggleShowIndividualDeck === true ? "mb-5" : "d-none";
  const toggleEditDeckVar = formData.toggleEditDeck === true ? "" : "d-none";

  const addToCurrentDeck = card => {
    if (formData.checked.add === true) {
      const filter = formData.currentDeck.cards.filter(
        item => item._id === card._id
      );

      if (filter.length !== 0) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>This card is already in the deck</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else {
        setFormData({
          ...formData,
          currentDeck: {
            ...formData.currentDeck,
            cards: [...formData.currentDeck.cards, card]
          }
        });
      }
    } else if (formData.checked.add === false) {
      setFormData({
        ...formData,
        toggleShowIndividualCard: true,
        toggleShowCard: false,
        newCard: card
      });
    }
  };
  const removeFromCurrentDeck = card => {
    if (formData.checked.remove === true) {
      const newArray = formData.currentDeck.cards.filter(
        item => item._id !== card._id
      );

      setFormData({
        ...formData,
        currentDeck: {
          ...formData.currentDeck,
          cards: newArray
        }
      });
    } else if (formData.checked.add === false) {
      setFormData({
        ...formData,
        toggleShowIndividualCard: true,
        toggleShowCard: false,
        newCard: card,
        toggleNewCard: false
      });
    }
  };

  const backToCard = () => {
    setFormData({
      ...formData,
      toggleEditDeck: false,
      toggleShowIndividualDeck: true
    });
  };

  const modalDeleteDeck = () => {
    setFormData({
      ...formData,
      modalDeleteDeck: !formData.modalDeleteDeck
    });
  };
  const modalDeleteCard = () => {
    setFormData({
      ...formData,
      modalDeleteCard: !formData.modalDeleteCard
    });
  };

  const deleteDeckFunc = () => {
    setLoadingDeleteDeck(true);
    deleteDeck({ deckId: formData.currentDeck._id, language: i18n.language });
  };
  const deleteCardFunc = () => {
    setLoadingDeleteCard(true);
    deleteCard({ cardId: formData.newCard._id, language: i18n.language });
  };

  // Edit Cards
  const toggleShowCardVar = formData.toggleShowCard === true ? "" : "d-none";
  const toggleShowIndividualCardVar =
    formData.toggleShowIndividualCard === true ? "mb-5" : "d-none";

  const toggleShowCard = () => {
    setFormData({
      ...formData,
      toggleShowCard: !formData.toggleShowCard,
      toggleShowIndividualCard: false,
      toggleNewCard: false,
      newCard: {
        name: "",
        front: "",
        back: ""
      },
      currentCard: {
        name: "",
        front: "",
        back: ""
      }
    });
  };

  const onChangeCheck = (e, value) => {
    setFormData({
      ...formData,
      checked: { ...formData.checked, [e.target.name]: value }
    });
  };

  const updateCardFunc = async () => {
    await setLoadingButtonCards(true);
    updateCard({ ...formData.newCard, language: i18n.language });
  };

  // Flash Card Game

  const modalPlayDeck = (item, mode) => {
    if (item.cards.length !== 0) {
      setFormData({
        ...formData,
        modalPlayDeck: !formData.modalPlayDeck,
        currentDeck: {
          name: item.name,
          description: item.description,
          englishLevel: item.englishLevel,
          _id: item._id,
          cards: item.cards
        },
        currentPracticeCard: {
          name: item.cards[0].name,
          front: item.cards[0].front,
          back: item.cards[0].back,
          _id: item.cards[0]._id,
          index: 0,
          answers: item.cards[0].answers
        },
        playMode: mode
      });
    } else {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>{t("other.8")}</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    }
  };

  const closemodalPlayDeck = () => {
    setFormData({
      ...formData,
      modalPlayDeck: !formData.modalPlayDeck,
      currentDeck: {
        name: "",
        description: "",
        englishLevel: [],
        _id: "",
        cards: []
      },
      flipped: false,
      flashCardMode: "normal"
    });
  };

  const handleFlipping = deck => {
    setFormData({
      ...formData,
      flipped: !formData.flipped,
      currentPracticeCard: {
        ...formData.currentPracticeCard
      }
    });
  };

  const showHideButtons = formData.flashCardMode === "spaced" ? "" : "d-none";
  const showHideButtons2 = formData.flashCardMode === "normal" ? "" : "d-none";
  const showHideButtons3 = formData.flashCardMode === "random" ? "" : "d-none";

  const normalModeVar =
    formData.flashCardMode === "normal" ? "pink-text pl-3" : "white-text pl-3";

  const normalMode = () => {
    setFormData({
      ...formData,
      flashCardMode: "normal",
      flipped: false,
      currentPracticeCard: {
        name: formData.currentDeck.cards[0].name,
        front: formData.currentDeck.cards[0].front,
        back: formData.currentDeck.cards[0].back,
        _id: formData.currentDeck.cards[0]._id,
        index: 0,
        answers2: formData.currentDeck.cards[0].answers2
      }
    });
  };
  const randomModeVar =
    formData.flashCardMode === "random" ? "pink-text pl-3" : "white-text pl-3";

  const randomMode = () => {
    const itemIndex = Math.floor(
      Math.random() * formData.currentDeck.cards.length
    );
    setFormData({
      ...formData,
      flashCardMode: "random",
      flipped: false,
      currentPracticeCard: {
        name: formData.currentDeck.cards[itemIndex].name,
        front: formData.currentDeck.cards[itemIndex].front,
        back: formData.currentDeck.cards[itemIndex].back,
        _id: formData.currentDeck.cards[itemIndex]._id,
        answers2: formData.currentDeck.cards[itemIndex].answers2
      }
    });
  };
  const spacedModeVar =
    formData.flashCardMode === "spaced" ? "pink-text pl-3" : "white-text pl-3";

  const spacedMode = async () => {
    const newArray = formData.currentDeck.cards.map((item, index) => {
      if (item.answers.length === 0) {
        return {
          ...item,
          score: 0.5
        };
      } else {
        const newArray2 = item.answers.map((answer, index) => {
          return {
            ...answer,
            time: moment(answer.time).toDate()
          };
        });

        return recordar(newArray2, [1200]).then(score => {
          return {
            ...item,
            answers: newArray2,
            score: score
          };
        });
      }
    });

    await Promise.all(newArray).then(async function(values) {
      const sortedUsers = orderBy(values, [v => v.score], ["asc"]);

      setFormData({
        ...formData,
        flashCardMode: "spaced",
        currentDeck: {
          ...formData.currentDeck,
          cards: sortedUsers
        },
        flipped: false,
        currentPracticeCard: {
          ...sortedUsers[0],
          name: sortedUsers[0].name,
          front: sortedUsers[0].front,
          back: sortedUsers[0].back,
          _id: sortedUsers[0]._id,
          answers2: sortedUsers[0].answers2,
          score: sortedUsers[0].score
        }
      });
    });
  };

  const cardAnswer = async answer => {
    if (answer === "right") {
      const date = new Date();
      const newAnswer = { type: "good", time: date };
      const newArray = [...formData.currentPracticeCard.answers, newAnswer];
      const newArray3 = formData.currentDeck.cards.map((item, index) => {
        if (item._id === formData.currentPracticeCard._id) {
          return recordar(newArray, [1200]).then(score => {
            return {
              ...item,
              answers: newArray,
              score: score
            };
          });
        } else if (item.answers.length === 0) {
          return {
            ...item,
            score: 0.5
          };
        } else {
          const newArray2 = item.answers.map((answer, index) => {
            return {
              ...answer,
              time: moment(answer.time).toDate()
            };
          });

          return recordar(newArray2, [1200]).then(score => {
            return {
              ...item,
              answers: newArray2,
              score: score
            };
          });
        }
      });

      await Promise.all(newArray3).then(async function(values) {
        const sortedUsers = orderBy(values, [v => v.score], ["asc"]);

        setFormData({
          ...formData,
          currentDeck: {
            ...formData.currentDeck,
            cards: sortedUsers
          },
          flipped: false,
          currentPracticeCard: {
            name: sortedUsers[0].name,
            front: sortedUsers[0].front,
            back: sortedUsers[0].back,
            _id: sortedUsers[0]._id,
            answers: sortedUsers[0].answers,
            score: sortedUsers[0].score
          }
        });
      });
    } else if (answer === "wrong") {
      const date = new Date();
      const newAnswer = { type: "bad", time: date };
      const newArray = [...formData.currentPracticeCard.answers, newAnswer];
      const newArray3 = formData.currentDeck.cards.map((item, index) => {
        if (item._id === formData.currentPracticeCard._id) {
          return recordar(newArray, [1200]).then(score => {
            return {
              ...item,
              answers: newArray,
              score: score
            };
          });
        } else if (item.answers.length === 0) {
          return {
            ...item,
            score: 0.5
          };
        } else {
          const newArray2 = item.answers.map((answer, index) => {
            return {
              ...answer,
              time: moment(answer.time).toDate()
            };
          });

          return recordar(newArray2, [1200]).then(score => {
            return {
              ...item,
              answers: newArray2,
              score: score
            };
          });
        }
      });

      await Promise.all(newArray3).then(async function(values) {
        const sortedUsers = orderBy(values, [v => v.score], ["asc"]);

        setFormData({
          ...formData,
          currentDeck: {
            ...formData.currentDeck,
            cards: sortedUsers
          },
          flipped: false,
          currentPracticeCard: {
            name: sortedUsers[0].name,
            front: sortedUsers[0].front,
            back: sortedUsers[0].back,
            _id: sortedUsers[0]._id,
            answers: sortedUsers[0].answers,
            score: sortedUsers[0].score
          }
        });
      });
    }
  };

  const saveSpace = () => {
    const newCardsArray = formData.currentDeck.cards.map((item, index) => {
      return {
        ...item,
        name: item.name,
        front: item.front,
        back: item.back,
        answers: item.answers.slice(-20),
        score: item.score,
        _id: item._id
      };
    });

    const newSavedObject = {
      name: formData.currentDeck.name,
      _id: formData.currentDeck._id,
      englishLevel: formData.currentDeck.englishLevel,
      description: formData.currentDeck.description,
      cards: newCardsArray,
      language: i18n.language
    };
    updateSpaced(newSavedObject);
  };
  const nextCard = answer => {
    const deckLength = formData.currentDeck.cards.length;
    const currentIndex = formData.currentDeck.cards.findIndex(
      card => card._id === formData.currentPracticeCard._id
    );

    if (currentIndex === deckLength - 1) {
      setFormData({
        ...formData,
        endOfDeck: true
      });
    } else {
      setFormData({
        ...formData,
        flipped: false,
        currentPracticeCard: {
          name: formData.currentDeck.cards[currentIndex + 1].name,
          front: formData.currentDeck.cards[currentIndex + 1].front,
          back: formData.currentDeck.cards[currentIndex + 1].back,
          _id: formData.currentDeck.cards[currentIndex + 1]._id,
          index: currentIndex + 1
        }
      });
    }
  };
  const previousCard = answer => {
    const currentIndex = formData.currentDeck.cards.findIndex(
      card => card._id === formData.currentPracticeCard._id
    );

    setFormData({
      ...formData,
      flipped: false,
      currentPracticeCard: {
        name: formData.currentDeck.cards[currentIndex - 1].name,
        front: formData.currentDeck.cards[currentIndex - 1].front,
        back: formData.currentDeck.cards[currentIndex - 1].back,
        _id: formData.currentDeck.cards[currentIndex - 1]._id,
        index: currentIndex - 1
      }
    });
  };

  const restartDeck = () => {
    setFormData({
      ...formData,
      endOfDeck: false,
      flipped: false,
      currentPracticeCard: {
        name: formData.currentDeck.cards[0].name,
        front: formData.currentDeck.cards[0].front,
        back: formData.currentDeck.cards[0].back,
        _id: formData.currentDeck.cards[0]._id,
        index: 0
      }
    });
  };

  const keyDown = event => {
    var key = event.keyCode;
    if (key === 37) {
      if (
        formData.flashCardMode === "spaced" &&
        formData.flipped === true &&
        formData.currentPracticeCard.score !== 0
      ) {
        cardAnswer("wrong");
      } else if (
        formData.currentPracticeCard.index !== 0 &&
        formData.flashCardMode === "normal"
      ) {
        previousCard();
      }
    } else if (key === 39) {
      if (
        formData.flashCardMode === "spaced" &&
        formData.flipped === true &&
        formData.currentPracticeCard.score !== 0
      ) {
        cardAnswer("right");
      } else if (
        formData.flashCardMode === "normal" &&
        formData.flipped === true
      ) {
        nextCard();
      } else if (
        formData.flashCardMode === "random" &&
        formData.flipped === true
      ) {
        randomMode();
      }
    } else if (key === 32) {
      if (formData.flashCardMode === "spaced" || "normal" || "random") {
        if (formData.flipped === false) {
          handleFlipping(formData.currentDeck);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyDown);
    return () => {
      document.removeEventListener("keydown", keyDown);
    };
  });

  // remove double scroll bar when in modal mode
  useEffect(() => {
    if (formData.modalCreateDeck === true || formData.modalPlayDeck === true) {
      document.body.style.cssText = "overflow-y: hidden !important;";
    } else {
      document.body.style.overflowY = "";
    }
  }, [formData.modalCreateDeck, formData.modalPlayDeck]);

  // Pagination Decks

  const onKeyPressEnterDecks = e => {
    if (e.key === "Enter") {
      decksSearchFunc();
    }
  };

  useEffect(() => {
    if (searchDecks) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchDecks: searchDecks
        };
      });
    }
  }, [searchDecks]);

  const decksSearchFunc = () => {
    searchDecksFunction({
      keywords: formData.searchValue,
      pageNumberDecks: formData.pageNumberDecks
    });
    setPaginationModeDecks("search");
  };

  const clearDecksFunc = () => {
    setFormData({
      ...formData,
      searchValue: "",
      searchDecks: []
    });
    clearDecksSearchReducer();
    setPaginationModeDecks("date");
    return formData.filteredDecks;
  };

  const searchDecksMap = () => {
    if (formData.searchDecks === "No Results") {
      return [];
    } else if (formData.searchDecks.length !== 0) {
      return formData.searchDecks;
    } else {
      return formData.filteredDecks;
    }
  };

  const isActive1 = page => {
    if (initialPageNumberDecks === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumberDecks === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationDecks = () => {
    if (paginationModeDecks === "date") {
      getDecks({ initialPageNumberDecks: "first" });
    } else if (paginationModeDecks === "search") {
      searchDecksFunction({
        pageNumberDecks: "first",
        keywords: formData.searchValue
      });
    }
  };

  const previousPaginationDecks = () => {
    if (paginationModeDecks === "date") {
      getDecks({ initialPageNumberDecks: initialPageNumberDecks - 1 });
    } else if (paginationModeDecks === "search") {
      searchDecksFunction({
        pageNumberDecks: pageNumberDecks - 1,
        keywords: formData.searchValue
      });
    }
  };

  const currentPaginationDecks = index => {
    if (paginationModeDecks === "date") {
      getDecks({ initialPageNumberDecks: index + 1 });
    } else if (paginationModeDecks === "search") {
      searchDecksFunction({
        pageNumberDecks: index + 1,
        keywords: formData.searchValue
      });
    }
  };

  const nextPaginationDecks = () => {
    if (paginationModeDecks === "date") {
      getDecks({ initialPageNumberDecks: initialPageNumberDecks + 1 });
    } else if (paginationModeDecks === "search") {
      searchDecksFunction({
        pageNumberDecks: pageNumberDecks + 1,
        keywords: formData.searchValue
      });
    }
  };

  const lastPaginationDecks = () => {
    if (paginationModeDecks === "date") {
      getDecks({ initialPageNumberDecks: "last" });
    } else if (paginationModeDecks === "search") {
      searchDecksFunction({
        pageNumberDecks: "last",
        keywords: formData.searchValue
      });
    }
  };

  // Pagination Cards

  const isActive3 = page => {
    if (initialPageNumberCards === page) {
      return true;
    } else {
      return false;
    }
  };
  const isActive4 = page => {
    if (pageNumberCards === page) {
      return true;
    } else {
      return false;
    }
  };

  const onKeyPressEnterCards = e => {
    if (e.key === "Enter") {
      cardsSearchFunc();
    }
  };

  useEffect(() => {
    if (searchCards) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchCards: searchCards
        };
      });
    }
  }, [searchCards]);

  const cardsSearchFunc = () => {
    searchCardsFunction({
      keywordsCards: formData.searchValueCards,
      pageNumberCards: formData.pageNumberCards
    });
    setPaginationModeCards("search");
  };

  const clearCardsFunc = () => {
    setFormData({
      ...formData,
      searchValueCards: "",
      searchCards: []
    });
    clearCardsSearchReducer();
    setPaginationModeCards("date");
    return formData.filteredCards;
  };

  const searchCardsMap = () => {
    if (formData.searchCards === "No Results") {
      return [];
    } else if (formData.searchCards.length !== 0) {
      return formData.searchCards;
    } else {
      return formData.filteredCards;
    }
  };

  const firstPaginationCards = () => {
    if (paginationModeCards === "date") {
      getCards({ initialPageNumberCards: "first" });
    } else if (paginationModeCards === "search") {
      searchCardsFunction({
        pageNumberCards: "first",
        keywordsCards: formData.searchValueCards
      });
    }
  };

  const previousPaginationCards = () => {
    if (paginationModeCards === "date") {
      getCards({ initialPageNumberCards: initialPageNumberCards - 1 });
    } else if (paginationModeCards === "search") {
      searchCardsFunction({
        pageNumberCards: pageNumberCards - 1,
        keywordsCards: formData.searchValueCards
      });
    }
  };

  const currentPaginationCards = index => {
    if (paginationModeCards === "date") {
      getCards({ initialPageNumberCards: index + 1 });
    } else if (paginationModeCards === "search") {
      searchCardsFunction({
        pageNumberCards: index + 1,
        keywordsCards: formData.searchValueCards
      });
    }
  };

  const nextPaginationCards = () => {
    if (paginationModeCards === "date") {
      getCards({ initialPageNumberCards: initialPageNumberCards + 1 });
    } else if (paginationModeCards === "search") {
      searchCardsFunction({
        pageNumberCards: pageNumberCards + 1,
        keywordsCards: formData.searchValueCards
      });
    }
  };

  const lastPaginationCards = () => {
    if (paginationModeCards === "date") {
      getCards({ initialPageNumberCards: "last" });
    } else if (paginationModeCards === "search") {
      searchCardsFunction({
        pageNumberCards: "last",
        keywordsCards: formData.searchValueCards
      });
    }
  };

  return loading ? (
    Spinner
  ) : user.view && user.view.decks === false ? (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student Flashcards</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className=" justify-content-center align-items-center mt-5 mb-5">
        <MDBRow className="pt-5 mt-3" center>
          <MDBCard narrow>
            <MDBCardHeader className="view view-cascade  primary-color d-flex justify-content-between align-items-center py-2 mx-4 mb-1 ">
              <MDBCol className="text-center">
                <p className="h5 quickSand white-text mx-2">
                  {t("studentDashboard.11")}
                </p>
              </MDBCol>
            </MDBCardHeader>
            <MDBCardBody cascade>
              <p className="quickSand black-text h5">{t("studentLessons.2")}</p>
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  ) : (
    <Fragment>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow className="mt-3" center>
          <MDBCol md="12">
            <MDBCard className="mt-5 bgc cardMarginBotton">
              <MDBRow className="pt-3" end>
                <MDBCol className="col-2" sm="2"></MDBCol>
                <MDBCol sm="4" className="px-4 col-6">
                  <MDBInput
                    className="white-text"
                    label={t("studentFlashcards.1")}
                    labelClass="white-text"
                    type="text"
                    containerClass="active-pink active-pink-2 mt-0 mb-3"
                    value={formData.searchValue}
                    onChange={deckSearch}
                    onKeyPress={onKeyPressEnterDecks}
                  />
                </MDBCol>
                <MDBCol className="col-2" sm="2">
                  <MDBRow className="align-items-center">
                    <div
                      className={
                        formData.searchValue.length !== 0 ? "mx-1" : "d-none"
                      }
                    >
                      <MDBIcon
                        icon="search"
                        onClick={decksSearchFunc}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      className={
                        formData.searchDecks.length !== 0 ||
                        formData.searchDecks === "No Results"
                          ? "mx-1"
                          : "d-none"
                      }
                    >
                      <MDBIcon
                        icon="times-circle"
                        onClick={clearDecksFunc}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="2" sm="2" className="col-2">
                  <MDBIcon
                    pull="right"
                    size="2x"
                    icon="pen"
                    style={{ cursor: "pointer" }}
                    className=" white-text pr-3"
                    onClick={modalCreateDeck}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className={toggleOptionVar}>
                <MDBCol md="3" className="mx-3">
                  <MDBSelect
                    options={formData.optionLevel}
                    getValue={handleSelectChangeLanding}
                    color="primary"
                    label={t("studentFlashcards.36")}
                    labelClass="white-text"
                    multiple
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow center className="my-4 mx-1">
                <div
                  className={
                    formData.searchDecks === "No Results" ? "mt-2" : "d-none"
                  }
                >
                  <p className="quickSand h4 black-text text-center">
                    There are no decks results for your search. Try Again.
                  </p>
                </div>
                {searchDecksMap().map((item, index) => {
                  return (
                    <MDBCol
                      key={index}
                      md="auto"
                      lg="auto"
                      sm="auto"
                      className=" mt-3  mx-md-0 col-auto mx-1"
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => modalPlayDeck(item, "notShared")}
                      >
                        <MDBCard className=" z-depth-1  my-auto">
                          <MDBCardBody>
                            <p className="CarterOne h4-responsive text-center text-pink  my-auto">
                              {item.name.charAt(0).toUpperCase() +
                                item.name.slice(1)}
                            </p>
                          </MDBCardBody>
                        </MDBCard>
                      </div>
                    </MDBCol>
                  );
                })}
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        {/* Pagination For Decks Medium Size */}

        <div
          className={
            formData.modalCreateDeck === false
              ? "middleVertical mb-5 paginationFlashcardsMedium"
              : "d-none"
          }
        >
          <MDBRow>
            <MDBCol>
              <MDBPagination circle>
                <MDBPageItem onClick={firstPaginationDecks}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationDecks}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationModeDecks === "date"
                  ? [...Array(initialNumberOfPagesDecks)].map((item, index) => {
                      if (index + 1 === initialPageNumberDecks) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPagesDecks)].map((item, index) => {
                      if (index + 1 === pageNumberDecks) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationDecks}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationDecks}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>

        {/* Pagination For Decks Small Size */}

        <div
          className={
            formData.modalCreateDeck === false
              ? "middleVertical mt-3 paginationFlashcardsSmall"
              : "d-none"
          }
        >
          <MDBRow>
            <MDBCol>
              <MDBPagination circle size="sm">
                <MDBPageItem onClick={firstPaginationDecks}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationDecks}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationModeDecks === "date"
                  ? [...Array(initialNumberOfPagesDecks)].map((item, index) => {
                      if (index + 1 === initialPageNumberDecks) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumberDecks + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPagesDecks)].map((item, index) => {
                      if (index + 1 === pageNumberDecks) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberDecks + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationDecks}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationDecks}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
      <MDBContainer className="mt-3 mb-5">
        <MDBRow className="mt-3 " center>
          <MDBCol md="12">
            <MDBCard className="mt-5 darkbgc">
              <MDBRow center>
                <p className="text-white text-center h4 pt-4 CarterOne">
                  {t("studentFlashcards.2")}
                </p>
              </MDBRow>
              <MDBRow center className="mt-4 mx-1">
                {formData.sharedDecksArray.map((item, index) => {
                  return (
                    <MDBCol
                      key={index}
                      md="auto"
                      lg="auto"
                      sm="auto"
                      className=" mt-3  mx-md-0 col-auto mx-1"
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => modalPlayDeck(item, "shared")}
                      >
                        <MDBCard className=" z-depth-1  my-auto bgc">
                          <MDBCardBody>
                            <p className="CarterOne h4-responsive text-center text-white  my-auto">
                              {item.name.charAt(0).toUpperCase() +
                                item.name.slice(1)}
                            </p>
                          </MDBCardBody>
                        </MDBCard>
                      </div>
                    </MDBCol>
                  );
                })}
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {/* Create Deck/Cards Modal */}
      <div className="flashcardModal">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalCreateDeck}
            toggle={modalCreateDeck}
            className="modal-full "
          >
            <div className="modal-full modal-content">
              <MDBModalBody>
                <MDBRow>
                  {/* Decks */}
                  <MDBCol md="6" sm="6" className="col-12 mb-5">
                    <MDBRow center>
                      <p className="FredokaOne h3 text-white">
                        {t("studentFlashcards.3")}
                      </p>
                    </MDBRow>
                    <MDBRow className="mt-3" center>
                      <MDBIcon
                        style={{ cursor: "pointer" }}
                        onClick={toggleNewDeck}
                        icon="fas fa-plus-circle"
                        className="white-text mx-3"
                        size="3x"
                      />
                      <MDBIcon
                        style={{ cursor: "pointer" }}
                        onClick={toggleShowDeck}
                        icon="fas fa-ellipsis-h"
                        className="white-text mx-3"
                        size="3x"
                      />
                    </MDBRow>
                    <MDBRow mt="2" center className={toggleNewDeckVar}>
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <MDBInput
                          className="white-text"
                          name="name"
                          value={formData.newDeck.name}
                          label={t("studentFlashcards.41")}
                          labelClass="text-white"
                          onChange={onChangeNewDeck}
                        />
                        <MDBInput
                          className="white-text"
                          name="description"
                          value={formData.newDeck.description}
                          label={t("studentFlashcards.40")}
                          labelClass="text-white"
                          onChange={onChangeNewDeck}
                        />
                      </MDBCol>
                      <div className="w-100" />
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <MDBSelect
                          options={formData.optionLevelNewDeck}
                          getValue={handleSelectChange}
                          color="dark"
                          label={t("studentFlashcards.36")}
                          labelClass="white-text"
                          multiple
                        />
                      </MDBCol>
                      <div className="w-100" />
                      <MDBBtn
                        onClick={createDeck}
                        disabled={loadingButtonDecks}
                        color="pink"
                      >
                        {loadingButtonDecks === true && (
                          <div className="spinner-grow spinner-grow-sm" />
                        )}
                        {t("studentFlashcards.30")}
                      </MDBBtn>
                    </MDBRow>
                    <MDBRow mt="2" center className={toggleShowDeckVar}>
                      <MDBRow center className="middleVertical5">
                        <div className="mr-3">
                          <MDBInput
                            className="white-text mt-3"
                            label={t("studentFlashcards.1")}
                            labelClass="white-text"
                            type="text"
                            containerClass="active-pink active-pink-2 mt-0 mb-3"
                            value={formData.searchValue}
                            onChange={deckSearch}
                            onKeyPress={onKeyPressEnterDecks}
                          />
                        </div>
                        <div
                          className={
                            formData.searchValue.length !== 0
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="search"
                            onClick={decksSearchFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className={
                            formData.searchDecks.length !== 0 ||
                            formData.searchDecks === "No Results"
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="times-circle"
                            onClick={clearDecksFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </MDBRow>
                      <div className="w-100" />
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <div style={{ overflowY: "auto" }}>
                          <MDBListGroup style={{ height: "23rem" }}>
                            <div
                              className={
                                formData.searchDecks === "No Results"
                                  ? "mt-2"
                                  : "d-none"
                              }
                            >
                              <p className="quickSand h4 black-text text-center">
                                There are no decks results for your search. Try
                                Again.
                              </p>
                            </div>
                            {searchDecksMap().map((item, index) => {
                              const englishLevel = item.englishLevel.map(
                                (item2, index) => {
                                  if (index === item.englishLevel.length - 1) {
                                    return ` ${item2}`;
                                  }
                                  return ` ${item2},`;
                                }
                              );
                              return (
                                <MDBListGroupItem
                                  key={item._id}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => showIndividualDeck(item)}
                                  className=" CarterOne listItem text-white my-1 z-depth-2"
                                >
                                  <MDBRow>
                                    <MDBCol size="auto" className="mr-auto">
                                      {item.name}
                                    </MDBCol>
                                    <MDBCol size="auto">
                                      <p>
                                        {t("studentFlashcards.39")}{" "}
                                        {englishLevel}
                                      </p>
                                    </MDBCol>
                                  </MDBRow>
                                </MDBListGroupItem>
                              );
                            })}
                          </MDBListGroup>
                        </div>
                        {/* Pagination for decks medium size (inside the modal) */}

                        <div
                          className={
                            formData.toggleShowDeck === true
                              ? "mt-2 middleVertical paginationFlashcardsMedium"
                              : "d-none"
                          }
                        >
                          <MDBRow>
                            <MDBCol>
                              <MDBPagination circle>
                                <MDBPageItem onClick={firstPaginationDecks}>
                                  <MDBPageNav className="page-link">
                                    <span>First</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                                <MDBPageItem onClick={previousPaginationDecks}>
                                  <MDBPageNav
                                    className="page-link"
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">&laquo;</span>
                                    <span className="sr-only">Previous</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                                {paginationModeDecks === "date"
                                  ? [...Array(initialNumberOfPagesDecks)].map(
                                      (item, index) => {
                                        if (
                                          index + 1 ===
                                          initialPageNumberDecks
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              active={isActive1(index + 1)}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberDecks - 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberDecks - 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberDecks - 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberDecks + 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberDecks + 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberDecks + 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else {
                                          return null;
                                        }
                                      }
                                    )
                                  : [...Array(numberOfPagesDecks)].map(
                                      (item, index) => {
                                        if (index + 1 === pageNumberDecks) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              active={isActive2(index + 1)}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          pageNumberDecks - 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          pageNumberDecks - 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          pageNumberDecks - 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          pageNumberDecks + 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          pageNumberDecks + 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          pageNumberDecks + 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationDecks(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else {
                                          return null;
                                        }
                                      }
                                    )}
                                <MDBPageItem onClick={nextPaginationDecks}>
                                  <MDBPageNav className="page-link">
                                    &raquo;
                                  </MDBPageNav>
                                </MDBPageItem>
                                <MDBPageItem onClick={lastPaginationDecks}>
                                  <MDBPageNav className="page-link">
                                    Last
                                  </MDBPageNav>
                                </MDBPageItem>
                              </MDBPagination>
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow mt="2" center className={toggleEditDeckVar}>
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <MDBInput
                          className="white-text"
                          name="name"
                          value={formData.newDeck.name}
                          label={t("studentFlashcards.38")}
                          labelClass="text-white"
                          onChange={onChangeNewDeck}
                        />
                        <MDBInput
                          className="white-text"
                          name="description"
                          value={formData.newDeck.description}
                          label={t("studentFlashcards.37")}
                          labelClass="text-white"
                          onChange={onChangeNewDeck}
                        />
                      </MDBCol>
                      <div className="w-100" />
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <MDBSelect
                          options={formData.optionLevelNewDeck}
                          getValue={handleSelectChange}
                          color="dark"
                          label={t("studentFlashcards.36")}
                          labelClass="white-text"
                          multiple
                        />
                      </MDBCol>
                      <div className="w-100" />
                      <MDBBtn
                        size="sm"
                        onClick={updateDeckFunc}
                        disabled={loadingButtonDecks}
                        color="pink"
                      >
                        {loadingButtonDecks === true && (
                          <div className="spinner-grow spinner-grow-sm" />
                        )}
                        {t("studentFlashcards.24")}
                      </MDBBtn>
                      <MDBBtn
                        size="sm"
                        onClick={modalDeleteDeck}
                        color="danger"
                      >
                        {t("studentFlashcards.25")}
                      </MDBBtn>
                      <MDBBtn size="sm" onClick={backToCard} color="pink">
                        {t("studentFlashcards.35")}
                      </MDBBtn>
                    </MDBRow>
                    <MDBRow
                      mt="2"
                      center
                      className={toggleShowIndividualDeckVar}
                    >
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <div style={{ overflowY: "auto" }}>
                          <div style={{ height: "23rem" }}>
                            <MDBCard className="mt-4">
                              <MDBCardBody className="elegant-color white-text rounded-bottom text-center">
                                <MDBRow>
                                  <MDBCol>
                                    <MDBCardTitle className="CarterOne text-center">
                                      {formData.currentDeck.name}
                                    </MDBCardTitle>
                                  </MDBCol>
                                  <div className="w-100"></div>
                                  <MDBCol>
                                    <p className="pink-text text-center">
                                      {formData.currentDeck.englishLevel.map(
                                        (item2, index) => {
                                          if (
                                            index ===
                                            formData.currentDeck.englishLevel
                                              .length -
                                              1
                                          ) {
                                            return ` ${item2}`;
                                          }
                                          return ` ${item2},`;
                                        }
                                      )}
                                    </p>
                                  </MDBCol>
                                </MDBRow>

                                <hr className="hr-light" />
                                <MDBInput
                                  checked={formData.checked.remove}
                                  name="remove"
                                  labelClass="text-white"
                                  label={t("studentFlashcards.34")}
                                  type="checkbox"
                                  id="checkbox2"
                                  onChange={e =>
                                    onChangeCheck(e, !formData.checked.remove)
                                  }
                                />
                                {formData.currentDeck.cards.length !== 0 &&
                                  formData.currentDeck.cards.map(
                                    (item, index) => {
                                      return (
                                        <MDBBtn
                                          key={item._id}
                                          onClick={() =>
                                            removeFromCurrentDeck(item)
                                          }
                                          size="sm"
                                          color="dark"
                                        >
                                          {item.name}
                                        </MDBBtn>
                                      );
                                    }
                                  )}
                                <MDBCardText className="white-text mt-3 text-center">
                                  {formData.currentDeck.description}
                                </MDBCardText>
                              </MDBCardBody>
                            </MDBCard>
                          </div>
                        </div>
                        <MDBRow center>
                          <MDBBtn
                            onClick={toggleEditDeck}
                            size="sm"
                            color="dark"
                          >
                            {t("studentFlashcards.33")}
                          </MDBBtn>
                          <MDBBtn
                            onClick={saveCardsToDeckFunc}
                            size="sm"
                            color="dark"
                          >
                            {t("studentFlashcards.32")}
                          </MDBBtn>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>

                  {/* Cards */}
                  <MDBCol md="6" sm="6" className="col-12 marginCreateCard">
                    <MDBRow center>
                      <p className="FredokaOne h3 text-white">
                        {t("studentFlashcards.4")}
                      </p>
                    </MDBRow>
                    <MDBRow className="mt-3" center>
                      <MDBIcon
                        style={{ cursor: "pointer" }}
                        onClick={toggleNewCard}
                        icon="fas fa-plus-circle"
                        className="white-text mx-3"
                        size="3x"
                      />
                      <MDBIcon
                        style={{ cursor: "pointer" }}
                        onClick={toggleShowCard}
                        icon="fas fa-ellipsis-h"
                        className="white-text mx-3"
                        size="3x"
                      />
                    </MDBRow>
                    <MDBRow center className={toggleNewCardVar}>
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <MDBInput
                          className="white-text"
                          name="name"
                          value={formData.newCard.name}
                          label={t("studentFlashcards.31")}
                          labelClass="text-white"
                          onChange={onChangeNewCard}
                        />
                      </MDBCol>
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <MDBInput
                          className="white-text"
                          name="front"
                          value={formData.newCard.front}
                          label={t("studentFlashcards.27")}
                          labelClass="text-white"
                          onChange={onChangeNewCard}
                        />
                      </MDBCol>
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <MDBInput
                          className="white-text"
                          name="back"
                          value={formData.newCard.back}
                          label={t("studentFlashcards.26")}
                          labelClass="text-white"
                          onChange={onChangeNewCard}
                        />
                      </MDBCol>
                      <div className="w-100" />
                      <MDBBtn
                        disabled={loadingButtonCards}
                        onClick={() => createCard({ add: false })}
                        color="pink"
                      >
                        {loadingButtonCards === true && (
                          <div className="spinner-grow spinner-grow-sm" />
                        )}
                        {t("studentFlashcards.30")}
                      </MDBBtn>
                      {formData.toggleShowIndividualDeck === true && (
                        <MDBBtn
                          onClick={() => createCard({ add: true })}
                          color="dark"
                          disabled={loadingButtonCards}
                        >
                          {loadingButtonCards === true && (
                            <div className="spinner-grow spinner-grow-sm" />
                          )}
                          {t("studentFlashcards.29")}
                          {formData.currentDeck.name}
                        </MDBBtn>
                      )}
                    </MDBRow>
                    <MDBRow center className={toggleShowIndividualCardVar}>
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <MDBInput
                          className="white-text"
                          name="name"
                          value={formData.newCard.name}
                          label={t("studentFlashcards.28")}
                          labelClass="text-white"
                          onChange={onChangeNewCard}
                        />
                      </MDBCol>
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <MDBInput
                          className="white-text"
                          name="front"
                          value={formData.newCard.front}
                          label={t("studentFlashcards.27")}
                          labelClass="text-white"
                          onChange={onChangeNewCard}
                        />
                      </MDBCol>
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <MDBInput
                          className="white-text"
                          name="back"
                          value={formData.newCard.back}
                          label={t("studentFlashcards.26")}
                          labelClass="text-white"
                          onChange={onChangeNewCard}
                        />
                      </MDBCol>

                      <div className="w-100" />
                      <MDBBtn
                        size="sm"
                        onClick={modalDeleteCard}
                        color="danger"
                      >
                        {t("studentFlashcards.25")}
                      </MDBBtn>
                      <MDBBtn
                        onClick={updateCardFunc}
                        color="pink"
                        disabled={loadingButtonCards}
                      >
                        {loadingButtonCards === true && (
                          <div className="spinner-grow spinner-grow-sm" />
                        )}
                        {t("studentFlashcards.24")}
                      </MDBBtn>
                    </MDBRow>
                    <MDBRow center className={toggleShowCardVar}>
                      <MDBCol
                        lg="6"
                        md="8"
                        sm="10"
                        className="mx-3 text-center"
                      >
                        <MDBRow center className="middleVertical5">
                          <div className="mr-3">
                            <MDBInput
                              className="white-text mt-3"
                              label={t("studentFlashcards.23")}
                              labelClass="white-text"
                              type="text"
                              containerClass="active-pink active-pink-2 mt-0 mb-3"
                              value={formData.searchValueCards}
                              onChange={cardSearch}
                              onKeyPress={onKeyPressEnterCards}
                            />
                          </div>
                          <div
                            className={
                              formData.searchValueCards.length !== 0
                                ? "mx-1"
                                : "d-none"
                            }
                          >
                            <MDBIcon
                              icon="search"
                              onClick={cardsSearchFunc}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <div
                            className={
                              formData.searchCards.length !== 0 ||
                              formData.searchCards === "No Results"
                                ? "mx-1"
                                : "d-none"
                            }
                          >
                            <MDBIcon
                              icon="times-circle"
                              onClick={clearCardsFunc}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          {formData.toggleShowIndividualDeck === true && (
                            <MDBInput
                              checked={formData.checked.add}
                              labelClass="text-white"
                              name="add"
                              label={t("studentFlashcards.22")}
                              onChange={e =>
                                onChangeCheck(e, !formData.checked.add)
                              }
                              type="checkbox"
                              id="checkbox3"
                            />
                          )}
                        </MDBRow>
                      </MDBCol>
                      <div className="w-100" />
                      <MDBCol lg="6" md="8" sm="10" className="mx-3">
                        <div style={{ overflowY: "auto" }}>
                          <div style={{ height: "26rem" }}>
                            <MDBCol>
                              <MDBListGroup>
                                <MDBRow>
                                  <div
                                    className={
                                      formData.searchCards === "No Results"
                                        ? "mt-2"
                                        : "d-none"
                                    }
                                  >
                                    <p className="quickSand h4 black-text text-center">
                                      There are no card results for your search.
                                      Try Again.
                                    </p>
                                  </div>
                                  {searchCardsMap().map((item, index) => {
                                    return (
                                      <MDBCol key={item._id} md="auto">
                                        <MDBListGroupItem
                                          style={{ cursor: "pointer" }}
                                          onClick={() => addToCurrentDeck(item)}
                                          className=" CarterOne listItem text-white my-1 z-depth-2"
                                        >
                                          <MDBRow>
                                            <MDBCol>{item.name}</MDBCol>
                                          </MDBRow>
                                        </MDBListGroupItem>
                                      </MDBCol>
                                    );
                                  })}
                                </MDBRow>
                              </MDBListGroup>
                            </MDBCol>
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    {/* Pagination Cards Medium Size (Inside Modal) */}
                    <div
                      className={
                        formData.toggleShowCard === true
                          ? "middleVertical mt-1 mb-2 paginationFlashcardsMedium"
                          : "d-none"
                      }
                    >
                      <MDBRow>
                        <MDBCol>
                          <MDBPagination circle>
                            <MDBPageItem onClick={firstPaginationCards}>
                              <MDBPageNav className="page-link">
                                <span>First</span>
                              </MDBPageNav>
                            </MDBPageItem>
                            <MDBPageItem onClick={previousPaginationCards}>
                              <MDBPageNav
                                className="page-link"
                                aria-label="Previous"
                              >
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                              </MDBPageNav>
                            </MDBPageItem>
                            {paginationModeCards === "date"
                              ? [...Array(initialNumberOfPagesCards)].map(
                                  (item, index) => {
                                    if (index + 1 === initialPageNumberCards) {
                                      return (
                                        <MDBPageItem
                                          key={index}
                                          active={isActive3(index + 1)}
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards - 1
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards - 2
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards - 3
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards + 1
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards + 2
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards + 3
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else {
                                      return null;
                                    }
                                  }
                                )
                              : [...Array(numberOfPagesCards)].map(
                                  (item, index) => {
                                    if (index + 1 === pageNumberCards) {
                                      return (
                                        <MDBPageItem
                                          key={index}
                                          active={isActive4(index + 1)}
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards - 1
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards - 2
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards - 3
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards + 1
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards + 2
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards + 3
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else {
                                      return null;
                                    }
                                  }
                                )}
                            <MDBPageItem onClick={nextPaginationCards}>
                              <MDBPageNav className="page-link">
                                &raquo;
                              </MDBPageNav>
                            </MDBPageItem>
                            <MDBPageItem onClick={lastPaginationCards}>
                              <MDBPageNav className="page-link">
                                Last
                              </MDBPageNav>
                            </MDBPageItem>
                          </MDBPagination>
                        </MDBCol>
                      </MDBRow>
                    </div>

                    {/* Pagination Cards Small Size (Inside Modal) */}

                    <div
                      className={
                        formData.toggleShowCard === true
                          ? "middleVertical mt-1 mb-4 paginationFlashcardsSmall"
                          : "d-none"
                      }
                    >
                      <MDBRow>
                        <MDBCol>
                          <MDBPagination circle size="sm">
                            <MDBPageItem onClick={firstPaginationCards}>
                              <MDBPageNav className="page-link">
                                <span>First</span>
                              </MDBPageNav>
                            </MDBPageItem>
                            <MDBPageItem onClick={previousPaginationCards}>
                              <MDBPageNav
                                className="page-link"
                                aria-label="Previous"
                              >
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                              </MDBPageNav>
                            </MDBPageItem>
                            {paginationModeCards === "date"
                              ? [...Array(initialNumberOfPagesCards)].map(
                                  (item, index) => {
                                    if (index + 1 === initialPageNumberCards) {
                                      return (
                                        <MDBPageItem
                                          key={index}
                                          active={isActive3(index + 1)}
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards - 1
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards - 2
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards - 3
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards + 1
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards + 2
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      initialPageNumberCards + 3
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else {
                                      return null;
                                    }
                                  }
                                )
                              : [...Array(numberOfPagesCards)].map(
                                  (item, index) => {
                                    if (index + 1 === pageNumberCards) {
                                      return (
                                        <MDBPageItem
                                          key={index}
                                          active={isActive4(index + 1)}
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards - 1
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards - 2
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards - 3
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards + 1
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards + 2
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else if (
                                      index + 1 ===
                                      pageNumberCards + 3
                                    ) {
                                      return (
                                        <MDBPageItem
                                          onClick={() =>
                                            currentPaginationCards(index)
                                          }
                                        >
                                          <MDBPageNav className="page-link">
                                            {index + 1}{" "}
                                            <span className="sr-only">
                                              (current)
                                            </span>
                                          </MDBPageNav>
                                        </MDBPageItem>
                                      );
                                    } else {
                                      return null;
                                    }
                                  }
                                )}
                            <MDBPageItem onClick={nextPaginationCards}>
                              <MDBPageNav className="page-link">
                                &raquo;
                              </MDBPageNav>
                            </MDBPageItem>
                            <MDBPageItem onClick={lastPaginationCards}>
                              <MDBPageNav className="page-link">
                                Last
                              </MDBPageNav>
                            </MDBPageItem>
                          </MDBPagination>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
              <MDBModalFooter className="pink">
                <MDBBtn color="pink" onClick={modalCreateDeck}>
                  {t("studentFlashcards.6")}
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </MDBContainer>
      </div>

      {/* FlashCard Game */}
      <div className="flashcardModal">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalPlayDeck}
            toggle={modalPlayDeck}
            className="modal-full "
          >
            <div className="modal-full modal-content">
              <MDBModalBody>
                <MDBRow end className="mb-5">
                  <MDBCol md="9">
                    <MDBCard>
                      <MDBCardBody>
                        <p className="cardProp text-center h1-responsive">
                          {formData.currentDeck.name}
                        </p>
                        <p className="my-1  text-center">
                          {formData.currentDeck.description}
                        </p>
                        <hr />

                        <MDBRow center>
                          <MDBCol
                            style={{ minHeight: "26rem", maxWidth: "22rem" }}
                            onClick={
                              formData.endOfDeck === false
                                ? () => handleFlipping(formData.currentDeck)
                                : null
                            }
                          >
                            <MDBCard
                              style={{ height: "400px" }}
                              className="text-center h-100 w-100"
                            >
                              <MDBCardBody>
                                {formData.endOfDeck === true &&
                                formData.flashCardMode === "normal" ? (
                                  <p className="font-weight-bold success-text h2-responsive">
                                    {t("studentFlashcards.21")}
                                  </p>
                                ) : formData.currentPracticeCard.score === 1 &&
                                  formData.flashCardMode === "spaced" ? (
                                  <p className="font-weight-bold success-text h2-responsive">
                                    {t("studentFlashcards.20")}
                                  </p>
                                ) : (
                                  <Fragment>
                                    <div
                                      className={
                                        formData.flipped === true
                                          ? "d-none"
                                          : ""
                                      }
                                    >
                                      <p className="font-weight-bold blue-text h2-responsive">
                                        {formData.currentPracticeCard.front}
                                      </p>
                                    </div>
                                    <div
                                      className={
                                        formData.flipped === false
                                          ? "d-none"
                                          : ""
                                      }
                                    >
                                      <p className="font-weight-bold blue-text h2-responsive">
                                        {formData.currentPracticeCard.front}
                                      </p>
                                      <hr />
                                      <p className="font-weight-bold pink-text h3-responsive">
                                        {formData.currentPracticeCard.back}
                                      </p>
                                    </div>{" "}
                                  </Fragment>
                                )}
                              </MDBCardBody>
                            </MDBCard>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow center className={showHideButtons}>
                          <MDBBtn
                            disabled={
                              formData.flipped === false
                                ? true
                                : formData.currentPracticeCard.score === 1
                                ? true
                                : false
                            }
                            color="danger"
                            onClick={() => cardAnswer("wrong")}
                          >
                            {t("studentFlashcards.19")}
                          </MDBBtn>

                          <MDBBtn
                            disabled={
                              formData.flipped === false
                                ? true
                                : formData.currentPracticeCard.score === 1
                                ? true
                                : false
                            }
                            color="success"
                            onClick={() => cardAnswer("right")}
                          >
                            {t("studentFlashcards.18")}
                          </MDBBtn>
                        </MDBRow>
                        <MDBRow center className={showHideButtons}>
                          <MDBBtn size="sm" color="info" onClick={saveSpace}>
                            {t("studentFlashcards.17")}
                          </MDBBtn>
                        </MDBRow>
                        <MDBRow center className={showHideButtons2}>
                          {formData.endOfDeck === false ? (
                            <Fragment>
                              <MDBBtn
                                className={
                                  formData.currentPracticeCard.index === 0
                                    ? "d-none"
                                    : ""
                                }
                                color="dark"
                                onClick={previousCard}
                              >
                                {t("studentFlashcards.16")}
                              </MDBBtn>

                              <MDBBtn
                                disabled={
                                  formData.flipped === false ? true : false
                                }
                                color="success"
                                onClick={nextCard}
                              >
                                {t("studentFlashcards.14")}
                              </MDBBtn>
                            </Fragment>
                          ) : (
                            <MDBBtn color="dark" onClick={restartDeck}>
                              {t("studentFlashcards.15")}
                            </MDBBtn>
                          )}
                        </MDBRow>
                        <MDBRow center className={showHideButtons3}>
                          <MDBBtn
                            disabled={formData.flipped === false ? true : false}
                            color="success"
                            onClick={randomMode}
                          >
                            {t("studentFlashcards.14")}
                          </MDBBtn>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  {/* Right Column */}
                  <MDBCol md="3">
                    <MDBCard className="darkbgc">
                      <MDBCardBody>
                        <MDBRow
                          onClick={normalMode}
                          center
                          style={{ cursor: "pointer" }}
                        >
                          <MDBIcon
                            size="2x"
                            icon="anchor "
                            className={normalModeVar}
                          />
                          <p className="pl-2 text-white h4-responsive quickSand">
                            {t("studentFlashcards.11")}
                          </p>
                        </MDBRow>
                        <hr className="hrColor" />
                        <MDBRow
                          className="mt-1"
                          center
                          onClick={randomMode}
                          style={{ cursor: "pointer" }}
                        >
                          <MDBIcon
                            size="2x"
                            icon="dice "
                            className={randomModeVar}
                          />
                          <p className="pl-2 text-white h4-responsive quickSand">
                            {t("studentFlashcards.12")}
                          </p>
                        </MDBRow>
                        <hr className="hrColor" />
                        {formData.playMode === "shared" && (
                          <p
                            className="CarterOne text-center downloadDeck downloadHover"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              makeDeck({
                                ...formData.currentDeck,
                                language: i18n.language
                              })
                            }
                          >
                            Download Deck
                          </p>
                        )}
                        {formData.playMode === "notShared" && (
                          <MDBRow
                            onClick={spacedMode}
                            className="mt-1"
                            center
                            style={{ cursor: "pointer" }}
                          >
                            <MDBIcon
                              size="2x"
                              icon="dumbbell "
                              className={spacedModeVar}
                            />
                            <p className="pl-2 text-white h4-responsive quickSand">
                              {t("studentFlashcards.13")}
                            </p>
                          </MDBRow>
                        )}

                        <MDBRow center className="mt-4">
                          <p className="pl-2 text-white h6-responsive quickSand">
                            {t("studentFlashcards.5")}
                            {formData.currentDeck.cards.length}
                          </p>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>

              <MDBModalFooter className="pink">
                <MDBBtn color="pink" onClick={closemodalPlayDeck}>
                  {t("studentFlashcards.6")}
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </MDBContainer>
      </div>
      {/* Modal Confirmation delete */}

      <MDBContainer>
        <MDBModal
          modalClassName="hhh"
          isOpen={formData.modalDeleteDeck}
          toggle={modalDeleteDeck}
        >
          <MDBModalHeader toggle={modalDeleteDeck}>
            {t("studentFlashcards.9")}
          </MDBModalHeader>
          <MDBModalBody>{t("studentFlashcards.7")}</MDBModalBody>
          <MDBModalFooter>
            <MDBBtn size="sm" color="dark" onClick={modalDeleteDeck}>
              {t("studentFlashcards.6")}
            </MDBBtn>
            <MDBBtn
              disabled={loadingDeleteDeck}
              onClick={deleteDeckFunc}
              size="sm"
              color="danger"
            >
              {loadingDeleteDeck === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              {t("studentFlashcards.8")}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          modalClassName="hhh"
          isOpen={formData.modalDeleteCard}
          toggle={modalDeleteCard}
        >
          <MDBModalHeader toggle={modalDeleteCard}>
            {t("studentFlashcards.9")}
          </MDBModalHeader>
          <MDBModalBody>{t("studentFlashcards.10")}</MDBModalBody>
          <MDBModalFooter>
            <MDBBtn size="sm" color="dark" onClick={modalDeleteCard}>
              {t("studentFlashcards.6")}
            </MDBBtn>
            <MDBBtn
              disabled={loadingDeleteCard}
              onClick={deleteCardFunc}
              size="sm"
              color="danger"
            >
              {loadingDeleteCard === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              {t("studentFlashcards.8")}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

FlashCards.propTypes = {};

const mapStateToProps = state => ({
  students: state.students
});

export default withRouter(
  connect(mapStateToProps, {
    makeDeck,
    makeCard,
    getDecks,
    getCards,
    updateDeck,
    deleteDeck,
    saveCardsToDeck,
    updateCard,
    updateSpaced,
    deleteCard,
    getSharedDecks,
    clearStudentReducer,
    searchDecksFunction,
    clearDecksSearchReducer,
    setPaginationModeDecks,
    searchCardsFunction,
    clearCardsSearchReducer,
    setPaginationModeCards,
    setLoadingButtonDecks,
    setLoadingButtonCards,
    setLoadingDeleteCard,
    setLoadingDeleteDeck
  })(FlashCards)
);
