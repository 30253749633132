import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask
} from "mdbreact";
import "./Login.css";
import Footer from "../layout/Footer";
import LoginForm from "./LoginForm";

const Login = ({ students: { isAuthenticated } }) => {
  const { t } = useTranslation();

  if (isAuthenticated === true) {
    return <Redirect to="/connect/dashboard" />;
  }

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student Login</title>

        <meta
          name="description"
          content="Log into Topscholar and communicate with your tutor."
        />
        <meta property="og:title" content="Topscholar: Student Login" />
        <meta
          property="og:description"
          content="Log into Topscholar and communicate with your tutor."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/connect/login" />
      </Helmet>
      <div className="classic-form-page" id="login">
        <MDBMask
          className="d-flex justify-content-center align-items-center"
          overlay="gradient"
        >
          <MDBContainer className="mt-3 mb-5">
            <MDBRow className="flex-center pt-5 mt-3">
              <MDBCol md="10" lg="6" xl="5" sm="12" className="mt-5 mx-auto">
                <MDBCard>
                  <MDBCardBody>
                    <div className="form-header loginbody">
                      <p className="h2 loginRegisterTitle">
                        {t("studentLogin.1")}
                      </p>
                    </div>

                    <LoginForm />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
        <Footer />
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  students: state.students
});

export default withRouter(connect(mapStateToProps, {})(Login));
