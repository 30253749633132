import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  login,
  getTeacherOptions,
  resetStudentLogin
} from "../../actions/students";
import SetPassword from "./connect/SetPassword";
import { useTranslation } from "react-i18next";

import {
  MDBInput,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCol,
  MDBModalFooter,
  MDBSelect
} from "mdbreact";

const Login = ({
  login,
  getTeacherOptions,
  resetStudentLogin,
  isAuthenticated,
  activateProp,
  history,
  students: { getTeachers, teacherOptions }
}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    passwordModal: false,
    teacherOptions: [],
    teacher: ""
  });

  const { t } = useTranslation();

  const { email, password, teacher } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = () => {
    login(email, password, teacher);
  };

  const toggleResetPassword = () => {
    setFormData({
      ...formData,
      passwordModal: true
    });
  };
  const toggleResetPasswordClose = () => {
    setFormData({
      ...formData,
      passwordModal: false
    });
  };

  var styles = {
    cursor: "pointer",
    fontSize: "12px"
  };

  const getTeachersFunc = () => {
    getTeacherOptions({ email: formData.email });
  };

  const back = () => {
    resetStudentLogin();
    setFormData({
      ...formData,
      email: "",
      password: ""
    });
  };

  useEffect(() => {
    setFormData(prevState => {
      const newArray = teacherOptions.teachers.map((item, index) => {
        return {
          text: item.userTutor.name,
          value: item.userTutor._id
        };
      });
      return {
        ...prevState,
        teacherOptions: newArray
      };
    });
  }, [teacherOptions]);

  const handleSelectTeacher = value => {
    setFormData({
      ...formData,
      teacher: value[0]
    });
  };

  return (
    <Fragment>
      <div className="form-group loginForm ">
        <MDBInput
          type="email"
          name="email"
          value={email}
          onChange={e => onChange(e)}
          required
          label={t("studentLogin.2")}
          labelClass="deep-purple-text quickSand"
          icon="envelope"
          iconClass="deep-purple-text"
          className="deep-purple-text"
          disabled={getTeachers === true ? true : false}
        />
      </div>
      {getTeachers === true ? (
        <Fragment>
          <div className="form-group loginForm">
            <MDBInput
              type="password"
              name="password"
              value={password}
              onChange={e => onChange(e)}
              label={t("studentLogin.3")}
              labelClass="deep-purple-text"
              icon="lock"
              iconClass="deep-purple-text"
              required
              className="deep-purple-text"
            />
          </div>
          <MDBSelect
            outline
            labelClass="black-text quickSand"
            options={formData.teacherOptions}
            label={t("studentLogin.4")}
            getValue={handleSelectTeacher}
          />
        </Fragment>
      ) : null}
      {getTeachers === true ? (
        <Fragment>
          <div className="form-group loginForm">
            <p
              className="deep-purple-text quickSand"
              style={styles}
              onClick={() => toggleResetPassword()}
            >
              {t("studentLogin.7")}
            </p>
            <div className="text-center mt-3 black-text">
              <MDBBtn onClick={back} className="loginbtn2" size="lg">
                {t("calendar.9")}
              </MDBBtn>
              <MDBBtn
                className="loginbtn"
                size="lg"
                type="submit"
                onClick={onSubmit}
              >
                {t("studentLogin.6")}
              </MDBBtn>
              <hr />
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="text-center mt-3 black-text">
          <MDBBtn className="loginbtn" size="lg" onClick={getTeachersFunc}>
            {t("studentLogin.5")}
          </MDBBtn>
          <hr />
        </div>
      )}

      <p className="my-1 quickSand">
        {t("studentLogin.13")}
        <HashLink to="/login" className="deep-purple-text">
          {t("studentLogin.14")}
        </HashLink>
      </p>
      <MDBContainer>
        <MDBModal
          isOpen={formData.passwordModal}
          toggle={toggleResetPasswordClose}
          size="sm"
        >
          <MDBModalHeader toggle={toggleResetPasswordClose}>
            {t("studentLogin.8")}
          </MDBModalHeader>
          <MDBModalBody>
            <MDBCol md="12" className="text-center">
              <SetPassword />
            </MDBCol>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              size="sm"
              onClick={toggleResetPasswordClose}
            >
              {t("studentLogin.9")}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  activateProp: PropTypes.string
};

const mapStateToProps = state => ({
  // isAuthenticated: state.auth.isAuthenticated,
  students: state.students
});

export default connect(mapStateToProps, {
  login,
  getTeacherOptions,
  resetStudentLogin
})(withRouter(Login));
