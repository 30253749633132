import {
  MAKE_DECK,
  DECK_ERROR,
  GET_DECKS,
  SEARCH_DECKS,
  SEARCH_CARDS,
  CLEAR_DECKS_SEARCH,
  CLEAR_CARDS_SEARCH,
  UPDATE_DECK,
  DELETE_DECK,
  MAKE_CARD,
  UPDATE_DECK_CARDS,
  UPDATE_FLASHCARD,
  UPDATE_SPACED,
  GET_FLASHCARDS,
  DELETE_FLASHCARD,
  SHARE_DECK,
  CLEAR_FLASHCARD,
  SET_PAGINATION_MODE_DECKS,
  SET_PAGINATION_MODE_CARDS,
  SET_LOADING_BUTTON_DECKS,
  SET_LOADING_BUTTON_CARDS,
  SET_LOADING_DELETE_DECK,
  SET_LOADING_DELETE_CARD,
  LOGOUT
} from "../actions/types";

const initialState = {
  decks: [],
  cards: [],
  loading: true,
  error: "",
  newDeck: "",
  newCard: "",
  updatedDeck: "",
  updatedCard: "",
  deletedDeck: "",
  updatedDeckCards: "",
  deletedCard: "",
  shared: "",
  initialNumberOfPages: 1,
  initialPageNumber: 1,
  numberOfPages: 1,
  pageNumber: 1,
  initialNumberOfPagesCards: 1,
  initialPageNumberCards: 1,
  numberOfPagesCards: 1,
  pageNumberCards: 1,
  searchDecks: [],
  searchCards: [],
  keywords: "",
  keywordsCards: "",
  paginationModeDecks: "date",
  paginationModeCards: "date",
  loadingButtonDecks: false,
  loadingButtonCards: false,
  decksLoading: true,
  loadingDeleteDeck: false,
  loadingDeleteCard: false
};
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_FLASHCARD:
      return {
        ...state,
        updatedDeck: "",
        updatedCard: "",
        updatedDeckCards: "",
        deletedCard: "",
        deletedDeck: "",
        newDeck: "",
        newCard: ""
      };
    case CLEAR_DECKS_SEARCH:
      return {
        ...state,
        searchDecks: "",
        keywords: "",
        numberOfPages: "",
        pageNumber: "",
        paginationModeDecks: "date"
      };
    case CLEAR_CARDS_SEARCH:
      return {
        ...state,
        searchCards: "",
        keywordsCards: "",
        numberOfPagesCards: "",
        pageNumberCards: "",
        paginationModeCards: "date"
      };
    case MAKE_DECK:
      return {
        ...state,
        newDeck: payload,
        decks: [...state.decks, payload],
        loadingButtonDecks: false
      };
    case MAKE_CARD:
      return {
        ...state,
        cards: [...state.cards, payload],
        newCard: payload,
        loadingButtonCards: false
      };
    case UPDATE_DECK:
      const newArray = state.decks.map((item, index) => {
        if (item._id === payload.deck._id) {
          return payload.deck;
        } else {
          return item;
        }
      });
      return {
        ...state,
        decks: newArray,
        updatedDeck: payload.deck,
        loadingButtonDecks: false
      };
    case UPDATE_FLASHCARD:
      const newArray2 = state.cards.map((item, index) => {
        if (item._id === payload.cards._id) {
          return payload.cards;
        } else {
          return item;
        }
      });

      const newDeckArray = state.decks.map((item, index) => {
        const cards = item.cards.map((item2, index2) => {
          if (item2._id === payload.cards._id) {
            return payload.cards;
          } else {
            return item2;
          }
        });
        return {
          ...item,
          cards: cards
        };
      });
      return {
        ...state,
        cards: newArray2,
        decks: newDeckArray,
        updatedCard: payload.cards,
        loadingButtonCards: false
      };
    case UPDATE_DECK_CARDS:
      const newArray3 = state.decks.map((item, index) => {
        if (item._id === payload.deck._id) {
          return payload.deck;
        } else {
          return item;
        }
      });
      return {
        ...state,
        decks: newArray3,
        updatedDeckCards: payload.deck
      };
    case UPDATE_SPACED:
      const newArray4 = state.decks.map((item, index) => {
        if (item._id === payload.deck._id) {
          return payload.deck;
        } else {
          return item;
        }
      });
      return {
        ...state,
        decks: newArray4
      };
    case DELETE_DECK:
      return {
        ...state,
        decks: state.decks.filter(deck => deck._id !== payload._id),
        deletedDeck: payload._id,
        loading: false,
        updatedCard: "",
        deletedCard: "",
        loadingDeleteDeck: false
      };
    case DELETE_FLASHCARD:
      const newDeckArray2 = state.decks.map((item, index) => {
        const cards = item.cards.filter((item2, index2) => {
          return item2._id !== payload._id;
        });
        return {
          ...item,
          cards: cards
        };
      });
      return {
        ...state,

        cards: state.cards.filter(card => card._id !== payload._id),
        updatedCard: "",
        deletedCard: payload._id,
        loading: false,
        decks: newDeckArray2,

        loadingDeleteCard: false
      };
    case GET_DECKS:
      return {
        ...state,
        decks: payload.decks,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber,
        loading: false,
        decksLoading: false
      };
    case SEARCH_DECKS:
      return {
        ...state,
        searchDecks: payload.searchDecks,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        loading: false
      };
    case SET_PAGINATION_MODE_DECKS:
      return {
        ...state,
        paginationModeDecks: payload
      };
    case SET_PAGINATION_MODE_CARDS:
      return {
        ...state,
        paginationModeCards: payload
      };
    case GET_FLASHCARDS:
      return {
        ...state,
        cards: payload.cards,
        initialNumberOfPagesCards: payload.initialNumberOfPagesCards,
        initialPageNumberCards: payload.initialPageNumberCards,
        loading: false
      };
    case SEARCH_CARDS:
      return {
        ...state,
        searchCards: payload.searchCards,
        keywordsCards: payload.keywordsCards,
        pageNumberCards: payload.pageNumberCards,
        numberOfPagesCards: payload.numberOfPagesCards,
        loading: false
      };
    case DECK_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonCards: false,
        loadingButtonDecks: false,
        loadingDeleteDeck: false,
        loadingDeleteCard: false
      };
    case SHARE_DECK:
      return {
        ...state,
        shared: payload
      };
    case SET_LOADING_BUTTON_DECKS:
      return {
        ...state,
        loadingButtonDecks: payload
      };
    case SET_LOADING_BUTTON_CARDS:
      return {
        ...state,
        loadingButtonCards: payload
      };
    case SET_LOADING_DELETE_DECK:
      return {
        ...state,
        loadingDeleteDeck: payload
      };
    case SET_LOADING_DELETE_CARD:
      return {
        ...state,
        loadingDeleteCard: payload
      };
    case LOGOUT:
      return {
        decks: [],
        cards: [],
        loading: true,
        error: "",
        newDeck: "",
        newCard: "",
        updatedDeck: "",
        updatedCard: "",
        deletedDeck: "",
        updatedDeckCards: "",
        deletedCard: "",
        shared: "",
        initialNumberOfPages: 1,
        initialPageNumber: 1,
        numberOfPages: 1,
        pageNumber: 1,
        initialNumberOfPagesCards: 1,
        initialPageNumberCards: 1,
        numberOfPagesCards: 1,
        pageNumberCards: 1,
        searchDecks: [],
        searchCards: [],
        keywords: "",
        keywordsCards: "",
        paginationModeDecks: "date",
        paginationModeCards: "date",
        loadingButtonDecks: false,
        loadingButtonCards: false,
        decksLoading: true,
        loadingDeleteDeck: false,
        loadingDeleteCard: false
      };
    default:
      return state;
  }
}
