import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { updateStudentConnect } from "../../../actions/students";
import { Helmet } from "react-helmet";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBView,
  MDBCardBody,
  MDBInput,
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from "mdbreact";
import SetPassword from "./SetPassword";

const Profile = ({ updateStudentConnect, students: { user } }) => {
  const [formData, setFormData] = useState({
    updateUser: {
      name: "",
      email: ""
    },
    modal: false,
    confirmationModal: false,
    confirmationModalCancel: false,
    passwordModal: false,
    loading: false
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      setFormData(prevState => {
        return {
          ...prevState,
          updateUser: {
            name: user.name,
            email: user.email
          }
        };
      });
    }
  }, [user]);

  const clickUpdate = async e => {
    e.preventDefault();
    await setFormData({
      ...formData,
      loading: true
    });

    await updateStudentConnect(user._id, formData.updateUser);
    setFormData({
      ...formData,
      loading: false
    });
  };

  const onChange = e =>
    setFormData({
      ...formData,
      updateUser: {
        ...formData.updateUser,
        [e.target.name]: e.target.value
      }
    });

  const toggleResetPassword = () => {
    setFormData({
      ...formData,
      passwordModal: true
    });
  };
  const toggleResetPasswordClose = () => {
    setFormData({
      ...formData,
      passwordModal: false
    });
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student Profile</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow center className="pt-5 mt-3">
          <MDBCol lg="8" className="mb-r">
            <MDBCard narrow>
              <MDBView cascade className="info-color card-header">
                <h5 className="mb-0 font-weight-bold text-center text-white montserratFont">
                  {t("studentProfile.1")}
                </h5>
              </MDBView>
              <MDBCardBody className="text-center">
                <form onSubmit={e => clickUpdate(e)}>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBInput
                        disabled
                        type="text"
                        name="name"
                        label={t("studentProfile.2")}
                        value={formData.updateUser.name}
                        onChange={onChange}
                        labelClass="quickSand"
                        className="quickSand black-text"
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        disabled
                        type="email"
                        name="email"
                        label={t("studentProfile.3")}
                        value={formData.updateUser.email}
                        onChange={onChange}
                        required
                        className="quickSand black-text"
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="text-center">
                      <MDBBtn
                        onClick={() => toggleResetPassword()}
                        className="mt-4 quickSand"
                        size="sm"
                        color="primary"
                      >
                        {t("studentProfile.4")}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <MDBContainer>
        <MDBModal
          isOpen={formData.passwordModal}
          toggle={toggleResetPasswordClose}
          size="sm"
        >
          <MDBModalHeader toggle={toggleResetPasswordClose}>
            {t("studentProfile.4")}
          </MDBModalHeader>
          <MDBModalBody>
            <MDBCol md="12" className="text-center">
              <SetPassword />
            </MDBCol>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              size="sm"
              onClick={toggleResetPasswordClose}
            >
              {t("studentProfile.5")}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  students: state.students
});

export default withRouter(
  connect(mapStateToProps, {
    updateStudentConnect
  })(Profile)
);
