import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { Helmet } from "react-helmet";

import {
  MDBScrollbar,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
  MDBSelect
} from "mdbreact";

import StudentExamEvaluations from "./StudentExamEvaluations";
import "./student.css";

import {
  getStudent,
  updateStudent,
  clearMessage,
  getStudentsSelect,
  deleteStudent,
  activationLink,
  studentPreferences,
  disactivatePortal,
  setLoadingButtonStudents,
  setLoadingButtonDeleteStudent
} from "../../actions/students";

const ShowStudent = ({
  getStudent,
  getStudentsSelect,
  setLoadingButtonStudents,
  setLoadingButtonDeleteStudent,
  students: {
    student,
    loading,
    topscholarconnect,
    studentsSelect,
    loadingButtonStudents,
    loadingButtonDeleteStudent
  },
  auth: { user },
  students,
  match,
  updateStudent,
  clearMessage,
  deleteStudent,
  history,
  activationLink,
  studentPreferences,
  disactivatePortal
}) => {
  const [formData, setFormData] = useState({
    profileTab: true,
    evaluationsTab: false,
    studentProfile: {
      name: "",
      email: "",
      phoneNumber: "",
      location: "",
      comments: "",
      preferredVideo: "",
      skype: "",
      whatsapp: "",
      wechat: "",
      studentPortalLanguage: ""
    },
    students: {
      message: ""
    },
    dataSet: [{ name: "", value: "" }],
    filteredSet: [{ name: "", value: "" }],
    searchValue: "",
    modalOpen: false,
    studentsArray: [],
    confirmationModal: false,
    confirmationModalConnect: false,
    studentError: "",
    loading: false,
    checked: {
      lessons: false,
      decks: false,
      evaluations: false,
      exams: false,
      whiteboard: false
    },
    preferredVideoOptions: [
      {
        text: "Skype",
        value: "Skype"
      },
      {
        text: "Whatsapp",
        value: "Whatsapp"
      },
      {
        text: "Wechat",
        value: "Wechat"
      }
    ],
    languageOptions: [
      {
        text: "English",
        value: "en"
      },
      {
        text: "French",
        value: "fr"
      },
      {
        text: "Portuguese",
        value: "pt"
      },
      {
        text: "Spanish",
        value: "es"
      },
      {
        text: "German",
        value: "de"
      },
      {
        text: "Indonesian",
        value: "id"
      },
      {
        text: "Hindi",
        value: "hi"
      },
      {
        text: "Simplified Chinese",
        value: "zh_CN"
      },
      {
        text: "Italian",
        value: "it"
      },
      {
        text: "Turkish",
        value: "tr"
      }
    ]
  });

  useEffect(() => {
    getStudent(match.params.id);
  }, [getStudent, match, topscholarconnect]);

  useEffect(() => {
    if (students) {
      setFormData(prevState => {
        return {
          ...prevState,
          students: {
            message: students.message
          }
        };
      });
    }
  }, [students.message, students]);

  useEffect(() => {
    if (student) {
      setFormData(prevState => {
        const newArray = prevState.preferredVideoOptions.map((item, index) => {
          if (item.value === student.preferredVideo) {
            return {
              ...item,
              checked: true
            };
          } else {
            return {
              ...item,
              checked: false
            };
          }
        });
        const newArray2 = prevState.languageOptions.map((item, index) => {
          if (item.value === student.studentPortalLanguage) {
            return {
              ...item,
              checked: true
            };
          } else {
            return {
              ...item,
              checked: false
            };
          }
        });
        return {
          ...prevState,
          studentProfile: {
            name: student.name,
            email: student.email,
            phoneNumber: student.phoneNumber,
            location: student.location,
            comments: student.comments,
            preferredVideo: student.preferredVideo,
            skype: student.skype,
            whatsapp: student.whatsapp,
            wechat: student.wechat,
            studentPortalLanguage: student.studentPortalLanguage
          },
          preferredVideoOptions: newArray,
          checked: student.view,
          languageOptions: newArray2
        };
      });
    }
  }, [student]);
  useEffect(() => {
    setTimeout(() => clearMessage(), 3000);
  }, [formData.students.message, clearMessage]);

  const onChange = e =>
    setFormData({
      ...formData,
      studentProfile: {
        ...formData.studentProfile,
        [e.target.name]: e.target.value
      }
    });


  const clickProfileTab = () => {
    setFormData({
      ...formData,
      profileTab: true,
      evaluationsTab: false
    });
  };
  const clickEvaluationsTab = () => {
    setFormData({
      ...formData,
      profileTab: false,
      evaluationsTab: true
    });
  };

  const clickUpdateStudent = async e => {
    e.preventDefault();
    await setLoadingButtonStudents(true);
    const newformData = {
      ...formData.studentProfile,
      stripe_account: user.stripe_user_id
    };
    await updateStudent(student._id, newformData);
    setFormData({
      ...formData,

      studentError: ""
    });
  };

  const clickUpdatePreferences = async e => {
    e.preventDefault();

    await studentPreferences(student._id, formData.checked);
    setFormData({
      ...formData,

      studentError: ""
    });
  };

  const handleSearch = event =>
    setFormData({ ...formData, searchValue: event.target.value });

  useEffect(() => {
    const searchForStudent = () => {
      setFormData(prevState => {
        return {
          ...prevState,
          filteredSet: prevState.dataSet.filter(item =>
            item.name.toLowerCase().match(formData.searchValue.toLowerCase())
          )
        };
      });
    };
    searchForStudent();
  }, [formData.searchValue]);

  useEffect(() => {
    if (formData.modalOpen === true) {
      getStudentsSelect();
    }
  }, [formData.modalOpen, getStudentsSelect]);

  const toggleModal = () =>
    setFormData({
      ...formData,
      modalOpen: !formData.modalOpen
    });

  useEffect(() => {
    if (studentsSelect) {
      const newArray = studentsSelect.map((item, index) => {
        return {
          name: item.name,
          value: item
        };
      });
      setFormData(prevState => {
        return {
          ...prevState,
          dataSet: newArray,
          filteredSet: newArray
        };
      });
    }
  }, [studentsSelect]);

  const toggleConfirmation = () => {
    setFormData({
      ...formData,
      confirmationModal: true
    });
  };

  const toggleConfirmationClose = () => {
    setFormData({
      ...formData,
      confirmationModal: false
    });
  };

  const toggleConfirmationConnect = () => {
    setFormData({
      ...formData,
      confirmationModalConnect: true
    });
  };

  const toggleConfirmationCloseConnect = () => {
    setFormData({
      ...formData,
      confirmationModalConnect: false
    });
  };
  const clickDelete = async () => {
    setLoadingButtonDeleteStudent(true);
    deleteStudent(match.params.id, history);
  };

  const onChangeCheck = (e, value) => {
    setFormData({
      ...formData,
      checked: { ...formData.checked, [e.target.name]: value }
    });
  };

  const getVideoOptions = value => {
    const find = formData.preferredVideoOptions.find(
      item => item.checked === true
    );
    if (find) {
      setFormData({
        ...formData,
        studentProfile: {
          ...formData.studentProfile,
          preferredVideo: find ? find.value : ""
        }
      });
    } else return null;
  };
  const getLanguageOptions = value => {
    const find = formData.languageOptions.find(item => item.checked === true);
    if (find) {
      setFormData({
        ...formData,
        studentProfile: {
          ...formData.studentProfile,
          studentPortalLanguage: find ? find.value : ""
        }
      });
    } else return null;
  };

  // Close Connect Modal upon disactivation

  useEffect(() => {
    if (topscholarconnect) {
      setFormData(prevState => {
        return {
          ...prevState,
          confirmationModalConnect: false
        };
      });
    }
  }, [topscholarconnect]);

  return loading || student === null ? (
    <MDBContainer className="mt-5 mb-5">
      <MDBRow className="pt-5 mt-3">
        <Spinner />
      </MDBRow>
    </MDBContainer>
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow className="pt-5 mt-3 " center>
          <MDBCol md="12">
            <MDBCard narrow className="mt-5 mb-3">
              <MDBCardHeader className="view view-cascade  primary-color d-flex justify-content-between align-items-center py-2 mx-4 mb-3 ">
                <div>
                  <MDBBtn
                    onClick={clickProfileTab}
                    outline
                    size="sm"
                    color="white"
                    className="px-2"
                  >
                    Profile
                  </MDBBtn>
                  <MDBBtn
                    onClick={clickEvaluationsTab}
                    outline
                    size="sm"
                    color="white"
                    className="px-2"
                  >
                    Evaluations
                  </MDBBtn>
                </div>
                <p className="white-text mt-3 quickSand mainEditFontSize2">
                  {formData.studentProfile.name}
                </p>
                <div>
                  <MDBBtn
                    onClick={toggleModal}
                    outline
                    rounded
                    size="sm"
                    color="white"
                    className="px-2"
                  >
                    <i className="fas fa-plus mt-0" />
                    {"  Search for another student"}
                  </MDBBtn>
                </div>
              </MDBCardHeader>

              <MDBCardBody cascade className="text-center">
                {formData.profileTab ? (
                  <Fragment>
                    <h3 className="railwayFont mainColor">
                      Edit Student Profile
                    </h3>
                    <MDBRow>
                      <MDBCol md="6">
                        <MDBInput
                          type="text"
                          label="Name"
                          name="name"
                          onChange={onChange}
                          value={formData.studentProfile.name}
                          className="quickSand mainEditFontSize text-black hintColor"
                        />
                      </MDBCol>
                      <MDBCol md="6">
                        <MDBInput
                          disabled={
                            student.topscholarConnect === "inactive"
                              ? false
                              : true
                          }
                          type="text"
                          label={
                            student.topscholarConnect === "inactive"
                              ? "Email"
                              : "Email (cannot update)"
                          }
                          name="email"
                          onChange={onChange}
                          value={formData.studentProfile.email}
                          className="quickSand mainEditFontSize text-black hintColor"
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="6">
                        <MDBInput
                          type="text"
                          label="Phone"
                          name="phoneNumber"
                          onChange={onChange}
                          value={formData.studentProfile.phoneNumber}
                          className="quickSand mainEditFontSize text-black hintColor"
                        />
                        <div className="videoInput">
                          <MDBSelect
                            options={formData.languageOptions}
                            getValue={getLanguageOptions}
                            labelClass="black-text"
                            label={"Student portal language"}
                            className="black-text"
                          />
                        </div>
                      </MDBCol>
                      <MDBCol md="6">
                        <MDBInput
                          type="text"
                          label="Location"
                          name="location"
                          onChange={onChange}
                          value={formData.studentProfile.location}
                          className="quickSand mainEditFontSize text-black hintColor"
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="6" className="mt-4">
                        <MDBInput
                          type="textarea"
                          label="Comments"
                          name="comments"
                          onChange={onChange}
                          value={formData.studentProfile.comments}
                          className="quickSand mainEditFontSize text-black hintColor"
                        />
                      </MDBCol>
                      <MDBCol md="6">
                        <div className="videoInput">
                          <MDBSelect
                            options={formData.preferredVideoOptions}
                            getValue={getVideoOptions}
                            labelClass="black-text"
                            label={"Choose Preferred Video"}
                            className="black-text"
                          />
                        </div>
                        <MDBRow>
                          <MDBCol md="4">
                            <MDBInput
                              type="text"
                              label="Skype"
                              name="skype"
                              onChange={onChange}
                              value={formData.studentProfile.skype}
                              className="quickSand mainEditFontSize text-black hintColor"
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              type="text"
                              label="WhatsApp"
                              name="whatsapp"
                              onChange={onChange}
                              value={formData.studentProfile.whatsapp}
                              className="quickSand mainEditFontSize text-black hintColor"
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              type="text"
                              label="WeChat"
                              name="wechat"
                              onChange={onChange}
                              value={formData.studentProfile.wechat}
                              className="quickSand mainEditFontSize text-black hintColor"
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12" className="text-center">
                        <MDBBtn
                          onClick={toggleConfirmation}
                          color="danger"
                          rounded
                          className="quickSand"
                        >
                          Delete Student
                        </MDBBtn>
                        <MDBBtn
                          onClick={clickUpdateStudent}
                          color="info"
                          rounded
                          disabled={loadingButtonStudents}
                          className="quickSand"
                        >
                          {loadingButtonStudents === true && (
                            <div className="spinner-grow spinner-grow-sm" />
                          )}
                          Update Profile
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </Fragment>
                ) : (
                  <MDBCol md="12">
                    <StudentExamEvaluations />
                  </MDBCol>
                )}
                <div>
                  <MDBModal
                    isOpen={formData.modalOpen}
                    s={toggleModal}
                    backdrop={false}
                    size="sm"
                    side
                    position="top-right"
                  >
                    <MDBModalHeader toggle={toggleModal}>
                      Your Students
                    </MDBModalHeader>
                    <MDBModalBody>
                      <MDBInput
                        value={formData.searchValue}
                        onChange={handleSearch}
                        hint="Search for student"
                        type="text"
                        containerClass="mt-0"
                      />
                      <MDBCol size="12">
                        <MDBScrollbar>
                          <MDBListGroup style={{ maxHeight: "25rem" }}>
                            {formData.filteredSet.map(item => (
                              <MDBListGroupItem key={item.value._id}>
                                <Link to={`/students/${item.value._id}`}>
                                  {item.name}
                                </Link>
                              </MDBListGroupItem>
                            ))}
                          </MDBListGroup>
                        </MDBScrollbar>
                      </MDBCol>
                    </MDBModalBody>
                    <MDBModalFooter>
                      <MDBBtn
                        className="quickSand"
                        color="secondary"
                        onClick={toggleModal}
                      >
                        Close
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModal>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {/* Delete confirmation Modal */}
      <MDBContainer>
        <MDBModal
          isOpen={formData.confirmationModal}
          toggle={toggleConfirmationClose}
          size="md"
        >
          <MDBModalHeader
            toggle={toggleConfirmationClose}
            className="montserratFont h4 black-text"
          >
            Confirm Delete
          </MDBModalHeader>
          <MDBModalBody className="quickSand h5 black-text">
            Warning! If you delete{" "}
            <strong> {formData.studentProfile.name} </strong> all attached data
            will be lost, including evaluations. If you were connected through
            Topscholar Connect, that student will also lose all his/her data and
            will no longer be able to access his/her account. If you had that
            person as a Stripe Customer, you will only be able to access his/her
            invoices and subscriptions on the Stripe.com dashboard. It is
            preferrable to advise your student before proceeding. Click 'Confirm
            Delete' to continue.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              size="sm"
              onClick={toggleConfirmationClose}
              className="quickSand"
            >
              Close
            </MDBBtn>
            <MDBBtn
              onClick={clickDelete}
              color="danger"
              size="sm"
              disabled={loadingButtonDeleteStudent}
              className="quickSand"
            >
              {loadingButtonDeleteStudent === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Confirm Delete
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      {/* Disconnect from Topscholar Connect Modal */}
      <MDBContainer>
        <MDBModal
          isOpen={formData.confirmationModalConnect}
          toggle={toggleConfirmationCloseConnect}
          size="md"
        >
          <MDBModalHeader
            toggle={toggleConfirmationCloseConnect}
            className="montserratFont h4 black-text"
          >
            Confirm Disable Portal
          </MDBModalHeader>
          <MDBModalBody className="quickSand h5 black-text">
            Warning! If you disable{" "}
            <strong> {formData.studentProfile.name} </strong> from Topscholar
            Connect, that student will no longer avec access to their account.
            It is preferrable to advise your student before doing this.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              size="sm"
              onClick={toggleConfirmationCloseConnect}
              className="quickSand"
            >
              Close
            </MDBBtn>
            <MDBBtn
              onClick={() =>
                disactivatePortal({
                  email: student.email,
                  id: student._id,
                  teacher: user.name
                })
              }
              color="danger"
              size="sm"
              className="quickSand"
            >
              Confirm Disable Portal
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      {formData.profileTab && (
        <section id="portal">
          {student.topscholarConnect === "inactive" ? (
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mb-4">
                  <MDBCard className="card-image">
                    <div className="text-white text-center buttoncolor py-5 px-4 rounded">
                      <div>
                        <h4 className="py-3 font-weight-bold ">
                          <strong>
                            Activate Topscholar-Connect for {student.name}
                          </strong>
                        </h4>
                        <p className="pb-3 ">
                          Topscholar-Connect comes with your subscription and
                          allows you to communicate with {student.name}.
                        </p>
                        <MDBBtn
                          onClick={() =>
                            activationLink({
                              email: student.email,
                              id: student._id,
                              teacher: user.name,
                              language: student.studentPortalLanguage
                            })
                          }
                          color="color-one"
                          rounded
                          size="md"
                        >
                          <MDBIcon far icon="clone" className="left" /> Enable
                          Topscholar-Connect for {student.name}
                        </MDBBtn>
                      </div>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          ) : student.topscholarConnect === "inprogress" ? (
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mb-4">
                  <MDBCard className="card-image">
                    <div className="text-white text-center buttoncolor py-5 px-4 rounded">
                      <div>
                        <h4 className="py-3 font-weight-bold ">
                          <strong>
                            Activation Link for {student.name} has been sent.
                          </strong>
                        </h4>
                        <p className="pb-1">
                          {student.name} has not activated their account yet. An
                          email with an activation link has been sent to their
                          inbox. Please inform {student.name}.
                        </p>
                        <p className="pb-3">
                          {" "}
                          <MDBIcon icon="hourglass-half" /> It might take a
                          couples of minutes to receive the email
                        </p>
                        <MDBBtn
                          onClick={() =>
                            disactivatePortal({
                              email: student.email,
                              id: student._id,
                              teacher: user.name
                            })
                          }
                          color="red"
                          rounded
                          size="md"
                        >
                          <MDBIcon far icon="clone" className="left" /> Cancel
                          Activation for {student.name}
                        </MDBBtn>
                        <MDBBtn
                          onClick={() =>
                            activationLink({
                              email: student.email,
                              id: student._id,
                              teacher: user.name,
                              language: student.studentPortalLanguage
                            })
                          }
                          color="color-one"
                          rounded
                          size="md"
                        >
                          <MDBIcon far icon="clone" className="left" /> Resend
                          Activation Link to {student.name}
                        </MDBBtn>
                      </div>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          ) : (
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mb-4 mx-auto">
                  <MDBCard className="card-image">
                    <div className="py-3 px-4 rounded">
                      <div>
                        <h4 className="py-1 font-weight-bold text-center Prata mainColor mainEditFontSize3">
                          <strong>You are connected with {student.name}</strong>
                        </h4>
                        <div>
                          <p className="text-primary text-center quickSand mainEditFontSize">
                            Set Preferences: Decide what your student can see in
                            their dashboard
                          </p>
                          <MDBRow>
                            <MDBCol md="6" className="center-column">
                              <MDBInput
                                checked={formData.checked.lessons}
                                label="Lessons"
                                name="lessons"
                                type="checkbox"
                                id="checkbox1"
                                labelClass="quickSand mainEditFontSize2"
                                onChange={e =>
                                  onChangeCheck(e, !formData.checked.lessons)
                                }
                              />
                              <p
                                style={{ marginLeft: "20px", fontSize: "15px" }}
                                className="quickSand"
                              >
                                Allow student to see their assigned lessons
                              </p>
                              <MDBInput
                                checked={formData.checked.decks}
                                label="Flashcards"
                                name="decks"
                                type="checkbox"
                                id="checkbox2"
                                labelClass="quickSand mainEditFontSize2"
                                onChange={e =>
                                  onChangeCheck(e, !formData.checked.decks)
                                }
                              />
                              <p
                                style={{ marginLeft: "20px", fontSize: "15px" }}
                                className="quickSand"
                              >
                                Gives student access to the flashcards
                                component.
                              </p>
                              <MDBInput
                                label="Evaluations"
                                name="evaluations"
                                type="checkbox"
                                id="checkbox3"
                                labelClass="quickSand mainEditFontSize2"
                                checked={formData.checked.evaluations}
                                onChange={e =>
                                  onChangeCheck(
                                    e,
                                    !formData.checked.evaluations
                                  )
                                }
                              />
                              <p
                                style={{ marginLeft: "20px", fontSize: "15px" }}
                                className="quickSand"
                              >
                                Allow student to view their evaluations
                              </p>
                              <MDBInput
                                label="Exams"
                                name="exams"
                                type="checkbox"
                                id="checkbox4"
                                labelClass="quickSand mainEditFontSize2"
                                checked={formData.checked.exams}
                                onChange={e =>
                                  onChangeCheck(e, !formData.checked.exams)
                                }
                              />
                              <p
                                style={{ marginLeft: "20px", fontSize: "15px" }}
                                className="quickSand"
                              >
                                Allow student to take exams that you assigned to
                                them
                              </p>
                              <MDBInput
                                label="Whiteboard"
                                name="whiteboard"
                                type="checkbox"
                                id="checkbox5"
                                labelClass="quickSand mainEditFontSize2"
                                checked={formData.checked.whiteboard}
                                onChange={e =>
                                  onChangeCheck(e, !formData.checked.whiteboard)
                                }
                              />
                              <p
                                style={{ marginLeft: "20px", fontSize: "15px" }}
                                className="quickSand"
                              >
                                Give access to the whiteboard component. Share
                                your whiteboard with your student
                              </p>
                            </MDBCol>
                          </MDBRow>
                          <MDBCol className="text-center">
                            <MDBBtn
                              onClick={clickUpdatePreferences}
                              color="info"
                              size="sm"
                              className="quickSand mainEditFontSize2"
                            >
                              Set Preferences
                            </MDBBtn>
                          </MDBCol>
                        </div>
                        <hr />
                        <div className="mt-3 text-center">
                          <MDBBtn
                            onClick={toggleConfirmationConnect}
                            color="red"
                            rounded
                            size="md"
                            className="quickSand"
                          >
                            <MDBIcon icon="times" className="left mr-1" />{" "}
                            Disable Topscholar-Connect for {student.name}
                          </MDBBtn>
                        </div>
                      </div>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          )}
        </section>
      )}
    </Fragment>
  );
};

ShowStudent.propTypes = {
  getStudent: PropTypes.func.isRequired,
  students: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  students: state.students,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    getStudent,
    updateStudent,
    clearMessage,
    getStudentsSelect,
    deleteStudent,
    activationLink,
    studentPreferences,
    disactivatePortal,
    setLoadingButtonStudents,
    setLoadingButtonDeleteStudent
  })(ShowStudent)
);
