import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import TradingMain from "../TradingMain";
import {
  getPublishedLesson,
  downloadLesson,
  getPublishedEditedLessons,
  setLoadingButtonDownload
} from "../../../actions/trading";

const Lesson = ({
  history,
  mode,
  match,
  getPublishedLesson,
  downloadLesson,
  setLoadingButtonDownload,
  trading: { publishedLesson, publishedLessonEdits },
  getPublishedEditedLessons,
  auth: { user },
  block
}) => {
  const [formData, setFormData] = useState({
    mode: "lesson",
    block: false
  });

  useEffect(() => {
    getPublishedLesson(match.params.id);
  }, [getPublishedLesson, match]);
  useEffect(() => {
    getPublishedEditedLessons({ originalId: match.params.id });
  }, [getPublishedEditedLessons, match]);

  const downloadLessonFunc = () => {
    setLoadingButtonDownload(true);
    downloadLesson({
      tradingId: publishedLesson._id,
      start: moment(new Date()).valueOf(),
      end: moment(new Date()).valueOf()
    });
  };

  useEffect(() => {
    if (publishedLesson) {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "lesson"
        };
      });
    }
  }, [publishedLesson]);

  useEffect(() => {
    if (block === true || block === false) {
      setFormData(prevState => {
        return {
          ...prevState,
          block: block
        };
      });
    }
  }, [block]);

  return (
    <Fragment>
      <TradingMain
        downloadLessonFunc={downloadLessonFunc}
        lessonContent={publishedLesson}
        url={formData.mode}
        block={formData.block}
        publishedLessonEdits={publishedLessonEdits}
      ></TradingMain>
    </Fragment>
  );
};

Lesson.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    getPublishedLesson,
    downloadLesson,
    getPublishedEditedLessons,
    setLoadingButtonDownload
  })(Lesson)
);
