import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import mongoose from "mongoose";
import Spinner from "../layout/Spinner";
import "./customizables.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import scrollIntoView from "scroll-into-view-if-needed";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSpring, animated } from "react-spring";
import uuid from "uuid/v4";
import uniqWith from "lodash/uniqWith";
import flattenDeep from "lodash/flattenDeep";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBSelect,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
  MDBScrollbar,
  MDBPagination,
  MDBPageItem,
  MDBPageNav
} from "mdbreact";

import CorrectedExams from "./CorrectedExams";
import CustomizablePDF from "./CustomizablePDF";

import { getStudentsSelect } from "../../actions/students";
import { getGroupsSelect } from "../../actions/groups";
import {
  makeCustomizableExam,
  getExamList,
  updateCusExam,
  deleteCusExam,
  clearCustomizablesReducer,
  getIndividualExam,
  searchExamsFunction,
  setPaginationModeExams,
  clearExamsSearchReducer,
  setLoadingButtonCustomizables,
  setLoadingButtonDeleteCustomizables
} from "../../actions/customizables";

const Customizables = ({
  students: { students, studentsSelect },
  auth: { user },

  groups: { groupsSelect },
  customizables: {
    makeExam,
    listExams,
    updatedCusExam,
    deletedCusExam,
    loading,
    individualExam,
    initialPageNumber,
    initialNumberOfPages,
    numberOfPages,
    pageNumber,
    searchExams,
    paginationModeExams,
    loadingButtonCustomizables,
    loadingButtonDeleteCustomizables,
    examLoading
  },
  getGroupsSelect,
  clearCustomizablesReducer,
  getStudentsSelect,
  setLoadingButtonCustomizables,
  setLoadingButtonDeleteCustomizables,
  makeCustomizableExam,
  getExamList,
  updateCusExam,
  deleteCusExam,
  location,
  getIndividualExam,
  searchExamsFunction,
  setPaginationModeExams,
  clearExamsSearchReducer
}) => {
  const [formData, setFormData] = useState({
    examList: [],
    filteredExamList: [],
    currentExam: {
      name: "",
      description: "",
      duration: "",
      students: [],
      components: [],
      date: "",
      image: "",
      englishLevel: [],
      examId: "",
      author: ""
    },
    currentComponent: {
      type: ""
    },
    newComponent: {
      type: "",
      _id: "",
      content: [
        {
          question: "",
          answer: ""
        }
      ]
    },
    newComponentArray: [],
    newExam: {
      name: "",
      author: "",
      userTutor: "",
      description: "",
      duration: "",
      _id: "",
      students: [],
      components: [],
      englishLevel: "",
      date: ""
    },
    modalNewExam: false,
    componentShow: false,
    durationOptions: [
      {
        text: "15 mins",
        value: "15",
        checked: false
      },
      {
        text: "30 mins",
        value: "30",
        checked: false
      },
      {
        text: "45 mins",
        value: "45",
        checked: false
      },
      {
        text: "1 hour",
        value: "60",
        checked: false
      },
      {
        text: "1 hour 15 mins",
        value: "75",
        checked: false
      },
      {
        text: "1 hour 30 mins",
        value: "90",
        checked: false
      },
      {
        text: "1 hour 45 mins",
        value: "105",
        checked: false
      },
      {
        text: "2 hours",
        value: "120",
        checked: false
      },
      {
        text: "None",
        value: "0",
        checked: false
      }
    ],
    studentOptions: [
      {
        text: "",
        value: ""
      }
    ],
    levelOptions: [
      {
        text: "A1",
        value: "A1"
      },
      {
        text: "A2",
        value: "A2"
      },
      {
        text: "B1",
        value: "B1"
      },
      {
        text: "B2",
        value: "B2"
      },
      {
        text: "C1",
        value: "C1"
      },
      {
        text: "C2",
        value: "C2"
      },
      {
        text: "No Level",
        value: "No Level"
      }
    ],

    dataExams: {
      columns: [
        {
          label: [
            <i
              key="1"
              className="fas fa-signature indigo-text fa-1x"
              aria-hidden="true"
            />,
            " Exam Name"
          ],
          field: "name",
          sort: "asc",
          width: 150
        },
        {
          label: [
            <i
              key="2"
              className="fas fa-graduation-cap indigo-text fa-1x"
              aria-hidden="true"
            />,
            " Level (A1...C2)"
          ],
          field: "level",
          sort: "asc",
          width: 270
        },
        {
          label: [
            <i
              key="3"
              className="fas fa-ruler indigo-text fa-1x"
              aria-hidden="true"
            />,
            " Duration"
          ],
          field: "duration",
          sort: "asc",
          width: 150
        },
        {
          label: [
            <i
              key="4"
              className="fas fa-book indigo-text fa-1x"
              aria-hidden="true"
            />,
            " Image"
          ],
          field: "image",
          sort: "asc",
          width: 150
        }
      ],
      rows: null
    },
    modalDurationSelect: false,
    selectedDuration: [],
    selectedStudents: [],
    modalStudentsSelect: false,
    modalLevelSelect: false,
    modalImageSelect: false,
    selectedLevel: [],
    dragColor: false,
    photoList: {
      results: []
    },
    searchList: {
      results: []
    },
    noResult: "",
    photoId: "",
    keywords: "",
    selectImage: {
      urls: {
        thumb: ""
      }
    },
    edit: false,
    modalDeleteExam: false,
    modalPlayExam: false,
    modalCorrectExam: false,
    buttonHide: false,
    endOfComponentsArray: false,
    matching: {
      matchingbArray: [],
      matching1: [],
      matching2: [],
      matching3: [],
      matching4: [],
      matching5: [],
      matching6: []
    },
    modalImageSelectMatch: false,
    groupOptions: [],
    shareOptions: [],
    searchExams: [],
    searchvalue: "",
    pageNumber: 1,
    bottomShow: false
  });

  useEffect(() => {
    getStudentsSelect();
    getExamList({ initialPageNumber: "first" });
    getGroupsSelect();
  }, [getStudentsSelect, getExamList, getGroupsSelect]);

  useEffect(() => {
    setFormData(prevState => {
      const newArray = groupsSelect.map((group, index) => {
        const newArray2 = group.students.map((student, index) => {
          return student._id;
        });
        return {
          text: group.name,
          value: newArray2
        };
      });
      return {
        ...prevState,
        groupOptions: newArray
      };
    });
  }, [groupsSelect]);

  useEffect(() => {
    if (listExams) {
      setFormData(prevState => {
        return {
          ...prevState,
          examList: listExams,
          filteredExamList: listExams
        };
      });
    }
  }, [listExams]);

  useEffect(() => {
    if (makeExam) {
      setFormData(prevState => {
        const newDurationOptions = prevState.durationOptions.map(
          (item, index) => {
            return {
              ...item,
              checked: false
            };
          }
        );
        const newStudentOptions = prevState.studentOptions.map(
          (item, index) => {
            return {
              ...item,
              checked: false
            };
          }
        );
        const newlevelOptions = prevState.levelOptions.map((item, index) => {
          return {
            ...item,
            checked: false
          };
        });
        const newgroupOptions = prevState.groupOptions.map((item, index) => {
          return {
            ...item,
            checked: false
          };
        });
        return {
          ...prevState,
          examList: [...prevState.examList, makeExam],
          filteredExamList: [...prevState.filteredExamList, makeExam],
          studentOptions: newStudentOptions,
          durationOptions: newDurationOptions,
          levelOptions: newlevelOptions,
          groupOptions: newgroupOptions,

          newComponentArray: [],
          edit: false,
          newExam: {
            name: "",
            author: "",
            userTutor: "",
            description: "",
            duration: "",
            _id: "",
            students: [],
            components: [],
            englishLevel: "",
            date: ""
          },
          modalNewExam: false,
          currentExamEditId: "",
          selectImage: {
            urls: {
              thumb: ""
            }
          },
          keywords: "",
          photoId: "",
          searchList: {
            results: []
          },
          selectedLevel: [],
          selectedDuration: [],
          selectedStudents: []
        };
      });

      clearCustomizablesReducer();
    }
  }, [makeExam, clearCustomizablesReducer]);

  useEffect(() => {
    if (updatedCusExam) {
      setFormData(prevState => {
        const newArray = prevState.examList.map((item, index) => {
          if (item._id === updatedCusExam._id) {
            return updatedCusExam;
          } else return item;
        });

        return {
          ...prevState,
          examList: newArray,
          filteredExamList: newArray
        };
      });

      clearCustomizablesReducer();
    }
  }, [updatedCusExam, clearCustomizablesReducer]);

  useEffect(() => {
    const newExamList = async () => {
      setFormData(prevState => {
        const newArray = prevState.examList.filter(
          exam => exam._id !== deletedCusExam
        );
        const newDurationOptions = prevState.durationOptions.map(
          (item, index) => {
            return {
              ...item,
              checked: false
            };
          }
        );

        const newgroupOptions = prevState.groupOptions.map((item, index) => {
          return {
            ...item,
            checked: false
          };
        });
        const newStudentOptions = prevState.studentOptions.map(
          (item, index) => {
            return {
              ...item,
              checked: false
            };
          }
        );
        const newlevelOptions = prevState.levelOptions.map((item, index) => {
          return {
            ...item,
            checked: false
          };
        });
        return {
          ...prevState,
          examList: newArray,
          studentOptions: newStudentOptions,
          durationOptions: newDurationOptions,
          levelOptions: newlevelOptions,
          groupOptions: newgroupOptions,
          filteredExamList: newArray,
          modalDeleteExam: false,
          newComponentArray: [],
          edit: false,
          newExam: {
            name: "",
            author: "",
            userTutor: "",
            description: "",
            duration: "",
            _id: "",
            students: [],
            components: [],
            englishLevel: "",
            date: ""
          },
          modalNewExam: false,
          currentExamEditId: "",
          selectImage: {
            urls: {
              thumb: ""
            }
          },
          keywords: "",
          photoId: "",
          searchList: {
            results: []
          },
          selectedLevel: [],
          selectedDuration: [],
          selectedStudents: []
        };
      });

      clearCustomizablesReducer();
    };

    if (deletedCusExam) {
      newExamList();
    }
  }, [deletedCusExam, clearCustomizablesReducer]);

  useEffect(() => {
    const newArray = studentsSelect.map((item, index) => {
      return {
        text: item.name,
        value: item._id
      };
    });

    setFormData(prevState => {
      return {
        ...prevState,
        studentOptions: newArray
      };
    });
  }, [studentsSelect]);

  const openNewExam = () => {
    setFormData({
      ...formData,
      modalNewExam: !formData.modalNewExam
    });
  };

  const openPlayExam = exam => {
    setFormData({
      ...formData,
      modalPlayExam: true,
      currentExam: {
        name: exam.name,
        description: exam.description,
        duration: exam.duration,
        students: exam.students,
        components: exam.components,
        date: exam.date,
        image: exam.image,
        englishLevel: exam.englishLevel,
        examId: exam._id,
        author: exam.author
      }
    });
  };
  const closePlayExam = () => {
    setFormData({
      ...formData,
      modalPlayExam: false,
      buttonHide: false
    });
  };
  const openCorrectExam = exam => {
    setFormData({
      ...formData,
      modalCorrectExam: true,
      currentExam: {
        name: exam.name,
        description: exam.description,
        duration: exam.duration,
        students: exam.students,
        components: exam.components,
        date: exam.date,
        image: exam.image,
        englishLevel: exam.englishLevel,
        examId: exam._id,
        author: exam.author
      },
      selectedLevel: exam.englishLevel
    });
  };

  const closeCorrectExam = () => {
    setFormData({
      ...formData,
      modalCorrectExam: false,
      buttonHide: false
    });
  };

  const closeNewExam = () => {
    const newArrayStudents = formData.studentOptions.map((item, index) => {
      return { ...item, checked: false };
    });
    const newArrayDuration = formData.durationOptions.map((item, index) => {
      return { ...item, checked: false };
    });
    const newArrayLevel = formData.levelOptions.map((item, index) => {
      return { ...item, checked: false };
    });

    const newArrayShareOptions = formData.shareOptions.map((item, index) => {
      return { ...item, checked: false };
    });

    const newArrayGroupOptions = formData.groupOptions.map((item, index) => {
      return { ...item, checked: false };
    });
    setFormData({
      ...formData,
      modalNewExam: !formData.modalNewExam,
      newComponentArray: [],
      edit: false,
      newExam: {
        name: "",
        author: "",
        userTutor: "",
        description: "",
        duration: "",
        _id: "",
        students: [],
        components: [],
        englishLevel: "",
        date: ""
      },

      currentExamEditId: "",
      selectImage: {
        urls: {
          thumb: ""
        }
      },
      keywords: "",
      photoId: "",
      searchList: {
        results: []
      },
      selectedLevel: [],
      selectedDuration: [],
      selectedStudents: [],
      studentOptions: newArrayStudents,
      durationOptions: newArrayDuration,
      levelOptions: newArrayLevel,
      shareOptions: newArrayShareOptions,
      groupOptions: newArrayGroupOptions
    });
  };

  const componentShowVar = () => {
    setFormData({
      ...formData,
      componentShow: !formData.componentShow
    });
  };

  const multipleChoice = () => {
    const multipleChoice = {
      type: "multipleChoice",
      question: "",
      choice1: "",
      choice2: "",
      choice3: "",
      choice4: "",
      answer: "",
      studentAnswer: "",
      _id: mongoose.Types.ObjectId()
    };
    setFormData({
      ...formData,
      newComponentArray: [...formData.newComponentArray, multipleChoice]
    });
  };

  const checkAllBoxes = () => {
    const checkAllBoxes = {
      type: "checkAllBoxes",
      question: "",
      choice1: "",
      choice2: "",
      choice3: "",
      choice4: "",
      answer: [],
      studentAnswer: [],
      _id: mongoose.Types.ObjectId()
    };
    setFormData({
      ...formData,
      newComponentArray: [...formData.newComponentArray, checkAllBoxes]
    });
  };
  const textArea = () => {
    const textArea = {
      type: "textArea",
      question: "",
      answer: "",
      studentAnswer: "",
      _id: mongoose.Types.ObjectId()
    };
    setFormData({
      ...formData,
      newComponentArray: [...formData.newComponentArray, textArea]
    });
  };
  const matching = () => {
    const matching = {
      type: "matching",
      question: "",
      match1: {
        match1a: "",
        match1b: ""
      },
      match2: {
        match2a: "",
        match2b: ""
      },
      match3: {
        match3a: "",
        match3b: ""
      },
      match4: {
        match4a: "",
        match4b: ""
      },
      match5: {
        match5a: "",
        match5b: ""
      },
      match6: {
        match6a: "",
        match6b: ""
      },
      _id: uuid(),
      studentAnswer: {
        matchingbArray: [],
        matching1: [],
        matching2: [],
        matching3: [],
        matching4: [],
        matching5: [],
        matching6: [],
        firstTime: true
      }
    };

    setFormData({
      ...formData,
      newComponentArray: [...formData.newComponentArray, matching]
    });
  };

  const radioPick = (nr, index) => {
    const newArray = formData.newComponentArray.map((item, index2) => {
      if (index === index2) {
        return {
          ...item,
          answer: nr
        };
      } else {
        return item;
      }
    });
    setFormData({
      ...formData,
      newComponentArray: newArray
    });
  };
  const radioPickPlay = (nr, id) => {
    const newArray = formData.currentExam.components.map((item, index) => {
      if (item._id === id) {
        return {
          ...item,
          studentAnswer: nr
        };
      } else {
        return item;
      }
    });
    setFormData({
      ...formData,
      currentExam: {
        ...formData.currentExam,
        components: newArray
      }
    });
  };

  const multipleChoiceCheck = (id, nr) => {
    const find = formData.currentExam.components.find(item => item._id === id);

    if (find) {
      return find.studentAnswer === nr ? true : false;
    } else {
      return false;
    }
  };
  const checkPicks = (nr, index) => {
    const newArray = formData.newComponentArray.map((item, index2) => {
      if (index === index2) {
        const filter = item.answer.filter(item2 => item2 === nr);
        if (filter.length !== 0) {
          const filter2 = item.answer.filter(item2 => item2 !== nr);
          return {
            ...item,
            answer: filter2
          };
        } else {
          return {
            ...item,
            answer: [...item.answer, nr]
          };
        }
      } else {
        return item;
      }
    });
    setFormData({
      ...formData,
      newComponentArray: newArray
    });
  };
  const checkPicksPlay = (nr, id) => {
    const newArray = formData.currentExam.components.map((item, index) => {
      if (id === item._id) {
        const filter = item.studentAnswer.filter(item2 => item2 === nr);
        if (filter.length !== 0) {
          const filter2 = item.studentAnswer.filter(item2 => item2 !== nr);
          return {
            ...item,
            studentAnswer: filter2
          };
        } else {
          return {
            ...item,
            studentAnswer: [...item.studentAnswer, nr]
          };
        }
      } else {
        return item;
      }
    });
    setFormData({
      ...formData,
      currentExam: {
        ...formData.currentExam,
        components: newArray
      }
    });
  };

  const onChangeComponent = (index, e) => {
    const newArray = formData.newComponentArray.map((item, index2) => {
      if (index === index2) {
        return {
          ...item,
          [e.target.name]: e.target.value
        };
      } else {
        return item;
      }
    });
    setFormData({
      ...formData,
      newComponentArray: newArray
    });
  };
  const onChangeComponentPlay = (id, e) => {
    const newArray = formData.currentExam.components.map((item, index) => {
      if (id === item._id) {
        return {
          ...item,
          [e.target.name]: e.target.value
        };
      } else {
        return item;
      }
    });
    setFormData({
      ...formData,
      currentExam: {
        ...formData.currentExam,
        components: newArray
      }
    });
  };

  const textAreaValuePlay = id => {
    const find = formData.currentExam.components.find(item => item._id === id);
    if (find) {
      return find.studentAnswer;
    }
  };

  const onChangeMain = e => {
    setFormData({
      ...formData,
      newExam: {
        ...formData.newExam,
        [e.target.name]: e.target.value
      }
    });
  };

  const onChangeComponentMatch = (index, match, e) => {
    const newArray = formData.newComponentArray.map((item, index2) => {
      if (index === index2) {
        return {
          ...item,
          [match]: {
            ...item[match],
            [e.target.name]: e.target.value
          }
        };
      } else {
        return item;
      }
    });
    setFormData({
      ...formData,
      newComponentArray: newArray
    });
  };

  // checkboxes
  const checkboxAnswer = (nr, index) => {
    const filter = formData.newComponentArray[index].answer.filter(
      item => item === nr
    );
    if (filter.length !== 0) {
      return true;
    } else return false;
  };
  const checkboxAnswerPlay = (nr, id) => {
    const find = formData.currentExam.components.find(item => item._id === id);

    if (find) {
      const filter = find.studentAnswer.filter(item => item === nr);
      if (filter.length !== 0) {
        return true;
      } else return false;
    }
  };
  const scrollTo = variable => {
    return scrollIntoView(document.querySelector(variable), {
      behavior: "smooth",
      block: "start"
    });
  };

  useEffect(() => {
    const duration = parseInt(formData.selectedDuration.join());

    setFormData(prevState => {
      return {
        ...prevState,
        newExam: {
          ...prevState.newExam,
          duration: duration
        }
      };
    });
  }, [formData.selectedDuration]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        newExam: {
          ...prevState.newExam,
          students: prevState.selectedStudents
        }
      };
    });
  }, [formData.selectedStudents]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        newExam: {
          ...prevState.newExam,
          englishLevel: prevState.selectedLevel
        }
      };
    });
  }, [formData.selectedLevel]);

  const handleSelectDuration = value => {
    setFormData({
      ...formData,
      selectedDuration: value
    });
  };
  const handleSelectStudents = value => {
    const newArray = flattenDeep(value);

    const newArray2 = uniqWith(newArray);
    setFormData({
      ...formData,
      selectedStudents: newArray2
    });
  };
  const handleSelectLevel = value => {
    setFormData({
      ...formData,
      selectedLevel: value
    });
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      setFormData({
        ...formData,

        dragColor: ""
      });
    } else if (result.destination.index === result.source.index) {
      setFormData({
        ...formData,
        dragColor: ""
      });
    } else {
      const newArray = reorder(
        formData.newComponentArray,
        result.source.index,
        result.destination.index
      );

      setFormData({
        ...formData,
        newComponentArray: newArray,
        dragColor: ""
      });
    }
  };

  const onDragEnd2 = result => {
    if (!result.destination) {
    } else if (result.destination.droppableId === result.source.droppableId) {
    } else {
      const find = formData.currentComponent.studentAnswer[
        result.source.droppableId
      ].find(item => item._id === result.draggableId);

      const filter = formData.currentComponent.studentAnswer[
        result.source.droppableId
      ].filter(item => item._id !== result.draggableId);

      setFormData({
        ...formData,
        currentComponent: {
          ...formData.currentComponent,
          studentAnswer: {
            ...formData.currentComponent.studentAnswer,
            [result.destination.droppableId]: [
              ...formData.currentComponent.studentAnswer[
                result.destination.droppableId
              ],
              find
            ],
            [result.source.droppableId]: filter
          }
        }
        // matching: {
        //   ...formData.matching,
        //   [result.destination.droppableId]: [
        //     ...formData.matching[result.destination.droppableId],
        //     find
        //   ],
        //   [result.source.droppableId]: filter
        // }
      });
    }
  };

  const dragColor = index => {
    if (formData.dragColor !== index) {
      return "CarterOne cyanBackground text-white my-1 z-depth-2";
    } else {
      return "CarterOne dragBackground text-white my-1 z-depth-2";
    }
  };

  const onDragStart = result => {
    const index = result.source.index;
    setFormData({
      ...formData,
      dragColor: index
    });
  };

  const createExamFunc = () => {
    const checkMultipleChoice = formData.newComponentArray.filter(
      item => item.type === "multipleChoice"
    );
    const checkMultipleChoiceMap = checkMultipleChoice.map((item, index) => {
      if (
        !item.answer ||
        !item.choice1 ||
        !item.choice2 ||
        !item.choice3 ||
        !item.choice4 ||
        !item.question
      ) {
        return "missing";
      } else {
        return null;
      }
    });

    const findMissingMultipleChoice = checkMultipleChoiceMap.find(
      item => item === "missing"
    );

    const checkCheckBox = formData.newComponentArray.filter(
      item => item.type === "checkAllBoxes"
    );

    const checkCheckBoxMap = checkCheckBox.map((item, index) => {
      if (
        item.answer.length === 0 ||
        !item.choice1 ||
        !item.choice2 ||
        !item.choice3 ||
        !item.choice4 ||
        !item.question
      ) {
        return "missing";
      } else {
        return null;
      }
    });

    const findMissingCheckBox = checkCheckBoxMap.find(
      item => item === "missing"
    );

    const checktextArea = formData.newComponentArray.filter(
      item => item.type === "textArea"
    );

    const checktextAreaMap = checktextArea.map((item, index) => {
      if (!item.question) {
        return "missing";
      } else {
        return null;
      }
    });

    const findMissingtextArea = checktextAreaMap.find(
      item => item === "missing"
    );

    const checkMatching = formData.newComponentArray.filter(
      item => item.type === "matching"
    );

    const checkMatchingMap = checkMatching.map((item, index) => {
      if (
        !item.question ||
        !item.match1.match1a ||
        !item.match1.match1b ||
        !item.match2.match2a ||
        !item.match2.match2b ||
        !item.match3.match3a ||
        !item.match3.match3b ||
        !item.match4.match4a ||
        !item.match4.match4b ||
        !item.match5.match5a ||
        !item.match5.match5b ||
        !item.match6.match6a ||
        !item.match6.match6b
      ) {
        return "missing";
      } else {
        return null;
      }
    });

    const findMissingMatching = checkMatchingMap.find(
      item => item === "missing"
    );

    if (formData.newComponentArray.length === 0) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>Your exam needs at least one question</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (findMissingMultipleChoice) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A multiple choice question is missing a field</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (findMissingCheckBox) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A checkbox question is missing a field</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (findMissingtextArea) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A long answer question is missing a field</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (findMissingMatching) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A matching question is missing a field</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      setLoadingButtonCustomizables(true);
      const date = new Date();
      makeCustomizableExam({
        name: formData.newExam.name,
        description: formData.newExam.description,
        duration: formData.newExam.duration,
        students: formData.newExam.students,
        components: formData.newComponentArray,
        englishLevel: formData.selectedLevel,
        date: date,
        image: formData.selectImage
      });
    }
  };

  const openEdit2 = exam => {
    const newArrayLevel = formData.levelOptions.map((item, index) => {
      const filter = exam.englishLevel.filter(item2 => item2 === item.value);

      if (filter.length !== 0) {
        return {
          ...item,
          checked: true
        };
      } else {
        return item;
      }
    });
    const newArrayDuration = formData.durationOptions.map((item, index) => {
      if (item.value === exam.duration.toString()) {
        return {
          ...item,
          checked: true
        };
      } else {
        return item;
      }
    });
    const newArrayStudents = formData.studentOptions.map((item, index) => {
      const filter = exam.students.filter(item2 => item2._id === item.value);

      if (filter.length !== 0) {
        return {
          ...item,
          checked: true
        };
      } else {
        return item;
      }
    });

    setFormData({
      ...formData,
      modalNewExam: true,
      newExam: {
        ...formData.newExam,
        name: exam.name,
        description: exam.description,
        duration: exam.duration,
        students: exam.students
      },
      newComponentArray: exam.components,
      date: exam.date,
      selectImage: exam.image,
      selectedLevel: exam.englishLevel,
      selectedStudents: exam.students,
      edit: true,
      currentExamEditId: exam._id,
      levelOptions: newArrayLevel,
      durationOptions: newArrayDuration,
      studentOptions: newArrayStudents
    });
  };

  const updateExamFunc = () => {
    const checkMultipleChoice = formData.newComponentArray.filter(
      item => item.type === "multipleChoice"
    );
    const checkMultipleChoiceMap = checkMultipleChoice.map((item, index) => {
      if (
        !item.answer ||
        !item.choice1 ||
        !item.choice2 ||
        !item.choice3 ||
        !item.choice4 ||
        !item.question
      ) {
        return "missing";
      } else {
        return null;
      }
    });

    const findMissingMultipleChoice = checkMultipleChoiceMap.find(
      item => item === "missing"
    );

    const checkCheckBox = formData.newComponentArray.filter(
      item => item.type === "checkAllBoxes"
    );

    const checkCheckBoxMap = checkCheckBox.map((item, index) => {
      if (
        item.answer.length === 0 ||
        !item.choice1 ||
        !item.choice2 ||
        !item.choice3 ||
        !item.choice4 ||
        !item.question
      ) {
        return "missing";
      } else {
        return null;
      }
    });

    const findMissingCheckBox = checkCheckBoxMap.find(
      item => item === "missing"
    );

    const checktextArea = formData.newComponentArray.filter(
      item => item.type === "textArea"
    );

    const checktextAreaMap = checktextArea.map((item, index) => {
      if (!item.question) {
        return "missing";
      } else {
        return null;
      }
    });

    const findMissingtextArea = checktextAreaMap.find(
      item => item === "missing"
    );

    const checkMatching = formData.newComponentArray.filter(
      item => item.type === "matching"
    );

    const checkMatchingMap = checkMatching.map((item, index) => {
      if (
        !item.question ||
        !item.match1.match1a ||
        !item.match1.match1b ||
        !item.match2.match2a ||
        !item.match2.match2b ||
        !item.match3.match3a ||
        !item.match3.match3b ||
        !item.match4.match4a ||
        !item.match4.match4b ||
        !item.match5.match5a ||
        !item.match5.match5b ||
        !item.match6.match6a ||
        !item.match6.match6b
      ) {
        return "missing";
      } else {
        return null;
      }
    });

    const findMissingMatching = checkMatchingMap.find(
      item => item === "missing"
    );

    if (formData.newComponentArray.length === 0) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>Your exam needs at least one question</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (findMissingMultipleChoice) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A multiple choice question is missing a field</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (findMissingCheckBox) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A checkbox question is missing a field</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (findMissingtextArea) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A long answer question is missing a field</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (findMissingMatching) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A matching question is missing a field</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      setLoadingButtonCustomizables(true);
      const date = new Date();
      updateCusExam({
        name: formData.newExam.name,
        description: formData.newExam.description,
        duration: formData.newExam.duration,
        students: formData.newExam.students,
        components: formData.newComponentArray,
        englishLevel: formData.selectedLevel,
        date: date,
        image: formData.selectImage,
        _id: formData.currentExamEditId
      });
    }
  };

  const deleteExamFunc = async () => {
    await setLoadingButtonDeleteCustomizables(true);
    deleteCusExam({
      _id: formData.currentExamEditId
    });
  };

  const deleteModalOpen = () => {
    setFormData({
      ...formData,
      modalDeleteExam: !formData.modalDeleteExam
    });
  };

  // Play Exam Mode

  const props = useSpring({
    opacity: formData.modalPlayExam ? (formData.buttonHide ? 0 : 1) : 0,
    delay: 100,
    config: { duration: 1000 }
    // visibility: formData.modalPlayExam ? "visible" : "hidden",
    // margin: "auto",
    // maxWidth: "500px",

    // marginLeft: "-37.5%",
    // width: "75%",

    // left: "50%",

    // transform: "translate(-50%, 0)",
    // position: "fixed"
  });
  const props2 = useSpring({
    opacity: formData.buttonHide === false ? 0 : 1,
    marginTop: formData.buttonHide === false ? -200 : 0,
    // to: { opacity: 1, marginTop: 0, marginRight: 0 },
    config: { duration: 1000 }
  });
  const props3 = useSpring({
    opacity: formData.buttonHide === false ? 0 : 1,
    config: { duration: 1000 },
    delay: 100
  });

  const startExam = () => {
    setFormData({
      ...formData,
      buttonHide: true,
      currentComponent: formData.currentExam.components[0]
    });
  };

  const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  useEffect(() => {
    if (formData.currentComponent.type === "matching") {
      if (formData.currentComponent.studentAnswer.firstTime === true) {
        const arrayVar = [
          { value: formData.currentComponent.match1.match1b, _id: uuid() },
          { value: formData.currentComponent.match2.match2b, _id: uuid() },
          { value: formData.currentComponent.match3.match3b, _id: uuid() },
          { value: formData.currentComponent.match4.match4b, _id: uuid() },
          { value: formData.currentComponent.match5.match5b, _id: uuid() },
          { value: formData.currentComponent.match6.match6b, _id: uuid() }
        ];

        shuffleArray(arrayVar);

        //  aa

        setFormData(prevState => {
          return {
            ...prevState,
            matching: {
              ...prevState.matching,
              _id: prevState.currentComponent._id,
              matchingbArray: arrayVar,
              matching1: [
                {
                  value: prevState.currentComponent.match1.match1a,
                  _id: uuid()
                }
              ],
              matching2: [
                {
                  value: prevState.currentComponent.match2.match2a,
                  _id: uuid()
                }
              ],
              matching3: [
                {
                  value: prevState.currentComponent.match3.match3a,
                  _id: uuid()
                }
              ],
              matching4: [
                {
                  value: prevState.currentComponent.match4.match4a,
                  _id: uuid()
                }
              ],
              matching5: [
                {
                  value: prevState.currentComponent.match5.match5a,
                  _id: uuid()
                }
              ],
              matching6: [
                {
                  value: prevState.currentComponent.match6.match6a,
                  _id: uuid()
                }
              ],
              firstTime: false
            }
          };
        });
      } else {
        setFormData(prevState => {
          const newArray = prevState.currentExam.components.map(
            (item, index) => {
              if (item._id === prevState.currentComponent._id) {
                return {
                  ...item,
                  studentAnswer: prevState.currentComponent.studentAnswer
                };
              } else {
                return item;
              }
            }
          );
          return {
            ...prevState,
            currentExam: {
              ...prevState.currentExam,
              components: newArray
            }
          };
        });
      }
    }
  }, [formData.currentComponent]);

  const nextQuestion = () => {
    const componentLengh = formData.currentExam.components.length;
    const currentIndex = formData.currentExam.components.findIndex(
      component => component._id === formData.currentComponent._id
    );
    if (currentIndex === componentLengh - 1) {
      setFormData(prevState => {
        return {
          ...prevState,
          endOfComponentsArray: true
        };
      });
    } else {
      setFormData(prevState => {
        return {
          ...prevState,
          currentComponent: prevState.currentExam.components[currentIndex + 1]
        };
      });
    }
  };
  const previousQuestion = () => {
    const currentIndex = formData.currentExam.components.findIndex(
      component => component._id === formData.currentComponent._id
    );

    setFormData({
      ...formData,
      currentComponent: formData.currentExam.components[currentIndex - 1]
    });
  };

  const findBegin = () => {
    const currentIndex = formData.currentExam.components.findIndex(
      component => component._id === formData.currentComponent._id
    );
    if (currentIndex === 0) {
      return "d-none";
    }
  };
  const findEnd = () => {
    const componentLengh = formData.currentExam.components.length;

    const currentIndex = formData.currentExam.components.findIndex(
      component => component._id === formData.currentComponent._id
    );
    if (currentIndex === componentLengh - 1) {
      return "d-none";
    }
  };

  const goToComponent = component => {
    const find = formData.currentExam.components.find(
      item => item._id === component._id
    );

    setFormData({
      ...formData,
      currentComponent: find
    });
  };

  // bb

  useEffect(() => {
    if (formData.matching.matchingbArray.length !== 0) {
      setFormData(prevState => {
        const newArray = prevState.currentExam.components.map((item, index) => {
          if (item._id === prevState.matching._id) {
            return {
              ...item,
              studentAnswer: prevState.matching
            };
          } else {
            return item;
          }
        });
        const find = newArray.find(
          item => item._id === prevState.currentComponent._id
        );
        return {
          ...prevState,
          currentComponent: find,
          currentExam: {
            ...prevState.currentExam,
            components: newArray
          }
        };
      });
    }
  }, [formData.matching]);

  const removeComponent = component => {
    const filter = formData.newComponentArray.filter(
      item => item._id !== component._id
    );
    setFormData({
      ...formData,
      newComponentArray: filter
    });
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        shareOptions: [...prevState.groupOptions, ...prevState.studentOptions]
      };
    });
  }, [formData.studentOptions, formData.groupOptions]);

  // remove double scroll bar when in modal mode
  useEffect(() => {
    if (
      formData.modalNewExam === true ||
      formData.modalPlayExam === true ||
      formData.modalCorrectExam === true
    ) {
      document.body.style.cssText = "overflow-y: hidden !important;";
    } else {
      document.body.style.overflowY = "";
    }
  }, [
    formData.modalNewExam,
    formData.modalPlayExam,
    formData.modalCorrectExam
  ]);

  useEffect(() => {
    if (location.search) {
      const examId = location.search.slice(1);
      getIndividualExam({ _id: examId });
    }
  }, [location.search, getIndividualExam]);

  useEffect(() => {
    if (individualExam) {
      const openEdit = exam => {
        setFormData(prevState => {
          const newArrayLevel = prevState.levelOptions.map((item, index) => {
            const filter = exam.englishLevel.filter(
              item2 => item2 === item.value
            );

            if (filter.length !== 0) {
              return {
                ...item,
                checked: true
              };
            } else {
              return item;
            }
          });
          const newArrayDuration = prevState.durationOptions.map(
            (item, index) => {
              if (item.value === exam.duration.toString()) {
                return {
                  ...item,
                  checked: true
                };
              } else {
                return item;
              }
            }
          );
          const newArrayStudents = prevState.studentOptions.map(
            (item, index) => {
              const filter = exam.students.filter(
                item2 => item2._id === item.value
              );

              if (filter.length !== 0) {
                return {
                  ...item,
                  checked: true
                };
              } else {
                return item;
              }
            }
          );
          return {
            ...prevState,
            modalNewExam: true,
            newExam: {
              ...prevState.newExam,
              name: exam.name,
              description: exam.description,
              duration: exam.duration,
              students: exam.students
            },
            newComponentArray: exam.components,
            date: exam.date,
            selectImage: exam.image,
            selectedLevel: exam.englishLevel,
            selectedStudents: exam.students,
            edit: true,
            currentExamEditId: exam._id,
            levelOptions: newArrayLevel,
            durationOptions: newArrayDuration,
            studentOptions: newArrayStudents
          };
        });
      };
      openEdit(individualExam);
    }
  }, [individualExam]);

  // Search

  const onKeyPressEnterCustomizables = e => {
    if (e.key === "Enter") {
      examsSearchFunc();
    }
  };

  useEffect(() => {
    if (searchExams) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchExams: searchExams
        };
      });
    }
  }, [searchExams]);

  const onChangeExams = e =>
    setFormData({
      ...formData,
      searchvalue: e.target.value
    });

  const examsSearchFunc = () => {
    searchExamsFunction({
      keywords: formData.searchvalue,
      pageNumber: formData.pageNumber
    });
    setPaginationModeExams("search");
  };

  const clearExamsFunc = () => {
    setFormData({
      ...formData,
      searchvalue: "",
      searchExams: []
    });
    clearExamsSearchReducer();
    setPaginationModeExams("date");
    return formData.examList;
  };

  const searchExamsMap = () => {
    if (searchExams === "No Results") {
      return [];
    } else if (searchExams.length !== 0) {
      return searchExams;
    } else {
      return formData.examList;
    }
  };

  // Pagination

  const isActive1 = page => {
    if (initialPageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationExams = () => {
    if (paginationModeExams === "date") {
      getExamList({ initialPageNumber: "first" });
    } else if (paginationModeExams === "search") {
      searchExamsFunction({
        pageNumber: "first",
        keywords: formData.searchvalue
      });
    }
  };

  const previousPaginationExams = () => {
    if (paginationModeExams === "date") {
      getExamList({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationModeExams === "search") {
      searchExamsFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchvalue
      });
    }
  };

  const currentPaginationExams = index => {
    if (paginationModeExams === "date") {
      getExamList({ initialPageNumber: index + 1 });
    } else if (paginationModeExams === "search") {
      searchExamsFunction({
        pageNumber: index + 1,
        keywords: formData.searchvalue
      });
    }
  };

  const nextPaginationExams = () => {
    if (paginationModeExams === "date") {
      getExamList({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationModeExams === "search") {
      searchExamsFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchvalue
      });
    }
  };

  const lastPaginationExams = () => {
    if (paginationModeExams === "date") {
      getExamList({ initialPageNumber: "last" });
    } else if (paginationModeExams === "search") {
      searchExamsFunction({
        pageNumber: "last",
        keywords: formData.searchvalue
      });
    }
  };

  const props10 = useSpring({
    opacity:
      formData.bottomShow === false
        ? 0
        : 1 || formData.bottomShow === true
        ? 1
        : 0,

    config: { duration: 600 }
  });

  const bottomShowFunc = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        bottomShow: !prevState.bottomShow
      };
    });
  };

  // before return
  return loading ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Online Exams</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 mb-2">
        <MDBRow className="mt-3">
          <MDBCol size="12">
            <MDBCard
              className="mt-5 mb-2 px-5 mx-auto"
              style={{ fontWeight: 300, maxWidth: "90%" }}
            >
              <MDBRow className="pt-5" end>
                <MDBCol md="4" sm="8" className="px-4 col-9">
                  <h2 className="h1-responsive font-weight-bold cyanText livvicFont text-center">
                    Your Exams
                  </h2>
                </MDBCol>
                <MDBCol md="4" sm="2" className="col-3">
                  <div className="">
                    <MDBIcon
                      pull="right"
                      size="3x"
                      far
                      icon="plus-square"
                      style={{ cursor: "pointer" }}
                      className="cyanText pr-3 iconShadow2"
                      onClick={openNewExam}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className=" middleVertical5 mt-1" center>
                <MDBCol className="col-0" sm="2"></MDBCol>
                <MDBCol sm="4" className="col-11 marginLeftCus">
                  <MDBInput
                    className="black-text"
                    label="Search your exams"
                    labelClass="black-text align-items-center"
                    type="text"
                    containerClass="active-pink active-pink-2 mt-3 mb-3"
                    value={formData.searchvalue}
                    onChange={onChangeExams}
                    onKeyPress={onKeyPressEnterCustomizables}
                  />
                </MDBCol>
                <MDBCol className="col-1" sm="2">
                  <MDBRow className="align-items-center">
                    <div
                      className={
                        formData.searchvalue.length !== 0 ? "mx-1" : "d-none"
                      }
                    >
                      <MDBIcon
                        icon="search"
                        onClick={examsSearchFunc}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      className={
                        searchExams.length !== 0 || searchExams === "No Results"
                          ? "mx-1"
                          : "d-none"
                      }
                    >
                      <MDBIcon
                        icon="times-circle"
                        onClick={clearExamsFunc}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              {/* <MDBRow className='mb-5'>
                {searchExamsMap()
                  .slice(-3)
                  .map((item, index) => {
                    return (
                      <MDBCol key={index} md='4'>
                        <MDBCard narrow ecommerce className='mb-2'>
                          <div className='embed-responsive embed-responsive-4by3 '>
                            <div className='embed-responsive-item '>
                              <MDBCardImage
                                className='img-fluid'
                                cascade
                                top
                                src={item.image.urls.regular}
                                alt=''
                              />
                            </div>
                          </div>
                          <MDBCardBody cascade>
                            <MDBCardTitle className='text-center'>
                              <strong>
                                <p
                                  className='livvicFont'
                                  onClick={() => openPlayExam(item)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.name}
                                </p>
                              </strong>
                            </MDBCardTitle>
                            <MDBCardText className='text-center livvicFont'>
                              {item.description}
                            </MDBCardText>
                          </MDBCardBody>
                          <div className='rounded-bottom cyanBackground lighten-3 text-center py-2'>
                            <MDBRow className=' font-small middleVertical'>
                              <MDBCol className=' pr-2 white-text mr-auto'>
                                {item.englishLevel.join(", ")}
                              </MDBCol>
                              <MDBCol className=' pr-2'>
                                <MDBIcon
                                  onClick={() => openEdit(item)}
                                  far
                                  icon='edit'
                                  className='mr-1 white-text'
                                  style={{ cursor: "pointer" }}
                                />
                              </MDBCol>
                              <MDBCol className=' pr-2'>
                                <MDBIcon
                                  onClick={() => openCorrectExam(item)}
                                  fas='true'
                                  icon='ellipsis-v'
                                  className='mr-1 white-text'
                                  style={{ cursor: "pointer" }}
                                />
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </MDBCard>
                      </MDBCol>
                    );
                  })}
              </MDBRow> */}
              {/* <MDBRow className='mt-4' center>
                <MDBIcon
                  onClick={() => {
                    setFormData({
                      ...formData,
                      toggleList: !formData.toggleList
                    });
                  }}
                  fas
                  icon='list-ul'
                  className='mr-1 cyan-text '
                  style={{ cursor: "pointer" }}
                  size='3x'
                />
              </MDBRow> */}
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {/* Exam List Pagination  */}

      <MDBContainer className="mb-4">
        <MDBRow className="mt-3">
          <MDBCol size="12">
            <MDBCard
              className="my-5  mx-auto customizablesScroll"
              style={{ fontWeight: 300, maxWidth: "90%" }}
            >
              {/* <MDBScrollbar> */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "30rem" }}>
                  <MDBCol size="12">
                    <div className=" text-center ">
                      <MDBRow className="pt-3 rounded-top cyanBackground lighten-3">
                        <MDBCol className="text-center livvicFont col-6" lg="6">
                          <p className="text-white">Title</p>
                        </MDBCol>

                        <MDBCol className="text-center livvicFont col-3" lg="3">
                          <p className="text-white">Level</p>
                        </MDBCol>
                        <MDBCol className="text-center livvicFont col-3" lg="3">
                          <p className="text-white">Duration</p>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <div
                      className={
                        formData.searchExams === "No Results" &&
                        formData.examList.length !== 0
                          ? "mt-2"
                          : "d-none"
                      }
                    >
                      <p className="quickSand h4 black-text text-center">
                        There are no exam results for your search. Try Again.
                      </p>
                    </div>
                    {(formData.searchExams.length === 0 || "No Results") &&
                    formData.examList.length === 0 &&
                    examLoading === false ? (
                      <div className="text-center">
                        <img
                          className="imageFlex"
                          src={
                            "https://res.cloudinary.com/unnamed/image/upload/v1578482471/No_Exams_Found_fp9fea.png"
                          }
                          alt=""
                        />
                      </div>
                    ) : (
                      <div style={{ minHeight: "43rem" }}>
                        {searchExamsMap().map((item, index) => {
                          return (
                            <MDBRow key={item._id} className="pt-3">
                              <MDBCol className="text-center" size="6">
                                <MDBRow>
                                  <MDBCol className="">
                                    <MDBIcon
                                      onClick={() => openEdit2(item)}
                                      far
                                      icon="edit"
                                      className="mr-1 cyan-text "
                                      style={{ cursor: "pointer" }}
                                    />
                                  </MDBCol>
                                  <MDBCol className="mr-auto">
                                    <p
                                      onClick={() => openPlayExam(item)}
                                      style={{ cursor: "pointer" }}
                                      className="livvicFont fontBold "
                                    >
                                      {item.name}
                                    </p>
                                  </MDBCol>

                                  <MDBCol className="tablePaddingCus">
                                    <MDBIcon
                                      onClick={() => openCorrectExam(item)}
                                      fas="true"
                                      icon="ellipsis-v"
                                      className="mr-1 cyan-text"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>

                              <MDBCol
                                className="text-center middleVertical"
                                size="3"
                              >
                                <p className="livvicFont fontBold">
                                  {item.englishLevel.join(", ")}
                                </p>
                              </MDBCol>
                              <MDBCol
                                className="text-center middleVertical tablePaddingCus2"
                                size="3"
                              >
                                <p className="livvicFont fontBold">
                                  {item.duration === 0
                                    ? "No Duration"
                                    : `${item.duration} mins`}
                                </p>
                              </MDBCol>
                              <div className=" divHeight cyanBackground lighten-3 text-center py-1">
                                {" "}
                              </div>
                            </MDBRow>
                          );
                        })}
                      </div>
                    )}
                  </MDBCol>
                </div>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        {/* Pagination */}

        <div
          className={
            formData.examList.length !== 0 || searchExams.length !== 0
              ? "middleVertical mb-3"
              : "d-none"
          }
        >
          <MDBRow>
            <MDBCol>
              <MDBPagination circle>
                <MDBPageItem onClick={firstPaginationExams}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationExams}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationModeExams === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationExams}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationExams}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
      <div className="customizablesModal">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalNewExam}
            toggle
            className="modal-full"
          >
            <div className="modal-full modal-content ">
              <MDBRow className="pb-5 cusFooterMarginMobile" center>
                {/* Left Side */}
                <MDBCol md="8">
                  <MDBCard className="mx-4 my-4">
                    <MDBRow center className="mx-3 ">
                      <MDBCol
                        onClick={componentShowVar}
                        style={{ cursor: "pointer" }}
                        md="12"
                        className="mt-3 text-center "
                      >
                        <div
                          style={{ height: "5rem" }}
                          className="cyanBackground rounded-top middleVertical "
                        >
                          <MDBIcon
                            size="3x"
                            icon="plus-circle"
                            className="text-white align-self-center "
                          />
                        </div>
                      </MDBCol>
                      <div className="w-100"></div>
                      <MDBCol
                        className={
                          formData.componentShow === true ? "" : "d-none"
                        }
                      >
                        <div className="cyanBackground rounded-bottom">
                          <MDBRow>
                            <MDBCol className="text-center text-white mx-3">
                              <MDBIcon
                                style={{ cursor: "pointer" }}
                                size="2x"
                                icon="compact-disc"
                                className="text-white iconShadow"
                                onClick={multipleChoice}
                              />
                              <p
                                onClick={multipleChoice}
                                style={{ cursor: "pointer" }}
                                className="livvicFont"
                              >
                                Multiple Choice
                              </p>
                            </MDBCol>
                            <MDBCol className="text-center text-white">
                              <MDBIcon
                                onClick={checkAllBoxes}
                                style={{ cursor: "pointer" }}
                                size="2x"
                                icon="check-circle"
                                className="text-white iconShadow"
                              />

                              <p
                                onClick={checkAllBoxes}
                                className="livvicFont"
                                style={{ cursor: "pointer" }}
                              >
                                Check all the boxes
                              </p>
                            </MDBCol>
                            <MDBCol className="text-center text-white">
                              <MDBIcon
                                onClick={textArea}
                                style={{ cursor: "pointer" }}
                                size="2x"
                                icon="text-height"
                                className="text-white iconShadow"
                              />
                              <p
                                onClick={textArea}
                                className="livvicFont"
                                style={{ cursor: "pointer" }}
                              >
                                Long Answer
                              </p>
                            </MDBCol>
                            <MDBCol className="text-center text-white">
                              <MDBIcon
                                onClick={matching}
                                style={{ cursor: "pointer" }}
                                size="2x"
                                icon="project-diagram"
                                className="text-white iconShadow"
                              />
                              <p
                                onClick={matching}
                                className="livvicFont"
                                style={{ cursor: "pointer" }}
                              >
                                Match
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <div className="customizablesScroll">
                      <div style={{ overflowY: "auto" }}>
                        <div style={{ height: "30rem" }}>
                          <MDBCardBody>
                            <MDBCol>
                              {formData.newComponentArray.map((item, index) => {
                                if (item.type === "multipleChoice") {
                                  return (
                                    <Fragment key={index}>
                                      <MDBRow
                                        id={`multipleChoice${index}`}
                                        className="pb-5 mb-5  z-depth-2"
                                      >
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="12"
                                        >
                                          {/* Question */}
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="question"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .question
                                            }
                                            label="Question"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponent(index, e)
                                            }
                                          />
                                        </MDBCol>

                                        <MDBCol
                                          className="text-center"
                                          size="6"
                                        >
                                          {/* Option 1 and 2 */}
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice1"
                                                type="text"
                                                value={
                                                  formData.newComponentArray[
                                                    index
                                                  ].choice1
                                                }
                                                label="Choice 1"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                            <div className="w-100"></div>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice2"
                                                type="text"
                                                value={
                                                  formData.newComponentArray[
                                                    index
                                                  ].choice2
                                                }
                                                label="Choice 2"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                        {/* Option 2 and 3 */}
                                        <MDBCol
                                          className="text-center"
                                          size="6"
                                        >
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice3"
                                                type="text"
                                                value={
                                                  formData.newComponentArray[
                                                    index
                                                  ].choice3
                                                }
                                                label="Choice 3"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                            <div className="w-100"></div>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice4"
                                                type="text"
                                                value={
                                                  formData.newComponentArray[
                                                    index
                                                  ].choice4
                                                }
                                                label="Choice 4"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                        {/* Answer */}

                                        <MDBCol
                                          className="text-center"
                                          size="12"
                                        >
                                          <p className="text-center h5 mt-2 livvicFont">
                                            Answer
                                          </p>
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  radioPick(1, index)
                                                }
                                                checked={
                                                  formData.newComponentArray[
                                                    index
                                                  ].answer === 1
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio1-${index}`}
                                                label="1"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  radioPick(2, index)
                                                }
                                                checked={
                                                  formData.newComponentArray[
                                                    index
                                                  ].answer === 2
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio2-${index}`}
                                                label="2"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  radioPick(3, index)
                                                }
                                                checked={
                                                  formData.newComponentArray[
                                                    index
                                                  ].answer === 3
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio3-${index}`}
                                                label="3"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  radioPick(4, index)
                                                }
                                                checked={
                                                  formData.newComponentArray[
                                                    index
                                                  ].answer === 4
                                                    ? true
                                                    : false
                                                }
                                                type="radio"
                                                id={`radio4-${index}`}
                                                label="4"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center"
                                          size="12"
                                        >
                                          <MDBRow center>
                                            <MDBIcon
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                removeComponent(item)
                                              }
                                              className="red-text"
                                              size="1x"
                                              icon="trash"
                                            />
                                          </MDBRow>
                                        </MDBCol>
                                      </MDBRow>
                                    </Fragment>
                                  );
                                } else if (item.type === "checkAllBoxes") {
                                  return (
                                    <Fragment key={index}>
                                      <MDBRow
                                        id={`checkAllBoxes${index}`}
                                        className="pb-5 mb-5  z-depth-2"
                                      >
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="12"
                                        >
                                          {/* Question */}
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="question"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .question
                                            }
                                            label="Question"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponent(index, e)
                                            }
                                          />
                                        </MDBCol>

                                        <MDBCol
                                          className="text-center"
                                          size="6"
                                        >
                                          {/* Option 1 and 2 */}
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice1"
                                                type="text"
                                                value={
                                                  formData.newComponentArray[
                                                    index
                                                  ].choice1
                                                }
                                                label="Choice 1"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                            <div className="w-100"></div>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice2"
                                                type="text"
                                                value={
                                                  formData.newComponentArray[
                                                    index
                                                  ].choice2
                                                }
                                                label="Choice 2"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                        {/* Option 2 and 3 */}
                                        <MDBCol
                                          className="text-center"
                                          size="6"
                                        >
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice3"
                                                type="text"
                                                value={
                                                  formData.newComponentArray[
                                                    index
                                                  ].choice3
                                                }
                                                label="Choice 3"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                            <div className="w-100"></div>
                                            <MDBCol>
                                              <MDBInput
                                                name="choice4"
                                                type="text"
                                                value={
                                                  formData.newComponentArray[
                                                    index
                                                  ].choice4
                                                }
                                                label="Choice 4"
                                                labelClass="livvicFont"
                                                onChange={e =>
                                                  onChangeComponent(index, e)
                                                }
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                        {/* Answer */}

                                        <MDBCol
                                          className="text-center"
                                          size="12"
                                        >
                                          <p className="text-center h5 mt-2 livvicFont">
                                            Answer
                                          </p>
                                          <MDBRow>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  checkPicks(1, index)
                                                }
                                                checked={checkboxAnswer(
                                                  1,
                                                  index
                                                )}
                                                type="checkbox"
                                                id={`checkbox1-${index}`}
                                                label="1"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  checkPicks(2, index)
                                                }
                                                checked={checkboxAnswer(
                                                  2,
                                                  index
                                                )}
                                                type="checkbox"
                                                id={`checkbox2-${index}`}
                                                label="2"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  checkPicks(3, index)
                                                }
                                                checked={checkboxAnswer(
                                                  3,
                                                  index
                                                )}
                                                type="checkbox"
                                                id={`checkbox3-${index}`}
                                                label="3"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol>
                                              <MDBInput
                                                onClick={() =>
                                                  checkPicks(4, index)
                                                }
                                                checked={checkboxAnswer(
                                                  4,
                                                  index
                                                )}
                                                type="checkbox"
                                                id={`checkbox4-${index}`}
                                                label="4"
                                                labelClass="livvicFont"
                                              />
                                            </MDBCol>
                                            <MDBCol
                                              className="text-center"
                                              size="12"
                                            >
                                              <MDBRow center>
                                                <MDBIcon
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    removeComponent(item)
                                                  }
                                                  className="red-text"
                                                  size="1x"
                                                  icon="trash"
                                                />
                                              </MDBRow>
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                      </MDBRow>
                                    </Fragment>
                                  );
                                } else if (item.type === "textArea") {
                                  return (
                                    <Fragment key={index}>
                                      <MDBRow
                                        id={`textArea${index}`}
                                        className="pb-5 mb-5  z-depth-2"
                                      >
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="12"
                                        >
                                          {/* Question */}
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="question"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .question
                                            }
                                            label="Question"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponent(index, e)
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="12"
                                        >
                                          {/* Question */}
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="answer"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .answer
                                            }
                                            label="Answer (optional)"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponent(index, e)
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center"
                                          size="12"
                                        >
                                          <MDBRow center>
                                            <MDBIcon
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                removeComponent(item)
                                              }
                                              className="red-text"
                                              size="1x"
                                              icon="trash"
                                            />
                                          </MDBRow>
                                        </MDBCol>
                                      </MDBRow>
                                    </Fragment>
                                  );
                                } else if (item.type === "matching") {
                                  return (
                                    <Fragment key={index}>
                                      <MDBRow
                                        id={`matching${index}`}
                                        className="pb-5 mb-5  z-depth-2"
                                      >
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="12"
                                        >
                                          {/* Question */}
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="question"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .question
                                            }
                                            label="Question"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponent(index, e)
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match1a"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match1.match1a
                                            }
                                            label="Match 1a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match1",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match1b"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match1.match1b
                                            }
                                            label="Match 1b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match1",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match2a"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match2.match2a
                                            }
                                            label="Match 2a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match2",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match2b"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match2.match2b
                                            }
                                            label="Match 2b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match2",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match3a"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match3.match3a
                                            }
                                            label="Match 3a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match3",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match3b"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match3.match3b
                                            }
                                            label="Match 3b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match3",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match4a"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match4.match4a
                                            }
                                            label="Match 4a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match4",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match4b"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match4.match4b
                                            }
                                            label="Match 4b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match4",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match5a"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match5.match5a
                                            }
                                            label="Match 5a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match5",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match5b"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match5.match5b
                                            }
                                            label="Match 5b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match5",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match6a"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match6.match6a
                                            }
                                            label="Match 6a"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match6",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center customizablesMarginBottom "
                                          size="6"
                                        >
                                          <MDBInput
                                            style={{ overflowY: "auto" }}
                                            name="match6b"
                                            type="textarea"
                                            value={
                                              formData.newComponentArray[index]
                                                .match6.match6b
                                            }
                                            label="Match 6b"
                                            labelClass="livvicFont"
                                            onChange={e =>
                                              onChangeComponentMatch(
                                                index,
                                                "match6",
                                                e
                                              )
                                            }
                                          />
                                        </MDBCol>
                                        <MDBCol
                                          className="text-center"
                                          size="12"
                                        >
                                          <MDBRow center>
                                            <MDBIcon
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                removeComponent(item)
                                              }
                                              className="red-text"
                                              size="1x"
                                              icon="trash"
                                            />
                                          </MDBRow>
                                        </MDBCol>
                                      </MDBRow>
                                    </Fragment>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </MDBCol>
                          </MDBCardBody>
                        </div>
                      </div>
                    </div>
                  </MDBCard>
                </MDBCol>
                {/* Right Side */}
                <MDBCol md="4">
                  <MDBRow center className="mx-3 mt-5 ">
                    <MDBCol>
                      <div className="customizablesScroll2">
                        <div style={{ overflowY: "auto" }}>
                          <MDBListGroup style={{ height: "23rem" }}>
                            <DragDropContext
                              onDragStart={onDragStart}
                              onDragEnd={onDragEnd}
                            >
                              <Droppable droppableId="list">
                                {provided => (
                                  <div
                                    className="Hero-List"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    {formData.newComponentArray.map(
                                      (item, index) => {
                                        return (
                                          <Draggable
                                            key={index}
                                            draggableId={`${item.type}${index}`}
                                            index={index}
                                          >
                                            {provided => (
                                              <div
                                                className="Container"
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                              >
                                                <MDBListGroupItem
                                                  className={dragColor(index)}
                                                >
                                                  <MDBRow
                                                    style={{
                                                      cursor: "pointer"
                                                    }}
                                                    onClick={() =>
                                                      scrollTo(
                                                        `#${item.type}${index}`
                                                      )
                                                    }
                                                  >
                                                    <MDBCol
                                                      size="auto"
                                                      className="mr-auto"
                                                    >
                                                      {item.type ===
                                                      "multipleChoice" ? (
                                                        <MDBIcon
                                                          size="1x"
                                                          icon="compact-disc"
                                                          className="text-white"
                                                        />
                                                      ) : item.type ===
                                                        "checkAllBoxes" ? (
                                                        <MDBIcon
                                                          size="1x"
                                                          icon="check-circle"
                                                          className="text-white"
                                                        />
                                                      ) : item.type ===
                                                        "textArea" ? (
                                                        <MDBIcon
                                                          size="1x"
                                                          icon="text-height"
                                                          className="text-white"
                                                        />
                                                      ) : (
                                                        item.type ===
                                                          "matching" && (
                                                          <MDBIcon
                                                            size="1x"
                                                            icon="project-diagram"
                                                            className="text-white"
                                                          />
                                                        )
                                                      )}
                                                    </MDBCol>
                                                    <MDBCol size="auto">
                                                      <p className="text-white text-truncate">
                                                        {`${index + 1} : ${
                                                          item.question
                                                        }`}
                                                      </p>
                                                    </MDBCol>
                                                  </MDBRow>
                                                  {/* </HashLink> */}
                                                </MDBListGroupItem>
                                              </div>
                                            )}
                                          </Draggable>
                                        );
                                      }
                                    )}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </MDBListGroup>
                        </div>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow center className="mx-3 ">
                    <Fragment>
                      <MDBCol size="12">
                        <MDBInput
                          name="name"
                          type="text"
                          value={formData.newExam.name}
                          label="Title of Exam"
                          labelClass="livvicFont text-white"
                          onChange={onChangeMain}
                          className="text-white"
                        />
                      </MDBCol>
                      <MDBCol size="12">
                        <MDBInput
                          style={{ overflowY: "auto" }}
                          name="description"
                          type="textarea"
                          value={formData.newExam.description}
                          label="Description of Exam"
                          labelClass="livvicFont text-white"
                          onChange={onChangeMain}
                          className="text-white"
                        />
                      </MDBCol>
                      <MDBRow className="middleVertical">
                        <MDBCol>
                          <MDBBtn
                            size="sm"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                modalDurationSelect: true
                              });
                            }}
                          >
                            Select Duration
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol>
                          <MDBBtn
                            size="sm"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                modalStudentsSelect: true
                              });
                            }}
                          >
                            Select Students
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol>
                          <MDBBtn
                            size="sm"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                modalLevelSelect: true
                              });
                            }}
                          >
                            Select Level
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </Fragment>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBModalFooter className="cyanBackground">
                <MDBRow
                  center
                  className={formData.edit === true ? "cusFooterMobile" : ""}
                >
                  {formData.edit === true && (
                    <MDBBtn color="danger" onClick={deleteModalOpen} size="sm">
                      Delete
                    </MDBBtn>
                  )}

                  {formData.edit === true ? (
                    <MDBBtn
                      onClick={updateExamFunc}
                      color="green darken-1"
                      disabled={loadingButtonCustomizables}
                      size="sm"
                    >
                      {loadingButtonCustomizables === true && (
                        <div className="spinner-grow spinner-grow-sm" />
                      )}
                      Update
                    </MDBBtn>
                  ) : (
                    <MDBBtn
                      onClick={createExamFunc}
                      color="green darken-1"
                      disabled={loadingButtonCustomizables}
                      size="sm"
                      className="quickSand"
                    >
                      {loadingButtonCustomizables === true && (
                        <div className="spinner-grow spinner-grow-sm" />
                      )}
                      Save
                    </MDBBtn>
                  )}

                  <MDBBtn
                    className="quickSand"
                    color="blue"
                    onClick={closeNewExam}
                    size="sm"
                  >
                    Close
                  </MDBBtn>
                  <CustomizablePDF
                    newComponentArray={formData.newComponentArray}
                    newExam={formData.newExam}
                  ></CustomizablePDF>
                </MDBRow>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </MDBContainer>
      </div>
      <MDBContainer>
        <MDBModal
          isOpen={formData.modalDurationSelect}
          toggle={() => {
            setFormData({
              ...formData,
              modalDurationSelect: false
            });
          }}
        >
          <MDBModalHeader className="cyanBackground whiteFont">
            Exam Duration
          </MDBModalHeader>
          <MDBModalBody className="cyanBackground">
            <MDBSelect
              options={formData.durationOptions}
              selected="Exam Duration"
              label="Exam Duration"
              labelClass="white-text"
              getValue={handleSelectDuration}
            />
          </MDBModalBody>

          <MDBModalFooter className="cyanBackground">
            <MDBBtn
              onClick={() => {
                setFormData({
                  ...formData,
                  modalDurationSelect: false
                });
              }}
              size="sm"
              color="dark"
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          isOpen={formData.modalStudentsSelect}
          toggle={() => {
            setFormData({
              ...formData,
              modalStudentsSelect: false
            });
          }}
        >
          <MDBModalHeader className="cyanBackground whiteFont">
            Your Students
          </MDBModalHeader>
          <MDBModalBody className="cyanBackground">
            <div className="dropDownStudents">
              <MDBSelect
                options={formData.shareOptions}
                search
                selected="Choose your students"
                label="Choose your students"
                labelClass="white-text"
                multiple
                getValue={handleSelectStudents}
              />
            </div>
          </MDBModalBody>

          <MDBModalFooter className="cyanBackground">
            <MDBBtn
              onClick={() => {
                setFormData({
                  ...formData,
                  modalStudentsSelect: false
                });
              }}
              size="sm"
              color="dark"
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          isOpen={formData.modalLevelSelect}
          toggle={() => {
            setFormData({
              ...formData,
              modalLevelSelect: false
            });
          }}
        >
          <MDBModalHeader className="cyanBackground whiteFont">
            English Level
          </MDBModalHeader>
          <MDBModalBody className="cyanBackground">
            <MDBSelect
              options={formData.levelOptions}
              search
              selected="Choose Level"
              label="Choose Level"
              labelClass="white-text"
              multiple
              getValue={handleSelectLevel}
            />
          </MDBModalBody>

          <MDBModalFooter className="cyanBackground">
            <MDBBtn
              onClick={() => {
                setFormData({
                  ...formData,
                  modalLevelSelect: false
                });
              }}
              size="sm"
              color="dark"
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>

      <MDBContainer>
        <MDBModal isOpen={formData.modalDeleteExam} toggle={deleteModalOpen}>
          <MDBModalHeader>Confirm Delete</MDBModalHeader>
          <MDBModalBody>
            If you would like to delete this exam please click on the 'confirm'
            button below.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={deleteModalOpen} size="sm" color="dark">
              Close
            </MDBBtn>
            <MDBBtn
              disabled={loadingButtonDeleteCustomizables}
              onClick={deleteExamFunc}
              size="sm"
              color="danger"
            >
              {loadingButtonDeleteCustomizables === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Confirm
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      {/* PLAY Exam */}
      <div className="customizablesModal">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalPlayExam}
            toggle
            className="modal-full"
          >
            <div className="modal-full modal-content ">
              <MDBRow className="pb-5 mb-5">
                {/* Play Left Side */}
                <MDBCol md="9">
                  <MDBRow className="mt-5 mx-2 " center>
                    <MDBCol size="12">
                      <animated.div style={props3}>
                        <MDBCard className="z-depth-2 ">
                          {formData.currentComponent.type ===
                          "multipleChoice" ? (
                            <Fragment>
                              <MDBRow className="pb-5 mb-5 mt-4 examPlayHeight ">
                                <MDBCol className="text-left " size="12">
                                  {/* Question */}
                                  <p className="h3-responsive livvicFont fontBold ml-3">
                                    {formData.currentComponent.question}
                                  </p>
                                </MDBCol>

                                {/* Answer */}

                                <MDBCol size="12">
                                  <MDBRow className="mx-1">
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          radioPickPlay(
                                            1,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={multipleChoiceCheck(
                                          formData.currentComponent._id,
                                          1
                                        )}
                                        type="radio"
                                        id={`radio1`}
                                        label={
                                          formData.currentComponent.choice1
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          radioPickPlay(
                                            2,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={multipleChoiceCheck(
                                          formData.currentComponent._id,
                                          2
                                        )}
                                        type="radio"
                                        id={`radio2`}
                                        label={
                                          formData.currentComponent.choice2
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          radioPickPlay(
                                            3,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={multipleChoiceCheck(
                                          formData.currentComponent._id,
                                          3
                                        )}
                                        type="radio"
                                        id={`radio3`}
                                        label={
                                          formData.currentComponent.choice3
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          radioPickPlay(
                                            4,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={multipleChoiceCheck(
                                          formData.currentComponent._id,
                                          4
                                        )}
                                        type="radio"
                                        id={`radio4`}
                                        label={
                                          formData.currentComponent.choice4
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                  </MDBRow>
                                </MDBCol>
                              </MDBRow>
                            </Fragment>
                          ) : formData.currentComponent.type ===
                            "checkAllBoxes" ? (
                            <Fragment>
                              <MDBRow className="pb-5 mb-5 mt-4 examPlayHeight ">
                                <MDBCol className="text-left " size="12">
                                  {/* Question */}
                                  <p className="h3-responsive livvicFont fontBold ml-3">
                                    {formData.currentComponent.question}
                                  </p>
                                </MDBCol>

                                {/* Answer */}

                                <MDBCol size="12">
                                  <MDBRow className="mx-1">
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          checkPicksPlay(
                                            1,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={checkboxAnswerPlay(
                                          1,
                                          formData.currentComponent._id
                                        )}
                                        type="checkbox"
                                        id={`radio1`}
                                        label={
                                          formData.currentComponent.choice1
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          checkPicksPlay(
                                            2,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={checkboxAnswerPlay(
                                          2,
                                          formData.currentComponent._id
                                        )}
                                        type="checkbox"
                                        id={`radio2`}
                                        label={
                                          formData.currentComponent.choice2
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          checkPicksPlay(
                                            3,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={checkboxAnswerPlay(
                                          3,
                                          formData.currentComponent._id
                                        )}
                                        type="checkbox"
                                        id={`radio3`}
                                        label={
                                          formData.currentComponent.choice3
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          checkPicksPlay(
                                            4,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={checkboxAnswerPlay(
                                          4,
                                          formData.currentComponent._id
                                        )}
                                        type="checkbox"
                                        id={`radio4`}
                                        label={
                                          formData.currentComponent.choice4
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                  </MDBRow>
                                </MDBCol>
                              </MDBRow>
                            </Fragment>
                          ) : formData.currentComponent.type === "textArea" ? (
                            <Fragment>
                              <MDBRow className="pb-5 mb-5 mt-4 examPlayHeight ">
                                <MDBCol className="text-left " size="12">
                                  {/* Question */}
                                  <p className="h3-responsive livvicFont fontBold ml-3">
                                    {formData.currentComponent.question}
                                  </p>
                                </MDBCol>
                                <MDBCol className="text-center" size="12">
                                  <MDBRow className="mx-3">
                                    <MDBCol>
                                      <MDBInput
                                        style={{ overflowY: "auto" }}
                                        name="studentAnswer"
                                        type="textarea"
                                        value={textAreaValuePlay(
                                          formData.currentComponent._id
                                        )}
                                        label="Answer "
                                        labelClass="livvicFont"
                                        onChange={e =>
                                          onChangeComponentPlay(
                                            formData.currentComponent._id,
                                            e
                                          )
                                        }
                                      />
                                    </MDBCol>
                                  </MDBRow>
                                </MDBCol>
                              </MDBRow>
                            </Fragment>
                          ) : formData.currentComponent.type === "matching" ? (
                            <Fragment>
                              <DragDropContext
                                // onDragStart={onDragStart}
                                onDragEnd={onDragEnd2}
                              >
                                <MDBRow className="pb-5 mb-5 mt-4 examPlayHeight ">
                                  <MDBCol className="text-left " size="12">
                                    {/* Question */}
                                    <p className="h3-responsive livvicFont fontBold ml-3">
                                      {formData.currentComponent.question}
                                    </p>
                                  </MDBCol>
                                  <MDBCol className="text-center" size="12">
                                    <MDBRow className="mx-3">
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching1">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching1.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match1.match1a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching2">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching2.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match2.match2a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching3">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching3.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match3.match3a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching4">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching4.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match4.match4a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching5">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching5.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match5.match5a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching6">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching6.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match6.match6a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow center>
                                      <Droppable
                                        isDropDisabled={true}
                                        droppableId="matchingbArray"
                                        direction="vertical"
                                      >
                                        {provided => (
                                          <div
                                            className="Hero-List"
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                          >
                                            {formData.currentComponent.studentAnswer.matchingbArray.map(
                                              (item, index) => {
                                                return (
                                                  <Draggable
                                                    key={item._id}
                                                    draggableId={item._id}
                                                    index={index}
                                                  >
                                                    {provided => (
                                                      <div
                                                        className="Container"
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                      >
                                                        <MDBCol>
                                                          <p className="h5-responsive livvicFont">
                                                            {item.value}{" "}
                                                          </p>
                                                        </MDBCol>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                );
                                              }
                                            )}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </MDBRow>
                                  </MDBCol>
                                </MDBRow>
                              </DragDropContext>
                            </Fragment>
                          ) : null}
                          <div
                            className={
                              formData.currentExam.components.length > 1
                                ? "rounded-bottom blue lighten-3 text-center py-2"
                                : "d-none"
                            }
                          >
                            <MDBRow center className="pt-3">
                              <MDBCol className="text-center livvicFont">
                                <MDBBtn
                                  onClick={previousQuestion}
                                  className={findBegin()}
                                  color="blue"
                                >
                                  Previous
                                </MDBBtn>
                              </MDBCol>

                              <MDBCol className="text-center livvicFont">
                                <MDBBtn
                                  onClick={nextQuestion}
                                  className={findEnd()}
                                  color="blue"
                                >
                                  Next
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </MDBCard>
                      </animated.div>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                {/* Play Right Side */}
                <MDBCol md="3">
                  <MDBRow center className="mr-1 mt-1 ">
                    <MDBCol size="12">
                      <animated.div style={props2}>
                        <div className="customizablesScroll2">
                          <div style={{ overflowY: "auto" }}>
                            <MDBListGroup style={{ height: "40rem" }}>
                              {formData.currentExam.components.map(
                                (item, index) => {
                                  return (
                                    <MDBListGroupItem
                                      key={item._id}
                                      className="CarterOne cyanBackground text-white my-1 z-depth-2"
                                    >
                                      <MDBRow
                                        style={{ cursor: "pointer" }}
                                        onClick={() => goToComponent(item)}
                                      >
                                        {/* <MDBCol size="auto" className="mr-auto">
                                        {item.type === "multipleChoice" ? (
                                          <MDBIcon
                                            size="1x"
                                            icon="compact-disc"
                                            className="text-white"
                                          />
                                        ) : item.type === "checkAllBoxes" ? (
                                          <MDBIcon
                                            size="1x"
                                            icon="check-circle"
                                            className="text-white"
                                          />
                                        ) : item.type === "textArea" ? (
                                          <MDBIcon
                                            size="1x"
                                            icon="text-height"
                                            className="text-white"
                                          />
                                        ) : (
                                          item.type === "matching" && (
                                            <MDBIcon
                                              size="1x"
                                              icon="project-diagram"
                                              className="text-white"
                                            />
                                          )
                                        )}
                                      </MDBCol> */}
                                        <MDBCol size="auto">
                                          <p className="text-white text-truncate">
                                            {`${index + 1} : ${item.question}`}
                                          </p>
                                        </MDBCol>
                                      </MDBRow>
                                      {/* </HashLink> */}
                                    </MDBListGroupItem>
                                  );
                                }
                              )}
                            </MDBListGroup>
                          </div>
                        </div>
                      </animated.div>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <animated.div
                className="centered"
                style={
                  props.opacity
                    ? {
                        ...props,
                        visibility: props.opacity.interpolate(o =>
                          o === 0 ? "hidden" : "visible"
                        )
                      }
                    : null
                }
              >
                <MDBRow className="justify-content-center">
                  <MDBCol size="10">
                    <MDBCard className="z-depth-2 cyanBackground py-3 px-3 mx-2">
                      <MDBRow center>
                        {formData.currentExam.duration === 0 ? (
                          <p className="h3 text-center livvicFont text-white mx-3">
                            {`There is no time limit for this exam`}
                          </p>
                        ) : (
                          <p className="h3 text-center livvicFont text-white mx-3">
                            {`You have ${formData.currentExam.duration} mins to complete the Exam.`}
                          </p>
                        )}
                      </MDBRow>
                      <MDBRow center>
                        <MDBBtn onClick={startExam} color="blue" className="">
                          Start Exam
                        </MDBBtn>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </animated.div>
              <MDBModalFooter className="cyanBackground">
                <MDBBtn color="blue" onClick={closePlayExam}>
                  Exit
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </MDBContainer>
      </div>
      <div className="customizablesModal">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalCorrectExam}
            toggle
            className="modal-full"
          >
            <div className="modal-full modal-content ">
              <CorrectedExams
                closeModalFunc={closeCorrectExam}
                currentExam={formData.currentExam}
              ></CorrectedExams>
            </div>
          </MDBModal>
        </MDBContainer>
      </div>
      {formData.bottomShow === false ? (
        <div className="removeUl hideBottom">
          <MDBBtn
            floating
            color="color-one"
            icon="info-circle"
            style={{ bottom: "24px", right: "24px" }}
            onClick={bottomShowFunc}
            size="sm"
            className="btnFixed"
          >
            <MDBIcon icon="info-circle"></MDBIcon>
          </MDBBtn>
        </div>
      ) : null}
      <animated.div style={props10}>
        {formData.bottomShow === true ? (
          <div className="float-wrap text-center z-depth-1-half">
            <MDBCard style={{ height: "16rem" }}>
              <MDBIcon
                onClick={bottomShowFunc}
                style={{ cursor: "pointer" }}
                size="2x"
                className="text-right mainColor bottomButton mr-1 mt-1"
                icon="window-close"
              />
              <MDBRow>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Creating an exam
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Creating an exam"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/qKFsNjfDPwU?rel=0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Modifying an exam
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Modifying an exam"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/SynLzoQRvAE?rel=0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Assigning an exam
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Assigning an exam"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/WJJousNzqnQ"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        ) : null}
      </animated.div>
    </Fragment>
  );
};

Customizables.propTypes = {};

const mapStateToProps = state => ({
  customizables: state.customizables,
  students: state.students,
  auth: state.auth,
  groups: state.groups
});

export default withRouter(
  connect(mapStateToProps, {
    getStudentsSelect,
    makeCustomizableExam,
    getExamList,
    updateCusExam,
    deleteCusExam,
    getGroupsSelect,
    clearCustomizablesReducer,
    getIndividualExam,
    searchExamsFunction,
    setPaginationModeExams,
    clearExamsSearchReducer,
    setLoadingButtonCustomizables,
    setLoadingButtonDeleteCustomizables
  })(Customizables)
);
