import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import "./Landing.css";

const Terms = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student Terms</title>
        <meta
          name="description"
          content="Topscholar terms and conditions for students"
        />
        <meta property="og:title" content="Topscholar: Student Terms" />
        <meta
          property="og:description"
          content="Topscholar terms and conditions for students"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/studentterms" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5 fill">
        <MDBRow className="pt-5 mt-3 ">
          <MDBCol md="12" className="text-center">
            <h3 className="font-weight-bold monserratFont mb-3 cookieMarginsMobile">
              {t("terms.1")}
            </h3>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.2")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.3")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.4")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.5")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.6")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.7")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.8")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.9")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.10")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.11")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.12")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.13")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.14")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.15")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.16")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.17")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.18")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.19")}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://stripe.com/payment-terms/legal"}
              >
                stripe.com/payment-terms/legal
              </a>
              {t("terms.19half")}
            </p>

            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.20")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.21")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.22")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.23")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.24")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.25")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.26")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.27")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.28")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.29")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.30")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.31")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.32")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.33")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.34")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.35")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("terms.36")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.37")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("terms.38")}
            </p>
            <h3 className="font-weight-bold monserratFont mb-3 cookieMarginsMobile">
              {t("findtutorsterms.1")}
            </h3>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.2")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.3")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("findtutorsterms.4")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.5")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("findtutorsterms.6")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.7")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.8")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.9")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("findtutorsterms.10")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.11")}{" "}
              <a href="https://stripe.com/docs/disputes/connect">
                {" "}
                stripe.com/docs/disputes/connect
              </a>
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("findtutorsterms.12")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.13")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.14")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.15")}
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default withRouter(Terms);
