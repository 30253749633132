import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import TradingMain from "../TradingMain";
import {
  getPublishedExam,
  downloadExam,
  getPublishedEditedExams,
  setLoadingButtonDownload
} from "../../../actions/trading";

const Exam = ({
  history,
  mode,
  match,
  getPublishedExam,
  setLoadingButtonDownload,
  trading: { publishedExam },
  downloadExam,
  getPublishedEditedExams,
  block
}) => {
  const [formData, setFormData] = useState({
    mode: "exam",
    block: false
  });

  useEffect(() => {
    getPublishedExam(match.params.id);
  }, [getPublishedExam, match]);

  const downloadExamFunc = () => {
    setLoadingButtonDownload(true);
    downloadExam({
      name: publishedExam.name,
      description: publishedExam.description,
      duration: publishedExam.duration,
      students: publishedExam.students,
      components: publishedExam.components,
      englishLevel: publishedExam.englishLevel,
      date: publishedExam.date,
      image: publishedExam.image,
      tradingId: publishedExam._id
    });
  };

  useEffect(() => {
    if (publishedExam) {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "exam"
        };
      });
    }
  }, [publishedExam]);

  useEffect(() => {
    getPublishedEditedExams({ originalId: match.params.id });
  }, [getPublishedEditedExams, match]);

  useEffect(() => {
    if (block === true || block === false) {
      setFormData(prevState => {
        return {
          ...prevState,
          block: block
        };
      });
    }
  }, [block]);

  return (
    <Fragment>
      <TradingMain
        downloadExamFunc={downloadExamFunc}
        examContent={publishedExam}
        url={formData.mode}
        block={formData.block}
      ></TradingMain>
    </Fragment>
  );
};

Exam.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    getPublishedExam,
    downloadExam,
    getPublishedEditedExams,
    setLoadingButtonDownload
  })(Exam)
);
