import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getEvals } from "../../actions/customizables";
import {
  getEvaluationList,
  makeEvaluation,
  deleteEvaluation,
  updateEvaluation,
  setLoadingButtonEvaluations
} from "../../actions/evaluations";
import { withRouter } from "react-router-dom";
import Spinner from "../layout/Spinner";
import Table from "react-bootstrap/Table";
import { DateTimePicker } from "react-widgets";
import { orderBy } from "natural-orderby";

import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import "./student.css";
import {
  MDBInput,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBTable,
  MDBTableBody,
  MDBTableHead
} from "mdbreact";

moment.locale("en");
momentLocalizer();

const StudentExamEvaluations = ({
  students: { examevaluations, loading },
  customizables: { cusEvalList, initialPageNumberCus, initialNumberOfPagesCus },
  evaluations: {
    listEvaluations,
    evaluation,
    initialPageNumber,
    initialNumberOfPages,
    loadingButtonEvaluations
  },
  getEvaluationList,
  makeEvaluation,
  deleteEvaluation,
  updateEvaluation,
  getEvals,
  match,
  connect,
  setLoadingButtonEvaluations
}) => {
  const [formData, setFormData] = useState({
    cusEvalList: [],
    evaluations: [],
    date: moment(new Date()).valueOf(),
    add: false,
    update: false,
    title: "",
    grade: "",
    id: ""
  });

  const onChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const add = () => {
    setFormData({
      ...formData,
      add: !formData.add,
      update: false,
      date: moment(new Date()).valueOf(),
      title: "",
      grade: "",
      id: ""
    });
  };

  const showUpdate = evaluation => {
    setFormData({
      ...formData,
      update: true,
      add: false,
      grade: evaluation.grade,
      title: evaluation.title,
      date: evaluation.date,
      id: evaluation._id
    });
  };

  useEffect(() => {
    if (connect) {
    } else {
      getEvals({ studentId: match.params.id, initialPageNumberCus: "first" });
      getEvaluationList({
        studentId: match.params.id,
        initialPageNumber: "first"
      });
      setFormData(prevState => {
        return {
          ...prevState,
          date: moment(new Date()).valueOf(),
          add: false,
          update: false,
          title: "",
          grade: "",
          id: ""
        };
      });
    }
  }, [getEvaluationList, getEvals, match, connect]);

  useEffect(() => {
    if (cusEvalList) {
      const sortedEvaluations = orderBy(
        cusEvalList,
        [v => v.dateExamStarted],
        ["desc"]
      );
      setFormData(prevState => {
        return {
          ...prevState,
          cusEvalList: sortedEvaluations
        };
      });
    }
  }, [cusEvalList]);

  useEffect(() => {
    if (listEvaluations) {
      const sortedEvaluations = orderBy(
        listEvaluations,
        [v => v.date],
        ["desc"]
      );

      setFormData(prevState => {
        return {
          ...prevState,
          evaluations: sortedEvaluations,
          date: moment(new Date()).valueOf(),
          add: false,
          update: false,
          title: "",
          grade: "",
          id: ""
        };
      });
    }
  }, [listEvaluations]);

  const getPickerValue = value => {
    if (value) {
      const mystring = moment(value).valueOf();

      setFormData({
        ...formData,

        date: mystring
      });
    }
  };

  const updateEvaluationFunc = () => {
    setLoadingButtonEvaluations(true);
    updateEvaluation({
      date: formData.date,
      grade: formData.grade,
      title: formData.title,
      _id: formData.id
    });
  };

  const addEvaluation = async () => {
    await setLoadingButtonEvaluations(true);
    setFormData({
      ...formData,
      date: moment(new Date()).valueOf(),
      grade: "",
      title: ""
    });
    await makeEvaluation({
      studentId: match.params.id,
      date: formData.date,
      grade: formData.grade,
      title: formData.title
    });
  };

  // Pagination Evaluations List

  const isActive1 = page => {
    if (initialPageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationEvaluationList = () => {
    getEvaluationList({
      initialPageNumber: "first",
      studentId: match.params.id
    });
  };

  const previousPaginationEvaluationList = () => {
    getEvaluationList({
      initialPageNumber: initialPageNumber - 1,
      studentId: match.params.id
    });
  };

  const currentPaginationEvaluationList = index => {
    getEvaluationList({
      initialPageNumber: index + 1,
      studentId: match.params.id
    });
  };

  const nextPaginationEvaluationList = () => {
    getEvaluationList({
      initialPageNumber: initialPageNumber + 1,
      studentId: match.params.id
    });
  };

  const lastPaginationEvaluationList = () => {
    getEvaluationList({
      initialPageNumber: "last",
      studentId: match.params.id
    });
  };

  // Pagination Customizable Exams

  const isActive2 = page => {
    if (initialPageNumberCus === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationCus = () => {
    getEvals({ studentId: match.params.id, initialPageNumberCus: "first" });
  };

  const previousPaginationCus = () => {
    getEvals({
      initialPageNumberCus: initialPageNumberCus - 1,
      studentId: match.params.id
    });
  };

  const currentPaginationCus = index => {
    getEvals({
      initialPageNumberCus: index + 1,
      studentId: match.params.id
    });
  };

  const nextPaginationCus = () => {
    getEvals({
      initialPageNumberCus: initialPageNumberCus + 1,
      studentId: match.params.id
    });
  };

  const lastPaginationCus = () => {
    getEvals({
      initialPageNumberCus: "last",
      studentId: match.params.id
    });
  };

  return loading || examevaluations === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <MDBRow center className="mb-3">
        <p className="quickSand h5 font-weight-bold">Online Exam Evaluations</p>
      </MDBRow>
      <div style={{ minHeight: "28rem" }}>
        <Table className="quickSand" fixed="true" bordered hover size="sm">
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Exam Title</th>
              <th style={{ width: "15%" }}>Grade</th>
              <th style={{ width: "30%" }}>Date</th>
            </tr>
          </thead>
          <tbody>
            {formData.cusEvalList.map((evaluation, idx) => {
              return (
                <Fragment key={idx}>
                  <tr>
                    <td>
                      {connect
                        ? evaluation.array[0].examName
                        : // <Link
                          //   to={`/exams/${evaluation.array[0].examId}`}
                          //   className=""
                          // >
                          evaluation.name
                      // </Link>
                      }
                    </td>
                    <td>{evaluation.grade} %</td>
                    <td>
                      {moment(evaluation.dateExamStarted, "X").format(
                        "YYYY MM DD"
                      )}
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>

        {/* Pagination Customizable Exam Evaluation List Medium Size*/}
      </div>

      <div
        className={
          formData.cusEvalList.length !== 0
            ? "middleVertical mt-5 mb-1 paginationGroupsMedium"
            : "d-none"
        }
      >
        <MDBRow>
          <MDBCol>
            <MDBPagination circle>
              <MDBPageItem onClick={firstPaginationCus}>
                <MDBPageNav className="page-link">
                  <span>First</span>
                </MDBPageNav>
              </MDBPageItem>
              <MDBPageItem onClick={previousPaginationCus}>
                <MDBPageNav className="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </MDBPageNav>
              </MDBPageItem>
              {[...Array(initialNumberOfPagesCus)].map((item, index) => {
                if (index + 1 === initialPageNumberCus) {
                  return (
                    <MDBPageItem
                      key={index}
                      active={isActive2(index + 1)}
                      onClick={() => currentPaginationCus(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus - 1) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus - 2) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus - 3) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus + 1) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus + 2) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus + 3) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else {
                  return null;
                }
              })}
              <MDBPageItem onClick={nextPaginationCus}>
                <MDBPageNav className="page-link">&raquo;</MDBPageNav>
              </MDBPageItem>
              <MDBPageItem onClick={lastPaginationCus}>
                <MDBPageNav className="page-link">Last</MDBPageNav>
              </MDBPageItem>
            </MDBPagination>
          </MDBCol>
        </MDBRow>
      </div>
      {/* Pagination Customizable Exam Evaluation List Small Size*/}

      <div
        className={
          formData.cusEvalList.length !== 0
            ? "middleVertical mt-5 mb-1 paginationGroupsSmall"
            : "d-none"
        }
      >
        <MDBRow>
          <MDBCol>
            <MDBPagination circle size="sm">
              <MDBPageItem onClick={firstPaginationCus}>
                <MDBPageNav className="page-link">
                  <span>First</span>
                </MDBPageNav>
              </MDBPageItem>
              <MDBPageItem onClick={previousPaginationCus}>
                <MDBPageNav className="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </MDBPageNav>
              </MDBPageItem>
              {[...Array(initialNumberOfPagesCus)].map((item, index) => {
                if (index + 1 === initialPageNumberCus) {
                  return (
                    <MDBPageItem
                      key={index}
                      active={isActive2(index + 1)}
                      onClick={() => currentPaginationCus(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus - 1) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus - 2) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus - 3) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus + 1) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus + 2) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumberCus + 3) {
                  return (
                    <MDBPageItem onClick={() => currentPaginationCus(index)}>
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else {
                  return null;
                }
              })}
              <MDBPageItem onClick={nextPaginationCus}>
                <MDBPageNav className="page-link">&raquo;</MDBPageNav>
              </MDBPageItem>
              <MDBPageItem onClick={lastPaginationCus}>
                <MDBPageNav className="page-link">Last</MDBPageNav>
              </MDBPageItem>
            </MDBPagination>
          </MDBCol>
        </MDBRow>
      </div>

      <MDBRow className="mt-5 mb-2" end>
        <MDBCol sm="4" className="col-12">
          <p className="quickSand h5 font-weight-bold">Other Evaluations</p>
        </MDBCol>
        <MDBCol sm="4" className="col-12">
          <MDBIcon
            style={{
              cursor: "pointer"
            }}
            onClick={add}
            size="1x"
            className="green-text"
            icon="plus"
          />{" "}
        </MDBCol>
      </MDBRow>
      <MDBRow className={formData.add === true ? "middleVertical" : "d-none"}>
        <MDBCol sm="3" className="col-6">
          <MDBInput
            name="title"
            value={formData.title}
            onChange={onChange}
            label="Title"
          />
        </MDBCol>
        <MDBCol sm="3" className="col-6">
          <MDBInput
            name="grade"
            value={formData.grade}
            onChange={onChange}
            label="Grade"
          />
        </MDBCol>
        <MDBCol sm="3" className="col-6">
          <DateTimePicker
            value={moment(formData.date, "x").toDate()}
            onChange={getPickerValue}
            format={"YYYY MM DD"}
            time={false}
          />
        </MDBCol>
        <MDBCol sm="3" className="col-6">
          <MDBBtn
            onClick={addEvaluation}
            color="green"
            disabled={loadingButtonEvaluations}
          >
            {loadingButtonEvaluations === true && (
              <div className="spinner-grow spinner-grow-sm" />
            )}
            Add
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow
        className={formData.update === true ? "middleVertical" : "d-none"}
      >
        <MDBCol sm="3" className="col-6">
          <MDBInput
            name="title"
            value={formData.title}
            onChange={onChange}
            label="Title"
          />
        </MDBCol>
        <MDBCol sm="3" className="col-6">
          <MDBInput
            name="grade"
            value={formData.grade}
            onChange={onChange}
            label="Grade"
          />
        </MDBCol>
        <MDBCol sm="3" className="col-6">
          <DateTimePicker
            value={moment(formData.date, "x").toDate()}
            onChange={getPickerValue}
            format={"YYYY MM DD"}
            time={false}
          />
        </MDBCol>
        <MDBCol sm="3" className="col-6">
          <MDBBtn
            onClick={updateEvaluationFunc}
            color="green"
            disabled={loadingButtonEvaluations}
          >
            {loadingButtonEvaluations === true && (
              <div className="spinner-grow spinner-grow-sm" />
            )}
            Update
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      <div style={{ minHeight: "29rem" }} className="table-responsive">
        <MDBTable
          className="quickSand"
          fixed="true"
          bordered
          hover
          small
          responsive
          size="sm"
        >
          <MDBTableHead minimal="sm">
            <tr>
              <th style={{ width: "40%" }}>Title</th>
              <th style={{ width: "15%" }}>Grade</th>
              <th style={{ width: "30%" }}>Date</th>
              <th style={{ width: "15%" }}></th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {formData.evaluations.map((evaluation, idx) => {
              return (
                <Fragment key={idx}>
                  <tr
                    onClick={() => showUpdate(evaluation)}
                    style={{
                      cursor: "pointer"
                    }}
                  >
                    <td>
                      {connect
                        ? evaluation.array[0].examName
                        : // <Link
                          //   to={`/exams/${evaluation.array[0].examId}`}
                          //   className=""
                          // >
                          evaluation.title
                      // </Link>
                      }
                    </td>
                    <td>{evaluation.grade} %</td>
                    <td>{moment(evaluation.date, "x").format("YYYY MM DD")}</td>
                    <td>
                      <MDBIcon
                        icon="times"
                        onClick={() => deleteEvaluation(evaluation._id)}
                        size="sm"
                        className="dangerIcon"
                      />
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </MDBTableBody>
        </MDBTable>
      </div>

      {/* Pagination Evaluation List Medium Size*/}

      <div
        className={
          formData.evaluations.length !== 0
            ? "middleVertical mt-5 mb-1 paginationGroupsMedium"
            : "d-none"
        }
      >
        <MDBRow>
          <MDBCol>
            <MDBPagination circle>
              <MDBPageItem onClick={firstPaginationEvaluationList}>
                <MDBPageNav className="page-link">
                  <span>First</span>
                </MDBPageNav>
              </MDBPageItem>
              <MDBPageItem onClick={previousPaginationEvaluationList}>
                <MDBPageNav className="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </MDBPageNav>
              </MDBPageItem>
              {[...Array(initialNumberOfPages)].map((item, index) => {
                if (index + 1 === initialPageNumber) {
                  return (
                    <MDBPageItem
                      active={isActive1(index + 1)}
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber - 1) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber - 2) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber - 3) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber + 1) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber + 2) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber + 3) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else {
                  return null;
                }
              })}
              <MDBPageItem onClick={nextPaginationEvaluationList}>
                <MDBPageNav className="page-link">&raquo;</MDBPageNav>
              </MDBPageItem>
              <MDBPageItem onClick={lastPaginationEvaluationList}>
                <MDBPageNav className="page-link">Last</MDBPageNav>
              </MDBPageItem>
            </MDBPagination>
          </MDBCol>
        </MDBRow>
      </div>

      {/* Pagination Evaluation List Small Size*/}

      <div
        className={
          formData.evaluations.length !== 0
            ? "middleVertical mt-5 mb-1 paginationGroupsSmall"
            : "d-none"
        }
      >
        <MDBRow>
          <MDBCol>
            <MDBPagination circle size="sm">
              <MDBPageItem onClick={firstPaginationEvaluationList}>
                <MDBPageNav className="page-link">
                  <span>First</span>
                </MDBPageNav>
              </MDBPageItem>
              <MDBPageItem onClick={previousPaginationEvaluationList}>
                <MDBPageNav className="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </MDBPageNav>
              </MDBPageItem>
              {[...Array(initialNumberOfPages)].map((item, index) => {
                if (index + 1 === initialPageNumber) {
                  return (
                    <MDBPageItem
                      active={isActive1(index + 1)}
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber - 1) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber - 2) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber - 3) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber + 1) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber + 2) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else if (index + 1 === initialPageNumber + 3) {
                  return (
                    <MDBPageItem
                      onClick={() => currentPaginationEvaluationList(index)}
                    >
                      <MDBPageNav className="page-link">
                        {index + 1} <span className="sr-only">(current)</span>
                      </MDBPageNav>
                    </MDBPageItem>
                  );
                } else {
                  return null;
                }
              })}
              <MDBPageItem onClick={nextPaginationEvaluationList}>
                <MDBPageNav className="page-link">&raquo;</MDBPageNav>
              </MDBPageItem>
              <MDBPageItem onClick={lastPaginationEvaluationList}>
                <MDBPageNav className="page-link">Last</MDBPageNav>
              </MDBPageItem>
            </MDBPagination>
          </MDBCol>
        </MDBRow>
      </div>
    </Fragment>
  );
};

StudentExamEvaluations.propTypes = {};
const mapStateToProps = state => ({
  students: state.students,
  customizables: state.customizables,
  evaluations: state.evaluations
});

export default connect(mapStateToProps, {
  getEvals,
  getEvaluationList,
  makeEvaluation,
  deleteEvaluation,
  updateEvaluation,
  setLoadingButtonEvaluations
})(withRouter(StudentExamEvaluations));
