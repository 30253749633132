import axios from "axios";
import { setAlert } from "./alert";

import {
  CANVAS_ERROR,
  MAKE_CANVAS,
  GET_CANVASLIST,
  UPDATE_CANVAS,
  DELETE_CANVAS,
  CLEAR_CANVAS,
  SEARCH_CANVAS,
  CLEAR_CANVAS_SEARCH,
  SET_PAGINATION_MODE_CANVAS,
  SET_LOADING_BUTTON_CANVAS,
  SET_LOADING_DELETE_CANVAS
} from "./types";

// Save Canvas

export const makeCanvas = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/canvas", formData, config);

    dispatch({
      type: MAKE_CANVAS,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CANVAS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all canvas
export const getCanvasList = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/canvas/get`, formData, config);

    dispatch({
      type: GET_CANVASLIST,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CANVAS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update deck
export const updateCanvas = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/canvas/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_CANVAS,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CANVAS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// delete canvas

export const deleteCanvas = id => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.delete(`/api/canvas/${id}`, config);

    dispatch({
      type: DELETE_CANVAS,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CANVAS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear Canvas

export const clearCanvasReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_CANVAS
  });
};

// Post Search Results Canvas

export const searchCanvasFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/canvas/search", formData, config);

    dispatch({
      type: SEARCH_CANVAS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CANVAS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Canvas

export const setPaginationModeCanvas = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_CANVAS,
    payload: formData
  });
};

// Set Loading Button Canvas

export const setLoadingButtonCanvas = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_CANVAS,
    payload: formData
  });
};

// Clear Canvas Search Results

export const clearCanvasSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_CANVAS_SEARCH
  });
};

export const setLoadingDeleteCanvas = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_DELETE_CANVAS,
    payload: formData
  });
};