import axios from "axios";
import { setAlert } from "./alert";
import ReactGa from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { useTranslation } from "react-i18next";

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ACTIVATE_USER,
  RESEND_TOKEN,
  PASSWORD_UPDATE,
  RESET_PASSWORD,
  MESSAGE_ERROR,
  UPDATE_USER,
  DELETE_USER,
  SEND_CONTACT,
  CREATE_CODES,
  SET_LOADING_BUTTON_REGISTRATION,
  NOTIFICATION_SEEN,
  WELCOME_MODAL_CLOSE,
  SET_LOADING_UPLOAD_AVATAR,
  SET_LOADING_DELETE_ACCOUNT,
  CREATE_BLOG,
  GET_BLOG,
  GET_BLOGS,
  RESET_BLOG_LOADER
} from "./types";
import setAuthToken from "../utils/setAuthToken";

// Load User

export const loadUser = () => async dispatch => {
  if (localStorage.topscholarusertoken) {
    setAuthToken(localStorage.topscholarusertoken);
  }

  try {
    const res = await axios.get("/api/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Register User
export const register = (
  { name, email, password, country, affiliate },
  history
) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ name, email, password, country, affiliate });

  try {
    const res = await axios.post("/api/users-tutors", body, config);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });

    // ReactGa.initialize("UA-155878366-1");
    ReactGa.event({
      category: "Registration",
      action: "create account"
    });

    ReactPixel.init("472592427013249");
    ReactPixel.trackCustom("track", "CompleteRegistration");

    // dispatch(loadUser());
    if (history) {
      history.push(`/activationwelcome`);
    }
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

// Login User
export const login = (email, password, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/auth", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());

    if (history) {
      history.push(`/dashboard`);
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Logout / Clear Profile
export const logout = () => async dispatch => {
  await dispatch({ type: LOGOUT });
  dispatch(setAlert("You have been successfully logged out", "success"));
};

// Activate account

export const activate = (id, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/users-tutors/activate/${id}`, config);

    dispatch({
      type: ACTIVATE_USER,
      payload: res.data
    });

    await dispatch({ type: LOGOUT });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//  Extend trial

export const extend = (id, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/users-tutors/extend/${id}`, config);

    if (history) {
      history.push("/login");
    }

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    if (history) {
      history.push("/login");
    }

    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Resend Token

export const resendToken = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/users-tutors/resend", formData, config);

    dispatch({
      type: RESEND_TOKEN,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Reset password

export const reset = (formData, id, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/users-tutors/reset/${id}`,
      formData,
      config
    );

    dispatch({
      type: PASSWORD_UPDATE,
      payload: res.data
    });

    if (history) {
      history.push(`/login`);
    }

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Reset password Token

export const resetTokenAction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/users-tutors/reset/", formData, config);

    dispatch({
      type: RESET_PASSWORD,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update user

export const updateUser = (id, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/users-tutors/update/${id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_USER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete user

export const deleteUser = () => async dispatch => {
  try {
    const res = await axios.delete(`/api/users-tutors/`);

    dispatch({
      type: DELETE_USER,
      payload: res.data
    });
    dispatch({ type: LOGOUT });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Not part of Auth. Contact us form

export const sendContact = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/users-tutors/contact`, formData, config);

    dispatch({
      type: SEND_CONTACT,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Not part of Auth. Contact us form

export const createCodes = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/users-tutors/codes/create`,
      formData,
      config
    );

    dispatch({
      type: CREATE_CODES,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const createBlog = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/users-tutors/blog/create`,
      formData,
      config
    );

    dispatch({
      type: CREATE_BLOG,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const getBlogs = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/users-tutors/blog`, formData, config);

    dispatch({
      type: GET_BLOGS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const getBlog = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.get(
      `/api/users-tutors/blog/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: GET_BLOG,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Not part of Auth. Contact us form

export const notificationSeen = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/subscription//notification/seen`,
      formData,
      config
    );

    dispatch({
      type: NOTIFICATION_SEEN,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Loading Button Registration

export const setLoadingButtonRegistration = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_REGISTRATION,
    payload: formData
  });
};

// Update user

export const closeWelcomeModalFunc = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/users-tutors/closewelcomemodal/`,
      formData,
      config
    );

    dispatch({
      type: WELCOME_MODAL_CLOSE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const setLoadingUploadAvatar = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_UPLOAD_AVATAR,
    payload: formData
  });
};
export const setLoadingDeleteAccount = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_DELETE_ACCOUNT,
    payload: formData
  });
};
export const resetBlogLoader = formData => async dispatch => {
  dispatch({
    type: RESET_BLOG_LOADER,
    payload: formData
  });
};
