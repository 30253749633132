import { subscriptionResponse } from "./actions/subscription";
import Axios from "axios";

const publicVapidKey =
  "BOtzXbXyIj2-6CmzZTZRsXLkAYLXGtyqz9pMgKObvzpmJdiLChybMBR_vsi5Ntz_C4wfFumOquQt__dJGjyni3w";

export const send = async () => {
  // Register Service Worker2
  console.log("Registering service worker...");
  const register = await navigator.serviceWorker.register("/sw.js", {
    scope: "/"
  });
  console.log("Service Worker Registered....");
  navigator.serviceWorker.ready
    // Check to see if we have a subscription
    .then(function(swreg) {
      return swreg.pushManager.getSubscription();
    })
    .then(async function(sub) {
      if (sub === null) {
        // Create a new subscription
        console.log("Registering Subscription...");
        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
        });
        console.log("Subscription Registered...");

        // Send Push Notification
        console.log("Sending Subscription...");

        // await sendNotification(body);
        await Axios.post("/api/subscription", {
          body: subscription,
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(function(response) {
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });
        console.log("Subscription Sent...");
      } else {
        // We have a subscription
      }
    });
};

export const unsubscribeUser = async () => {
  const register = await navigator.serviceWorker.register("/sw.js");
  navigator.serviceWorker.ready
    // Check to see if we have a subscription
    .then(function(swreg) {
      return swreg.pushManager.getSubscription();
    })
    .then(async function(subscription) {
      if (subscription) {
        const subscriptionData = {
          endpoint: subscription.endpoint
        };

        await Axios.post("/api/subscription/unsubscribe", {
          headers: {
            "Content-Type": "application/json"
          },
          body: subscriptionData
        })
          .then(function(response) {
            console.log(response);
          })

          .catch(function(error) {
            console.error("error fetching subscribe", error);
          });
        return subscription.unsubscribe();
      }
    });
};

// Convert the URL safe base64 string to a Uint8Array
function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default { send, unsubscribeUser };
