import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSeenStudent } from "../../actions/students";
import CalendarSection from "./connect/CalendarSection";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon
} from "mdbreact";

const Dashboard = ({ students: { user, seenStudent }, getSeenStudent }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student Dashboard</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className=" justify-content-center align-items-center mt-5 mb-5 ">
        <MDBRow className="pt-5 dashboardMarginTop">
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/connect/lessons">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="clone fas" className="primary-color" />
                  <div className="data">
                    <p className="fontFlexDashboard2 dashboardFont dashboardFontColor">
                      {t("studentDashboard.1")}
                    </p>
                  </div>
                </div>
                <MDBCardBody cascade>
                  <p className="card-text dashboardInfoFontColor montserratFont">
                    {t("studentDashboard.2")}
                  </p>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>

          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/connect/evaluations">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="chart-bar" className="light-blue lighten-1" />
                  <div className="data">
                    <p className="fontFlexDashboard2 dashboardFont dashboardFontColor">
                      {t("studentDashboard.3")}
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <p className="card-text dashboardInfoFontColor montserratFont">
                    {t("studentDashboard.4")}
                  </p>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>

          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/connect/profile">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="user fas" className="unique-color-dark" />
                  <div className="data">
                    <p className="fontFlexDashboard2 dashboardFont dashboardFontColor">
                      {t("studentDashboard.7")}
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <p className="card-text dashboardInfoFontColor montserratFont">
                    {t("studentDashboard.8")}
                  </p>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/connect/messages">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="comments" className="indigo lighten-1" />
                  <div className="data">
                    <p className="fontFlexDashboard2 dashboardFont dashboardFontColor">
                      {t("studentDashboard.9")}
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        {t("studentDashboard.10")}
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/connect/cards">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="brain" className="pink" />
                  <div className="data">
                    <p className="fontFlexDashboard2 dashboardFont dashboardFontColor">
                      {t("studentDashboard.11")}
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        {t("studentDashboard.12")}
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/connect/whiteboard">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="chalkboard" className=" green darken-1" />
                  <div className="data">
                    <p className="fontFlexDashboard2 dashboardFont dashboardFontColor">
                      {t("studentDashboard.13")}
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        {t("studentDashboard.14")}
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/connect/customizables">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="th" className="cyan" />
                  <div className="data">
                    <p className="fontFlexDashboard2 dashboardFont dashboardFontColor">
                      {t("studentDashboard.15")}
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        {t("studentDashboard.16")}
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
        </MDBRow>
        <CalendarSection />
      </MDBContainer>
    </Fragment>
  );
};

Dashboard.propTypes = {
  students: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  students: state.students
});

export default withRouter(
  connect(mapStateToProps, { getSeenStudent })(Dashboard)
);
