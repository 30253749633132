import React, { Fragment } from "react";
import { MDBSpinner, MDBContainer, MDBRow } from "mdbreact";

export default () => (
  <Fragment>
    <MDBContainer className="mt-5 mb-5">
      <MDBRow className="pt-5 mt-3" center>
        <div className="h-100 row align-items-center">
          <div className="col">
            <MDBSpinner className="spinnerColor" big />
          </div>
        </div>
      </MDBRow>
    </MDBContainer>
  </Fragment>
);
