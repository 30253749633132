import {
  ADD_STUDENT,
  STUDENT_ERROR,
  GET_STUDENTS,
  GET_STUDENTS_SELECT,
  GET_STUDENT,
  UPDATE_STUDENT,
  STUDENTS_DELETE,
  GET_STUDENTEXAMEVALUATIONS,
  GET_STUDENTHOMEWORKEVALUATIONS,
  CLEAR_STUDENT_MESSAGE,
  STUDENT_LOGIN_SUCCESS,
  STUDENT_REGISTER_FAIL,
  STUDENT_AUTH_ERROR,
  STUDENT_LOGIN_FAIL,
  STUDENT_LOGOUT,
  STUDENT_USER_LOADED,
  GET_STUDENT_LESSONS,
  STUDENT_RESET_PASSWORD,
  STUDENT_PASSWORD_UPDATE,
  UPDATE_PREFERENCES,
  TOPSCHOLARCONNECT,
  GET_SEEN,
  GET_SEENSTUDENT,
  MAKE_DECK_STUDENT,
  GET_DECKS_STUDENT,
  UPDATE_DECK_STUDENT,
  UPDATE_CARD_STUDENT,
  DELETE_DECK_STUDENT,
  SEARCH_DECKS_STUDENTS,
  SEARCH_CARDS_STUDENTS,
  SET_PAGINATION_MODE_DECKS_STUDENTS,
  SET_PAGINATION_MODE_CARDS_STUDENTS,
  DELETE_CARD_STUDENT,
  MAKE_CARD_STUDENT,
  UPDATE_DECK_CARDS_STUDENT,
  UPDATE_SPACED_STUDENT,
  GET_CARDS_STUDENT,
  GET_SHARED_DECKS_STUDENT,
  GET_CUSEXAM_STUDENT,
  MAKE_CUSEXAM_EVAL,
  GET_CUSEXAM_EVAL,
  UPDATE_CUSEXAM_EVAL,
  CLEAR_STUDENT,
  SEARCH_STUDENTS,
  CLEAR_STUDENTS_SEARCH,
  SET_PAGINATION_MODE_STUDENTS,
  SET_LOADING_BUTTON_STUDENTS,
  SET_LOADING_BUTTON_DELETE_STUDENT,
  TOPSCHOLARCONNECT_GET_TEACHERS,
  ACTIVATE_PORTAL_2,
  GET_STUDENTS_CHAT,
  LATEST_CHAT_MSG_STUDENT,
  RESET_STUDENT_LOGIN,
  CLEAR_DECKS_SEARCH_STUDENTS,
  CLEAR_CARDS_SEARCH_STUDENTS,
  SET_LOADING_BUTTON_DECKS_STUDENTS,
  SET_LOADING_BUTTON_CARDS_STUDENTS,
  SET_LOADING_DELETE_CARD_STUDENT,
  SET_LOADING_DELETE_DECK_STUDENT,
  LOGOUT
} from "../actions/types";

const initialState = {
  getTeachers: false,
  students: [],
  studentsSelect: [],
  student: null,
  loading: true,
  loadingAuth: true,
  error: {},
  examevaluations: [],
  homeworkevaluations: [],
  message: "",
  token: localStorage.getItem("topscholarstudenttoken"),
  isAuthenticated: null,
  user: {
    _id: "",
    email: "",
    sharedDecks: [],
    view: {
      decks: false,
      lessons: false,
      exams: false,
      whiteboard: false,
      evaluations: false
    }
  },
  lessons: [],
  updatePassword: null,
  resetToken: null,
  preferences: null,
  topscholarconnect: null,
  seen: [],
  seenStudent: "",
  newDeck: "",
  decks: [],
  sharedDecks: [],
  cards: [],
  newCard: "",
  updatedCard: "",
  updatedDeck: "",
  deletedCard: "",
  deletedDeck: "",
  cusExamList: [],
  cusExamEval: "",
  cusExamEvalList: [],
  cusExamEvalUpdate: "",
  updatedDeckCards: "",
  initialNumberOfPagesStudents: 1,
  initialPageNumberStudents: 1,
  searchStudents: [],
  paginationModeStudents: "date",
  keywordsStudents: "",
  numberOfPagesStudents: 1,
  pageNumberStudents: 1,
  loadingButtonStudents: false,
  loadingButtonDeleteStudent: false,
  teacherOptions: {
    teachers: []
  },
  hasStudentProfile: null,
  numberOfPagesLessons: 1,
  pageNumberLessons: 1,
  studentsChat: [],
  latestChatMsg: {
    msg: "",
    date: "",
    seen: ""
  },
  initialPageNumberDecks: 1,
  initialNumberOfPagesDecks: 1,
  numberOfPagesDecks: 1,
  pageNumberDecks: 1,
  initialPageNumberCards: 1,
  initialNumberOfPagesCards: 1,
  numberOfPagesCards: 1,
  pageNumberCards: 1,
  searchDecks: [],
  searchCards: [],
  keywordsCards: "",
  keywords: "",
  paginationModeDecks: "date",
  paginationModeCards: "date",
  loadingButtonCards: false,
  loadingButtonDecks: false,
  loadingStudents: true,
  loadingDeleteCard: false,
  loadingDeleteDeck: false,
  chatLoading: true
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_STUDENT:
      return {
        ...state,
        newDeck: "",
        newCard: "",
        updatedCard: "",
        updatedDeck: "",
        deletedCard: "",
        deletedDeck: "",
        cusExamEval: "",
        updatedDeckCards: ""
      };
    case CLEAR_STUDENTS_SEARCH:
      return {
        ...state,
        searchStudents: "",
        keywordsStudents: "",
        numberOfPagesStudents: "",
        pageNumberStudents: "",
        paginationModeStudents: "date"
      };
    case CLEAR_DECKS_SEARCH_STUDENTS:
      return {
        ...state,
        searchDecks: "",
        keywords: "",
        numberOfPages: "",
        pageNumber: "",
        paginationModeDecks: "date"
      };
    case CLEAR_CARDS_SEARCH_STUDENTS:
      return {
        ...state,
        searchCards: "",
        keywordsCards: "",
        numberOfPagesCards: "",
        pageNumberCards: "",
        paginationModeCards: "date"
      };
    case ADD_STUDENT:
      return {
        ...state,
        student: payload.student,
        loadingButtonStudents: false
      };
    case CLEAR_STUDENT_MESSAGE:
      return {
        ...state,
        message: ""
      };

    case STUDENT_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonStudents: false,
        loadingButtonDecks: false,
        loadingButtonCards: false,
        loadingButtonDeleteStudent: false,
        loadingDeleteCard: false,
        loadingDeleteDeck: false
      };
    case GET_STUDENTS:
      return {
        ...state,
        students: payload.students,
        initialNumberOfPagesStudents: payload.initialNumberOfPagesStudents,
        initialPageNumberStudents: payload.initialPageNumberStudents,
        loading: false,
        loadingStudents: false
      };
    case GET_STUDENTS_SELECT:
      return {
        ...state,
        studentsSelect: payload,
        loading: false
      };

    case UPDATE_STUDENT:
      return {
        ...state,
        message: "Update Successful",
        student: payload.student,
        loadingButtonStudents: false
      };
    case GET_STUDENT:
      return {
        ...state,
        student: payload,
        loading: false
      };
    case STUDENTS_DELETE:
      return {
        ...state,
        students: state.students.filter(students => students._id !== payload),
        loading: false,
        loadingButtonDeleteStudent: false
      };
    case GET_STUDENTEXAMEVALUATIONS:
      return {
        ...state,
        examevaluations: payload,
        loading: false
      };
    case GET_STUDENTHOMEWORKEVALUATIONS:
      return {
        ...state,
        homeworkevaluations: payload,
        loading: false
      };
    case STUDENT_LOGIN_SUCCESS:
      localStorage.removeItem("topscholarusertoken");
      localStorage.setItem("topscholarstudenttoken", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loadingAuth: false
      };
    case STUDENT_REGISTER_FAIL:
    case STUDENT_AUTH_ERROR:
    case STUDENT_LOGIN_FAIL:
      localStorage.removeItem("topscholarstudenttoken");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loadingAuth: false,
        user: {
          _id: "",
          email: ""
        }
      };
    case STUDENT_LOGOUT:
      localStorage.removeItem("topscholarstudenttoken");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loadingAuth: false,
        user: {
          _id: "",
          email: ""
        },
        teacherOptions: {
          teachers: []
        },
        getTeachers: false
      };
    case STUDENT_USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loadingAuth: false,
        user: payload,
        latestChatMsg: payload.latestChatMsg
          ? payload.latestChatMsg
          : {
              msg: "",
              date: "",
              seen: "",
              studentId: ""
            }
      };
    case GET_STUDENT_LESSONS:
      return {
        ...state,
        lessons: payload.lessons,
        numberOfPagesLessons: payload.numberOfPagesLessons,
        pageNumberLessons: payload.pageNumberLessons,
        loading: false
      };

    case STUDENT_PASSWORD_UPDATE:
      return {
        ...state,
        updatePassword: payload,
        loading: false
      };
    case STUDENT_RESET_PASSWORD:
      return {
        ...state,
        resetToken: payload,
        loading: false
      };
    case UPDATE_PREFERENCES:
      return {
        ...state,
        preferences: payload
      };
    case TOPSCHOLARCONNECT:
      return {
        ...state,
        topscholarconnect: payload
      };
    case GET_SEEN:
      return {
        ...state,
        seen: payload
      };
    case GET_SEENSTUDENT:
      return {
        ...state,
        seenStudent: payload
      };
    case MAKE_DECK_STUDENT:
      return {
        ...state,
        newDeck: payload,
        decks: [...state.decks, payload],
        loadingButtonDecks: false
      };
    case MAKE_CARD_STUDENT:
      return {
        ...state,
        cards: [...state.cards, payload],
        newCard: payload,
        loadingButtonCards: false
      };
    case GET_DECKS_STUDENT:
      return {
        ...state,
        decks: payload.decks,
        initialNumberOfPagesDecks: payload.initialNumberOfPagesDecks,
        initialPageNumberDecks: payload.initialPageNumberDecks,
        loading: false
      };
    case GET_SHARED_DECKS_STUDENT:
      return {
        ...state,

        sharedDecks: payload
      };
    case GET_CARDS_STUDENT:
      return {
        ...state,
        cards: payload.cards,
        initialNumberOfPagesCards: payload.initialNumberOfPagesCards,
        initialPageNumberCards: payload.initialPageNumberCards,
        loading: false
      };
    case UPDATE_DECK_STUDENT:
      const newArray = state.decks.map((item, index) => {
        if (item._id === payload.deck._id) {
          return payload.deck;
        } else {
          return item;
        }
      });
      return {
        ...state,
        decks: newArray,
        updatedDeck: payload.deck,
        loadingButtonDecks: false
      };
    case UPDATE_CARD_STUDENT:
      const newArray6 = state.cards.map((item, index) => {
        if (item._id === payload.cards._id) {
          return payload.cards;
        } else {
          return item;
        }
      });
      const newDeckArray = state.decks.map((item, index) => {
        const cards = item.cards.map((item2, index2) => {
          if (item2._id === payload.cards._id) {
            return payload.cards;
          } else {
            return item2;
          }
        });
        return {
          ...item,
          cards: cards
        };
      });
      return {
        ...state,
        cards: newArray6,
        decks: newDeckArray,
        updatedCard: payload.cards,
        loadingButtonCards: false
      };

    case UPDATE_SPACED_STUDENT:
      const newArray4 = state.decks.map((item, index) => {
        if (item._id === payload.deck._id) {
          return payload.deck;
        } else {
          return item;
        }
      });
      return {
        ...state,
        decks: newArray4
      };

    case UPDATE_DECK_CARDS_STUDENT:
      const newArray3 = state.decks.map((item, index) => {
        if (item._id === payload.deck._id) {
          return payload.deck;
        } else {
          return item;
        }
      });
      return {
        ...state,
        decks: newArray3,
        updatedDeckCards: payload.deck
      };
    case DELETE_DECK_STUDENT:
      return {
        ...state,

        decks: state.decks.filter(deck => deck._id !== payload._id),
        updatedCard: "",
        deletedCard: "",
        deletedDeck: payload._id,
        loading: false,
        loadingDeleteDeck: false
      };
    case SEARCH_DECKS_STUDENTS:
      return {
        ...state,
        searchDecks: payload.searchDecks,
        keywords: payload.keywords,
        pageNumber: payload.pageNumberDecks,
        numberOfPages: payload.numberOfPagesDecks,
        loading: false
      };
    case SEARCH_CARDS_STUDENTS:
      return {
        ...state,
        searchCards: payload.searchCards,
        keywordsCards: payload.keywordsCards,
        pageNumberCards: payload.pageNumberCards,
        numberOfPagesCards: payload.numberOfPagesCards,
        loading: false
      };
    case SET_PAGINATION_MODE_DECKS_STUDENTS:
      return {
        ...state,
        paginationModeDecks: payload
      };
    case SET_PAGINATION_MODE_CARDS_STUDENTS:
      return {
        ...state,
        paginationModeCards: payload
      };
    case SET_LOADING_BUTTON_DECKS_STUDENTS:
      return {
        ...state,
        loadingButtonDecks: payload
      };
    case SET_LOADING_BUTTON_CARDS_STUDENTS:
      return {
        ...state,
        loadingButtonCards: payload
      };
    case DELETE_CARD_STUDENT:
      const newDeckArray2 = state.decks.map((item, index) => {
        const cards = item.cards.filter((item2, index2) => {
          return item2._id !== payload._id;
        });
        return {
          ...item,
          cards: cards
        };
      });
      return {
        ...state,

        cards: state.cards.filter(card => card._id !== payload._id),
        updatedCard: "",
        deletedCard: payload._id,
        loading: false,
        decks: newDeckArray2,
        loadingDeleteCard: false
      };
    case GET_CUSEXAM_STUDENT:
      return {
        ...state,

        cusExamList: payload
      };
    case MAKE_CUSEXAM_EVAL:
      return {
        ...state,
        cusExamEval: payload.cusEval
        // cusExamEvalList: [...state.cusExamEvalList, payload.cusEval]
      };
    case GET_CUSEXAM_EVAL:
      return {
        ...state,
        cusExamEvalList: payload
      };
    case UPDATE_CUSEXAM_EVAL:
      return {
        ...state,
        cusExamEvalUpdate: payload.exam
      };
    case SEARCH_STUDENTS:
      return {
        ...state,
        searchStudents: payload.searchStudents,
        keywordsStudents: payload.keywordsStudents,
        pageNumberStudents: payload.pageNumberStudents,
        numberOfPagesStudents: payload.numberOfPagesStudents,
        loading: false
      };
    case SET_PAGINATION_MODE_STUDENTS:
      return {
        ...state,
        paginationModeStudents: payload
      };
    case SET_LOADING_BUTTON_STUDENTS:
      return {
        ...state,
        loadingButtonStudents: payload
      };
    case SET_LOADING_BUTTON_DELETE_STUDENT:
      return {
        ...state,
        loadingButtonDeleteStudent: payload
      };
    case TOPSCHOLARCONNECT_GET_TEACHERS:
      return {
        ...state,
        teacherOptions: payload.teacherOptions,
        getTeachers: true
      };
    case ACTIVATE_PORTAL_2:
      return {
        ...state,
        hasStudentProfile: payload.hasStudentProfile
      };
    case GET_STUDENTS_CHAT:
      return {
        ...state,
        studentsChat: payload,
        chatLoading: false
      };
    case LATEST_CHAT_MSG_STUDENT:
      return {
        ...state,
        latestChatMsg: payload
      };
    case RESET_STUDENT_LOGIN:
      return {
        ...state,
        teacherOptions: {
          teachers: []
        },
        getTeachers: false
      };
    case SET_LOADING_DELETE_CARD_STUDENT:
      return {
        ...state,
        loadingDeleteCard: payload
      };
    case SET_LOADING_DELETE_DECK_STUDENT:
      return {
        ...state,
        loadingDeleteDeck: payload
      };
    case LOGOUT:
      return {
        getTeachers: false,
        students: [],
        studentsSelect: [],
        student: null,
        loading: true,
        loadingAuth: true,
        error: {},
        examevaluations: [],
        homeworkevaluations: [],
        message: "",
        token: localStorage.getItem("topscholarstudenttoken"),
        isAuthenticated: null,
        user: {
          _id: "",
          email: "",
          sharedDecks: [],
          view: {
            decks: false,
            lessons: false,
            exams: false,
            whiteboard: false,
            evaluations: false
          }
        },
        lessons: [],
        updatePassword: null,
        resetToken: null,
        preferences: null,
        topscholarconnect: null,
        seen: [],
        seenStudent: "",
        newDeck: "",
        decks: [],
        sharedDecks: [],
        cards: [],
        newCard: "",
        updatedCard: "",
        updatedDeck: "",
        deletedCard: "",
        deletedDeck: "",
        cusExamList: [],
        cusExamEval: "",
        cusExamEvalList: [],
        cusExamEvalUpdate: "",
        updatedDeckCards: "",
        initialNumberOfPagesStudents: 1,
        initialPageNumberStudents: 1,
        searchStudents: [],
        paginationModeStudents: "date",
        keywordsStudents: "",
        numberOfPagesStudents: 1,
        pageNumberStudents: 1,
        loadingButtonStudents: false,
        loadingButtonDeleteStudent: false,
        teacherOptions: {
          teachers: []
        },
        hasStudentProfile: null,
        numberOfPagesLessons: 1,
        pageNumberLessons: 1,
        studentsChat: [],
        latestChatMsg: {
          msg: "",
          date: "",
          seen: ""
        },
        initialPageNumberDecks: 1,
        initialNumberOfPagesDecks: 1,
        numberOfPagesDecks: 1,
        pageNumberDecks: 1,
        initialPageNumberCards: 1,
        initialNumberOfPagesCards: 1,
        numberOfPagesCards: 1,
        pageNumberCards: 1,
        searchDecks: [],
        searchCards: [],
        keywordsCards: "",
        keywords: "",
        paginationModeDecks: "date",
        paginationModeCards: "date",
        loadingButtonCards: false,
        loadingButtonDecks: false,
        loadingStudents: true,
        loadingDeleteCard: false,
        loadingDeleteDeck: false,
        chatLoading: true
      };
    default:
      return state;
  }
}
