import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./customizables.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import { useSpring, animated } from "react-spring";
import Countdown from "react-countdown-now";
import uuid from "uuid/v4";
import CorrectedExams from "./CorrectedExams";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBCard,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
  MDBScrollbar,
  MDBCardHeader,
  MDBCardBody
} from "mdbreact";

import {
  getCusExams,
  createEval,
  getEvalList,
  updateEval,
  clearStudentReducer
} from "../../../actions/students";

const Customizables = ({
  students: { user },

  students: { cusExamList, cusExamEvalList, cusExamEval, cusExamEvalUpdate },
  clearStudentReducer,
  getCusExams,
  createEval,
  getEvalList,
  updateEval
}) => {
  const [formData, setFormData] = useState({
    examList: [],
    filteredExamList: [],
    evalList: [],
    filteredEvalList: [],
    currentExam: {
      name: "",
      description: "",
      duration: "",
      students: [],
      components: [],
      date: "",
      image: "",
      englishLevel: [],
      examId: "",
      author: ""
    },
    currentComponent: {
      type: ""
    },

    modalPlayExam: false,
    buttonHide: false,
    endOfComponentsArray: false,
    matching: {
      matchingbArray: [],
      matching1: [],
      matching2: [],
      matching3: [],
      matching4: [],
      matching5: [],
      matching6: []
    },
    endExam: false,
    modalSeeEval: false
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (user._id) {
      getEvalList();
      getCusExams({ studentId: user._id });
    }
  }, [user, getCusExams, getEvalList]);

  useEffect(() => {
    if (cusExamEval) {
      setFormData(prevState => {
        return {
          ...prevState,
          currentExam: cusExamEval,
          evalList: [...prevState.evalList, cusExamEval],
          filteredEvalList: [...prevState.filteredEvalList, cusExamEval]
        };
      });
      clearStudentReducer();
    }
  }, [cusExamEval, clearStudentReducer]);
  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        evalList: cusExamEvalList,
        filteredEvalList: cusExamEvalList
      };
    });
  }, [cusExamEvalList]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        examList: cusExamEvalList,
        filteredEvalList: cusExamEvalList
      };
    });
  }, [cusExamList, cusExamEvalList]);

  useEffect(() => {
    const newArray = cusExamList.map((item, index) => {
      const filter = formData.evalList.filter(
        item2 => item2.examId === item._id
      );
      if (filter.length !== 0) {
        return "remove";
      } else {
        return item;
      }
    });

    const filter2 = newArray.filter(item => item !== "remove");

    setFormData(prevState => {
      return {
        ...prevState,
        examList: filter2,
        filteredExamList: filter2
      };
    });
  }, [cusExamList, formData.evalList]);

  useEffect(() => {
    if (cusExamEvalUpdate) {
      setFormData(prevState => {
        const newArray = prevState.evalList.map((item, index) => {
          if (item._id === cusExamEvalUpdate._id) {
            return cusExamEvalUpdate;
          } else {
            return item;
          }
        });

        const filter2 = cusExamList.map((item, index) => {
          const filter = newArray.filter(item2 => item2.examId !== item._id);

          return filter;
        });

        const endExamBoolean =
          cusExamEvalUpdate.status === "Started/Ongoing" ? false : true;

        return {
          ...prevState,
          evalList: newArray,
          filteredEvalList: newArray,
          examList: filter2,
          filteredExamList: filter2,
          modalPlayExam: false,
          endExam: endExamBoolean,
          buttonHide: false,
          currentExam: {
            name: "",
            description: "",
            duration: "",
            students: [],
            components: [],
            date: "",
            image: "",
            englishLevel: [],
            examId: "",
            author: ""
          },
          matching: {
            matchingbArray: [],
            matching1: [],
            matching2: [],
            matching3: [],
            matching4: [],
            matching5: [],
            matching6: []
          }
        };
      });
    }
  }, [cusExamEvalUpdate, cusExamList]);

  const openPlayExam = exam => {
    if (exam.status === "Started/Ongoing") {
      setFormData({
        ...formData,
        modalPlayExam: true,
        endExam: false,
        currentExam: {
          name: exam.name,
          description: exam.description,
          duration: exam.duration,
          students: exam.students,
          components: exam.components,
          date: exam.date,
          image: exam.image,
          englishLevel: exam.englishLevel,
          examId: exam.examId,
          author: exam.author,
          _id: exam._id,
          dateExamStarted: exam.dateExamStarted
        },
        buttonHide: true,
        currentComponent: exam.components[0]
      });
    } else {
      setFormData({
        ...formData,
        modalPlayExam: true,
        currentExam: {
          name: exam.name,
          description: exam.description,
          duration: exam.duration,
          students: exam.students,
          components: exam.components,
          date: exam.date,
          image: exam.image,
          englishLevel: exam.englishLevel,
          examId: exam._id,
          author: exam.author
        }
      });
    }
  };
  const closePlayExam = () => {
    updateEvalFunc();
    // setFormData({
    //   ...formData,
    //   modalPlayExam: false,
    //   buttonHide: false
    // });
  };

  const radioPickPlay = (nr, id) => {
    const newArray = formData.currentExam.components.map((item, index) => {
      if (item._id === id) {
        return {
          ...item,
          studentAnswer: nr
        };
      } else {
        return item;
      }
    });
    setFormData({
      ...formData,
      currentExam: {
        ...formData.currentExam,
        components: newArray
      }
    });
  };

  const multipleChoiceCheck = (id, nr) => {
    const find = formData.currentExam.components.find(item => item._id === id);
    if (find) {
      return find.studentAnswer === nr ? true : false;
    } else {
      return false;
    }
  };

  const checkPicksPlay = (nr, id) => {
    const newArray = formData.currentExam.components.map((item, index) => {
      if (id === item._id) {
        const filter = item.studentAnswer.filter(item2 => item2 === nr);
        if (filter.length !== 0) {
          const filter2 = item.studentAnswer.filter(item2 => item2 !== nr);
          return {
            ...item,
            studentAnswer: filter2
          };
        } else {
          return {
            ...item,
            studentAnswer: [...item.studentAnswer, nr]
          };
        }
      } else {
        return item;
      }
    });
    setFormData({
      ...formData,
      currentExam: {
        ...formData.currentExam,
        components: newArray
      }
    });
  };

  const onChangeComponentPlay = (id, e) => {
    const newArray = formData.currentExam.components.map((item, index) => {
      if (id === item._id) {
        return {
          ...item,
          [e.target.name]: e.target.value
        };
      } else {
        return item;
      }
    });
    setFormData({
      ...formData,
      currentExam: {
        ...formData.currentExam,
        components: newArray
      }
    });
  };

  const textAreaValuePlay = id => {
    const find = formData.currentExam.components.find(item => item._id === id);
    if (find) {
      return find.studentAnswer;
    }
  };

  // checkboxes

  const checkboxAnswerPlay = (nr, id) => {
    const find = formData.currentExam.components.find(item => item._id === id);
    if (find) {
      const filter = find.studentAnswer.filter(item => item === nr);
      if (filter.length !== 0) {
        return true;
      } else return false;
    }
  };

  const onDragEnd2 = result => {
    if (!result.destination) {
    } else if (result.destination.droppableId === result.source.droppableId) {
    } else {
      const find = formData.currentComponent.studentAnswer[
        result.source.droppableId
      ].find(item => item._id === result.draggableId);

      const filter = formData.currentComponent.studentAnswer[
        result.source.droppableId
      ].filter(item => item._id !== result.draggableId);

      setFormData({
        ...formData,
        currentComponent: {
          ...formData.currentComponent,
          studentAnswer: {
            ...formData.currentComponent.studentAnswer,
            [result.destination.droppableId]: [
              ...formData.currentComponent.studentAnswer[
                result.destination.droppableId
              ],
              find
            ],
            [result.source.droppableId]: filter
          }
        }
      });
    }
  };

  // Play Exam Mode

  const props = useSpring({
    opacity: formData.modalPlayExam ? (formData.buttonHide ? 0 : 1) : 0,
    delay: 100,
    config: { duration: 1000 }
  });
  const props2 = useSpring({
    opacity: formData.buttonHide === false ? 0 : 1,
    marginTop: formData.buttonHide === false ? -200 : 0,

    config: { duration: 1000 }
  });
  const props3 = useSpring({
    opacity: formData.buttonHide === false ? 0 : 1,
    config: { duration: 1000 },
    delay: 100
  });

  const startExam = () => {
    createEvalFunc();
    setFormData({
      ...formData,
      buttonHide: true,
      currentComponent: formData.currentExam.components[0]
    });
  };

  const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  useEffect(() => {
    if (formData.currentComponent.type === "matching") {
      if (formData.currentComponent.studentAnswer.firstTime === true) {
        //  aa

        setFormData(prevState => {
          const arrayVar = [
            { value: prevState.currentComponent.match1.match1b, _id: uuid() },
            { value: prevState.currentComponent.match2.match2b, _id: uuid() },
            { value: prevState.currentComponent.match3.match3b, _id: uuid() },
            { value: prevState.currentComponent.match4.match4b, _id: uuid() },
            { value: prevState.currentComponent.match5.match5b, _id: uuid() },
            { value: prevState.currentComponent.match6.match6b, _id: uuid() }
          ];

          shuffleArray(arrayVar);
          return {
            ...prevState,
            matching: {
              ...prevState.matching,
              _id: prevState.currentComponent._id,
              matchingbArray: arrayVar,
              matching1: [
                {
                  value: prevState.currentComponent.match1.match1a,
                  _id: uuid()
                }
              ],
              matching2: [
                {
                  value: prevState.currentComponent.match2.match2a,
                  _id: uuid()
                }
              ],
              matching3: [
                {
                  value: prevState.currentComponent.match3.match3a,
                  _id: uuid()
                }
              ],
              matching4: [
                {
                  value: prevState.currentComponent.match4.match4a,
                  _id: uuid()
                }
              ],
              matching5: [
                {
                  value: prevState.currentComponent.match5.match5a,
                  _id: uuid()
                }
              ],
              matching6: [
                {
                  value: prevState.currentComponent.match6.match6a,
                  _id: uuid()
                }
              ],
              firstTime: false
            }
          };
        });
      } else {
        setFormData(prevState => {
          const newArray = prevState.currentExam.components.map(
            (item, index) => {
              if (item._id === prevState.currentComponent._id) {
                return {
                  ...item,
                  studentAnswer: prevState.currentComponent.studentAnswer
                };
              } else {
                return item;
              }
            }
          );

          return {
            ...prevState,
            currentExam: {
              ...prevState.currentExam,
              components: newArray
            }
          };
        });
      }
    }
  }, [formData.currentComponent]);

  const nextQuestion = () => {
    const componentLengh = formData.currentExam.components.length;
    const currentIndex = formData.currentExam.components.findIndex(
      component => component._id === formData.currentComponent._id
    );
    if (currentIndex === componentLengh - 1) {
      setFormData({
        ...formData,
        endOfComponentsArray: true
      });
    } else {
      setFormData({
        ...formData,
        currentComponent: formData.currentExam.components[currentIndex + 1]
      });
    }
  };
  const previousQuestion = () => {
    const currentIndex = formData.currentExam.components.findIndex(
      component => component._id === formData.currentComponent._id
    );

    setFormData({
      ...formData,
      currentComponent: formData.currentExam.components[currentIndex - 1]
    });
  };

  const findBegin = () => {
    const currentIndex = formData.currentExam.components.findIndex(
      component => component._id === formData.currentComponent._id
    );
    if (currentIndex === 0) {
      return "d-none";
    }
  };
  const findEnd = () => {
    const componentLengh = formData.currentExam.components.length;

    const currentIndex = formData.currentExam.components.findIndex(
      component => component._id === formData.currentComponent._id
    );
    if (currentIndex === componentLengh - 1) {
      return "d-none";
    }
  };

  const goToComponent = component => {
    const find = formData.currentExam.components.find(
      item => item._id === component._id
    );

    setFormData({
      ...formData,
      currentComponent: find
    });
  };

  // bb

  useEffect(() => {
    if (formData.matching.matchingbArray.length !== 0) {
      setFormData(prevState => {
        const newArray = prevState.currentExam.components.map((item, index) => {
          if (item._id === prevState.matching._id) {
            return {
              ...item,
              studentAnswer: prevState.matching
            };
          } else {
            return item;
          }
        });

        const find = newArray.find(
          item => item._id === prevState.currentComponent._id
        );
        return {
          ...prevState,
          currentComponent: find,
          currentExam: {
            ...prevState.currentExam,
            components: newArray
          }
        };
      });
    }
  }, [formData.matching]);

  const createEvalFunc = () => {
    createEval({
      name: formData.currentExam.name,
      author: formData.currentExam.author,
      description: formData.currentExam.description,
      userTutor: user.userTutor,
      duration: formData.currentExam.duration,
      components: formData.currentExam.components,
      englishLevel: formData.currentExam.englishLevel,
      date: formData.currentExam.date,
      studentId: user._id,
      examId: formData.currentExam.examId,
      status: "Started/Ongoing",
      dateExamStarted: moment().unix()
    });
  };
  const updateEvalFunc = () => {
    updateEval({
      name: formData.currentExam.name,
      author: formData.currentExam.author,
      description: formData.currentExam.description,
      userTutor: user.userTutor,
      duration: formData.currentExam.duration,
      components: formData.currentExam.components,
      englishLevel: formData.currentExam.englishLevel,
      date: formData.currentExam.date,
      studentId: user._id,
      examId: formData.currentExam.examId,
      status: "Started/Ongoing",
      _id: formData.currentExam._id,
      dateExamStarted: formData.currentExam.dateExamStarted
    });

    // setFormData({
    //   ...formData,
    //   currentExam: {
    //     name: "",
    //     description: "",
    //     duration: "",
    //     students: [],
    //     components: [],
    //     date: "",
    //     image: "",
    //     englishLevel: [],
    //     examId: "",
    //     author: ""
    //   },
    //   matching: {
    //     matchingbArray: [],
    //     matching1: [],
    //     matching2: [],
    //     matching3: [],
    //     matching4: [],
    //     matching5: [],
    //     matching6: []
    //   }
    // });
  };
  const updateEvalFuncTimer = () => {
    updateEval({
      name: formData.currentExam.name,
      author: formData.currentExam.author,
      description: formData.currentExam.description,
      userTutor: user.userTutor,
      duration: formData.currentExam.duration,
      components: formData.currentExam.components,
      englishLevel: formData.currentExam.englishLevel,
      date: formData.currentExam.date,
      studentId: user._id,
      examId: formData.currentExam.examId,
      status: "Started/Ongoing",
      _id: formData.currentExam._id,
      dateExamStarted: formData.currentExam.dateExamStarted
    });

    // setFormData({
    //   ...formData,
    //   currentExam: {
    //     name: "",
    //     description: "",
    //     duration: "",
    //     students: [],
    //     components: [],
    //     date: "",
    //     image: "",
    //     englishLevel: [],
    //     examId: "",
    //     author: ""
    //   },
    //   matching: {
    //     matchingbArray: [],
    //     matching1: [],
    //     matching2: [],
    //     matching3: [],
    //     matching4: [],
    //     matching5: [],
    //     matching6: []
    //   },
    //   endExam: true,
    //   modalPlayExam: false
    // });
  };

  const Completionist = () => {
    return <p>Saved</p>;
  };

  const countDownDate = () => {
    return (
      Date.now() +
      (formData.currentExam.duration * 60 -
        (moment().unix() - formData.currentExam.dateExamStarted)) *
        1000
    );
  };

  const propsSee = props => {
    if (props.completed) {
      updateEvalFuncTimer();
      setFormData({
        ...formData,

        endExam: true
      });

      return <Completionist />;
    } else {
      return (
        <p className="text-white h4 livvicFont">
          {props.formatted.hours}:{props.formatted.minutes}:
          {props.formatted.seconds}
        </p>
      );
    }
  };

  useEffect(() => {
    const messageError = () => {
      return (
        <Fragment>
          <MDBRow>
            <MDBCol>
              <MDBIcon icon="clock" />
              <p>Time has run out. Exam Completed</p>
            </MDBCol>
          </MDBRow>
        </Fragment>
      );
    };
    if (formData.endExam === true) {
      if (cusExamEvalUpdate.duration !== 0) {
        toast.info(messageError);
      }
    }
  }, [formData.endExam]);

  const openSeeEval = item => {
    setFormData({
      ...formData,
      modalSeeEval: true,
      currentExam: item
    });
  };
  const closeSeeEval = () => {
    setFormData({
      ...formData,
      modalSeeEval: false
    });
  };

  // remove double scroll bar when in modal mode
  useEffect(() => {
    if (formData.modalSeeEval === true || formData.modalPlayExam === true) {
      document.body.style.cssText = "overflow-y: hidden !important;";
    } else {
      document.body.style.overflowY = "";
    }
  }, [formData.modalSeeEval, formData.modalPlayExam]);

  // before return
  return user.view && user.view.exams === false ? (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student Online Exam</title>

        <meta name="robots" content="noindex" />
      </Helmet>

      <MDBContainer className=" justify-content-center align-items-center mt-5 mb-5">
        <MDBRow className="pt-5 mt-3" center>
          <MDBCard narrow>
            <MDBCardHeader className="view view-cascade  primary-color d-flex justify-content-between align-items-center py-2 mx-4 mb-1 ">
              <MDBCol className="text-center">
                <p className="h5 quickSand white-text mx-2">
                  {t("studentDashboard.15")}
                </p>
              </MDBCol>
            </MDBCardHeader>
            <MDBCardBody cascade>
              <p className="quickSand black-text h5">{t("studentLessons.2")}</p>
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  ) : (
    <Fragment>
      <MDBContainer className="mt-5">
        <MDBRow className="mt-3">
          <MDBCol size="12">
            <MDBCard
              className="my-5  mx-auto customizablesScroll"
              style={{ fontWeight: 300, maxWidth: "90%" }}
            >
              {/* <MDBScrollbar> */}
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: "30rem" }}>
                  <MDBCol size="12">
                    <div className="rounded-top cyanBackground lighten-3 text-center py-2">
                      <MDBRow className="pt-3">
                        <MDBCol className="text-center livvicFont" size="6">
                          <p className="text-white">
                            {t("studentCustomizables.1")}
                          </p>
                        </MDBCol>

                        <MDBCol className="text-center livvicFont" size="3">
                          <p className="text-white">
                            {t("studentCustomizables.2")}
                          </p>
                        </MDBCol>
                        <MDBCol className="text-center livvicFont" size="3">
                          <p className="text-white">
                            {t("studentCustomizables.3")}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    {formData.examList.map((item, index) => {
                      return (
                        <MDBRow key={index} className="pt-1">
                          <MDBCol className="text-center" size="6">
                            <MDBRow>
                              <MDBCol className="mr-auto">
                                <p
                                  onClick={() => openPlayExam(item)}
                                  style={{ cursor: "pointer" }}
                                  className="livvicFont fontBold "
                                >
                                  {item.name}
                                </p>
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>

                          <MDBCol
                            className="text-center middleVertical"
                            size="3"
                          >
                            <p className="livvicFont fontBold">
                              {t("studentCustomizables.4")}
                            </p>
                          </MDBCol>
                          <MDBCol
                            className="text-center middleVertical"
                            size="3"
                          >
                            <p className="livvicFont fontBold">
                              {item.duration === 0
                                ? t("studentCustomizables.5")
                                : `${item.duration} ${t(
                                    "studentCustomizables.6"
                                  )}`}
                            </p>
                          </MDBCol>
                          <div className=" divHeight cyanBackground lighten-3 text-center py-1">
                            {" "}
                          </div>
                        </MDBRow>
                      );
                    })}
                    {formData.evalList.map((item, index) => {
                      const status = () => {
                        if (item.status === "evaluated") {
                          return "evaluated";
                        } else if (item.duration === 0) {
                          return "No duration";
                        } else {
                          return (
                            (item.duration * 60 -
                              (moment().unix() - item.dateExamStarted)) *
                            1000
                          );
                        }
                      };

                      const click =
                        status() === "evaluated"
                          ? () => openSeeEval(item)
                          : status() === "No duration"
                          ? () => openPlayExam(item)
                          : status() < 0
                          ? () => null
                          : () => openPlayExam(item);

                      return (
                        <MDBRow key={item._id} className="pt-1">
                          <MDBCol className="text-center" size="6">
                            <MDBRow>
                              <MDBCol className="mr-auto">
                                <p
                                  onClick={click}
                                  style={{ cursor: "pointer" }}
                                  className="livvicFont fontBold "
                                >
                                  {item.name}
                                </p>
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>

                          <MDBCol
                            className="text-center middleVertical"
                            size="3"
                          >
                            <p className="livvicFont fontBold">
                              {status() === "evaluated"
                                ? t("studentCustomizables.7")
                                : status() === "No duration"
                                ? t("studentCustomizables.8")
                                : status() < 0
                                ? t("studentCustomizables.9")
                                : t("studentCustomizables.10")}
                            </p>
                          </MDBCol>
                          <MDBCol
                            className="text-center middleVertical"
                            size="3"
                          >
                            <p className="livvicFont fontBold">
                              {item.duration === 0
                                ? t("studentCustomizables.5")
                                : `${item.duration} ${t(
                                    "studentCustomizables.6"
                                  )}`}
                            </p>
                          </MDBCol>
                          <div className=" divHeight cyanBackground lighten-3 text-center py-1">
                            {" "}
                          </div>
                        </MDBRow>
                      );
                    })}
                  </MDBCol>
                </div>
              </div>
              {/* </MDBScrollbar> */}
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {/* PLAY Exam */}
      <div className="customizablesModal">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalPlayExam}
            toggle
            className="modal-full"
          >
            <div className="modal-full modal-content ">
              <MDBRow className="pb-5 mb-5">
                {/* Play Left Side */}
                <MDBCol md="9">
                  <MDBRow className="mt-5 mx-2 " center>
                    <MDBCol size="12">
                      <animated.div style={props3}>
                        <MDBCard className="z-depth-2 ">
                          {formData.currentComponent.type ===
                          "multipleChoice" ? (
                            <Fragment>
                              <MDBRow className="pb-5 mb-5 mt-4 examPlayHeight ">
                                <MDBCol className="text-left" size="12">
                                  {/* Question */}
                                  <p className="h3-responsive livvicFont fontBold ml-3">
                                    {formData.currentComponent.question}
                                  </p>
                                </MDBCol>

                                {/* Answer */}

                                <MDBCol size="12">
                                  <MDBRow className="mx-1">
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          radioPickPlay(
                                            1,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={multipleChoiceCheck(
                                          formData.currentComponent._id,
                                          1
                                        )}
                                        type="radio"
                                        id={`radio1`}
                                        label={
                                          formData.currentComponent.choice1
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          radioPickPlay(
                                            2,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={multipleChoiceCheck(
                                          formData.currentComponent._id,
                                          2
                                        )}
                                        type="radio"
                                        id={`radio2`}
                                        label={
                                          formData.currentComponent.choice2
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          radioPickPlay(
                                            3,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={multipleChoiceCheck(
                                          formData.currentComponent._id,
                                          3
                                        )}
                                        type="radio"
                                        id={`radio3`}
                                        label={
                                          formData.currentComponent.choice3
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          radioPickPlay(
                                            4,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={multipleChoiceCheck(
                                          formData.currentComponent._id,
                                          4
                                        )}
                                        type="radio"
                                        id={`radio4`}
                                        label={
                                          formData.currentComponent.choice4
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                  </MDBRow>
                                </MDBCol>
                              </MDBRow>
                            </Fragment>
                          ) : formData.currentComponent.type ===
                            "checkAllBoxes" ? (
                            <Fragment>
                              <MDBRow className="pb-5 mb-5 mt-4 examPlayHeight ">
                                <MDBCol className="text-left " size="12">
                                  {/* Question */}
                                  <p className="h3-responsive livvicFont fontBold ml-3">
                                    {formData.currentComponent.question}
                                  </p>
                                </MDBCol>

                                {/* Answer */}

                                <MDBCol size="12">
                                  <MDBRow className="mx-1">
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          checkPicksPlay(
                                            1,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={checkboxAnswerPlay(
                                          1,
                                          formData.currentComponent._id
                                        )}
                                        type="checkbox"
                                        id={`radio1`}
                                        label={
                                          formData.currentComponent.choice1
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          checkPicksPlay(
                                            2,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={checkboxAnswerPlay(
                                          2,
                                          formData.currentComponent._id
                                        )}
                                        type="checkbox"
                                        id={`radio2`}
                                        label={
                                          formData.currentComponent.choice2
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          checkPicksPlay(
                                            3,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={checkboxAnswerPlay(
                                          3,
                                          formData.currentComponent._id
                                        )}
                                        type="checkbox"
                                        id={`radio3`}
                                        label={
                                          formData.currentComponent.choice3
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                    <MDBCol>
                                      <MDBInput
                                        onClick={() =>
                                          checkPicksPlay(
                                            4,
                                            formData.currentComponent._id
                                          )
                                        }
                                        checked={checkboxAnswerPlay(
                                          4,
                                          formData.currentComponent._id
                                        )}
                                        type="checkbox"
                                        id={`radio4`}
                                        label={
                                          formData.currentComponent.choice4
                                        }
                                        labelClass="livvicFont h5-responsive"
                                      />
                                    </MDBCol>
                                  </MDBRow>
                                </MDBCol>
                              </MDBRow>
                            </Fragment>
                          ) : formData.currentComponent.type === "textArea" ? (
                            <Fragment>
                              <MDBRow className="pb-5 mb-5 mt-4 examPlayHeight ">
                                <MDBCol className="text-left " size="12">
                                  {/* Question */}
                                  <p className="h3-responsive livvicFont fontBold ml-3">
                                    {formData.currentComponent.question}
                                  </p>
                                </MDBCol>
                                <MDBCol className="text-center" size="12">
                                  <MDBRow className="mx-3">
                                    <MDBCol>
                                      <MDBInput
                                        style={{ overflowY: "auto" }}
                                        name="studentAnswer"
                                        type="textarea"
                                        value={textAreaValuePlay(
                                          formData.currentComponent._id
                                        )}
                                        label="Answer "
                                        labelClass="livvicFont"
                                        onChange={e =>
                                          onChangeComponentPlay(
                                            formData.currentComponent._id,
                                            e
                                          )
                                        }
                                      />
                                    </MDBCol>
                                  </MDBRow>
                                </MDBCol>
                              </MDBRow>
                            </Fragment>
                          ) : formData.currentComponent.type === "matching" ? (
                            <Fragment>
                              <DragDropContext
                                // onDragStart={onDragStart}
                                onDragEnd={onDragEnd2}
                              >
                                <MDBRow className="pb-5 mb-5 mt-4 examPlayHeight ">
                                  <MDBCol className="text-left " size="12">
                                    {/* Question */}
                                    <p className="h3-responsive livvicFont fontBold ml-3">
                                      {formData.currentComponent.question}
                                    </p>
                                  </MDBCol>
                                  <MDBCol className="text-left" size="12">
                                    <MDBRow className="mx-3">
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching1">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching1.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match1.match1a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching2">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching2.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match2.match2a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching3">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching3.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match3.match3a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching4">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching4.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match4.match4a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching5">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching5.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match5.match5a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                      <MDBCol
                                        className="customizablesMarginBottom"
                                        size="6"
                                      >
                                        <MDBListGroup>
                                          <MDBListGroupItem>
                                            <Droppable droppableId="matching6">
                                              {provided => (
                                                <div
                                                  style={{
                                                    minHeight: "3rem"
                                                  }}
                                                  className="Hero-List"
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {formData.currentComponent.studentAnswer.matching6.map(
                                                    (item, index) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={
                                                            item.value ===
                                                            formData
                                                              .currentComponent
                                                              .match6.match6a
                                                          }
                                                          key={item._id}
                                                          draggableId={item._id}
                                                          index={index}
                                                        >
                                                          {provided => (
                                                            <div
                                                              className="Container"
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              <p className="h5-responsive livvicFont">
                                                                {item.value}{" "}
                                                              </p>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </MDBListGroupItem>
                                        </MDBListGroup>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow center>
                                      <Droppable
                                        isDropDisabled={true}
                                        droppableId="matchingbArray"
                                        direction="vertical"
                                      >
                                        {provided => (
                                          <div
                                            style={{
                                              minHeight: "3rem"
                                            }}
                                            className="Hero-List"
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                          >
                                            {formData.currentComponent.studentAnswer.matchingbArray.map(
                                              (item, index) => {
                                                return (
                                                  <Draggable
                                                    key={item._id}
                                                    draggableId={item._id}
                                                    index={index}
                                                  >
                                                    {provided => (
                                                      <div
                                                        className="Container"
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                      >
                                                        <MDBCol>
                                                          <p className="h5-responsive livvicFont">
                                                            {item.value}{" "}
                                                          </p>
                                                        </MDBCol>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                );
                                              }
                                            )}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </MDBRow>
                                  </MDBCol>
                                </MDBRow>
                              </DragDropContext>
                            </Fragment>
                          ) : null}
                          <div className="rounded-bottom blue lighten-3 text-center py-2">
                            <MDBRow center className="pt-3">
                              <MDBCol className="text-center livvicFont">
                                <MDBBtn
                                  onClick={previousQuestion}
                                  className={findBegin()}
                                  color="blue"
                                >
                                  {t("studentCustomizables.15")}
                                </MDBBtn>
                              </MDBCol>

                              <MDBCol className="text-center livvicFont">
                                <MDBBtn
                                  onClick={nextQuestion}
                                  className={findEnd()}
                                  color="blue"
                                >
                                  {t("studentCustomizables.14")}
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </MDBCard>
                      </animated.div>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                {/* Play Right Side */}
                <MDBCol md="3">
                  <MDBRow center className="mr-1 mt-1">
                    <MDBCol size="12">
                      <animated.div style={props2}>
                        <div className="customizablesScroll2">
                          <div style={{ overflowY: "auto" }}>
                            <MDBListGroup style={{ height: "40rem" }}>
                              {formData.currentExam.components.map(
                                (item, index) => {
                                  return (
                                    <MDBListGroupItem
                                      className="CarterOne cyanBackground text-white my-1 z-depth-2"
                                      key={item._id}
                                    >
                                      <MDBRow
                                        style={{ cursor: "pointer" }}
                                        onClick={() => goToComponent(item)}
                                      >
                                        <MDBCol size="auto">
                                          <p className="text-white text-truncate">
                                            {`${index + 1} : ${item.question}`}
                                          </p>
                                        </MDBCol>
                                      </MDBRow>
                                    </MDBListGroupItem>
                                  );
                                }
                              )}
                            </MDBListGroup>
                          </div>
                        </div>
                      </animated.div>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <animated.div
                className="centered"
                style={
                  props.opacity
                    ? {
                        ...props,
                        visibility: props.opacity.interpolate(o =>
                          o === 0 ? "hidden" : "visible"
                        )
                      }
                    : null
                }
              >
                <MDBRow className="justify-content-center">
                  <MDBCol size="10">
                    <MDBCard className="z-depth-2 cyanBackground py-3 px-3 mx-2">
                      <MDBRow center>
                        {formData.currentExam.duration === 0 ? (
                          <p className="h3 text-center livvicFont text-white mx-3">
                            {`There is no time limit for this exam`}
                          </p>
                        ) : (
                          <p className="h3 text-center livvicFont text-white mx-3">
                            {`${t("studentCustomizables.11")} ${
                              formData.currentExam.duration
                            } ${t("studentCustomizables.12")}`}
                          </p>
                        )}
                      </MDBRow>
                      <MDBRow center>
                        <MDBBtn onClick={startExam} color="blue" className="">
                          {t("studentCustomizables.13")}
                        </MDBBtn>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </animated.div>
              <MDBModalFooter className="cyanBackground">
                <div className={formData.buttonHide === false ? "d-none" : ""}>
                  {formData.currentExam.duration === 0 ||
                  formData.endExam === true ? null : (
                    <Countdown
                      date={countDownDate()}
                      renderer={props => propsSee(props)}
                    ></Countdown>
                  )}
                </div>
                <MDBBtn
                  className={formData.buttonHide === false ? "d-none" : ""}
                  color="blue"
                  onClick={closePlayExam}
                >
                  {t("studentCustomizables.16")}
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </MDBContainer>
      </div>
      {/* Evaluated Exam */}
      <div className="customizablesModal">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalSeeEval}
            toggle
            className="modal-full"
          >
            <div className="modal-full modal-content ">
              <CorrectedExams
                currentExam={formData.currentExam}
              ></CorrectedExams>
              <MDBModalFooter className="cyanBackground">
                <MDBBtn color="blue" onClick={closeSeeEval}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </MDBContainer>
      </div>
    </Fragment>
  );
};

Customizables.propTypes = {};

const mapStateToProps = state => ({
  students: state.students,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getCusExams,
  createEval,
  getEvalList,
  updateEval,
  clearStudentReducer
})(Customizables);
