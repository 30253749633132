import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSelect,
  MDBBtn,
  MDBIcon
} from "mdbreact";
// import LinkCard from "../LinkCard";
import {
  addGames,
  updateGame,
  setLoadingButtonGames
} from "../../actions/games";

const CreateGame = ({
  addGames,
  updateGame,
  history,
  games,
  individualGame,
  setLoadingButtonGames,
  games: { loadingButtonGames },
  saveGameBoolean,
  clearsaveGameBoolean
}) => {
  const [formData, setFormData] = useState({
    levelOptions: [
      {
        text: "A1",
        value: "A1"
      },
      {
        text: "A2",
        value: "A2"
      },
      {
        text: "B1",
        value: "B1"
      },
      {
        text: "B2",
        value: "B2"
      },
      {
        text: "C1",
        value: "C1"
      },
      {
        text: "C2",
        value: "C2"
      },
      {
        text: "No level",
        value: "No level"
      }
    ],
    studentOptions: [
      {
        text: "1",
        value: "1"
      },
      {
        text: "2",
        value: "2"
      },
      {
        text: "3",
        value: "3"
      },
      {
        text: "4",
        value: "4"
      },
      {
        text: "5",
        value: "5"
      },
      {
        text: "Greater than 5",
        value: "5+"
      }
    ],
    newGame: {
      title: "",
      instructions: "",
      materials: "",
      level: "",
      duration: "",
      numberOfStudents: "",
      youtube: [],
      links: [],
      gameId: ""
    },
    youtubeLink: "",
    youtubeTitle: "",
    customLink: "",
    customTitle: "",
    levelError: "",
    loading: false,
    gameError: "",
    instructions: "",
    materials: "",
    durationOptions: [
      {
        text: "Short",
        value: "Short"
      },
      {
        text: "Medium",
        value: "Medium"
      },
      {
        text: "Long",
        value: "Long"
      }
    ]
  });

  const [formData2, setFormData2] = useState({
    text: ""
  });
  const [formData3, setFormData3] = useState({
    text2: ""
  });

  useEffect(() => {
    if (individualGame.title) {
      setFormData(prevState => {
        const newArray = prevState.levelOptions.map((item, index) => {
          if (item.value === individualGame.level) {
            return {
              ...item,
              checked: true
            };
          } else {
            return item;
          }
        });

        const newArray2 = prevState.studentOptions.map((item2, index) => {
          if (item2.value === individualGame.numberOfStudents) {
            return {
              ...item2,
              checked: true
            };
          } else {
            return item2;
          }
        });

        const newArray3 = prevState.durationOptions.map((item3, index) => {
          if (item3.value === individualGame.duration) {
            return {
              ...item3,
              checked: true
            };
          } else {
            return item3;
          }
        });
        return {
          ...prevState,
          newGame: {
            title: individualGame.title,
            text: individualGame.text ? individualGame.text : "",
            level: individualGame.level ? individualGame.level : "",
            duration: individualGame.duration ? individualGame.duration : "",
            instructions: individualGame.instructions,
            materials: individualGame.materials,
            numberOfStudents: individualGame.numberOfStudents
              ? individualGame.numberOfStudents
              : "",
            youtube: individualGame.youtube ? individualGame.youtube : [],
            links: individualGame.links ? individualGame.links : [],
            GameId: individualGame._id
          },
          levelOptions: newArray,
          studentOptions: newArray2,
          durationOptions: newArray3
        };
      });
    }
  }, [individualGame]);

  useEffect(() => {
    if (individualGame.title) {
      setFormData2(prevState => {
        return {
          ...prevState,
          text: individualGame.instructions ? individualGame.instructions : ""
        };
      });
    }
  }, [individualGame]);

  useEffect(() => {
    if (individualGame.title) {
      setFormData3(prevState => {
        return {
          ...prevState,
          text2: individualGame.materials ? individualGame.materials : ""
        };
      });
      // setFormData3({
      //   ...formData3,
      //   text2: individualGame.materials ? individualGame.materials : ""
      // });
    }
  }, [individualGame]);

  const getGameLevel = value => {
    const find = formData.levelOptions.find(item => item.checked === true);
    setFormData({
      ...formData,
      newGame: {
        ...formData.newGame,
        level: find ? find.value : ""
      }
    });
  };

  const getGameStudentOptions = value => {
    const find = formData.studentOptions.find(item => item.checked === true);
    setFormData({
      ...formData,
      newGame: {
        ...formData.newGame,
        numberOfStudents: find ? find.value : ""
      }
    });
  };

  const getGameDurationOptions = value => {
    const find = formData.durationOptions.find(item => item.checked === true);
    setFormData({
      ...formData,
      newGame: {
        ...formData.newGame,
        duration: find ? find.value : ""
      }
    });
  };

  const handleChangeYoutube = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const addYoutubeLink = () => {
    if (formData.youtubeLink === "") {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>You must enter a Youtube link</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      setFormData({
        ...formData,
        newGame: {
          ...formData.newGame,
          youtube: [
            ...formData.newGame.youtube,
            {
              url: formData.youtubeLink,
              title: formData.youtubeTitle ? formData.youtubeTitle : "No Title"
            }
          ]
        },
        youtubeLink: "",
        youtubeTitle: ""
      });
    }
  };

  const deleteYoutubeLink = index2 => {
    setFormData({
      ...formData,
      newGame: {
        ...formData.newGame,
        youtube: formData.newGame.youtube.filter(
          array => formData.newGame.youtube.indexOf(array) !== index2
        )
      },
      youtubeLink: ""
    });
  };
  const handleChangeLink = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const addCustomLink = () => {
    if (formData.customLink === "") {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>You must enter a website link</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      setFormData({
        ...formData,
        newGame: {
          ...formData.newGame,

          links: [
            ...formData.newGame.links,
            {
              url: formData.customLink,
              title: formData.customTitle ? formData.customTitle : "No Title"
            }
          ]
        },
        customLink: "",
        customTitle: ""
      });
    }
  };

  const deleteCustomLink = index2 => {
    setFormData({
      ...formData,
      newGame: {
        ...formData.newGame,
        links: formData.newGame.links.filter(
          array => formData.newGame.links.indexOf(array) !== index2
        )
      },
      customLink: ""
    });
  };

  const onChange = e =>
    setFormData({
      ...formData,
      newGame: {
        ...formData.newGame,
        [e.target.name]: e.target.value
      }
    });

  const onChange2 = (content, delta, source, editor) => {
    setFormData2({
      ...formData2,
      text: content
    });
  };

  const onChange3 = (content, delta, source, editor) => {
    setFormData3({
      ...formData3,
      text2: content
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (!formData.newGame.title) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A title is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (!formData.newGame.level) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A level is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    }
    if (individualGame._id) {
      setLoadingButtonGames(true);
      updateGame(individualGame._id, {
        ...formData.newGame,
        instructions: formData2.text,
        materials: formData3.text2
      });
      setFormData({
        ...formData,
        gameError: ""
      });
    } else {
      setLoadingButtonGames(true);
      addGames({
        ...formData.newGame,
        instructions: formData2.text,
        materials: formData3.text2
      });
    }
  };

  useEffect(() => {
    if (saveGameBoolean === true) {
      const onSubmitGame = () => {
        // if (!formData.newGame.title) {
        //   const messageError = () => {
        //     return (
        //       <Fragment>
        //         <MDBRow>
        //           <MDBCol>
        //             <MDBIcon icon="times" />
        //             <p>A title is required</p>
        //           </MDBCol>
        //         </MDBRow>
        //       </Fragment>
        //     );
        //   };
        //   toast.error(messageError);
        // } else if (!formData.newGame.level) {
        //   const messageError = () => {
        //     return (
        //       <Fragment>
        //         <MDBRow>
        //           <MDBCol>
        //             <MDBIcon icon="times" />
        //             <p>A level is required</p>
        //           </MDBCol>
        //         </MDBRow>
        //       </Fragment>
        //     );
        //   };
        //   toast.error(messageError);
        // }
        if (individualGame._id) {
          setLoadingButtonGames(true);
          updateGame(individualGame._id, {
            ...formData.newGame,
            instructions: formData2.text,
            materials: formData3.text2
          });
          setFormData({
            ...formData,
            gameError: ""
          });
        } else {
          setLoadingButtonGames(true);
          addGames({
            ...formData.newGame,
            instructions: formData2.text,
            materials: formData3.text2
          });
        }
      };

      onSubmitGame();
      clearsaveGameBoolean();
    }
  }, [saveGameBoolean]);

  // const handleKeyPress = event => {
  //   if (event.which === 13 /* Enter */) {
  //     event.preventDefault();
  //   }
  // };
  var styles = {
    cursor: "pointer"
  };

  // before return

  return (
    <section>
      <MDBCard className="modalBottomMargin mx-3">
        <MDBCardBody>
          <p className="h4 pb-2 quickSand text-center">Game information</p>

          <MDBRow>
            <MDBCol md="6" className="mb-2">
              <MDBInput
                name="title"
                onChange={e => onChange(e)}
                value={formData.newGame.title}
                label="Game Title"
                labelClass="quickSand"
                className="quickSand"
                required
              />
            </MDBCol>
            <MDBCol md="6" className="mb-2">
              <MDBSelect
                options={formData.levelOptions}
                getValue={getGameLevel}
                labelClass="black-text quickSand"
                label={"Choose Level"}
                className="quickSand"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" className="mb-2">
              <MDBSelect
                options={formData.studentOptions}
                getValue={getGameStudentOptions}
                labelClass="black-text quickSand"
                label={"Number of Players"}
                className="quickSand"
              />
            </MDBCol>
            <MDBCol md="6" className="mb-2">
              <MDBSelect
                options={formData.durationOptions}
                getValue={getGameDurationOptions}
                labelClass="black-text quickSand"
                label={"Choose Duration"}
                className="quickSand"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <div className="gameQuill">
                <ReactQuill
                  className="gameQuill"
                  theme="bubble"
                  name="instructions"
                  onChange={onChange2}
                  value={formData2.text}
                  // defaultValue={formData.newGame.text}
                  placeholder="Game Instructions"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" }
                      ],

                      ["clean"],
                      [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" }
                      ]
                    ],

                    clipboard: {
                      matchVisual: false
                    }
                  }}
                />
              </div>
            </MDBCol>
            <MDBCol md="6" className="mb-4">
              <div>
                <ReactQuill
                  className="gameQuill"
                  theme="bubble"
                  name="materials"
                  onChange={onChange3}
                  value={formData3.text2}
                  // defaultValue={formData.newGame.text}
                  placeholder="Game Materials Required"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" }
                      ],

                      ["clean"],
                      [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" }
                      ]
                    ],

                    clipboard: {
                      matchVisual: false
                    }
                  }}
                />
              </div>
            </MDBCol>
          </MDBRow>
          <p className="h4 pb-2 quickSand text-center">Resources</p>
          <p className="h6 pb-2 quickSand">Youtube Links</p>
          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <MDBInput
                onChange={e => handleChangeYoutube(e)}
                value={formData.youtubeLink}
                name="youtubeLink"
                label="Paste Youtube URL"
                background
                size="sm"
                className="quickSand"
                labelClass="quickSand"
              />
              <MDBInput
                onChange={e => handleChangeYoutube(e)}
                value={formData.youtubeTitle}
                name="youtubeTitle"
                label="Add Title"
                background
                size="sm"
                className="quickSand"
                labelClass="quickSand"
              />
              <MDBBtn
                onClick={addYoutubeLink}
                color="warning"
                size="sm"
                outline
                className="quickSand"
              >
                Add video
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6" className="mb-4">
              <MDBIcon fab icon="youtube" />

              <hr />
              {formData.newGame.youtube.map((link, index) => {
                return (
                  <Fragment key={index}>
                    <MDBRow>
                      <MDBCol>
                        <MDBIcon
                          onClick={() => deleteYoutubeLink(index)}
                          icon="trash"
                          size="1x"
                          className="red-text"
                          style={styles}
                        />
                      </MDBCol>
                      <MDBCol>
                        <MDBRow>
                          <p className="mr-1">{`${index + 1}.`}</p>
                          <a
                            href={`${link.url}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="quickSand"
                          >
                            {link.title}
                          </a>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </Fragment>
                );
              })}
              <hr />
            </MDBCol>
          </MDBRow>
          <p className="h6 pb-2 quickSand">Regular Website Links</p>
          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <MDBInput
                onChange={e => handleChangeLink(e)}
                value={formData.customLink}
                name="customLink"
                label="Paste Website URL"
                className="quickSand"
                labelClass="quickSand"
                background
                size="sm"
              />
              <MDBInput
                onChange={e => handleChangeLink(e)}
                value={formData.customTitle}
                name="customTitle"
                label="Url Title"
                className="quickSand"
                labelClass="quickSand"
                background
                size="sm"
              />
              <MDBBtn
                className="quickSand"
                onClick={addCustomLink}
                color="warning"
                size="sm"
                outline
              >
                Add Link
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6" className="mb-4">
              <MDBIcon icon="link" />
              <hr />
              {formData.newGame.links.map((link, index) => {
                return (
                  <Fragment key={index}>
                    <MDBRow>
                      <MDBCol>
                        <MDBIcon
                          onClick={() => deleteCustomLink(index)}
                          icon="trash"
                          size="1x"
                          className="red-text"
                          style={styles}
                        />
                      </MDBCol>
                      <MDBCol>
                        <MDBRow>
                          <p className="mr-1">{`${index + 1}.`}</p>
                          <a
                            href={`${link.url}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="quickSand"
                          >
                            {link.title}
                          </a>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </Fragment>
                );
              })}
              <hr />
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </section>
  );
};

const mapStateToProps = state => ({
  games: state.games
});

export default connect(mapStateToProps, {
  addGames,
  updateGame,
  setLoadingButtonGames
})(withRouter(CreateGame));
