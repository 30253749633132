import {
  CREATE_PAYMENTINTENT,
  CUSTOMER_ERROR,
  ACCESS,
  GET_CARDS,
  GET_CARD,
  UPDATE_CARD,
  DELETE_CARD,
  MAKE_CARDDEFAULT,
  GET_CUSTOMER,
  CANCEL_SUBSCRIPTION,
  LOGOUT
} from "../actions/types";

const initialState = {
  paymentIntent: null,
  haveAccess: null,
  cards: [],
  card: null,
  defaultCardStore: null,
  customer: {
    subscriptions: {
      data: []
    }
  },
  deletedCard: null,
  startTrial: "",
  cancelSubscription: ""
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PAYMENTINTENT:
      return {
        ...state,
        paymentIntent: payload,
        loading: false
      };

    case ACCESS:
      return {
        ...state,
        haveAccess: payload,
        loading: false
      };

    case CUSTOMER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case GET_CARDS:
      return {
        ...state,
        cards: payload,
        loading: false
      };
    case GET_CARD:
      return {
        ...state,
        defaultCardStore: payload,
        loading: false
      };
    case UPDATE_CARD:
      return {
        ...state,
        card: payload,
        loading: false
      };
    case DELETE_CARD:
      return {
        ...state,
        cards: state.cards.filter(cards => cards.id !== payload),
        loading: false,
        deletedCard: payload
      };

    case MAKE_CARDDEFAULT:
    case GET_CUSTOMER:
      return {
        ...state,
        customer: payload,
        loading: false
      };

    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelSubscription: payload
      };
    case LOGOUT:
      return {
        paymentIntent: null,
        haveAccess: null,
        cards: [],
        card: null,
        defaultCardStore: null,
        customer: {
          subscriptions: {
            data: []
          }
        },
        deletedCard: null,
        startTrial: "",
        cancelSubscription: ""
      };
    default:
      return state;
  }
}
