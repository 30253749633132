import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import moment from "moment";
import Linkify from "react-linkify";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import {
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBBtn,
  MDBScrollbar,
  MDBContainer,
  MDBInput
} from "mdbreact";
import "./Chat.css";

const socket = io("/", {
  transports: ["websocket"]
});

const url = io("/messages/student", {
  transports: ["websocket"]
});

const Chat = ({ students: { user, latestChatMsg } }) => {
  const [formData, setFormData] = useState({
    students: [],
    messages: [],

    newMessage: "",

    userId: "",
    userType: "",
    pressEnter: true
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      if (user._id) {
        setFormData(prevState => {
          return {
            ...prevState,
            userId: user._id,
            userType: user.userType,
            teacherId: user.userTutor
          };
        });

        socket.open();
        url.open();

        return () => {
          socket.close();
          url.close();
        };
      }
    }
  }, [user]);

  const messageToClients = msg => {
    setFormData({
      ...formData,
      messages: [...formData.messages, msg],
      newMessage: ""
    });
  };

  const historyCatchUp = history => {
    const historyArray = history.history.map((item, index) => {
      return {
        author: item.author,
        icon: item.icon,
        when: item.when,
        message: item.message,
        userId: item.userId
      };
    });
    setFormData({
      ...formData,
      messages: historyArray,
      studentId: user._id
    });
  };

  useEffect(() => {
    if (user) {
      if (user._id) {
        url.emit("joinRoom", {
          studentId: user._id,

          latestChatMsg: user.latestChatMsg
        });
      }
    }
  }, [user]);

  useEffect(() => {
    url.on("historyCatchUp", historyCatchUp);
    return () => {
      url.off("historyCatchUp", historyCatchUp);
    };
  });

  useEffect(() => {
    url.on("messageToClients", messageToClients);

    return () => {
      url.off("messageToClients", messageToClients);
    };
  });

  // useEffect(() => {
  //   socket.once("nsList", nsList);

  //   return () => {
  //     socket.off("nsList", nsList);
  //   };
  // });

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async e => {
    e.preventDefault();
    if (formData.newMessage === "") {
      return null;
    } else {
      url.emit("newMessageToServer", {
        text: formData.newMessage,
        userTutor: user.name,
        userId: user._id,
        studentId: formData.studentId,
        userType: user.userType
        // teacherId: user.userTutor
      });
    }
  };

  // Key Press Enter

  const onKeyPressEnterChat = e => {
    if (formData.pressEnter === true) {
      if (e.key === "Enter") {
        onSubmit(e);
      }
    } else {
      return;
    }
  };

  // Press enter to send message

  const onChangeEnter = e => {
    setFormData({
      ...formData,
      pressEnter: !formData.pressEnter
    });
  };

  useEffect(() => {
    const notSeen = formData.messages.filter(singleMessage => {
      return singleMessage.seen === false;
    });
    if (notSeen.length !== 0) {
      if (latestChatMsg.seen === false) {
        url.emit("updateSeenStudent", {
          studentId: user._id,

          latestChatMsg: latestChatMsg
        });
      }
    }
  }, [formData.messages, user._id, latestChatMsg]);

  const scrollToBottom = () => {
    window.location.href = "#scrolltobottom";
  };

  useEffect(scrollToBottom, [formData.messages]);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student Chat</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow className="pt-5 mt-3 " center>
          <MDBCol>
            <MDBCard className="grey lighten-3 chat-room cardMobile">
              <MDBCardBody>
                <MDBRow center className="px-lg-2 px-2">
                  <MDBCol
                    md="12"
                    xl="8"
                    className="pl-md-3 mt-4 mt-md-0 px-lg-auto"
                  >
                    <div className="scrollable-chat mb-3 pb-0">
                      <MDBScrollbar>
                        <MDBListGroup className="list-unstyled pl-3 pr-3 mobileChatScroll">
                          {formData.messages.length !== 0 &&
                            formData.messages.map(message => (
                              <ChatMessage
                                key={message.author + message.when}
                                message={message}
                                user={user}
                              />
                            ))}
                        </MDBListGroup>
                        <div id="scrolltobottom" />
                      </MDBScrollbar>
                    </div>
                    <div className="form-group basic-textarea textdimension">
                      <MDBInput
                        onChange={e => onChange(e)}
                        name="newMessage"
                        type="textarea"
                        value={formData.newMessage}
                        className="form-control pl-2 my-0 textAreaMobile borderChat"
                        id="exampleFormControlTextarea2"
                        rows="3"
                        onKeyPress={e => onKeyPressEnterChat(e)}
                        autoFocus
                      />
                      <MDBBtn
                        onClick={e => onSubmit(e)}
                        color="indigo"
                        rounded
                        size="sm"
                        className="float-right mt-4"
                      >
                        {t("studentChat.2")}
                      </MDBBtn>
                      <span className="d-inline-block quickSand mt-4">
                        <MDBInput
                          label="Press enter to send"
                          checked={formData.pressEnter}
                          type="checkbox"
                          id="checkbox3"
                          onChange={onChangeEnter}
                        />
                      </span>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

const ChatMessage = ({ message: { author, when, message, userId }, user }) => {
  // var today = moment();
  // var yesterday = moment().subtract(1, "day");

  // var engagementDate = when;
  const datedisplay = () => {
    return moment(when, "x").fromNow();
    // if (moment(engagementDate).isSame(today, "day")) {
    //   return moment(when).format("h:mm");
    // } else if (moment(engagementDate).isSame(yesterday, "day")) {
    //   return moment(when).format("YYYY MM DD");
    // }
  };

  const backgroundcolor = () => {
    if (userId === user._id) {
      return "primary-color messageCardBorder z-depth-1-half h-100 messageCardWidth";
    } else {
      return "messageCardBorder z-depth-1-half";
    }
  };
  const textcolor = () => {
    if (userId === user._id) {
      return "text-white robotoFont messageTextSize2";
    } else {
      return "";
    }
  };

  const position = () => {
    if (userId === user._id) {
      return "offset-md-2 middleVertical col-12";
    } else {
      return "middleVertical col-12";
    }
  };
  const linked = () => {
    if (userId === user._id) {
      return (
        <div className="linkdiv">
          <Linkify>{message}</Linkify>
        </div>
      );
    } else {
      return (
        <div className="robotoFont messageTextSize2">
          <Linkify>{message}</Linkify>
        </div>
      );
    }
  };

  var links = document.links;
  for (var i = 0; i < links.length; i++) {
    links[i].target = "_blank";
  }

  return (
    <li className="mb-2">
      <MDBCol md="10" className={position()}>
        <MDBCol md="1" className="col-1">
          <MDBRow center>
            {userId !== user._id ? (
              <div className="middleVertical">
                <img
                  style={{ width: "43px" }}
                  src={user.userTutor.avatar.url}
                  alt=""
                  className="rounded-circle z-depth-1-half"
                />{" "}
              </div>
            ) : (
              <div>
                <MDBCard className="chatMessageCard middleVertical">
                  <p className="mt-3 messageTextSize3 questrialFont">
                    {author.charAt(0).toUpperCase()}
                  </p>
                </MDBCard>
              </div>
            )}
          </MDBRow>
        </MDBCol>
        <MDBCol md="11" className="col-11">
          <MDBCard className={backgroundcolor()}>
            <MDBCardBody className={textcolor()}>{linked()}</MDBCardBody>
            <p className="ml-auto pr-2 mb-0 pb-1 quickSand messageTextSize4">
              {" "}
              {datedisplay()}
            </p>
          </MDBCard>
        </MDBCol>
      </MDBCol>
    </li>
  );
};

const mapStateToProps = state => ({
  students: state.students
});

export default withRouter(connect(mapStateToProps, {})(Chat));
