import React, { Fragment, useEffect } from "react";
import { Redirect, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { getBlogs } from "../../actions/auth";
import { resetBlogLoader } from "../../actions/auth";
import Spinner from "../layout/Spinner";

import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBPagination,
  MDBPageItem,
  MDBPageNav
} from "mdbreact";

const BlogList = ({
  activate,
  match,
  history,
  getBlogs,
  auth: {
    blog,
    blogs,
    initialPageNumber,
    initialNumberOfPages,
    loadingBlogList
  },
  resetBlogLoader
}) => {
  useEffect(() => {
    getBlogs({ initialPageNumber: "first" });
    resetBlogLoader(true);
  }, [getBlogs, resetBlogLoader]);

  // Pagination

  const isActive = page => {
    if (initialPageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationBlog = () => {
    getBlogs({ initialPageNumber: "first" });
  };

  const previousPaginationBlog = () => {
    getBlogs({ initialPageNumber: initialPageNumber - 1 });
  };

  const currentPaginationBlog = index => {
    getBlogs({ initialPageNumber: index + 1 });
  };

  const nextPaginationBlog = () => {
    getBlogs({ initialPageNumber: initialPageNumber + 1 });
  };

  const lastPaginationBlog = () => {
    getBlogs({ initialPageNumber: "last" });
  };

  return loadingBlogList ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Blog Posts</title>
        <meta
          name="description"
          content="Follow Topscholar's blog posts to get awesome teaching material."
        />
        <meta property="og:title" content="Topscholar: Blog Posts" />
        <meta
          property="og:description"
          content="Follow Topscholar's blog posts to get awesome teaching material."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href={`https://www.topscholar.io/blog`} />
      </Helmet>
      <div className="mx-3" style={{ marginTop: "7rem", marginBottom: "3rem" }}>
        <MDBContainer>
          <MDBCard>
            <p className="quickSand h1-responsive text-center">Blog Posts</p>
            <MDBCardBody>
              <MDBRow>
                {blogs.map((item, index) => {
                  return (
                    <MDBCol lg="4" md="4" className="mb-3">
                      <Link to={`/blog/${item.url}`}>
                        <div
                          hover
                          className="rounded z-depth-2 mb-4 text-center"
                          style={{ cursor: "pointer" }}
                        >
                          <img className="img-fluid" src={item.image} alt="" />
                          <p
                            className="quickSand text-center my-1 pb-1 text-truncate mx-1"
                            style={{ fontSize: "20px" }}
                          >
                            {item.title}
                          </p>
                        </div>
                      </Link>
                    </MDBCol>
                  );
                })}
              </MDBRow>

              {/* Pagination Blog Medium Size */}

              <div className="middleVertical mt-4 mb-3 paginationCanvasMedium">
                <MDBRow>
                  <MDBCol>
                    <MDBPagination circle>
                      <MDBPageItem onClick={firstPaginationBlog}>
                        <MDBPageNav className="page-link">
                          <span>First</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={previousPaginationBlog}>
                        <MDBPageNav className="page-link" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      {[...Array(initialNumberOfPages)].map((item, index) => {
                        if (index + 1 === initialPageNumber) {
                          return (
                            <MDBPageItem
                              key={index}
                              active={isActive(index + 1)}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber - 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber - 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber - 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber + 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber + 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber + 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <MDBPageItem onClick={nextPaginationBlog}>
                        <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={lastPaginationBlog}>
                        <MDBPageNav className="page-link">Last</MDBPageNav>
                      </MDBPageItem>
                    </MDBPagination>
                  </MDBCol>
                </MDBRow>
              </div>

              {/* Pagination Blog Small Size */}

              <div className="middleVertical mt-4 mb-3 paginationCanvasSmall">
                <MDBRow>
                  <MDBCol>
                    <MDBPagination circle size="sm">
                      <MDBPageItem onClick={firstPaginationBlog}>
                        <MDBPageNav className="page-link">
                          <span>First</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={previousPaginationBlog}>
                        <MDBPageNav className="page-link" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      {[...Array(initialNumberOfPages)].map((item, index) => {
                        if (index + 1 === initialPageNumber) {
                          return (
                            <MDBPageItem
                              key={index}
                              active={isActive(index + 1)}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber - 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber - 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber - 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber + 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber + 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumber + 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationBlog(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <MDBPageItem onClick={nextPaginationBlog}>
                        <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={lastPaginationBlog}>
                        <MDBPageNav className="page-link">Last</MDBPageNav>
                      </MDBPageItem>
                    </MDBPagination>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
      </div>
    </Fragment>
  );
};

BlogList.propTypes = {
  auth: PropTypes.object.isRequired,
  activate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, { getBlogs, resetBlogLoader })(BlogList)
);
