import {
  MAKE_EVALUATION,
  EVALUATION_ERROR,
  GET_EVALUATIONS,
  DELETE_EVALUATION,
  UPDATE_EVALUATION,
  SET_LOADING_BUTTON_EVALUATIONS,
  LOGOUT
} from "../actions/types";

const initialState = {
  listEvaluations: [],
  updatedEvaluation: "",
  deletedEvaluation: "",
  loading: true,
  error: "",
  evaluation: "",
  initialNumberOfPages: 1,
  initialPageNumber: 1,
  loadingButtonEvaluations: false
};
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MAKE_EVALUATION:
      return {
        ...state,
        evaluation: payload.evaluation,
        listEvaluations: [...state.listEvaluations, payload.evaluation],
        loadingButtonEvaluations: false
      };
    case GET_EVALUATIONS:
      return {
        ...state,
        listEvaluations: payload.evaluation,
        initialPageNumber: payload.initialPageNumber,
        initialNumberOfPages: payload.initialNumberOfPages,
        loading: false
      };
    case EVALUATION_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonEvaluations: false
      };
    case UPDATE_EVALUATION:
      const newArray = state.listEvaluations.map((item, index) => {
        if (item._id === payload.evaluation._id) {
          return payload.evaluation;
        } else {
          return item;
        }
      });
      return {
        ...state,
        listEvaluations: newArray,
        updatedEvaluation: payload.evaluation,
        loadingButtonEvaluations: false
      };
    case DELETE_EVALUATION:
      return {
        ...state,

        listEvaluations: state.listEvaluations.filter(
          evaluation => evaluation._id !== payload.evaluation._id
        ),
        deletedEvaluation: payload.evaluation
      };
    case SET_LOADING_BUTTON_EVALUATIONS:
      return {
        ...state,
        loadingButtonEvaluations: payload
      };
    case LOGOUT:
      return {
        listEvaluations: [],
        updatedEvaluation: "",
        deletedEvaluation: "",
        loading: true,
        error: "",
        evaluation: "",
        initialNumberOfPages: 1,
        initialPageNumber: 1,
        loadingButtonEvaluations: false
      };
    default:
      return state;
  }
}
