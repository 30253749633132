import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "../layout/Spinner";
import "./canvas.css";
import "react-toastify/dist/ReactToastify.css";
import uniqWith from "lodash/uniqWith";
import flattenDeep from "lodash/flattenDeep";
import io from "socket.io-client";
import { useSpring, animated } from "react-spring";

import { Stage, Layer, Line } from "react-konva";
import { HuePicker } from "react-color";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBSelect,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtnFixedItem,
  MDBBtnFixed,
  MDBPagination,
  MDBPageNav,
  MDBPageItem
} from "mdbreact";

import {
  makeCanvas,
  getCanvasList,
  updateCanvas,
  deleteCanvas,
  clearCanvasReducer,
  setPaginationModeCanvas,
  searchCanvasFunction,
  clearCanvasSearchReducer,
  setLoadingButtonCanvas,
  setLoadingDeleteCanvas
} from "../../actions/canvas";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getStudentsSelect } from "../../actions/students";
import { getGroupsSelect } from "../../actions/groups";

// removed: :5000/canvas/student
const url = io("/canvas/student", {
  transports: ["websocket"]
});

const CanvasDash = ({
  canvas: {
    createCanvas,
    listCanvas,
    updatedCanvas,
    deletedCanvas,
    loading,
    initialPageNumber,
    initialNumberOfPages,
    numberOfPages,
    pageNumber,
    searchCanvas,
    paginationModeCanvas,
    loadingButtonCanvas,
    canvasLoading,
    loadingDeleteCanvas
  },
  students: { students, studentsSelect },
  auth: { user },
  groups: { groups, groupsSelect },
  getGroupsSelect,
  makeCanvas,
  getCanvasList,
  updateCanvas,
  deleteCanvas,
  getStudentsSelect,
  clearCanvasReducer,
  setPaginationModeCanvas,
  searchCanvasFunction,
  clearCanvasSearchReducer,
  setLoadingButtonCanvas,
  setLoadingDeleteCanvas
}) => {
  const [formData, setFormData] = useState({
    draws: [],
    _id: "",
    historyRedo: [],
    historyClear: [],
    points: {
      coordinates: [],
      color: "",
      mode: "",
      strokewidth: 5
    },

    newCanvas: true,
    filteredCanvasList: [],
    canvasList: [],
    color: "#9c45eb",
    huepicker: true,
    mode: "brush",
    strokewidth: 5,
    // end of canvas

    searchValue: "",
    searchCanvas: [],
    pageNumber: 1,
    name: "",
    openNewCanvas: false,
    modalMakeCanvas: false,
    modalDeleteCanvas: false,
    modalCloseCanvas: false,
    buttonStyle: {
      transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
      opacity: "0"
    },
    studentOptions: [
      {
        text: "",
        value: ""
      }
    ],
    selectedStudents: [],
    groupOptions: [],
    shareOptions: [],
    stageWidth: window.innerWidth,
    stageHeight: window.innerHeight,
    heightConst: 1000,
    widthConst: 1000,
    bottomShow: false
  });
  const [formData2, setFormData2] = useState({
    points2: {
      coordinates: [],
      color: "",
      mode: "",
      strokewidth: 5
    }
  });

  // Initialize variables

  useEffect(() => {
    getCanvasList({ initialPageNumber: "first" });
    getStudentsSelect();
    getGroupsSelect();
  }, [getCanvasList, getStudentsSelect, getGroupsSelect]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        shareOptions: [...prevState.groupOptions, ...prevState.studentOptions]
      };
    });
  }, [formData.studentOptions, formData.groupOptions]);

  useEffect(() => {
    const newArray = groupsSelect.map((group, index) => {
      const newArray2 = group.students.map((student, index) => {
        return student._id;
      });
      return {
        text: group.name,
        value: newArray2
      };
    });
    setFormData(prevState => {
      return {
        ...prevState,
        groupOptions: newArray
      };
    });
  }, [groupsSelect]);

  useEffect(() => {
    return () => {
      url.emit("endShareServer");
    };
  }, [formData._id]);

  useEffect(() => {
    if (updatedCanvas) {
      setFormData(prevState => {
        const newArray = prevState.canvasList.map((item, index) => {
          if (item._id === updatedCanvas._id) {
            return updatedCanvas;
          } else return item;
        });
        return {
          ...prevState,
          canvasList: newArray,
          filteredCanvasList: newArray
        };
      });
      clearCanvasReducer();
    }
  }, [updatedCanvas, clearCanvasReducer]);

  useEffect(() => {
    const newCanvasList = async () => {
      await setFormData(prevState => {
        const newArray = prevState.canvasList.filter(
          canvas => canvas._id !== deletedCanvas
        );
        return {
          ...prevState,
          canvasList: newArray,
          filteredCanvasList: newArray,
          draws: [],
          _id: "",
          name: "",
          modalDeleteCanvas: false,
          openNewCanvas: false,
          toggleEditDeck: false
        };
      });

      clearCanvasReducer();
    };

    if (deletedCanvas) {
      newCanvasList();
    }
  }, [deletedCanvas, clearCanvasReducer]);

  // Deck Search
  const whiteboardSearch = event =>
    setFormData({ ...formData, searchValue: event.target.value });

  const handleMouseDown = e => {
    if (e.type === "touchstart") {
      setTimeout(function() {
        if (e.evt.touches.length > 1) {
          return true;
        } else if (e.evt.changedTouches.length === 1) {
          e.evt.preventDefault();
          e.evt.stopPropagation();
          if (formData.points.coordinates.length === 0) {
            const { x, y } = e.target.getStage().getPointerPosition();
            setFormData({
              ...formData,
              points: {
                coordinates: [...formData.points.coordinates, x, y],
                mode: formData.mode,
                color: formData.color,
                strokewidth: formData.strokewidth,
                new: true
              }
            });
          }
        }
      }, 0);
    } else {
      if (formData.points.coordinates.length === 0) {
        const { x, y } = e.target.getStage().getPointerPosition();
        setFormData({
          ...formData,
          points: {
            coordinates: [...formData.points.coordinates, x, y],
            mode: formData.mode,
            color: formData.color,
            strokewidth: formData.strokewidth,
            new: true
          }
        });
      }
    }
  };
  const handleMouseMove = e => {
    if (e.type === "touchmove") {
      if (e.evt.touches.length > 1) {
        return true;
      } else if (e.evt.changedTouches.length === 1) {
        e.evt.preventDefault();
        e.evt.stopPropagation();
        if (formData.points.coordinates.length !== 0) {
          const { x, y } = e.target.getStage().getPointerPosition();
          setFormData({
            ...formData,
            points: {
              ...formData.points,
              coordinates: [...formData.points.coordinates, x, y]
            }
          });
        }
      }
    } else {
      if (formData.points.coordinates.length !== 0) {
        const { x, y } = e.target.getStage().getPointerPosition();
        setFormData({
          ...formData,
          points: {
            ...formData.points,
            coordinates: [...formData.points.coordinates, x, y]
          }
        });
      }
    }
  };

  const handleMouseUp = async e => {
    if (e.type === "touchend") {
      if (e.evt.touches.length > 1) {
        return true;
      } else if (e.evt.changedTouches.length === 1) {
        const scale = formData.stageWidth / 1000;
        const newArray2 = formData.points.coordinates.map((item2, index2) => {
          return item2 / scale;
        });
        e.evt.preventDefault();
        e.evt.stopPropagation();

        await url.emit("updateDraws", {
          points: {
            color: formData.points.color,
            coordinates: newArray2,
            mode: formData.points.mode,
            strokewidth: formData.points.strokewidth
          },
          studentId: user._id
        });
        setFormData({
          ...formData,
          draws: [...formData.draws, formData.points],
          points: {
            coordinates: [],
            color: "",
            mode: "",
            strokewidth: ""
          },
          historyClear: []
        });
      }
    } else {
      const scale = formData.stageWidth / 1000;
      const newArray2 = formData.points.coordinates.map((item2, index2) => {
        return item2 / scale;
      });
      await url.emit("updateDraws", {
        points: {
          color: formData.points.color,
          coordinates: newArray2,
          mode: formData.points.mode,
          strokewidth: formData.points.strokewidth
        },
        studentId: user._id
      });
      setFormData({
        ...formData,
        draws: [...formData.draws, formData.points],
        points: {
          coordinates: [],
          color: "",
          mode: "",
          strokewidth: ""
        },
        historyClear: []
      });
    }
  };

  const mouseout = () => {
    if (formData.points.coordinates.length !== 0) {
      const scale = formData.stageWidth / 1000;
      const newArray2 = formData.points.coordinates.map((item2, index2) => {
        return item2 / scale;
      });
      url.emit("updateDraws", {
        points: {
          color: formData.points.color,
          coordinates: newArray2,
          mode: formData.points.mode,
          strokewidth: formData.points.strokewidth
        },
        studentId: user._id
      });
      setFormData({
        ...formData,
        draws: [...formData.draws, formData.points],
        points: {
          coordinates: [],
          color: "",
          mode: "",
          strokewidth: ""
        },
        historyClear: []
      });
    }
  };
  const touchmove = e => {
    if (e.touches.length > 1) {
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("mouseout", mouseout);
    return () => {
      document.removeEventListener("mouseout", mouseout);
    };
  });

  useEffect(() => {
    document.addEventListener("touchmove", touchmove);
    return () => {
      document.removeEventListener("touchmove", touchmove);
    };
  });
  useEffect(() => {
    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  });

  const checkSize = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    const newArray = formData.draws.map((item, index) => {
      const scale = formData.stageWidth / 1000;
      const newArray2 = item.coordinates.map((item2, index2) => {
        if (item.new) {
          return item2 / scale;
        } else {
          return item2;
        }
      });
      return {
        color: item.color,
        coordinates: newArray2,
        mode: item.mode,
        strokewidth: item.strokewidth
      };
    });

    setFormData({
      ...formData,
      stageWidth: width,
      stageHeight: height,
      draws: newArray
    });
  };

  useEffect(() => {
    if (createCanvas) {
      setFormData(prevState => {
        return {
          ...prevState,
          canvasList: [...prevState.canvasList, createCanvas],
          filteredCanvasList: [...prevState.canvasList, createCanvas],

          draws: createCanvas.draws,
          name: createCanvas.name,
          _id: createCanvas._id,
          newCanvas: false,
          modalMakeCanvas: false
        };
      });
      clearCanvasReducer();
    }
  }, [createCanvas, clearCanvasReducer]);

  const handleChange = (color, event) => {
    setFormData({ ...formData, color: color.hex });
  };

  const toggleEraser = () => {
    setFormData({
      ...formData,
      mode: formData.mode === "eraser" ? "brush" : "eraser",
      strokewidth: formData.mode === "brush" ? 17 : 5
    });
  };

  const handleUndo = () => {
    if (formData.historyClear.length !== 0) {
      url.emit("undoServer", { studentId: user._id });
      setFormData({
        ...formData,
        draws: formData.historyClear,
        historyClear: []
      });
    } else {
      url.emit("undoServer", { studentId: user._id });
      setFormData({
        ...formData,
        draws: formData.draws.slice(0, -1),
        historyRedo: [
          ...formData.historyRedo,
          formData.draws[formData.draws.length - 1]
        ]
      });
    }
  };
  const handleRedo = () => {
    url.emit("redoServer", { studentId: user._id });
    setFormData({
      ...formData,
      draws: [
        ...formData.draws,
        formData.historyRedo[formData.historyRedo.length - 1]
      ],
      historyRedo: formData.historyRedo.slice(0, -1)
    });
  };

  const clear = () => {
    url.emit("clearServer", { studentId: user._id });
    setFormData({
      ...formData,
      draws: [],
      historyClear: formData.draws,
      historyRedo: []
    });
  };

  //   New Canvas

  const newCanvas = () => {
    setFormData({
      ...formData,
      openNewCanvas: true,
      newCanvas: true
    });
  };

  const createCanvasFunc = async () => {
    const newDrawsArray = formData.draws.map((item, index) => {
      const scale = formData.stageWidth / 1000;
      const newArray2 = item.coordinates.map((item2, index2) => {
        if (item.new) {
          return item2 / scale;
        } else {
          return item2;
        }
      });
      return {
        color: item.color,
        coordinates: newArray2,
        mode: item.mode,
        strokewidth: item.strokewidth
      };
    });
    await setLoadingButtonCanvas(true);
    makeCanvas({
      draws: newDrawsArray,
      name: formData.name
    });
  };

  const saveCanvasFunc = () => {
    if (formData.newCanvas === false) {
      const newDrawsArray = formData.draws.map((item, index) => {
        const scale = formData.stageWidth / 1000;
        const newArray2 = item.coordinates.map((item2, index2) => {
          if (item.new) {
            return item2 / scale;
          } else {
            return item2;
          }
        });
        return {
          color: item.color,
          coordinates: newArray2,
          mode: item.mode,
          strokewidth: item.strokewidth
        };
      });
      setLoadingButtonCanvas(true);
      updateCanvas({
        draws: newDrawsArray,
        name: formData.name,
        _id: formData._id
      });
    } else {
      setFormData({
        ...formData,
        modalMakeCanvas: true
      });
    }
  };

  const closeOpenCanvas = () => {
    const newArray = formData.studentOptions.map((item, index) => {
      return {
        ...item,
        checked: false
      };
    });
    url.emit("endShareServer");
    setFormData({
      ...formData,
      openNewCanvas: false,
      modalCloseCanvas: false,
      draws: [],
      historyRedo: [],
      historyClear: [],
      points: {
        coordinates: [],
        color: "",
        mode: "",
        strokewidth: ""
      },
      color: "#9c45eb",
      huepicker: false,
      mode: "brush",
      selectedStudents: [],
      studentOptions: newArray,
      name: "",
      _id: ""
    });
  };

  const toggleMakeCanvas = () => {
    setFormData({
      ...formData,
      modalMakeCanvas: !formData.modalMakeCanvas
    });
  };

  const toggleCloseCanvas = () => {
    setFormData({
      ...formData,
      modalCloseCanvas: !formData.modalCloseCanvas
    });
  };

  const onChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

  // Show Canvas

  const modalShowCanvas = item => {
    setFormData({
      ...formData,
      draws: item.draws,
      name: item.name,
      _id: item._id,
      openNewCanvas: true,
      newCanvas: false
    });
  };

  const onHover = () => {
    setFormData({
      ...formData,
      buttonStyle: {
        transform: "scaleY(1) scaleX(1) translateY(0) translateX(0)",
        opacity: "1"
      }
    });
  };

  const onMouseLeave = () => {
    setFormData({
      ...formData,
      buttonStyle: {
        transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
        opacity: "0"
      }
    });
  };

  const handleSelectStudent = value => {
    setFormData({
      ...formData,
      selectedStudents: value
    });
  };

  const loadStudentModal = () => {
    setFormData({
      ...formData,
      modalStudentSelect: true
    });
  };

  useEffect(() => {
    const newArray = studentsSelect.map((item, index) => {
      return {
        text: item.name,
        value: item._id
      };
    });
    setFormData(prevState => {
      return {
        ...prevState,
        studentOptions: newArray
      };
    });
  }, [studentsSelect]);

  useEffect(() => {
    if (formData.selectedStudents.length !== 0) {
      const newArray3 = formData.draws.map((draws, index) => {
        const scale = formData.stageWidth / 1000;
        const newArray4 = draws.coordinates.map((points, index2) => {
          return points / scale;
        });
        return {
          color: draws.color,
          coordinates: newArray4,
          mode: draws.mode,
          strokewidth: draws.strokewidth
        };
      });
      const newArray = flattenDeep(formData.selectedStudents);
      const newArray2 = uniqWith(newArray);
      url.emit("joinRoom", {
        students: newArray2,
        canvasId: formData._id,
        canvasName: formData.name,
        draws: newArray3
      });
    }
  }, [
    formData.selectedStudents,
    formData._id,
    formData.draws,
    formData.name,
    formData.stageWidth
  ]);

  const studentJoined = () => {
    url.emit("initialDraws", formData.draws);
  };

  useEffect(() => {
    url.on("studentJoined", studentJoined);

    return () => {
      url.off("studentJoined", studentJoined);
    };
  });

  const updateCanvasDraws = dataCanvas => {
    if (dataCanvas.studentId !== user._id) {
      setFormData({
        ...formData,
        draws: [
          ...formData.draws,
          { ...dataCanvas.points, coordinates: dataCanvas.points.coordinates }
        ]
      });
    }
  };

  useEffect(() => {
    url.on("receiveDraws", updateCanvasDraws);

    return () => {
      url.off("receiveDraws", updateCanvasDraws);
    };
  });
  const clearClient = dataCanvas => {
    if (dataCanvas.studentId !== user._id) {
      setFormData({
        ...formData,
        historyClear: formData.draws,
        draws: [],
        historyRedo: []
      });
    }
  };

  useEffect(() => {
    url.on("clearClient", clearClient);

    return () => {
      url.off("clearClient", clearClient);
    };
  });
  const undoClient = dataCanvas => {
    if (dataCanvas.studentId !== user._id) {
      if (formData.historyClear.length !== 0) {
        setFormData({
          ...formData,
          draws: formData.historyClear,
          historyClear: []
        });
      } else {
        setFormData({
          ...formData,
          draws: formData.draws.slice(0, -1),
          historyRedo: [
            ...formData.historyRedo,
            formData.draws[formData.draws.length - 1]
          ]
        });
      }
    }
  };

  useEffect(() => {
    url.on("undoClient", undoClient);

    return () => {
      url.off("undoClient", undoClient);
    };
  });
  const redoClient = dataCanvas => {
    if (dataCanvas.studentId !== user._id) {
      setFormData({
        ...formData,
        draws: [
          ...formData.draws,
          formData.historyRedo[formData.historyRedo.length - 1]
        ],
        historyRedo: formData.historyRedo.slice(0, -1)
      });
    }
  };

  useEffect(() => {
    url.on("redoClient", redoClient);

    return () => {
      url.off("redoClient", redoClient);
    };
  });

  useEffect(() => {
    if (user) {
      const scale = formData.stageWidth / 1000;
      const newArray2 = formData.points.coordinates.map((item2, index2) => {
        return item2 / scale;
      });
      url.emit("pointsServer", {
        points: {
          color: formData.points.color,
          coordinates: newArray2,
          mode: formData.points.mode,
          strokewidth: formData.points.strokewidth
        },
        studentId: user._id
      });
    }
  }, [formData.points, user, formData.stageWidth]);

  const pointsClient = dataCanvas => {
    if (dataCanvas.studentId !== user._id) {
      setFormData2({
        ...formData2,
        points2: dataCanvas.points
      });
    }
  };

  useEffect(() => {
    url.on("pointsClient", pointsClient);

    return () => {
      url.off("pointsClient", pointsClient);
    };
  });
  const askClient = dataCanvas => {
    const filtered = formData.selectedStudents.filter(
      item => item === dataCanvas
    );

    if (filtered.length !== 0 && formData._id) {
      url.emit("joinRoom", {
        students: formData.selectedStudents,
        canvasId: formData._id,
        canvasName: formData.name,
        draws: formData.draws
      });
    }
  };

  useEffect(() => {
    url.on("askClient", askClient);

    return () => {
      url.off("askClient", askClient);
    };
  });

  // remove double scroll bar when in modal mode
  useEffect(() => {
    if (formData.openNewCanvas === true) {
      if (formData._id) {
        // document.getElementById("fixedStudentButton").style.cssText =
        //   "position: none !important;";
        document.body.style.cssText = "overflow-y: hidden !important;";
      } else {
        document.body.style.cssText = "overflow-y: hidden !important;";
      }
    } else {
      document.body.style.overflowY = "";
    }
  }, [formData.openNewCanvas, formData._id]);

  // Pagination

  useEffect(() => {
    if (searchCanvas) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchCanvas: searchCanvas
        };
      });
    }
  }, [searchCanvas]);

  const onKeyPressEnterCanvas = e => {
    if (e.key === "Enter") {
      canvasSearchFunc();
    }
  };

  const canvasSearchFunc = () => {
    searchCanvasFunction({
      keywords: formData.searchValue,
      pageNumber: formData.pageNumber
    });
    setPaginationModeCanvas("search");
  };

  const clearCanvasFunc = () => {
    setFormData({
      ...formData,
      searchValue: "",
      searchCanvas: []
    });
    clearCanvasSearchReducer();
    setPaginationModeCanvas("date");
    return formData.filteredCanvasList;
  };

  const searchCanvasMap = () => {
    if (formData.searchCanvas === "No Results") {
      return [];
    } else if (formData.searchCanvas.length !== 0) {
      return formData.searchCanvas;
    } else {
      return formData.filteredCanvasList;
    }
  };

  const isActive1 = page => {
    if (initialPageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationCanvas = () => {
    if (paginationModeCanvas === "date") {
      getCanvasList({ initialPageNumber: "first" });
    } else if (paginationModeCanvas === "search") {
      searchCanvasFunction({
        pageNumber: "first",
        keywords: formData.searchValue
      });
    }
  };

  const previousPaginationCanvas = () => {
    if (paginationModeCanvas === "date") {
      getCanvasList({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationModeCanvas === "search") {
      searchCanvasFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchValue
      });
    }
  };

  const currentPaginationCanvas = index => {
    if (paginationModeCanvas === "date") {
      getCanvasList({ initialPageNumber: index + 1 });
    } else if (paginationModeCanvas === "search") {
      searchCanvasFunction({
        pageNumber: index + 1,
        keywords: formData.searchValue
      });
    }
  };

  const nextPaginationCanvas = () => {
    if (paginationModeCanvas === "date") {
      getCanvasList({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationModeCanvas === "search") {
      searchCanvasFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchValue
      });
    }
  };

  const lastPaginationCanvas = () => {
    if (paginationModeCanvas === "date") {
      getCanvasList({ initialPageNumber: "last" });
    } else if (paginationModeCanvas === "search") {
      searchCanvasFunction({
        pageNumber: "last",
        keywords: formData.searchValue
      });
    }
  };

  const points2Scale = () => {
    const scale = formData.stageWidth / 1000;

    const newArray = formData2.points2.coordinates.map((item2, index2) => {
      return item2 * scale;
    });

    return newArray;
  };

  const props2 = useSpring({
    opacity:
      formData.bottomShow === false
        ? 0
        : 1 || formData.bottomShow === true
        ? 1
        : 0,

    config: { duration: 600 }
  });

  const bottomShowFunc = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        bottomShow: !prevState.bottomShow
      };
    });
  };

  const deleteCanvasFunc = () => {
    setLoadingDeleteCanvas(true);
    deleteCanvas(formData._id);
  };

  return loading ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Whiteboard</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow className="mt-3 " center>
          <MDBCol md="12">
            <MDBCard className="mt-5 bgcc">
              <MDBRow className="pt-3 middleVertical5" end>
                <MDBCol className="col-1" sm="2"></MDBCol>
                <MDBCol sm="4" className="col-7">
                  <MDBInput
                    className="white-text"
                    label="Search your whiteboards"
                    labelClass="white-text"
                    type="text"
                    containerClass="active-pink active-pink-2 mt-0 mb-3"
                    value={formData.searchValue}
                    onChange={whiteboardSearch}
                    onKeyPress={onKeyPressEnterCanvas}
                  />
                </MDBCol>
                <MDBCol className="col-2" sm="2">
                  <MDBRow className="align-items-center">
                    <div
                      className={
                        formData.searchValue.length !== 0 ? "mx-1" : "d-none"
                      }
                    >
                      <MDBIcon
                        icon="search"
                        onClick={canvasSearchFunc}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      className={
                        formData.searchCanvas.length !== 0 ||
                        formData.searchCanvas === "No Results"
                          ? "mx-1"
                          : "d-none"
                      }
                    >
                      <MDBIcon
                        icon="times-circle"
                        onClick={clearCanvasFunc}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </MDBRow>
                </MDBCol>
                <MDBCol sm="2" className="col-2">
                  <MDBIcon
                    pull="right"
                    size="2x"
                    icon="chalkboard"
                    style={{ cursor: "pointer" }}
                    className=" white-text pr-3"
                    onClick={newCanvas}
                  />
                </MDBCol>
              </MDBRow>

              <div
                className={
                  formData.searchCanvas === "No Results" &&
                  formData.filteredCanvasList.length !== 0
                    ? "mt-2"
                    : "d-none"
                }
              >
                <p className="quickSand h4 black-text text-center">
                  There are no canvas results for your search. Try Again.
                </p>
              </div>

              <div style={{ minHeight: "13rem" }}>
                {(formData.searchCanvas.length === 0 || "No Results") &&
                formData.filteredCanvasList.length === 0 &&
                canvasLoading === false ? (
                  <div className="cardWidth text-center">
                    <img
                      className="imageFlex"
                      src={
                        "https://res.cloudinary.com/unnamed/image/upload/v1578481241/No_whiteboards_found_mblbrv.png"
                      }
                      alt=""
                    />
                  </div>
                ) : (
                  <MDBRow center className="mt-4 mx-1">
                    {searchCanvasMap().map((item, index) => {
                      return (
                        <MDBCol
                          key={index}
                          md="auto"
                          lg="auto"
                          sm="auto"
                          className=" mt-3  mx-md-0 col-auto mx-1"
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => modalShowCanvas(item)}
                          >
                            <MDBCard className=" z-depth-1  my-auto">
                              <MDBCardBody>
                                <p className="CarterOne h4-responsive text-center text-pink  my-auto">
                                  {item.name.charAt(0).toUpperCase() +
                                    item.name.slice(1)}
                                </p>
                              </MDBCardBody>
                            </MDBCard>
                          </div>
                        </MDBCol>
                      );
                    })}
                  </MDBRow>
                )}
              </div>

              {/* Pagination Canvas Medium Size */}

              <div
                className={
                  formData.openNewCanvas === false
                    ? "middleVertical mt-4 mb-3 paginationCanvasMedium"
                    : "d-none"
                }
              >
                <MDBRow>
                  <MDBCol>
                    <MDBPagination circle>
                      <MDBPageItem onClick={firstPaginationCanvas}>
                        <MDBPageNav className="page-link">
                          <span>First</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={previousPaginationCanvas}>
                        <MDBPageNav className="page-link" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      {paginationModeCanvas === "date"
                        ? [...Array(initialNumberOfPages)].map(
                            (item, index) => {
                              if (index + 1 === initialPageNumber) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    active={isActive1(index + 1)}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 1) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 2) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 3) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 1) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 2) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 3) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else {
                                return null;
                              }
                            }
                          )
                        : [...Array(numberOfPages)].map((item, index) => {
                            if (index + 1 === pageNumber) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  active={isActive2(index + 1)}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 1) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 2) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 3) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 1) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 2) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 3) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else {
                              return null;
                            }
                          })}
                      <MDBPageItem onClick={nextPaginationCanvas}>
                        <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={lastPaginationCanvas}>
                        <MDBPageNav className="page-link">Last</MDBPageNav>
                      </MDBPageItem>
                    </MDBPagination>
                  </MDBCol>
                </MDBRow>
              </div>

              {/* Pagination Canvas Small Size */}

              <div
                className={
                  formData.openNewCanvas === false
                    ? "middleVertical mt-4 mb-3 paginationCanvasSmall"
                    : "d-none"
                }
              >
                <MDBRow>
                  <MDBCol>
                    <MDBPagination circle size="sm">
                      <MDBPageItem onClick={firstPaginationCanvas}>
                        <MDBPageNav className="page-link">
                          <span>First</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={previousPaginationCanvas}>
                        <MDBPageNav className="page-link" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      {paginationModeCanvas === "date"
                        ? [...Array(initialNumberOfPages)].map(
                            (item, index) => {
                              if (index + 1 === initialPageNumber) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    active={isActive1(index + 1)}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 1) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 2) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 3) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 1) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 2) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 3) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    onClick={() =>
                                      currentPaginationCanvas(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else {
                                return null;
                              }
                            }
                          )
                        : [...Array(numberOfPages)].map((item, index) => {
                            if (index + 1 === pageNumber) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  active={isActive2(index + 1)}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 1) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 2) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 3) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 1) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 2) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 3) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  onClick={() => currentPaginationCanvas(index)}
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else {
                              return null;
                            }
                          })}
                      <MDBPageItem onClick={nextPaginationCanvas}>
                        <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={lastPaginationCanvas}>
                        <MDBPageNav className="page-link">Last</MDBPageNav>
                      </MDBPageItem>
                    </MDBPagination>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {/* Canvas Main Modal */}
      <div className="canvasModal">
        <MDBContainer>
          <MDBModal
            isOpen={formData.openNewCanvas}
            toggle
            className="modal-full"
          >
            <div className="modal-full modal-content ">
              <MDBRow center>
                <MDBCol>
                  <div style={{ backgroundColor: "white" }}>
                    {/* <MDBScrollbar> */}
                    <Stage
                      className={
                        formData.mode === "brush" ? "custom-cur" : "eraser-cur"
                      }
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                      onTouchStart={handleMouseDown}
                      onTouchMove={handleMouseMove}
                      onTouchEnd={handleMouseUp}
                      width={formData.stageWidth}
                      height={formData.stageWidth}
                      // scale={{
                      //   x: formData.stageWidth / 1000,
                      //   y: formData.stageWidth / 1000
                      // }}
                    >
                      <Layer>
                        {formData.draws.map((item, index) => {
                          const scale = formData.stageWidth / 1000;
                          const newArray = item.coordinates.map(
                            (item2, index2) => {
                              if (item.new) {
                                return item2;
                              } else {
                                return item2 * scale;
                              }
                            }
                          );
                          return (
                            <Line
                              key={index}
                              points={newArray}
                              strokeWidth={item.strokewidth}
                              fill={item.color}
                              stroke={item.color}
                              globalCompositeOperation={
                                item.mode === "brush"
                                  ? "source-over"
                                  : "destination-out"
                              }
                            />
                          );
                        })}
                        <Line
                          points={formData.points.coordinates}
                          fill={formData.color}
                          stroke={formData.color}
                          strokeWidth={formData.strokewidth}
                          globalCompositeOperation={
                            formData.mode === "brush"
                              ? "source-over"
                              : "destination-out"
                          }
                        />
                        <Line
                          points={points2Scale()}
                          fill={formData2.points2.color}
                          stroke={formData2.points2.color}
                          strokeWidth={formData2.points2.strokewidth}
                          globalCompositeOperation={
                            formData2.points2.mode === "brush"
                              ? "source-over"
                              : "destination-out"
                          }
                        />
                      </Layer>
                    </Stage>
                    {/* </MDBScrollbar> */}
                  </div>
                </MDBCol>
              </MDBRow>
              {/* float buttons */}
              <MDBBtnFixed
                onMouseMove={onHover}
                onMouseLeave={onMouseLeave}
                floating
                color="green darken-1"
                icon="expand-arrows-alt"
                id="canvasRightButtons"
              >
                <MDBBtnFixedItem
                  buttonStyle={formData.buttonStyle}
                  color="blue"
                  icon="file"
                  onClick={clear}
                />
                <MDBBtnFixedItem
                  buttonStyle={formData.buttonStyle}
                  color="green"
                  icon={formData.mode === "brush" ? "eraser" : "paint-brush"}
                  onClick={toggleEraser}
                />
                <MDBBtnFixedItem
                  buttonStyle={formData.buttonStyle}
                  color={
                    formData.historyRedo.length === 0
                      ? "deep-purple lighten-3"
                      : "deep-purple"
                  }
                  icon="redo"
                  onClick={handleRedo}
                  disabled={formData.historyRedo.length === 0 ? true : false}
                />
                <MDBBtnFixedItem
                  buttonStyle={formData.buttonStyle}
                  color={
                    formData.draws.length === 0 &&
                    formData.historyClear.length === 0
                      ? "red lighten-3"
                      : "red"
                  }
                  icon="undo"
                  onClick={handleUndo}
                  disabled={
                    formData.draws.length === 0 &&
                    formData.historyClear.length === 0
                      ? true
                      : false
                  }
                />
              </MDBBtnFixed>
              {formData._id ? (
                <MDBBtn
                  floating
                  id="inviteButton"
                  color="green darken-1"
                  onClick={loadStudentModal}
                >
                  {" "}
                  <MDBIcon icon="user-graduate" className="mr-1" />{" "}
                </MDBBtn>
              ) : null}

              <div className="topcorner">
                <HuePicker
                  color={formData.color}
                  onChange={handleChange}
                ></HuePicker>
              </div>
              <MDBModalFooter className="green darken-1 ">
                <MDBBtn
                  block
                  color="danger"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      modalDeleteCanvas: true
                    });
                  }}
                >
                  Delete
                </MDBBtn>

                <MDBBtn
                  block
                  color="green darken-1"
                  onClick={saveCanvasFunc}
                  disabled={loadingButtonCanvas}
                >
                  {loadingButtonCanvas === true && (
                    <div className="spinner-grow spinner-grow-sm" />
                  )}
                  Save
                </MDBBtn>

                <MDBBtn block color="blue" onClick={toggleCloseCanvas}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </MDBContainer>

        {/* Modal Confirmation delete */}
      </div>
      <MDBContainer>
        <MDBModal isOpen={formData.modalDeleteCanvas} toggle>
          <MDBModalHeader>Confirm Delete</MDBModalHeader>
          <MDBModalBody>
            If you would like to delete this whiteboard please click on the
            'confirm' button below.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              onClick={() => {
                setFormData({
                  ...formData,
                  modalDeleteCanvas: false
                });
              }}
              size="sm"
              color="dark"
            >
              Close
            </MDBBtn>
            <MDBBtn
              disabled={loadingDeleteCanvas}
              onClick={deleteCanvasFunc}
              size="sm"
              color="danger"
            >
              {loadingDeleteCanvas === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Confirm
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal isOpen={formData.modalCloseCanvas} toggle>
          <MDBModalHeader className="montserratFont h4 black-text">
            Confirm Close
          </MDBModalHeader>
          <MDBModalBody className="quickSand h5 black-text">
            Please make sure you have saved your canvas before closing.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              className="quickSand"
              onClick={() => {
                setFormData({
                  ...formData,
                  modalCloseCanvas: false
                });
              }}
              size="sm"
              color="dark"
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              className="quickSand"
              onClick={closeOpenCanvas}
              size="sm"
              color="danger"
            >
              Confirm Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal isOpen={formData.modalStudentSelect} toggle={() => null}>
          <MDBModalHeader className="bgcc whiteFont">
            Invite students
          </MDBModalHeader>
          <MDBModalBody className="bgcc">
            <div className="dropDownStudents">
              <MDBSelect
                multiple
                search
                options={formData.shareOptions}
                selected="Choose your students"
                selectAll
                getValue={handleSelectStudent}
              />
            </div>
          </MDBModalBody>

          <MDBModalFooter className="bgcc">
            <MDBBtn
              onClick={() => {
                setFormData({
                  ...formData,
                  modalStudentSelect: false
                });
              }}
              size="sm"
              color="dark"
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal isOpen={formData.modalMakeCanvas} toggle={toggleMakeCanvas}>
          <MDBModalHeader>Save Whiteboard</MDBModalHeader>
          <MDBModalBody>
            <MDBInput
              name="name"
              onChange={e => onChange(e)}
              value={formData.name}
              label="Name of new whiteboard"
              required
            ></MDBInput>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={toggleMakeCanvas} size="sm" color="dark">
              Close
            </MDBBtn>
            <MDBBtn
              onClick={createCanvasFunc}
              size="sm"
              color="danger"
              disabled={loadingButtonCanvas}
            >
              {loadingButtonCanvas === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Save
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      {formData.bottomShow === false ? (
        <div className="removeUl hideBottom">
          <MDBBtn
            floating
            color="color-one"
            icon="info-circle"
            style={{ bottom: "24px", right: "24px" }}
            onClick={bottomShowFunc}
            size="sm"
            className="btnFixed"
          >
            <MDBIcon icon="info-circle"></MDBIcon>
          </MDBBtn>
        </div>
      ) : null}
      <animated.div style={props2}>
        {formData.bottomShow === true ? (
          <div className="float-wrap text-center z-depth-1-half">
            <MDBCard style={{ height: "16rem" }}>
              <MDBIcon
                onClick={bottomShowFunc}
                style={{ cursor: "pointer" }}
                size="2x"
                className="text-right mainColor bottomButton mr-1 mt-1"
                icon="window-close"
              />
              <MDBRow center>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Whiteboard Overview
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        className="embed-responsive-item"
                        title="Whiteboard Overview"
                        src="https://www.youtube.com/embed/Ycnm0npWt7Q"
                        allowFullScreen
                      ></iframe>
                      {/* <iframe
                        style={{ height: "11rem" }}
                        title="Using your calendar"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/vlDzYIIOYmM"
                        allowFullScreen
                      ></iframe> */}
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Sharing Whiteboard
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Sharing Whiteboard"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/l2nsuKsaneU"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        ) : null}
      </animated.div>
    </Fragment>
  );
};

CanvasDash.propTypes = {};

const mapStateToProps = state => ({
  canvas: state.canvas,
  students: state.students,
  auth: state.auth,
  groups: state.groups
});

export default withRouter(
  connect(mapStateToProps, {
    makeCanvas,
    getCanvasList,
    updateCanvas,
    deleteCanvas,
    getStudentsSelect,
    getGroupsSelect,
    clearCanvasReducer,
    setPaginationModeCanvas,
    searchCanvasFunction,
    clearCanvasSearchReducer,
    setLoadingButtonCanvas,
    setLoadingDeleteCanvas
  })(CanvasDash)
);
