import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import sum from "lodash/sum";
import { Helmet } from "react-helmet";

import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import urlParser from "js-video-url-parser";
import cloudinary from "cloudinary-core";
import parse from "html-react-parser";
import {
  createComment,
  deleteComment,
  likeComment,
  setLoadingButtonComment
} from "../../../actions/trading";

import { withRouter } from "react-router-dom";

import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput
} from "mdbreact";

var cl = cloudinary.Cloudinary.new({ cloud_name: "unnamed" });

const TopicView = ({
  history,
  mode,
  match,
  topicContent,
  createComment,
  setLoadingButtonComment,
  trading: { comments, loadingButtonComment },
  auth: { user },
  likeComment,
  deleteComment
}) => {
  const [formData, setFormData] = useState({
    individualTopic: {
      title: "",
      text: "",
      level: "",
      vocabulary: [],
      grammar: [],
      youtube: [],
      links: [],
      documents: [],
      date: "",
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      }
    },
    showCommentButton: false,
    comment: ""
  });

  useEffect(() => {
    if (topicContent) {
      setFormData(prevState => {
        return {
          ...prevState,
          individualTopic: {
            ...prevState.individualTopic,
            title: topicContent.title,
            text: topicContent.text,
            level: topicContent.level,
            vocabulary: topicContent.vocabulary,
            grammar: topicContent.grammar,
            youtube: topicContent.youtube,
            links: topicContent.links,
            documents: topicContent.documents,
            date: topicContent.date,
            userTutor: topicContent.userTutor,
            description: topicContent.description
          }
        };
      });
    }
  }, [topicContent]);

  const createCommentFunc = () => {
    setLoadingButtonComment(true);
    createComment({
      date: moment(new Date()).valueOf(),
      text: formData.comment,
      topicId: match.params.id,
      module: "topic"
    });
  };

  const onChangeComment = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const showCommentButton = () => {
    setFormData({
      ...formData,
      showCommentButton: true
    });
  };

  const likeFarFunc = item => {
    const find = item.thumbsUp.find(item3 => item3.user === user._id);
    if (find) {
      if (find.score === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const dislikeFarFunc = item => {
    const find = item.thumbsUp.find(item3 => item3.user === user._id);
    if (find) {
      if (find.score === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        comment: "",
        showCommentButton: false
      };
    });
  }, [comments]);

  const viewProfile = item => {
    history.push(`/trading/profile/${formData.individualTopic.userTutor._id}`);
  };

  const viewProfile2 = item => {
    history.push(`/trading/profile/${item}`);
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{formData.individualTopic.title}</title>
        <meta
          name="description"
          content={formData.individualTopic.description}
        />
        <meta property="og:title" content={formData.individualTopic.title} />
        <meta
          property="og:description"
          content={formData.individualTopic.description}
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link
          rel="canonical"
          href={`https://www.topscholar.io/trading/topic/${match.params.id}`}
        />
      </Helmet>
      <MDBRow className="middleVertical5 mx-2">
        <MDBCol sm="6">
          <MDBRow center>
            <MDBCol>
              <p className="quickSand mt-3 duruSans h5 text-center text-white">
                {formData.individualTopic.title}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol sm="6">
          <MDBRow
            center
            className="middleVertical tradingNameHover"
            onClick={() =>
              viewProfile({ _id: formData.individualTopic.userTutor._id })
            }
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ width: "35px" }}
              src={formData.individualTopic.userTutor.avatar.url}
              alt=""
              className="rounded-circle z-depth-1-half"
            />
            <p className="mt-3 quickSand titleFontSizeTrading text-black text-white pl-2">
              Published by {formData.individualTopic.userTutor.screenName}
            </p>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <hr />
      <MDBRow>
        <MDBCol>
          <p className="mt-1 tradingFontSize3  text-white pl-2 mb-3 ml-2">
            Description: {formData.individualTopic.description}
          </p>
        </MDBCol>
      </MDBRow>
      <Fragment>
        <MDBContainer className=" modalBottomMargin rounded backgroundTradingTopic">
          <MDBRow>
            <MDBCol
              md="6"
              className={
                formData.individualTopic.text !== ""
                  ? "mb-1 text-center"
                  : "d-none"
              }
            >
              <div>
                <p className="pb-1 montserratFont tradingFontSize">
                  Notes / Explanation
                </p>
                <div className="ql-editor " id="fixMargin">
                  <div
                    className="quickSand tradingFontSize2 ml-2 text-left"
                    dangerouslySetInnerHTML={{
                      __html: formData.individualTopic.text
                    }}
                  ></div>
                </div>
              </div>
            </MDBCol>
            <MDBCol md="6" className="mb-1 text-center">
              <Fragment>
                <p className="pb-1 montserratFont tradingFontSize">
                  Topic level
                </p>
                <p className="quickSand tradingFontSize2">
                  {formData.individualTopic.level}
                </p>
              </Fragment>
            </MDBCol>
          </MDBRow>
          <MDBRow
            className={
              (formData.individualTopic.vocabulary.length !== 0 &&
                formData.individualTopic.grammar.length === 0) ||
              (formData.individualTopic.vocabulary.length === 0 &&
                formData.individualTopic.grammar.length !== 0)
                ? "align-self-start"
                : formData.individualTopic.vocabulary.length === 0 &&
                  formData.individualTopic.grammar.length === 0
                ? "d-none"
                : formData.individualTopic.vocabulary.length !== 0 &&
                  formData.individualTopic.grammar.length !== 0
                ? "middleVertical"
                : "middleVertical"
            }
          >
            <MDBCol
              md="6"
              className={
                formData.individualTopic.vocabulary.length !== 0 &&
                formData.individualTopic.grammar.length === 0
                  ? "mb-1 text-center"
                  : formData.individualTopic.vocabulary.length !== 0 &&
                    formData.individualTopic.grammar.length !== 0
                  ? "mb-1 text-center"
                  : formData.individualTopic.vocabulary.length === 0 &&
                    formData.individualTopic.grammar.length === 0
                  ? "d-none"
                  : "d-none"
              }
            >
              <p className="montserratFont lessonFontSize pb-1">
                Vocabulary Words
              </p>

              <ul className="list-inline tradingFontSize2 quickSand vocabularyCommas">
                {formData.individualTopic.vocabulary.map((item, index) => {
                  return (
                    <li key={index} className="list-inline-item">
                      {item}
                    </li>
                  );
                })}
              </ul>
            </MDBCol>
            <MDBCol
              md="6"
              className={
                formData.individualTopic.vocabulary.length === 0 &&
                formData.individualTopic.grammar.length !== 0
                  ? "text-center"
                  : formData.individualTopic.vocabulary.length !== 0 &&
                    formData.individualTopic.grammar.length !== 0
                  ? "text-center"
                  : formData.individualTopic.vocabulary.length === 0 &&
                    formData.individualTopic.grammar.length === 0
                  ? "d-none"
                  : "d-none"
              }
            >
              <p className="montserratFont tradingFontSize pb-1">
                Grammar Topics
              </p>
              <ul className="list-inline tradingFontSize2 quickSand vocabularyCommas">
                {formData.individualTopic.grammar.map((item, index) => {
                  return (
                    <li key={index} className="list-inline-item">
                      {item}
                    </li>
                  );
                })}
              </ul>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol
              md="12"
              className={
                formData.individualTopic.youtube.length !== 0
                  ? "mb-4"
                  : "d-none"
              }
            >
              <Fragment>
                <p className="montserratFont tradingFontSize pb-1 text-center">
                  Youtube Videos
                </p>
              </Fragment>
            </MDBCol>
            {formData.individualTopic.youtube.map((item, index) => {
              const emb = urlParser.parse(item.url);
              if (typeof emb === "undefined") {
                return null;
              } else {
                const embUrl = urlParser.create({
                  videoInfo: emb,
                  format: "embed"
                });

                return (
                  <MDBCol key={index} md="6" className="mb-4">
                    <div className="embed-responsive embed-responsive-16by9 mb-1">
                      <iframe
                        title="Embeds Page"
                        className="embed-responsive-item"
                        src={embUrl}
                        allowFullScreen
                      />
                    </div>
                  </MDBCol>
                );
              }
            })}
          </MDBRow>
          <MDBRow
            className={
              (formData.individualTopic.links.length === 0 &&
                formData.individualTopic.documents.length !== 0) ||
              (formData.individualTopic.links.length !== 0 &&
                formData.individualTopic.documents.length === 0)
                ? "mb-4 text-center"
                : formData.individualTopic.links.length !== 0 &&
                  formData.individualTopic.documents.length !== 0
                ? "mb-4 text-center"
                : formData.individualTopic.links.length === 0 &&
                  formData.individualTopic.documents.length === 0
                ? "d-none"
                : "d-none"
            }
          >
            <MDBCol
              md="6"
              className={
                formData.individualTopic.links.length !== 0 &&
                formData.individualTopic.documents.length === 0
                  ? "mb-4 text-center"
                  : formData.individualTopic.links.length !== 0 &&
                    formData.individualTopic.documents.length !== 0
                  ? "mb-4 text-center"
                  : formData.individualTopic.links.length === 0 &&
                    formData.individualTopic.documents.length === 0
                  ? "d-none"
                  : "d-none"
              }
            >
              <Fragment>
                <p className="montserratFont tradingFontSize text-center pb-1">
                  Website Links
                </p>
              </Fragment>

              {formData.individualTopic.links.map((item, index) => {
                const hrefgood = item.url.replace(/(^\w+:|^)\/\//, "");

                return (
                  <ul key={index} className="fa-ul text-center">
                    <li className="list-inline-item">
                      <i className="fa-li fa fa-link" />

                      <a
                        href={`http://${hrefgood}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <span className="tradingFontSize3">{item.title}</span>
                      </a>
                    </li>
                  </ul>
                );
              })}
            </MDBCol>
            <MDBCol
              md="6"
              className={
                formData.individualTopic.links.length === 0 &&
                formData.individualTopic.documents.length !== 0
                  ? "mb-4 text-center"
                  : formData.individualTopic.links.length !== 0 &&
                    formData.individualTopic.documents.length !== 0
                  ? "mb-4 text-center"
                  : formData.individualTopic.links.length === 0 &&
                    formData.individualTopic.documents.length === 0
                  ? "d-none"
                  : "d-none"
              }
            >
              <Fragment>
                <p className="text-center pb-1 montserratFont tradingFontSize">
                  Saved file
                </p>
              </Fragment>

              {formData.individualTopic.documents.map((item, index) => {
                if (item.document_type === "video") {
                  return (
                    <MDBRow center>
                      <MDBCol>
                        <a
                          key={index}
                          href={`https://res.cloudinary.com/unnamed/${
                            item.document_type
                          }/upload/${item.document_id}${item.document_id.slice(
                            -4
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <span className="tradingFontSize3">
                            {item.title
                              ? item.title
                              : " Link to your saved video"}
                          </span>
                        </a>
                      </MDBCol>
                    </MDBRow>
                  );
                } else if (item.document_type === "raw") {
                  return (
                    <MDBRow center>
                      <MDBCol>
                        <a
                          key={index}
                          href={`https://res.cloudinary.com/unnamed/${item.document_type}/upload/${item.document_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <span className="tradingFontSize3">
                            {item.title
                              ? item.title
                              : "Download your saved file"}
                          </span>
                        </a>
                      </MDBCol>
                    </MDBRow>
                  );
                } else if (item.document_id.slice(-3) === "pdf") {
                  return (
                    <MDBRow center>
                      <MDBCol>
                        <a
                          key={index}
                          href={`https://res.cloudinary.com/unnamed/${item.document_type}/upload/${item.document_id}.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <span className="tradingFontSize3">
                            {item.title
                              ? item.title
                              : "Download your saved pdf"}
                          </span>
                        </a>
                      </MDBCol>
                    </MDBRow>
                  );
                } else {
                  return (
                    <MDBRow center>
                      <MDBCol>
                        <a
                          key={index}
                          href={cl.url(item.document_id)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <span className="tradingFontSize3">
                            {item.title
                              ? item.title
                              : "Link to your saved file"}
                          </span>
                        </a>
                      </MDBCol>
                    </MDBRow>
                  );
                }
              })}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow center>
          <p className=" duruSans h5 text-center text-white">Comments</p>
        </MDBRow>
        <MDBRow center>
          <MDBCol size="12">
            <MDBInput
              style={{ overflowY: "auto" }}
              name="comment"
              type="textarea"
              value={formData.comment}
              label="Post a new comment"
              labelClass="livvicFont text-white"
              className="text-white"
              onChange={onChangeComment}
              onClick={showCommentButton}
            />
          </MDBCol>
          <MDBCol
            size="12"
            className={
              formData.showCommentButton === false ? "d-none" : "text-right"
            }
          >
            <MDBBtn
              onClick={() => {
                setFormData({
                  ...formData,
                  showCommentButton: false,
                  comment: ""
                });
              }}
              outline
            >
              Cancel
            </MDBBtn>
            <MDBBtn disabled={loadingButtonComment} onClick={createCommentFunc}>
              {loadingButtonComment === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Comment
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <hr />
        {comments.map((item, index) => {
          const thumb = item.thumbsUp.map((item2, index2) => {
            return item2.score;
          });
          let summation = sum(thumb);

          summation = summation <= 0 ? null : summation;

          return (
            <MDBRow className="mt-1 mb-4" key={item._id}>
              <MDBCol sm="4" md="2" className="col-4">
                <div className="label">
                  <img
                    style={{ width: "35px", cursor: "pointer" }}
                    src={item.userTutor.avatar.url}
                    alt=""
                    className="rounded-circle z-depth-1-half"
                    onClick={() => viewProfile2(item.userTutor._id)}
                  />
                </div>
              </MDBCol>
              <MDBCol sm="8" md="10" className="col-8">
                <div className="">
                  <MDBRow className="middleVertical5">
                    <p
                      style={{ margin: "0rem", cursor: "pointer" }}
                      className="quickSand text-white"
                      onClick={() => viewProfile2(item.userTutor._id)}
                    >
                      {item.userTutor.screenName}
                    </p>

                    <div
                      className={
                        item.userTutor._id === user._id
                          ? "ml-auto mr-5"
                          : "d-none"
                      }
                    >
                      <MDBIcon
                        onClick={() => deleteComment({ commentId: item._id })}
                        style={{ cursor: "pointer" }}
                        className="text-danger"
                        icon="trash-alt"
                      />
                    </div>
                  </MDBRow>
                  <MDBRow>
                    <p style={{ marginBottom: "2px" }}>
                      {moment(item.date, "x").fromNow()}
                    </p>
                  </MDBRow>
                  <MDBRow>
                    <p
                      style={{ margin: "0rem" }}
                      className="text-white quickSand mb-1"
                    >
                      {item.text}
                    </p>
                  </MDBRow>
                  <MDBRow>
                    <p className="text-info font-weight-bold mr-2">
                      {summation}
                    </p>

                    <MDBIcon
                      style={{ cursor: "pointer" }}
                      className="mr-2 text-info"
                      far={likeFarFunc(item)}
                      icon="thumbs-up"
                      onClick={() => {
                        likeComment({ score: 1, commentId: item._id });
                      }}
                    />

                    <MDBIcon
                      style={{ cursor: "pointer" }}
                      className="text-info"
                      far={dislikeFarFunc(item)}
                      icon="thumbs-down "
                      onClick={() => {
                        likeComment({ score: -1, commentId: item._id });
                      }}
                    />
                  </MDBRow>
                </div>
              </MDBCol>
            </MDBRow>
          );
        })}
      </Fragment>
    </Fragment>
  );
};

TopicView.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    createComment,
    deleteComment,
    likeComment,
    setLoadingButtonComment
  })(TopicView)
);
