import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "./trading.css";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import {
  followUser,
  unFollowUser,
  getFollowPublic,
  getPublicProfile,
  setLoadingButtonFollow
} from "../../actions/trading";

import {
  MDBAvatar,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBPagination,
  MDBPageItem,
  MDBPageNav
} from "mdbreact";

const PublicProfileView = ({
  history,
  mode,
  match,
  switchMode,
  setLoadingButtonFollow,
  trading: { publicProfile, follow, followPublic, loadingButtonFollow },
  followUser,
  unFollowUser,
  getFollowPublic,
  modalFollowPublic,
  getPublicProfile,
  modalReport
}) => {
  const viewLesson = item => {
    history.push(`/trading/lesson/${item._id}`);
  };
  const viewTopic = item => {
    history.push(`/trading/topic/${item._id}`);
  };
  const viewExam = item => {
    history.push(`/trading/exam/${item._id}`);
  };
  const viewGame = item => {
    history.push(`/trading/game/${item._id}`);
  };
  const viewDeck = item => {
    history.push(`/trading/deck/${item._id}`);
  };
  const viewProfile = item => {
    history.push(`/trading/profile/${item._id}`);
  };
  const viewCurriculum = item => {
    history.push(`/trading/curriculum/${item._id}`);
  };

  const unFollowUserFunc = () => {
    setLoadingButtonFollow(true);
    unFollowUser({ userId: match.params.id });
  };

  const followUserFunc = () => {
    setLoadingButtonFollow(true);
    followUser({ userId: match.params.id });
  };

  const followButton = () => {
    const find = follow.following.find(item => item._id === match.params.id);
    if (find) {
      return (
        <MDBRow className="mt-2" center>
          <MDBBtn
            onClick={unFollowUserFunc}
            color="purple"
            disabled={loadingButtonFollow}
          >
            {loadingButtonFollow === true && (
              <div className="spinner-grow spinner-grow-sm" />
            )}
            Unfollow
          </MDBBtn>
        </MDBRow>
      );
    } else {
      return (
        <MDBRow className="mt-2" center>
          <MDBBtn
            onClick={followUserFunc}
            color="purple"
            disabled={loadingButtonFollow}
          >
            {loadingButtonFollow === true && (
              <div className="spinner-grow spinner-grow-sm" />
            )}
            Follow
          </MDBBtn>
        </MDBRow>
      );
    }
  };

  useEffect(() => {
    getFollowPublic({ userId: match.params.id });
  }, [getFollowPublic, match]);

  // Pagination

  const isActive1 = page => {
    if (publicProfile.pageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationModules = () => {
    getPublicProfile({ pageNumber: "first", id: match.params.id });
  };

  const previousPaginationModules = () => {
    getPublicProfile({
      pageNumber: publicProfile.pageNumber - 1,
      id: match.params.id
    });
  };

  const currentPaginationModules = index => {
    getPublicProfile({ pageNumber: index + 1, id: match.params.id });
  };

  const nextPaginationModules = () => {
    getPublicProfile({
      pageNumber: publicProfile.pageNumber + 1,
      id: match.params.id
    });
  };

  const lastPaginationModules = () => {
    getPublicProfile({ pageNumber: "last", id: match.params.id });
  };

  return publicProfile.deleted === true ? (
    <p>Profile no longer exists. Deleted by user.</p>
  ) : (
    // Main Card

    <Fragment>
      <MDBCol md="12" className="mb-r middleVertical">
        <MDBCard
          className="tradingCard3 mb-3"
          style={{ height: "auto", width: "80%" }}
        >
          <MDBRow center>
            <MDBCol size="12" className="text-center">
              <MDBAvatar
                tag="img"
                src={publicProfile.avatar.url}
                className="rounded-circle z-depth-1 profileAvatarWidth mr-2 mt-1"
                alt="avatar"
              />
            </MDBCol>
          </MDBRow>
          <MDBCardBody>
            <MDBCol size="12" className="text-center">
              <MDBCardBody className="pt-0 mt-0">
                <h3 className="font-bold white-text mb-4 quickSand">
                  {publicProfile.screenName}
                </h3>
                <p className="mt-4 dark-grey-text quickSand">
                  {publicProfile.description}
                </p>
                <p className="quickSand h4 white-text">
                  {publicProfile.score} points
                </p>
              </MDBCardBody>
              <MDBRow className="middleVertical">
                <MDBCol lg="2" className="col-12"></MDBCol>
                <MDBCol lg="8" className="col-12">
                  {followButton()}
                </MDBCol>
                <MDBCol lg="2" className="col-12">
                  <MDBIcon
                    icon="gavel"
                    size="sm"
                    pull="right"
                    className="mt-5 reportIcon"
                    onClick={modalReport}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

      {/* Left Card */}

      <MDBRow className="row justify-content-around mx-2">
        <MDBCol lg="4" className="col-12">
          <div className="followersCard" style={{ height: "auto" }}>
            <MDBRow>
              {followPublic.followed.map((item, index) => {
                if (index === 0) {
                  return (
                    <MDBAvatar
                      key={item._id}
                      tag="img"
                      src={item.avatar.url}
                      className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                      alt="avatar"
                      onClick={() => viewProfile({ _id: item._id })}
                      style={{ cursor: "pointer", width: "55px" }}
                    />
                  );
                } else if (index === 1) {
                  return (
                    <MDBAvatar
                      tag="img"
                      src={item.avatar.url}
                      className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                      alt="avatar"
                      onClick={() => viewProfile({ _id: item._id })}
                      style={{ cursor: "pointer", width: "55px" }}
                    />
                  );
                } else if (index === 2) {
                  return (
                    <MDBAvatar
                      tag="img"
                      src={item.avatar.url}
                      className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                      alt="avatar"
                      onClick={() => viewProfile({ _id: item._id })}
                      style={{ cursor: "pointer", width: "55px" }}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </MDBRow>
            <MDBRow>
              {followPublic.followed.map((item, index) => {
                if (index === 3) {
                  return (
                    <MDBAvatar
                      tag="img"
                      src={item.avatar.url}
                      className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                      alt="avatar"
                      onClick={() => viewProfile({ _id: item._id })}
                      style={{ cursor: "pointer", width: "55px" }}
                    />
                  );
                } else if (index === 4) {
                  return (
                    <MDBAvatar
                      tag="img"
                      src={item.avatar.url}
                      className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                      alt="avatar"
                      onClick={() => viewProfile({ _id: item._id })}
                      style={{ cursor: "pointer", width: "55px" }}
                    />
                  );
                } else if (index === 5) {
                  return (
                    <MDBAvatar
                      tag="img"
                      src={item.avatar.url}
                      className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                      alt="avatar"
                      onClick={() => viewProfile({ _id: item._id })}
                      style={{ cursor: "pointer", width: "55px" }}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </MDBRow>
            <MDBRow>
              <p
                className="badScript text-white mx-2 my-2"
                style={{ cursor: "pointer" }}
                onClick={modalFollowPublic}
              >
                {`${followPublic.followed.length} tutors are following this user`}
              </p>
            </MDBRow>
          </div>
        </MDBCol>

        {/* Right Card */}

        <MDBCol lg="4" className="col-12">
          <div style={{ minHeight: "19rem" }}>
            {publicProfile.modules.map((item, index) => {
              if (item.type === "lessons") {
                return (
                  <MDBCard
                    key={item._id}
                    style={{ height: "auto", cursor: "pointer" }}
                    className="tradingCard px-4 my-2"
                    onClick={() => {
                      viewLesson({ _id: item._id });
                    }}
                  >
                    <MDBRow center className="middleVertical5">
                      <MDBCol sm="4">
                        <MDBIcon icon="clone fas" size="md" />
                      </MDBCol>
                      <MDBCol sm="8" className="mt-2">
                        <p className="quickSand text-black publicFont text-truncate">
                          {item.title}
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                );
              } else if (item.type === "decks") {
                return (
                  <MDBCard
                    style={{ height: "auto", cursor: "pointer" }}
                    className="tradingCard2 px-4 my-2"
                    onClick={() => {
                      viewDeck({ _id: item._id });
                    }}
                  >
                    <MDBRow center className="middleVertical5">
                      <MDBCol sm="4">
                        <MDBIcon icon="brain" size="md" />
                      </MDBCol>
                      <MDBCol sm="8" className="mt-2">
                        <p className="quickSand text-black publicFont text-truncate">
                          {item.name}
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                );
              } else if (item.type === "exams") {
                return (
                  <MDBCard
                    style={{ height: "auto", cursor: "pointer" }}
                    className="tradingCard3  my-2 px-4"
                    onClick={() => {
                      viewExam({ _id: item._id });
                    }}
                  >
                    <MDBRow center className="middleVertical5">
                      <MDBCol sm="4">
                        <MDBIcon icon="th" size="md" />
                      </MDBCol>
                      <MDBCol sm="8" className="mt-2">
                        <p className="quickSand text-black publicFont text-truncate">
                          {item.name}
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                );
              } else if (item.type === "games") {
                return (
                  <MDBCard
                    style={{ height: "auto", cursor: "pointer" }}
                    className="tradingCard1 px-4 my-2"
                    onClick={() => {
                      viewGame({ _id: item._id });
                    }}
                  >
                    <MDBRow center className="middleVertical5">
                      <MDBCol sm="4">
                        <MDBIcon icon="trophy" size="md" />
                      </MDBCol>
                      <MDBCol sm="8" className="mt-2">
                        <p className="quickSand text-black publicFont text-truncate">
                          {item.title}
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                );
              } else if (item.type === "topics") {
                return (
                  <MDBCard
                    style={{ height: "auto", cursor: "pointer" }}
                    className="tradingCard5 px-4 my-2 px-2"
                    onClick={() => {
                      viewTopic({ _id: item._id });
                    }}
                  >
                    <MDBRow center className="middleVertical5">
                      <MDBCol sm="4">
                        <MDBIcon icon="graduation-cap" size="md" />
                      </MDBCol>
                      <MDBCol sm="8" className="mt-2">
                        <p className="quickSand text-black publicFont text-truncate">
                          {item.title}
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                );
              } else if (item.type === "curriculums") {
                return (
                  <MDBCard
                    style={{ height: "auto", cursor: "pointer" }}
                    className="tradingCard4 px-4 my-2 px-2"
                    onClick={() => {
                      viewCurriculum({ _id: item._id });
                    }}
                  >
                    <MDBRow center className="middleVertical5">
                      <MDBCol sm="4">
                        <MDBIcon icon="university" size="md" />
                      </MDBCol>
                      <MDBCol sm="8" className="mt-2">
                        <p className="quickSand text-black publicFont text-truncate">
                          {item.title}
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                );
              } else {
                return null;
              }
            })}
          </div>

          {/* Pagination for published modules public profile medium size */}

          <div className="paginationTradingMedium middleVertical">
            <MDBRow>
              <MDBCol>
                <MDBPagination circle>
                  <MDBPageItem onClick={firstPaginationModules}>
                    <MDBPageNav className="page-link">
                      <span>First</span>
                    </MDBPageNav>
                  </MDBPageItem>
                  <MDBPageItem onClick={previousPaginationModules}>
                    <MDBPageNav className="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </MDBPageNav>
                  </MDBPageItem>
                  {[...Array(publicProfile.numberOfPages)].map(
                    (item, index) => {
                      if (index + 1 === publicProfile.pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    }
                  )}
                  <MDBPageItem onClick={nextPaginationModules}>
                    <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                  </MDBPageItem>
                  <MDBPageItem onClick={lastPaginationModules}>
                    <MDBPageNav className="page-link">Last</MDBPageNav>
                  </MDBPageItem>
                </MDBPagination>
              </MDBCol>
            </MDBRow>
          </div>

          {/* Pagination for published modules public profile small size*/}

          <div className="paginationTradingSmall middleVertical">
            <MDBRow>
              <MDBCol>
                <MDBPagination circle size="sm">
                  <MDBPageItem onClick={firstPaginationModules}>
                    <MDBPageNav className="page-link">
                      <span>First</span>
                    </MDBPageNav>
                  </MDBPageItem>
                  <MDBPageItem onClick={previousPaginationModules}>
                    <MDBPageNav className="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </MDBPageNav>
                  </MDBPageItem>
                  {[...Array(publicProfile.numberOfPages)].map(
                    (item, index) => {
                      if (index + 1 === publicProfile.pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === publicProfile.pageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    }
                  )}
                  <MDBPageItem onClick={nextPaginationModules}>
                    <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                  </MDBPageItem>
                  <MDBPageItem onClick={lastPaginationModules}>
                    <MDBPageNav className="page-link">Last</MDBPageNav>
                  </MDBPageItem>
                </MDBPagination>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCol>
      </MDBRow>
    </Fragment>
  );
};

PublicProfileView.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    followUser,
    unFollowUser,
    getFollowPublic,
    getPublicProfile,
    setLoadingButtonFollow
  })(PublicProfileView)
);
