import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import "./trading.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  publishLessonO,
  publishTopicO,
  publishExamO,
  publishGameO,
  publishDeckO,
  publishCurriculumO,
  setLoadingButtonPublish,
  clearPublishedModule
} from "../../actions/trading";

import {
  MDBCard,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSelect,
  MDBIcon
} from "mdbreact";

const Publish = ({
  history,
  mode,
  publishType,
  publishTypeFunc,
  publishLessonO,
  publishTopicO,
  publishExamO,
  publishGameO,
  publishDeckO,
  publishCurriculumO,
  setLoadingButtonPublish,
  clearPublishedModule,
  trading: { lesson, topic, exam, deck, game, curriculum, loadingButtonPublish }
}) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    _id: "",
    level: "",
    options: [
      {
        text: "A1",
        value: "A1"
      },
      {
        text: "A2",
        value: "A2"
      },
      {
        text: "B1",
        value: "B1"
      },
      {
        text: "B2",
        value: "B2"
      },
      {
        text: "C1",
        value: "C1"
      },
      {
        text: "C2",
        value: "C2"
      }
    ]
  });

  useEffect(() => {
    if (publishType) {
      setFormData(prevState => {
        return {
          ...prevState,
          title:
            publishType.type === "lesson" ||
            publishType.type === "topic" ||
            publishType.type === "game" ||
            publishType.type === "curriculum"
              ? publishType.item.title
              : publishType.type === "exam" || publishType.type === "deck"
              ? publishType.item.name
              : "",
          _id:
            publishType.type === "lesson" ||
            publishType.type === "topic" ||
            publishType.type === "exam" ||
            publishType.type === "game" ||
            publishType.type === "deck" ||
            publishType.type === "curriculum"
              ? publishType.item._id
              : "",
          description:
            publishType.type === "exam" || publishType.type === "deck"
              ? publishType.item.description
              : ""
        };
      });
    }
  }, [publishType]);

  const handleSelectLevel = value => {
    setFormData({
      ...formData,
      level: value[0]
    });
  };

  const onChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const publishModule = () => {
    if (publishType.type === "lesson") {
      setLoadingButtonPublish(true);
      publishLessonO({
        title: formData.title,
        _id: formData._id,
        description: formData.description,
        date: moment(new Date()).valueOf(),
        level: formData.level
      });
    } else if (publishType.type === "topic") {
      setLoadingButtonPublish(true);
      publishTopicO({
        title: formData.title,
        _id: formData._id,
        description: formData.description,
        date: moment(new Date()).valueOf()
      });
    } else if (publishType.type === "exam") {
      setLoadingButtonPublish(true);
      publishExamO({
        title: formData.title,
        _id: formData._id,
        description: formData.description,
        date: moment(new Date()).valueOf()
      });
    } else if (publishType.type === "game") {
      setLoadingButtonPublish(true);
      publishGameO({
        title: formData.title,
        _id: formData._id,
        description: formData.description,
        date: moment(new Date()).valueOf()
      });
    } else if (publishType.type === "deck") {
      setLoadingButtonPublish(true);
      publishDeckO({
        title: formData.title,
        _id: formData._id,
        description: formData.description,
        date: moment(new Date()).valueOf()
      });
    } else if (publishType.type === "curriculum") {
      setLoadingButtonPublish(true);
      publishCurriculumO({
        title: formData.title,
        _id: formData._id,
        description: formData.description,
        date: moment(new Date()).valueOf(),
        level: formData.level
      });
    }
  };

  useEffect(() => {
    if (lesson || topic || exam || deck || game || curriculum) {
      setFormData(prevState => {
        const newArray = prevState.options.map((item, index) => {
          return {
            ...item,
            checked: false
          };
        });
        return {
          ...prevState,
          title: "",
          description: "",
          _id: "",
          level: "",
          options: newArray
        };
      });

      if (lesson) {
        history.push(`/trading/lesson/${lesson._id}`);
        clearPublishedModule();
      }
      if (topic) {
        history.push(`/trading/topic/${topic._id}`);
        clearPublishedModule();
      }
      if (exam) {
        history.push(`/trading/exam/${exam._id}`);
        clearPublishedModule();
      }
      if (deck) {
        history.push(`/trading/deck/${deck._id}`);
        clearPublishedModule();
      }
      if (game) {
        history.push(`/trading/game/${game._id}`);
        clearPublishedModule();
      }
      if (curriculum) {
        history.push(`/trading/curriculum/${curriculum._id}`);
        clearPublishedModule();
      }
    }
  }, [
    lesson,
    topic,
    exam,
    deck,
    game,
    curriculum,
    clearPublishedModule,
    history
  ]);

  return (
    <Fragment>
      <MDBRow center>
        <p className="quickSand mt-3 duruSans h5 text-center text-white">
          Publish Module
        </p>
      </MDBRow>
      <MDBRow className="mx-3">
        <MDBCol>
          <MDBInput
            className="white-text"
            labelClass="text-white"
            label="Title"
            value={formData.title}
            onChange={onChange}
            name="title"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mx-3">
        <MDBCol>
          <MDBInput
            className="white-text"
            labelClass="text-white"
            type="textarea"
            label="Description - 130 character limit"
            maxLength="130"
            rows="3"
            name="description"
            value={formData.description}
            onChange={onChange}
          />
        </MDBCol>
      </MDBRow>
      {publishType.type === "lesson" || publishType.type === "curriculum" ? (
        <MDBRow center>
          <MDBSelect
            options={formData.options}
            selected="Choose a level"
            // label="Choose a level"
            selectAll
            getValue={handleSelectLevel}
          />
        </MDBRow>
      ) : null}

      <MDBRow center>
        <MDBCol size="8">
          {publishType.type === "lesson" ? (
            <div>
              <p className="text-center text-info h5">Lesson:</p>
              <MDBCard>
                <p className="text-center tradingColor h3 quickSand">
                  {publishType.item.title}
                </p>
              </MDBCard>
            </div>
          ) : null}

          {publishType.type === "topic" ? (
            <div>
              <p className="text-center text-info h5">Topic:</p>
              <MDBCard>
                <p className="text-center tradingColor h3 quickSand">
                  {publishType.item.title}
                </p>
              </MDBCard>
            </div>
          ) : null}
          {publishType.type === "exam" ? (
            <div>
              <p className="text-center text-info h5">Exam:</p>
              <MDBCard>
                <p className="text-center tradingColor h3 quickSand">
                  {publishType.item.name}
                </p>
              </MDBCard>
            </div>
          ) : null}

          {publishType.type === "game" ? (
            <div>
              <p className="text-center text-info h5">Game:</p>
              <MDBCard>
                <p className="text-center tradingColor h3 quickSand">
                  {publishType.item.title}
                </p>
              </MDBCard>
            </div>
          ) : null}

          {publishType.type === "deck" ? (
            <div>
              <p className="text-center text-info h5">Deck:</p>
              <MDBCard>
                <p className="text-center tradingColor h3 quickSand">
                  {publishType.item.name}
                </p>
              </MDBCard>
            </div>
          ) : null}

          {publishType.type === "curriculum" ? (
            <div>
              <p className="text-center text-info h5">Curriculum:</p>
              <MDBCard>
                <p className="text-center tradingColor h3 quickSand">
                  {publishType.item.title}
                </p>
              </MDBCard>
            </div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3 mb-3" center>
        <MDBBtn
          disabled={loadingButtonPublish}
          onClick={publishModule}
          color="purple"
        >
          {loadingButtonPublish === true && (
            <div className="spinner-grow spinner-grow-sm" />
          )}
          Publish
        </MDBBtn>
      </MDBRow>
      <MDBRow className="middleVertical ml-1">
        <p className="ptsansText black-text asterixFont">
          <MDBIcon icon="asterisk" size="sm" className="mr-2" />
          Be sure to revise all material before publishing to the trading system
          as you will not be able to edit it once it is published.
        </p>
      </MDBRow>
    </Fragment>
  );
};

Publish.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    publishLessonO,
    publishTopicO,
    publishExamO,
    publishGameO,
    publishDeckO,
    publishCurriculumO,
    setLoadingButtonPublish,
    clearPublishedModule
  })(Publish)
);
