import {
  SEND_NOTIFICATION,
  NOTIFICATION_ERROR,
  SET_LOADING_BUTTON_NOTIFICATIONS,
  DEFERRED_PROMPT,
  LOGOUT
} from "../actions/types";

const initialState = {
  notification: "",
  loadingButtonNotifications: false,
  deferredPrompt: "",
  subscriptionResponse: "",
  loadingButtonSubscription: false
};
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEND_NOTIFICATION:
      return {
        ...state,
        notification: payload,
        loadingButtonLessons: false
      };
    case NOTIFICATION_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonLessons: false
      };
    case SET_LOADING_BUTTON_NOTIFICATIONS:
      return {
        ...state,
        loadingButtonNotifications: payload
      };
    case DEFERRED_PROMPT:
      return {
        ...state,
        deferredPrompt: payload
      };
    case LOGOUT:
      return {
        notification: "",
        loadingButtonNotifications: false,
        deferredPrompt: "",
        subscriptionResponse: "",
        loadingButtonSubscription: false
      };
    default:
      return state;
  }
}
