import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./assets/scss/mdb.scss";
import "./components/lessonPlans/lessons.css";
import App from "./App";
import Spinner from "./components/layout/Spinner";

import "./i18next";

ReactDOM.render(
  <Suspense fallback={<Spinner></Spinner>}>
    <App />
  </Suspense>,
  document.getElementById("root")
);
