import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./PublicPage.css";
import { useSpring, animated } from "react-spring";
import { Helmet } from "react-helmet";

import moment from "moment-timezone";
import urlParser from "js-video-url-parser";
import NumericInput from "react-numeric-input";
import StudentRequests from "./StudentRequests";
import CountryData from "./CountryData.json";
import validator from "validator";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { withRouter, Link } from "react-router-dom";
import { extendMoment } from "moment-range";
import concat from "lodash/concat";
import uuid from "uuid/v4";
import stripHtml from "string-strip-html";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from "react-share";

import {
  base64StringtoFile,
  extractImageFileExtensionFromBase64
} from "../Profile/imageUtils";

import {
  createPublicPage,
  getPublicPage,
  updatePublicPage,
  setLoadingButtonPublicPage,
  setLoadingButtonDeactivate,
  deactivatePublicPage,
  activatePublicPage
} from "../../actions/publicpage";

import { getLessonsCalendar } from "../../actions/lessons";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBFileInput,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInputGroup,
  MDBSelect,
  MDBScrollbar,
  MDBJumbotron
} from "mdbreact";

const momentRange = extendMoment(moment);

const localizer = momentLocalizer(moment);

const PublicPage = ({
  createPublicPage,
  getPublicPage,
  updatePublicPage,
  deactivatePublicPage,
  activatePublicPage,
  setLoadingButtonPublicPage,
  setLoadingButtonDeactivate,
  publicpage: {
    publicPage,
    publicPageExist,
    loadingButtonPublicPage,
    loadingButtonDeactivate,
    newPublicPage
  },
  auth: { user },
  getLessonsCalendar,
  lessons: { calendarLessons },
  location,
  history
}) => {
  const [formData, setFormData] = useState({
    events: [],
    mode: "publicPage",
    countryOptions: CountryData,
    currencyOptions: [
      {
        text: "EUR",
        value: "EUR"
      },
      {
        text: "USD",
        value: "USD"
      },
      {
        text: "MXN",
        value: "MXN"
      },
      {
        text: "BRL",
        value: "BRL"
      },
      {
        text: "IDR",
        value: "IDR"
      },
      {
        text: "TRY",
        value: "TRY"
      },
      {
        text: "INR",
        value: "INR"
      },
      {
        text: "CAD",
        value: "CAD"
      }
    ],
    teachingOptions: [
      {
        text: "Online",
        value: "Online"
      },
      {
        text: "In-Person",
        value: "In-Person"
      }
    ],
    levelOptions: [
      {
        text: "A1",
        value: "A1"
      },
      {
        text: "A2",
        value: "A2"
      },
      {
        text: "B1",
        value: "B1"
      },
      {
        text: "B2",
        value: "B2"
      },
      {
        text: "C1",
        value: "C1"
      },
      {
        text: "C2",
        value: "C2"
      }
    ],
    degreeLevels: [
      {
        text: "Doctorate",
        value: "Doctorate"
      },
      {
        text: "Master's Degree",
        value: "Master"
      },
      {
        text: "Bachelor’s degree",
        value: "Bachelor"
      },
      {
        text: "Trade/technical/vocational training",
        value: "Trade"
      },
      {
        text: "College Degree",
        value: "College"
      },
      {
        text: "High School Diploma",
        value: "High"
      }
    ],
    newPublicPage: {
      name: "",
      country: "",
      city: "",
      avatar: {
        document_id: "",
        document_type: "",
        url: ""
      },
      youtube: "",
      linkedIn: "",
      instagram: "",
      twitter: "",
      facebook: "",
      events: [],
      rate: 0,
      currency: "",
      levels: [],
      degree: "",
      years: 0,
      certification: "",
      methods: [],
      lastExperience: "",
      uniqueURL: "",
      availability: [],
      activatePublicPage: ""
    },
    document: "",
    croppedDocument: "",
    crop: {
      unit: "%",
      width: 50,
      aspect: 1 / 1
    },

    imgSrc: "",
    croppedBase64: "",
    modalUpdateAvatar: false,
    uniqueURL: "",
    activatePublicPage: false,
    scrollToDate: new Date().setHours(8, 0, 0),
    calendarLessons: [],
    availabilitySelect: {
      start: "",
      end: ""
    },
    modalConfigureAvailabilitySelect: false,
    modalSeeLesson: false,
    intervalOptions: [
      {
        text: "30 mins",
        value: 30
      },
      {
        text: "45 mins",
        value: 45
      },
      {
        text: "60 mins",
        value: 60
      },
      {
        text: "90 mins",
        value: 90
      },
      {
        text: "120 mins",
        value: 120
      }
    ],
    selectedInterval: "",
    combinedArray: [],
    viewLesson: {
      title: "",
      comments: ""
    },
    bottomShow: false
  });

  const [formData2, setFormData2] = useState({
    description: ""
  });

  const cropImageRef = useRef(null);

  const getCurrencyOptions = value => {
    const find = formData.currencyOptions.find(item => item.checked === true);
    setFormData({
      ...formData,
      newPublicPage: {
        ...formData.newPublicPage,
        currency: find ? find.value : ""
      }
    });
  };

  const setHourlyRateNumber = value => {
    if (value === null) {
      setFormData({
        ...formData,
        newPublicPage: {
          ...formData.newPublicPage,
          rate: ""
        }
      });
    } else {
      let q = Math.floor(value);
      setFormData({
        ...formData,
        newPublicPage: {
          ...formData.newPublicPage,
          rate: q
        }
      });
    }
  };

  const formatHourlyRate = o => {
    let q = o;

    q = Math.floor(o);

    return q;
  };

  const formatYearsOfExperience = o => {
    let q = o;

    q = Math.floor(o);

    return q;
  };

  const handleSelectChangeEnglishLevel = value => {
    setFormData({
      ...formData,
      newPublicPage: {
        ...formData.newPublicPage,
        levels: value
      }
    });
  };

  const handleSelectChangeDegree = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        newPublicPage: {
          ...formData.newPublicPage,
          degree: value[0]
        }
      });
    }
  };

  const handleSelectChangeTeaching = value => {
    setFormData({
      ...formData,
      newPublicPage: {
        ...formData.newPublicPage,
        methods: value
      }
    });
  };

  const handleSelectChangeCountry = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        newPublicPage: {
          ...formData.newPublicPage,
          country: value[0]
        }
      });
    }
  };

  const setYearsOfExperience = value => {
    if (value === null) {
      setFormData({
        ...formData,
        newPublicPage: {
          ...formData.newPublicPage,
          years: ""
        }
      });
    } else {
      let q = Math.floor(value);
      setFormData({
        ...formData,
        newPublicPage: {
          ...formData.newPublicPage,
          years: q
        }
      });
    }
  };

  const onChange = e =>
    setFormData({
      ...formData,
      newPublicPage: {
        ...formData.newPublicPage,
        [e.target.name]: e.target.value
      }
    });

  const onChange2 = (content, delta, source, editor) => {
    setFormData2({
      ...formData2,
      description: content
    });
  };

  const handleChangeYoutube = e => {
    setFormData({
      ...formData,
      newPublicPage: {
        ...formData.newPublicPage,
        [e.target.name]: e.target.value
      }
    });
  };

  const embedYoutube = () => {
    const emb = urlParser.parse(formData.newPublicPage.youtube);
    if (typeof emb === "undefined") {
      return (
        <MDBCol md="6" className="mb-4">
          <p>Please add a valid Youtube Link</p>
        </MDBCol>
      );
    } else {
      const embUrl = urlParser.create({
        videoInfo: emb,
        format: "embed"
      });

      return (
        <MDBCol md="6" className="mb-4">
          <div className="embed-responsive embed-responsive-16by9 mb-1">
            <iframe
              title="Embeds Page"
              className="embed-responsive-item"
              src={embUrl}
              allowFullScreen
            />
          </div>
        </MDBCol>
      );
    }
  };

  const switchMode = mode => {
    setFormData({
      ...formData,
      mode: mode
    });
  };

  const bottomShowFunc = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        bottomShow: !prevState.bottomShow
      };
    });
  };

  const onSubmit = () => {
    if (publicPage) {
      if (formData.croppedBase64 === "") {
        const newArray = formData.newPublicPage.availability.map(
          (item, index) => {
            const start1 = moment(item.start, "x").format("YYYY MM DD hh:mm a");

            const start2 = moment
              .tz(start1, "YYYY MM DD hh:mm a", user.defaultTimezone)
              .valueOf();
            const end1 = moment(item.end, "x").format("YYYY MM DD hh:mm a");

            const end2 = moment
              .tz(end1, "YYYY MM DD hh:mm a", user.defaultTimezone)
              .valueOf();

            return {
              ...item,
              start: start2,
              end: end2
            };
          }
        );
        setLoadingButtonPublicPage(true);
        updatePublicPage({
          ...formData.newPublicPage,
          avatar: "",
          description: stripHtml(formData2.description),
          availability: newArray
        });
      } else if (formData.croppedBase64 !== "") {
        const newArray = formData.newPublicPage.availability.map(
          (item, index) => {
            const start1 = moment(item.start, "x").format("YYYY MM DD hh:mm a");

            const start2 = moment
              .tz(start1, "YYYY MM DD hh:mm a", user.defaultTimezone)
              .valueOf();
            const end1 = moment(item.end, "x").format("YYYY MM DD hh:mm a");

            const end2 = moment
              .tz(end1, "YYYY MM DD hh:mm a", user.defaultTimezone)
              .valueOf();

            return {
              ...item,
              start: start2,
              end: end2
            };
          }
        );
        setLoadingButtonPublicPage(true);
        updatePublicPage({
          ...formData.newPublicPage,
          description: stripHtml(formData2.description),
          availability: newArray
        });
      }
    } else if (formData.newPublicPage.avatar.url === "") {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>You must select an avatar for your profile</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      const newArray = formData.newPublicPage.availability.map(
        (item, index) => {
          const start1 = moment(item.start, "x").format("YYYY MM DD hh:mm a");

          const start2 = moment
            .tz(start1, "YYYY MM DD hh:mm a", user.defaultTimezone)
            .valueOf();
          const end1 = moment(item.end, "x").format("YYYY MM DD hh:mm a");

          const end2 = moment
            .tz(end1, "YYYY MM DD hh:mm a", user.defaultTimezone)
            .valueOf();

          return {
            ...item,
            start: start2,
            end: end2
          };
        }
      );
      setLoadingButtonPublicPage(true);
      createPublicPage({
        ...formData.newPublicPage,
        description: stripHtml(formData2.description),
        uniqueURL: formData.uniqueURL,
        activatePublicPage: true,
        availability: newArray
      });
    }
  };

  useEffect(() => {
    if (publicPage) {
      setFormData(prevState => {
        const newArray = prevState.countryOptions.map((item, index) => {
          if (item.value === publicPage.country) {
            return {
              ...item,
              checked: true
            };
          } else {
            return {
              ...item,
              checked: false
            };
          }
        });
        const newArray2 = prevState.levelOptions.map((item, index) => {
          const curr = publicPage.levels.filter(value => value === item.value);
          if (curr.length !== 0) {
            return { ...item, checked: true };
          } else {
            return item;
          }
        });
        const newArray3 = prevState.degreeLevels.map((item, index) => {
          if (item.value === publicPage.degree) {
            return {
              ...item,
              checked: true
            };
          } else {
            return {
              ...item,
              checked: false
            };
          }
        });
        const newArray4 = prevState.currencyOptions.map((item, index) => {
          if (item.value === publicPage.currency) {
            return {
              ...item,
              checked: true
            };
          } else {
            return {
              ...item,
              checked: false
            };
          }
        });
        const newArray5 = prevState.teachingOptions.map((item, index) => {
          const curr = publicPage.methods.filter(value => value === item.value);
          if (curr.length !== 0) {
            return { ...item, checked: true };
          } else {
            return item;
          }
        });

        const availabilityArray = publicPage.availability.map((item, index) => {
          const start1 = moment(item.start, "x")
            .tz(user.defaultTimezone)
            .format("YYYY MM DD hh:mm a");
          const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();
          const end1 = moment(item.end, "x")
            .tz(user.defaultTimezone)
            .format("YYYY MM DD hh:mm a");
          const end2 = moment(end1, "YYYY MM DD hh:mm a").toDate();

          return {
            ...item,
            start: start2,
            end: end2,
            uniqueId: uuid()
          };
        });

        const lessonsArray = calendarLessons.map((item, index) => {
          const start1 = moment(item.start, "x")
            .tz(user.defaultTimezone)
            .format("YYYY MM DD hh:mm a");
          const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();
          const end1 = moment(item.end, "x")
            .tz(user.defaultTimezone)
            .format("YYYY MM DD hh:mm a");
          const end2 = moment(end1, "YYYY MM DD hh:mm a").toDate();

          return {
            start: start2,
            end: end2,
            title: "Lesson",
            _id: item._id,
            uniqueId: uuid(),
            comments: `${stripHtml(item.comments)}`
          };
        });

        const combinedArray = concat(availabilityArray, lessonsArray);
        return {
          ...prevState,
          newPublicPage: {
            ...publicPage,
            availability: availabilityArray
          },
          countryOptions: newArray,
          levelOptions: newArray2,
          degreeLevels: newArray3,
          currencyOptions: newArray4,
          teachingOptions: newArray5,
          modalUpdateAvatar: false,
          combinedArray: combinedArray
        };
      });

      setFormData2(prevState => {
        return {
          ...prevState,
          description: publicPage.description
        };
      });
    }
  }, [publicPage, user.defaultTimezone, calendarLessons]);

  useEffect(() => {
    getPublicPage();
    getLessonsCalendar();
  }, [getPublicPage, getLessonsCalendar]);

  const toggleUpdateAvatar = () => {
    setFormData({
      ...formData,
      modalUpdateAvatar: !formData.modalUpdateAvatar
    });
  };

  const toggleCloseLesson = () => {
    setFormData({
      ...formData,
      modalSeeLesson: false
    });
  };

  const fileInputHandler = document => {
    if (document) {
      const currentImage = document[0];
      if (
        currentImage.type === "image/png" ||
        currentImage.type === "image/jpeg" ||
        currentImage.type === "image/jpg" ||
        currentImage.type === "image/x-png" ||
        currentImage.type === "image/gif" ||
        currentImage.type === "image/svg+xml"
      ) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            setFormData({
              ...formData,
              imgSrc: reader.result,
              document: document[0],
              newPublicPage: {
                ...formData.newPublicPage,
                avatar: document[0]
              }
            });
          },
          false
        );
        reader.readAsDataURL(currentImage);
      } else {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="times" />
                  <p>
                    Invalid image file. Please choose an image. Jpeg, Jpg, Gif
                    and PNG are accepted.
                  </p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      }
    }
  };

  const submitAvatar = async () => {
    const fileExtension = await extractImageFileExtensionFromBase64(
      formData.croppedBase64
    );
    const myFilename = "newAvatar." + fileExtension;

    const myNewCroppedFile = await base64StringtoFile(
      formData.croppedBase64,
      myFilename
    );
    if (myNewCroppedFile.size > 10000000) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>Your file exceeds 10Mb. Please select a smaller image</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      setFormData({
        ...formData,
        newPublicPage: {
          ...formData.newPublicPage,
          avatar: myNewCroppedFile
        },
        modalUpdateAvatar: !formData.modalUpdateAvatar
      });
    }
  };

  const cropFunc = async () => {
    const imageData64 = await cropImageRef.current
      .getCroppedCanvas()
      .toDataURL();

    await setFormData(prevState => {
      return {
        ...prevState,
        croppedBase64: imageData64
      };
    });
  };

  const onChangeUrl = e => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const getDefaultDate = () => {
    const newDate = moment(new Date()).valueOf();
    const start1 = moment(newDate, "x")
      .tz(user.defaultTimezone)
      .format("YYYY MM DD hh:mm a");

    const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();

    return start2;
  };

  const formats = {
    // dateFormat: "dd",
    timeGutterFormat: "h a"
  };

  const selectDisable = value => {
    const start1 = moment(value.start).format("YYYY MM DD hh:mm a");

    const start2 = moment
      .tz(start1, "YYYY MM DD hh:mm a", user.defaultTimezone)
      .valueOf();
    const end1 = moment(value.end).format("YYYY MM DD hh:mm a");

    const end2 = moment
      .tz(end1, "YYYY MM DD hh:mm a", user.defaultTimezone)
      .valueOf();

    const newArray = formData.combinedArray.map((item, index) => {
      const itemStart1 = moment(item.start).format("YYYY MM DD hh:mm a");

      const itemStart2 = moment
        .tz(itemStart1, "YYYY MM DD hh:mm a", user.defaultTimezone)
        .valueOf();
      const itemEnd1 = moment(item.end).format("YYYY MM DD hh:mm a");

      const itemEnd2 = moment
        .tz(itemEnd1, "YYYY MM DD hh:mm a", user.defaultTimezone)
        .valueOf();
      const range1 = momentRange.range(start2, end2);
      const range2 = momentRange.range(itemStart2, itemEnd2);

      const see = range1.overlaps(range2);
      return see;
    });

    const find = newArray.find(item => item === true);

    if (find) {
      return false;
    } else if (start2 < moment().valueOf()) {
      return false;
    } else {
      return true;
    }
  };

  const handleSelect = ({ start, end, type }) => {
    const start1 = moment(start).format("YYYY MM DD hh:mm a");

    const start2 = moment
      .tz(start1, "YYYY MM DD hh:mm a", user.defaultTimezone)
      .valueOf();
    const end1 = moment(end).format("YYYY MM DD hh:mm a");

    const end2 = moment
      .tz(end1, "YYYY MM DD hh:mm a", user.defaultTimezone)
      .valueOf();
    const difference = end2 - start2;

    const newArray = formData.intervalOptions.map((item, index) => {
      const numberOfSlots = difference / (item.value * 60000);
      const isIntcheck = validator.isInt(numberOfSlots.toString());
      if (numberOfSlots < 1) {
        return {
          ...item,
          disabled: true,
          checked: false
        };
      } else if (isIntcheck === false) {
        return {
          ...item,
          disabled: true,
          checked: false
        };
      } else {
        return { ...item, disabled: false, checked: false };
      }
    });

    setFormData({
      ...formData,

      modalConfigureAvailabilitySelect: true,
      availabilitySelect: {
        ...formData.availabilitySelect,
        start: start2,
        end: end2
      },
      intervalOptions: newArray
    });
  };

  const closeAvailabilityModal = () => {
    setFormData({
      ...formData,

      modalConfigureAvailabilitySelect: false,
      availabilitySelect: {
        ...formData.availabilitySelect,
        start: "",
        end: ""
      }
    });
  };

  const selectInterval = value => {
    if (value.length) {
      setFormData({
        ...formData,
        selectedInterval: value[0]
      });
    }
  };

  const submitSelection = () => {
    if (formData.selectedInterval) {
      const difference =
        formData.availabilitySelect.end - formData.availabilitySelect.start;
      const numberOfSlots = difference / (formData.selectedInterval * 60000);

      const newArray = [...Array(numberOfSlots)].map((item, index) => {
        const start =
          formData.availabilitySelect.start +
          index * (formData.selectedInterval * 60000);
        const end =
          formData.availabilitySelect.start +
          (index + 1) * (formData.selectedInterval * 60000);

        const start1 = moment(start, "x")
          .tz(user.defaultTimezone)
          .format("YYYY MM DD hh:mm a");
        const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();
        const end1 = moment(end, "x")
          .tz(user.defaultTimezone)
          .format("YYYY MM DD hh:mm a");
        const end2 = moment(end1, "YYYY MM DD hh:mm a").toDate();
        return {
          start: start2,
          end: end2,
          title: "Available",
          uniqueId: uuid()
        };
      });

      const combinedAvailability = concat(
        formData.newPublicPage.availability,
        newArray
      );

      const combinedArray = concat(formData.combinedArray, newArray);

      setFormData({
        ...formData,
        newPublicPage: {
          ...formData.newPublicPage,
          availability: combinedAvailability
        },
        combinedArray: combinedArray,
        modalConfigureAvailabilitySelect: false,
        selectedInterval: ""
      });
    }
  };

  const handleEvent = event => {
    if (event.title === "Available") {
      const filter = formData.combinedArray.filter(
        item => item.uniqueId !== event.uniqueId
      );
      const filter2 = formData.newPublicPage.availability.filter(
        item => item.uniqueId !== event.uniqueId
      );
      setFormData({
        ...formData,
        combinedArray: filter,
        newPublicPage: {
          ...formData.newPublicPage,
          availability: filter2
        }
      });
    } else if (event.title === "Pending") {
      return null;
      // const newArray = calendarLessons.map((item, index) => {
      //   return (
      //     <MDBPopover placement='right' popover clickable id='popper2'>
      //       <div>
      //         <MDBPopoverHeader>{item.title}</MDBPopoverHeader>
      //         <MDBPopoverBody>{item.objective}</MDBPopoverBody>
      //       </div>
      //     </MDBPopover>
      //   );
      // });
    } else {
      setFormData({
        ...formData,
        modalSeeLesson: true,
        viewLesson: event
      });
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    if (event.title === "Available") {
      let newStyle = {
        backgroundColor: "#aed581 ",
        color: "black",
        borderRadius: "0px",
        fontFamily: "quickSand",
        borderColor: "black"
      };
      return {
        style: newStyle
      };
    } else if (event.title === "Pending") {
      let newStyle = {
        backgroundColor: "#CC0000 ",
        color: "white",
        borderRadius: "0px",
        fontFamily: "quickSand",
        borderColor: "black"
      };
      return {
        style: newStyle
      };
    } else {
      let newStyle = {
        backgroundColor: "#9c45eb",
        color: "white",
        borderRadius: "0px",
        fontFamily: "quickSand",
        borderColor: "black"
      };
      return {
        style: newStyle
      };
    }
  };

  const activatePublicPageFunc = () => {
    setLoadingButtonDeactivate(true);
    activatePublicPage();
  };

  const deactivatePublicPageFunc = () => {
    setLoadingButtonDeactivate(true);
    deactivatePublicPage();
  };

  const props2 = useSpring({
    opacity:
      formData.bottomShow === false
        ? 0
        : 1 || formData.bottomShow === true
        ? 1
        : 0,

    config: { duration: 600 }
  });

  // Share public page

  const shareUrl = `topscholar.io/tutors/${formData.newPublicPage.uniqueURL}`;
  const title = "Reserve a tutoring session with me!";
  const title2 = `Reserve a tutoring session with me! topscholar.io/tutors/${formData.newPublicPage.uniqueURL}`;
  const twitterAccount = "Topscholarapp";

  useEffect(() => {
    if (location.state) {
      if (location.state.studentRequests) {
        const switchMode2 = mode => {
          setFormData(prevState => {
            return {
              ...prevState,
              mode: mode
            };
          });
        };
        switchMode2("studentRequests");
        history.replace();
      }
    }
  }, [location, history]);

  return (
    // Public Page Information
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Public Page</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="publicPageBackground">
        {formData.mode === "studentRequests" ? (
          <StudentRequests switchMode={switchMode}></StudentRequests>
        ) : formData.newPublicPage.activatePublicPage === false ? (
          <Fragment>
            <MDBContainer className="mt-5 text-center">
              <MDBRow>
                <MDBCol
                  md="10"
                  lg="10"
                  xl="10"
                  sm="12"
                  className="mt-5 mx-auto"
                >
                  <MDBJumbotron className="mt-5">
                    <MDBCardBody color="blue">
                      <MDBRow center mt-5>
                        <p className="quickSand h3">
                          Your Public Page is currently deactivated. Click the
                          button below to reactivate it.
                        </p>
                      </MDBRow>
                      <MDBRow center>
                        <MDBBtn
                          disabled={loadingButtonDeactivate}
                          onClick={activatePublicPageFunc}
                          color="danger"
                        >
                          {loadingButtonDeactivate === true && (
                            <div className="spinner-grow spinner-grow-sm" />
                          )}
                          Reactivate Public Page
                        </MDBBtn>

                        <p className="quickSand mt-2 text-center">
                          It might take up to 24 hours to remove your public
                          page from find tutors
                        </p>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBJumbotron>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </Fragment>
        ) : (
          <Fragment>
            <MDBRow className="mx-1 mt-5 justify-content-start">
              <MDBCol lg="5" md="5" sm="12" className="mb-2 col-12">
                <MDBRow center className="mt-4 pt-5 mb-3 ml-1">
                  <MDBCol className="middleVertical col-12 ">
                    {formData.newPublicPage.activatePublicPage === true ? (
                      <div className="publicPageButton2 mb-3">
                        <MDBBtn
                          color="unique"
                          size="sm"
                          rounded
                          onClick={() => switchMode("studentRequests")}
                        >
                          Student Requests
                        </MDBBtn>
                      </div>
                    ) : null}

                    <Link
                      className={
                        formData.newPublicPage.activatePublicPage === true
                          ? "mb-3 publicPageButton2"
                          : "d-none"
                      }
                      to={{
                        pathname: `/tutors/${formData.newPublicPage.uniqueURL}`
                      }}
                      target="_blank"
                    >
                      <MDBBtn color="mdb-color" size="sm" rounded>
                        {publicPageExist === true ? "See Public Page" : null}
                        See Public Page
                      </MDBBtn>
                    </Link>
                  </MDBCol>
                  <MDBCol className="middleVertical col-12">
                    <p className="livvicFont h3 text-black justify-content-center mr-2">
                      Public Page Information
                    </p>{" "}
                    <MDBIcon
                      style={{ cursor: "pointer" }}
                      className="color1 hideBottom"
                      onClick={bottomShowFunc}
                      icon="info-circle"
                    ></MDBIcon>
                  </MDBCol>
                </MDBRow>
                <MDBCard
                  className="publicPageCard socialPublicPageCard mb-3"
                  style={{ height: "auto" }}
                >
                  {formData.croppedBase64 !== "" ? (
                    <MDBRow center className="my-3 justify-content-around">
                      <MDBCol sm="12" className="col-12">
                        <div className="text-center">
                          <img
                            style={{ width: "175px" }}
                            src={formData.croppedBase64}
                            alt=""
                            className="rounded-circle z-depth-1-half "
                          />
                        </div>
                        <div>
                          <p
                            style={{ cursor: "pointer" }}
                            className="quickSand publicPageIconColor mt-3 text-center"
                            onClick={toggleUpdateAvatar}
                          >
                            Change Avatar
                          </p>
                        </div>
                      </MDBCol>
                      <MDBCol sm="4" className="col-4"></MDBCol>
                    </MDBRow>
                  ) : formData.newPublicPage.avatar.url ? (
                    <MDBRow center className="my-3 justify-content-around">
                      <MDBCol sm="12" className="col-12">
                        <div className="text-center">
                          <img
                            style={{ width: "175px" }}
                            src={formData.newPublicPage.avatar.url}
                            alt=""
                            className="rounded-circle z-depth-1-half "
                          />
                        </div>
                        <div>
                          <p
                            style={{ cursor: "pointer" }}
                            className="quickSand publicPageIconColor mt-3 text-center"
                            onClick={toggleUpdateAvatar}
                          >
                            Change Avatar
                          </p>
                        </div>
                      </MDBCol>
                      <MDBCol sm="4" className="col-4"></MDBCol>
                    </MDBRow>
                  ) : (
                    <MDBRow className="mx-2 my-3 ">
                      <MDBCol md="12" lg="12" className="col-12 middleVertical">
                        <MDBCard
                          className="pictureCard middleVertical publicPageHover"
                          style={{ cursor: "pointer" }}
                          onClick={toggleUpdateAvatar}
                        >
                          <MDBRow center>
                            <MDBIcon far size="lg" icon="image" />{" "}
                          </MDBRow>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  )}

                  <MDBCardBody className="pt-0 mt-0">
                    <MDBCol className="mr-auto text-center">
                      <MDBInput
                        hint="Your Name"
                        name="name"
                        type="text"
                        className="quickSand text-black borderFilterPublicPage hintColor"
                        value={formData.newPublicPage.name}
                        onChange={e => onChange(e)}
                        required
                      />
                    </MDBCol>
                    <MDBCol className=" text-center ">
                      <MDBRow center>
                        <MDBCol sm="5">
                          <MDBInput
                            type="text"
                            name="city"
                            value={formData.newPublicPage.city}
                            onChange={e => onChange(e)}
                            hint="City"
                            required
                            className="quickSand text-black borderFilterPublicPage hintColor"
                          />
                        </MDBCol>
                        <MDBCol sm="7" className="countrySelect2">
                          <div className="countrySelect">
                            <MDBSelect
                              label="Choose Country"
                              selected="Choose your country"
                              className="text-black countrySelect quickSand"
                              labelClass="countrySelect"
                              options={formData.countryOptions}
                              getValue={handleSelectChangeCountry}
                              search
                            />
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol className="mr-auto text-center">
                      <ReactQuill
                        className="quillPublicPage hintColor"
                        theme="bubble"
                        name="description"
                        placeholder="Your Description. max 400 characters"
                        onChange={onChange2}
                        value={formData2.description}
                      />
                    </MDBCol>
                    <div
                      className={
                        formData.newPublicPage.uniqueURL === "" ? "" : "d-none"
                      }
                    >
                      <MDBCol
                        sm="12"
                        className="mr-auto text-center mt-1 col-12"
                      >
                        <MDBRow>
                          <p className="quickSand">
                            Please enter your unique URL. Must contain only
                            alphanumeric characters. You will only be able to
                            choose this one time.
                          </p>
                        </MDBRow>
                        <MDBRow center className="middleVertical">
                          <MDBInputGroup
                            material
                            labelClassName="mb-0 ml-2 quickSand"
                            containerClassName="mb-3 mt-0 quickSand"
                            prepend="topscholar.io/tutors/"
                            id="uniqueURL"
                            value={formData.uniqueURL}
                            onChange={onChangeUrl}
                          />
                        </MDBRow>
                      </MDBCol>
                    </div>
                    <div
                      className={
                        formData.newPublicPage.uniqueURL !== "" ? "" : "d-none"
                      }
                    >
                      <MDBCol
                        sm="12"
                        className="mr-auto text-center mt-1 col-12"
                      >
                        <MDBRow center className="middleVertical mb-1">
                          <MDBCol>
                            <p className="quickSand h5 mt-2">
                              <MDBIcon
                                icon="link"
                                className="uniqueText mr-2"
                              />
                              topscholar.io/tutors/
                              {formData.newPublicPage.uniqueURL}
                            </p>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow center>
                          <div
                            className="mr-3 growMessage2"
                            style={{ cursor: "pointer" }}
                          >
                            <FacebookShareButton url={shareUrl} quote={title}>
                              <FacebookIcon size={40} round />
                            </FacebookShareButton>
                          </div>
                          <div
                            className="mr-3 growMessage2"
                            style={{ cursor: "pointer" }}
                          >
                            <LinkedinShareButton url={shareUrl}>
                              <LinkedinIcon size={40} round />
                            </LinkedinShareButton>
                          </div>
                          <div
                            className="mr-4 growMessage2"
                            style={{ cursor: "pointer" }}
                          >
                            <TwitterShareButton
                              url={shareUrl}
                              title={title2}
                              via={twitterAccount}
                            >
                              <TwitterIcon size={40} round />
                            </TwitterShareButton>
                          </div>
                          <div className="mt-1">
                            <MDBIcon
                              icon="share"
                              className="uniqueText shareIcon"
                            />
                          </div>
                        </MDBRow>
                      </MDBCol>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              {/* Availabilities */}

              <MDBCol lg="7" md="7" sm="12" className="mb-1 col-12 text-center">
                <MDBRow className="mt-4 pt-5 mb-3 mr-1 middleVertical">
                  <MDBCol sm="6" className="col-12">
                    <p className="livvicFont h3 text-black ml-3">
                      Your Availabilities
                    </p>
                    <MDBRow center>
                      <p className="quickSand mx-1">
                        <MDBIcon className="green-text" icon="square" /> :
                        Available
                      </p>
                      <p className="quickSand mx-1">
                        <MDBIcon className="red-text" icon="square" /> : Pending
                      </p>
                      <p className="quickSand mx-1">
                        <MDBIcon className="purple-text" icon="square" /> :
                        Lesson
                      </p>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol sm="3" className="col-12">
                    {formData.newPublicPage.activatePublicPage === true ? (
                      <div className="publicPageButton">
                        <MDBBtn
                          color="unique"
                          size="sm"
                          rounded
                          onClick={() => switchMode("studentRequests")}
                        >
                          Student Requests
                        </MDBBtn>
                      </div>
                    ) : null}
                  </MDBCol>
                  <MDBCol sm="3" className="col-12">
                    <Link
                      className={
                        formData.newPublicPage.activatePublicPage === true
                          ? "publicPageButton"
                          : "d-none"
                      }
                      to={{
                        pathname: `/tutors/${formData.newPublicPage.uniqueURL}`
                      }}
                      target="_blank"
                    >
                      <MDBBtn color="mdb-color" size="sm" rounded>
                        See Public Page
                      </MDBBtn>
                    </Link>
                    {/* <div className='publicPageButton'>
                      <MDBBtn
                        color='mdb-color'
                        size='sm'
                        rounded
                        onClick={() => switchMode("URL")}
                      >
                        See Public Page
                      </MDBBtn>
                    </div> */}
                  </MDBCol>
                </MDBRow>
                <MDBCard
                  style={{ height: "auto" }}
                  className="ml-2 calendarAvailabilityMobile"
                >
                  <MDBCardBody>
                    <div className="customScroll">
                      <MDBRow end className="mr-1">
                        <p className="quickSand font-weight-bold">
                          <MDBIcon far icon="calendar-alt" />
                          {` Timezone: ${user.defaultTimezone}`}
                        </p>
                      </MDBRow>
                      <MDBRow center>
                        {/* <MDBScrollbar className=""> */}
                        <div
                          // touchStart={changeCssScroll}
                          // touchMove={changeCssScroll}
                          // className="mobileCalendarScroll customScroll"
                          className="customScrollColorCalendar"
                          // id="test100"
                          style={{ overflowX: "auto" }}
                        >
                          <div
                            style={{ minWidth: "30rem", paddingBottom: "5rem" }}
                            // className="customScroll"
                          >
                            <Calendar
                              localizer={localizer}
                              // defaultDate={getDefaultDate()}
                              defaultView="week"
                              views={["week", "day", "month", "agenda"]}
                              events={formData.combinedArray}
                              style={{
                                height: "25rem",
                                fontFamily: "Quicksand",
                                minWidth: "30rem"
                              }}
                              onSelectEvent={event => handleEvent(event)}
                              onSelectSlot={handleSelect}
                              formats={formats}
                              getNow={getDefaultDate}
                              // showMultiDayTimes={false}
                              // scrollToTime={getStartDate()}
                              selectable="ignoreEvents"
                              onSelecting={selectDisable}
                              eventPropGetter={eventStyleGetter}
                            />
                          </div>
                          {/* </MDBScrollbar> */}
                        </div>
                      </MDBRow>
                    </div>

                    {/* <Calendar
                      selectable
                      defaultDate={new Date()}
                      defaultView="week"
                      style={{ height: "32rem" }}
                      localizer={localizer}
                      events={formData.events}
                      views={{
                        week: true,
                        month: false,
                        day: false
                      }}
                      toolbar={false}
                      onSelectSlot={handleSelectEvent}
                      drilldownView={null}
                      formats={{ dayFormat: "ddd" }}
                      getNow={getNow}
                    /> */}
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

            {/* Social Media Handlers  */}

            <MDBRow className="mx-1  socialPublicPageCard">
              <MDBCol sm="12" className="col-12 mx-1">
                <MDBRow center className="mt-4 pt-5 mb-3 mr-1">
                  <p className="livvicFont h3 text-black">
                    Social Media Handlers
                  </p>
                </MDBRow>
                <div>
                  <MDBCard style={{ height: "auto" }} className="my-3 mx-1">
                    <MDBCol size="12">
                      <MDBRow center className="middleVertical my-3 mx-1">
                        <MDBCol md="6" className="col-12">
                          <div>
                            <MDBInput
                              icon="facebook"
                              iconBrand
                              background
                              name="facebook"
                              label="Paste Facebook URL"
                              size="md"
                              onChange={e => onChange(e)}
                              value={formData.newPublicPage.facebook}
                              className="quickSand"
                            />
                          </div>
                          <MDBInput
                            name="instagram"
                            icon="instagram"
                            iconBrand
                            label="Paste Instagram URL"
                            background
                            size="md"
                            onChange={e => onChange(e)}
                            value={formData.newPublicPage.instagram}
                            className="quickSand"
                          />
                        </MDBCol>

                        <MDBCol md="6" className="col-12">
                          <MDBInput
                            name="linkedIn"
                            icon="linkedin"
                            iconBrand
                            label="Paste LinkedIn URL"
                            background
                            size="md"
                            onChange={e => onChange(e)}
                            value={formData.newPublicPage.linkedIn}
                            className="quickSand"
                          />
                          <MDBInput
                            name="twitter"
                            iconBrand
                            icon="twitter"
                            label="Paste Twitter URL"
                            background
                            size="md"
                            onChange={e => onChange(e)}
                            value={formData.newPublicPage.twitter}
                            className="quickSand"
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBCard>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mx-1 justify-content-start">
              <MDBCol sm="6" className="col-12">
                <MDBRow center className="mt-4 pt-2 mb-3 mr-1">
                  <p className="livvicFont h3 text-black">Preferences</p>
                </MDBRow>
                <MDBCard
                  className="publicPageCard2 socialPublicPageCard"
                  style={{ height: "auto" }}
                >
                  <MDBRow className="middleVertical middleVertical3 center my-1 mx-2">
                    <p className="h5 quickSand preferencesFont pb-2">
                      Youtube video link best describing yourself to potential
                      students. One minute max.
                    </p>{" "}
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center my-1 mx-2">
                    <MDBCol sm="12" className="col-12">
                      <MDBInput
                        label="Youtube Link"
                        outline={true}
                        onChange={e => handleChangeYoutube(e)}
                        value={formData.newPublicPage.youtube}
                        name="youtube"
                        className="quickSand"
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow center>{embedYoutube()}</MDBRow>

                  <MDBRow className="middleVertical middleVertical3 center my-1 mx-2">
                    <p className="h5 quickSand preferencesFont pb-2">
                      Hourly rate as well as the preferred currency
                    </p>{" "}
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center my-1 mx-2">
                    <MDBCol sm="12" md="12" lg="6" className="col-12">
                      <div>
                        <NumericInput
                          label="Hourly Rate"
                          outline="true"
                          min={0}
                          max={500}
                          format={formatHourlyRate}
                          value={formData.newPublicPage.rate}
                          onChange={value => setHourlyRateNumber(value)}
                          className="form-control quickSand"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol sm="12" md="12" lg="6" className="col-12 mb-2">
                      <div className="currencyInput">
                        <MDBSelect
                          options={formData.currencyOptions}
                          getValue={getCurrencyOptions}
                          labelClass="black-text"
                          label={"Choose Currency"}
                          className="black-text"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center my-1 mx-2">
                    <p className="h5 quickSand preferencesFont">
                      Preferred English levels to teach
                    </p>{" "}
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center my-1 mx-2">
                    <MDBCol sm="12" md="12" lg="12" className="col-12 mb-3">
                      <div className="currencyInput">
                        <MDBSelect
                          options={formData.levelOptions}
                          getValue={handleSelectChangeEnglishLevel}
                          color="dark"
                          label="English Level"
                          labelClass="black-text"
                          multiple
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="middleVertical middleVertical3 center my-1 mx-2">
                    <p className="h5 quickSand preferencesFont">
                      Preferred teaching methods
                    </p>{" "}
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center my-1 mx-2">
                    <MDBCol sm="12" md="12" lg="12" className="col-12 mb-3">
                      <div className="currencyInput">
                        <MDBSelect
                          options={formData.teachingOptions}
                          getValue={handleSelectChangeTeaching}
                          color="dark"
                          label="Teaching Methods"
                          labelClass="black-text"
                          multiple
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </MDBCol>
              <MDBCol sm="6" className="col-12">
                <MDBRow center className="mt-4 pt-2 mb-3 ">
                  <p className="livvicFont h3 text-black">Other Information</p>
                </MDBRow>
                <MDBCard
                  className="publicPageCard2 "
                  style={{ height: "auto" }}
                >
                  <MDBRow className="middleVertical middleVertical3 center my-1 mx-2">
                    <p className="h5 quickSand preferencesFont pb-2">
                      Highest degree you have earned (Optional)
                    </p>{" "}
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center my-1 mx-2">
                    <MDBCol sm="12" className="col-12">
                      <div className="currencyInput">
                        <MDBSelect
                          options={formData.degreeLevels}
                          getValue={handleSelectChangeDegree}
                          color="dark"
                          label="Highest Degree Earned (Optional)"
                          labelClass="black-text quickSand"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center mt-4 mx-2">
                    <p className="h5 quickSand preferencesFont pb-2">
                      How many years you have been teaching English for?
                      (Optional)
                    </p>{" "}
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center mt-4 mx-2 mb-4">
                    <MDBCol sm="12" className="col-12">
                      <div>
                        <NumericInput
                          label="Years of Experience (Optional)"
                          outline="true"
                          min={0}
                          max={50}
                          format={formatYearsOfExperience}
                          value={formData.newPublicPage.years}
                          onChange={value => setYearsOfExperience(value)}
                          className="form-control quickSand"
                        />{" "}
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center mt-4 mx-2">
                    <p className="h5 quickSand preferencesFont">
                      English Language Teaching Certifications (Optional)
                    </p>{" "}
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center mx-2 mb-1 mt-4">
                    <MDBCol sm="12" className="col-12">
                      <MDBInput
                        name="certification"
                        onChange={e => onChange(e)}
                        value={formData.newPublicPage.certification}
                        hint="English Teaching Certification (Optional)"
                        className="quickSand"
                      ></MDBInput>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center mt-3 mx-2">
                    <p className="h5 quickSand preferencesFont pb-1">
                      Last Teaching Experience (Optional)
                    </p>{" "}
                  </MDBRow>
                  <MDBRow className="middleVertical middleVertical3 center mx-2 mb-3">
                    <MDBCol sm="12" className="col-12">
                      <MDBInput
                        name="lastExperience"
                        onChange={e => onChange(e)}
                        value={formData.newPublicPage.lastExperience}
                        hint="Last Teaching Experience (Optional)"
                        className="quickSand"
                      ></MDBInput>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </MDBCol>
            </MDBRow>
            <MDBModalFooter className="footerPublicPage  mt-2">
              <MDBCol size="12">
                <MDBRow>
                  <MDBCol sm="6" className="text-left col-12">
                    <div className="socialPublicPageCard ">
                      {publicPageExist === true ? (
                        <MDBBtn
                          disabled={loadingButtonDeactivate}
                          onClick={deactivatePublicPageFunc}
                          color="danger"
                        >
                          {loadingButtonDeactivate === true && (
                            <div className="spinner-grow spinner-grow-sm" />
                          )}
                          Deactivate Public Page
                        </MDBBtn>
                      ) : null}
                    </div>
                  </MDBCol>
                  <MDBCol
                    sm="6"
                    className="text-right col-12 saveChangesButton"
                  >
                    <MDBBtn
                      onClick={onSubmit}
                      color="blue"
                      disabled={loadingButtonPublicPage}
                    >
                      {loadingButtonPublicPage === true && (
                        <div className="spinner-grow spinner-grow-sm" />
                      )}
                      {publicPageExist === true
                        ? "Save Changes"
                        : "Create Public Page"}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBModalFooter>

            <MDBContainer>
              <MDBModal
                isOpen={formData.modalUpdateAvatar}
                toggle={() => null}
                size="md"
              >
                <MDBModalBody>
                  <MDBRow center>
                    <p className="h6 quickSand pb-2">
                      Upload an image (Max file size: 10 Mb)
                    </p>
                  </MDBRow>
                  <MDBRow center>
                    <Cropper
                      ref={cropImageRef}
                      src={formData.imgSrc}
                      style={{ height: 250, width: "100%" }}
                      aspectRatio={1 / 1}
                      guides={false}
                      crop={cropFunc}
                    />

                    <MDBCol md="6" className="mb-4">
                      <MDBFileInput
                        reset
                        textFieldTitle=""
                        btnColor="outline-info"
                        btn-size="sm"
                        getValue={fileInputHandler}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow center>
                    <p className="quickSand h6 text-info">Avatar Preview</p>
                  </MDBRow>
                  <MDBRow center className="mb-2">
                    <img
                      style={{ width: "80px" }}
                      src={formData.croppedBase64}
                      alt=""
                      className="rounded-circle z-depth-1-half"
                    />
                  </MDBRow>
                  <MDBRow center>
                    <p className="quickSand">
                      A square image works best. PNG and jpeg supported.
                    </p>
                  </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="success" size="sm" onClick={submitAvatar}>
                    Select
                  </MDBBtn>
                  <MDBBtn
                    color="secondary"
                    size="sm"
                    onClick={toggleUpdateAvatar}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>
            </MDBContainer>
          </Fragment>
        )}
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          size="lg"
          isOpen={formData.modalConfigureAvailabilitySelect}
          toggle={() => null}
        >
          <Fragment>
            <MDBModalHeader className="montserratFont black-text h4">
              Choose slot interval
            </MDBModalHeader>
            <MDBModalBody className="quickSand h5 black-text">
              <MDBContainer className="row d-flex justify-content-center">
                <MDBCol md="6" className="text-center">
                  <div className="quickSand">
                    <MDBSelect
                      required
                      className="quickSand black-text font-weight-bold mt-3"
                      options={formData.intervalOptions}
                      labelClass="black-text font-weight-bold quickSand"
                      label="Availability Interval"
                      outline
                      size="sm"
                      getValue={selectInterval}
                    />
                  </div>
                </MDBCol>
              </MDBContainer>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="quickSand"
                onClick={submitSelection}
                color="primary"
              >
                Save
              </MDBBtn>
              <MDBBtn
                className="quickSand"
                color="secondary"
                onClick={closeAvailabilityModal}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </Fragment>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          size="sm"
          isOpen={formData.modalSeeLesson}
          toggle={toggleCloseLesson}
        >
          <MDBModalHeader
            className="montserratFont black-text h4"
            toggle={toggleCloseLesson}
          >
            {formData.viewLesson.title}
          </MDBModalHeader>
          <MDBModalBody className="quickSand black-text h5">
            {formData.viewLesson.comments}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              className="quickSand"
              color="secondary"
              size="sm"
              onClick={toggleCloseLesson}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <animated.div style={props2}>
        {formData.bottomShow === true ? (
          <div className="float-wrap text-center z-depth-1-half">
            <MDBCard style={{ height: "16rem" }}>
              <MDBIcon
                onClick={bottomShowFunc}
                style={{ cursor: "pointer" }}
                size="2x"
                className="text-right mainColor bottomButton mr-1 mt-1"
                icon="window-close"
              />
              <MDBRow>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Creating your public page
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Creating your public page"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/Cf0wpH8jFUI"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Calendar Availabilities
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Calendar Availabilities"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/5oR-0H0MlLg?rel=0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Student Invite Requests
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Student Invite Requests"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/xd1k4zMRX1A?rel=0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        ) : null}
      </animated.div>
    </Fragment>
  );
};

PublicPage.propTypes = {};

const mapStateToProps = state => ({
  publicpage: state.publicpage,
  auth: state.auth,
  lessons: state.lessons
});

export default withRouter(
  connect(mapStateToProps, {
    createPublicPage,
    getPublicPage,
    updatePublicPage,
    getLessonsCalendar,
    setLoadingButtonPublicPage,
    deactivatePublicPage,
    activatePublicPage,
    setLoadingButtonDeactivate
  })(PublicPage)
);
