import React, { Fragment, useEffect, useState } from "react";
import sum from "lodash/sum";
import { Scrollbars } from "react-custom-scrollbars";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import {
  ratingLesson,
  getPublishedLesson,
  setLoadingButtonRating,
  getPublishedEditedLessons
} from "../../../actions/trading";
import Rating from "react-rating";
import { withRouter } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from "react-share";

import {
  MDBBtn,
  MDBRow,
  MDBListGroupItem,
  MDBListGroup,
  MDBIcon
} from "mdbreact";

const LessonView = ({
  history,
  mode,
  match,
  downloadLessonFunc,
  ratingLesson,
  lessonContent,
  auth: { user },
  modalUploadEdit,
  modalUploadEditFunc,
  setLoadingButtonRating,
  getPublishedEditedLessons,
  trading: {
    publishedLessonEdits,
    loadingButtonDownload,
    downloads,
    loadingButtonRating,
    lesson,
    publishedLesson
  },
  getPublishedLesson
}) => {
  const [formData, setFormData] = useState({
    ratingValue: "",
    individualLesson: {
      title: "",
      comments: "",
      objective: "",
      date: "",
      groupIds: {
        name: ""
      },
      level: "",
      userTutor: {
        _id: ""
      },
      topicIds: [],
      rating: [],
      downloads: ""
    }
  });

  useEffect(() => {
    if (lessonContent) {
      setFormData(prevState => {
        return {
          ...prevState,
          individualLesson: {
            ...prevState.individualLesson,
            title: lessonContent.title,
            comments: lessonContent.comments,
            objective: lessonContent.objective,
            date: lessonContent.date,
            level: lessonContent.level,
            userTutor: lessonContent.userTutor,
            topicIds: lessonContent.topicIds,
            rating: lessonContent.rating,
            downloads: lessonContent.downloads,
            _id: lessonContent._id
          },
          ratingValue: ""
        };
      });
    }
  }, [lessonContent]);

  const handleClick = value => {
    setFormData({ ...formData, ratingValue: value });
  };

  const ratingLessonFunc = () => {
    const find = formData.individualLesson.rating.find(
      item => item.userId === user._id
    );
    if (!find) {
      setLoadingButtonRating(true);
      ratingLesson({
        value: formData.ratingValue,
        lessonId: formData.individualLesson._id
      });
    }
  };

  const hideButton = () => {
    const find = formData.individualLesson.rating.find(
      item => item.userId === user._id
    );
    if (formData.ratingValue && !find) {
      return true;
    } else {
      return false;
    }
  };

  const findRating = () => {
    const find = formData.individualLesson.rating.find(
      item => item.userId === user._id
    );
    if (formData.individualLesson.userTutor._id === user._id) {
      return true;
    } else if (find) {
      return true;
    } else {
      return false;
    }
  };

  const initialRating = () => {
    const find = formData.individualLesson.rating.find(
      item => item.userId === user._id
    );

    if (find) {
      return find.value;
    } else {
      return formData.ratingValue;
    }
  };

  const countRatingStar = () => {
    const newArray = formData.individualLesson.rating.map((item, index) => {
      return item.value;
    });

    let summation = sum(newArray);
    summation = summation / formData.individualLesson.rating.length;
    return Math.floor(summation);
  };

  const countRating = () => {
    return formData.individualLesson.rating.length;
  };

  useEffect(() => {
    if (lesson) {
      getPublishedEditedLessons({ originalId: match.params.id });
    }
  }, [getPublishedEditedLessons, match, lesson]);

  // Share published lesson to social media

  const shareUrl = `topscholar.io/trading/lesson/${formData.individualLesson._id}`;
  const title = "Check out this lesson published on TopScholar!";
  const title2 = `Check out this lesson published on TopScholar! topscholar.io/trading/lesson/${match.params.id}`;
  const twitterAccount = "Topscholarapp";

  return (
    <Fragment>
      <MDBRow className="mt-3" center>
        <p className="text-white h4 quickSand">Versions</p>
      </MDBRow>

      <MDBRow center>
        <MDBListGroup style={{ height: "15rem" }}>
          <MDBListGroupItem
            style={{ minWidth: "16rem", cursor: "pointer" }}
            className=" quickSand info-color-dark
                text-white h4 my-1 z-depth-2"
            onClick={() => getPublishedLesson(match.params.id)}
          >
            <p className="text-center">Original</p>
          </MDBListGroupItem>
          <Scrollbars autoHide autoHideTimeout={2000}>
            {publishedLessonEdits.map((item, index) => {
              return (
                <MDBListGroupItem
                  style={{ minWidth: "14rem", cursor: "pointer" }}
                  className=" quickSand  info-color text-white h6 my-1 z-depth-2"
                  onClick={() => getPublishedLesson(item._id)}
                >
                  <p className="text-center">Edited by {item.userTutor.name}</p>
                </MDBListGroupItem>
              );
            })}
          </Scrollbars>
        </MDBListGroup>
      </MDBRow>
      <hr />
      <MDBRow center className="uploadButtonPadding">
        <MDBBtn
          size="sm"
          onClick={() => modalUploadEditFunc("lessons")}
          color="info"
        >
          Upload Edited Version
        </MDBBtn>
        <MDBBtn
          onClick={downloadLessonFunc}
          color="pink"
          disabled={loadingButtonDownload}
        >
          {loadingButtonDownload === true && (
            <div className="spinner-grow spinner-grow-sm" />
          )}
          Download Lesson
        </MDBBtn>
      </MDBRow>
      <MDBRow center>
        <p className="text-white quickSand">
          Downloads: {formData.individualLesson.downloads}
        </p>
      </MDBRow>
      <div className={publishedLesson.original === true ? "" : "d-none"}>
        <MDBRow center>
          <Rating
            readonly={true}
            emptySymbol="far fa-star fa-2x"
            fullSymbol="fas fa-star fa-2x pinkColor "
            initialRating={countRatingStar()}
            onClick={handleClick}
          />
        </MDBRow>

        <MDBRow center>
          <p className="text-white quickSand">Ratings: {countRating()}</p>
        </MDBRow>
        <MDBRow className="mt-2" center>
          <Rating
            readonly={findRating()}
            initialRating={initialRating()}
            emptySymbol="far fa-star fa-2x"
            fullSymbol="fas fa-star fa-2x pinkColor "
            onClick={handleClick}
          />
        </MDBRow>
        {hideButton() ? (
          <MDBRow center>
            <MDBBtn
              onClick={ratingLessonFunc}
              size="sm"
              color="red"
              disabled={loadingButtonRating}
            >
              {loadingButtonRating === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Submit Rating
            </MDBBtn>
          </MDBRow>
        ) : null}
        <MDBRow center className="mb-1">
          <p className="text-white quickSand">Your rating</p>
        </MDBRow>
      </div>
      <MDBRow center className="ml-3">
        <div className="mr-3 growMessageTrading" style={{ cursor: "pointer" }}>
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>
        </div>
        <div className="mr-3 growMessageTrading" style={{ cursor: "pointer" }}>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
        </div>
        <div className="mr-4 growMessageTrading" style={{ cursor: "pointer" }}>
          <TwitterShareButton
            url={shareUrl}
            title={title2}
            via={twitterAccount}
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
        </div>
        <div className="mt-1">
          <MDBIcon icon="share" className="shareArrowColor shareIconTrading" />
        </div>
      </MDBRow>
    </Fragment>
  );
};

LessonView.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    ratingLesson,
    getPublishedLesson,
    setLoadingButtonRating,
    getPublishedEditedLessons
  })(LessonView)
);
