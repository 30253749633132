import React, { Fragment, useEffect, useState } from "react";
import sum from "lodash/sum";
import { Scrollbars } from "react-custom-scrollbars";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import {
  ratingGame,
  getPublishedGame,
  setLoadingButtonRating,
  getPublishedEditedGames
} from "../../../actions/trading";
import Rating from "react-rating";
import { withRouter } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from "react-share";

import {
  MDBBtn,
  MDBRow,
  MDBListGroupItem,
  MDBListGroup,
  MDBIcon
} from "mdbreact";

const GameView = ({
  history,
  mode,
  match,
  downloadGameFunc,
  ratingGame,
  gameContent,
  auth: { user },
  modalUploadEditFunc,
  setLoadingButtonRating,
  trading: {
    publishedGameEdits,
    loadingButtonDownload,
    loadingButtonRating,
    game,
    publishedGame
  },
  getPublishedGame,
  getPublishedEditedGames
}) => {
  const [formData, setFormData] = useState({
    ratingValue: "",
    individualGame: {
      title: "",
      numberOfStudents: "",
      level: "",
      instructions: "",
      materials: "",
      duration: "",
      youtube: [],
      links: [],
      userTutor: {
        name: "",
        _id: ""
      },
      date: "",
      rating: [],
      downloads: ""
    }
  });

  useEffect(() => {
    if (gameContent) {
      setFormData(prevState => {
        return {
          ...prevState,
          individualGame: {
            ...prevState.individualGame,
            title: gameContent.title,
            numberOfStudents: gameContent.numberOfStudents,
            level: gameContent.level,
            instructions: gameContent.instructions,
            materials: gameContent.materials,
            duration: gameContent.duration,
            youtube: gameContent.youtube,
            links: gameContent.links,
            userTutor: gameContent.userTutor,
            date: gameContent.date,
            rating: gameContent.rating,
            downloads: gameContent.downloads,
            _id: gameContent._id
          },
          ratingValue: ""
        };
      });
    }
  }, [gameContent]);

  const handleClick = value => {
    setFormData({ ...formData, ratingValue: value });
  };

  const ratingGameFunc = () => {
    const find = formData.individualGame.rating.find(
      item => item.userId === user._id
    );
    if (!find) {
      setLoadingButtonRating(true);
      ratingGame({
        value: formData.ratingValue,
        gameId: formData.individualGame._id
      });
    }
  };

  const hideButton = () => {
    const find = formData.individualGame.rating.find(
      item => item.userId === user._id
    );
    if (formData.ratingValue && !find) {
      return true;
    } else {
      return false;
    }
  };

  const findRating = () => {
    const find = formData.individualGame.rating.find(
      item => item.userId === user._id
    );

    if (formData.individualGame.userTutor._id === user._id) {
      return true;
    } else if (find) {
      return true;
    } else {
      return false;
    }
  };

  const initialRating = () => {
    const find = formData.individualGame.rating.find(
      item => item.userId === user._id
    );

    if (find) {
      return find.value;
    } else {
      return formData.ratingValue;
    }
  };

  const countRatingStar = () => {
    const newArray = formData.individualGame.rating.map((item, index) => {
      return item.value;
    });

    let summation = sum(newArray);
    summation = summation / formData.individualGame.rating.length;
    return Math.floor(summation);
  };

  const countRating = () => {
    return formData.individualGame.rating.length;
  };

  useEffect(() => {
    if (game) {
      getPublishedEditedGames({ originalId: match.params.id });
    }
  }, [getPublishedEditedGames, match, game]);

  // Share published game to social media

  const shareUrl = `topscholar.io/trading/game/${formData.individualGame._id}`;
  const title = "Check out this game published on TopScholar!";
  const title2 = `Check out this game published on TopScholar! topscholar.io/trading/game/${match.params.id}`;
  const twitterAccount = "Topscholarapp";

  return (
    <Fragment>
      <MDBRow className="mt-3" center>
        <p className="text-white h4 quickSand">Versions</p>
      </MDBRow>

      <MDBRow center>
        <MDBListGroup style={{ height: "15rem" }}>
          <MDBListGroupItem
            style={{ minWidth: "16rem", cursor: "pointer" }}
            className=" quickSand info-color-dark
                text-white h4 my-1 z-depth-2"
            onClick={() => getPublishedGame(match.params.id)}
          >
            <p className="text-center">Original</p>
          </MDBListGroupItem>
          <Scrollbars autoHide autoHideTimeout={2000}>
            {publishedGameEdits.map((item, index) => {
              return (
                <MDBListGroupItem
                  style={{ minWidth: "14rem", cursor: "pointer" }}
                  className=" quickSand  info-color text-white h6 my-1 z-depth-2"
                  onClick={() => getPublishedGame(item._id)}
                >
                  <p className="text-center">Edited by {item.userTutor.name}</p>
                </MDBListGroupItem>
              );
            })}
          </Scrollbars>
        </MDBListGroup>
      </MDBRow>
      <hr />
      <MDBRow center className="uploadButtonPadding">
        <MDBBtn
          size="sm"
          onClick={() => modalUploadEditFunc("games")}
          color="info"
        >
          Upload Edited Version
        </MDBBtn>
        <MDBBtn
          onClick={downloadGameFunc}
          color="pink"
          disabled={loadingButtonDownload}
        >
          {loadingButtonDownload === true && (
            <div className="spinner-grow spinner-grow-sm" />
          )}
          Download Game
        </MDBBtn>
      </MDBRow>
      <MDBRow center>
        <p className="text-white quickSand">
          {" "}
          Downloads: {formData.individualGame.downloads}
        </p>
      </MDBRow>
      <div className={publishedGame.original === true ? "" : "d-none"}>
        <MDBRow center>
          <Rating
            readonly={true}
            emptySymbol="far fa-star fa-2x"
            fullSymbol="fas fa-star fa-2x pinkColor "
            initialRating={countRatingStar()}
          />
        </MDBRow>
        <MDBRow center>
          <p className="text-white quickSand">Ratings: {countRating()}</p>
        </MDBRow>
        <MDBRow className="mt-2" center>
          <Rating
            readonly={findRating()}
            initialRating={initialRating()}
            emptySymbol="far fa-star fa-2x"
            fullSymbol="fas fa-star fa-2x pinkColor "
            onClick={handleClick}
          />
        </MDBRow>
        {hideButton() ? (
          <MDBRow center my-2>
            <MDBBtn
              onClick={ratingGameFunc}
              size="sm"
              color="red"
              disabled={loadingButtonRating}
            >
              {loadingButtonRating === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Submit Rating
            </MDBBtn>
          </MDBRow>
        ) : null}
        <MDBRow center className="mb-1">
          <p className="text-white quickSand">Your rating</p>
        </MDBRow>
      </div>
      <MDBRow center className="ml-3">
        <div className="mr-3 growMessageTrading" style={{ cursor: "pointer" }}>
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>
        </div>
        <div className="mr-3 growMessageTrading" style={{ cursor: "pointer" }}>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
        </div>
        <div className="mr-4 growMessageTrading" style={{ cursor: "pointer" }}>
          <TwitterShareButton
            url={shareUrl}
            title={title2}
            via={twitterAccount}
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
        </div>
        <div className="mt-1">
          <MDBIcon icon="share" className="shareArrowColor shareIconTrading" />
        </div>
      </MDBRow>
    </Fragment>
  );
};

GameView.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    ratingGame,
    getPublishedGame,
    setLoadingButtonRating,
    getPublishedEditedGames
  })(GameView)
);
