import React, { Fragment, useState, useEffect } from "react";
import {
  MDBDataTable,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBPageItem,
  MDBPageNav,
  MDBPagination,
  MDBInput,
  MDBIcon
} from "mdbreact";

import parse from "html-react-parser";

import Spinner from "../layout/Spinner";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getGames,
  deleteGame,
  clearGamesReducer,
  searchGamesFunction,
  setPaginationModeGames,
  clearGamesSearchReducer,
  setLoadingDeleteGame
} from "../../actions/games";

import urlParser from "js-video-url-parser";
import "./games.css";

import CreateGame from "./CreateGame";

const GameData = ({
  getGames,
  deleteGame,
  clearGamesReducer,
  setLoadingDeleteGame,
  games: {
    game,
    games,
    loading,
    updatedGame,
    gameDeleted,
    initialNumberOfPages,
    initialPageNumber,
    numberOfPages,
    pageNumber,
    searchGames,
    paginationModeGames,
    gamesLoading,
    loadingDeleteGame,
    loadingButtonGames
  },
  history,
  location,
  searchGamesFunction,
  setPaginationModeGames,
  clearGamesSearchReducer
}) => {
  const [formData, setFormData] = useState({
    dataGames: {
      columns: [
        {
          label: [
            <p key={1} className="quickSand font-weight-bold">
              {"Game Title"}
            </p>
          ],
          field: "title",
          sort: "asc",
          width: 150
        },
        {
          label: [
            <p key={2} className="quickSand font-weight-bold">
              {"Level"}
            </p>
          ],
          field: "level",
          sort: "asc",
          width: 270
        },
        {
          label: [
            <p key={3} className="quickSand font-weight-bold">
              {"Duration"}
            </p>
          ],
          field: "duration",
          sort: "asc",
          width: 150
        }
      ],
      rows: []
    },

    gameList: [],
    modalCreateGame: false,
    modalSeeGame: false,
    modalDeleteGame: false,
    individualGame: {
      title: "",
      numberOfStudents: "",
      level: "",
      instructions: "",
      materials: "",
      duration: "",
      youtube: [],
      links: []
    },
    games: [],
    searchGames: [],
    searchvalue: "",
    pageNumber: 1,
    saveGameBoolean: false
  });

  useEffect(() => {
    getGames({ initialPageNumber: "first" });
  }, [getGames]);

  useEffect(() => {
    if (game) {
      setFormData(prevState => {
        return {
          ...prevState,
          gameList: [...prevState.gameList, game],
          modalCreateGame: false
        };
      });
      clearGamesReducer();
    }
  }, [game, clearGamesReducer]);

  var styles = {
    cursor: "pointer"
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        gameList: games
      };
    });
  }, [games]);

  useEffect(() => {
    const searchGamesMap = () => {
      if (formData.searchGames === "No Results") {
        return [];
      } else if (formData.searchGames.length !== 0) {
        return formData.searchGames;
      } else {
        return games;
      }
    };
    if (formData.gameList.length !== 0) {
      const gameClick = item => {
        setFormData(prevState => {
          return {
            ...prevState,
            modalCreateGame: false,
            modalSeeGame: true,
            individualGame: item
          };
        });
      };
      const newGameArray = searchGamesMap().map((item, index) => {
        return {
          title: item.title,
          level: item.level ? item.level : "No Level",
          duration: item.duration ? item.duration : "No Duration",
          clickEvent: () => {
            gameClick(item);
          }
        };
      });
      setFormData(prevState => {
        return {
          ...prevState,
          dataGames: {
            ...prevState.dataGames,
            rows: newGameArray
          }
        };
      });
    }
  }, [formData.gameList, formData.searchGames, games]);

  const gameClickClose = item => {
    setFormData({
      ...formData,
      modalSeeGame: false,
      individualGame: {
        title: "",
        numberOfStudents: "",
        level: "",
        instructions: "",
        materials: "",
        duration: "",
        youtube: [],
        links: []
      }
    });
  };

  const createGameModal = () => {
    setFormData({
      ...formData,
      modalCreateGame: !formData.modalCreateGame,
      modalSeeGame: false
    });
  };
  const createGameModalClose = () => {
    setFormData({
      ...formData,
      modalCreateGame: false,
      individualGame: {
        title: "",
        numberOfStudents: "",
        level: "",
        instructions: "",
        materials: "",
        duration: "",
        youtube: [],
        links: []
      }
    });
  };

  useEffect(() => {
    if (updatedGame) {
      setFormData(prevState => {
        const newArray = prevState.gameList.map((item, index) => {
          if (item._id === updatedGame._id) {
            return updatedGame;
          } else {
            return item;
          }
        });
        return {
          ...prevState,
          modalCreateGame: false,
          individualGame: {
            title: "",
            numberOfStudents: "",
            level: "",
            instructions: "",
            materials: "",
            duration: "",
            youtube: [],
            links: []
          },
          gameList: newArray
        };
      });

      clearGamesReducer();
    }
  }, [updatedGame, clearGamesReducer]);

  useEffect(() => {
    if (gameDeleted) {
      setFormData(prevState => {
        const filter = prevState.gameList.filter(
          item => item._id !== gameDeleted._id
        );
        return {
          ...prevState,
          modalCreateGame: false,
          modalSeeGame: false,
          modalDeleteGame: false,
          individualGame: {
            title: "",
            numberOfStudents: "",
            level: "",
            instructions: "",
            materials: "",
            duration: "",
            youtube: [],
            links: []
          },
          gameList: filter
        };
      });

      clearGamesReducer();
      getGames({ initialPageNumber: "first" });
    }
  }, [gameDeleted, clearGamesReducer, getGames]);

  const gameOnDelete = () => {
    setLoadingDeleteGame(true);
    deleteGame(formData.individualGame._id);
  };

  // remove double scroll bar when in modal mode
  useEffect(() => {
    if (formData.modalCreateGame === true || formData.modalSeeGame === true) {
      document.body.style.cssText = "overflow-y: hidden !important;";
    } else {
      document.body.style.overflowY = "";
    }
  }, [formData.modalCreateGame, formData.modalSeeGame]);

  // Search

  useEffect(() => {
    if (searchGames) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchGames: searchGames
        };
      });
      // setFormData({
      //   ...formData,
      //   searchGames: searchGames
      // });
    }
  }, [searchGames]);

  const onKeyPressEnterGames = e => {
    if (e.key === "Enter") {
      gamesSearchFunc();
    }
  };

  const onChangeGames = e =>
    setFormData({
      ...formData,
      searchvalue: e.target.value
    });

  const gamesSearchFunc = () => {
    searchGamesFunction({
      keywords: formData.searchvalue,
      pageNumber: formData.pageNumber
    });
    setPaginationModeGames("search");
  };

  const clearGamesFunc = () => {
    setFormData({
      ...formData,
      searchvalue: "",
      searchGames: []
    });
    clearGamesSearchReducer();
    setPaginationModeGames("date");
    return games;
  };

  // Pagination

  const isActive1 = page => {
    if (initialPageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationGames = () => {
    if (paginationModeGames === "date") {
      getGames({ initialPageNumber: "first" });
    } else if (paginationModeGames === "search") {
      searchGamesFunction({
        pageNumber: "first",
        keywords: formData.searchvalue
      });
    }
  };

  const previousPaginationGames = () => {
    if (paginationModeGames === "date") {
      getGames({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationModeGames === "search") {
      searchGamesFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchvalue
      });
    }
  };

  const currentPaginationGames = index => {
    if (paginationModeGames === "date") {
      getGames({ initialPageNumber: index + 1 });
    } else if (paginationModeGames === "search") {
      searchGamesFunction({
        pageNumber: index + 1,
        keywords: formData.searchvalue
      });
    }
  };

  const nextPaginationGames = () => {
    if (paginationModeGames === "date") {
      getGames({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationModeGames === "search") {
      searchGamesFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchvalue
      });
    }
  };

  const lastPaginationGames = () => {
    if (paginationModeGames === "date") {
      getGames({ initialPageNumber: "last" });
    } else if (paginationModeGames === "search") {
      searchGamesFunction({
        pageNumber: "last",
        keywords: formData.searchvalue
      });
    }
  };

  const deleteGameModalOpen = () => {
    setFormData({
      ...formData,
      modalDeleteGame: !formData.modalDeleteGame
    });
  };

  // Close modal upon backend response

  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     modalDeleteGame: false
  //   });
  // }, [game]);

  // before return

  const gameSave = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        saveGameBoolean: true
      };
    });
  };

  const clearsaveGameBoolean = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        saveGameBoolean: false
      };
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <MDBCol>
        <MDBCard narrow>
          <MDBCardHeader className="view view-cascade backgroundGames d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
            <p className="h5 quickSand black-text mx-2">Games</p>
            <div>
              <MDBBtn
                onClick={createGameModal}
                outline
                rounded
                size="sm"
                color="black"
                className="h6 quickSand px-2"
              >
                <i className="fas fa-plus mt-0" /> Add new game
              </MDBBtn>
            </div>
          </MDBCardHeader>
          <MDBRow className="middleVertical5" center>
            <MDBCol className="col-2" lg="2" md="2" sm="2"></MDBCol>
            <MDBCol lg="4" md="8" sm="4" className="col-8">
              <MDBInput
                className="black-text"
                label="Search your games"
                labelClass="black-text"
                type="text"
                containerClass="active-pink active-pink-2 mt-0 mb-3"
                value={formData.searchvalue}
                onChange={onChangeGames}
                onKeyPress={onKeyPressEnterGames}
              />
            </MDBCol>
            <MDBCol className="col-2" lg="2" md="2" sm="2">
              <MDBRow className="align-items-center">
                <div
                  className={
                    formData.searchvalue.length !== 0 ? "mx-1" : "d-none"
                  }
                >
                  <MDBIcon
                    icon="search"
                    onClick={gamesSearchFunc}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div
                  className={
                    formData.searchGames.length !== 0 ||
                    formData.searchGames === "No Results"
                      ? "mx-1"
                      : "d-none"
                  }
                >
                  <MDBIcon
                    icon="times-circle"
                    onClick={clearGamesFunc}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBCardBody cascade center>
            {(formData.searchGames.length === 0 || "No Results") &&
            games.length === 0 &&
            gamesLoading === false ? (
              <div className="cardWidth text-center">
                <img
                  className="imageFlex"
                  src={
                    "https://res.cloudinary.com/unnamed/image/upload/v1578488001/No_Games_Found_1_q8zkke.png"
                  }
                  alt=""
                />
              </div>
            ) : (
              <div className="tableGame" style={{ minHeight: "27rem" }}>
                <MDBDataTable
                  btn
                  small
                  fixed
                  className="text-center quickSand"
                  info={false}
                  hover
                  data={formData.dataGames}
                  bordered
                  theadColor="yellow lighten-4"
                  style={styles}
                  paging={false}
                  searching={false}
                  sortable={false}
                />
              </div>
            )}
            {/* Pagination Games Medium Size*/}

            <div
              className={
                formData.modalCreateGame === false
                  ? "middleVertical mt-4 mb-3 paginationGamesMedium"
                  : "d-none"
              }
            >
              <MDBRow>
                <MDBCol>
                  <MDBPagination circle>
                    <MDBPageItem onClick={firstPaginationGames}>
                      <MDBPageNav className="page-link">
                        <span>First</span>
                      </MDBPageNav>
                    </MDBPageItem>
                    <MDBPageItem onClick={previousPaginationGames}>
                      <MDBPageNav className="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </MDBPageNav>
                    </MDBPageItem>
                    {paginationModeGames === "date"
                      ? [...Array(initialNumberOfPages)].map((item, index) => {
                          if (index + 1 === initialPageNumber) {
                            return (
                              <MDBPageItem
                                key={index}
                                active={isActive1(index + 1)}
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber - 1) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber - 2) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber - 3) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber + 1) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber + 2) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber + 3) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else {
                            return null;
                          }
                        })
                      : [...Array(numberOfPages)].map((item, index) => {
                          if (index + 1 === pageNumber) {
                            return (
                              <MDBPageItem
                                key={index}
                                active={isActive2(index + 1)}
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber - 1) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber - 2) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber - 3) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber + 1) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber + 2) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber + 3) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                    <MDBPageItem onClick={nextPaginationGames}>
                      <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                    </MDBPageItem>
                    <MDBPageItem onClick={lastPaginationGames}>
                      <MDBPageNav className="page-link">Last</MDBPageNav>
                    </MDBPageItem>
                  </MDBPagination>
                </MDBCol>
              </MDBRow>
            </div>

            <div
              className={
                formData.modalCreateGame === false
                  ? "middleVertical mt-4 mb-3 paginationGamesSmall"
                  : "d-none"
              }
            >
              <MDBRow>
                <MDBCol>
                  <MDBPagination circle size="sm">
                    <MDBPageItem onClick={firstPaginationGames}>
                      <MDBPageNav className="page-link">
                        <span>First</span>
                      </MDBPageNav>
                    </MDBPageItem>
                    <MDBPageItem onClick={previousPaginationGames}>
                      <MDBPageNav className="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </MDBPageNav>
                    </MDBPageItem>
                    {paginationModeGames === "date"
                      ? [...Array(initialNumberOfPages)].map((item, index) => {
                          if (index + 1 === initialPageNumber) {
                            return (
                              <MDBPageItem
                                key={index}
                                active={isActive1(index + 1)}
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber - 1) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber - 2) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber - 3) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber + 1) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber + 2) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === initialPageNumber + 3) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else {
                            return null;
                          }
                        })
                      : [...Array(numberOfPages)].map((item, index) => {
                          if (index + 1 === pageNumber) {
                            return (
                              <MDBPageItem
                                active={isActive2(index + 1)}
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber - 1) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber - 2) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber - 3) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber + 1) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber + 2) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else if (index + 1 === pageNumber + 3) {
                            return (
                              <MDBPageItem
                                onClick={() => currentPaginationGames(index)}
                              >
                                <MDBPageNav className="page-link">
                                  {index + 1}{" "}
                                  <span className="sr-only">(current)</span>
                                </MDBPageNav>
                              </MDBPageItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                    <MDBPageItem onClick={nextPaginationGames}>
                      <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                    </MDBPageItem>
                    <MDBPageItem onClick={lastPaginationGames}>
                      <MDBPageNav className="page-link">Last</MDBPageNav>
                    </MDBPageItem>
                  </MDBPagination>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

      <MDBContainer>
        <div className="gamesModal">
          <MDBModal isOpen={formData.modalCreateGame} className="modal-full">
            <div className="modal-full modal-content ">
              <MDBRow center className="middleVertical py-3">
                <p className="text-center quickSand black-white h2">
                  {formData.individualGame.title
                    ? "Edit Game"
                    : "Create a New Game"}
                </p>
              </MDBRow>

              <CreateGame
                saveGameBoolean={formData.saveGameBoolean}
                clearsaveGameBoolean={clearsaveGameBoolean}
                individualGame={formData.individualGame}
              />

              <MDBModalFooter className="backgroundGames">
                {formData.individualGame.title ? (
                  <MDBBtn
                    className="mr-auto p-2 col-example"
                    onClick={deleteGameModalOpen}
                    color="danger"
                  >
                    Delete this game
                  </MDBBtn>
                ) : null}
                <MDBBtn
                  className="p-2 col-example mr-2 gameButton"
                  onClick={gameSave}
                  disabled={loadingButtonGames}
                >
                  {loadingButtonGames === true && (
                    <div className="spinner-grow spinner-grow-sm" />
                  )}
                  Save Changes
                </MDBBtn>

                <MDBBtn
                  className="p-2 col-example mr-2"
                  onClick={createGameModalClose}
                  color="color-one"
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </div>
        <div className="gamesModal">
          <MDBModal isOpen={formData.modalSeeGame} className="modal-full">
            <div className="modal-full modal-content ">
              <MDBRow center className="middleVertical py-3">
                <p className="quickSand text-black h2 text-center">
                  {formData.individualGame.title.charAt(0).toUpperCase() +
                    formData.individualGame.title.slice(1)}
                </p>
              </MDBRow>

              <div className=" modalBottomMargin rounded white mx-3">
                <MDBContainer>
                  <MDBRow className="pt-5">
                    <MDBCol md="4" className="mr-auto text-center">
                      <p className="h5 pb-1 montserratFont">
                        Level:{" "}
                        <span className="gameFontSize4">
                          <strong>
                            {formData.individualGame.level
                              ? formData.individualGame.level
                              : "Not Assigned"}
                          </strong>
                        </span>
                      </p>
                    </MDBCol>
                    <MDBCol
                      md="4"
                      className={
                        formData.individualGame.numberOfStudents !== ""
                          ? "text-center"
                          : "d-none"
                      }
                    >
                      <p className="h5 pb-1 montserratFont">
                        Number of Students:{" "}
                        <span className="gameFontSize4">
                          <strong>
                            {formData.individualGame.numberOfStudents}
                          </strong>
                        </span>
                      </p>
                    </MDBCol>
                    <MDBCol md="4" className="text-center">
                      <p className="h5 pb-1 montserratFont">
                        Duration:{" "}
                        <span className="gameFontSize4">
                          <strong>{formData.individualGame.duration}</strong>
                        </span>
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
                <hr />
                <MDBContainer>
                  <MDBRow className="row1">
                    <MDBCol
                      lg="12"
                      className={
                        formData.individualGame.instructions !== ""
                          ? "col-12"
                          : "d-none"
                      }
                    >
                      <p className="pb-1 montserratFont gameFontSize">
                        Instructions
                      </p>
                      <div className="ql-editor " id="fixMargin">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formData.individualGame.instructions
                          }}
                          className="gameFontSize2"
                        ></p>
                      </div>
                    </MDBCol>
                    <MDBCol
                      lg="12"
                      className={
                        formData.individualGame.materials !== ""
                          ? "col-12"
                          : "d-none"
                      }
                    >
                      <p className="pb-1 montserratFont gameFontSize">
                        Game Materials Required
                      </p>
                      <div className="ql-editor " id="fixMargin">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formData.individualGame.materials
                          }}
                          className="gameFontSize2  text-left"
                        ></p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
                <hr />
                <Fragment>
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol
                        md="12"
                        className={
                          formData.individualGame.youtube.length !== 0
                            ? "mb-4"
                            : "d-none"
                        }
                      >
                        <Fragment>
                          <p className="pb-1 text-center montserratFont gameFontSize">
                            Youtube Videos
                          </p>
                        </Fragment>
                      </MDBCol>
                      {formData.individualGame.youtube.map((item, index) => {
                        const emb = urlParser.parse(item.url);
                        if (typeof emb === "undefined") {
                          return (
                            <MDBCol
                              key={index}
                              md="6"
                              className="mb-4 gameFontSize3"
                            >
                              <p>
                                A Youtube link is not showing since it is an
                                invalid link. Please add a valid link
                              </p>
                            </MDBCol>
                          );
                        } else {
                          const embUrl = urlParser.create({
                            videoInfo: emb,
                            format: "embed"
                          });

                          return (
                            <MDBCol key={index} md="6" className="mb-4">
                              <div className="embed-responsive embed-responsive-16by9 mb-1">
                                <iframe
                                  title="Embeds Page"
                                  className="embed-responsive-item"
                                  src={embUrl}
                                  allowFullScreen
                                />
                              </div>
                            </MDBCol>
                          );
                        }
                      })}
                    </MDBRow>
                    <MDBRow>
                      <MDBCol
                        md="12"
                        className={
                          formData.individualGame.links.length !== 0
                            ? "mb-4"
                            : "d-none"
                        }
                      >
                        <p className="text-center pb-1 montserratFont gameFontSize">
                          Website Links
                        </p>
                        {formData.individualGame.links.map((item, index) => {
                          const hrefgood = item.url.replace(
                            /(^\w+:|^)\/\//,
                            ""
                          );

                          return (
                            <ul
                              key={index}
                              className="fa-ul text-center quickSand"
                            >
                              <li className="list-inline-item">
                                <i className="fa-li fa fa-link" />

                                <a
                                  href={`http://${hrefgood}`}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <span className="gameFontSize3 quickSand">
                                    {item.title}
                                  </span>
                                </a>
                              </li>
                            </ul>
                          );
                        })}
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </Fragment>
              </div>

              <MDBModalFooter className="backgroundGames">
                <MDBBtn
                  className="quickSand"
                  onClick={createGameModal}
                  color="primary"
                >
                  Edit Game
                </MDBBtn>
                <MDBBtn
                  onClick={gameClickClose}
                  color="secondary"
                  className="mr-3 quickSand"
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </div>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          isOpen={formData.modalDeleteGame}
          toggle={deleteGameModalOpen}
        >
          <MDBModalHeader>Confirm Delete</MDBModalHeader>
          <MDBModalBody>
            Please confirm that you wish to delete this game
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              onClick={() => {
                setFormData({
                  ...formData,
                  modalDeleteGame: false
                });
              }}
              size="sm"
              color="dark"
            >
              Close
            </MDBBtn>
            <MDBBtn
              disabled={loadingDeleteGame}
              onClick={gameOnDelete}
              size="sm"
              color="danger"
            >
              {loadingDeleteGame === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Confirm
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  games: state.games
});

export default connect(mapStateToProps, {
  getGames,
  deleteGame,
  clearGamesReducer,
  searchGamesFunction,
  setPaginationModeGames,
  clearGamesSearchReducer,
  setLoadingDeleteGame
})(withRouter(GameData));
