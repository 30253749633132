import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import Spinner from "../layout/Spinner";

import { connect } from "react-redux";
import "./trading.css";
import "react-toastify/dist/ReactToastify.css";
import PrivateProfileView from "./PrivateProfileView";
import PublicProfileView from "./PublicProfileView";
import LessonView from "./middleSide/LessonView";
import ExamView from "./middleSide/ExamView";
import GameView from "./middleSide/GameView";
import FlashcardView from "./middleSide/FlashcardView";
import TopicView from "./middleSide/TopicView";
import SeriesView from "./middleSide/SeriesView";
import Publish from "./Publish";

import {
  searchLessonsFunction,
  searchTopicsFunction,
  searchExamsFunction,
  searchGamesFunction,
  searchDecksFunction,
  searchCurriculumsFunction,
  clearSearchReducer,
  clearFilterReducer,
  getMain,
  getPublishedLessons,
  getPublishedTopics,
  getPublishedExams,
  getPublishedGames,
  getPublishedDecks,
  getPublishedCurriculums,
  filterLessons,
  filterTopics,
  filterExams,
  filterGames,
  filterDecks,
  filterCurriculums,
  setPaginationMode
} from "../../actions/trading";

import {
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBPagination,
  MDBPageItem,
  MDBPageNav
} from "mdbreact";

const TradingCenter = ({
  history,
  mode,
  switchMode,
  publishType,
  publishTypeFunc,
  publishedLessons,
  publishedTopics,
  publishedExams,
  publishedGames,
  publishedDecks,
  publishedCurriculums,
  lessonContent,
  topicContent,
  examContent,
  gameContent,
  deckContent,
  searchLessonsFunction,
  searchTopicsFunction,
  searchExamsFunction,
  searchGamesFunction,
  searchDecksFunction,
  searchCurriculumsFunction,
  clearSearchReducer,
  clearFilterReducer,
  getPublishedLessons,
  getPublishedTopics,
  getPublishedExams,
  getPublishedGames,
  getPublishedDecks,
  getPublishedCurriculums,
  filterLessons,
  filterTopics,
  filterExams,
  filterGames,
  filterDecks,
  filterCurriculums,
  setPaginationMode,
  trading: {
    searchLessons,
    searchTopics,
    searchExams,
    searchGames,
    searchDecks,
    searchCurriculums,
    main,
    lessonsFilter,
    topicsFilter,
    examsFilter,
    gamesFilter,
    decksFilter,
    curriculumsFilter,
    initialNumberOfPages,
    initialPageNumber,
    numberOfPages,
    pageNumber,
    paginationMode,
    filterPreferences,
    keywords,
    loadingGetMain
  },
  getMain,
  publicProfile,
  modalDescription,
  modalFollowPublic,
  modalFollow,
  modalFollowing,
  modalReport
}) => {
  const [formData, setFormData] = useState({
    searchValueLessons: "",
    searchValueTopics: "",
    searchValueExams: "",
    searchValueDecks: "",
    searchValueGames: "",
    searchValueCurriculums: "",
    searchLessons: [],
    searchTopics: [],
    searchExams: [],
    searchGames: [],
    searchDecks: [],
    searchCurriculums: [],
    pageNumber: 1
  });

  const viewLesson = item => {
    // switchMode("lessonView");
    history.push(`/trading/lesson/${item._id}`);
  };
  const viewTopic = item => {
    history.push(`/trading/topic/${item._id}`);
  };
  const viewExam = item => {
    history.push(`/trading/exam/${item._id}`);
  };
  const viewGame = item => {
    history.push(`/trading/game/${item._id}`);
  };
  const viewDeck = item => {
    history.push(`/trading/deck/${item._id}`);
  };
  const viewCurriculum = item => {
    history.push(`/trading/curriculum/${item._id}`);
  };

  const onChangeSearch = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

  const lessonsSearchFunc = () => {
    clearFilterReducer();
    searchLessonsFunction({
      keywords: formData.searchValueLessons,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("search");
  };

  const topicsSearchFunc = () => {
    clearFilterReducer();
    searchTopicsFunction({
      keywords: formData.searchValueTopics,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("search");
  };

  const examsSearchFunc = () => {
    clearFilterReducer();
    searchExamsFunction({
      keywords: formData.searchValueExams,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("search");
  };

  const gamesSearchFunc = () => {
    clearFilterReducer();
    searchGamesFunction({
      keywords: formData.searchValueGames,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("search");
  };

  const decksSearchFunc = () => {
    clearFilterReducer();
    searchDecksFunction({
      keywords: formData.searchValueDecks,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("search");
  };

  const curriculumsSearchFunc = () => {
    clearFilterReducer();
    searchCurriculumsFunction({
      keywords: formData.searchValueCurriculums,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("search");
  };

  const clearLessonsFunc = () => {
    setFormData({
      ...formData,
      searchValueLessons: "",
      searchLessons: []
    });
    clearSearchReducer();
    clearFilterReducer();
    setPaginationMode("date");
    return publishedLessons;
  };

  const clearTopicsFunc = () => {
    setFormData({
      ...formData,
      searchValueTopics: "",
      searchTopics: []
    });
    clearSearchReducer();
    clearFilterReducer();
    setPaginationMode("date");
    return publishedTopics;
  };

  const clearExamsFunc = () => {
    setFormData({
      ...formData,
      searchValueExams: "",
      searchExams: []
    });
    clearSearchReducer();
    clearFilterReducer();
    setPaginationMode("date");
    return publishedExams;
  };

  const clearGamesFunc = () => {
    setFormData({
      ...formData,
      searchValueGames: "",
      searchGames: []
    });
    clearSearchReducer();
    clearFilterReducer();
    setPaginationMode("date");
    return publishedGames;
  };

  const clearDecksFunc = () => {
    setFormData({
      ...formData,
      searchValueDecks: "",
      searchDecks: []
    });
    clearSearchReducer();
    clearFilterReducer();
    setPaginationMode("date");
    return publishedDecks;
  };

  const clearCurriculumsFunc = () => {
    setFormData({
      ...formData,
      searchValueCurriculums: "",
      searchCurriculums: []
    });
    clearSearchReducer();
    clearFilterReducer();
    setPaginationMode("date");
    return publishedCurriculums;
  };

  useEffect(() => {
    if (searchLessons) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchLessons: searchLessons
        };
      });
    }
  }, [searchLessons]);

  useEffect(() => {
    if (searchTopics) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchTopics: searchTopics
        };
      });
    }
  }, [searchTopics]);

  useEffect(() => {
    if (searchExams) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchExams: searchExams
        };
      });
    }
  }, [searchExams]);

  useEffect(() => {
    if (searchGames) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchGames: searchGames
        };
      });
    }
  }, [searchGames]);

  useEffect(() => {
    if (searchDecks) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchDecks: searchDecks
        };
      });
    }
  }, [searchDecks]);

  useEffect(() => {
    if (searchCurriculums) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchCurriculums: searchCurriculums
        };
      });
    }
  }, [searchCurriculums]);

  // Mapping of different modules based on their properties

  const searchLessonsMap = () => {
    if (
      formData.searchLessons === "No Results" ||
      lessonsFilter === "No Results"
    ) {
      return [];
    } else if (lessonsFilter.length !== 0) {
      return lessonsFilter;
    } else if (formData.searchLessons.length !== 0) {
      return formData.searchLessons;
    } else {
      return publishedLessons;
    }
  };

  const searchTopicsMap = () => {
    if (
      formData.searchTopics === "No Results" ||
      topicsFilter === "No Results"
    ) {
      return [];
    } else if (topicsFilter.length !== 0) {
      return topicsFilter;
    } else if (formData.searchTopics.length !== 0) {
      return formData.searchTopics;
    } else {
      return publishedTopics;
    }
  };

  const searchExamsMap = () => {
    if (formData.searchExams === "No Results" || examsFilter === "No Results") {
      return [];
    } else if (examsFilter.length !== 0) {
      return examsFilter;
    } else if (formData.searchExams.length !== 0) {
      return formData.searchExams;
    } else {
      return publishedExams;
    }
  };
  const searchGamesMap = () => {
    if (formData.searchGames === "No Results" || gamesFilter === "No Results") {
      return [];
    } else if (gamesFilter.length !== 0) {
      return gamesFilter;
    } else if (formData.searchGames.length !== 0) {
      return formData.searchGames;
    } else {
      return publishedGames;
    }
  };

  const searchDecksMap = () => {
    if (formData.searchDecks === "No Results" || decksFilter === "No Results") {
      return [];
    } else if (decksFilter.length !== 0) {
      return decksFilter;
    } else if (formData.searchDecks.length !== 0) {
      return formData.searchDecks;
    } else {
      return publishedDecks;
    }
  };
  const searchCurriculumsMap = () => {
    if (
      formData.searchCurriculums === "No Results" ||
      curriculumsFilter === "No Results"
    ) {
      return [];
    } else if (curriculumsFilter.length !== 0) {
      return curriculumsFilter;
    } else if (formData.searchCurriculums.length !== 0) {
      return formData.searchCurriculums;
    } else {
      return publishedCurriculums;
    }
  };

  useEffect(() => {
    getMain();
  }, [getMain]);

  // Pagination

  const isActive1 = page => {
    if (initialPageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationLessons = () => {
    if (paginationMode === "date") {
      getPublishedLessons({ initialPageNumber: "first" });
    } else if (paginationMode === "search") {
      searchLessonsFunction({
        pageNumber: "first",
        keywords: formData.searchValueLessons
      });
    } else if (paginationMode === "filter") {
      filterLessons({
        pageNumber: "first",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const previousPaginationLessons = () => {
    if (paginationMode === "date") {
      getPublishedLessons({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationMode === "search") {
      searchLessonsFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchValueLessons
      });
    } else if (paginationMode === "filter") {
      filterLessons({
        pageNumber: pageNumber - 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const currentPaginationLessons = index => {
    if (paginationMode === "date") {
      getPublishedLessons({ initialPageNumber: index + 1 });
    } else if (paginationMode === "search") {
      searchLessonsFunction({
        pageNumber: index + 1,
        keywords: formData.searchValueLessons
      });
    } else if (paginationMode === "filter") {
      filterLessons({
        pageNumber: index + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const nextPaginationLessons = () => {
    if (paginationMode === "date") {
      getPublishedLessons({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationMode === "search") {
      searchLessonsFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchValueLessons
      });
    } else if (paginationMode === "filter") {
      filterLessons({
        pageNumber: pageNumber + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const lastPaginationLessons = () => {
    if (paginationMode === "date") {
      getPublishedLessons({ initialPageNumber: "last" });
    } else if (paginationMode === "search") {
      searchLessonsFunction({
        pageNumber: "last",
        keywords: formData.searchValueLessons
      });
    } else if (paginationMode === "filter") {
      filterLessons({
        pageNumber: "last",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const firstPaginationTopics = () => {
    if (paginationMode === "date") {
      getPublishedTopics({ initialPageNumber: "first" });
    } else if (paginationMode === "search") {
      searchTopicsFunction({
        pageNumber: "first",
        keywords: formData.searchValueTopics
      });
    } else if (paginationMode === "filter") {
      filterTopics({
        pageNumber: "first",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const previousPaginationTopics = () => {
    if (paginationMode === "date") {
      getPublishedTopics({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationMode === "search") {
      searchTopicsFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchValueTopics
      });
    } else if (paginationMode === "filter") {
      filterTopics({
        pageNumber: pageNumber - 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const currentPaginationTopics = index => {
    if (paginationMode === "date") {
      getPublishedTopics({ initialPageNumber: index + 1 });
    } else if (paginationMode === "search") {
      searchTopicsFunction({
        pageNumber: index + 1,
        keywords: formData.searchValueTopics
      });
    } else if (paginationMode === "filter") {
      filterTopics({
        pageNumber: index + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const nextPaginationTopics = () => {
    if (paginationMode === "date") {
      getPublishedTopics({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationMode === "search") {
      searchTopicsFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchValueTopics
      });
    } else if (paginationMode === "filter") {
      filterTopics({
        pageNumber: pageNumber + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const lastPaginationTopics = () => {
    if (paginationMode === "date") {
      getPublishedTopics({ initialPageNumber: "last" });
    } else if (paginationMode === "search") {
      searchTopicsFunction({
        pageNumber: "last",
        keywords: formData.searchValueTopics
      });
    } else if (paginationMode === "filter") {
      filterTopics({
        pageNumber: "last",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };
  const firstPaginationExams = () => {
    if (paginationMode === "date") {
      getPublishedExams({ initialPageNumber: "first" });
    } else if (paginationMode === "search") {
      searchExamsFunction({
        pageNumber: "first",
        keywords: formData.searchValueExams
      });
    } else if (paginationMode === "filter") {
      filterExams({
        pageNumber: "first",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const previousPaginationExams = () => {
    if (paginationMode === "date") {
      getPublishedExams({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationMode === "search") {
      searchExamsFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchValueExams
      });
    } else if (paginationMode === "filter") {
      filterExams({
        pageNumber: pageNumber - 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const currentPaginationExams = index => {
    if (paginationMode === "date") {
      getPublishedExams({ initialPageNumber: index + 1 });
    } else if (paginationMode === "search") {
      searchExamsFunction({
        pageNumber: index + 1,
        keywords: formData.searchValueExams
      });
    } else if (paginationMode === "filter") {
      filterExams({
        pageNumber: index + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const nextPaginationExams = () => {
    if (paginationMode === "date") {
      getPublishedExams({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationMode === "search") {
      searchExamsFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchValueExams
      });
    } else if (paginationMode === "filter") {
      filterExams({
        pageNumber: pageNumber + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const lastPaginationExams = () => {
    if (paginationMode === "date") {
      getPublishedExams({ initialPageNumber: "last" });
    } else if (paginationMode === "search") {
      searchExamsFunction({
        pageNumber: "last",
        keywords: formData.searchValueExams
      });
    } else if (paginationMode === "filter") {
      filterExams({
        pageNumber: "last",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };
  const firstPaginationGames = () => {
    if (paginationMode === "date") {
      getPublishedGames({ initialPageNumber: "first" });
    } else if (paginationMode === "search") {
      searchGamesFunction({
        pageNumber: "first",
        keywords: formData.searchValueGames
      });
    } else if (paginationMode === "filter") {
      filterGames({
        pageNumber: "first",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const previousPaginationGames = () => {
    if (paginationMode === "date") {
      getPublishedGames({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationMode === "search") {
      searchGamesFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchValueGames
      });
    } else if (paginationMode === "filter") {
      filterGames({
        pageNumber: pageNumber - 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const currentPaginationGames = index => {
    if (paginationMode === "date") {
      getPublishedGames({ initialPageNumber: index + 1 });
    } else if (paginationMode === "search") {
      searchGamesFunction({
        pageNumber: index + 1,
        keywords: formData.searchValueGames
      });
    } else if (paginationMode === "filter") {
      filterGames({
        pageNumber: index + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const nextPaginationGames = () => {
    if (paginationMode === "date") {
      getPublishedGames({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationMode === "search") {
      searchGamesFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchValueGames
      });
    } else if (paginationMode === "filter") {
      filterGames({
        pageNumber: pageNumber + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const lastPaginationGames = () => {
    if (paginationMode === "date") {
      getPublishedGames({ initialPageNumber: "last" });
    } else if (paginationMode === "search") {
      searchGamesFunction({
        pageNumber: "last",
        keywords: formData.searchValueGames
      });
    } else if (paginationMode === "filter") {
      filterGames({
        pageNumber: "last",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };
  const firstPaginationDecks = () => {
    if (paginationMode === "date") {
      getPublishedDecks({ initialPageNumber: "first" });
    } else if (paginationMode === "search") {
      searchDecksFunction({
        pageNumber: "first",
        keywords: formData.searchValueDecks
      });
    } else if (paginationMode === "filter") {
      filterDecks({
        pageNumber: "first",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const previousPaginationDecks = () => {
    if (paginationMode === "date") {
      getPublishedDecks({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationMode === "search") {
      searchDecksFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchValueDecks
      });
    } else if (paginationMode === "filter") {
      filterDecks({
        pageNumber: pageNumber - 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const currentPaginationDecks = index => {
    if (paginationMode === "date") {
      getPublishedDecks({ initialPageNumber: index + 1 });
    } else if (paginationMode === "search") {
      searchDecksFunction({
        pageNumber: index + 1,
        keywords: formData.searchValueDecks
      });
    } else if (paginationMode === "filter") {
      filterDecks({
        pageNumber: index + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const nextPaginationDecks = () => {
    if (paginationMode === "date") {
      getPublishedDecks({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationMode === "search") {
      searchDecksFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchValueDecks
      });
    } else if (paginationMode === "filter") {
      filterDecks({
        pageNumber: pageNumber + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const lastPaginationDecks = () => {
    if (paginationMode === "date") {
      getPublishedDecks({ initialPageNumber: "last" });
    } else if (paginationMode === "search") {
      searchDecksFunction({
        pageNumber: "last",
        keywords: formData.searchValueDecks
      });
    } else if (paginationMode === "filter") {
      filterDecks({
        pageNumber: "last",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const firstPaginationCurriculums = () => {
    if (paginationMode === "date") {
      getPublishedCurriculums({ initialPageNumber: "first" });
    } else if (paginationMode === "search") {
      searchCurriculumsFunction({
        pageNumber: "first",
        keywords: formData.searchValueCurriculums
      });
    } else if (paginationMode === "filter") {
      filterCurriculums({
        pageNumber: "first",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const previousPaginationCurriculums = () => {
    if (paginationMode === "date") {
      getPublishedCurriculums({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationMode === "search") {
      searchCurriculumsFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchValueCurriculums
      });
    } else if (paginationMode === "filter") {
      filterCurriculums({
        pageNumber: pageNumber - 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const currentPaginationCurriculums = index => {
    if (paginationMode === "date") {
      getPublishedCurriculums({ initialPageNumber: index + 1 });
    } else if (paginationMode === "search") {
      searchCurriculumsFunction({
        pageNumber: index + 1,
        keywords: formData.searchValueCurriculums
      });
    } else if (paginationMode === "filter") {
      filterCurriculums({
        pageNumber: index + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const nextPaginationCurriculums = () => {
    if (paginationMode === "date") {
      getPublishedCurriculums({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationMode === "search") {
      searchCurriculumsFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchValueCurriculums
      });
    } else if (paginationMode === "filter") {
      filterCurriculums({
        pageNumber: pageNumber + 1,
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  const lastPaginationCurriculums = () => {
    if (paginationMode === "date") {
      getPublishedCurriculums({ initialPageNumber: "last" });
    } else if (paginationMode === "search") {
      searchCurriculumsFunction({
        pageNumber: "last",
        keywords: formData.searchValueCurriculums
      });
    } else if (paginationMode === "filter") {
      filterCurriculums({
        pageNumber: "last",
        date: filterPreferences.date,
        rating: filterPreferences.rating,
        level: filterPreferences.level,
        keywords: keywords
      });
    }
  };

  return loadingGetMain && mode === "main" ? (
    <Spinner></Spinner>
  ) : (
    // Main

    <Fragment>
      <MDBCard
        style={{ height: "700px" }}
        className="mx-1 my-1 tradingBackgroundColor"
      >
        <Scrollbars autoHide autoHideTimeout={2000}>
          <MDBCardBody>
            {mode === "main" ? (
              <div>
                <MDBRow center>
                  <p className="mt-1 livvicFont h3 text-center text-white">
                    Recent Modules
                  </p>
                </MDBRow>
                {main.lesson &&
                main.topic &&
                main.exam &&
                main.game &&
                main.deck &&
                main.curriculum ? (
                  <MDBRow className="middleVertical">
                    <MDBCol lg="6">
                      <MDBCard
                        style={{ minHeight: "200px" }}
                        className="mx-2 my-2 tradingCard"
                      >
                        <MDBCol size="12">
                          <MDBRow className="mx-1 my-2">
                            <img
                              style={{ width: "45px" }}
                              src={main.lesson.userTutor.avatar.url}
                              alt=""
                              className="rounded-circle z-depth-1-half"
                            />
                            <p className="mt-1 quickSand h6 text-black middleVertical pl-2">
                              {main.lesson.userTutor.screenName} posted a new
                              lesson.
                            </p>
                          </MDBRow>
                          <MDBRow center>
                            <MDBCol>
                              <p className=" text-black text-center quickSand h4 fontWeightNormal text-truncate">
                                {main.lesson.title}
                              </p>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow center className="pt-1">
                            <MDBCol size="12" className="text-center">
                              <MDBBtn
                                size="sm"
                                className="lessonButton"
                                rounded
                                color="secondary"
                                onClick={() =>
                                  viewLesson({ _id: main.lesson._id })
                                }
                              >
                                See Module
                              </MDBBtn>
                            </MDBCol>
                            <MDBCol size="12" className="text-right">
                              <p className="text-black quickSand  fontWeightNormal dateFont">
                                {moment(main.lesson.date, "x").fromNow()}
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol lg="6">
                      <MDBCard
                        style={{ minHeight: "200px" }}
                        className="mx-2 my-2 tradingCard5"
                      >
                        <MDBCol size="12">
                          <MDBRow className="mx-1 my-2">
                            <img
                              style={{ width: "45px" }}
                              src={main.topic.userTutor.avatar.url}
                              alt=""
                              className="rounded-circle z-depth-1-half"
                            />
                            <p className="mt-1 quickSand h6 text-black middleVertical pl-2">
                              {main.topic.userTutor.screenName} posted a new
                              topic.
                            </p>
                          </MDBRow>
                          <MDBRow center>
                            <MDBCol>
                              <p className="text-black text-center quickSand h4 fontWeightNormal text-truncate">
                                {main.topic.title}
                              </p>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow center className="pt-1">
                            <MDBCol size="12" className="text-center">
                              <MDBBtn
                                size="sm"
                                rounded
                                className="topicButton"
                                onClick={() =>
                                  viewTopic({ _id: main.topic._id })
                                }
                              >
                                See Module
                              </MDBBtn>
                            </MDBCol>
                            <MDBCol size="12" className="text-right">
                              <p className="text-black quickSand  fontWeightNormal dateFont">
                                {moment(main.topic.date, "x").fromNow()}
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                ) : (
                  <div className="mx-2 text-center mb-2">
                    <img
                      src={
                        "https://res.cloudinary.com/unnamed/image/upload/v1579721886/Trading_System_Image_Small_fa8f6h.png"
                      }
                      alt=""
                      style={{ width: "80%" }}
                    />
                  </div>
                )}

                {main.lesson &&
                main.topic &&
                main.exam &&
                main.game &&
                main.deck &&
                main.curriculum ? (
                  <MDBRow className="middleVertical">
                    <MDBCol lg="6">
                      <MDBCard
                        style={{ minHeight: "200px" }}
                        className="mx-2 my-2 tradingCard2"
                      >
                        <MDBCol size="12">
                          <MDBRow className="mx-1 my-2">
                            <img
                              style={{ width: "45px" }}
                              src={main.deck.userTutor.avatar.url}
                              alt=""
                              className="rounded-circle z-depth-1-half"
                            />
                            <p className="mt-1 quickSand h6 text-black middleVertical pl-2">
                              {main.deck.userTutor.screenName} posted a new
                              Deck.
                            </p>
                          </MDBRow>
                          <MDBRow center>
                            <MDBCol>
                              <p className="text-black text-center quickSand h4 fontWeightNormal text-truncate">
                                {main.deck.name}
                              </p>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow center className="pt-1">
                            <MDBCol size="12" className="text-center">
                              <MDBBtn
                                size="sm"
                                rounded
                                className="flashCardButton"
                                onClick={() => viewDeck({ _id: main.deck._id })}
                              >
                                See Module
                              </MDBBtn>
                            </MDBCol>
                            <MDBCol size="12" className="text-right">
                              <p className="text-black quickSand  fontWeightNormal dateFont">
                                {moment(main.deck.date, "x").fromNow()}
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol lg="6">
                      <MDBCard
                        style={{ minHeight: "200px" }}
                        className="mx-2 my-2 tradingCard3"
                      >
                        <MDBCol size="12">
                          <MDBRow className="mx-1 my-2">
                            <img
                              style={{ width: "45px" }}
                              src={main.exam.userTutor.avatar.url}
                              alt=""
                              className="rounded-circle z-depth-1-half"
                            />
                            <p className="mt-1 quickSand h6 text-black middleVertical pl-2">
                              {main.exam.userTutor.screenName} posted a new
                              exam.
                            </p>
                          </MDBRow>
                          <MDBRow center>
                            <MDBCol>
                              <p className="text-black text-center quickSand h4 fontWeightNormal text-truncate">
                                {main.exam.name}
                              </p>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow center className="pt-1">
                            <MDBCol size="12" className="text-center">
                              <MDBBtn
                                size="sm"
                                className="lessonButton"
                                rounded
                                color="light-blue"
                                onClick={() => viewExam({ _id: main.exam._id })}
                              >
                                See Module
                              </MDBBtn>
                            </MDBCol>
                            <MDBCol size="12" className="text-right">
                              <p className="text-black quickSand  fontWeightNormal dateFont">
                                {moment(main.exam.date, "x").fromNow()}
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol lg="6">
                      <MDBCard
                        style={{ minHeight: "200px" }}
                        className="mx-2 my-2 tradingCard1"
                      >
                        <MDBCol size="12">
                          <MDBRow className="mx-1 my-2">
                            <img
                              style={{ width: "45px" }}
                              src={main.game.userTutor.avatar.url}
                              alt=""
                              className="rounded-circle z-depth-1-half"
                            />
                            <p className="mt-1 quickSand h6 text-black middleVertical pl-2">
                              {main.game.userTutor.screenName} posted a new
                              game.
                            </p>
                          </MDBRow>
                          <MDBRow center>
                            <MDBCol>
                              <p className="text-black text-center quickSand h4 fontWeightNormal text-truncate">
                                {main.game.title}
                              </p>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow center className="pt-1">
                            <MDBCol size="12" className="text-center">
                              <MDBBtn
                                size="sm"
                                className="gameButton"
                                rounded
                                onClick={() => viewGame({ _id: main.game._id })}
                              >
                                See Module
                              </MDBBtn>
                            </MDBCol>
                            <MDBCol size="12" className="text-right">
                              <p className="text-black quickSand  fontWeightNormal dateFont">
                                {moment(main.game.date, "x").fromNow()}
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol lg="6">
                      <MDBCard
                        style={{ minHeight: "200px" }}
                        className="mx-2 my-2 tradingCard4"
                      >
                        <MDBCol size="12">
                          <MDBRow className="mx-1 my-2">
                            <img
                              style={{ width: "45px" }}
                              src={main.curriculum.userTutor.avatar.url}
                              alt=""
                              className="rounded-circle z-depth-1-half"
                            />
                            <p className="mt-1 quickSand h6 text-black middleVertical pl-2">
                              {main.curriculum.userTutor.screenName} posted a
                              new curriculum.
                            </p>
                          </MDBRow>
                          <MDBRow center>
                            <MDBCol>
                              <p className="text-black text-center quickSand h4 fontWeightNormal text-truncate">
                                {main.curriculum.title}
                              </p>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow center className="pt-1">
                            <MDBCol size="12" className="text-center">
                              <MDBBtn
                                size="sm"
                                className="lessonButton"
                                rounded
                                color="dark-green"
                                onClick={() =>
                                  viewCurriculum({ _id: main.curriculum._id })
                                }
                              >
                                See Module
                              </MDBBtn>
                            </MDBCol>
                            <MDBCol size="12" className="text-right">
                              <p className="text-black quickSand  fontWeightNormal dateFont">
                                {moment(main.curriculum.date, "x").fromNow()}
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                ) : null}
              </div>
            ) : null}

            {/* Lessons Center */}
            {mode === "lessons" ? (
              <div className={"zIndexPaginationTrading"}>
                <MDBCol size="12">
                  <MDBRow center>
                    <p className="mt-3 duruSans h5 text-center text-white">
                      Lessons
                    </p>
                  </MDBRow>
                  <MDBRow center className="middleVertical5">
                    <MDBCol className="col-2" sm="2"></MDBCol>
                    <MDBCol sm="4" className="col-8">
                      {" "}
                      <MDBInput
                        name="searchValueLessons"
                        className="white-text quickSand mt-3"
                        label="Search for lessons"
                        labelClass="white-text"
                        type="text"
                        containerClass="active-pink active-pink-2 mt-0 mb-3"
                        value={formData.searchValueLessons}
                        onChange={onChangeSearch}
                        onKeyPress={e => {
                          if (e.key === "Enter") {
                            lessonsSearchFunc();
                          }
                        }}
                      />
                    </MDBCol>
                    <MDBCol className="col-2" sm="2">
                      <MDBRow className="align-items-center">
                        <div
                          className={
                            formData.searchValueLessons.length !== 0
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="search"
                            onClick={lessonsSearchFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className={
                            formData.searchLessons.length !== 0 ||
                            formData.searchLessons === "No Results"
                              ? ""
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="times-circle"
                            onClick={clearLessonsFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>

                  <div
                    className={
                      formData.searchLessons === "No Results"
                        ? "mt-2"
                        : "d-none"
                    }
                  >
                    <p className="quickSand h4 black-text text-center">
                      There are no lesson results for your search. Try Again.
                    </p>
                  </div>

                  {searchLessonsMap().map((item, index) => {
                    return (
                      <MDBRow
                        key={item._id}
                        className="mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => viewLesson(item)}
                      >
                        <MDBCol size="12">
                          <MDBCard
                            style={{ minHeight: "20px" }}
                            className="mx-2  tradingCard"
                          >
                            <MDBRow
                              style={{ marginBottom: "1rem" }}
                              className="mb-1"
                            >
                              <MDBCol size="12">
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <img
                                    style={{ width: "37px" }}
                                    src={item.userTutor.avatar.url}
                                    alt=""
                                    className="rounded-circle z-depth-1-half"
                                  />
                                  <p className=" ml-2 text-black text-center quickSand h5 fontWeightNormal text-truncate">
                                    {item.title}
                                  </p>
                                </MDBRow>
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <p className=" ml-2 text-black quickSand  fontWeightNormal dateFont text-truncate">
                                    {item.description}
                                  </p>
                                </MDBRow>
                                <MDBRow center className=" mx-2">
                                  <MDBCol className="text-left ">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {item.level}
                                    </p>
                                  </MDBCol>
                                  <MDBCol className="text-right">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {moment(item.date, "x").fromNow()}
                                    </p>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}
                </MDBCol>
              </div>
            ) : null}

            {/* Topics Center  */}
            {mode === "topics" ? (
              <div>
                <MDBCol size="12">
                  <MDBRow center>
                    <p className="mt-3 duruSans h5 text-center text-white">
                      Topics
                    </p>
                  </MDBRow>
                  <MDBRow center className="middleVertical5">
                    <MDBCol className="col-2" sm="2"></MDBCol>
                    <MDBCol sm="4" className="col-6">
                      <MDBInput
                        name="searchValueTopics"
                        className="white-text quickSand mt-3"
                        label="Search for topics"
                        labelClass="white-text"
                        type="text"
                        containerClass="active-pink active-pink-2 mt-0 mb-3"
                        value={formData.searchValueTopics}
                        onChange={onChangeSearch}
                        onKeyPress={e => {
                          if (e.key === "Enter") {
                            topicsSearchFunc();
                          }
                        }}
                      />
                    </MDBCol>
                    <MDBCol className="col-2" sm="2">
                      <MDBRow className="align-items-center">
                        <div
                          className={
                            formData.searchValueTopics.length !== 0
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="search"
                            onClick={topicsSearchFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className={
                            formData.searchTopics.length !== 0 ||
                            formData.searchTopics === "No Results"
                              ? ""
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="times-circle"
                            onClick={clearTopicsFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <div
                    className={
                      formData.searchTopics === "No Results" ? "mt-2" : "d-none"
                    }
                  >
                    <p className="quickSand h4 black-text text-center">
                      There are no topic results for your search. Try Again.
                    </p>
                  </div>
                  {searchTopicsMap().map((item, index) => {
                    return (
                      <MDBRow
                        key={item._id}
                        className="mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => viewTopic(item)}
                      >
                        <MDBCol size="12">
                          <MDBCard
                            style={{ minHeight: "20px" }}
                            className="mx-2  tradingCard5"
                          >
                            <MDBRow
                              style={{ marginBottom: "1rem" }}
                              className="mb-1"
                            >
                              <MDBCol size="12">
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <img
                                    style={{ width: "37px" }}
                                    src={item.userTutor.avatar.url}
                                    alt=""
                                    className="rounded-circle z-depth-1-half"
                                  />
                                  <p className=" ml-2 text-black text-center quickSand h5 fontWeightNormal text-truncate">
                                    {item.title}
                                  </p>
                                </MDBRow>
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <p className=" ml-2 text-black quickSand  fontWeightNormal dateFont text-truncate">
                                    {item.description}
                                  </p>
                                </MDBRow>
                                <MDBRow center className=" mx-2">
                                  <MDBCol className="text-left ">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {item.level}
                                    </p>
                                  </MDBCol>
                                  <MDBCol className="text-right">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {moment(item.date, "x").fromNow()}
                                    </p>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}
                </MDBCol>
              </div>
            ) : null}

            {/* Exams Center  */}
            {mode === "exams" ? (
              <div>
                <MDBCol size="12">
                  <MDBRow center>
                    <p className="mt-3 duruSans h5 text-center text-white">
                      Exams
                    </p>
                  </MDBRow>
                  <MDBRow center className="middleVertical5">
                    <MDBCol className="col-2" sm="2"></MDBCol>
                    <MDBCol sm="4" className="col-6">
                      <MDBInput
                        name="searchValueExams"
                        className="white-text quickSand mt-3"
                        label="Search for exams"
                        labelClass="white-text"
                        type="text"
                        containerClass="active-pink active-pink-2 mt-0 mb-3"
                        value={formData.searchValueExams}
                        onChange={onChangeSearch}
                        onKeyPress={e => {
                          if (e.key === "Enter") {
                            examsSearchFunc();
                          }
                        }}
                      />
                    </MDBCol>
                    <MDBCol className="col-2" sm="2">
                      <MDBRow className="align-items-center">
                        <div
                          className={
                            formData.searchValueExams.length !== 0
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="search"
                            onClick={examsSearchFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className={
                            formData.searchExams.length !== 0 ||
                            formData.searchExams === "No Results"
                              ? ""
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="times-circle"
                            onClick={clearExamsFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <div
                    className={
                      formData.searchExams === "No Results" ? "mt-2" : "d-none"
                    }
                  >
                    <p className="quickSand h4 black-text text-center">
                      There are no exam results for your search. Try Again.
                    </p>
                  </div>
                  {searchExamsMap().map((item, index) => {
                    return (
                      <MDBRow
                        key={item._id}
                        className="mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => viewExam(item)}
                      >
                        <MDBCol size="12">
                          <MDBCard
                            style={{ minHeight: "20px" }}
                            className="mx-2  tradingCard3"
                          >
                            <MDBRow
                              style={{ marginBottom: "1rem" }}
                              className="mb-1"
                            >
                              <MDBCol size="12">
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <img
                                    style={{ width: "37px" }}
                                    src={item.userTutor.avatar.url}
                                    alt=""
                                    className="rounded-circle z-depth-1-half"
                                  />
                                  <p className=" ml-2 text-black text-center quickSand h5 fontWeightNormal text-truncate">
                                    {item.name}
                                  </p>
                                </MDBRow>
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <p className=" ml-2 text-black quickSand  fontWeightNormal dateFont text-truncate">
                                    {item.description}
                                  </p>
                                </MDBRow>
                                <MDBRow center className=" mx-2">
                                  <MDBCol className="text-left ">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {item.englishLevel.join(", ")}
                                    </p>
                                  </MDBCol>
                                  <MDBCol className="text-right">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {moment(item.date, "x").fromNow()}
                                    </p>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}
                </MDBCol>
              </div>
            ) : null}

            {/* Games Center  */}
            {mode === "games" ? (
              <div>
                <MDBCol size="12">
                  <MDBRow center>
                    <p className="mt-3 duruSans h5 text-center text-white">
                      Games
                    </p>
                  </MDBRow>

                  <MDBRow center className="middleVertical5">
                    <MDBCol className="col-2" sm="2"></MDBCol>
                    <MDBCol sm="4" className="col-6">
                      <MDBInput
                        name="searchValueGames"
                        className="white-text quickSand mt-3"
                        label="Search for games"
                        labelClass="white-text"
                        type="text"
                        containerClass="active-pink active-pink-2 mt-0 mb-3"
                        value={formData.searchValueGames}
                        onChange={onChangeSearch}
                        onKeyPress={e => {
                          if (e.key === "Enter") {
                            gamesSearchFunc();
                          }
                        }}
                      />
                    </MDBCol>
                    <MDBCol className="col-2" sm="2">
                      <MDBRow className="align-items-center">
                        <div
                          className={
                            formData.searchValueGames.length !== 0
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="search"
                            onClick={gamesSearchFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className={
                            formData.searchGames.length !== 0 ||
                            formData.searchGames === "No Results"
                              ? ""
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="times-circle"
                            onClick={clearGamesFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <div
                    className={
                      formData.searchGames === "No Results" ? "mt-2" : "d-none"
                    }
                  >
                    <p className="quickSand h4 black-text text-center">
                      There are no game results for your search. Try Again.
                    </p>
                  </div>
                  {searchGamesMap().map((item, index) => {
                    return (
                      <MDBRow
                        key={item._id}
                        className="mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => viewGame(item)}
                      >
                        <MDBCol size="12">
                          <MDBCard
                            style={{ minHeight: "20px" }}
                            className="mx-2  tradingCard1"
                          >
                            <MDBRow
                              style={{ marginBottom: "1rem" }}
                              className="mb-1"
                            >
                              <MDBCol size="12">
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <img
                                    style={{ width: "37px" }}
                                    src={item.userTutor.avatar.url}
                                    alt=""
                                    className="rounded-circle z-depth-1-half"
                                  />
                                  <p className=" ml-2 text-black text-center quickSand h5 fontWeightNormal text-truncate">
                                    {item.title}
                                  </p>
                                </MDBRow>
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <p className=" ml-2 text-black quickSand  fontWeightNormal dateFont text-truncate">
                                    {item.description}
                                  </p>
                                </MDBRow>
                                <MDBRow center className=" mx-2">
                                  <MDBCol className="text-left ">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {item.level}
                                    </p>
                                  </MDBCol>
                                  <MDBCol className="text-right">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {moment(item.date, "x").fromNow()}
                                    </p>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}
                </MDBCol>
              </div>
            ) : null}

            {/* Flashcards Center */}
            {mode === "flashcards" ? (
              <div>
                <MDBCol size="12">
                  <MDBRow center>
                    <p className="mt-3 duruSans h5 text-center text-white">
                      Flashcards
                    </p>
                  </MDBRow>
                  <MDBRow center className="middleVertical5">
                    <MDBCol className="col-2" sm="2"></MDBCol>
                    <MDBCol sm="4" className="col-6">
                      <MDBInput
                        name="searchValueDecks"
                        className="white-text quickSand mt-3"
                        label="Search for decks"
                        labelClass="white-text"
                        type="text"
                        containerClass="active-pink active-pink-2 mt-0 mb-3"
                        value={formData.searchValueDecks}
                        onChange={onChangeSearch}
                        onKeyPress={e => {
                          if (e.key === "Enter") {
                            decksSearchFunc();
                          }
                        }}
                      />
                    </MDBCol>
                    <MDBCol className="col-2" sm="2">
                      <MDBRow className="align-items-center">
                        <div
                          className={
                            formData.searchValueDecks.length !== 0
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="search"
                            onClick={decksSearchFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className={
                            formData.searchDecks.length !== 0 ||
                            formData.searchDecks === "No Results"
                              ? ""
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="times-circle"
                            onClick={clearDecksFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>

                  <div
                    className={
                      formData.searchDecks === "No Results" ? "mt-2" : "d-none"
                    }
                  >
                    <p className="quickSand h4 black-text text-center">
                      There are no flashcard results for your search. Try Again.
                    </p>
                  </div>

                  {searchDecksMap().map((item, index) => {
                    return (
                      <MDBRow
                        key={item._id}
                        className="mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => viewDeck(item)}
                      >
                        <MDBCol size="12">
                          <MDBCard
                            style={{ minHeight: "20px" }}
                            className="mx-2  tradingCard2"
                          >
                            <MDBRow
                              style={{ marginBottom: "1rem" }}
                              className="mb-1"
                            >
                              <MDBCol size="12">
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <img
                                    style={{ width: "37px" }}
                                    src={item.userTutor.avatar.url}
                                    alt=""
                                    className="rounded-circle z-depth-1-half"
                                  />
                                  <p className=" ml-2 text-black text-center quickSand h5 fontWeightNormal text-truncate">
                                    {item.name}
                                  </p>
                                </MDBRow>
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <p className=" ml-2 text-black quickSand  fontWeightNormal dateFont text-truncate">
                                    {item.description}
                                  </p>
                                </MDBRow>
                                <MDBRow center className=" mx-2">
                                  <MDBCol className="text-left ">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {item.englishLevel.join(", ")}
                                    </p>
                                  </MDBCol>
                                  <MDBCol className="text-right">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {moment(item.date, "x").fromNow()}
                                    </p>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}
                </MDBCol>
              </div>
            ) : null}

            {/* Series Center  */}
            {mode === "series" ? (
              <div>
                <MDBCol size="12">
                  <MDBRow center>
                    <p className="mt-3 duruSans h5 text-center text-white">
                      Curriculums
                    </p>
                  </MDBRow>
                  <MDBRow center className="middleVertical5">
                    <MDBCol className="col-2" sm="2"></MDBCol>
                    <MDBCol sm="4" className="col-8">
                      <MDBInput
                        name="searchValueCurriculums"
                        className="white-text quickSand mt-3"
                        label="Search for curriculums"
                        labelClass="white-text"
                        type="text"
                        containerClass="active-pink active-pink-2 mt-0 mb-3"
                        value={formData.searchValueCurriculums}
                        onChange={onChangeSearch}
                        onKeyPress={e => {
                          if (e.key === "Enter") {
                            curriculumsSearchFunc();
                          }
                        }}
                      />
                    </MDBCol>
                    <MDBCol className="col-2" sm="2">
                      <MDBRow className="align-items-center">
                        <div
                          className={
                            formData.searchValueCurriculums.length !== 0
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="search"
                            onClick={curriculumsSearchFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className={
                            formData.searchCurriculums.length !== 0 ||
                            formData.searchCurriculums === "No Results"
                              ? ""
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="times-circle"
                            onClick={clearCurriculumsFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>

                  <div
                    className={
                      formData.searchCurriculums === "No Results"
                        ? "mt-2"
                        : "d-none"
                    }
                  >
                    <p className="quickSand h4 black-text text-center">
                      There are no curriculum results for your search. Try
                      Again.
                    </p>
                  </div>

                  {searchCurriculumsMap().map((item, index) => {
                    return (
                      <MDBRow
                        key={item._id}
                        className="mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => viewCurriculum(item)}
                      >
                        <MDBCol size="12">
                          <MDBCard
                            style={{ minHeight: "20px" }}
                            className="mx-2  tradingCard4"
                          >
                            <MDBRow
                              style={{ marginBottom: "1rem" }}
                              className="mb-1"
                            >
                              <MDBCol size="12">
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <img
                                    style={{ width: "37px" }}
                                    src={item.userTutor.avatar.url}
                                    alt=""
                                    className="rounded-circle z-depth-1-half"
                                  />
                                  <p className=" ml-2 text-black text-center quickSand h5 fontWeightNormal text-truncate">
                                    {item.title}
                                  </p>
                                </MDBRow>
                                <MDBRow className="mx-1 mt-1  middleVertical5">
                                  <p className=" ml-2 text-black quickSand  fontWeightNormal dateFont text-truncate">
                                    {item.description}
                                  </p>
                                </MDBRow>
                                <MDBRow center className=" mx-2">
                                  <MDBCol className="text-left ">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {item.level}
                                    </p>
                                  </MDBCol>
                                  <MDBCol className="text-right">
                                    <p className="text-black quickSand  fontWeightNormal dateFont">
                                      {moment(item.date, "x").fromNow()}
                                    </p>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}
                </MDBCol>
              </div>
            ) : null}

            {mode === "lessonView" ? (
              <div>
                <LessonView lessonContent={lessonContent}></LessonView>
              </div>
            ) : null}

            {mode === "privateProfile" ? (
              <div>
                <PrivateProfileView
                  modalDescription={modalDescription}
                  switchMode={switchMode}
                  modalFollow={modalFollow}
                  modalFollowing={modalFollowing}
                ></PrivateProfileView>
              </div>
            ) : null}
            {mode === "publicProfile" ? (
              <div>
                <PublicProfileView
                  modalFollowPublic={modalFollowPublic}
                  publicProfile={publicProfile}
                  switchMode={switchMode}
                  modalReport={modalReport}
                ></PublicProfileView>
              </div>
            ) : null}

            {mode === "topicView" ? (
              <div>
                <TopicView topicContent={topicContent}></TopicView>
              </div>
            ) : null}
            {mode === "flashcardView" ? (
              <div>
                <FlashcardView deckContent={deckContent}></FlashcardView>
              </div>
            ) : null}
            {mode === "gameView" ? (
              <div>
                <GameView gameContent={gameContent}></GameView>
              </div>
            ) : null}
            {mode === "examView" ? (
              <div>
                <ExamView examContent={examContent}></ExamView>
              </div>
            ) : null}
            {mode === "seriesView" ? (
              <div>
                <SeriesView></SeriesView>
              </div>
            ) : null}
            {mode === "publish" ? (
              <div>
                <Publish
                  publishType={publishType}
                  publishTypeFunc={publishTypeFunc}
                ></Publish>
              </div>
            ) : null}
          </MDBCardBody>
        </Scrollbars>
      </MDBCard>

      {/* Pagination Lessons Medium Size */}
      {mode === "lessons" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingMedium"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle>
                <MDBPageItem onClick={firstPaginationLessons}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationLessons}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationLessons}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationLessons}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Lessons Small Size */}
      {mode === "lessons" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingSmall"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle size="sm">
                <MDBPageItem onClick={firstPaginationLessons}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationLessons}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationLessons}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationLessons}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Topics Medium Size */}
      {mode === "topics" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingMedium"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle>
                <MDBPageItem onClick={firstPaginationTopics}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationTopics}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationTopics}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationTopics}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Topics Small Size */}
      {mode === "topics" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingSmall"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle size="sm">
                <MDBPageItem onClick={firstPaginationTopics}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationTopics}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationTopics}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationTopics}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Exams Medium Size*/}
      {mode === "exams" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingMedium"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle>
                <MDBPageItem onClick={firstPaginationExams}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationExams}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationExams}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationExams}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Exams Small Size*/}
      {mode === "exams" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingSmall"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle size="sm">
                <MDBPageItem onClick={firstPaginationExams}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationExams}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationExams(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationExams}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationExams}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Games Medium Size*/}
      {mode === "games" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingMedium"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle>
                <MDBPageItem onClick={firstPaginationGames}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationGames}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationGames}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationGames}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Games Small Size*/}
      {mode === "games" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingSmall"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle size="sm">
                <MDBPageItem onClick={firstPaginationGames}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationGames}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationGames(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationGames}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationGames}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Decks Medium Size*/}
      {mode === "flashcards" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingMedium"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle>
                <MDBPageItem onClick={firstPaginationDecks}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationDecks}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationDecks}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationDecks}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Decks Small Size*/}
      {mode === "flashcards" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingSmall"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle size="sm">
                <MDBPageItem onClick={firstPaginationDecks}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationDecks}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationDecks(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationDecks}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationDecks}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Curriculums Medium Size */}
      {mode === "series" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingMedium"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle>
                <MDBPageItem onClick={firstPaginationCurriculums}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationCurriculums}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationCurriculums}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationCurriculums}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}

      {/* Pagination Curriculums Small Size */}
      {mode === "series" ? (
        <div className={"middleVertical mt-5 mb-1 paginationTradingSmall"}>
          <MDBRow>
            <MDBCol>
              <MDBPagination circle size="sm">
                <MDBPageItem onClick={firstPaginationCurriculums}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationCurriculums}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationMode === "date"
                  ? [...Array(initialNumberOfPages)].map((item, index) => {
                      if (index + 1 === initialPageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === initialPageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })
                  : [...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationCurriculums(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationCurriculums}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationCurriculums}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}
    </Fragment>
  );
};

TradingCenter.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    searchLessonsFunction,
    searchTopicsFunction,
    searchExamsFunction,
    searchGamesFunction,
    searchDecksFunction,
    searchCurriculumsFunction,
    clearSearchReducer,
    clearFilterReducer,
    getMain,
    getPublishedLessons,
    getPublishedTopics,
    getPublishedExams,
    getPublishedGames,
    getPublishedDecks,
    getPublishedCurriculums,
    filterLessons,
    filterTopics,
    filterExams,
    filterGames,
    filterDecks,
    filterCurriculums,
    setPaginationMode
  })(TradingCenter)
);
