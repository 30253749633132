import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resetTokenAction } from "../../../actions/students";
import { MDBRow, MDBCol, MDBInput } from "mdbreact";

const SetPassword = ({ resetTokenAction, auth: { resetToken } }) => {
  const [formData, setFormData] = useState({
    email: ""
  });

  const { t, i18n } = useTranslation();

  const { email } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    resetTokenAction({
      ...formData,
      language: i18n.language
    });
  };

  return (
    <Fragment>
      <MDBRow mt="2" center>
        <MDBCol>
          <p className="text-center">{t("studentLogin.10")}</p>
        </MDBCol>
        <div className="w-100" />
        <MDBCol>
          <form className="form" onSubmit={e => onSubmit(e)}>
            <MDBRow center>
              <MDBInput
                type="email"
                name="email"
                value={email}
                onChange={e => onChange(e)}
                required
                label={t("studentLogin.11")}
                labelClass="black-text quickSand"
                className="black-text"
              />
              {/* <input
                type="email"
                placeholder={t("studentLogin.11")}
                name="email"
                value={email}
                onChange={e => onChange(e)}
                required
              /> */}

              <input
                type="submit"
                className="btn btn-primary"
                value={t("studentLogin.12")}
              />
            </MDBRow>
          </form>
        </MDBCol>
        <div className="w-100" />
        {!resetToken ? (
          ""
        ) : (
          <p className="text-center">{resetToken.message}</p>
        )}{" "}
      </MDBRow>
    </Fragment>
  );
};

SetPassword.propTypes = {
  resetTokenAction: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, { resetTokenAction })(SetPassword)
);
