import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./PublicPage.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { withRouter, Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment-timezone";
import validator from "validator";

import {
  MDBContainer,
  MDBCard,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBPagination,
  MDBPageItem,
  MDBPageNav
} from "mdbreact";

import {
  getMessageList,
  updateMessage,
  deleteMessage,
  setPaginationModeMessage,
  searchMessageFunction,
  clearMessageSearchReducer,
  sendAccept,
  sendReject,
  setLoadingButtonResponse,
  addBlockEmail,
  removeBlockEmail,
  clearStudentRequests
} from "../../actions/publicpage";

const StudentRequests = ({
  publicpage: {
    messageList,
    updatedMessage,
    deletedMessage,
    initialPageNumber,
    initialNumberOfPages,
    numberOfPages,
    pageNumber,
    searchMessage,
    paginationModeMessage,
    responded,
    loadingButtonResponse,
    publicPage
  },
  clearUpdatedMessage,
  clearStudentRequests,
  getMessageList,
  updateMessage,
  deleteMessage,
  setPaginationModeMessage,
  searchMessageFunction,
  clearMessageSearchReducer,
  setLoadingButtonResponse,
  switchMode,
  sendAccept,
  sendReject,
  addBlockEmail,
  removeBlockEmail,
  history,
  auth: { user }
}) => {
  const [formData, setFormData] = useState({
    statusOptions: [
      {
        text: "Contacted",
        value: "Contacted"
      },
      {
        text: "No Response",
        value: "None"
      },
      {
        text: "Became Student",
        value: "Student"
      }
    ],
    mode: "studentRequests",
    modalSeeMessage: false,
    confirmationModalDelete: false,
    message: {
      name: "",
      email: "",
      message: "",
      status: "",
      seen: false,
      _id: "",
      appointment: []
    },
    copied: false,
    deleteMessageId: "",
    searchValue: "",
    searchMessage: [],
    pageNumber: 1,
    modalSeeMessageAppointment: false,
    appointmentStatus: "",
    message3:
      "Thank you for reserving a lesson with me. I have confirmed the lesson and I look forward to teaching you. My skype Id is...",
    message4:
      "Thank you for your interest. Unfortunately I am unable to teach this lesson. Thank you again.",
    checked3: false,
    checked4: true,
    email: user.email,
    checked5: true,
    checked6: false,
    rejectMessage: "",
    acceptMessage: "",
    modalBlockEmail: false,
    selectedBlockEmail: "",
    newBlockEmail: ""
  });

  useEffect(() => {
    getMessageList({ initialPageNumber: "first" });
  }, [getMessageList]);

  const toggleConfirmationCloseDelete = () => {
    setFormData({
      ...formData,
      confirmationModalDelete: false
    });
  };

  const toggleConfirmationDelete = selected => {
    setFormData({
      ...formData,
      confirmationModalDelete: true,
      deleteMessageId: selected._id
    });
  };

  const toDashboard = () => {
    history.push(`/dashboard`);
  };

  const onClickCopy = () => {
    setFormData({
      ...formData,
      copied: true
    });
    const messageSuccess = () => {
      return (
        <Fragment>
          <MDBRow>
            <MDBCol>
              <MDBIcon icon="check" />
              <p>Copied to clipboard</p>
            </MDBCol>
          </MDBRow>
        </Fragment>
      );
    };
    toast.success(messageSuccess);
  };

  // Update

  const selectStatus = (e, selected) => {
    updateMessage({
      status: e.target.value,
      _id: selected._id
    });
  };

  const changeSeen = selected => {
    updateMessage({
      seen: true,
      _id: selected._id
    });
  };

  // Delete

  const deleteMessageFunc = selected => {
    deleteMessage({
      _id: formData.deleteMessageId
    });
    setFormData({
      ...formData,
      confirmationModalDelete: false
    });
  };

  const onKeyPressEnter = e => {
    if (e.key === "Enter") {
      messageSearchFunc();
    }
  };

  // Message Search

  const isActive1 = page => {
    if (initialPageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (searchMessage) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchMessage: searchMessage
        };
      });
    }
  }, [searchMessage]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        newBlockEmail: "",
        selectedBlockEmail: ""
      };
    });
  }, [publicPage]);

  const onChangeMessage = e =>
    setFormData({
      ...formData,
      searchValue: e.target.value
    });

  const messageSearchFunc = () => {
    searchMessageFunction({
      keywords: formData.searchValue,
      pageNumber: formData.pageNumber
    });
    setPaginationModeMessage("search");
  };

  const clearMessageFunc = () => {
    setFormData({
      ...formData,
      searchValue: "",
      searchMessage: []
    });
    clearMessageSearchReducer();
    setPaginationModeMessage("date");
    return formData.filteredMessageList;
  };

  const searchMessageMap = () => {
    if (formData.searchMessage === "No Results") {
      return [];
    } else if (formData.searchMessage.length !== 0) {
      return formData.searchMessage;
    } else {
      return messageList;
    }
  };

  // Pagination

  const firstPaginationMessage = () => {
    if (paginationModeMessage === "date") {
      getMessageList({ initialPageNumber: "first" });
    } else if (paginationModeMessage === "search") {
      searchMessageFunction({
        pageNumber: "first",
        keywords: formData.searchValue
      });
    }
  };

  const previousPaginationMessage = () => {
    if (paginationModeMessage === "date") {
      getMessageList({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationModeMessage === "search") {
      searchMessageFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchValue
      });
    }
  };

  const currentPaginationMessage = index => {
    if (paginationModeMessage === "date") {
      getMessageList({ initialPageNumber: index + 1 });
    } else if (paginationModeMessage === "search") {
      searchMessageFunction({
        pageNumber: index + 1,
        keywords: formData.searchValue
      });
    }
  };

  const nextPaginationMessage = () => {
    if (paginationModeMessage === "date") {
      getMessageList({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationModeMessage === "search") {
      searchMessageFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchValue
      });
    }
  };

  const lastPaginationMessage = () => {
    if (paginationModeMessage === "date") {
      getMessageList({ initialPageNumber: "last" });
    } else if (paginationModeMessage === "search") {
      searchMessageFunction({
        pageNumber: "last",
        keywords: formData.searchValue
      });
    }
  };

  // Close modal upon backend response

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        modalSeeMessage: false,
        modalSeeMessageAppointment: false,
        modalSeeMessageAppointmentConnect: false,
        checked3: false,
        checked4: true,

        checked5: true,
        checked6: false,
        rejectMessage: "",
        acceptMessage: "",

        appointmentStatus: "",
        message: {
          name: "",
          email: "",
          message: "",
          status: "",
          seen: false,
          _id: "",
          appointment: []
        }
      };
    });

    clearStudentRequests();
  }, [deletedMessage, clearStudentRequests]);

  const onClick4 = () => {
    setFormData({
      ...formData,

      checked3: false,
      checked4: true,
      newMessage: {
        ...formData.newMessage,
        message: ""
      }
    });
  };

  const onClick3 = () => {
    setFormData({
      ...formData,

      checked3: true,
      checked4: false,
      acceptMessage: formData.message3
    });
  };
  const onClick5 = () => {
    setFormData({
      ...formData,

      checked5: true,
      checked6: false,
      newMessage: {
        ...formData.newMessage,
        message: ""
      }
    });
  };

  const onClick6 = () => {
    setFormData({
      ...formData,

      checked5: false,
      checked6: true,
      rejectMessage: formData.message4
    });
  };

  const onChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      selectedBlockEmail: ""
    });

  // Accept or deny student requests

  const acceptFunc = () => {
    setLoadingButtonResponse(true);
    sendAccept({
      email: formData.email,
      acceptMessage: formData.acceptMessage,
      messageObject: formData.message
    });
  };
  const rejectFunc = () => {
    setLoadingButtonResponse(true);
    sendReject({
      rejectMessage: formData.rejectMessage,
      messageObject: formData.message
    });
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        checked3: false,
        checked4: true,

        checked5: true,
        checked6: false,
        rejectMessage: "",
        acceptMessage: "",
        modalSeeMessageAppointment: false,
        modalSeeMessageAppointmentConnect: false,
        appointmentStatus: "",
        message: {
          name: "",
          email: "",
          message: "",
          status: "",
          seen: false,
          _id: "",
          appointment: []
        }
      };
    });

    clearStudentRequests();
  }, [responded, clearStudentRequests]);

  const selectColor = item => {
    if (item === formData.selectedBlockEmail) {
      return "red text-white quickSand";
    } else {
      return "quickSand";
    }
  };

  // before return
  return (
    <Fragment>
      <MDBRow className="mt-5">
        <MDBCol size="12">
          <MDBCard
            className="mt-5 mb-2 px-5 mx-auto"
            style={{ fontWeight: 300, maxWidth: "90%", height: "auto" }}
          >
            <MDBRow className="pt-5 middleVertical" center>
              <MDBCol sm="2" className="col-4">
                <MDBIcon
                  size="3x"
                  fas="true"
                  icon="arrow-left"
                  style={{ cursor: "pointer" }}
                  className="uniqueText"
                  onClick={() => switchMode("publicPage")}
                />
              </MDBCol>
              <MDBCol sm="86" className="px-4 col-8">
                <h2 className="h1-responsive font-weight-bold uniqueText Prata text-center">
                  Student Invite Requests
                </h2>
              </MDBCol>
              <MDBCol sm="2" className="px-4"></MDBCol>
            </MDBRow>
            <MDBRow className=" middleVertical5 mt-1" center>
              <MDBCol className="col-4" sm="4"></MDBCol>
              <MDBCol sm="5" className="col-12">
                <MDBInput
                  className="uniqueText"
                  label="Search your requests"
                  labelClass="uniqueText"
                  type="text"
                  containerClass="active-pink active-pink-2 mt-0 mb-3"
                  value={formData.searchValue}
                  onChange={onChangeMessage}
                  onKeyPress={onKeyPressEnter}
                />
              </MDBCol>
              <MDBCol className="col-3" sm="3">
                <MDBRow className="align-items-center">
                  <div
                    className={
                      formData.searchValue.length !== 0 ? "mx-1" : "d-none"
                    }
                  >
                    <MDBIcon
                      icon="search"
                      onClick={messageSearchFunc}
                      style={{ cursor: "pointer" }}
                      className="uniqueText"
                    />
                  </div>
                  <div
                    className={
                      searchMessage.length !== 0 ||
                      searchMessage === "No Results"
                        ? "mx-1"
                        : "d-none"
                    }
                  >
                    <MDBIcon
                      icon="times-circle"
                      onClick={clearMessageFunc}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </MDBRow>
              </MDBCol>
              <MDBCol sm="2"></MDBCol>
            </MDBRow>
            <MDBRow end>
              <p
                onClick={() => {
                  setFormData({
                    ...formData,
                    modalBlockEmail: true
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                <MDBIcon className="red-text" icon="ban" />
                {` Blocked Emails`}
              </p>
            </MDBRow>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBContainer className="mb-4">
        <MDBRow className="mt-3">
          <MDBCol size="12">
            <MDBCard className="my-1 mx-auto" style={{ fontWeight: 300 }}>
              <div className="rounded-top uniqueBackground lighten-3 text-center py-2 mb-2">
                <MDBRow className="pt-3">
                  <MDBCol className="text-center livvicFont col-12" sm="5">
                    <p className="text-white">Email</p>
                  </MDBCol>

                  <MDBCol className="text-center livvicFont col-12" sm="1">
                    <p className="text-white">Name</p>
                  </MDBCol>
                  <MDBCol className="text-center livvicFont col-12" sm="6">
                    <p className="text-white">Status</p>
                  </MDBCol>
                </MDBRow>
              </div>
              <div
                className={
                  formData.searchMessage === "No Results" ? "mt-2" : "d-none"
                }
              >
                <p className="quickSand h4 black-text text-center">
                  There are no message results for your search. Try Again.
                </p>
              </div>
              <div className="messageCardHeight">
                {searchMessageMap().map((item, index) => {
                  return (
                    <MDBRow key={item._id} className="pt-3 messageHover">
                      <MDBCol className="text-center col-12" sm="1">
                        {item.seen === false ? (
                          <MDBIcon
                            far={false}
                            icon={
                              item.type === "regular"
                                ? "envelope"
                                : item.type === "awaitingConnect"
                                ? "user-graduate"
                                : item.type === "confirmedConnect"
                                ? "user-graduate"
                                : "chalkboard-teacher"
                            }
                            className="mr-1 uniqueText"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (item.type === "regular") {
                                setFormData({
                                  ...formData,
                                  modalSeeMessage: true,
                                  message: item
                                });
                                changeSeen(item);
                              } else if (item.type === "awaitingConnect") {
                                setFormData({
                                  ...formData,
                                  modalSeeMessageAppointmentConnect: true,
                                  message: item
                                });
                                changeSeen(item);
                              } else if (item.type === "deniedConnect") {
                                setFormData({
                                  ...formData,
                                  modalSeeMessageAppointmentConnect: true,
                                  message: item
                                });
                                changeSeen(item);
                              } else if (item.type === "confirmedConnect") {
                                setFormData({
                                  ...formData,
                                  modalSeeMessageAppointmentConnect: true,
                                  message: item
                                });
                                changeSeen(item);
                              } else {
                                setFormData({
                                  ...formData,
                                  modalSeeMessageAppointment: true,
                                  message: item
                                });
                                changeSeen(item);
                              }
                            }}
                          />
                        ) : (
                          <MDBIcon
                            far={item.type === "regular" ? true : false}
                            icon={
                              item.type === "regular"
                                ? "envelope-open"
                                : item.type === "awaitingConnect"
                                ? "user-graduate"
                                : item.type === "confirmedConnect"
                                ? "user-graduate"
                                : "chalkboard-teacher"
                            }
                            className="mr-1 openMessageColor"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (item.type === "regular") {
                                setFormData({
                                  ...formData,
                                  modalSeeMessage: true,
                                  message: item
                                });
                                changeSeen(item);
                              } else if (item.type === "awaitingConnect") {
                                setFormData({
                                  ...formData,
                                  modalSeeMessageAppointmentConnect: true,
                                  message: item
                                });
                                changeSeen(item);
                              } else if (item.type === "deniedConnect") {
                                setFormData({
                                  ...formData,
                                  modalSeeMessageAppointmentConnect: true,
                                  message: item
                                });
                                changeSeen(item);
                              } else if (item.type === "confirmedConnect") {
                                setFormData({
                                  ...formData,
                                  modalSeeMessageAppointmentConnect: true,
                                  message: item
                                });
                                changeSeen(item);
                              } else {
                                setFormData({
                                  ...formData,
                                  modalSeeMessageAppointment: true,
                                  message: item
                                });
                                changeSeen(item);
                              }
                            }}
                          />
                        )}
                      </MDBCol>
                      <MDBCol
                        className="text-center col-12"
                        sm="3"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (item.type === "regular") {
                            setFormData({
                              ...formData,
                              modalSeeMessage: true,
                              message: item
                            });
                            changeSeen(item);
                          } else if (item.type === "awaitingConnect") {
                            setFormData({
                              ...formData,
                              modalSeeMessageAppointmentConnect: true,
                              message: item
                            });
                            changeSeen(item);
                          } else if (item.type === "deniedConnect") {
                            setFormData({
                              ...formData,
                              modalSeeMessageAppointmentConnect: true,
                              message: item
                            });
                            changeSeen(item);
                          } else if (item.type === "confirmedConnect") {
                            setFormData({
                              ...formData,
                              modalSeeMessageAppointmentConnect: true,
                              message: item
                            });
                            changeSeen(item);
                          } else {
                            setFormData({
                              ...formData,
                              modalSeeMessageAppointment: true,
                              message: item
                            });
                            changeSeen(item);
                          }
                        }}
                      >
                        <p className="livvicFont fontBold ">{item.email}</p>
                      </MDBCol>

                      <MDBCol
                        className="text-center middleVertical col-12"
                        sm="3"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (item.type === "regular") {
                            setFormData({
                              ...formData,
                              modalSeeMessage: true,
                              message: item
                            });
                            changeSeen(item);
                          } else if (item.type === "awaitingConnect") {
                            setFormData({
                              ...formData,
                              modalSeeMessageAppointmentConnect: true,
                              message: item
                            });
                            changeSeen(item);
                          } else if (item.type === "confirmedConnect") {
                            setFormData({
                              ...formData,
                              modalSeeMessageAppointmentConnect: true,
                              message: item
                            });
                            changeSeen(item);
                          } else if (item.type === "confirmedDenied") {
                            setFormData({
                              ...formData,
                              modalSeeMessageAppointmentConnect: true,
                              message: item
                            });
                            changeSeen(item);
                          } else {
                            setFormData({
                              ...formData,
                              modalSeeMessageAppointment: true,
                              message: item
                            });
                            changeSeen(item);
                          }
                        }}
                      >
                        <p className="livvicFont fontBold">{item.name}</p>
                      </MDBCol>
                      <MDBCol
                        className="text-center col-12"
                        sm="4"
                        // onClick={() => {
                        //   setFormData({
                        //     ...formData,
                        //     message: item
                        //   });
                        // }}
                      >
                        <div className="statusInput uniqueText">
                          <select
                            className="statusInput browser-default custom-select uniqueText mb-2"
                            style={{ cursor: "pointer" }}
                            value={item.status}
                            onChange={e => selectStatus(e, item)}
                          >
                            <option defaultValue>
                              {item.status ? item.status : "Select Status"}
                            </option>
                            <option value="Contacted">Contacted</option>
                            <option value="No Response">No Response</option>
                            <option value="Became Student">
                              Became Student
                            </option>
                          </select>
                        </div>
                      </MDBCol>

                      <MDBCol sm="1" className="text-center col-12">
                        {item.type === "regular" ? (
                          <MDBIcon
                            fas="true"
                            icon="times"
                            className="mr-1 mt-2 dangerIcon growMessage"
                            style={{ cursor: "pointer" }}
                            onClick={() => toggleConfirmationDelete(item)}
                          />
                        ) : null}
                        <hr className="hrOnMobile" />
                      </MDBCol>
                    </MDBRow>
                  );
                })}
              </div>

              {/* Message List Pagination Medium Size*/}

              <div className="middleVertical paginationMessagesMedium">
                <MDBRow className="my-4">
                  <MDBCol sm="12" className="zIndexPagination">
                    <MDBPagination circle>
                      <MDBPageItem onClick={firstPaginationMessage}>
                        <MDBPageNav className="page-link">
                          <span>First</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={previousPaginationMessage}>
                        <MDBPageNav className="page-link" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      {paginationModeMessage === "date"
                        ? [...Array(initialNumberOfPages)].map(
                            (item, index) => {
                              if (index + 1 === initialPageNumber) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    active={isActive1(index + 1)}
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else {
                                return null;
                              }
                            }
                          )
                        : [...Array(numberOfPages)].map((item, index) => {
                            if (index + 1 === pageNumber) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  active={isActive2(index + 1)}
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 1) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 2) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 3) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 1) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 2) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 3) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else {
                              return null;
                            }
                          })}
                      <MDBPageItem onClick={nextPaginationMessage}>
                        <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={lastPaginationMessage}>
                        <MDBPageNav className="page-link">Last</MDBPageNav>
                      </MDBPageItem>
                    </MDBPagination>
                  </MDBCol>
                </MDBRow>
              </div>

              {/* Message List Pagination Small Size*/}

              <div className="middleVertical paginationMessagesSmall">
                <MDBRow className="my-4">
                  <MDBCol
                    lg="12"
                    className="col-12 middleVertical zIndexPagination"
                  >
                    <MDBPagination max-size="3" size="sm" circle>
                      <MDBPageItem onClick={firstPaginationMessage}>
                        <MDBPageNav className="page-link">
                          <span>First</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={previousPaginationMessage}>
                        <MDBPageNav className="page-link" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </MDBPageNav>
                      </MDBPageItem>
                      {paginationModeMessage === "date"
                        ? [...Array(initialNumberOfPages)].map(
                            (item, index) => {
                              if (index + 1 === initialPageNumber) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    active={isActive1(index + 1)}
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber - 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === initialPageNumber + 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationMessage(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else {
                                return null;
                              }
                            }
                          )
                        : [...Array(numberOfPages)].map((item, index) => {
                            if (index + 1 === pageNumber) {
                              return (
                                <MDBPageItem
                                  key={index}
                                  active={isActive2(index + 1)}
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 1) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 2) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber - 3) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 1) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 2) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else if (index + 1 === pageNumber + 3) {
                              return (
                                <MDBPageItem
                                  onClick={() =>
                                    currentPaginationMessage(index)
                                  }
                                >
                                  <MDBPageNav className="page-link">
                                    {index + 1}{" "}
                                    <span className="sr-only">(current)</span>
                                  </MDBPageNav>
                                </MDBPageItem>
                              );
                            } else {
                              return null;
                            }
                          })}
                      <MDBPageItem onClick={nextPaginationMessage}>
                        <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                      </MDBPageItem>
                      <MDBPageItem onClick={lastPaginationMessage}>
                        <MDBPageNav className="page-link">Last</MDBPageNav>
                      </MDBPageItem>
                    </MDBPagination>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBContainer>
          <MDBModal
            isOpen={formData.modalSeeMessage}
            toggle={() => {
              setFormData({
                ...formData,
                modalSeeMessage: false,
                checked3: false,
                checked4: true,

                checked5: true,
                checked6: false,
                rejectMessage: "",
                acceptMessage: "",

                appointmentStatus: "",
                message: {
                  name: "",
                  email: "",
                  message: "",
                  status: "",
                  seen: false,
                  _id: "",
                  appointment: []
                }
              });
            }}
            cascading="true"
          >
            <MDBModalHeader
              className="uniqueBackground white-text"
              toggle={() => {
                setFormData({
                  ...formData,
                  modalSeeMessage: false,

                  checked3: false,
                  checked4: true,

                  checked5: true,
                  checked6: false,
                  rejectMessage: "",
                  acceptMessage: "",

                  appointmentStatus: "",
                  message: {
                    name: "",
                    email: "",
                    message: "",
                    status: "",
                    seen: false,
                    _id: "",
                    appointment: []
                  }
                });
              }}
              cascading="true"
            >
              <MDBIcon icon="far fa-envelope" className="mr-2" />
              New Message from {formData.message.name}
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect h5">Name:</p>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect">
                  {formData.message.name}
                </p>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect h5">Email:</p>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect mr-3">
                  {formData.message.email}
                </p>
                <CopyToClipboard
                  text={formData.message.email}
                  onCopy={onClickCopy}
                >
                  <MDBIcon
                    far
                    icon="copy"
                    size="md"
                    className="uniqueText mt-1"
                    style={{ cursor: "pointer" }}
                  />
                </CopyToClipboard>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect h5">Message:</p>
              </MDBRow>
              <MDBRow>
                <p className="quickSand countrySelect">
                  {formData.message.message}
                </p>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter center>
              <Link
                className=""
                to={{
                  pathname: "/classes",
                  state: {
                    activeItemOuterTabs: "2",
                    newStudent: {
                      email: formData.message.email,
                      name: formData.message.name
                    }
                  }
                }}
              >
                <MDBBtn className="mr-auto" outline color="unique">
                  Create Student
                </MDBBtn>
              </Link>
              <MDBBtn
                color="danger"
                onClick={() => toggleConfirmationDelete(formData.message)}
              >
                Delete Message
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalBlockEmail}
            toggle={() => {
              setFormData({
                ...formData,
                modalBlockEmail: false
              });
            }}
            cascading="true"
          >
            <MDBModalHeader
              className="uniqueBackground white-text"
              toggle={() => {
                setFormData({
                  ...formData,
                  modalBlockEmail: false
                });
              }}
              cascading="true"
            >
              <MDBIcon icon="ban" className="mr-2" />
              Blocked Emails
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow center>
                <p className="quickSand h4 font-weight-bold">
                  Blocked Email List
                </p>
              </MDBRow>
              {publicPage.blockedEmails.map((item, index) => {
                return (
                  <MDBRow center key={index}>
                    <p
                      className={selectColor(item)}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setFormData({
                          ...formData,
                          selectedBlockEmail: item,
                          newBlockEmail: ""
                        });
                      }}
                    >
                      {item}
                    </p>
                  </MDBRow>
                );
              })}

              <MDBCol className="mb-1">
                <MDBInput
                  type="email"
                  label="Add Email"
                  name="newBlockEmail"
                  className="quickSand text-black hintColor"
                  value={formData.newBlockEmail}
                  onChange={e => onChange(e)}
                />
              </MDBCol>
            </MDBModalBody>
            <MDBModalFooter center>
              {formData.selectedBlockEmail ? (
                <MDBBtn
                  className="mr-auto"
                  outline
                  color="unique"
                  onClick={() => {
                    return removeBlockEmail({
                      blockedEmail: formData.selectedBlockEmail
                    });
                  }}
                >
                  Remove Email
                </MDBBtn>
              ) : null}

              {formData.newBlockEmail ? (
                <MDBBtn
                  color="danger"
                  onClick={() => {
                    const email = validator.trim(formData.newBlockEmail);
                    const find = publicPage.blockedEmails.find(
                      item => item === email
                    );

                    if (find) {
                      const messageError = () => {
                        return (
                          <Fragment>
                            <MDBRow>
                              <MDBCol>
                                <MDBIcon icon="exclamation" />
                                <p>
                                  That email is already in your blocked email
                                  list
                                </p>
                              </MDBCol>
                            </MDBRow>
                          </Fragment>
                        );
                      };
                      toast.error(messageError);
                    } else {
                      return addBlockEmail({ blockedEmail: email });
                    }
                  }}
                >
                  Add Email
                </MDBBtn>
              ) : null}
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalSeeMessageAppointment}
            toggle={() => {
              setFormData({
                ...formData,
                modalSeeMessageAppointment: false,
                checked3: false,
                checked4: true,

                checked5: true,
                checked6: false,
                rejectMessage: "",
                acceptMessage: "",

                appointmentStatus: "",
                message: {
                  name: "",
                  email: "",
                  message: "",
                  status: "",
                  seen: false,
                  _id: "",
                  appointment: []
                }
              });
            }}
            cascading="true"
          >
            <MDBModalHeader
              className="uniqueBackground white-text"
              toggle={() => {
                setFormData({
                  ...formData,
                  modalSeeMessageAppointment: false,
                  checked3: false,
                  checked4: true,

                  checked5: true,
                  checked6: false,
                  rejectMessage: "",
                  acceptMessage: "",

                  appointmentStatus: "",
                  message: {
                    name: "",
                    email: "",
                    message: "",
                    status: "",
                    seen: false,
                    _id: "",
                    appointment: []
                  }
                });
              }}
              cascading="true"
            >
              <MDBIcon icon="chalkboard-teacher" className="mr-2" />
              Lesson Request from {formData.message.name}
            </MDBModalHeader>
            <MDBModalBody>
              {formData.message.type === "confirmed" ? (
                <p className="h3 quickSand font-weight-bold text-center my-2 green-text">
                  Appointment Confirmed
                </p>
              ) : formData.message.type === "denied" ? (
                <p className="h3 quickSand font-weight-bold text-center my-2 red-text">
                  Appointment Rejected
                </p>
              ) : null}
              {formData.message.appointment.map((item, index) => {
                return (
                  <MDBRow center key={item._id}>
                    <p>
                      {moment(item.start, "x")
                        .tz(user.defaultTimezone)
                        .format("llll")}{" "}
                      -{" "}
                      {moment(item.end, "x")
                        .tz(user.defaultTimezone)
                        .format("llll")}
                    </p>
                  </MDBRow>
                );
              })}

              <MDBRow className="mb-1">
                <p className="quickSand countrySelect h5">Name:</p>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect">
                  {formData.message.name}
                </p>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect h5">Email:</p>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect mr-3">
                  {formData.message.email}
                </p>
                <CopyToClipboard
                  text={formData.message.email}
                  onCopy={onClickCopy}
                >
                  <MDBIcon
                    far
                    icon="copy"
                    size="md"
                    className="uniqueText mt-1"
                    style={{ cursor: "pointer" }}
                  />
                </CopyToClipboard>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect h5">Message:</p>
              </MDBRow>
              <MDBRow>
                <p className="quickSand countrySelect">
                  {formData.message.message}
                </p>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter center>
              {formData.message.type === "awaiting" ? (
                <Fragment>
                  <MDBBtn
                    className="mr-auto"
                    color={
                      formData.appointmentStatus === "accept"
                        ? "success"
                        : formData.appointmentStatus === "reject"
                        ? "dark"
                        : "success"
                    }
                    onClick={() => {
                      setFormData({
                        ...formData,
                        appointmentStatus: "accept"
                      });
                    }}
                  >
                    Accept
                  </MDBBtn>
                  <MDBBtn
                    color={
                      formData.appointmentStatus === "reject"
                        ? "danger"
                        : formData.appointmentStatus === "accept"
                        ? "dark"
                        : "danger"
                    }
                    onClick={() => {
                      setFormData({
                        ...formData,
                        appointmentStatus: "reject"
                      });
                    }}
                  >
                    Reject
                  </MDBBtn>
                </Fragment>
              ) : formData.message.type === "denied" ? (
                <Fragment>
                  <MDBBtn
                    color="danger"
                    onClick={() => toggleConfirmationDelete(formData.message)}
                  >
                    Delete Message
                  </MDBBtn>
                </Fragment>
              ) : formData.message.type === "confirmed" ? (
                <Fragment>
                  <Link
                    className=""
                    to={{
                      pathname: "/classes",
                      state: {
                        activeItemOuterTabs: "2",
                        newStudent: {
                          email: formData.message.email,
                          name: formData.message.name
                        }
                      }
                    }}
                  >
                    <MDBBtn className="mr-auto" outline color="unique">
                      Create Student
                    </MDBBtn>
                  </Link>
                  <MDBBtn
                    color="danger"
                    onClick={() => toggleConfirmationDelete(formData.message)}
                  >
                    Delete Message
                  </MDBBtn>
                </Fragment>
              ) : null}
            </MDBModalFooter>
            {formData.appointmentStatus === "accept" ? (
              <Fragment>
                <MDBModalBody>
                  <p className="text-center quickSand h4 my-2">
                    Response (Accept)
                  </p>
                  <MDBCol className="mb-1">
                    <MDBInput
                      label={formData.message3}
                      type="radio"
                      id="radio3"
                      labelClass="text-black quickSand"
                      onClick={onClick3}
                      checked={formData.checked3}
                    />
                  </MDBCol>
                  <MDBCol className="mb-1">
                    <MDBInput
                      label="Custom Message"
                      type="radio"
                      id="radio4"
                      labelClass="text-black quickSand"
                      onClick={onClick4}
                      checked={formData.checked4}
                    />
                  </MDBCol>
                  <MDBInput
                    type="email"
                    label="Your Email"
                    name="email"
                    className="quickSand text-black hintColor"
                    value={formData.email}
                    onChange={e => onChange(e)}
                    required
                  />
                  <MDBInput
                    type="textarea"
                    label="Your Message"
                    name="acceptMessage"
                    className="quickSand text-black hintColor"
                    value={formData.acceptMessage}
                    onChange={e => onChange(e)}
                    required
                    rows={10}
                  />
                  <MDBRow center>
                    <MDBBtn
                      disabled={loadingButtonResponse}
                      onClick={acceptFunc}
                      className="uniqueBackground"
                    >
                      {loadingButtonResponse === true && (
                        <div className="spinner-grow spinner-grow-sm" />
                      )}
                      Send to Student
                    </MDBBtn>
                  </MDBRow>
                </MDBModalBody>
              </Fragment>
            ) : formData.appointmentStatus === "reject" ? (
              <Fragment>
                <MDBModalBody>
                  <p className="text-center quickSand h4 my-2">
                    Response (Reject)
                  </p>
                  <MDBCol className="mb-1">
                    <MDBInput
                      label={formData.message4}
                      type="radio"
                      id="radio6"
                      labelClass="text-black quickSand"
                      onClick={onClick6}
                      checked={formData.checked6}
                    />
                  </MDBCol>
                  <MDBCol className="mb-1">
                    <MDBInput
                      label="Custom Message"
                      type="radio"
                      id="radio5"
                      labelClass="text-black quickSand"
                      onClick={onClick5}
                      checked={formData.checked5}
                    />
                  </MDBCol>

                  <MDBInput
                    type="textarea"
                    label="Your Message"
                    name="rejectMessage"
                    className="quickSand text-black hintColor"
                    value={formData.rejectMessage}
                    onChange={e => onChange(e)}
                    required
                    rows={10}
                  />
                  <MDBRow center>
                    <MDBBtn
                      disabled={loadingButtonResponse}
                      onClick={rejectFunc}
                      className="uniqueBackground"
                    >
                      {loadingButtonResponse === true && (
                        <div className="spinner-grow spinner-grow-sm" />
                      )}
                      Send to Student
                    </MDBBtn>
                  </MDBRow>
                </MDBModalBody>
              </Fragment>
            ) : null}
          </MDBModal>
        </MDBContainer>
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalSeeMessageAppointmentConnect}
            toggle={() => {
              setFormData({
                ...formData,
                modalSeeMessageAppointmentConnect: false,
                checked3: false,
                checked4: true,

                checked5: true,
                checked6: false,
                rejectMessage: "",
                acceptMessage: "",

                appointmentStatus: "",
                message: {
                  name: "",
                  email: "",
                  message: "",
                  status: "",
                  seen: false,
                  _id: "",
                  appointment: []
                }
              });
            }}
            cascading="true"
          >
            <MDBModalHeader
              className="uniqueBackground white-text"
              toggle={() => {
                setFormData({
                  ...formData,
                  modalSeeMessageAppointmentConnect: false,
                  checked3: false,
                  checked4: true,

                  checked5: true,
                  checked6: false,
                  rejectMessage: "",
                  acceptMessage: "",

                  appointmentStatus: "",
                  message: {
                    name: "",
                    email: "",
                    message: "",
                    status: "",
                    seen: false,
                    _id: "",
                    appointment: []
                  }
                });
              }}
              cascading="true"
            >
              <MDBIcon icon="user-graduate" className="mr-2" />
              Lesson Request from {formData.message.name}
            </MDBModalHeader>
            <MDBModalBody>
              {formData.message.type === "confirmedConnect" ? (
                <p className="h3 quickSand font-weight-bold text-center my-2 green-text">
                  Appointment Confirmed
                </p>
              ) : formData.message.type === "deniedConnect" ? (
                <p className="h3 quickSand font-weight-bold text-center my-2 red-text">
                  Appointment Rejected
                </p>
              ) : null}
              {formData.message.appointment.map((item, index) => {
                return (
                  <MDBRow key={item._id} center>
                    <p>
                      {moment(item.start, "x")
                        .tz(user.defaultTimezone)
                        .format("llll")}{" "}
                      -{" "}
                      {moment(item.end, "x")
                        .tz(user.defaultTimezone)
                        .format("llll")}
                    </p>
                  </MDBRow>
                );
              })}

              <MDBRow className="mb-1">
                <p className="quickSand countrySelect h5">Name:</p>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect">
                  {formData.message.name}
                </p>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect h5">Email:</p>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect mr-3">
                  {formData.message.email}
                </p>
                <CopyToClipboard
                  text={formData.message.email}
                  onCopy={onClickCopy}
                >
                  <MDBIcon
                    far
                    icon="copy"
                    size="md"
                    className="uniqueText mt-1"
                    style={{ cursor: "pointer" }}
                  />
                </CopyToClipboard>
              </MDBRow>
              <MDBRow className="mb-1">
                <p className="quickSand countrySelect h5">Message:</p>
              </MDBRow>
              <MDBRow>
                <p className="quickSand countrySelect">
                  {formData.message.message}
                </p>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter center>
              {formData.message.type === "awaitingConnect" ? (
                <Fragment>
                  <MDBBtn
                    className="mr-auto"
                    color={
                      formData.appointmentStatus === "accept"
                        ? "success"
                        : formData.appointmentStatus === "reject"
                        ? "dark"
                        : "success"
                    }
                    onClick={() => {
                      setFormData({
                        ...formData,
                        appointmentStatus: "accept"
                      });
                    }}
                  >
                    Accept
                  </MDBBtn>
                  <MDBBtn
                    color={
                      formData.appointmentStatus === "reject"
                        ? "danger"
                        : formData.appointmentStatus === "accept"
                        ? "dark"
                        : "danger"
                    }
                    onClick={() => {
                      setFormData({
                        ...formData,
                        appointmentStatus: "reject"
                      });
                    }}
                  >
                    Reject
                  </MDBBtn>
                </Fragment>
              ) : formData.message.type === "deniedConnect" ? (
                <Fragment>
                  <MDBBtn
                    color="danger"
                    onClick={() => toggleConfirmationDelete(formData.message)}
                  >
                    Delete Message
                  </MDBBtn>
                </Fragment>
              ) : formData.message.type === "confirmedConnect" ? (
                <Fragment>
                  <MDBBtn
                    color="danger"
                    onClick={() => toggleConfirmationDelete(formData.message)}
                  >
                    Delete Message
                  </MDBBtn>
                </Fragment>
              ) : null}
            </MDBModalFooter>
            {formData.appointmentStatus === "accept" ? (
              <Fragment>
                <MDBModalBody>
                  <p className="text-center quickSand h4 my-2">
                    Response (Accept)
                  </p>
                  <MDBCol className="mb-1">
                    <MDBInput
                      label={formData.message3}
                      type="radio"
                      id="radio3"
                      labelClass="text-black quickSand"
                      onClick={onClick3}
                      checked={formData.checked3}
                    />
                  </MDBCol>
                  <MDBCol className="mb-1">
                    <MDBInput
                      label="Custom Message"
                      type="radio"
                      id="radio4"
                      labelClass="text-black quickSand"
                      onClick={onClick4}
                      checked={formData.checked4}
                    />
                  </MDBCol>
                  <MDBInput
                    type="email"
                    label="Your Email"
                    name="email"
                    className="quickSand text-black hintColor"
                    value={formData.email}
                    onChange={e => onChange(e)}
                    required
                  />
                  <MDBInput
                    type="textarea"
                    label="Your Message"
                    name="acceptMessage"
                    className="quickSand text-black hintColor"
                    value={formData.acceptMessage}
                    onChange={e => onChange(e)}
                    required
                    rows={10}
                  />
                  <MDBRow center>
                    <MDBBtn
                      disabled={loadingButtonResponse}
                      onClick={acceptFunc}
                      className="uniqueBackground"
                    >
                      {loadingButtonResponse === true && (
                        <div className="spinner-grow spinner-grow-sm" />
                      )}
                      Send to Student
                    </MDBBtn>
                  </MDBRow>
                </MDBModalBody>
              </Fragment>
            ) : formData.appointmentStatus === "reject" ? (
              <Fragment>
                <MDBModalBody>
                  <p className="text-center quickSand h4 my-2">
                    Response (Reject)
                  </p>
                  <MDBCol className="mb-1">
                    <MDBInput
                      label={formData.message4}
                      type="radio"
                      id="radio6"
                      labelClass="text-black quickSand"
                      onClick={onClick6}
                      checked={formData.checked6}
                    />
                  </MDBCol>
                  <MDBCol className="mb-1">
                    <MDBInput
                      label="Custom Message"
                      type="radio"
                      id="radio5"
                      labelClass="text-black quickSand"
                      onClick={onClick5}
                      checked={formData.checked5}
                    />
                  </MDBCol>

                  <MDBInput
                    type="textarea"
                    label="Your Message"
                    name="rejectMessage"
                    className="quickSand text-black hintColor"
                    value={formData.rejectMessage}
                    onChange={e => onChange(e)}
                    required
                    rows={10}
                  />
                  <MDBRow center>
                    <MDBBtn
                      disabled={loadingButtonResponse}
                      onClick={rejectFunc}
                      className="uniqueBackground"
                    >
                      {loadingButtonResponse === true && (
                        <div className="spinner-grow spinner-grow-sm" />
                      )}
                      Send to Student
                    </MDBBtn>
                  </MDBRow>
                </MDBModalBody>
              </Fragment>
            ) : null}
          </MDBModal>
        </MDBContainer>
        <MDBContainer>
          <MDBModal
            isOpen={formData.confirmationModalDelete}
            toggle={toggleConfirmationCloseDelete}
            size="sm"
          >
            <MDBModalHeader
              className="montserratFont black-text h4"
              toggle={toggleConfirmationCloseDelete}
            >
              Confirm Delete
            </MDBModalHeader>
            <MDBModalBody className="quickSand h5 black-text">
              Please confirm that you wish to delete this message
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                size="sm"
                onClick={toggleConfirmationCloseDelete}
                className="quickSand"
              >
                Close
              </MDBBtn>
              <MDBBtn
                onClick={() => deleteMessageFunc()}
                color="danger"
                size="sm"
                className="quickSand"
              >
                Confirm Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </MDBContainer>
      <MDBModalFooter className="footerPublicPage mt-2">
        <MDBCol sm="10"></MDBCol>
        <MDBCol sm="2" className="col-12">
          <MDBBtn onClick={toDashboard} color="grey">
            Dashboard
          </MDBBtn>
        </MDBCol>
      </MDBModalFooter>
    </Fragment>
  );
};

StudentRequests.propTypes = {};

const mapStateToProps = state => ({
  publicpage: state.publicpage,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    getMessageList,
    updateMessage,
    deleteMessage,
    setPaginationModeMessage,
    searchMessageFunction,
    clearMessageSearchReducer,
    sendAccept,
    sendReject,
    setLoadingButtonResponse,
    addBlockEmail,
    removeBlockEmail,
    clearStudentRequests
  })(StudentRequests)
);
