import {
  CREATE_PUBLICPAGE,
  GET_PUBLICPAGE,
  GET_PUBLICPAGE_URL,
  UPDATE_PUBLICPAGE,
  CREATE_MESSAGE,
  REPORT_USER,
  GET_MESSAGE_LIST,
  UPDATE_MESSAGE_LIST,
  DELETE_MESSAGE,
  SEARCH_MESSAGE,
  CLEAR_MESSAGE_SEARCH,
  SET_PAGINATION_MODE_MESSAGE,
  SET_LOADING_BUTTON_MESSAGE,
  GET_TUTOR_LIST,
  SEARCH_TUTOR,
  CLEAR_TUTOR_SEARCH,
  SET_PAGINATION_MODE_TUTOR,
  FILTER_TUTOR,
  CLEAR_FILTER_TUTOR,
  PUBLICPAGE_ERROR,
  PUBLICPAGE_EMAIL_VERIFY,
  PUBLICPAGE_APPOINTMENT_ACCEPT,
  PUBLICPAGE_APPOINTMENT_REJECT,
  SET_LOADING_BUTTON_PUBLICPAGE,
  SET_LOADING_BUTTON_REPORT,
  SET_LOADING_BUTTON_DEACTIVATE,
  SET_LOADING_BUTTON_APPOINTMENT,
  SET_LOADING_BUTTON_RESPONSE,
  PUBLICPAGE_EMAILBLOCK_ADD,
  PUBLICPAGE_EMAILBLOCK_REMOVE,
  PUBLICPAGE_VERIFYEMAIL_MESSAGE,
  PUBLICPAGE_CONNECT_REQUEST,
  PUBLICPAGE_ACTIVATE,
  PUBLICPAGE_DEACTIVATE,
  CLEAR_UPDATED_MESSAGE,
  CLEAR_STUDENTREQUESTS,
  LOGOUT
} from "../actions/types";

const initialState = {
  loading: true,
  newPublicPage: "",
  newMessage: "",
  updatedMessage: "",
  deletedMessage: "",
  messageList: [],
  publicPage: {
    blockedEmails: [],
    levels: [],
    methods: [],
    availability: [],
    avatar: {
      url: ""
    },
    uniqueURL: "",
    activatePagePublic: "",
    name: "",
    city: "",
    country: "",
    rate: "",
    currency: "",
    facebook: "",
    instagram: "",
    twitter: "",
    linkedIn: "",
    description: "",
    youtube: "",
    years: 0,
    degree: "",
    certification: "",
    lastExperience: ""
  },
  publicPageURL: {
    avatar: {
      url: ""
    },
    name: "",
    city: "",
    country: "",
    rate: "",
    currency: "",
    facebook: "",
    instagram: "",
    twitter: "",
    linkedIn: "",
    description: "",
    youtube: "",
    years: 0,
    degree: "",
    certification: "",
    lastExperience: "",
    levels: [],
    methods: [],
    availability: [],
    activatePublicPage: ""
  },
  initialPageNumber: 1,
  initialNumberOfPages: 1,
  pageNumber: 1,
  numberOfPages: 1,
  keywords: "",
  searchMessage: [],
  paginationModeMessage: "date",
  tutorList: [],
  initialPageNumberTutor: 1,
  initialNumberOfPagesTutor: 1,
  pageNumberTutor: 1,
  numberOfPagesTutor: 1,
  keywordsTutor: "",
  searchTutor: [],
  paginationModeTutor: "date",
  tutorFilter: [],
  filterPreferences: {
    levels: "",
    methods: "",
    country: ""
  },
  error: {},
  publicPageExist: false,
  emailVerify: "",
  emailVerifyLoading: true,
  messageObject: {
    appointment: []
  },
  loadingButtonPublicPage: false,
  loadingButtonMessage: false,
  loadingButtonAppointment: false,
  loadingButtonResponse: false,
  loadingButtonDeactivate: false,
  loadingButtonReport: false,
  responded: false,
  verifyMessageEmail: false,
  verifyMessageEmailLoading: true,
  connectRequestStatus: "",
  loadingTutorList: true,
  report: ""
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_MESSAGE_SEARCH:
      return {
        ...state,
        searchMessage: "",
        keywords: "",
        numberOfPages: "",
        pageNumber: "",
        paginationModeMessage: "date"
      };
    case CLEAR_TUTOR_SEARCH:
      return {
        ...state,
        searchTutor: "",
        keywordsTutor: "",
        numberOfPagesTutor: "",
        pageNumberTutor: "",
        paginationModeMessageTutor: "date"
      };
    case CLEAR_FILTER_TUTOR:
      return {
        ...state,
        tutorFilter: [],
        paginationModeTutor: "date",
        filterPreferences: { levels: "", methods: "", country: "" }
      };
    case SEARCH_MESSAGE:
      return {
        ...state,
        searchMessage: payload.searchMessage,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        loading: false
      };
    case SEARCH_TUTOR:
      return {
        ...state,
        // searchTutor: payload.searchTutor,
        // keywordsTutor: payload.keywordsTutor,
        // pageNumberTutor: payload.pageNumberTutor,
        // numberOfPagesTutor: payload.numberOfPagesTutor,
        searchTutor: payload.searchTutor,
        pageNumberTutor: payload.pageNumberTutor,
        numberOfPagesTutor: payload.numberOfPagesTutor,
        filterPreferences: payload.filterPreferences,
        keywordsTutor: payload.keywordsTutor,
        loading: false
      };
    case SET_PAGINATION_MODE_MESSAGE:
      return {
        ...state,
        paginationModeMessage: payload
      };
    case SET_PAGINATION_MODE_TUTOR:
      return {
        ...state,
        paginationModeTutor: payload
      };
    case CREATE_PUBLICPAGE:
      return {
        ...state,
        publicPage: payload.newPublicPage,
        newPublicPage: payload.newPublicPage,
        publicPageExist: payload.publicPageExist,
        loadingButtonPublicPage: false
      };
    case GET_PUBLICPAGE:
      return {
        ...state,
        publicPage: payload.publicPage,
        publicPageExist: payload.publicPageExist
      };
    case GET_PUBLICPAGE_URL:
      return {
        ...state,
        publicPageURL: payload.publicPageURL,
        publicPageExist: payload.publicPageExist,
        loading: false
      };
    case UPDATE_PUBLICPAGE:
      return {
        ...state,
        publicPage: payload.publicPage,
        loadingButtonPublicPage: false
      };
    case CREATE_MESSAGE:
      if (payload.publicPage) {
        return {
          ...state,
          newMessage: payload.newMessage,
          publicPageURL: payload.publicPage,
          loadingButtonMessage: false,
          loadingButtonAppointment: false
        };
      } else {
        return {
          ...state,
          newMessage: payload.newMessage,
          loadingButtonMessage: false,
          loadingButtonAppointment: false
        };
      }
    case REPORT_USER:
      return {
        ...state,
        report: payload,
        loadingButtonReport: false
      };
    case GET_MESSAGE_LIST:
      return {
        ...state,
        messageList: payload.messageList,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber
      };
    case GET_TUTOR_LIST:
      return {
        ...state,
        tutorList: payload.tutorList,
        initialNumberOfPagesTutor: payload.initialNumberOfPagesTutor,
        initialPageNumberTutor: payload.initialPageNumberTutor,
        loadingTutorList: false
      };
    case UPDATE_MESSAGE_LIST:
      const newArray = state.messageList.map((item, index) => {
        if (item._id === payload.updatedMessage._id) {
          return payload.updatedMessage;
        } else {
          return item;
        }
      });
      return {
        ...state,
        messageList: newArray,
        newMessage: ""
      };
    case DELETE_MESSAGE:
      return {
        ...state,

        messageList: state.messageList.filter(
          message => message._id !== payload.messageDeleted._id
        ),
        deletedMessage: payload.messageDeleted._id,
        updatedMessage: "",
        newMessage: ""
      };
    case FILTER_TUTOR:
      return {
        ...state,
        tutorFilter: payload.tutorFilter,
        pageNumberTutor: payload.pageNumberTutor,
        numberOfPagesTutor: payload.numberOfPagesTutor,
        filterPreferences: payload.filterPreferences
      };
    case PUBLICPAGE_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonMessage: false,
        loadingButtonPublicPage: false,
        loadingButtonAppointment: false,
        loadingButtonResponse: false,
        loadingButtonDeactivate: false,
        loadingButtonReport: false
      };
    case PUBLICPAGE_EMAIL_VERIFY:
      return {
        ...state,
        emailVerify: payload.appointmentStatus,
        messageObject: payload.messageObject,
        emailVerifyLoading: false,
        updateMsgAlert: payload.updateMsgAlert
      };
    case PUBLICPAGE_APPOINTMENT_ACCEPT:
      const newArray2 = state.messageList.map((item, index) => {
        if (item._id === payload.messageObject._id) {
          return payload.messageObject;
        } else {
          return item;
        }
      });
      return {
        ...state,
        messageList: newArray2,
        publicPage: payload.publicPage,
        responded: true,
        loadingButtonResponse: false
      };
    case PUBLICPAGE_APPOINTMENT_REJECT:
      const newArray3 = state.messageList.map((item, index) => {
        if (item._id === payload.messageObject._id) {
          return payload.messageObject;
        } else {
          return item;
        }
      });
      return {
        ...state,
        messageList: newArray3,
        publicPage: payload.publicPage,
        responded: true,
        loadingButtonResponse: false
      };
    case SET_LOADING_BUTTON_PUBLICPAGE:
      return {
        ...state,
        loadingButtonPublicPage: payload
      };
    case SET_LOADING_BUTTON_MESSAGE:
      return {
        ...state,
        loadingButtonMessage: payload
      };
    case SET_LOADING_BUTTON_REPORT:
      return {
        ...state,
        loadingButtonReport: payload
      };
    case SET_LOADING_BUTTON_APPOINTMENT:
      return {
        ...state,
        loadingButtonAppointment: payload
      };
    case SET_LOADING_BUTTON_RESPONSE:
      return {
        ...state,
        loadingButtonResponse: payload
      };
    case SET_LOADING_BUTTON_DEACTIVATE:
      return {
        ...state,
        loadingButtonDeactivate: payload
      };
    case PUBLICPAGE_EMAILBLOCK_ADD:
      return {
        ...state,
        publicPage: payload.publicPage
      };
    case PUBLICPAGE_EMAILBLOCK_REMOVE:
      return {
        ...state,
        publicPage: payload.publicPage
      };
    case PUBLICPAGE_VERIFYEMAIL_MESSAGE:
      return {
        ...state,
        verifyMessageEmail: payload.verifyMessageEmail,
        verifyMessageEmailLoading: false,
        updateMsgAlert: payload.updateMsgAlert
      };
    case PUBLICPAGE_CONNECT_REQUEST:
      return {
        ...state,
        publicPageURL: payload.publicPage,
        connectRequestStatus: payload.connectRequestStatus,
        loadingButtonAppointment: false,
        updateMsgAlert: payload.updateMsgAlert
      };
    case PUBLICPAGE_ACTIVATE:
      return {
        ...state,
        publicPage: payload.publicPage,
        loadingButtonDeactivate: false
      };
    case PUBLICPAGE_DEACTIVATE:
      return {
        ...state,
        publicPage: payload.publicPage,
        loadingButtonDeactivate: false
      };
    case CLEAR_UPDATED_MESSAGE:
      return {
        ...state,
        updateMsgAlert: ""
      };
    case CLEAR_STUDENTREQUESTS:
      return {
        ...state,
        deletedMessage: "",
        responded: false
      };
    case LOGOUT:
      return {
        loading: true,
        newPublicPage: "",
        newMessage: "",
        updatedMessage: "",
        deletedMessage: "",
        messageList: [],
        publicPage: {
          blockedEmails: [],
          levels: [],
          methods: [],
          availability: [],
          avatar: {
            url: ""
          },
          uniqueURL: "",
          activatePagePublic: "",
          name: "",
          city: "",
          country: "",
          rate: "",
          currency: "",
          facebook: "",
          instagram: "",
          twitter: "",
          linkedIn: "",
          description: "",
          youtube: "",
          years: 0,
          degree: "",
          certification: "",
          lastExperience: ""
        },
        publicPageURL: {
          avatar: {
            url: ""
          },
          name: "",
          city: "",
          country: "",
          rate: "",
          currency: "",
          facebook: "",
          instagram: "",
          twitter: "",
          linkedIn: "",
          description: "",
          youtube: "",
          years: 0,
          degree: "",
          certification: "",
          lastExperience: "",
          levels: [],
          methods: [],
          availability: [],
          activatePublicPage: ""
        },
        initialPageNumber: 1,
        initialNumberOfPages: 1,
        pageNumber: 1,
        numberOfPages: 1,
        keywords: "",
        searchMessage: [],
        paginationModeMessage: "date",
        tutorList: [],
        initialPageNumberTutor: 1,
        initialNumberOfPagesTutor: 1,
        pageNumberTutor: 1,
        numberOfPagesTutor: 1,
        keywordsTutor: "",
        searchTutor: [],
        paginationModeTutor: "date",
        tutorFilter: [],
        filterPreferences: {
          levels: "",
          methods: "",
          country: ""
        },
        error: {},
        publicPageExist: false,
        emailVerify: "",
        emailVerifyLoading: true,
        messageObject: {
          appointment: []
        },
        loadingButtonPublicPage: false,
        loadingButtonMessage: false,
        loadingButtonAppointment: false,
        loadingButtonResponse: false,
        loadingButtonDeactivate: false,
        loadingButtonReport: false,
        responded: false,
        verifyMessageEmail: false,
        verifyMessageEmailLoading: true,
        connectRequestStatus: "",
        loadingTutorList: true,
        report: ""
      };

    default:
      return state;
  }
}
