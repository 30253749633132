import React, { Fragment } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import "./Landing.css";
import { useTranslation } from "react-i18next";

import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

const Cookies = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Cookies</title>
        <meta name="description" content="Topscholar's cookie policy" />
        <meta property="og:title" content="Topscholar: Cookies" />
        <meta property="og:description" content="Topscholar's cookie policy" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/cookies" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5 text-center">
        <MDBRow className="pt-5 mt-3 justify-content-center">
          <h3 className="font-weight-bold monserratFont mb-3 cookieMarginsMobile">
            {t("cookies.1")}
          </h3>
        </MDBRow>
        <MDBRow className="justify-content-center">
          <p className="quickSand cookieFont cookieMarginsMobile">
            {t("cookies.2")}
          </p>
          <p className="quickSand copyrightFont justify-content-start cookieMarginsMobile privacyIndent">
            {t("cookies.3")}
          </p>
        </MDBRow>
        <MDBRow className="justify-content-center">
          <p className="quickSand cookieFont cookieMarginsMobile">
            {t("cookies.4")}
          </p>
          <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
            {t("cookies.5")}
          </p>
        </MDBRow>
        <MDBRow className="justify-content-center">
          <p className="quickSand cookieFont cookieMarginsMobile">
            {t("cookies.6")}
          </p>
        </MDBRow>
        <MDBRow>
          <p className="quickSand copyrightFont cookieMarginsMobile">
            {t("cookies.7")}
          </p>

          <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
            {t("cookies.8")}
          </p>
        </MDBRow>
        <MDBRow>
          <p className="quickSand copyrightFont cookieMarginsMobile">
            {t("cookies.9")}
          </p>

          <p className="quickSand copyrightFont justif-content-start cookieMarginsMobile privacyIndent">
            {t("cookies.10")}
          </p>
        </MDBRow>
        <MDBRow>
          <p className="quickSand copyrightFont cookieMarginsMobile">
            {t("cookies.11")}
          </p>

          <p className="quickSand copyrightFont justif-content-start cookieMarginsMobile privacyIndent">
            {t("cookies.12")}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://stripe.com/cookies-policy/legal"}
            >
              {" "}
              stripe.com/cookies-policy/legal.
            </a>
          </p>
          <p className="quickSand copyrightFont justif-content-start cookieMarginsMobile privacyIndent">
            {t("cookies.13")}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
              }
            >
              {" "}
              developers.google.com/analytics/...
            </a>
          </p>
          <p className="quickSand copyrightFont justif-content-start cookieMarginsMobile privacyIndent">
            {t("cookies.14")}
          </p>
        </MDBRow>
        <MDBRow className="justify-content-center mb-3">
          <p className="quickSand cookieFont cookieMarginsMobile">
            {t("cookies.15")}
          </p>
        </MDBRow>
        <MDBRow>
          <p className="quickSand copyrightFont justif-content-start cookieMarginsMobile">
            {t("cookies.16")}
          </p>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="3" className="col-12">
            <MDBRow>
              <p className="quickSand copyrightFont cookieMarginsMobile">
                • Chrome
              </p>
            </MDBRow>
            <MDBRow>
              <p className="quickSand copyrightFont cookieMarginsMobile justif-content-start">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies"
                  }
                >
                  Chrome {t("cookies.17")}
                </a>
              </p>
            </MDBRow>
          </MDBCol>
          <MDBCol sm="3" className="col-12">
            <MDBRow>
              <p className="quickSand copyrightFont cookieMarginsMobile">
                • Firefox
              </p>
            </MDBRow>
            <MDBRow>
              <p className="quickSand copyrightFont cookieMarginsMobile justif-content-start">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                  }
                >
                  Firefox {t("cookies.17")}
                </a>
              </p>
            </MDBRow>
          </MDBCol>
          <MDBCol sm="3" className="col-12">
            <MDBRow>
              <p className="quickSand copyrightFont cookieMarginsMobile">
                • Safari
              </p>
            </MDBRow>
            <MDBRow>
              <p className="quickSand copyrightFont cookieMarginsMobile justif-content-start">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                  }
                >
                  Safari {t("cookies.17")}
                </a>
              </p>
            </MDBRow>
          </MDBCol>
          <MDBCol sm="3" className="col-12">
            <MDBRow>
              <p className="quickSand copyrightFont cookieMarginsMobile">
                • Edge
              </p>
            </MDBRow>
            <MDBRow>
              <p className="quickSand copyrightFont cookieMarginsMobile justif-content-start">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://support.microsoft.com/en-us/help/4027947/windows-delete-cookies"
                  }
                >
                  Microsoft {t("cookies.17")}
                </a>
              </p>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
          <p className="quickSand copyrightFont cookieMarginsMobile justif-content-start privacyIndent">
            {t("cookies.18")}
          </p>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default withRouter(Cookies);
