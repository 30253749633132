import React, { useState, useEffect, Fragment } from "react";
import { HashLink } from "react-router-hash-link";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import queryString from "query-string";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import Register from "../../components/auth/Register";
import { sendContact } from "../../actions/auth";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBIcon,
  MDBBtn,
  MDBInput,
  MDBFooter,
  MDBTestimonial,
  MDBLightbox,
  MDBView,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  ModalFooter
} from "mdbreact";
import "./Landing.css";

const Landing = ({ auth: { contactForm }, sendContact, history, location }) => {
  const [formData, setFormData] = useState({
    contact: {
      name: "",
      subject: "",
      message: "",
      email: ""
    },
    feature: 1
  });
  const { t } = useTranslation();

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        contact: {
          name: "",
          subject: "",
          message: "",
          email: ""
        }
      };
    });
  }, [contactForm]);

  const onChange = e =>
    setFormData({
      ...formData,
      contact: {
        ...formData.contact,
        [e.target.name]: e.target.value
      }
    });

  const clickContactUs = async e => {
    e.preventDefault();

    sendContact(formData.contact);
  };

  const date = new Date().getFullYear();
  const changeFeature = value => {
    setFormData({ ...formData, feature: value });
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: English Tutor App</title>

        <meta
          name="description"
          content="Create lessons, exams and much more. Book tutoring appointments and trade content with other English Teachers. Completely free"
        />
        <meta property="og:title" content="Topscholar: English Tutor App" />
        <meta
          property="og:description"
          content="Create lessons, exams and much more. Book tutoring appointments and trade content with other English Teachers. Completely free"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/" />
      </Helmet>
      <div id="landing">
        <MDBCard id="home" className="h-100">
          <MDBMask className="d-flex justify-content-center align-items-center">
            <MDBContainer className="h-100 d-flex justify-content-center align-items-center mt-5 mb-5">
              <MDBRow className="align-items-start pt-5 mt-3">
                <MDBCol
                  md="6"
                  sm="12"
                  className="text-center text-md-left mb-5"
                >
                  <div className="white-text text-center">
                    {/* <img
                    src={Logo2}
                    className="img-responsive"
                    width="80%"
                    height="auto"
                    alt=""
                  /> */}
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        width="560"
                        height="315"
                        title="Topscholar Intro"
                        src="https://www.youtube.com/embed/20cUC4pZKWc?rel=0"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                    {/* <h1 className="abrilfatface indigo-text text-center">
                    Topscholar
                  </h1>
                  <h2 className="sourcesanspro subfont text-center">
                    Tutor App
                  </h2> */}

                    <p className="h1-responsive quickSand font-weight-bold text-center mt-3">
                      {t("landing.1")}
                    </p>
                    <hr className="hr-light " />
                    <p className="quickSand text-center px-2 landingFont2">
                      {t("landing.2")}
                    </p>
                    <br />
                    <MDBRow center>
                      <HashLink
                        to="#learnMore"
                        scroll={el =>
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "start"
                          })
                        }
                        className="btn btn-outline-white quickSand "
                      >
                        {t("landing.3")}
                      </HashLink>
                    </MDBRow>
                  </div>
                </MDBCol>

                <MDBCol md="6" sm="12" className="col-xl-5 offset-xl-1">
                  <MDBCard className="page-padding text-primary registercard">
                    <MDBCardBody>
                      <div className="text-center">
                        <h3 className="white-text loginRegisterTitle">
                          <MDBIcon className="white-text " /> {t("landing.4")}
                        </h3>
                        <hr className="hr-light" />
                      </div>

                      <Register />
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBCard>

        <section id="learnMore" className="h-100 mb-2">
          <MDBRow>
            <MDBCol sm="12" className="text-right ">
              <h2 className="quickSand h1-responsive font-weight-bold text-center my-5">
                {t("landing.10")}
              </h2>
              <p className="quickSand lead black-text w-responsive text-center mx-auto mb-5">
                {t("landing.12")}
              </p>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol lg="5" className="text-right ">
              <img
                src={
                  "https://res.cloudinary.com/unnamed/image/upload/v1578785747/Landing/Books_ig0kng.png"
                }
                className="img-responsive imageMargin"
                width="100%"
                height="auto"
                alt=""
              />
            </MDBCol>
            <MDBCol lg="7">
              <MDBRow className="mb-3">
                <MDBCol size="1">
                  <MDBIcon icon="book-open" size="lg" className="blue-text" />
                </MDBCol>
                <MDBCol xl="10" md="11" size="10">
                  <h5 className="quickSand font-weight-bold mb-3">
                    {t("landing.13")}
                  </h5>
                  <p className="quickSand lead  black-text">
                    {t("landing.14")}
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol size="1">
                  <MDBIcon icon="euro-sign" size="lg" className="purple-text" />
                </MDBCol>
                <MDBCol xl="10" md="11" size="10">
                  <h5 className="quickSand font-weight-bold mb-3">
                    {t("landing.15")}
                  </h5>
                  <p className="quickSand lead black-text">{t("landing.16")}</p>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol size="1">
                  <MDBIcon icon="piggy-bank" size="lg" className="cyan-text" />
                </MDBCol>
                <MDBCol xl="10" md="11" size="10">
                  <h5 className="quickSand font-weight-bold mb-3">
                    {t("landing.17")}
                  </h5>
                  <p className="quickSand lead  black-text">
                    {t("landing.18")}
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol size="1">
                  <MDBIcon
                    icon="balance-scale"
                    size="lg"
                    className="cyan-text"
                  />
                </MDBCol>
                <MDBCol xl="10" md="11" size="10">
                  <h5 className="quickSand font-weight-bold mb-3">
                    {t("landing.41")}
                  </h5>
                  <p className="quickSand lead  black-text">
                    {t("landing.42")}
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </section>

        <section className="text-center" id="features">
          <MDBContainer>
            <MDBRow center>
              <h2 className="quickSand h1-responsive font-weight-bold my-5">
                {t("features.44")}
              </h2>
            </MDBRow>
            {/* <p className="lead black-text w-responsive text-center mx-auto mb-5">
                {t("landing.20")}
              </p> */}
            <div className="adjustHeight">
              {formData.feature === 1 ? (
                <MDBRow className="text-center">
                  <MDBCol md="6" className="">
                    <MDBLightbox
                      images={[
                        {
                          src:
                            "https://res.cloudinary.com/unnamed/image/upload/f_auto/v1577823156/Landing/lesson_tyi2rk.jpg",
                          alt: "lessonFeature"
                        }
                      ]}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="">
                    <MDBRow center>
                      <h4 className="quickSand   dark-grey-text">
                        {t("features.1")}
                      </h4>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.2")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.3")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.4")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.5")}
                      </p>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ) : null}
              {formData.feature === 2 ? (
                <MDBRow className="text-center">
                  <MDBCol md="6" className="">
                    <MDBLightbox
                      images={[
                        {
                          src:
                            "https://res.cloudinary.com/unnamed/image/upload/v1577829161/Landing/trading2_nyrgvn.jpg",
                          alt: "tradingFeature"
                        }
                      ]}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="">
                    <MDBRow center>
                      <h4 className="quickSand   dark-grey-text">
                        {t("features.6")}
                      </h4>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.7")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.9")}
                      </p>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ) : null}
              {formData.feature === 3 ? (
                <MDBRow className="text-center">
                  <MDBCol md="6" className="">
                    <MDBLightbox
                      images={[
                        {
                          src:
                            "https://res.cloudinary.com/unnamed/image/upload/v1577829898/Landing/payment_tx9e5t.jpg",
                          alt: "paymentFeature"
                        }
                      ]}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="">
                    <MDBRow center>
                      <h4 className="quickSand   dark-grey-text">
                        {t("features.10")}
                      </h4>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.11")}
                      </p>
                    </MDBRow>

                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.12")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.13")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.14")}{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={"https://stripe.com/global"}
                        >
                          {t("features.15")}
                        </a>
                      </p>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ) : null}
              {formData.feature === 4 ? (
                <MDBRow className="text-center">
                  <MDBCol md="6" className="">
                    <MDBLightbox
                      images={[
                        {
                          src:
                            "https://res.cloudinary.com/unnamed/image/upload/v1577830285/Landing/decks2_qysviv.jpg",
                          alt: "flashcardFeature"
                        }
                      ]}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="">
                    <MDBRow center>
                      <h4 className="quickSand   dark-grey-text">
                        {t("features.16")}
                      </h4>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.17")}
                      </p>
                    </MDBRow>

                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.18")}
                      </p>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ) : null}
              {formData.feature === 5 ? (
                <MDBRow className="text-center">
                  <MDBCol md="6" className="">
                    <MDBLightbox
                      images={[
                        {
                          src:
                            "https://res.cloudinary.com/unnamed/image/upload/v1577830707/Landing/classes2_aajkdk.jpg",
                          alt: "classFeature"
                        }
                      ]}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="">
                    <MDBRow center>
                      <h4 className="quickSand   dark-grey-text">
                        {t("features.19")}
                      </h4>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.20")}
                      </p>
                    </MDBRow>

                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.21")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.22")}
                      </p>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ) : null}
              {formData.feature === 6 ? (
                <MDBRow className="text-center">
                  <MDBCol md="6" className="">
                    <MDBLightbox
                      images={[
                        {
                          src:
                            "https://res.cloudinary.com/unnamed/image/upload/v1577823156/Landing/exam_wjkevr.jpg",
                          alt: "examFeature"
                        }
                      ]}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="">
                    <MDBRow center>
                      <h4 className="quickSand   dark-grey-text">
                        {t("features.23")}
                      </h4>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.24")}
                      </p>
                    </MDBRow>

                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.25")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.26")}
                      </p>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ) : null}
              {formData.feature === 7 ? (
                <MDBRow className="text-center">
                  <MDBCol md="6" className="">
                    <MDBLightbox
                      images={[
                        {
                          src:
                            "https://res.cloudinary.com/unnamed/image/upload/v1577823156/Landing/chat_wybnzd.jpg",
                          alt: "chatFeature"
                        }
                      ]}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="">
                    <MDBRow center>
                      <h4 className="quickSand   dark-grey-text">
                        {t("features.27")}
                      </h4>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.28")}
                      </p>
                    </MDBRow>

                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.29")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.30")}
                      </p>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ) : null}
              {formData.feature === 8 ? (
                <MDBRow className="text-center">
                  <MDBCol md="6" className="">
                    <MDBLightbox
                      images={[
                        {
                          src:
                            "https://res.cloudinary.com/unnamed/image/upload/v1577823156/Landing/Calendar_e4ezvj.jpg",
                          alt: "chatFeature"
                        }
                      ]}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="">
                    <MDBRow center>
                      <h4 className="quickSand   dark-grey-text">
                        {t("features.31")}
                      </h4>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.32")}
                      </p>
                    </MDBRow>

                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.33")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.34")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.35")}
                      </p>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ) : null}
              {formData.feature === 9 ? (
                <MDBRow className="text-center">
                  <MDBCol md="6" className="">
                    <MDBLightbox
                      images={[
                        {
                          src:
                            "https://res.cloudinary.com/unnamed/image/upload/v1577842698/Landing/whiteboard2_ts1aij.jpg",
                          alt: "chatFeature"
                        }
                      ]}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="">
                    <MDBRow center>
                      <h4 className="quickSand   dark-grey-text">
                        {t("features.36")}
                      </h4>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.37")}
                      </p>
                    </MDBRow>

                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.38")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.39")}
                      </p>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ) : null}
              {formData.feature === 10 ? (
                <MDBRow className="text-center">
                  <MDBCol md="6" className="">
                    <MDBLightbox
                      images={[
                        {
                          src:
                            "https://res.cloudinary.com/unnamed/image/upload/v1577823157/Landing/studentdash_ee02yj.jpg",
                          alt: "chatFeature"
                        }
                      ]}
                    />
                  </MDBCol>
                  <MDBCol md="6" className="">
                    <MDBRow center>
                      <h4 className="quickSand   dark-grey-text">
                        {t("features.40")}
                      </h4>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.41")}
                      </p>
                    </MDBRow>

                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.42")}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <p className="quickSand landingFont text-left">
                        <MDBIcon
                          icon="fas fa-dot-circle"
                          className="mr-2 mt-1 documentationIcon2"
                        />
                        {t("features.43")}
                      </p>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ) : null}
            </div>
            <MDBRow center className="mt-2">
              <MDBCol md="3" className="col-5 mx-1">
                <MDBRow center className="mb-2">
                  <MDBView>
                    <img
                      src="https://res.cloudinary.com/unnamed/image/upload/c_scale,w_400/v1577823156/Landing/lesson_tyi2rk.jpg"
                      className="img-fluid rounded mb-0"
                      alt=""
                    />
                    <MDBMask
                      onClick={() => changeFeature(1)}
                      className="flex-center"
                      overlay={
                        formData.feature === 1 ? "blue-strong" : "blue-light"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <p className="black-text font-weight-bold h6-responsive quickSand">
                        {t("features.1")}
                      </p>
                    </MDBMask>
                  </MDBView>
                </MDBRow>
              </MDBCol>
              <MDBCol md="3" className="col-5 mx-1">
                <MDBRow center className="mb-2">
                  <MDBView>
                    <img
                      src="https://res.cloudinary.com/unnamed/image/upload/c_scale,h_280,w_400/v1577829161/Landing/trading2_nyrgvn.jpg"
                      alt=""
                      className="img-fluid rounded mb-0"
                    />
                    <MDBMask
                      className="flex-center"
                      overlay={
                        formData.feature === 2 ? "blue-strong" : "blue-light"
                      }
                      onClick={() => changeFeature(2)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="black-text font-weight-bold h6-responsive quickSand">
                        {t("features.6")}
                      </p>
                    </MDBMask>
                  </MDBView>
                </MDBRow>
              </MDBCol>
              <MDBCol md="3" className="col-5 mx-1">
                <MDBRow center className="mb-2">
                  <MDBView>
                    <img
                      src="https://res.cloudinary.com/unnamed/image/upload/c_scale,h_280,w_400/v1577829898/Landing/payment_tx9e5t.jpg"
                      alt=""
                      className="img-fluid rounded mb-0"
                    />
                    <MDBMask
                      className="flex-center"
                      overlay={
                        formData.feature === 3 ? "blue-strong" : "blue-light"
                      }
                      onClick={() => changeFeature(3)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="black-text font-weight-bold h6-responsive quickSand">
                        {t("features.10")}
                      </p>
                    </MDBMask>
                  </MDBView>
                </MDBRow>
              </MDBCol>
              <MDBCol md="3" className="col-5 mx-1">
                <MDBRow center className="mb-2">
                  <MDBView>
                    <img
                      src="https://res.cloudinary.com/unnamed/image/upload/c_fill,h_280,w_400/v1577830285/Landing/decks2_qysviv.jpg"
                      alt=""
                      className="img-fluid rounded mb-0"
                    />
                    <MDBMask
                      className="flex-center"
                      overlay={
                        formData.feature === 4 ? "blue-strong" : "blue-light"
                      }
                      onClick={() => changeFeature(4)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="black-text font-weight-bold h6-responsive quickSand">
                        {t("features.16")}
                      </p>
                    </MDBMask>
                  </MDBView>
                </MDBRow>
              </MDBCol>
              <MDBCol md="3" className="col-5 mx-1">
                <MDBRow center className="mb-2">
                  <MDBView>
                    <img
                      src="https://res.cloudinary.com/unnamed/image/upload/c_fill,h_280,w_400/v1577830707/Landing/classes2_aajkdk.jpg"
                      alt=""
                      className="img-fluid rounded mb-0"
                    />
                    <MDBMask
                      className="flex-center "
                      overlay={
                        formData.feature === 5 ? "blue-strong" : "blue-light"
                      }
                      onClick={() => changeFeature(5)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="black-text font-weight-bold h6-responsive quickSand">
                        {t("features.19")}
                      </p>
                    </MDBMask>
                  </MDBView>
                </MDBRow>
              </MDBCol>
              <MDBCol md="3" className="col-5 mx-1">
                <MDBRow center className="mb-2">
                  <MDBView>
                    <img
                      src="https://res.cloudinary.com/unnamed/image/upload/c_fill,h_280,w_400/v1577823156/Landing/exam_wjkevr.jpg"
                      alt=""
                      className="img-fluid rounded mb-0"
                    />
                    <MDBMask
                      className="flex-center "
                      overlay={
                        formData.feature === 6 ? "blue-strong" : "blue-light"
                      }
                      onClick={() => changeFeature(6)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="black-text font-weight-bold h6-responsive quickSand">
                        {t("features.23")}
                      </p>
                    </MDBMask>
                  </MDBView>
                </MDBRow>
              </MDBCol>
              <MDBCol md="3" className="col-5 mx-1">
                <MDBRow center className="mb-2">
                  <MDBView>
                    <img
                      src="https://res.cloudinary.com/unnamed/image/upload/c_scale,h_280,w_400/v1577823156/Landing/chat_wybnzd.jpg"
                      alt=""
                      className="img-fluid rounded mb-0"
                    />
                    <MDBMask
                      className="flex-center "
                      overlay={
                        formData.feature === 7 ? "blue-strong" : "blue-light"
                      }
                      onClick={() => changeFeature(7)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="black-text font-weight-bold h6-responsive quickSand">
                        {t("features.27")}
                      </p>
                    </MDBMask>
                  </MDBView>
                </MDBRow>
              </MDBCol>
              <MDBCol md="3" className="col-5 mx-1">
                <MDBRow center className="mb-2">
                  <MDBView>
                    <img
                      src="https://res.cloudinary.com/unnamed/image/upload/c_scale,w_400/v1577823156/Landing/Calendar_e4ezvj.jpg"
                      alt=""
                      className="img-fluid rounded mb-0"
                    />
                    <MDBMask
                      className="flex-center "
                      overlay={
                        formData.feature === 8 ? "blue-strong" : "blue-light"
                      }
                      onClick={() => changeFeature(8)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="black-text font-weight-bold h6-responsive quickSand">
                        {t("features.31")}
                      </p>
                    </MDBMask>
                  </MDBView>
                </MDBRow>
              </MDBCol>
              <MDBCol md="3" className="col-5 mx-1">
                <MDBRow center className="mb-2">
                  <MDBView>
                    <img
                      src="https://res.cloudinary.com/unnamed/image/upload/c_fill,h_280,w_400/v1577842698/Landing/whiteboard2_ts1aij.jpg"
                      alt=""
                      className="img-fluid rounded mb-0"
                    />
                    <MDBMask
                      className="flex-center "
                      overlay={
                        formData.feature === 9 ? "blue-strong" : "blue-light"
                      }
                      onClick={() => changeFeature(9)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="black-text font-weight-bold h6-responsive quickSand">
                        {t("features.36")}
                      </p>
                    </MDBMask>
                  </MDBView>
                </MDBRow>
              </MDBCol>
              <MDBCol md="3" className="col-5 mx-1">
                <MDBRow center className="mb-2">
                  <MDBView>
                    <img
                      src="https://res.cloudinary.com/unnamed/image/upload/c_scale,h_280,w_400/v1577823157/Landing/studentdash_ee02yj.jpg"
                      alt=""
                      className="img-fluid rounded mb-0"
                    />
                    <MDBMask
                      className="flex-center "
                      overlay={
                        formData.feature === 10 ? "blue-strong" : "blue-light"
                      }
                      onClick={() => changeFeature(10)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="black-text font-weight-bold h6-responsive quickSand">
                        {t("features.40")}
                      </p>
                    </MDBMask>
                  </MDBView>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
        <section className="text-center" id="payment22">
          <MDBContainer>
            <MDBRow center>
              <MDBRow center>
                <h2 className="quickSand h1-responsive font-weight-bold my-5">
                  {t("landing.19")}
                </h2>
                <p className="lead black-text  text-center mx-auto mb-5">
                  {t("landing.20")}
                </p>

                <MDBRow className="text-center">
                  <MDBCol md="4" className="mb-md-0 mb-5">
                    <MDBTestimonial>
                      <img
                        src={
                          "https://res.cloudinary.com/unnamed/image/upload/v1579692105/Landing/Picture_for_signup_kbpo6j.png"
                        }
                        alt=""
                        className=" img-fluid "
                      />

                      <h4 className="quickSand font-weight-bold dark-grey-text mt-4">
                        {t("landing.21")}
                      </h4>
                      <p className="quickSand font-weight-normal dark-grey-text">
                        {t("landing.22")}
                      </p>
                    </MDBTestimonial>
                  </MDBCol>
                  <MDBCol md="4" className="mb-md-0 mb-5">
                    <MDBTestimonial>
                      <img
                        src={
                          "https://res.cloudinary.com/unnamed/image/upload/v1579692105/Landing/Picture_for_payments_tbeuql.png"
                        }
                        alt=""
                        className="img-fluid"
                      />
                      <h4 className="quickSand font-weight-bold dark-grey-text mt-4">
                        {t("landing.23")}
                      </h4>
                      <p className="quickSand font-weight-normal dark-grey-text">
                        {t("landing.24")}
                      </p>
                    </MDBTestimonial>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBTestimonial>
                      <img
                        src={
                          "https://res.cloudinary.com/unnamed/image/upload/v1579692131/Landing/Picture_for_withdraw_1_ai2tsh.png"
                        }
                        alt=""
                        className="img-fluid"
                      />
                      <h4 className="quickSand font-weight-bold dark-grey-text mt-4">
                        {t("landing.25")}
                      </h4>
                      <p className="quickSand font-weight-normal dark-grey-text">
                        {t("landing.26")}
                      </p>
                    </MDBTestimonial>
                  </MDBCol>
                </MDBRow>
              </MDBRow>
            </MDBRow>
          </MDBContainer>
        </section>

        {/* <MDBContainer-fluid>
          <section className="mb-5">
            <form onSubmit={e => clickContactUs(e)}>
              <MDBCol size="12">
                <p className="text-center my-5 h1-responsive">
                  {t("landing.27")}
                </p>
                <p className=" quickSand lead text-center mb-5 w-responsive mx-auto">
                  {t("landing.28")}
                </p>
              </MDBCol>
              <MDBRow center className="mx-4">
                <MDBCol lg="6">
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="md-form mb-0">
                        <MDBInput
                          name="name"
                          onChange={e => onChange(e)}
                          value={formData.contact.name}
                          required
                          type="text"
                          label={t("landing.29")}
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="md-form mb-0">
                        <MDBInput
                          label="Your email"
                          name="email"
                          onChange={e => onChange(e)}
                          value={formData.contact.email}
                          required
                          type="email"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="md-form mb-0">
                        <MDBInput
                          name="subject"
                          onChange={e => onChange(e)}
                          value={formData.contact.subject}
                          required
                          type="text"
                          label={t("landing.31")}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="md-form mb-0">
                        <MDBInput
                          name="message"
                          onChange={e => onChange(e)}
                          value={formData.contact.message}
                          required
                          type="textarea"
                          label={t("landing.32")}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow center>
                    <MDBBtn type="submit" outline color="purple">
                      {t("landing.33")}
                    </MDBBtn>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </form>
          </section>
        </MDBContainer-fluid> */}
        <MDBFooter className="pt-3 mt-5 text-center pb-4">
          <MDBContainer>
            <MDBRow center className="pt-3">
              <MDBCol md="6">
                <h6 className="mb-0 py-3 text-center">
                  <strong>
                    &copy; {date} Copyright:{" "}
                    <a href="https://www.Topscholar.io"> Topscholar OÜ </a>
                  </strong>
                </h6>
                <p>{t("landing.34")}</p>
                <ul className="text-center list-unstyled">
                  <li>
                    <MDBIcon size="2x" icon="envelope" className="white-text" />
                    <p>sales@topscholar.io</p>
                  </li>
                </ul>
              </MDBCol>
              <hr className="w-100 clearfix d-md-none" />
              <MDBCol md="6" className="ml-auto contactMargin">
                <h6 className="text-uppercase font-weight-bold">
                  {t("landing.35")}
                </h6>
                <ul className="list-unstyled">
                  <li>
                    <HashLink to="/Team">{t("landing.36")}</HashLink>
                  </li>
                  <li>
                    <HashLink to="/Terms">{t("landing.37")}</HashLink>
                  </li>
                  <li>
                    <HashLink to="/StudentTerms">{t("landing.43")}</HashLink>
                  </li>
                  <li>
                    <HashLink to="/Privacy">{t("landing.38")}</HashLink>
                  </li>
                  <li>
                    <HashLink to="/Copyright">{t("landing.39")}</HashLink>
                  </li>
                  <li>
                    <HashLink to="/Cookies">Cookies</HashLink>
                  </li>
                  <li>
                    <HashLink to="/FAQ">{t("landing.40")}</HashLink>
                  </li>
                  <li>
                    <HashLink to="/blog">Blog</HashLink>
                  </li>
                  {/* <li>
                    <HashLink to="/affiliates">{t("landing.44")}</HashLink>
                  </li> */}
                </ul>
              </MDBCol>
              <hr className="w-100 clearfix d-md-none" />
            </MDBRow>
          </MDBContainer>
          <hr />
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12">
                <ul className="list-unstyled d-flex justify-content-center mb-0 pb-0 pt-2 list-inline">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/topscholarapp/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MDBBtn size="medium" floating social="fb">
                        {" "}
                        <MDBIcon fab icon="facebook-f" />{" "}
                      </MDBBtn>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://twitter.com/Topscholarapp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MDBBtn size="medium" floating social="tw">
                        {" "}
                        <MDBIcon fab icon="twitter" />{" "}
                      </MDBBtn>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/topscholar/about/?viewAsMember=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MDBBtn size="medium" floating social="li">
                        <MDBIcon fab icon="linkedin-in" />
                      </MDBBtn>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/topscholarapp/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MDBBtn
                        size="medium"
                        floating
                        social="ins"
                        color="pink lighten-3"
                      >
                        <MDBIcon fab icon="instagram" />
                      </MDBBtn>
                    </a>
                  </li>
                </ul>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBFooter>
      </div>
    </Fragment>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, { sendContact })(Landing));
