import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_GAMES,
  GAMES_ERROR,
  GAMES_DELETE,
  ADD_GAMES,
  UPDATE_GAME,
  CLEAR_GAMES,
  SEARCH_GAMES,
  SET_PAGINATION_MODE_GAMES,
  SET_LOADING_BUTTON_GAMES,
  CLEAR_GAMES_SEARCH,
  SET_LOADING_DELETE_GAME
} from "./types";

// Get current games
export const getGames = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/games/get", formData, config);

    dispatch({
      type: GET_GAMES,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GAMES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete games

export const deleteGame = (id, history) => async dispatch => {
  try {
    const res = await axios.delete(`/api/games/${id}`);

    dispatch({
      type: GAMES_DELETE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
    if (history) {
      history.push("/games");
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GAMES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add games

export const addGames = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/games", formData, config);

    dispatch({
      type: ADD_GAMES,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GAMES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update game

export const updateGame = (id, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/games/${id}`, formData, config);

    dispatch({
      type: UPDATE_GAME,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GAMES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const clearGamesReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_GAMES
  });
};

// Post Search Results Games

export const searchGamesFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/games/search", formData, config);

    dispatch({
      type: SEARCH_GAMES,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GAMES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Games

export const setPaginationModeGames = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_GAMES,
    payload: formData
  });
};

// Set Loading Button Games

export const setLoadingButtonGames = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_GAMES,
    payload: formData
  });
};

// Clear Lesson Search Results

export const clearGamesSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_GAMES_SEARCH
  });
};

export const setLoadingDeleteGame = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_DELETE_GAME,
    payload: formData
  });
};
