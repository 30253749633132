import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import TradingMain from "../TradingMain";
import {
  getPublishedGame,
  downloadGame,
  getPublishedEditedGames,
  setLoadingButtonDownload
} from "../../../actions/trading";

const Game = ({
  history,
  mode,
  match,
  getPublishedGame,
  downloadGame,
  setLoadingButtonDownload,
  trading: { publishedGame },
  getPublishedEditedGames,
  block
}) => {
  const [formData, setFormData] = useState({
    mode: "game",
    block: false
  });

  useEffect(() => {
    getPublishedGame(match.params.id);
  }, [getPublishedGame, match]);

  const downloadGameFunc = () => {
    setLoadingButtonDownload(true);
    downloadGame({
      title: publishedGame.title,
      instructions: publishedGame.instructions,
      materials: publishedGame.materials,
      youtube: publishedGame.youtube,
      links: publishedGame.links,
      duration: publishedGame.duration,
      numberOfStudents: publishedGame.numberOfStudents,
      level: publishedGame.level,
      tradingId: publishedGame._id
    });
  };

  useEffect(() => {
    if (publishedGame) {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "game"
        };
      });
    }
  }, [publishedGame]);

  useEffect(() => {
    if (block === true || block === false) {
      setFormData(prevState => {
        return {
          ...prevState,
          block: block
        };
      });
    }
  }, [block]);

  useEffect(() => {
    getPublishedEditedGames({ originalId: match.params.id });
  }, [getPublishedEditedGames, match]);

  return (
    <Fragment>
      <TradingMain
        downloadGameFunc={downloadGameFunc}
        gameContent={publishedGame}
        url={formData.mode}
        block={formData.block}
      ></TradingMain>
    </Fragment>
  );
};

Game.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    getPublishedGame,
    downloadGame,
    getPublishedEditedGames,
    setLoadingButtonDownload
  })(Game)
);
