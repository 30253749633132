import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getEvaluationList } from "../../../actions/evaluations";
import { getEvals } from "../../../actions/customizables";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBCardHeader
} from "mdbreact";

const ExamList = ({
  students: { user },
  getEvaluationList,
  getEvals,
  evaluations: { listEvaluations, initialPageNumber, initialNumberOfPages },
  customizables: { cusEvalList, initialPageNumberCus, initialNumberOfPagesCus }
}) => {
  const [formData] = useState({
    activeItemOuterTabs: "1"
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (user._id) {
      getEvaluationList({
        userTutor: user.userTutor,
        studentId: user._id,
        initialPageNumber: "first"
      });
      getEvals({
        studentId: user._id,
        initialPageNumberCus: "first"
      });
    }
  }, [getEvaluationList, getEvals, user]);

  // Pagination Evaluations List

  const isActive1 = page => {
    if (initialPageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationEvaluationList = () => {
    getEvaluationList({
      userTutor: user.userTutor,
      studentId: user._id,
      initialPageNumber: "first"
    });
  };

  const previousPaginationEvaluationList = () => {
    getEvaluationList({
      initialPageNumber: initialPageNumber - 1,
      userTutor: user.userTutor,
      studentId: user._id
    });
  };
  const currentPaginationEvaluationList = index => {
    getEvaluationList({
      initialPageNumber: index + 1,
      userTutor: user.userTutor,
      studentId: user._id
    });
  };

  const nextPaginationEvaluationList = () => {
    getEvaluationList({
      initialPageNumber: initialPageNumber + 1,
      userTutor: user.userTutor,
      studentId: user._id
    });
  };

  const lastPaginationEvaluationList = () => {
    getEvaluationList({
      initialPageNumber: "last",
      userTutor: user.userTutor,
      studentId: user._id
    });
  };

  // Pagination Customizable Exams

  const isActive2 = page => {
    if (initialPageNumberCus === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationCus = () => {
    getEvals({ studentId: user._id, initialPageNumberCus: "first" });
  };

  const previousPaginationCus = () => {
    getEvals({
      initialPageNumberCus: initialPageNumberCus - 1,
      studentId: user._id
    });
  };

  const currentPaginationCus = index => {
    getEvals({
      initialPageNumberCus: index + 1,
      studentId: user._id
    });
  };

  const nextPaginationCus = () => {
    getEvals({
      initialPageNumberCus: initialPageNumberCus + 1,
      studentId: user._id
    });
  };

  const lastPaginationCus = () => {
    getEvals({
      initialPageNumberCus: "last",
      studentId: user._id
    });
  };

  return user.view && user.view.evaluations === false ? (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student Evaluations</title>

        <meta name="robots" content="noindex" />
      </Helmet>

      <MDBContainer className=" justify-content-center align-items-center mt-5 mb-5">
        <MDBRow className="pt-5 mt-3" center>
          <MDBCard narrow>
            <MDBCardHeader className="view view-cascade  primary-color d-flex justify-content-between align-items-center py-2 mx-4 mb-1 ">
              <MDBCol className="text-center">
                <p className="h5 quickSand white-text mx-2">
                  {t("studentEvaluations.1")}
                </p>
              </MDBCol>
            </MDBCardHeader>
            <MDBCardBody cascade>
              <p className="quickSand black-text h5">{t("studentLessons.2")}</p>
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  ) : (
    <Fragment>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow className="pt-5 mt-3">
          <MDBCol md="12">
            <MDBNav tabs className="nav-justified " color="primary">
              <MDBNavItem>
                <MDBNavLink
                  to="#"
                  className={
                    formData.activeItemOuterTabs === "1" ? "active" : ""
                  }
                  role="tab"
                  style={{ cursor: "default" }}
                >
                  <MDBIcon icon="chart-bar" />
                  {t("studentEvaluations.1")}
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol md="12">
            <MDBTabContent
              className="card mb-5 bgb"
              activeItem={formData.activeItemOuterTabs}
            >
              <MDBTabPane tabId="1" role="tabpanel">
                <MDBRow>
                  <MDBCol>
                    <MDBCard className="mt-3 ">
                      {user.view && user.view.evaluations === false ? (
                        <MDBCardBody>
                          <MDBRow>
                            <MDBCol>
                              <p className="text-center">
                                {t("studentEvaluations.2")}
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </MDBCardBody>
                      ) : (
                        <Fragment>
                          <MDBCardBody>
                            <MDBRow center className="my-4">
                              <p className="quickSand h5-responsive text-center">
                                {t("studentEvaluations.3")}
                              </p>
                            </MDBRow>
                            <MDBRow center>
                              <MDBCol className="text-center" size="4">
                                <p className="quickSand font-weight-bold h6">
                                  {t("studentEvaluations.4")}
                                </p>
                              </MDBCol>
                              <MDBCol className="text-center" size="4">
                                <p className="quickSand font-weight-bold h6">
                                  {t("studentEvaluations.5")}
                                </p>
                              </MDBCol>
                              <MDBCol className="text-center" size="4">
                                <p className="quickSand font-weight-bold h6">
                                  {t("studentEvaluations.6")}
                                </p>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                            <div style={{ minHeight: "17rem" }}>
                              {cusEvalList.map((item, index) => {
                                return (
                                  <Fragment key={item._id}>
                                    <MDBRow center>
                                      <MDBCol className="text-center" size="4">
                                        <p className="quickSand  h6">
                                          {item.name}
                                        </p>
                                      </MDBCol>
                                      <MDBCol className="text-center" size="4">
                                        <p className="quickSand  h6">
                                          {`${item.grade} %`}
                                        </p>
                                      </MDBCol>
                                      <MDBCol className="text-center" size="4">
                                        <p className="quickSand  h6">
                                          {moment(
                                            item.dateExamStarted,
                                            "X"
                                          ).format("YYYY MM DD")}
                                        </p>
                                      </MDBCol>
                                    </MDBRow>
                                  </Fragment>
                                );
                              })}
                            </div>

                            {/* Pagination Customizable Exam Evaluation List Medium Size*/}

                            <div className="middleVertical mt-5 mb-1 paginationGroupsMedium">
                              <MDBRow>
                                <MDBCol>
                                  <MDBPagination circle>
                                    <MDBPageItem onClick={firstPaginationCus}>
                                      <MDBPageNav className="page-link">
                                        <span>First</span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                    <MDBPageItem
                                      onClick={previousPaginationCus}
                                    >
                                      <MDBPageNav
                                        className="page-link"
                                        aria-label="Previous"
                                      >
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">
                                          Previous
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                    {[...Array(initialNumberOfPagesCus)].map(
                                      (item, index) => {
                                        if (
                                          index + 1 ===
                                          initialPageNumberCus
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              active={isActive2(index + 1)}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus - 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus - 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus - 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus + 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus + 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus + 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else {
                                          return null;
                                        }
                                      }
                                    )}
                                    <MDBPageItem onClick={nextPaginationCus}>
                                      <MDBPageNav className="page-link">
                                        &raquo;
                                      </MDBPageNav>
                                    </MDBPageItem>
                                    <MDBPageItem onClick={lastPaginationCus}>
                                      <MDBPageNav className="page-link">
                                        Last
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  </MDBPagination>
                                </MDBCol>
                              </MDBRow>
                            </div>

                            {/* Pagination Customizable Exam Evaluation List Small Size*/}

                            <div className="middleVertical mt-5 mb-1 paginationGroupsSmall">
                              <MDBRow>
                                <MDBCol>
                                  <MDBPagination circle size="sm">
                                    <MDBPageItem onClick={firstPaginationCus}>
                                      <MDBPageNav className="page-link">
                                        <span>First</span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                    <MDBPageItem
                                      onClick={previousPaginationCus}
                                    >
                                      <MDBPageNav
                                        className="page-link"
                                        aria-label="Previous"
                                      >
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">
                                          Previous
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                    {[...Array(initialNumberOfPagesCus)].map(
                                      (item, index) => {
                                        if (
                                          index + 1 ===
                                          initialPageNumberCus
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              active={isActive2(index + 1)}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus - 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus - 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav
                                                key={index}
                                                className="page-link"
                                              >
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus - 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus + 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus + 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberCus + 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              onClick={() =>
                                                currentPaginationCus(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else {
                                          return null;
                                        }
                                      }
                                    )}
                                    <MDBPageItem onClick={nextPaginationCus}>
                                      <MDBPageNav className="page-link">
                                        &raquo;
                                      </MDBPageNav>
                                    </MDBPageItem>
                                    <MDBPageItem onClick={lastPaginationCus}>
                                      <MDBPageNav className="page-link">
                                        Last
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  </MDBPagination>
                                </MDBCol>
                              </MDBRow>
                            </div>
                          </MDBCardBody>
                          <div style={{ minHeight: "25rem" }}>
                            <MDBCardBody>
                              <MDBRow center className="my-4">
                                <p className="quickSand h5-responsive">
                                  {t("studentEvaluations.7")}
                                </p>
                              </MDBRow>
                              <MDBRow center>
                                <MDBCol className="text-center" size="4">
                                  <p className="quickSand font-weight-bold h6">
                                    {t("studentEvaluations.8")}
                                  </p>
                                </MDBCol>
                                <MDBCol className="text-center" size="4">
                                  <p className="quickSand font-weight-bold h6">
                                    {t("studentEvaluations.9")}
                                  </p>
                                </MDBCol>
                                <MDBCol className="text-center" size="4">
                                  <p className="quickSand font-weight-bold h6">
                                    {t("studentEvaluations.10")}
                                  </p>
                                </MDBCol>
                              </MDBRow>
                              <hr />
                              {listEvaluations.map((item, index) => {
                                return (
                                  <Fragment key={item._id}>
                                    <MDBRow center>
                                      <MDBCol className="text-center" size="4">
                                        <p className="quickSand  h6">
                                          {item.title}
                                        </p>
                                      </MDBCol>
                                      <MDBCol className="text-center" size="4">
                                        <p className="quickSand  h6">
                                          {`${item.grade} %`}
                                        </p>
                                      </MDBCol>
                                      <MDBCol className="text-center" size="4">
                                        <p className="quickSand  h6">
                                          {moment(item.date, "x").format(
                                            "YYYY MM DD"
                                          )}
                                        </p>
                                      </MDBCol>
                                    </MDBRow>
                                  </Fragment>
                                );
                              })}
                            </MDBCardBody>
                          </div>

                          {/* Pagination Other Evaluation List Medium Size*/}

                          <div className="middleVertical mt-5 mb-1 paginationGroupsMedium">
                            <MDBRow>
                              <MDBCol>
                                <MDBPagination circle>
                                  <MDBPageItem
                                    onClick={firstPaginationEvaluationList}
                                  >
                                    <MDBPageNav className="page-link">
                                      <span>First</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  <MDBPageItem
                                    onClick={previousPaginationEvaluationList}
                                  >
                                    <MDBPageNav
                                      className="page-link"
                                      aria-label="Previous"
                                    >
                                      <span aria-hidden="true">&laquo;</span>
                                      <span className="sr-only">Previous</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  {[...Array(initialNumberOfPages)].map(
                                    (item, index) => {
                                      if (index + 1 === initialPageNumber) {
                                        return (
                                          <MDBPageItem
                                            key={index}
                                            active={isActive1(index + 1)}
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber - 1
                                      ) {
                                        return (
                                          <MDBPageItem
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber - 2
                                      ) {
                                        return (
                                          <MDBPageItem
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber - 3
                                      ) {
                                        return (
                                          <MDBPageItem
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber + 1
                                      ) {
                                        return (
                                          <MDBPageItem
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber + 2
                                      ) {
                                        return (
                                          <MDBPageItem
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber + 3
                                      ) {
                                        return (
                                          <MDBPageItem
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                                  <MDBPageItem
                                    onClick={nextPaginationEvaluationList}
                                  >
                                    <MDBPageNav className="page-link">
                                      &raquo;
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  <MDBPageItem
                                    onClick={lastPaginationEvaluationList}
                                  >
                                    <MDBPageNav className="page-link">
                                      Last
                                    </MDBPageNav>
                                  </MDBPageItem>
                                </MDBPagination>
                              </MDBCol>
                            </MDBRow>
                          </div>

                          {/* Pagination Other Evaluation List Small Size*/}

                          <div className="middleVertical mt-5 mb-1 paginationGroupsSmall">
                            <MDBRow>
                              <MDBCol>
                                <MDBPagination circle size="sm">
                                  <MDBPageItem
                                    onClick={firstPaginationEvaluationList}
                                  >
                                    <MDBPageNav className="page-link">
                                      <span>First</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  <MDBPageItem
                                    onClick={previousPaginationEvaluationList}
                                  >
                                    <MDBPageNav
                                      className="page-link"
                                      aria-label="Previous"
                                    >
                                      <span aria-hidden="true">&laquo;</span>
                                      <span className="sr-only">Previous</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  {[...Array(initialNumberOfPages)].map(
                                    (item, index) => {
                                      if (index + 1 === initialPageNumber) {
                                        return (
                                          <MDBPageItem
                                            key={index}
                                            active={isActive1(index + 1)}
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber - 1
                                      ) {
                                        return (
                                          <MDBPageItem
                                            key={index}
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber - 2
                                      ) {
                                        return (
                                          <MDBPageItem
                                            key={index}
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber - 3
                                      ) {
                                        return (
                                          <MDBPageItem
                                            key={index}
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber + 1
                                      ) {
                                        return (
                                          <MDBPageItem
                                            key={index}
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber + 2
                                      ) {
                                        return (
                                          <MDBPageItem
                                            key={index}
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else if (
                                        index + 1 ===
                                        initialPageNumber + 3
                                      ) {
                                        return (
                                          <MDBPageItem
                                            key={index}
                                            onClick={() =>
                                              currentPaginationEvaluationList(
                                                index
                                              )
                                            }
                                          >
                                            <MDBPageNav className="page-link">
                                              {index + 1}{" "}
                                              <span className="sr-only">
                                                (current)
                                              </span>
                                            </MDBPageNav>
                                          </MDBPageItem>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                                  <MDBPageItem
                                    onClick={nextPaginationEvaluationList}
                                  >
                                    <MDBPageNav className="page-link">
                                      &raquo;
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  <MDBPageItem
                                    onClick={lastPaginationEvaluationList}
                                  >
                                    <MDBPageNav className="page-link">
                                      Last
                                    </MDBPageNav>
                                  </MDBPageItem>
                                </MDBPagination>
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </Fragment>
                      )}
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
            </MDBTabContent>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

ExamList.propTypes = {};

const mapStateToProps = state => ({
  students: state.students,
  evaluations: state.evaluations,
  customizables: state.customizables
});

export default withRouter(
  connect(mapStateToProps, { getEvaluationList, getEvals })(ExamList)
);
