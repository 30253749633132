import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  MDBJumbotron,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBBtn
} from "mdbreact";

const Promotion = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Topscholar: Setting Up and Running your English Tutoring Business
        </title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 text-center">
        <MDBRow className="flex-center  mb-3">
          <div className="white-text pt-5">
            <img
              src={
                "https://res.cloudinary.com/unnamed/image/upload/v1579464269/Landing/Untitled_design_10_jhzdzs.png"
              }
              className="img-responsive"
              width="100%"
              height="auto"
              alt=""
            />
          </div>
        </MDBRow>
        <MDBRow>
          <MDBCol md="10" lg="10" xl="10" sm="12" className=" mx-auto">
            <MDBJumbotron className="">
              <MDBCardBody>
                <h3 className="quickSand notFoundFontColor mb-3">
                  Setting Up and Running your English Tutoring Business
                </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://drive.google.com/file/d/1TJ2vDtvS43AJkELJqvBJbi7oVpicaV1O/view?usp=sharing"
                  }
                >
                  <MDBBtn color="danger">Download Free Guide</MDBBtn>
                </a>
              </MDBCardBody>
            </MDBJumbotron>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default withRouter(Promotion);
