import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBFileInput,
  MDBSelect,
  MDBBtn,
  MDBIcon
} from "mdbreact";
// import LinkCard from "../LinkCard";
import { getGroups } from "../../actions/groups";
import {
  addTopicNL,
  updateTopic,
  deleteDocument,
  clearLessonReducer,
  setLoadingButtonTopics
} from "../../actions/lessons";
import ChipInput from "material-ui-chip-input";
import cloudinary from "cloudinary-core";
var cl = cloudinary.Cloudinary.new({ cloud_name: "unnamed" });

const CreateTopic = ({
  clearLessonReducer,
  lessonChosen,
  getGroups,
  addTopicNL,
  getTopics,
  setLoadingButtonTopics,
  groups: { groups, loading },
  history,
  individualTopic,
  updateTopic,
  deleteDocument,
  lessons: { documentDelete, updated, topicLesson, loadingButtonTopics },
  saveTopicBoolean,
  clearsaveTopicBoolean
}) => {
  const [formData, setFormData] = useState({
    levelOptions: [
      {
        text: "A1",
        value: "A1"
      },
      {
        text: "A2",
        value: "A2"
      },
      {
        text: "B1",
        value: "B1"
      },
      {
        text: "B2",
        value: "B2"
      },
      {
        text: "C1",
        value: "C1"
      },
      {
        text: "C2",
        value: "C2"
      },
      {
        text: "No level",
        value: "No level"
      }
    ],
    newTopic: {
      title: "",
      text: "",
      level: "",
      vocabulary: [],
      grammar: [],
      youtube: [],
      links: [],
      documents: "",
      documents2: "",
      documents3: "",
      topicId: ""
    },
    youtubeLink: "",
    youtubeTitle: "",
    customLink: "",
    customTitle: "",
    levelError: "",
    loading: false,
    text: ""
  });

  const [formData2, setFormData2] = useState({
    text: ""
  });

  useEffect(() => {
    if (individualTopic.title) {
      setFormData(prevState => {
        const newArray = prevState.levelOptions.map((item, index) => {
          if (item.value === individualTopic.level) {
            return {
              ...item,
              checked: true
            };
          } else {
            return item;
          }
        });
        return {
          ...prevState,
          newTopic: {
            title: individualTopic.title,
            text: individualTopic.text ? individualTopic.text : "",
            level: individualTopic.level ? individualTopic.level : "",
            vocabulary: individualTopic.vocabulary
              ? individualTopic.vocabulary
              : [],
            grammar: individualTopic.grammar ? individualTopic.grammar : [],
            youtube: individualTopic.youtube ? individualTopic.youtube : [],
            links: individualTopic.links ? individualTopic.links : [],
            documents: individualTopic.documents[0]
              ? individualTopic.documents[0]
              : [],
            topicId: individualTopic._id
          },
          levelOptions: newArray
        };
      });
    }
  }, [individualTopic]);

  useEffect(() => {
    if (individualTopic.title) {
      setFormData2(prevState => {
        return {
          ...prevState,
          text: individualTopic.text ? individualTopic.text : ""
        };
      });
      //   setFormData2({
      //     ...formData2,
      //     text: individualTopic.text ? individualTopic.text : ""
      //   });
      // }
    }
  }, [individualTopic]);

  const getTopicLevel = value => {
    const find = formData.levelOptions.find(item => item.checked === true);

    setFormData({
      ...formData,
      newTopic: {
        ...formData.newTopic,
        level: find ? find.value : ""
      }
    });
  };

  const handleChangeYoutube = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const addYoutubeLink = () => {
    if (formData.youtubeLink === "") {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>You must enter a Youtube Link</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      setFormData({
        ...formData,
        newTopic: {
          ...formData.newTopic,
          youtube: [
            ...formData.newTopic.youtube,
            {
              url: formData.youtubeLink,
              title: formData.youtubeTitle ? formData.youtubeTitle : "No Title"
            }
          ]
        },
        youtubeLink: "",
        youtubeTitle: ""
      });
    }
  };

  const deleteYoutubeLink = index2 => {
    setFormData({
      ...formData,
      newTopic: {
        ...formData.newTopic,
        youtube: formData.newTopic.youtube.filter(
          array => formData.newTopic.youtube.indexOf(array) !== index2
        )
      },
      youtubeLink: ""
    });
  };
  const handleChangeLink = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const addCustomLink = () => {
    if (formData.customLink === "") {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>You must enter a link</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      setFormData({
        ...formData,
        newTopic: {
          ...formData.newTopic,

          links: [
            ...formData.newTopic.links,
            {
              url: formData.customLink,
              title: formData.customTitle ? formData.customTitle : "No Title"
            }
          ]
        },
        customLink: "",
        customTitle: ""
      });
    }
  };

  const deleteCustomLink = index2 => {
    setFormData({
      ...formData,
      newTopic: {
        ...formData.newTopic,
        links: formData.newTopic.links.filter(
          array => formData.newTopic.links.indexOf(array) !== index2
        )
      },
      customLink: ""
    });
  };

  const onChange = e =>
    setFormData({
      ...formData,
      newTopic: {
        ...formData.newTopic,
        [e.target.name]: e.target.value
      }
    });

  const onChange2 = (content, delta, source, editor) => {
    setFormData2({
      ...formData2,
      text: content
    });
  };

  const handleAddChip = chip => {
    setFormData({
      ...formData,
      newTopic: {
        ...formData.newTopic,
        vocabulary: [...formData.newTopic.vocabulary, chip]
      }
    });
  };
  const handleDeleteChip = (chip, index) => {
    setFormData({
      ...formData,
      newTopic: {
        ...formData.newTopic,
        vocabulary: formData.newTopic.vocabulary
          .filter(vocab => vocab !== chip)
          .map((item, index) => {
            return item;
          })
      }
    });
  };
  const handleAddChip2 = chip => {
    setFormData({
      ...formData,
      newTopic: {
        ...formData.newTopic,
        grammar: [...formData.newTopic.grammar, chip]
      }
    });
  };
  const fileInputHandler = document => {
    console.log(document);
    if (document) {
      setFormData({
        ...formData,
        newTopic: {
          ...formData.newTopic,
          documents: document[0]
        }
      });
    }
  };
  const fileInputHandler2 = document => {
    if (document) {
      setFormData({
        ...formData,
        newTopic: {
          ...formData.newTopic,
          documents2: document[0]
        }
      });
    }
  };
  const fileInputHandler3 = document => {
    if (document) {
      setFormData({
        ...formData,
        newTopic: {
          ...formData.newTopic,
          documents3: document[0]
        }
      });
    }
  };
  const handleDeleteChip2 = (chip, index) => {
    setFormData({
      ...formData,
      newTopic: {
        ...formData.newTopic,
        grammar: formData.newTopic.grammar
          .filter(gram => gram !== chip)
          .map((item, index) => {
            return item;
          })
      }
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    await setLoadingButtonTopics(true);
    if (!formData.newTopic.title) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A title is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
      setLoadingButtonTopics(false);
    } else if (!formData.newTopic.level) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A level is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
      setLoadingButtonTopics(false);
    } else if (formData.newTopic.documents.size > 10000000) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>Your file exceeds 10Mb. Please select a smaller file</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
      setLoadingButtonTopics(false);
    } else if (individualTopic.title) {
      await setLoadingButtonTopics(true);
      updateTopic(individualTopic._id, {
        ...formData.newTopic,
        text: formData2.text
      });
    } else {
      await setLoadingButtonTopics(true);
      addTopicNL({
        ...formData.newTopic,
        text: formData2.text,
        lessonChosen: lessonChosen ? lessonChosen.value : ""
      });
    }
  };

  const handleKeyPress = event => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };
  var styles = {
    cursor: "pointer"
  };

  // useEffect(() => {
  //   if (topicLesson) {
  //     setFormData(prevState => {
  //       const newArray = prevState.levelOptions.map((item, index) => {
  //         return {
  //           ...item,
  //           checked: false
  //         };
  //       });
  //       return {
  //         ...prevState,
  //         levelOptions: newArray,
  //         newTopic: {
  //           title: "",
  //           text: "",
  //           level: "",
  //           vocabulary: [],
  //           grammar: [],
  //           youtube: [],
  //           links: [],
  //           documents: "",
  //           topicId: ""
  //         },
  //         youtubeLink: "",
  //         youtubeTitle: "",
  //         customLink: "",
  //         customTitle: "",

  //         text: ""
  //       };
  //     });

  //     setFormData2({
  //       text: ""
  //     });
  //   }
  // }, [topicLesson]);

  useEffect(() => {
    if (saveTopicBoolean === true) {
      const onSubmitTopic = () => {
        setLoadingButtonTopics(true);
        if (!formData.newTopic.title) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="times" />
                    <p>A title is required</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
          setLoadingButtonTopics(false);
        } else if (!formData.newTopic.level) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="times" />
                    <p>A level is required</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
          setLoadingButtonTopics(false);
        } else if (formData.newTopic.documents.size > 10000000) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="times" />
                    <p>Your file exceeds 10Mb. Please select a smaller file</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
          setLoadingButtonTopics(false);
        } else if (individualTopic.title) {
          setLoadingButtonTopics(true);
          updateTopic(individualTopic._id, {
            ...formData.newTopic,
            text: formData2.text
          });
        } else {
          setLoadingButtonTopics(true);
          addTopicNL({
            ...formData.newTopic,
            text: formData2.text,
            lessonChosen: lessonChosen ? lessonChosen.value : ""
          });
        }
      };
      clearsaveTopicBoolean();
      onSubmitTopic();
    }
  }, [saveTopicBoolean]);

  console.log(formData);

  return (
    <section>
      <MDBCard className="modalBottomMargin mx-3">
        <MDBCardBody>
          <p className="h4 pb-2 quickSand text-center">Topic information</p>

          <MDBRow>
            <MDBCol md="6" className="mb-2">
              <MDBInput
                name="title"
                onChange={e => onChange(e)}
                value={formData.newTopic.title}
                label="Topic Title"
                required
              />
            </MDBCol>
            <MDBCol md="6" className="mb-2">
              <MDBSelect
                options={formData.levelOptions}
                getValue={getTopicLevel}
                labelClass="black-text"
                label={"Choose level"}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow center>
            <MDBCol md="8" className="mb-4">
              <div>
                <ReactQuill
                  className="quill"
                  theme="bubble"
                  name="text"
                  onChange={onChange2}
                  value={formData2.text}
                  // defaultValue={formData.newTopic.text}
                  placeholder="Topic notes/explanation"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" }
                      ],

                      ["clean"],
                      [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" }
                      ]
                    ],

                    clipboard: {
                      matchVisual: false
                    }
                  }}
                />
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <MDBRow>
                <MDBCol md="6" className="mb-4">
                  <MDBIcon icon="tags" />
                  <ChipInput
                    label="+ Add vocabulary words"
                    value={formData.newTopic.vocabulary}
                    onAdd={chip => handleAddChip(chip)}
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    fullWidth={true}
                    fullWidthInput={true}
                  />
                </MDBCol>
                <MDBCol md="6" className="mb-4">
                  <MDBIcon icon="tags" />
                  <ChipInput
                    label="+ Add grammar topics"
                    value={formData.newTopic.grammar}
                    onAdd={chip => handleAddChip2(chip)}
                    onDelete={(chip, index) => handleDeleteChip2(chip, index)}
                    fullWidth={true}
                    fullWidthInput={true}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <p className="h4 pb-2 quickSand text-center">Resources</p>
          <p className="h6 pb-2 quickSand">Youtube Links</p>
          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <MDBInput
                onChange={e => handleChangeYoutube(e)}
                value={formData.youtubeLink}
                name="youtubeLink"
                label="Paste Youtube URL"
                background
                size="sm"
              />
              <MDBInput
                onChange={e => handleChangeYoutube(e)}
                value={formData.youtubeTitle}
                name="youtubeTitle"
                label="Add Title"
                background
                size="sm"
              />
              <MDBBtn onClick={addYoutubeLink} color="info" size="sm" outline>
                Add video
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6" className="mb-4">
              <MDBIcon fab icon="youtube" />

              <hr />
              {formData.newTopic.youtube.map((link, index) => {
                return (
                  <Fragment key={index}>
                    <MDBRow>
                      <MDBCol>
                        <MDBIcon
                          onClick={() => deleteYoutubeLink(index)}
                          icon="trash"
                          size="1x"
                          className="red-text"
                          style={styles}
                        />
                      </MDBCol>
                      <MDBCol>
                        <MDBRow>
                          <p className="mr-1">{`${index + 1}.`}</p>
                          <a
                            href={`${link.url}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {link.title}
                          </a>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </Fragment>
                );
              })}
              <hr />
            </MDBCol>
          </MDBRow>
          <p className="h6 pb-2 quickSand">Regular Website Links</p>
          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <MDBInput
                onChange={e => handleChangeLink(e)}
                value={formData.customLink}
                name="customLink"
                label="Paste Website URL"
                background
                size="sm"
              />
              <MDBInput
                onChange={e => handleChangeLink(e)}
                value={formData.customTitle}
                name="customTitle"
                label="Url Title"
                background
                size="sm"
              />
              <MDBBtn onClick={addCustomLink} color="info" size="sm" outline>
                Add Link
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6" className="mb-4">
              <MDBIcon icon="link" />
              <hr />
              {formData.newTopic.links.map((link, index) => {
                return (
                  <Fragment key={index}>
                    <MDBRow>
                      <MDBCol>
                        <MDBIcon
                          onClick={() => deleteCustomLink(index)}
                          icon="trash"
                          size="1x"
                          className="red-text"
                          style={styles}
                        />
                      </MDBCol>
                      <MDBCol>
                        <MDBRow>
                          <p className="mr-1">{`${index + 1}.`}</p>
                          <a
                            href={`${link.url}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {link.title}
                          </a>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </Fragment>
                );
              })}
              <hr />
            </MDBCol>
          </MDBRow>
          {individualTopic.documents[0] ? (
            <Fragment>
              <div classname="mb-3">
                <MDBRow>
                  <MDBCol md="6">
                    {individualTopic.documents[0].document_type === "raw" ? (
                      <a
                        href={`https://res.cloudinary.com/unnamed/${individualTopic.documents[0].document_type}/upload/${individualTopic.documents[0].document_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Download your saved file{" "}
                      </a>
                    ) : individualTopic.documents[0].document_type ===
                      "video" ? (
                      <a
                        href={`https://res.cloudinary.com/unnamed/${
                          individualTopic.documents[0].document_type
                        }/upload/${
                          individualTopic.documents[0].document_id
                        }${individualTopic.documents[0].document_id.slice(-4)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {individualTopic.documents[0].title
                          ? individualTopic.documents[0].title
                          : "Link to your saved video"}
                      </a>
                    ) : individualTopic.documents[0].document_id.slice(-3) ===
                      "pdf" ? (
                      <a
                        href={`https://res.cloudinary.com/unnamed/${individualTopic.documents[0].document_type}/upload/${individualTopic.documents[0].document_id}.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {individualTopic.documents[0].title
                          ? individualTopic.documents[0].title
                          : "Download your saved pdf"}
                      </a>
                    ) : (
                      <a
                        href={cl.url(individualTopic.documents[0].document_id)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {individualTopic.documents[0].title
                          ? individualTopic.documents[0].title
                          : "Link to your saved file"}
                      </a>
                    )}
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBBtn
                    color="danger"
                    size="sm"
                    onClick={() =>
                      deleteDocument({
                        topicdocument: individualTopic.documents[0].document_id,
                        topicId: individualTopic._id
                      })
                    }
                  >
                    Remove saved file
                  </MDBBtn>
                </MDBRow>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <MDBRow>
                <MDBCol md="6" className="mb-4">
                  <h6 className="mt-3">
                    Upload a document (Max file size: 10 Mb)
                  </h6>
                  <MDBFileInput
                    textFieldTitle=""
                    btnColor="outline-info"
                    btn-size="sm"
                    getValue={fileInputHandler}
                  />
                </MDBCol>
              </MDBRow>
            </Fragment>
          )}
          {individualTopic.documents[1] ? (
            <Fragment>
              <div classname="mb-3">
                <MDBRow>
                  <MDBCol md="6">
                    {individualTopic.documents[1].document_type === "raw" ? (
                      <a
                        href={`https://res.cloudinary.com/unnamed/${individualTopic.documents[1].document_type}/upload/${individualTopic.documents[1].document_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Download your saved file{" "}
                      </a>
                    ) : individualTopic.documents[1].document_type ===
                      "video" ? (
                      <a
                        href={`https://res.cloudinary.com/unnamed/${
                          individualTopic.documents[1].document_type
                        }/upload/${
                          individualTopic.documents[1].document_id
                        }${individualTopic.documents[1].document_id.slice(-4)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {individualTopic.documents[1].title
                          ? individualTopic.documents[1].title
                          : "Link to your saved video"}
                      </a>
                    ) : individualTopic.documents[1].document_id.slice(-3) ===
                      "pdf" ? (
                      <a
                        href={`https://res.cloudinary.com/unnamed/${individualTopic.documents[1].document_type}/upload/${individualTopic.documents[1].document_id}.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {individualTopic.documents[1].title
                          ? individualTopic.documents[1].title
                          : "Download your saved pdf"}
                      </a>
                    ) : (
                      <a
                        href={cl.url(individualTopic.documents[1].document_id)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {individualTopic.documents[1].title
                          ? individualTopic.documents[1].title
                          : "Link to your saved file"}
                      </a>
                    )}
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBBtn
                    color="danger"
                    size="sm"
                    onClick={() =>
                      deleteDocument({
                        topicdocument: individualTopic.documents[1].document_id,
                        topicId: individualTopic._id
                      })
                    }
                  >
                    Remove saved file
                  </MDBBtn>
                </MDBRow>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <MDBRow className="mt-3">
                <MDBCol md="6" className="mb-4">
                  <h6>Upload a document (Max file size: 10 Mb)</h6>
                  <MDBFileInput
                    textFieldTitle=""
                    btnColor="outline-info"
                    btn-size="sm"
                    getValue={fileInputHandler2}
                  />
                </MDBCol>
              </MDBRow>
            </Fragment>
          )}
          {individualTopic.documents[2] ? (
            <Fragment>
              <div classname="mb-3">
                <MDBRow>
                  <MDBCol md="6">
                    {individualTopic.documents[2].document_type === "raw" ? (
                      <a
                        href={`https://res.cloudinary.com/unnamed/${individualTopic.documents[2].document_type}/upload/${individualTopic.documents[2].document_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Download your saved file{" "}
                      </a>
                    ) : individualTopic.documents[2].document_type ===
                      "video" ? (
                      <a
                        href={`https://res.cloudinary.com/unnamed/${
                          individualTopic.documents[2].document_type
                        }/upload/${
                          individualTopic.documents[2].document_id
                        }${individualTopic.documents[2].document_id.slice(-4)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {individualTopic.documents[2].title
                          ? individualTopic.documents[2].title
                          : "Link to your saved video"}
                      </a>
                    ) : individualTopic.documents[2].document_id.slice(-3) ===
                      "pdf" ? (
                      <a
                        href={`https://res.cloudinary.com/unnamed/${individualTopic.documents[2].document_type}/upload/${individualTopic.documents[2].document_id}.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {individualTopic.documents[2].title
                          ? individualTopic.documents[2].title
                          : "Download your saved pdf"}
                      </a>
                    ) : (
                      <a
                        href={cl.url(individualTopic.documents[2].document_id)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {individualTopic.documents[2].title
                          ? individualTopic.documents[2].title
                          : "Link to your saved file"}
                      </a>
                    )}
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBBtn
                    color="danger"
                    size="sm"
                    onClick={() =>
                      deleteDocument({
                        topicdocument: individualTopic.documents[2].document_id,
                        topicId: individualTopic._id
                      })
                    }
                  >
                    Remove saved file
                  </MDBBtn>
                </MDBRow>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <MDBRow className="mt-3">
                <MDBCol md="6" className="mb-4">
                  <h6>Upload a document (Max file size: 10 Mb)</h6>
                  <MDBFileInput
                    textFieldTitle=""
                    btnColor="outline-info"
                    btn-size="sm"
                    getValue={fileInputHandler3}
                  />
                </MDBCol>
              </MDBRow>
            </Fragment>
          )}
        </MDBCardBody>
      </MDBCard>
    </section>
  );
};

const mapStateToProps = state => ({
  groups: state.groups,
  lessons: state.lessons
});

export default connect(mapStateToProps, {
  getGroups,
  addTopicNL,
  updateTopic,
  deleteDocument,
  clearLessonReducer,
  setLoadingButtonTopics
})(withRouter(CreateTopic));
