import {
  ADD_GROUP,
  GROUP_ERROR,
  GET_GROUPS,
  GET_GROUPS_SELECT,
  GET_GROUP,
  UPDATE_GROUP,
  GROUPS_DELETE,
  SEARCH_GROUPS,
  SET_PAGINATION_MODE_GROUPS,
  SET_LOADING_BUTTON_GROUPS,
  CLEAR_GROUPS_SEARCH,
  STUDENTS_DELETE,
  SET_LOADING_BUTTON_DELETE_GROUP,
  LOGOUT
} from "../actions/types";

const initialState = {
  groups: [],
  groupsSelect: [],
  group: null,
  initialNumberOfPagesGroups: 1,
  initialPageNumberGroups: 1,
  searchGroups: [],
  paginationModeGroups: "date",
  keywordsGroups: "",
  numberOfPagesGroups: 1,
  pageNumberGroups: 1,
  loading: true,
  loadingButtonGroups: false,
  groupLoading: true,
  error: {},
  loadingGroupDelete: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_GROUPS_SEARCH:
      return {
        ...state,
        searchGroups: "",
        keywordsGroups: "",
        numberOfPagesGroups: "",
        pageNumberGroups: "",
        paginationModeGroups: "date"
      };
    case ADD_GROUP:
      return {
        ...state,
        group: payload,
        loading: false,
        loadingButtonGroups: false
      };
    case GROUP_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonGroups: false,
        loadingGroupDelete: false
      };
    case GET_GROUPS:
      return {
        ...state,
        groups: payload.groups,
        initialNumberOfPagesGroups: payload.initialNumberOfPagesGroups,
        initialPageNumberGroups: payload.initialPageNumberGroups,
        loading: false,
        groupLoading: false
      };
    case GET_GROUPS_SELECT:
      return {
        ...state,
        groupsSelect: payload,
        loading: false
      };

    case UPDATE_GROUP:
    case GET_GROUP:
      return {
        ...state,
        group: payload,
        loading: false,
        loadingButtonGroups: false
      };
    case GROUPS_DELETE:
      return {
        ...state,
        groups: state.groups.filter(groups => groups._id !== payload),
        loading: false,
        group: payload,
        loadingGroupDelete: false
      };
    case SEARCH_GROUPS:
      return {
        ...state,
        searchGroups: payload.searchGroups,
        keywordsGroups: payload.keywordsGroups,
        pageNumberGroups: payload.pageNumberGroups,
        numberOfPagesGroups: payload.numberOfPagesGroups,
        loading: false
      };
    case SET_PAGINATION_MODE_GROUPS:
      return {
        ...state,
        paginationModeGroups: payload
      };
    case SET_LOADING_BUTTON_GROUPS:
      return {
        ...state,
        loadingButtonGroups: payload
      };
    case SET_LOADING_BUTTON_DELETE_GROUP:
      return {
        ...state,
        loadingGroupDelete: payload
      };
    case STUDENTS_DELETE:
      const mapGroups = state.groups.map((item, index) => {
        const mapStudents = item.students.filter(
          students => students._id !== payload
        );
        return {
          ...item,
          students: mapStudents
        };
      });
      return {
        ...state,
        groups: mapGroups
      };
    case LOGOUT:
      return {
        groups: [],
        groupsSelect: [],
        group: null,
        initialNumberOfPagesGroups: 1,
        initialPageNumberGroups: 1,
        searchGroups: [],
        paginationModeGroups: "date",
        keywordsGroups: "",
        numberOfPagesGroups: 1,
        pageNumberGroups: 1,
        loading: true,
        loadingButtonGroups: false,
        groupLoading: true,
        error: {},
        loadingGroupDelete: false
      };
    default:
      return state;
  }
}
