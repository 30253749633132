import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { Helmet } from "react-helmet";

import Spinner from "../layout/Spinner";

import {
  getGroups,
  addGroup,
  updateGroup,
  deleteGroup,
  searchGroupsFunction,
  clearGroupsSearchReducer,
  setPaginationModeGroups,
  setLoadingButtonGroups,
  setLoadingDeleteGroup
} from "../../actions/groups";
import {
  getStudents,
  getStudentsSelect,
  addStudent,
  searchStudentsFunction,
  clearStudentsSearchReducer,
  setPaginationModeStudents,
  setLoadingButtonStudents
} from "../../actions/students";
import unionBy from "lodash/unionBy";
import property from "lodash/property";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBDataTable,
  MDBPageItem,
  MDBPageNav,
  MDBPagination
} from "mdbreact";

import "./Group.css";

const GroupList = ({
  getGroups,
  setLoadingButtonGroups,
  groups: {
    groups,
    group,
    loading,
    initialNumberOfPagesGroups,
    initialPageNumberGroups,
    numberOfPagesGroups,
    pageNumberGroups,
    searchGroups,
    paginationModeGroups,
    loadingButtonGroups,
    groupLoading,
    loadingGroupDelete
  },
  students: {
    students,
    studentsSelect,
    student,
    initialNumberOfPagesStudents,
    initialPageNumberStudents,
    numberOfPagesStudents,
    pageNumberStudents,
    paginationModeStudents,
    searchStudents,
    loadingButtonStudents,
    loadingStudents
  },
  auth: { user },
  addGroup,
  addStudent,
  getStudents,
  getStudentsSelect,
  updateGroup,
  deleteGroup,
  history,
  location,
  searchGroupsFunction,
  clearGroupsSearchReducer,
  setPaginationModeGroups,
  searchStudentsFunction,
  clearStudentsSearchReducer,
  setPaginationModeStudents,
  setLoadingButtonStudents,
  setLoadingDeleteGroup
}) => {
  const [formData, setFormData] = useState({
    activeItemOuterTabs: "1",
    groups: [],
    modalCreateClass: false,
    modalCreateStudent: false,
    modalShowClass: false,
    confirmationModal: false,

    studentOptions: [],
    newGroup: {
      name: "",
      students: []
    },
    newStudent: {
      name: "",
      email: "",
      phoneNumber: ""
    },
    individualClass: {
      students: [],
      name: "",
      _id: ""
    },
    groupError: "",
    studentData: {
      columns: [
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 150
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 150
        },
        {
          label: "Class",
          field: "class",
          sort: "asc",
          width: 150
        }
      ],
      rows: []
    },
    classError: "",
    studentError: "",
    searchGroups: [],
    searchStudents: [],
    searchvalueGroups: "",
    searchvalueStudents: "",
    pageNumberGroups: 1,
    pageNumberStudents: 1,
    bottomShow: false
  });

  const props2 = useSpring({
    opacity:
      formData.bottomShow === false
        ? 0
        : 1 || formData.bottomShow === true
        ? 1
        : 0,

    config: { duration: 600 }
  });

  const bottomShowFunc = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        bottomShow: !prevState.bottomShow
      };
    });
  };

  const onChange = e =>
    setFormData({
      ...formData,
      newGroup: {
        ...formData.newGroup,
        [e.target.name]: e.target.value
      }
    });
  const onChangeStudent = e =>
    setFormData({
      ...formData,
      newStudent: {
        ...formData.newStudent,
        [e.target.name]: e.target.value
      }
    });

  const toggleCreateClass = () => {
    setFormData({
      ...formData,
      modalCreateClass: !formData.modalCreateClass,
      newGroup: {
        name: "",
        students: []
      },

      classError: ""
    });
  };
  const toggleCreateStudent = () => {
    setFormData({
      ...formData,
      modalCreateStudent: !formData.modalCreateStudent,
      newStudent: {
        name: "",
        email: "",
        phoneNumber: ""
      },

      studentError: ""
    });
  };

  const toggleOuterTabs = tab => e => {
    if (formData.activeItemOuterTabs2 !== tab) {
      setFormData({
        ...formData,
        activeItemOuterTabs: tab
      });
    }
  };

  useEffect(() => {
    getGroups({ initialPageNumberGroups: "first" });
    setFormData(prevState => {
      return {
        ...prevState,
        modalCreateClass: false,
        modalCreateStudent: false,
        modalShowClass: false,
        confirmationModal: false,
        newGroup: {
          name: ""
        },
        studentOptions: [],
        individualClass: {
          students: [],
          name: "",
          _id: ""
        }
      };
    });
  }, [getGroups, group]);

  const handleSelectChangeStudents = value => {
    setFormData({
      ...formData,
      newGroup: {
        ...formData.newGroup,
        students: value
      }
    });
  };

  useEffect(() => {
    if (formData.modalCreateClass === true) {
      getStudentsSelect();
    }
  }, [formData.modalCreateClass, getStudentsSelect]);

  useEffect(() => {
    if (formData.modalShowClass === true) {
      getStudentsSelect();
    }
  }, [formData.modalShowClass, getStudentsSelect]);

  useEffect(() => {
    if (studentsSelect) {
      const newArray = studentsSelect.map((item, index) => {
        return {
          text: item.name,
          value: item._id
        };
      });

      setFormData(prevState => {
        return {
          ...prevState,
          studentOptions: unionBy(
            prevState.studentOptions,
            newArray,
            property("value")
          )
        };
      });
    }
  }, [studentsSelect]);

  const toggleShowClass = item => {
    setFormData({
      ...formData,
      modalShowClass: !formData.modalShowClass,
      individualClass: item
    });
  };
  const toggleShowClassClose = () => {
    const newArray = formData.studentOptions.map((item, index) => {
      return { ...item, checked: false };
    });
    setFormData({
      ...formData,
      modalShowClass: !formData.modalShowClass,
      individualClass: {
        students: [],
        name: ""
      },
      newGroup: {
        name: "",
        students: []
      },
      studentOptions: newArray
    });
  };
  const toggleConfirmation = () => {
    setFormData({
      ...formData,
      confirmationModal: true
    });
  };
  const toggleConfirmationClose = () => {
    setFormData({
      ...formData,
      confirmationModal: false
    });
  };

  useEffect(() => {
    if (formData.individualClass.name) {
      const bb = formData.individualClass.students.map((item, index) => {
        return {
          text: item.name,
          value: item._id,
          checked: true
        };
      });

      setFormData(prevState => {
        const newArray = unionBy(bb, prevState.studentOptions);
        return {
          ...prevState,
          newGroup: {
            ...prevState.newGroup,
            name: formData.individualClass.name,
            id: formData.individualClass._id
          },
          studentOptions: newArray
        };
      });
    }
  }, [
    formData.individualClass.name,
    formData.individualClass._id,
    formData.individualClass.students
  ]);

  const clickUpdate = () => {
    setLoadingButtonGroups(true);
    updateGroup(formData.newGroup);
  };

  const clickDelete = () => {
    setLoadingDeleteGroup(true);
    deleteGroup(formData.individualClass._id);
  };

  useEffect(() => {
    if (formData.activeItemOuterTabs === "2") {
      getStudents({ initialPageNumberStudents: "first" });

      if (location.state) {
        if (location.state.newStudent) {
          setFormData(prevState => {
            return {
              ...prevState,
              activeItemOuterTabsNew: location.state.activeItemOuterTabs,
              modalCreateStudent: true,
              newStudent: {
                ...prevState.newStudent,
                name: location.state.newStudent.name,
                email: location.state.newStudent.email
              }
            };
          });

          history.replace();
        }
      } else {
        setFormData(prevState => {
          return {
            ...prevState,
            newStudent: {
              name: "",
              email: "",
              phoneNumber: ""
            }
          };
        });
      }
    }
  }, [
    getStudents,
    formData.activeItemOuterTabs,
    student,
    history,
    location.state
  ]);

  useEffect(() => {
    const searchStudentsMap = () => {
      if (formData.searchStudents === "No Results") {
        return [];
      } else if (formData.searchStudents.length !== 0) {
        return formData.searchStudents;
      } else {
        return students;
      }
    };

    const clickStudentProfile = item => {
      history.push(`/students/${item._id}`);
    };
    if (students && formData.activeItemOuterTabs === "2") {
      if (students.length === 0) {
      } else {
        const newArray = searchStudentsMap().map((item, index) => {
          const classArray = item.groups.map((item2, index) => {
            return item2.name;
          });
          const emailExists = item.email ? item.email : "No email";
          const newArray2 =
            classArray.length === 0 ? "No class" : classArray.join(", ");
          return {
            name: item.name,
            email: emailExists,
            class: newArray2,
            clickEvent: () => {
              clickStudentProfile(item);
            }
          };
        });
        setFormData(prevState => {
          return {
            ...prevState,
            studentData: {
              ...prevState.studentData,
              rows: newArray
            }
          };
        });
      }
    }
  }, [
    getStudents,
    students,
    formData.activeItemOuterTabs,
    history,
    formData.searchStudents
  ]);

  const clickSubmitClass = async e => {
    e.preventDefault();
    await setLoadingButtonGroups(true);
    addGroup(formData.newGroup);
  };
  const clickSubmitStudent = async e => {
    e.preventDefault();
    await setLoadingButtonStudents(true);
    const object = {
      ...formData.newStudent,
      stripe_account: user.stripe_user_id
    };
    addStudent(object);
  };

  useEffect(() => {
    if (location.state) {
      setFormData(prevState => {
        return {
          ...prevState,
          activeItemOuterTabs: location.state.activeItemOuterTabs
        };
      });
    }
  }, [location.state]);

  const styles = {
    cursor: "pointer"
  };

  // Search Groups

  const onKeyPressEnterGroups = e => {
    if (e.key === "Enter") {
      groupsSearchFunc();
    }
  };

  useEffect(() => {
    if (searchGroups) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchGroups: searchGroups
        };
      });
    }
  }, [searchGroups]);

  const onChangeGroups = e =>
    setFormData({
      ...formData,
      searchvalueGroups: e.target.value
    });

  const groupsSearchFunc = () => {
    searchGroupsFunction({
      keywordsGroups: formData.searchvalueGroups,
      pageNumberGroups: formData.pageNumberGroups
    });
    setPaginationModeGroups("search");
  };

  const clearGroupsFunc = () => {
    setFormData({
      ...formData,
      searchvalueGroups: "",
      searchGroups: []
    });
    clearGroupsSearchReducer();
    setPaginationModeGroups("date");
    return groups;
  };

  const searchGroupsMap = () => {
    if (formData.searchGroups === "No Results") {
      return [];
    } else if (formData.searchGroups.length !== 0) {
      return formData.searchGroups;
    } else {
      return groups;
    }
  };

  // Pagination Groups

  const isActive1 = page => {
    if (initialPageNumberGroups === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumberGroups === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationGroups = () => {
    if (paginationModeGroups === "date") {
      getGroups({ initialPageNumberGroups: "first" });
    } else if (paginationModeGroups === "search") {
      searchGroupsFunction({
        pageNumberGroups: "first",
        keywordsGroups: formData.searchvalueGroups
      });
    }
  };

  const previousPaginationGroups = () => {
    if (paginationModeGroups === "date") {
      getGroups({ initialPageNumberGroups: initialPageNumberGroups - 1 });
    } else if (paginationModeGroups === "search") {
      searchGroupsFunction({
        pageNumberGroups: pageNumberGroups - 1,
        keywordsGroups: formData.searchvalueGroups
      });
    }
  };

  const currentPaginationGroups = index => {
    if (paginationModeGroups === "date") {
      getGroups({ initialPageNumberGroups: index + 1 });
    } else if (paginationModeGroups === "search") {
      searchGroupsFunction({
        pageNumberGroups: index + 1,
        keywordsGroups: formData.searchvalueGroups
      });
    }
  };

  const nextPaginationGroups = () => {
    if (paginationModeGroups === "date") {
      getGroups({ initialPageNumberGroups: initialPageNumberGroups + 1 });
    } else if (paginationModeGroups === "search") {
      searchGroupsFunction({
        pageNumberGroups: pageNumberGroups + 1,
        keywordsGroups: formData.searchvalueGroups
      });
    }
  };

  const lastPaginationGroups = () => {
    if (paginationModeGroups === "date") {
      getGroups({ initialPageNumberGroups: "last" });
    } else if (paginationModeGroups === "search") {
      searchGroupsFunction({
        pageNumberGroups: "last",
        keywordsGroups: formData.searchvalueGroups
      });
    }
  };

  // Search Students

  useEffect(() => {
    if (searchStudents) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchStudents: searchStudents
        };
      });
    }
  }, [searchStudents]);

  const onKeyPressEnterStudents = e => {
    if (e.key === "Enter") {
      studentsSearchFunc();
    }
  };

  const onChangeStudents = e =>
    setFormData({
      ...formData,
      searchvalueStudents: e.target.value
    });

  const studentsSearchFunc = () => {
    searchStudentsFunction({
      keywordsStudents: formData.searchvalueStudents,
      pageNumberStudents: formData.pageNumberStudents
    });
    setPaginationModeStudents("search");
  };

  const clearStudentsFunc = () => {
    setFormData({
      ...formData,
      searchvalueStudents: "",
      searchStudents: []
    });
    clearStudentsSearchReducer();
    setPaginationModeStudents("date");
    return students;
  };

  // Pagination Students

  const isActive3 = page => {
    if (initialPageNumberStudents === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive4 = page => {
    if (pageNumberStudents === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationStudents = () => {
    if (paginationModeStudents === "date") {
      getStudents({ initialPageNumberStudents: "first" });
    } else if (paginationModeStudents === "search") {
      searchStudentsFunction({
        pageNumberStudents: "last",
        keywordsStudents: formData.searchvalueStudents
      });
    }
  };

  const previousPaginationStudents = () => {
    if (paginationModeStudents === "date") {
      getStudents({ initialPageNumberStudents: initialPageNumberStudents - 1 });
    } else if (paginationModeStudents === "search") {
      searchStudentsFunction({
        pageNumberStudents: pageNumberStudents - 1,
        keywordsStudents: formData.searchvalueStudents
      });
    }
  };

  const currentPaginationStudents = index => {
    if (paginationModeStudents === "date") {
      getStudents({ initialPageNumberStudents: index + 1 });
    } else if (paginationModeStudents === "search") {
      searchStudentsFunction({
        pageNumberStudents: index + 1,
        keywordsStudents: formData.searchvalueStudents
      });
    }
  };

  const nextPaginationStudents = () => {
    if (paginationModeStudents === "date") {
      getStudents({ initialPageNumberStudents: initialPageNumberStudents + 1 });
    } else if (paginationModeStudents === "search") {
      searchStudentsFunction({
        pageNumberStudents: pageNumberStudents + 1,
        keywordsStudents: formData.searchvalueStudents
      });
    }
  };

  const lastPaginationStudents = () => {
    if (paginationModeStudents === "date") {
      getStudents({ initialPageNumberStudents: "last" });
    } else if (paginationModeStudents === "search") {
      searchStudentsFunction({
        pageNumberStudents: "last",
        keywordsStudents: formData.searchvalueStudents
      });
    }
  };

  // Remove double scroll bar for welcome modal
  useEffect(() => {
    if (formData.modalShowClass === true) {
      document.body.style.cssText = "overflow-y: hidden !important;";
    } else {
      document.body.style.overflowY = "";
    }
  }, [formData.modalShowClass]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Classes and Students</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="group">
        <MDBContainer className="mt-5 mb-5">
          <MDBRow className="pt-5 mt-3">
            <MDBCol md="12">
              <MDBNav tabs className="nav-justified cardBackgroundGroups">
                <MDBNavItem>
                  <MDBNavLink
                    to="#"
                    className={
                      formData.activeItemOuterTabs === "1"
                        ? "active mx-2 montserratFont"
                        : "montserratFont"
                    }
                    onClick={toggleOuterTabs("1")}
                    role="tab"
                  >
                    <MDBIcon icon="school" className="mr-1" /> Classes
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    to="#"
                    className={
                      formData.activeItemOuterTabs === "2"
                        ? "active mx-2 montserratFont"
                        : "montserratFont"
                    }
                    onClick={toggleOuterTabs("2")}
                    role="tab"
                  >
                    <MDBIcon icon="user-graduate" className="mr-1" /> Students
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNav>
            </MDBCol>
          </MDBRow>
          <MDBRow center>
            <MDBCol md="12">
              <MDBTabContent
                className="card mb-3 bgb"
                activeItem={formData.activeItemOuterTabs}
              >
                <MDBTabPane tabId="1" role="tabpanel">
                  <MDBRow center>
                    <MDBCol md="7" className="text-center">
                      <MDBBtn
                        onClick={toggleCreateClass}
                        color="buttoncolor"
                        className="px-2 z-depth-2 buttoncolor"
                      >
                        <i className="fas fa-plus mt-0" /> {" Add a New Class"}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className=" middleVertical5 mt-3" center>
                    <MDBCol className="col-2" sm="2"></MDBCol>
                    <MDBCol sm="4" className="col-8">
                      <MDBInput
                        className="inputColorGroups blue-text"
                        label="Search your groups"
                        labelClass="white-text"
                        type="text"
                        containerClass="active-pink active-pink-2 mt-0 mb-3"
                        value={formData.searchvalueGroups}
                        onChange={onChangeGroups}
                        onKeyPress={onKeyPressEnterGroups}
                      />
                    </MDBCol>
                    <MDBCol className="col-2" sm="2">
                      <MDBRow className="align-items-center">
                        <div
                          className={
                            formData.searchvalueGroups.length !== 0
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="search"
                            onClick={groupsSearchFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className={
                            formData.searchGroups.length !== 0 ||
                            formData.searchGroups === "No Results"
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="times-circle"
                            onClick={clearGroupsFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <div
                    className={
                      formData.searchGroups === "No Results" &&
                      groups.length !== 0
                        ? "mt-2"
                        : "d-none"
                    }
                  >
                    <p className="quickSand h4 black-text text-center">
                      There are no group results for your search. Try Again.
                    </p>
                  </div>
                  {(formData.searchGroups.length === 0 || "No Results") &&
                  groups.length === 0 &&
                  groupLoading === false ? (
                    <div className="cardWidth text-center">
                      <img
                        className="imageFlex"
                        src={
                          "https://res.cloudinary.com/unnamed/image/upload/v1578418862/No_classes_found_ytpeuy.png"
                        }
                        alt=""
                      />
                    </div>
                  ) : (
                    <div style={{ minHeight: "42rem" }}>
                      <MDBRow center className="mt-4">
                        {searchGroupsMap().map((item, index) => {
                          return (
                            <MDBCol
                              key={index}
                              md="3"
                              sm="4"
                              className="col-6 mt-3 mx-sm-7 mx-md-0"
                            >
                              <div
                                style={{
                                  cursor: "pointer"
                                }}
                                onClick={() => toggleShowClass(item)}
                              >
                                <MDBCard
                                  style={{
                                    minHeight: "19rem"
                                  }}
                                  className="cardBackgroundGroups z-depth-3 my-auto groupListHover"
                                >
                                  <MDBCardBody>
                                    <div
                                      style={{
                                        minHeight: "15rem"
                                      }}
                                    >
                                      <p className="quickSand h4-responsive text-center text-white  my-auto">
                                        {item.name.charAt(0).toUpperCase() +
                                          item.name.slice(1)}
                                      </p>
                                      <hr />
                                      {item.students
                                        .slice(0, 3)
                                        .map((item2, index) => {
                                          return (
                                            <p
                                              key={item2._id}
                                              className="quickSand text-center "
                                            >
                                              {item2.name}
                                            </p>
                                          );
                                        })}
                                      {item.students.length > 3 ? (
                                        <p className="text-center">...</p>
                                      ) : null}
                                    </div>
                                    <p className="text-center">{`Total Students: ${item.students.length}`}</p>
                                  </MDBCardBody>
                                </MDBCard>
                              </div>
                            </MDBCol>
                          );
                        })}
                      </MDBRow>
                    </div>
                  )}

                  {/* Pagination Groups Medium Size*/}

                  <div
                    className={
                      formData.activeItemOuterTabs === "1"
                        ? "middleVertical mt-5 mb-1 paginationGroupsMedium"
                        : "d-none"
                    }
                  >
                    <MDBRow>
                      <MDBCol>
                        <MDBPagination circle>
                          <MDBPageItem onClick={firstPaginationGroups}>
                            <MDBPageNav className="page-link">
                              <span>First</span>
                            </MDBPageNav>
                          </MDBPageItem>
                          <MDBPageItem onClick={previousPaginationGroups}>
                            <MDBPageNav
                              className="page-link"
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span className="sr-only">Previous</span>
                            </MDBPageNav>
                          </MDBPageItem>
                          {paginationModeGroups === "date"
                            ? [...Array(initialNumberOfPagesGroups)].map(
                                (item, index) => {
                                  if (index + 1 === initialPageNumberGroups) {
                                    return (
                                      <MDBPageItem
                                        key={index}
                                        active={isActive1(index + 1)}
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups - 1
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups - 2
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups - 3
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups + 1
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups + 2
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups + 3
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else {
                                    return null;
                                  }
                                }
                              )
                            : [...Array(numberOfPagesGroups)].map(
                                (item, index) => {
                                  if (index + 1 === pageNumberGroups) {
                                    return (
                                      <MDBPageItem
                                        key={index}
                                        active={isActive2(index + 1)}
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups - 1
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups - 2
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups - 3
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups + 1
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups + 2
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups + 3
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else {
                                    return null;
                                  }
                                }
                              )}
                          <MDBPageItem onClick={nextPaginationGroups}>
                            <MDBPageNav className="page-link">
                              &raquo;
                            </MDBPageNav>
                          </MDBPageItem>
                          <MDBPageItem onClick={lastPaginationGroups}>
                            <MDBPageNav className="page-link">Last</MDBPageNav>
                          </MDBPageItem>
                        </MDBPagination>
                      </MDBCol>
                    </MDBRow>
                  </div>

                  {/* Pagination Groups Small Size*/}

                  <div
                    className={
                      formData.activeItemOuterTabs === "1"
                        ? "middleVertical mt-5 mb-1 paginationGroupsSmall"
                        : "d-none"
                    }
                  >
                    <MDBRow>
                      <MDBCol>
                        <MDBPagination circle size="sm">
                          <MDBPageItem onClick={firstPaginationGroups}>
                            <MDBPageNav className="page-link">
                              <span>First</span>
                            </MDBPageNav>
                          </MDBPageItem>
                          <MDBPageItem onClick={previousPaginationGroups}>
                            <MDBPageNav
                              className="page-link"
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span className="sr-only">Previous</span>
                            </MDBPageNav>
                          </MDBPageItem>
                          {paginationModeGroups === "date"
                            ? [...Array(initialNumberOfPagesGroups)].map(
                                (item, index) => {
                                  if (index + 1 === initialPageNumberGroups) {
                                    return (
                                      <MDBPageItem
                                        key={index}
                                        active={isActive1(index + 1)}
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups - 1
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups - 2
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups - 3
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups + 1
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups + 2
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    initialPageNumberGroups + 3
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else {
                                    return null;
                                  }
                                }
                              )
                            : [...Array(numberOfPagesGroups)].map(
                                (item, index) => {
                                  if (index + 1 === pageNumberGroups) {
                                    return (
                                      <MDBPageItem
                                        key={index}
                                        active={isActive2(index + 1)}
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups - 1
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups - 2
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups - 3
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups + 1
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups + 2
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else if (
                                    index + 1 ===
                                    pageNumberGroups + 3
                                  ) {
                                    return (
                                      <MDBPageItem
                                        onClick={() =>
                                          currentPaginationGroups(index)
                                        }
                                      >
                                        <MDBPageNav className="page-link">
                                          {index + 1}{" "}
                                          <span className="sr-only">
                                            (current)
                                          </span>
                                        </MDBPageNav>
                                      </MDBPageItem>
                                    );
                                  } else {
                                    return null;
                                  }
                                }
                              )}
                          <MDBPageItem onClick={nextPaginationGroups}>
                            <MDBPageNav className="page-link">
                              &raquo;
                            </MDBPageNav>
                          </MDBPageItem>
                          <MDBPageItem onClick={lastPaginationGroups}>
                            <MDBPageNav className="page-link">Last</MDBPageNav>
                          </MDBPageItem>
                        </MDBPagination>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </MDBTabPane>

                <MDBTabPane tabId="2" role="tabpanel">
                  {/* <p>hello</p> */}
                  <MDBRow center>
                    <MDBCol md="12" className="text-center">
                      <MDBBtn
                        onClick={toggleCreateStudent}
                        color="buttoncolor"
                        className="px-2 z-depth-2 buttoncolor"
                      >
                        <i className="fas fa-plus mt-0" />{" "}
                        {" Add a New Student"}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBCard className="mt-3">
                        <MDBRow className=" middleVertical5 mt-3" center>
                          <MDBCol className="col-2" sm="2"></MDBCol>
                          <MDBCol sm="4" className="col-8">
                            <MDBInput
                              className="inputColorGroups blue-text"
                              label="Search your students"
                              labelClass="blue-text"
                              type="text"
                              containerClass="active-pink active-pink-2 mt-0 mb-3"
                              value={formData.searchvalueStudents}
                              onChange={onChangeStudents}
                              onKeyPress={onKeyPressEnterStudents}
                            />
                          </MDBCol>
                          <MDBCol className="col-2" sm="2">
                            <MDBRow className="align-items-center">
                              <div
                                className={
                                  formData.searchvalueStudents.length !== 0
                                    ? "mx-1"
                                    : "d-none"
                                }
                              >
                                <MDBIcon
                                  icon="search"
                                  onClick={studentsSearchFunc}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div
                                className={
                                  formData.searchStudents.length !== 0 ||
                                  formData.searchStudents === "No Results"
                                    ? "mx-1"
                                    : "d-none"
                                }
                              >
                                <MDBIcon
                                  icon="times-circle"
                                  onClick={clearStudentsFunc}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                        <MDBCardBody>
                          <MDBRow>
                            <MDBCol>
                              {(formData.searchStudents.length === 0 ||
                                "No Results") &&
                              students.length === 0 &&
                              loadingStudents === false ? (
                                <div className="cardWidth text-center">
                                  <img
                                    className="imageFlex"
                                    src={
                                      "https://res.cloudinary.com/unnamed/image/upload/v1578435043/No_Students_Found_eb3uxs.png"
                                    }
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <MDBRow>
                                  <div style={{ minHeight: "40rem" }}>
                                    <MDBDataTable
                                      paging={false}
                                      searching={false}
                                      sortable={false}
                                      btn
                                      small
                                      fixed
                                      className="text-center quickSand tableFont2 black-text"
                                      info={false}
                                      hover
                                      data={formData.studentData}
                                      bordered
                                      theadColor="blue lighten-4"
                                      style={styles}
                                    />
                                  </div>
                                </MDBRow>
                              )}
                            </MDBCol>
                          </MDBRow>

                          {/* Pagination Students Medium Size*/}

                          <div
                            className={
                              formData.activeItemOuterTabs === "2"
                                ? "middleVertical mt-5 mb-1 paginationGroupsMedium"
                                : "d-none"
                            }
                          >
                            <MDBRow>
                              <MDBCol>
                                <MDBPagination circle>
                                  <MDBPageItem
                                    onClick={firstPaginationStudents}
                                  >
                                    <MDBPageNav className="page-link">
                                      <span>First</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  <MDBPageItem
                                    onClick={previousPaginationStudents}
                                  >
                                    <MDBPageNav
                                      className="page-link"
                                      aria-label="Previous"
                                    >
                                      <span aria-hidden="true">&laquo;</span>
                                      <span className="sr-only">Previous</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  {paginationModeStudents === "date"
                                    ? [
                                        ...Array(initialNumberOfPagesStudents)
                                      ].map((item, index) => {
                                        if (
                                          index + 1 ===
                                          initialPageNumberStudents
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              active={isActive3(index + 1)}
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents - 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents - 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents - 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents + 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents + 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents + 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })
                                    : [...Array(numberOfPagesStudents)].map(
                                        (item, index) => {
                                          if (
                                            index + 1 ===
                                            pageNumberStudents
                                          ) {
                                            return (
                                              <MDBPageItem
                                                key={index}
                                                active={isActive4(index + 1)}
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents - 1
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents - 2
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents - 3
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents + 1
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents + 2
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents + 3
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else {
                                            return null;
                                          }
                                        }
                                      )}
                                  <MDBPageItem onClick={nextPaginationStudents}>
                                    <MDBPageNav className="page-link">
                                      &raquo;
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  <MDBPageItem onClick={lastPaginationStudents}>
                                    <MDBPageNav className="page-link">
                                      Last
                                    </MDBPageNav>
                                  </MDBPageItem>
                                </MDBPagination>
                              </MDBCol>
                            </MDBRow>
                          </div>

                          {/* Pagination Students Small Size*/}

                          <div
                            className={
                              formData.activeItemOuterTabs === "2"
                                ? "middleVertical mt-5 mb-1 paginationGroupsSmall"
                                : "d-none"
                            }
                          >
                            <MDBRow>
                              <MDBCol>
                                <MDBPagination circle size="sm">
                                  <MDBPageItem
                                    onClick={firstPaginationStudents}
                                  >
                                    <MDBPageNav className="page-link">
                                      <span>First</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  <MDBPageItem
                                    onClick={previousPaginationStudents}
                                  >
                                    <MDBPageNav
                                      className="page-link"
                                      aria-label="Previous"
                                    >
                                      <span aria-hidden="true">&laquo;</span>
                                      <span className="sr-only">Previous</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  {paginationModeStudents === "date"
                                    ? [
                                        ...Array(initialNumberOfPagesStudents)
                                      ].map((item, index) => {
                                        if (
                                          index + 1 ===
                                          initialPageNumberStudents
                                        ) {
                                          return (
                                            <MDBPageItem
                                              key={index}
                                              active={isActive3(index + 1)}
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents - 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents - 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents - 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents + 1
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents + 2
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else if (
                                          index + 1 ===
                                          initialPageNumberStudents + 3
                                        ) {
                                          return (
                                            <MDBPageItem
                                              onClick={() =>
                                                currentPaginationStudents(index)
                                              }
                                            >
                                              <MDBPageNav className="page-link">
                                                {index + 1}{" "}
                                                <span className="sr-only">
                                                  (current)
                                                </span>
                                              </MDBPageNav>
                                            </MDBPageItem>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })
                                    : [...Array(numberOfPagesStudents)].map(
                                        (item, index) => {
                                          if (
                                            index + 1 ===
                                            pageNumberStudents
                                          ) {
                                            return (
                                              <MDBPageItem
                                                key={index}
                                                active={isActive4(index + 1)}
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents - 1
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents - 2
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents - 3
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents + 1
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents + 2
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else if (
                                            index + 1 ===
                                            pageNumberStudents + 3
                                          ) {
                                            return (
                                              <MDBPageItem
                                                onClick={() =>
                                                  currentPaginationStudents(
                                                    index
                                                  )
                                                }
                                              >
                                                <MDBPageNav className="page-link">
                                                  {index + 1}{" "}
                                                  <span className="sr-only">
                                                    (current)
                                                  </span>
                                                </MDBPageNav>
                                              </MDBPageItem>
                                            );
                                          } else {
                                            return null;
                                          }
                                        }
                                      )}
                                  <MDBPageItem onClick={nextPaginationStudents}>
                                    <MDBPageNav className="page-link">
                                      &raquo;
                                    </MDBPageNav>
                                  </MDBPageItem>
                                  <MDBPageItem onClick={lastPaginationStudents}>
                                    <MDBPageNav className="page-link">
                                      Last
                                    </MDBPageNav>
                                  </MDBPageItem>
                                </MDBPagination>
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </MDBTabPane>
              </MDBTabContent>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalCreateClass}
            toggle={toggleCreateClass}
          >
            <MDBCol md="12">
              <MDBModalHeader
                toggle={toggleCreateClass}
                className="montserratFont black-text h4"
              >
                Create a New Class
              </MDBModalHeader>
            </MDBCol>
            <div className="post-form">
              <form className="form my-1" onSubmit={e => clickSubmitClass(e)}>
                <MDBModalBody className="text-center">
                  <MDBInput
                    type="text"
                    name="name"
                    value={formData.newGroup.name}
                    onChange={onChange}
                    label="Class Name"
                    size="lg"
                    labelClass="quickSand black-text"
                    className="quickSand"
                  />
                  <div className="dropDownStudents">
                    <MDBSelect
                      multiple
                      search
                      options={formData.studentOptions}
                      selected="Add Students"
                      selectAll
                      getValue={handleSelectChangeStudents}
                      className="quickSand"
                    />
                  </div>
                </MDBModalBody>
                <p className="text-danger text-center">
                  {" "}
                  {formData.classError}{" "}
                </p>
                <MDBModalFooter>
                  <MDBBtn
                    className="quickSand"
                    color="secondary"
                    onClick={toggleCreateClass}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn
                    type="submit"
                    value="Submit"
                    color="primary"
                    disabled={loadingButtonGroups}
                    className="quickSand"
                  >
                    {loadingButtonGroups === true && (
                      <div className="spinner-grow spinner-grow-sm" />
                    )}
                    Save changes
                  </MDBBtn>
                </MDBModalFooter>
              </form>
            </div>
          </MDBModal>
        </MDBContainer>
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalCreateStudent}
            toggle={toggleCreateStudent}
          >
            <MDBCol md="12">
              <MDBModalHeader
                className="montserratFont black-text h4"
                toggle={toggleCreateStudent}
              >
                Create a New Student
              </MDBModalHeader>
            </MDBCol>
            <div className="post-form">
              <form className="form my-1" onSubmit={e => clickSubmitStudent(e)}>
                <MDBModalBody className="text-center">
                  <MDBInput
                    type="text"
                    name="name"
                    value={formData.newStudent.name}
                    onChange={onChangeStudent}
                    label="Student Name"
                    size="lg"
                    required
                    labelClass="quickSand black-text"
                    className="quickSand"
                  />
                  <MDBInput
                    type="email"
                    name="email"
                    value={formData.newStudent.email}
                    onChange={onChangeStudent}
                    label="Student Email"
                    size="lg"
                    labelClass="quickSand black-text"
                    className="quickSand"
                  />
                  <MDBInput
                    type="text"
                    name="phoneNumber"
                    value={formData.newStudent.phoneNumber}
                    onChange={onChangeStudent}
                    label="Student Phone Number"
                    size="lg"
                    labelClass="quickSand black-text"
                    className="quickSand"
                  />
                </MDBModalBody>
                <p className="text-danger text-center">
                  {" "}
                  {formData.studentError}{" "}
                </p>
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={toggleCreateStudent}>
                    Close
                  </MDBBtn>
                  <MDBBtn
                    type="submit"
                    value="Submit"
                    color="primary"
                    disabled={loadingButtonStudents}
                  >
                    {loadingButtonStudents === true && (
                      <div className="spinner-grow spinner-grow-sm" />
                    )}
                    Save changes
                  </MDBBtn>
                </MDBModalFooter>
              </form>
            </div>
          </MDBModal>
        </MDBContainer>
        <MDBContainer>
          <MDBModal isOpen={formData.modalShowClass} size="lg">
            <MDBModalHeader toggle={toggleShowClassClose}>
              <MDBInput
                type="text"
                name="name"
                value={formData.newGroup.name}
                onChange={onChange}
                label="Class Name"
                size="lg"
              />
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="12" className="text-center">
                  <p className="h5 quickSand">Students in the Class: </p>

                  <MDBTable responsive className="mb-3 quickSand">
                    <MDBTableHead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {formData.individualClass.students.map((item, index) => {
                        const url = `/students/${item._id}`;
                        return (
                          <tr
                            style={{
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              // history.push(`/students/${item._id}`);
                              window.open(url);
                            }}
                            key={index}
                          >
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phoneNumber}</td>
                          </tr>
                        );
                      })}
                    </MDBTableBody>
                  </MDBTable>
                  <hr />
                  <p className="h6 mt-3 quickSand">
                    Add Students to this class:{" "}
                  </p>
                  <div className="mx-5 wrapperSelectStudent">
                    <MDBSelect
                      multiple
                      search
                      options={formData.studentOptions}
                      selected="Add Students"
                      selectAll
                      getValue={handleSelectChangeStudents}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="text-right">
                  <p className="text-danger text-right">
                    {" "}
                    {formData.groupError}{" "}
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBContainer>
                <MDBModal
                  isOpen={formData.confirmationModal}
                  toggle={toggleConfirmationClose}
                  size="sm"
                >
                  <MDBModalHeader toggle={toggleConfirmationClose}>
                    Confirm Delete
                  </MDBModalHeader>
                  <MDBModalBody>
                    Warning! Are you sure you want to delete
                    <strong> {formData.individualClass.name} </strong> ? Click
                    "Confirm Delete" to delete this class.
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      color="secondary"
                      size="sm"
                      onClick={toggleConfirmationClose}
                    >
                      Close
                    </MDBBtn>
                    <MDBBtn
                      disabled={loadingGroupDelete}
                      onClick={clickDelete}
                      color="danger"
                      size="sm"
                    >
                      {loadingGroupDelete === true && (
                        <div className="spinner-grow spinner-grow-sm" />
                      )}
                      Confirm Delete
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModal>
              </MDBContainer>
            </MDBModalBody>
            <MDBModalFooter className="d-flex mb-2 example-parent">
              <MDBBtn
                className="mr-auto p-2 col-example"
                color="danger"
                onClick={toggleConfirmation}
              >
                Delete Class
              </MDBBtn>
              <MDBBtn
                className="p-2 col-example"
                color="secondary"
                onClick={toggleShowClassClose}
              >
                Close
              </MDBBtn>
              <MDBBtn
                className="p-2 col-example"
                onClick={clickUpdate}
                color="primary"
                disabled={loadingButtonGroups}
              >
                {loadingButtonGroups === true && (
                  <div className="spinner-grow spinner-grow-sm" />
                )}
                Save changes
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
      {formData.bottomShow === false ? (
        <div className="removeUl hideBottom">
          <MDBBtn
            floating
            color="color-one"
            icon="info-circle"
            style={{ bottom: "24px", right: "24px" }}
            onClick={bottomShowFunc}
            className="btnFixed"
            size="sm"
          >
            <MDBIcon icon="info-circle"></MDBIcon>
          </MDBBtn>
        </div>
      ) : null}
      <animated.div style={props2}>
        {formData.bottomShow === true ? (
          <div className="float-wrap text-center z-depth-1-half">
            <MDBCard style={{ height: "16rem" }}>
              <MDBIcon
                onClick={bottomShowFunc}
                style={{ cursor: "pointer" }}
                size="2x"
                className="text-right mainColor bottomButton mr-1 mt-1"
                icon="window-close"
              />
              <MDBRow>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont ">
                      Creating Classes and Students
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Creating Classes and Students"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/nA1X77yxAwY"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont ">
                      Student Profile
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Student Profile"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/Drq3hf8dDwM"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont ">
                      Student Evaluations
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Student Evaluations"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/SMidk7r_f54"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        ) : null}
      </animated.div>
    </Fragment>
  );
};

GroupList.propTypes = {
  getGroups: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  getStudents: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  addStudent: PropTypes.func.isRequired,
  groups: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  groups: state.groups,
  students: state.students,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    getGroups,
    addGroup,
    getStudents,
    updateGroup,
    deleteGroup,
    addStudent,
    searchGroupsFunction,
    clearGroupsSearchReducer,
    setPaginationModeGroups,
    searchStudentsFunction,
    clearStudentsSearchReducer,
    setPaginationModeStudents,
    getStudentsSelect,
    setLoadingButtonGroups,
    setLoadingButtonStudents,
    setLoadingDeleteGroup
  })(GroupList)
);
