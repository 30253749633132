import React, { Fragment } from "react";

import { connect } from "react-redux";
import "./trading.css";
import "react-toastify/dist/ReactToastify.css";

import { MDBCard, MDBBtn, MDBRow, MDBCol, MDBAvatar, MDBIcon } from "mdbreact";

const TradingLeft = ({
  history,
  mode,
  switchMode,
  bottomShowFunc,
  getPublishedLessonsFunc,
  getPublishedTopicsFunc,
  getPublishedExamsFunc,
  getPublishedGamesFunc,
  getPublishedDecksFunc,
  getPublishedCurriculumsFunc,
  auth: { user }
}) => {
  return (
    <Fragment>
      <MDBCard
        style={{ height: "8rem" }}
        className="mx-1 my-1 tradingBackgroundColor middleVertical profileCard"
        onClick={() => switchMode("privateProfile")}
      >
        <MDBRow style={{ cursor: "pointer" }} className="middleVertical">
          <MDBCol className="text-center">
            <MDBAvatar
              tag="img"
              src={
                user.avatar.url
                  ? user.avatar.url
                  : "https://res.cloudinary.com/unnamed/image/upload/e_sharpen:100/v1579567771/Avatar%20default/Topscholar_Default_Avatar_h2ubdg.png"
              }
              className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
              alt="Avatar"
            />
          </MDBCol>
          <MDBCol className="mr-auto text-center">
            <p className="quickSand h4 text-white">{user.screenName}</p>
          </MDBCol>
        </MDBRow>
      </MDBCard>
      <MDBRow className="mt-2" center>
        <MDBBtn
          onClick={() => switchMode("publish")}
          size="sm"
          outline
          color="white"
        >
          Publish a module
        </MDBBtn>
      </MDBRow>
      <div className="mt-4">
        <MDBRow className="middleVertical">
          <MDBCol>
            <p
              onClick={() => {
                switchMode("lessons");
                getPublishedLessonsFunc();
              }}
              style={{ cursor: "pointer" }}
              className={
                mode === "lessons"
                  ? "mainFontColor text-center quickSand h5 fontWeightNormal"
                  : "text-white text-center quickSand h5 fontWeightNormal"
              }
            >
              Lessons
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="middleVertical mt-1">
          <MDBCol>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                switchMode("topics");
                getPublishedTopicsFunc();
              }}
              className={
                mode === "topics"
                  ? "mainFontColor text-center quickSand h5 fontWeightNormal"
                  : "text-white text-center quickSand h5 fontWeightNormal"
              }
            >
              Topics
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="middleVertical mt-1">
          <MDBCol>
            <p
              onClick={() => {
                switchMode("exams");
                getPublishedExamsFunc();
              }}
              className={
                mode === "exams"
                  ? "mainFontColor text-center quickSand h5 fontWeightNormal"
                  : "text-white text-center quickSand h5 fontWeightNormal"
              }
              style={{ cursor: "pointer" }}
            >
              Exams
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="middleVertical mt-1">
          <MDBCol>
            <p
              onClick={() => {
                switchMode("games");
                getPublishedGamesFunc();
              }}
              className={
                mode === "games"
                  ? "mainFontColor text-center quickSand h5 fontWeightNormal"
                  : "text-white text-center quickSand h5 fontWeightNormal"
              }
              style={{ cursor: "pointer" }}
            >
              Games
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="middleVertical mt-1">
          <MDBCol>
            <p
              onClick={() => {
                switchMode("flashcards");
                getPublishedDecksFunc();
              }}
              className={
                mode === "flashcards"
                  ? "mainFontColor text-center quickSand h5 fontWeightNormal"
                  : "text-white text-center quickSand h5 fontWeightNormal"
              }
              style={{ cursor: "pointer" }}
            >
              Flashcards
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="middleVertical mt-1">
          <MDBCol>
            <p
              onClick={() => {
                switchMode("series");
                getPublishedCurriculumsFunc();
              }}
              style={{ cursor: "pointer" }}
              className={
                mode === "series"
                  ? "mainFontColor text-center quickSand h5 fontWeightNormal"
                  : "text-white text-center quickSand h5 fontWeightNormal"
              }
            >
              Curriculums
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="middleVertical mt-4">
          <MDBCol>
            <p
              onClick={() => switchMode("main")}
              style={{ cursor: "pointer" }}
              className={
                mode === "main"
                  ? "mainFontColor text-center quickSand h5 fontWeightNormal"
                  : "text-white text-center quickSand h5 fontWeightNormal"
              }
            >
              Main
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="middleVertical mt-4 mb-2 hideBottom">
          <MDBIcon
            style={{ cursor: "pointer" }}
            className="text-white"
            onClick={bottomShowFunc}
            icon="info-circle"
          ></MDBIcon>
        </MDBRow>
      </div>
    </Fragment>
  );
};

TradingLeft.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(TradingLeft);
