import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import TradingMain from "../TradingMain";
import {
  getPublishedTopic,
  downloadTopic,
  getPublishedEditedTopics,
  setLoadingButtonDownload
} from "../../../actions/trading";

const Topic = ({
  history,
  mode,
  match,
  getPublishedTopic,
  setLoadingButtonDownload,
  trading: { publishedTopic },
  downloadTopic,
  getPublishedEditedTopics,
  block
}) => {
  const [formData, setFormData] = useState({
    mode: "topic",
    block: false
  });

  const downloadTopicFunc = () => {
    setLoadingButtonDownload(true);
    downloadTopic({
      title: publishedTopic.title,
      text: publishedTopic.text,
      level: publishedTopic.level,
      vocabulary: publishedTopic.vocabulary,
      grammar: publishedTopic.grammar,
      youtube: publishedTopic.youtube,
      links: publishedTopic.links,
      tradingId: publishedTopic._id
    });
  };

  useEffect(() => {
    getPublishedTopic(match.params.id);
  }, [getPublishedTopic, match]);

  useEffect(() => {
    if (publishedTopic) {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "topic"
        };
      });
    }
  }, [publishedTopic]);

  useEffect(() => {
    getPublishedEditedTopics({ originalId: match.params.id });
  }, [getPublishedEditedTopics, match]);

  useEffect(() => {
    if (block === true || block === false) {
      setFormData(prevState => {
        return {
          ...prevState,
          block: block
        };
      });
    }
  }, [block]);

  return (
    <Fragment>
      <TradingMain
        downloadTopicFunc={downloadTopicFunc}
        topicContent={publishedTopic}
        url={formData.mode}
        block={formData.block}
      ></TradingMain>
    </Fragment>
  );
};

Topic.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    getPublishedTopic,
    downloadTopic,
    getPublishedEditedTopics,
    setLoadingButtonDownload
  })(Topic)
);
