import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./curriculum.css";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withRouter, Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { Helmet } from "react-helmet";

import { getLessons, getExams } from "../../actions/trading";
import { getLesson } from "../../actions/lessons";
import {
  makeCurriculum,
  updateCurriculum,
  deleteCurriculum,
  getCurriculumList,
  searchCurriculumsFunction,
  clearCurriculumsSearchReducer,
  setPaginationModeCurriculums,
  setLoadingButtonCurriculums,
  setLoadingDeleteCurriculum
} from "../../actions/curriculum";

import {
  MDBContainer,
  MDBCard,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
  MDBScrollbar,
  MDBPageItem,
  MDBPageNav,
  MDBPagination,
  MDBDropdownItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu
} from "mdbreact";

const Curriculum = ({
  history,
  match,
  setLoadingDeleteCurriculum,
  getLessons,
  getLesson,
  getExams,
  makeCurriculum,
  updateCurriculum,
  deleteCurriculum,
  getCurriculumList,
  searchCurriculumsFunction,
  clearCurriculumsSearchReducer,
  setPaginationModeCurriculums,
  setLoadingButtonCurriculums,
  trading: { lessons, exams },
  curriculum: {
    curriculum,
    listCurriculum,
    deletedCurriculum,
    initialPageNumber,
    initialNumberOfPages,
    pageNumber,
    numberOfPages,
    searchCurriculums,
    paginationModeCurriculums,
    loadingButtonCurriculums,
    curriculumsLoading,
    loadingDeleteCurriculum
  }
}) => {
  const [formData, setFormData] = useState({
    searchValue: "",
    mode: "list",
    type: "",
    curriculumList: [],
    dragColor: "",
    modalDeleteCurriculum: false,
    description: "",
    title: "",
    _id: "",
    searchCurriculums: [],
    pageNumber: 1,
    bottomShow: false
  });

  const onChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const newCurriculum = () => {
    setFormData({
      ...formData,
      mode: "new"
    });
  };
  const listMode = () => {
    setFormData({
      ...formData,
      mode: "list",

      title: "",
      description: "",
      _id: "",

      type: "",
      curriculumList: []
    });
  };
  const editMode = () => {
    setFormData({
      ...formData,
      mode: "new"
    });
  };

  const dragColor = index => {
    if (formData.dragColor !== index) {
      return "CarterOne blue text-white my-1 z-depth-2";
    } else {
      return "CarterOne blue text-white my-1 z-depth-2";
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      setFormData({
        ...formData,

        dragColor: ""
      });
    } else if (result.destination.index === result.source.index) {
      setFormData({
        ...formData,
        dragColor: ""
      });
    } else {
      const newArray = reorder(
        formData.curriculumList,
        result.source.index,
        result.destination.index
      );

      setFormData({
        ...formData,
        curriculumList: newArray,

        dragColor: ""
      });
    }
  };

  const onDragStart = result => {
    const index = result.source.index;
    setFormData({
      ...formData,
      dragColor: index
    });
  };

  const showLessons = () => {
    getLessons();
    setFormData({
      ...formData,
      type: "lessons"
    });
  };
  const showExams = () => {
    getExams();
    setFormData({
      ...formData,
      type: "exams"
    });
  };

  const addToCurriculum = (type, item) => {
    if (type === "lesson") {
      const find = formData.curriculumList.find(
        module => module.moduleId === item._id
      );
      if (!find) {
        setFormData({
          ...formData,
          curriculumList: [
            ...formData.curriculumList,
            {
              title: item.title,
              moduleId: item._id,
              type: "lesson",
              kind: "lessons"
            }
          ]
        });
      }
    } else if (type === "exam") {
      const find = formData.curriculumList.find(
        module => module.moduleId === item._id
      );
      if (!find) {
        setFormData({
          ...formData,
          curriculumList: [
            ...formData.curriculumList,
            {
              title: item.name,
              moduleId: item._id,
              type: "exam",
              kind: "customizables"
            }
          ]
        });
      }
    }
  };

  const removeFromCurriculum = module => {
    const filter = formData.curriculumList.filter(
      item => item.moduleId !== module.moduleId
    );
    setFormData({
      ...formData,
      curriculumList: filter
    });
  };

  const modalDeleteCurriculum = () => {
    setFormData({
      ...formData,
      modalDeleteCurriculum: !formData.modalDeleteCurriculum
    });
  };

  const makeCurriculumFunc = () => {
    setLoadingButtonCurriculums(true);
    makeCurriculum({
      title: formData.title,
      description: formData.description,
      curriculumList: formData.curriculumList
    });
  };
  const updateCurriculumFunc = () => {
    setLoadingButtonCurriculums(true);
    updateCurriculum({
      title: formData.title,
      description: formData.description,
      curriculumList: formData.curriculumList,
      _id: formData._id
    });
  };
  const deleteCurriculumFunc = () => {
    setLoadingDeleteCurriculum(true);
    deleteCurriculum(formData._id);
  };

  useEffect(() => {
    if (curriculum) {
      setFormData(prevState => {
        return {
          ...prevState,
          description: curriculum.description,
          title: curriculum.title,
          _id: curriculum._id,
          curriculumList: curriculum.curriculumList
        };
      });
    }
  }, [curriculum]);

  useEffect(() => {
    if (deletedCurriculum) {
      setFormData(prevState => {
        return {
          ...prevState,
          title: "",
          description: "",
          _id: "",
          mode: "list",
          type: "",
          modalDeleteCurriculum: false,
          curriculumList: []
        };
      });
    }
  }, [deletedCurriculum]);

  useEffect(() => {
    getCurriculumList({ initialPageNumber: "first" });
  }, [getCurriculumList]);

  const seeCurriculum = curriculum => {
    setFormData({
      ...formData,
      mode: "see",
      title: curriculum.title,
      _id: curriculum._id,
      description: curriculum.description,
      curriculumList: curriculum.curriculumList
    });
  };

  // Search Curriculums

  const onKeyPressEnterCurriculums = e => {
    if (e.key === "Enter") {
      curriculumsSearchFunc();
    }
  };

  useEffect(() => {
    if (searchCurriculums) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchCurriculums: searchCurriculums
        };
      });
    }
  }, [searchCurriculums]);

  const curriculumsSearchFunc = () => {
    searchCurriculumsFunction({
      keywords: formData.searchValue,
      pageNumber: formData.pageNumber
    });
    setPaginationModeCurriculums("search");
  };

  const clearCurriculumsFunc = () => {
    setFormData({
      ...formData,
      searchValue: "",
      searchCurriculums: []
    });
    clearCurriculumsSearchReducer();
    setPaginationModeCurriculums("date");
    return listCurriculum;
  };

  const searchCurriculumsMap = () => {
    if (formData.searchCurriculums === "No Results") {
      return [];
    } else if (formData.searchCurriculums.length !== 0) {
      return formData.searchCurriculums;
    } else {
      return listCurriculum;
    }
  };

  // Pagination Curriculums

  const isActive1 = page => {
    if (initialPageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationCurriculums = () => {
    if (paginationModeCurriculums === "date") {
      getCurriculumList({ initialPageNumber: "first" });
    } else if (paginationModeCurriculums === "search") {
      searchCurriculumsFunction({
        pageNumber: "first",
        keywords: formData.searchValue
      });
    }
  };

  const previousPaginationCurriculums = () => {
    if (paginationModeCurriculums === "date") {
      getCurriculumList({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationModeCurriculums === "search") {
      searchCurriculumsFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchValue
      });
    }
  };

  const currentPaginationCurriculums = index => {
    if (paginationModeCurriculums === "date") {
      getCurriculumList({ initialPageNumber: index + 1 });
    } else if (paginationModeCurriculums === "search") {
      searchCurriculumsFunction({
        pageNumber: index + 1,
        keywords: formData.searchValue
      });
    }
  };

  const nextPaginationCurriculums = () => {
    if (paginationModeCurriculums === "date") {
      getCurriculumList({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationModeCurriculums === "search") {
      searchCurriculumsFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchValue
      });
    }
  };

  const lastPaginationCurriculums = () => {
    if (paginationModeCurriculums === "date") {
      getCurriculumList({ initialPageNumber: "last" });
    } else if (paginationModeCurriculums === "search") {
      searchCurriculumsFunction({
        pageNumber: "last",
        keywords: formData.searchValue
      });
    }
  };

  const props2 = useSpring({
    opacity:
      formData.bottomShow === false
        ? 0
        : 1 || formData.bottomShow === true
        ? 1
        : 0,

    config: { duration: 600 }
  });

  const bottomShowFunc = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        bottomShow: !prevState.bottomShow
      };
    });
  };

  // Before Return

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Curriculum</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      {formData.mode === "list" ? (
        <MDBContainer className="mt-5 mb-5">
          <MDBRow className="mt-3 " center>
            <MDBCol md="12">
              <MDBCard className="mt-5 mb-1 curriculumBackground">
                <MDBRow className="pt-3 middleVertical5" end>
                  <MDBCol sm="2" className="px-4 col-2"></MDBCol>
                  <MDBCol sm="4" className="col-6">
                    <MDBInput
                      name="searchValue"
                      className="white-text"
                      label="Search curriculums"
                      labelClass="white-text"
                      type="text"
                      containerClass="active-pink active-pink-2 mt-0 mb-3"
                      value={formData.searchValue}
                      onChange={onChange}
                      onKeyPress={onKeyPressEnterCurriculums}
                    />
                  </MDBCol>
                  <MDBCol className="col-2" sm="2">
                    <MDBRow className="align-items-center">
                      <div
                        className={
                          formData.searchValue !== "" ? "mx-1" : "d-none"
                        }
                      >
                        <MDBIcon
                          icon="search"
                          onClick={curriculumsSearchFunc}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        className={
                          formData.searchCurriculums.length !== 0 ||
                          formData.searchCurriculums === "No Results"
                            ? "mx-1"
                            : "d-none"
                        }
                      >
                        <MDBIcon
                          icon="times-circle"
                          onClick={clearCurriculumsFunc}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol sm="2" className="col-2">
                    <MDBIcon
                      pull="right"
                      size="2x"
                      icon="plus"
                      style={{ cursor: "pointer" }}
                      className=" white-text pr-3"
                      onClick={newCurriculum}
                    />
                  </MDBCol>
                </MDBRow>

                {(formData.searchCurriculums.length === 0 || "No Results") &&
                listCurriculum.length === 0 &&
                curriculumsLoading === false ? (
                  <div className="cardWidth text-center">
                    <img
                      className="imageFlex"
                      src={
                        "https://res.cloudinary.com/unnamed/image/upload/v1578489137/No_Curriculum_Found_1_jsdxxw.png"
                      }
                      alt=""
                    />
                  </div>
                ) : (
                  <div style={{ minHeight: "10rem" }}>
                    <div
                      className={
                        formData.searchCurriculums === "No Results"
                          ? "mt-2"
                          : "d-none"
                      }
                    >
                      <p className="quickSand h4 black-text text-center">
                        There are no curriculum results for your search. Try
                        Again.
                      </p>
                    </div>
                    <MDBRow center className="mt-4 mb-1 mx-1">
                      {searchCurriculumsMap().map((item, index) => {
                        return (
                          <MDBCol
                            key={item._id}
                            style={{ cursor: "pointer", marginBottom: "1rem" }}
                            onClick={() => seeCurriculum(item)}
                            sm="4"
                          >
                            <MDBCard className="curriculumBackground ">
                              <p className="mx-1 text-center text-white quickSand h5-responsive">
                                {item.title}
                              </p>
                            </MDBCard>
                          </MDBCol>
                        );
                      })}
                    </MDBRow>
                  </div>
                )}
                {/* Pagination Curriculums Medium Size  */}

                <div
                  className={
                    formData.mode === "list"
                      ? "middleVertical mt-5 paginationCurriculumsMedium"
                      : "d-none"
                  }
                >
                  <MDBRow>
                    <MDBCol>
                      <MDBPagination circle>
                        <MDBPageItem onClick={firstPaginationCurriculums}>
                          <MDBPageNav className="page-link">
                            <span>First</span>
                          </MDBPageNav>
                        </MDBPageItem>
                        <MDBPageItem onClick={previousPaginationCurriculums}>
                          <MDBPageNav
                            className="page-link"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                          </MDBPageNav>
                        </MDBPageItem>
                        {paginationModeCurriculums === "date"
                          ? [...Array(initialNumberOfPages)].map(
                              (item, index) => {
                                if (index + 1 === initialPageNumber) {
                                  return (
                                    <MDBPageItem
                                      key={index}
                                      active={isActive1(index + 1)}
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber - 1
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber - 2
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber - 3
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber + 1
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber + 2
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber + 3
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )
                          : [...Array(numberOfPages)].map((item, index) => {
                              if (index + 1 === pageNumber) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    active={isActive2(index + 1)}
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber - 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber - 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber - 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber + 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber + 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber + 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else {
                                return null;
                              }
                            })}
                        <MDBPageItem onClick={nextPaginationCurriculums}>
                          <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                        </MDBPageItem>
                        <MDBPageItem onClick={lastPaginationCurriculums}>
                          <MDBPageNav className="page-link">Last</MDBPageNav>
                        </MDBPageItem>
                      </MDBPagination>
                    </MDBCol>
                  </MDBRow>
                </div>

                {/* Pagination Curriculums Small Size  */}

                <div
                  className={
                    formData.mode === "list"
                      ? "middleVertical mt-5 paginationCurriculumsSmall"
                      : "d-none"
                  }
                >
                  <MDBRow>
                    <MDBCol>
                      <MDBPagination circle size="sm">
                        <MDBPageItem onClick={firstPaginationCurriculums}>
                          <MDBPageNav className="page-link">
                            <span>First</span>
                          </MDBPageNav>
                        </MDBPageItem>
                        <MDBPageItem onClick={previousPaginationCurriculums}>
                          <MDBPageNav
                            className="page-link"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                          </MDBPageNav>
                        </MDBPageItem>
                        {paginationModeCurriculums === "date"
                          ? [...Array(initialNumberOfPages)].map(
                              (item, index) => {
                                if (index + 1 === initialPageNumber) {
                                  return (
                                    <MDBPageItem
                                      key={index}
                                      active={isActive1(index + 1)}
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber - 1
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber - 2
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber - 3
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber + 1
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber + 2
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber + 3
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationCurriculums(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )
                          : [...Array(numberOfPages)].map((item, index) => {
                              if (index + 1 === pageNumber) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    active={isActive2(index + 1)}
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber - 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber - 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber - 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber + 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber + 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber + 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationCurriculums(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else {
                                return null;
                              }
                            })}
                        <MDBPageItem onClick={nextPaginationCurriculums}>
                          <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                        </MDBPageItem>
                        <MDBPageItem onClick={lastPaginationCurriculums}>
                          <MDBPageNav className="page-link">Last</MDBPageNav>
                        </MDBPageItem>
                      </MDBPagination>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      ) : formData.mode === "new" ? (
        <MDBContainer className="mt-5 mb-5">
          <MDBRow className="mt-3" center>
            <MDBCol md="12">
              <MDBCard className="mt-5 mb-5 curriculumBackground">
                <MDBRow className="pt-3">
                  <MDBCol>
                    <MDBIcon
                      pull="left"
                      size="2x"
                      icon="arrow-left"
                      style={{ cursor: "pointer" }}
                      className=" white-text pl-3"
                      onClick={listMode}
                    />
                  </MDBCol>
                  <MDBCol>
                    {formData._id ? (
                      <div
                        className={
                          loadingButtonCurriculums === true
                            ? "iconDisabled"
                            : "iconEnabled"
                        }
                      >
                        <MDBIcon
                          pull="right"
                          size="2x"
                          icon="save"
                          className=" white-text pr-3"
                          onClick={updateCurriculumFunc}
                        />
                      </div>
                    ) : (
                      <div
                        className={
                          loadingButtonCurriculums === true
                            ? "iconDisabled"
                            : "iconEnabled"
                        }
                      >
                        <MDBIcon
                          pull="right"
                          size="2x"
                          icon="save"
                          className=" white-text pr-3"
                          onClick={makeCurriculumFunc}
                        />
                      </div>
                    )}

                    {formData._id ? (
                      <MDBIcon
                        pull="right"
                        size="2x"
                        icon="trash-alt"
                        style={{ cursor: "pointer" }}
                        className=" white-text pr-3"
                        onClick={modalDeleteCurriculum}
                      />
                    ) : null}
                  </MDBCol>
                </MDBRow>

                <MDBRow center className="mt-4 mx-1 ">
                  <MDBCol sm="7">
                    <MDBRow center className="mt-4">
                      <MDBDropdown>
                        <MDBDropdownToggle caret color="info">
                          Choose Component
                        </MDBDropdownToggle>
                        <MDBDropdownMenu color="info" basic>
                          <MDBDropdownItem
                            onClick={showLessons}
                            className="quickSand"
                          >
                            Lessons
                          </MDBDropdownItem>

                          <MDBDropdownItem
                            onClick={showExams}
                            className="quickSand"
                          >
                            Exams
                          </MDBDropdownItem>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </MDBRow>
                    <div
                      className={
                        formData.type === "lessons" ? "mt-1" : "d-none"
                      }
                    >
                      <MDBRow center>
                        <p className="h3 text-info quickSand">
                          Choose a Lesson:
                        </p>
                      </MDBRow>
                      <MDBCol size="12">
                        <MDBScrollbar>
                          <div style={{ height: "20rem" }}>
                            {lessons.map((item, index) => {
                              return (
                                <p
                                  key={item._id}
                                  style={{ cursor: "pointer" }}
                                  className="text-white quickSand text-center"
                                  onClick={() =>
                                    addToCurriculum("lesson", item)
                                  }
                                >
                                  {item.title}
                                </p>
                              );
                            })}
                          </div>
                        </MDBScrollbar>
                      </MDBCol>
                    </div>
                    <div
                      className={formData.type === "exams" ? "mt-1" : "d-none"}
                    >
                      <MDBRow center>
                        <p className="h3 text-info quickSand">
                          Choose an Exam:
                        </p>
                      </MDBRow>
                      <MDBCol size="12">
                        <MDBScrollbar>
                          <div style={{ height: "20rem" }}>
                            {exams.map((item, index) => {
                              return (
                                <p
                                  key={item._id}
                                  style={{ cursor: "pointer" }}
                                  className="text-white quickSand text-center"
                                  onClick={() => addToCurriculum("exam", item)}
                                >
                                  {item.name}
                                </p>
                              );
                            })}
                          </div>
                        </MDBScrollbar>
                      </MDBCol>
                    </div>
                  </MDBCol>
                  {/* right side */}
                  <MDBCol sm="5">
                    <MDBRow center>
                      <MDBCol md="12">
                        <MDBInput
                          name="title"
                          className="white-text quickSand"
                          label="Title of curriculum"
                          labelClass="white-text"
                          type="text"
                          value={formData.title}
                          onChange={onChange}
                        />
                      </MDBCol>
                      <MDBCol md="12">
                        <MDBInput
                          style={{ overflowY: "auto" }}
                          name="description"
                          className="white-text quickSand"
                          label="Description of curriculum"
                          labelClass="white-text"
                          type="textarea"
                          value={formData.description}
                          onChange={onChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow center>
                      <p className="quickSand h5-responsive text-white">
                        List of modules
                      </p>
                    </MDBRow>

                    <MDBRow center>
                      <MDBCol size="12">
                        <MDBScrollbar>
                          <MDBListGroup style={{ height: "23rem" }}>
                            <DragDropContext
                              onDragStart={onDragStart}
                              onDragEnd={onDragEnd}
                            >
                              <Droppable droppableId="list">
                                {provided => (
                                  <div
                                    className="Hero-List"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    {formData.curriculumList.map(
                                      (item, index) => {
                                        return (
                                          <Draggable
                                            key={item._id}
                                            draggableId={item.moduleId}
                                            index={index}
                                          >
                                            {provided => (
                                              <div
                                                className="Container"
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                              >
                                                <MDBListGroupItem
                                                  className={dragColor(index)}
                                                >
                                                  <MDBRow
                                                    // style={{
                                                    //   width: "auto"
                                                    // }}
                                                    className="middleVertical5"
                                                  >
                                                    {item.type === "lesson" ? (
                                                      <MDBCol size="9">
                                                        <Link
                                                          to={{
                                                            pathname:
                                                              "/lessonplans",
                                                            search:
                                                              item.moduleId
                                                          }}
                                                          target="_blank"
                                                        >
                                                          <p
                                                            style={{
                                                              cursor: "pointer"
                                                            }}
                                                            className=" h6 text-white text-truncate font-weight-bold quickSand "
                                                            // onClick={() =>
                                                            //   toLesson(item)
                                                            // }
                                                          >
                                                            {`${item.type} : ${item.title}`}
                                                          </p>
                                                        </Link>
                                                      </MDBCol>
                                                    ) : (
                                                      <MDBCol size="9">
                                                        <Link
                                                          to={{
                                                            pathname:
                                                              "/customizables",
                                                            search:
                                                              item.moduleId
                                                          }}
                                                          target="_blank"
                                                        >
                                                          <p
                                                            style={{
                                                              cursor: "pointer"
                                                            }}
                                                            className=" h6 text-white text-truncate font-weight-bold quickSand "
                                                            // onClick={() =>
                                                            //   toLesson(item)
                                                            // }
                                                          >
                                                            {`${item.type} : ${item.title}`}
                                                          </p>
                                                        </Link>
                                                      </MDBCol>
                                                    )}

                                                    <MDBCol size="3">
                                                      <MDBIcon
                                                        style={{
                                                          cursor: "pointer"
                                                        }}
                                                        icon="times"
                                                        onClick={() =>
                                                          removeFromCurriculum(
                                                            item
                                                          )
                                                        }
                                                      />
                                                    </MDBCol>
                                                  </MDBRow>

                                                  {/* </HashLink> */}
                                                </MDBListGroupItem>
                                              </div>
                                            )}
                                          </Draggable>
                                        );
                                      }
                                    )}

                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </MDBListGroup>
                        </MDBScrollbar>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      ) : (
        <MDBContainer className="mt-5 mb-5">
          <MDBRow className="mt-3 " center>
            <MDBCol md="12">
              <MDBCard className="mt-5 mb-5 curriculumBackground">
                <MDBRow className="pt-3">
                  <MDBCol>
                    <MDBIcon
                      pull="left"
                      size="2x"
                      icon="arrow-left"
                      style={{ cursor: "pointer" }}
                      className=" white-text pl-3"
                      onClick={listMode}
                    />
                  </MDBCol>
                  <MDBCol>
                    <MDBIcon
                      pull="right"
                      size="2x"
                      icon="edit"
                      style={{ cursor: "pointer" }}
                      className=" white-text pr-3"
                      onClick={editMode}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow center className="mt-4 mx-1">
                  <p className="quickSand h4 text-white">{formData.title}</p>
                </MDBRow>
                <MDBRow center className="mt-1 mx-1">
                  <p className="quickSand h6 text-elegant">
                    {formData.description}
                  </p>
                </MDBRow>
                {formData.curriculumList.map((item, index) => {
                  return (
                    <MDBRow key={item._id} center className="mt-4 mx-1">
                      {item.type === "lesson" ? (
                        <Link
                          to={{
                            pathname: "/lessonplans",
                            search: item.moduleId
                          }}
                          target="_blank"
                        >
                          <MDBCard
                            style={{ minWidth: "20rem" }}
                            className="curriculumBackground"
                          >
                            <p className="quickSand h5 mt-1 text-center text-black ">
                              {item.type.charAt(0).toUpperCase() +
                                item.type.slice(1)}
                            </p>

                            <p className="quickSand h6 text-center text-white mb-2">
                              {item.title}
                            </p>
                          </MDBCard>
                        </Link>
                      ) : item.type === "exam" ? (
                        <Link
                          to={{
                            pathname: "/customizables",
                            search: item.moduleId
                          }}
                          target="_blank"
                        >
                          <MDBCard
                            style={{ minWidth: "20rem" }}
                            className="curriculumBackground"
                          >
                            <p className="quickSand h5 mt-1 text-center text-black ">
                              {item.type.charAt(0).toUpperCase() +
                                item.type.slice(1)}
                            </p>

                            <p className="quickSand h6 text-center text-white mb-2">
                              {item.title}
                            </p>
                          </MDBCard>
                        </Link>
                      ) : null}
                    </MDBRow>
                  );
                })}
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )}

      <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalDeleteCurriculum}
            toggle={modalDeleteCurriculum}
          >
            <MDBModalBody className="curriculumBackground">
              <p className="text-center quickSand text-white h5">
                If you would like to delete this curriculum please click
                'confirm delete' below.
              </p>
            </MDBModalBody>

            <MDBModalFooter className="curriculumBackground">
              <MDBBtn
                disabled={loadingDeleteCurriculum}
                onClick={deleteCurriculumFunc}
                size="sm"
                color="danger"
              >
                {loadingDeleteCurriculum === true && (
                  <div className="spinner-grow spinner-grow-sm" />
                )}
                Confirm Delete
              </MDBBtn>
              <MDBBtn onClick={modalDeleteCurriculum} size="sm" color="dark">
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
      {formData.bottomShow === false ? (
        <div className="removeUl hideBottom">
          <MDBBtn
            floating
            color="color-one"
            icon="info-circle"
            style={{ bottom: "24px", right: "24px" }}
            onClick={bottomShowFunc}
            className="btnFixed"
            size="sm"
          >
            <MDBIcon icon="info-circle"></MDBIcon>
          </MDBBtn>
        </div>
      ) : null}
      <animated.div style={props2}>
        {formData.bottomShow === true ? (
          <div className="float-wrap text-center z-depth-1-half">
            <MDBCard style={{ height: "16rem" }}>
              <MDBIcon
                onClick={bottomShowFunc}
                style={{ cursor: "pointer" }}
                size="2x"
                className="text-right mainColor bottomButton mr-1 mt-1"
                icon="window-close"
              />
              <MDBRow center>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Curriculum Overview
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Curriculum Overview"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/X00gux7uFzU"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        ) : null}
      </animated.div>
    </Fragment>
  );
};

Curriculum.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  trading: state.trading,
  curriculum: state.curriculum
});

export default withRouter(
  connect(mapStateToProps, {
    getLessons,
    getLesson,
    getExams,
    makeCurriculum,
    updateCurriculum,
    deleteCurriculum,
    getCurriculumList,
    searchCurriculumsFunction,
    clearCurriculumsSearchReducer,
    setPaginationModeCurriculums,
    setLoadingButtonCurriculums,
    setLoadingDeleteCurriculum
  })(Curriculum)
);
