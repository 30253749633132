import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBIcon
} from "mdbreact";
import "./Login.css";
import Footer from "../layout/Footer";
import LoginForm from "./LoginForm";
import { withRouter, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Login = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Login</title>

        <meta
          name="description"
          content="Log into Topscholar and grow your English tutoring business"
        />
        <meta property="og:title" content="Topscholar: Login" />
        <meta
          property="og:description"
          content="Log into Topscholar and grow your English tutoring business"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/login" />
      </Helmet>
      <div className="classic-form-page" id="login">
        <MDBMask
          className="d-flex justify-content-center align-items-center"
          overlay="gradient"
        >
          <MDBContainer className="mt-3 mb-5">
            <MDBRow className="flex-center pt-5 mt-3">
              <MDBCol md="10" lg="6" xl="5" sm="12" className="mt-5 mx-auto">
                {/* <Link to="/#landing">
                  <MDBCard className="trialCard trialHover">
                    <MDBRow center className="middleVertical">
                      <MDBIcon
                        far
                        icon="hand-pointer"
                        size="md"
                        className="trialIcon mr-2"
                      />
                      <p className="text-center trialFont">
                        Start Your 7 Day Free Trial
                      </p>
                    </MDBRow>
                  </MDBCard>
                </Link> */}
                <MDBCard>
                  <MDBCardBody>
                    <div className="form-header loginbody">
                      <p className="h2 loginRegisterTitle">Teacher Login</p>
                    </div>

                    <LoginForm />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
        <Footer />
      </div>
    </Fragment>
  );
};

export default withRouter(connect(null, {})(Login));
