import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_REMINDERS,
  GET_REMINDER,
  CREATE_REMINDER,
  DELETE_REMINDER,
  REMINDER_ERROR,
  SET_LOADING_BUTTON_REMINDERS
} from "./types";

// Get all reminders
export const getAllReminders = () => async dispatch => {
  try {
    const res = await axios.get("/api/users-tutors/reminder");

    dispatch({
      type: GET_REMINDERS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: REMINDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get individual reminder
export const getReminder = id => async dispatch => {
  try {
    const res = await axios.get(`api/users-tutors/reminder/${id}`);

    dispatch({
      type: GET_REMINDER,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: REMINDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// make a reminder

export const makeReminder = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/users-tutors/reminder",
      formData,
      config
    );

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }

    dispatch({
      type: CREATE_REMINDER,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: REMINDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete reminder

export const deleteReminder = (id, history) => async dispatch => {
  try {
    const res = await axios.delete(`/api/users-tutors/reminder/${id}`);

    dispatch({
      type: DELETE_REMINDER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: REMINDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Loading Button Reminders

export const setLoadingButtonReminders = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_REMINDERS,
    payload: formData
  });
};
