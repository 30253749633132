import React, { Fragment, useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import "./trading.css";
import "react-toastify/dist/ReactToastify.css";
import TradingCenter from "./TradingCenter";
import TradingLeft from "./TradingLeft";
import TradingRight from "./TradingRight";
import moment from "moment";
import { useSpring, animated } from "react-spring";
import { Helmet } from "react-helmet";

import {
  getLessons,
  getTopics,
  getExams,
  getGames,
  getDecks,
  getCurriculums,
  getPublishedLessons,
  getPublishedTopics,
  getPublishedExams,
  getPublishedGames,
  getPublishedDecks,
  getPublishedCurriculums,
  getTopUsers,
  saveDescription,
  getFollow,
  getEditedLessonsUser,
  getEditedGamesUser,
  getEditedExamsUser,
  getEditedDecksUser,
  getEditedTopicsUser,
  publishLessonO,
  publishTopicO,
  publishExamO,
  publishDeckO,
  publishGameO,
  getComments,
  setLoadingButtonPublishEdited,
  setLoadingButtonReportTrading,
  reportUser
} from "../../actions/trading";

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBScrollbar,
  MDBInput,
  MDBModalHeader,
  MDBIcon,
  MDBCard
} from "mdbreact";

const TradingMain = ({
  history,
  trading: {
    lessons,
    lesson,
    topics,
    topic,
    exams,
    exam,
    games,
    game,
    decks,
    deck,
    curriculums,
    curriculum,
    publishedLessons,
    publishedLesson,
    publishedTopics,
    publishedTopic,
    publishedExams,
    publishedExam,
    publishedGames,
    publishedGame,
    publishedDecks,
    publishedDeck,
    publishedCurriculums,
    publishedCurriculum,
    topUsers,
    editedDecks,
    editedLessons,
    editedTopics,
    editedExams,
    editedGames,
    followPublic,
    follow,
    loadingButtonPublishEdited,
    loadingGetMain,
    report,
    loadingButtonReportTrading
  },
  getLessons,
  getTopics,
  getExams,
  getGames,
  getDecks,
  getCurriculums,
  getPublishedLessons,
  getPublishedTopics,
  getPublishedExams,
  getPublishedGames,
  getPublishedDecks,
  getPublishedCurriculums,
  url,
  match,
  lessonContent,
  topicContent,
  examContent,
  gameContent,
  deckContent,
  downloadTopicFunc,
  downloadExamFunc,
  downloadGameFunc,
  downloadDeckFunc,
  downloadLessonFunc,
  getTopUsers,
  publicProfile,
  saveDescription,
  auth: { user },
  getFollow,
  getEditedLessonsUser,
  getEditedGamesUser,
  getEditedExamsUser,
  getEditedDecksUser,
  getEditedTopicsUser,
  publishLessonO,
  publishDeckO,
  publishTopicO,
  publishGameO,
  publishExamO,
  publishedLessonEdits,
  getComments,
  setLoadingButtonPublishEdited,
  setLoadingButtonReportTrading,
  reportUser,
  block
}) => {
  const [formData, setFormData] = useState({
    mode: "main",
    publishType: {
      type: "",
      item: ""
    },
    lessons: [],
    topics: [],
    exams: [],
    games: [],
    decks: [],
    publishedLessons: [],
    publishedTopics: [],
    publishedExams: [],
    publishedGames: [],
    publishedDecks: [],
    publishedCurriculums: [],
    description: "",
    modalDescription: false,
    modalUploadEdit: false,
    selectedEdited: "",
    editeddate: moment(new Date()).valueOf(),
    editdescription: "",
    initialPageNumber: 1,
    modalFollowPublic: false,
    modalReportUser: false,
    modalFollow: false,
    modalFollowing: false,
    modalImage: false,
    reportUser: {
      subject: "",
      body: ""
    },
    bottomShow: false
  });

  const switchMode = mode => {
    setFormData({
      ...formData,
      mode: mode
    });
  };

  const publishTypeFunc = (type, item) => {
    setFormData({
      ...formData,
      publishType: {
        ...formData.publishType,
        type: type,
        item: item
      }
    });
  };

  const getLessonsFunc = () => {
    getLessons();
  };
  const getTopicsFunc = () => {
    getTopics();
  };
  const getExamsFunc = () => {
    getExams();
  };
  const getGamesFunc = () => {
    getGames();
  };
  const getDecksFunc = () => {
    getDecks();
  };
  const getCurriculumsFunc = () => {
    getCurriculums();
  };

  const getPublishedLessonsFunc = () => {
    getPublishedLessons({ initialPageNumber: formData.initialPageNumber });
  };
  const getPublishedTopicsFunc = () => {
    getPublishedTopics({ initialPageNumber: formData.initialPageNumber });
  };
  const getPublishedExamsFunc = () => {
    getPublishedExams({ initialPageNumber: formData.initialPageNumber });
  };
  const getPublishedGamesFunc = () => {
    getPublishedGames({ initialPageNumber: formData.initialPageNumber });
  };
  const getPublishedDecksFunc = () => {
    getPublishedDecks({ initialPageNumber: formData.initialPageNumber });
  };
  const getPublishedCurriculumsFunc = () => {
    getPublishedCurriculums({ initialPageNumber: formData.initialPageNumber });
  };

  useEffect(() => {
    getFollow();
  }, [getFollow]);

  useEffect(() => {
    if (lessons) {
      setFormData(prevState => {
        return {
          ...prevState,
          lessons: lessons
        };
      });
    }
  }, [lessons]);

  useEffect(() => {
    if (topics) {
      setFormData(prevState => {
        return {
          ...prevState,
          topics: topics
        };
      });
    }
  }, [topics]);

  useEffect(() => {
    if (exams) {
      setFormData(prevState => {
        return {
          ...prevState,
          exams: exams
        };
      });
    }
  }, [exams]);
  useEffect(() => {
    if (games) {
      setFormData(prevState => {
        return {
          ...prevState,
          games: games
        };
      });
    }
  }, [games]);
  useEffect(() => {
    if (decks) {
      setFormData(prevState => {
        return {
          ...prevState,
          decks: decks
        };
      });
    }
  }, [decks]);
  useEffect(() => {
    if (curriculums) {
      setFormData(prevState => {
        return {
          ...prevState,
          curriculums: curriculums
        };
      });
    }
  }, [curriculums]);

  useEffect(() => {
    if (publishedLessons) {
      setFormData(prevState => {
        return {
          ...prevState,
          publishedLessons: publishedLessons
        };
      });
    }
  }, [publishedLessons]);

  useEffect(() => {
    if (publishedTopics) {
      setFormData(prevState => {
        return {
          ...prevState,
          publishedTopics: publishedTopics
        };
      });
    }
  }, [publishedTopics]);
  useEffect(() => {
    if (publishedExams) {
      setFormData(prevState => {
        return {
          ...prevState,
          publishedExams: publishedExams
        };
      });
    }
  }, [publishedExams]);
  useEffect(() => {
    if (publishedGames) {
      setFormData(prevState => {
        return {
          ...prevState,
          publishedGames: publishedGames
        };
      });
    }
  }, [publishedGames]);
  useEffect(() => {
    if (publishedDecks) {
      setFormData(prevState => {
        return {
          ...prevState,
          publishedDecks: publishedDecks
        };
      });
    }
  }, [publishedDecks]);

  useEffect(() => {
    if (publishedCurriculums) {
      setFormData(prevState => {
        return {
          ...prevState,
          publishedCurriculums: publishedCurriculums
        };
      });
    }
  }, [publishedCurriculums]);

  useEffect(() => {
    if (url === "topic") {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "topicView"
        };
      });
    } else if (url === "exam") {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "examView"
        };
      });
    } else if (url === "game") {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "gameView"
        };
      });
    } else if (url === "deck") {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "flashcardView"
        };
      });
    } else if (url === "publicProfile") {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "publicProfile"
        };
      });
    } else if (url === "lesson") {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "lessonView"
        };
      });
    } else if (url === "series") {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "seriesView"
        };
      });
    }
  }, [url, match]);

  useEffect(() => {
    getTopUsers();
  }, [getTopUsers]);

  const toDashboard = () => {
    history.push(`/dashboard`);
  };

  const onChangeDescription = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const modalDescription = () => {
    setFormData({
      ...formData,
      modalDescription: !formData.modalDescription
    });
  };

  useEffect(() => {
    if (user.description) {
      setFormData(prevState => {
        return {
          ...prevState,
          description: user.description,
          modalDescription: false
        };
      });
    }
  }, [user.description]);

  const modalUploadEdit = item => {
    if (item === "lessons") {
      getEditedLessonsUser({ tradingId: match.params.id });
    } else if (item === "topics") {
      getEditedTopicsUser({ tradingId: match.params.id });
    } else if (item === "exams") {
      getEditedExamsUser({ tradingId: match.params.id });
    } else if (item === "decks") {
      getEditedDecksUser({ tradingId: match.params.id });
    } else if (item === "games") {
      getEditedGamesUser({ tradingId: match.params.id });
    } else {
      return null;
    }
    setFormData({
      ...formData,
      modalUploadEdit: true
    });
  };

  const modalUploadEditClose = () => {
    setFormData({
      ...formData,
      modalUploadEdit: false,
      selectedEdited: ""
    });
  };
  const modalFollowPublicClose = () => {
    setFormData({
      ...formData,
      modalFollowPublic: false
    });
  };

  const modalFollowPublic = () => {
    setFormData({
      ...formData,
      modalFollowPublic: true
    });
  };
  const modalFollowClose = () => {
    setFormData({
      ...formData,
      modalFollow: false
    });
  };

  const modalFollow = () => {
    setFormData({
      ...formData,
      modalFollow: true
    });
  };
  const modalFollowingClose = () => {
    setFormData({
      ...formData,
      modalFollowing: false
    });
  };

  const modalFollowing = () => {
    setFormData({
      ...formData,
      modalFollowing: true
    });
  };

  // Open report modal

  const modalReport = () => {
    setFormData({
      ...formData,
      modalReportUser: true
    });
  };

  // Open Leaderboard Image Modal

  // const openImageModal = () => {
  //   setFormData({
  //     ...formData,
  //     modalImage: true
  //   });
  // };

  const saveDescriptionFunc = () => {
    saveDescription({ description: formData.description });
  };

  const selectEditedLesson = item => {
    setFormData({
      ...formData,
      selectedEdited: {
        ...item,
        typeEdited: "lesson"
      }
    });
  };

  const className = item => {
    if (formData.selectedEdited._id === item._id) {
      return "text-white text-center quickSand info-color px-2";
    } else {
      return "text-white text-center quickSand";
    }
  };
  const selectEditedTopic = item => {
    setFormData({
      ...formData,
      selectedEdited: {
        ...item,
        typeEdited: "topic"
      }
    });
  };
  const selectEditedExam = item => {
    setFormData({
      ...formData,
      selectedEdited: {
        ...item,
        typeEdited: "exam"
      }
    });
  };
  const selectEditedGame = item => {
    setFormData({
      ...formData,
      selectedEdited: {
        ...item,
        typeEdited: "game"
      }
    });
  };
  const selectEditedDeck = item => {
    setFormData({
      ...formData,
      selectedEdited: {
        ...item,
        typeEdited: "deck"
      }
    });
  };

  const uploadEditedFunc = () => {
    if (formData.selectedEdited.typeEdited === "lesson") {
      setLoadingButtonPublishEdited(true);
      publishLessonO({
        date: formData.editdate,
        _id: formData.selectedEdited._id,
        description: formData.editdescription,
        title: formData.selectedEdited.title,
        typeEdited: formData.selectedEdited.typeEdited,
        originalId: formData.selectedEdited.tradingId
      });
    } else if (formData.selectedEdited.typeEdited === "topic") {
      setLoadingButtonPublishEdited(true);
      publishTopicO({
        date: formData.editdate,
        _id: formData.selectedEdited._id,
        description: formData.editdescription,
        title: formData.selectedEdited.title,
        typeEdited: formData.selectedEdited.typeEdited,
        originalId: formData.selectedEdited.tradingId
      });
    } else if (formData.selectedEdited.typeEdited === "exam") {
      setLoadingButtonPublishEdited(true);
      publishExamO({
        date: formData.editdate,
        _id: formData.selectedEdited._id,
        description: formData.editdescription,
        title: formData.selectedEdited.name,
        typeEdited: formData.selectedEdited.typeEdited,
        originalId: formData.selectedEdited.tradingId
      });
    } else if (formData.selectedEdited.typeEdited === "game") {
      setLoadingButtonPublishEdited(true);
      publishGameO({
        date: formData.editdate,
        _id: formData.selectedEdited._id,
        description: formData.editdescription,
        title: formData.selectedEdited.title,
        typeEdited: formData.selectedEdited.typeEdited,
        originalId: formData.selectedEdited.tradingId
      });
    } else if (formData.selectedEdited.typeEdited === "deck") {
      setLoadingButtonPublishEdited(true);
      publishDeckO({
        date: formData.editdate,
        _id: formData.selectedEdited._id,
        description: formData.editdescription,
        title: formData.selectedEdited.name,
        typeEdited: formData.selectedEdited.typeEdited,
        originalId: formData.selectedEdited.tradingId
      });
    } else {
      return null;
    }
  };

  const onChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (lesson || topic || exam || game || deck || curriculum) {
      setFormData(prevState => {
        return {
          ...prevState,
          modalUploadEdit: false,
          publishType: {
            type: "",
            item: ""
          }
        };
      });
    }
  }, [lesson, topic, exam, game, deck, curriculum]);

  useEffect(() => {
    if (formData.mode === "lessonView") {
      getComments({
        lessonId: match.params.id,
        module: "lesson"
      });
    } else if (formData.mode === "topicView") {
      getComments({
        topicId: match.params.id,
        module: "topic"
      });
    } else if (formData.mode === "examView") {
      getComments({
        examId: match.params.id,
        module: "exam"
      });
    } else if (formData.mode === "gameView") {
      getComments({
        gameId: match.params.id,
        module: "game"
      });
    } else if (formData.mode === "flashcardView") {
      getComments({
        deckId: match.params.id,
        module: "deck"
      });
    } else if (formData.mode === "seriesView") {
      getComments({
        curriculumId: match.params.id,
        module: "curriculum"
      });
    }
  }, [getComments, match, formData.mode]);

  const viewProfile = item => {
    history.push(`/trading/profile/${item._id}`);
  };

  // Report User

  const sendReport = async () => {
    setLoadingButtonReportTrading(true);
    reportUser({
      ...formData.reportUser,
      userId: match.params.id
    });
  };

  const onChange2 = e =>
    setFormData({
      ...formData,
      reportUser: {
        ...formData.reportUser,
        [e.target.name]: e.target.value
      }
    });

  // Close Report Modal Upon Backend response

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        modalReportUser: false,
        reportUser: {
          subject: "",
          body: ""
        }
      };
    });
  }, [report]);

  const props2 = useSpring({
    opacity:
      formData.bottomShow === false
        ? 0
        : 1 || formData.bottomShow === true
        ? 1
        : 0,

    config: { duration: 600 }
  });

  const bottomShowFunc = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        bottomShow: !prevState.bottomShow
      };
    });
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Trading</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      {/* Trading Main Modal */}

      <div className="tradingModal">
        <MDBModal
          disableFocusTrap={true}
          isOpen={true}
          toggle
          backdrop={false}
          className="modal-full "
        >
          <div className="modal-full modal-content ">
            <MDBRow style={{ marginBottom: "7rem" }}>
              <MDBCol lg="3">
                <TradingLeft
                  mode={formData.mode}
                  switchMode={switchMode}
                  getPublishedLessonsFunc={getPublishedLessonsFunc}
                  getPublishedTopicsFunc={getPublishedTopicsFunc}
                  getPublishedExamsFunc={getPublishedExamsFunc}
                  getPublishedGamesFunc={getPublishedGamesFunc}
                  getPublishedDecksFunc={getPublishedDecksFunc}
                  getPublishedCurriculumsFunc={getPublishedCurriculumsFunc}
                  bottomShowFunc={bottomShowFunc}
                ></TradingLeft>
              </MDBCol>
              {formData.mode === "privateProfile" ||
              formData.mode === "publicProfile" ? (
                <Fragment>
                  <MDBCol lg="9">
                    <TradingCenter
                      publishType={formData.publishType}
                      switchMode={switchMode}
                      mode={formData.mode}
                      publishedLessons={formData.publishedLessons}
                      publishedTopics={formData.publishedTopics}
                      publishedGames={formData.publishedGames}
                      publishedExams={formData.publishedExams}
                      publishedDecks={formData.publishedDecks}
                      publishedCurriculums={formData.publishedCurriculums}
                      lessonContent={lessonContent}
                      topicContent={topicContent}
                      examContent={examContent}
                      gameContent={gameContent}
                      deckContent={deckContent}
                      publicProfile={publicProfile}
                      modalDescription={modalDescription}
                      modalFollowPublic={modalFollowPublic}
                      modalFollow={modalFollow}
                      modalFollowing={modalFollowing}
                      modalReport={modalReport}
                      sendReport={sendReport}
                    ></TradingCenter>
                  </MDBCol>
                </Fragment>
              ) : (
                <Fragment>
                  <MDBCol lg="6">
                    <TradingCenter
                      switchMode={switchMode}
                      mode={formData.mode}
                      publishType={formData.publishType}
                      publishTypeFunc={publishTypeFunc}
                      publishedLessons={formData.publishedLessons}
                      publishedTopics={formData.publishedTopics}
                      publishedGames={formData.publishedGames}
                      publishedExams={formData.publishedExams}
                      publishedDecks={formData.publishedDecks}
                      publishedCurriculums={formData.publishedCurriculums}
                      lessonContent={lessonContent}
                      topicContent={topicContent}
                      examContent={examContent}
                      gameContent={gameContent}
                      deckContent={deckContent}
                      publicProfile={publicProfile}
                    ></TradingCenter>
                  </MDBCol>
                  <MDBCol lg="3">
                    <TradingRight
                      modalUploadEditFunc={modalUploadEdit}
                      modalUploadEdit={formData.modalUploadEdit}
                      publicProfile={publicProfile}
                      downloadExamFunc={downloadExamFunc}
                      downloadTopicFunc={downloadTopicFunc}
                      downloadGameFunc={downloadGameFunc}
                      downloadDeckFunc={downloadDeckFunc}
                      downloadLessonFunc={downloadLessonFunc}
                      mode={formData.mode}
                      publishType={formData.publishType}
                      publishTypeFunc={publishTypeFunc}
                      getLessonsFunc={getLessonsFunc}
                      getTopicsFunc={getTopicsFunc}
                      getExamsFunc={getExamsFunc}
                      getGamesFunc={getGamesFunc}
                      getDecksFunc={getDecksFunc}
                      getCurriculumsFunc={getCurriculumsFunc}
                      lessons={formData.lessons}
                      topics={formData.topics}
                      exams={formData.exams}
                      games={formData.games}
                      decks={formData.decks}
                      curriculums={formData.curriculums}
                      lessonContent={lessonContent}
                      topicContent={topicContent}
                      examContent={examContent}
                      gameContent={gameContent}
                      deckContent={deckContent}
                      topUsers={topUsers}
                      // openImageModal={openImageModal}
                    ></TradingRight>
                  </MDBCol>
                </Fragment>
              )}
            </MDBRow>
            <animated.div style={props2}>
              {formData.bottomShow === true ? (
                <div className="float-wrap2 text-center z-depth-1-half">
                  <MDBCard style={{ height: "16rem" }}>
                    <MDBIcon
                      onClick={bottomShowFunc}
                      style={{ cursor: "pointer" }}
                      size="2x"
                      className="text-right mainColor bottomButton mr-1 mt-1"
                      icon="window-close"
                    />
                    <MDBRow>
                      <MDBCol size="4">
                        <MDBCard style={{ height: "14rem" }}>
                          <p className="text-center krubFont footerFont">
                            Publishing a module
                          </p>
                          <div className="embed-responsive embed-responsive-1by1 py-3">
                            <iframe
                              style={{ height: "11rem" }}
                              title="Publishing a module"
                              className="embed-responsive-item"
                              src="https://www.youtube.com/embed/zexDI3Q2gw4?rel=0"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </MDBCard>
                      </MDBCol>
                      <MDBCol size="4">
                        <MDBCard style={{ height: "14rem" }}>
                          <p className="text-center krubFont footerFont">
                            Searching for a module
                          </p>
                          <div className="embed-responsive embed-responsive-1by1 py-3">
                            <iframe
                              style={{ height: "11rem" }}
                              title="Searching for a module"
                              className="embed-responsive-item"
                              src="https://www.youtube.com/embed/YpXreqOoVME?rel=0"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </MDBCard>
                      </MDBCol>
                      <MDBCol size="4">
                        <MDBCard style={{ height: "14rem" }}>
                          <p className="text-center krubFont footerFont">
                            Navigating the trading system
                          </p>
                          <div className="embed-responsive embed-responsive-1by1 py-3">
                            <iframe
                              style={{ height: "11rem" }}
                              title="Navigating the trading system"
                              className="embed-responsive-item"
                              src="https://www.youtube.com/embed/xbmbKqjNpuA?rel=0"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </div>
              ) : null}
            </animated.div>
            <MDBModalFooter className="tradingBackgroundColor">
              <MDBBtn color="blue" onClick={toDashboard} className="quickSand">
                Dashboard
              </MDBBtn>
            </MDBModalFooter>
          </div>
        </MDBModal>

        {/* Modal Confirmation delete */}
      </div>
      <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalDescription}
            toggle={modalDescription}
          >
            <MDBModalBody className="tradingBackgroundColor">
              <MDBInput
                style={{ overflowY: "auto" }}
                name="description"
                type="textarea"
                value={formData.description}
                label="Description"
                labelClass="livvicFont text-white"
                className="text-white"
                onChange={onChangeDescription}
              />
            </MDBModalBody>

            <MDBModalFooter className="tradingBackgroundColor">
              <MDBBtn onClick={saveDescriptionFunc} size="sm" color="success">
                Update
              </MDBBtn>
              <MDBBtn onClick={modalDescription} size="sm" color="dark">
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
      <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalUploadEdit}
            toggle={modalUploadEditClose}
          >
            <MDBModalBody className="tradingBackgroundColor">
              {formData.mode === "lessonView" ? (
                <p className="text-center text-info h5">Choose a lesson</p>
              ) : formData.mode === "topicView" ? (
                <p className="text-center text-info h5">Choose a topic</p>
              ) : formData.mode === "examView" ? (
                <p className="text-center text-info h5">Choose an exam</p>
              ) : formData.mode === "gameView" ? (
                <p className="text-center text-info h5">Choose a game</p>
              ) : formData.mode === "flashcardView" ? (
                <p className="text-center text-info h5">Choose a deck</p>
              ) : null}
              <MDBRow center>
                <div style={{ height: "11rem" }}>
                  <MDBScrollbar>
                    {formData.mode === "lessonView"
                      ? editedLessons.map((item, index) => {
                          return (
                            <p
                              key={item._id}
                              onClick={() => selectEditedLesson(item)}
                              style={{ cursor: "pointer" }}
                              className={className(item)}
                            >
                              {item.title}
                            </p>
                          );
                        })
                      : formData.mode === "topicView"
                      ? editedTopics.map((item, index) => {
                          return (
                            <p
                              key={item._id}
                              onClick={() => selectEditedTopic(item)}
                              style={{ cursor: "pointer" }}
                              className={className(item)}
                            >
                              {item.title}
                            </p>
                          );
                        })
                      : formData.mode === "examView"
                      ? editedExams.map((item, index) => {
                          return (
                            <p
                              key={item._id}
                              onClick={() => selectEditedExam(item)}
                              style={{ cursor: "pointer" }}
                              className={className(item)}
                            >
                              {item.name}
                            </p>
                          );
                        })
                      : formData.mode === "gameView"
                      ? editedGames.map((item, index) => {
                          return (
                            <p
                              key={item._id}
                              onClick={() => selectEditedGame(item)}
                              style={{ cursor: "pointer" }}
                              className={className(item)}
                            >
                              {item.title}
                            </p>
                          );
                        })
                      : formData.mode === "flashcardView"
                      ? editedDecks.map((item, index) => {
                          return (
                            <p
                              key={item._id}
                              onClick={() => selectEditedDeck(item)}
                              style={{ cursor: "pointer" }}
                              className={className(item)}
                            >
                              {item.name}
                            </p>
                          );
                        })
                      : null}
                  </MDBScrollbar>
                </div>
              </MDBRow>
              {formData.selectedEdited && (
                <Fragment>
                  <MDBRow center>
                    <p className="quickSand mt-3 duruSans h5 text-center text-white">
                      Publish Edited Version
                    </p>
                  </MDBRow>
                  <p className="mt-1 text-center text-info quickSand">
                    Describe your edited version
                  </p>
                  <MDBRow className="mx-3">
                    <MDBCol>
                      <MDBInput
                        className="white-text"
                        labelClass="text-white"
                        type="textarea"
                        label="Description - 130 character limit"
                        maxlength="130"
                        rows="3"
                        name="editdescription"
                        value={formData.editdescription}
                        onChange={onChange}
                      />
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              )}
            </MDBModalBody>

            <MDBModalFooter className="tradingBackgroundColor">
              {formData.selectedEdited && (
                <MDBBtn
                  disabled={loadingButtonPublishEdited}
                  onClick={uploadEditedFunc}
                  size="sm"
                  color="success"
                >
                  {loadingButtonPublishEdited === true && (
                    <div className="spinner-grow spinner-grow-sm" />
                  )}
                  Submit
                </MDBBtn>
              )}

              <MDBBtn onClick={modalUploadEditClose} size="sm" color="dark">
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
      <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalFollowPublic}
            toggle={modalFollowPublicClose}
          >
            <MDBModalBody className="tradingBackgroundColor">
              <p className="text-center text-info h5">Followers</p>

              <MDBRow center>
                <div style={{ height: "11rem" }}>
                  <MDBScrollbar>
                    <MDBCol size="12">
                      <MDBRow>
                        {followPublic.followed.map((item, index) => {
                          return (
                            <MDBCol
                              key={item._id}
                              center
                              className="mt-1 text-center"
                              onClick={() => {
                                viewProfile({ _id: item._id });
                                setFormData({
                                  ...formData,
                                  modalFollowPublic: false
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                style={{ width: "35px" }}
                                src={item.avatar.url}
                                alt=""
                                className="rounded-circle z-depth-1-half"
                              />
                              <p className="mt-1 quickSand h6  text-white pl-2">
                                {item.screenName}
                              </p>
                            </MDBCol>
                          );
                        })}
                      </MDBRow>
                    </MDBCol>
                  </MDBScrollbar>
                </div>
              </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="tradingBackgroundColor">
              <MDBBtn onClick={modalFollowPublicClose} size="sm" color="dark">
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
      <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal isOpen={formData.modalFollow} toggle={modalFollowClose}>
            <MDBModalBody className="tradingBackgroundColor">
              <p className="text-center text-info h5">Followers</p>

              <MDBRow center>
                <div style={{ height: "11rem" }}>
                  <MDBScrollbar>
                    <MDBCol size="12">
                      <MDBRow>
                        {follow.followed.map((item, index) => {
                          return (
                            <MDBCol
                              key={item._id}
                              className="mt-1 text-center"
                              onClick={() => {
                                viewProfile({ _id: item._id });
                                setFormData({
                                  ...formData,
                                  modalFollow: false
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                style={{ width: "35px" }}
                                src={item.avatar.url}
                                alt=""
                                className="rounded-circle z-depth-1-half"
                              />
                              <p className="mt-1 quickSand h6 text-white pl-2">
                                {item.screenName}
                              </p>
                            </MDBCol>
                          );
                        })}
                      </MDBRow>
                    </MDBCol>
                  </MDBScrollbar>
                </div>
              </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="tradingBackgroundColor">
              <MDBBtn onClick={modalFollowClose} size="sm" color="dark">
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
      <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalFollowing}
            toggle={modalFollowingClose}
          >
            <MDBModalBody className="tradingBackgroundColor">
              <p className="text-center text-info h5">You are following:</p>

              <MDBRow center>
                <div style={{ height: "11rem" }}>
                  <MDBScrollbar>
                    <MDBCol size="12">
                      <MDBRow>
                        {follow.following.map((item, index) => {
                          return (
                            <MDBCol
                              key={item._id}
                              className="mt-1 text-center"
                              onClick={() => {
                                viewProfile({ _id: item._id });
                                setFormData({
                                  ...formData,
                                  modalFollow: false
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                style={{ width: "35px" }}
                                src={item.avatar.url}
                                alt=""
                                className="rounded-circle z-depth-1-half"
                              />
                              <p className="mt-1 quickSand h6  text-white pl-2">
                                {item.screenName}
                              </p>
                            </MDBCol>
                          );
                        })}
                      </MDBRow>
                    </MDBCol>
                  </MDBScrollbar>
                </div>
              </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="tradingBackgroundColor">
              <MDBBtn onClick={modalFollowingClose} size="sm" color="dark">
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>

      {/* Report user modal */}

      <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalReportUser}
            toggle={() => {
              setFormData({
                ...formData,
                modalReportUser: false
              });
            }}
            cascading
          >
            <MDBModalHeader
              toggle={() => {
                setFormData({
                  ...formData,
                  modalReportUser: false
                });
              }}
              className="reportColor white-text"
            >
              Report User
            </MDBModalHeader>
            <MDBModalBody>
              <div>
                <MDBInput
                  type="text"
                  label="Subject of the report"
                  name="subject"
                  className="quickSand text-black hintColor"
                  value={formData.reportUser.subject}
                  onChange={e => onChange2(e)}
                  required
                />
                <MDBInput
                  type="textarea"
                  label="What would you like to report about this user?"
                  name="body"
                  className="quickSand text-black hintColor"
                  value={formData.reportUser.body}
                  onChange={e => onChange2(e)}
                  required
                  rows={10}
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter center>
              <MDBBtn
                outline
                color="secondary"
                onClick={() => {
                  setFormData({
                    ...formData,
                    modalReportUser: false
                  });
                }}
              >
                Cancel
              </MDBBtn>
              <MDBBtn
                className="reportColor"
                onClick={sendReport}
                disabled={loadingButtonReportTrading}
              >
                {loadingButtonReportTrading === true && (
                  <div className="spinner-grow spinner-grow-sm" />
                )}
                Send
                <MDBIcon icon="paper-plane" className="ml-1 " />
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>

      {/* Trading System Explanation Image Modal */}

      {/* <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalImage}
            size="lg"
            className="modal-notify modal-info text-white"
          >
            <MDBModalHeader
              toggle={() => {
                setFormData({
                  ...formData,
                  modalImage: false
                });
              }}
              className="text-center h4 reportColor livvicFont"
              tag="p"
              titleClass="w-100"
            >
              Monthly Leaderboard Explanation
            </MDBModalHeader>
            <MDBModalBody>
              <div>
                <img
                  src={
                    "https://res.cloudinary.com/unnamed/image/upload/v1580328150/Points_Leaderboard_unaqhj.png"
                  }
                  className="img-responsive imageMargin"
                  width="100%"
                  height="auto"
                  alt=""
                ></img>
              </div>
            </MDBModalBody>
            <MDBModalFooter center>
              <MDBBtn
                outline
                color="secondary"
                onClick={() => {
                  setFormData({
                    ...formData,
                    modalImage: false
                  });
                }}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div> */}
      <MDBContainer>
        <MDBModal
          className="modal-notify modal-info text-white"
          isOpen={block}
          toggle={() => null}
          centered
        >
          <MDBModalHeader
            className="text-center quickSand tradingBrandColor"
            titleClass="w-100"
            tag="p"
          >
            Members only
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <MDBIcon
              icon="chalkboard-teacher"
              size="3x"
              className="animated rotateIn mb-4"
            />
            <p className="quickSand">
              This content is for members only. If you aren't a member, register
              for free.
            </p>
          </MDBModalBody>
          <MDBModalFooter className="justify-content-center">
            <Link to="/">
              <MDBBtn className="tradingBrandColor quickSand">Register</MDBBtn>
            </Link>
            <Link to="/login">
              <MDBBtn className="quickSand" color="purple" outline>
                Login
              </MDBBtn>
            </Link>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

TradingMain.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    getLessons,
    getTopics,
    getExams,
    getGames,
    getDecks,
    getPublishedLessons,
    getPublishedTopics,
    getPublishedExams,
    getPublishedGames,
    getPublishedDecks,
    getPublishedCurriculums,
    getTopUsers,
    saveDescription,
    getFollow,
    getEditedLessonsUser,
    getEditedGamesUser,
    getEditedExamsUser,
    getEditedDecksUser,
    getEditedTopicsUser,
    publishLessonO,
    publishTopicO,
    publishExamO,
    publishDeckO,
    publishGameO,
    getComments,
    getCurriculums,
    setLoadingButtonPublishEdited,
    reportUser,
    setLoadingButtonReportTrading
  })(TradingMain)
);
