import React, { Fragment, useState } from "react";
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import "./trading.css";
import "react-toastify/dist/ReactToastify.css";
import PrivateProfileView from "./PrivateProfileView";
import PublicProfileView from "./PublicProfileView";
import LessonView from "./rightSide/LessonView";
import TopicView from "./rightSide/TopicView";
import ExamView from "./rightSide/ExamView";
import GameView from "./rightSide/GameView";
import FlashcardView from "./rightSide/FlashcardView";
import SeriesView from "./rightSide/SeriesView";
import FilterRight from "./FilterRight";
import moment from "moment";
import { MDBCardHeader, MDBCard, MDBBtn, MDBRow, MDBCol } from "mdbreact";
import PublishRight from "./PublishRight";

const TradingRight = ({
  history,
  mode,
  publishTypeFunc,
  publishType,
  getLessonsFunc,
  lessons,
  topics,
  getTopicsFunc,
  exams,
  getExamsFunc,
  getGamesFunc,
  getDecksFunc,
  getCurriculumsFunc,
  games,
  decks,
  downloadTopicFunc,
  downloadExamFunc,
  downloadGameFunc,
  downloadDeckFunc,
  downloadLessonFunc,
  topicContent,
  lessonContent,
  gameContent,
  examContent,
  deckContent,
  trading: { currentMonthScore, previousMonthScore },
  auth: { user },
  publicProfile,
  modalUploadEdit,
  modalUploadEditFunc,
  openImageModal
}) => {
  const [formData, setFormData] = useState({
    stats: "monthly",
    topUsers: []
  });

  const switchStats = item => {
    setFormData({
      ...formData,
      stats: item
    });
  };

  return (
    //Home Page Leaderboard

    <Fragment>
      {mode === "main"
        ? null
        : // <div>
          //   {formData.stats === "alltime" ? (
          //     <div>
          //       <MDBCard
          //         narrow
          //         style={{ height: "auto" }}
          //         className="my-3  pt-3 tradingBackgroundColor"
          //       >
          //         <MDBCardHeader className="view view-cascade tradingCard d-flex middleVertical">
          //           <p className="h5 quickSand mx-2 paragraphColor text-center">
          //             Last Month Winners
          //           </p>
          //         </MDBCardHeader>

          //         {previousMonthScore &&
          //           previousMonthScore.topUsersMonthly.map((item, index) => {
          //             if (index === 0) {
          //               return (
          //                 <Link
          //                   to={{
          //                     pathname: `/trading/profile/${item.userTutor._id}`
          //                   }}
          //                   style={{ color: "#000" }}
          //                 >
          //                   <MDBCard
          //                     key={item._id}
          //                     style={{ height: "auto", cursor: "pointer" }}
          //                     className=" my-2 whiteBackground cardScoreboard mx-2"
          //                   >
          //                     <MDBRow className="my-2 middleVertical5">
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={item.userTutor.avatar.url}
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                       <MDBCol className="widthFull" size="6">
          //                         <p className="quickSand text-black scoreboardFont text-center">
          //                           {item.userTutor.screenName} {item.score}{" "}
          //                           points
          //                         </p>
          //                       </MDBCol>
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={
          //                             "https://res.cloudinary.com/unnamed/image/upload/v1579693830/Topscholar_Gold_Icon_s5xvmq.png"
          //                           }
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                     </MDBRow>
          //                   </MDBCard>
          //                 </Link>
          //               );
          //             } else if (index === 1) {
          //               return (
          //                 <Link
          //                   to={{
          //                     pathname: `/trading/profile/${item.userTutor._id}`
          //                   }}
          //                   style={{ color: "#000" }}
          //                 >
          //                   <MDBCard
          //                     style={{ height: "auto" }}
          //                     className=" my-2 whiteBackground cardScoreboard mx-2"
          //                   >
          //                     <MDBRow className="my-2 middleVertical5">
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={item.userTutor.avatar.url}
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                       <MDBCol className="widthFull" size="6">
          //                         <p className="quickSand text-black scoreboardFont text-center">
          //                           {item.userTutor.screenName} {item.score}{" "}
          //                           points
          //                         </p>
          //                       </MDBCol>
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={
          //                             "https://res.cloudinary.com/unnamed/image/upload/v1579694601/Topscholar_Silver_Icon_ojefoy.png"
          //                           }
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                     </MDBRow>
          //                   </MDBCard>
          //                 </Link>
          //               );
          //             } else if (index === 2) {
          //               return (
          //                 <Link
          //                   to={{
          //                     pathname: `/trading/profile/${item.userTutor._id}`
          //                   }}
          //                   style={{ color: "#000" }}
          //                 >
          //                   <MDBCard
          //                     style={{ height: "auto" }}
          //                     className=" my-2 whiteBackground cardScoreboard mx-2"
          //                   >
          //                     <MDBRow className="my-2 middleVertical5">
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={item.userTutor.avatar.url}
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                       <MDBCol className="widthFull" size="6">
          //                         <p className="quickSand text-black scoreboardFont text-center">
          //                           {item.userTutor.screenName} {item.score}{" "}
          //                           points
          //                         </p>
          //                       </MDBCol>
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={
          //                             "https://res.cloudinary.com/unnamed/image/upload/v1579695414/Topscholar_Bronze_Icon_aacykp.png"
          //                           }
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                     </MDBRow>
          //                   </MDBCard>
          //                 </Link>
          //               );
          //             } else {
          //               return null;
          //             }
          //           })}
          //       </MDBCard>
          //     </div>
          //   ) : null}

          //   {formData.stats === "private" ? (
          //     <div>
          //       <MDBCard
          //         narrow
          //         style={{ height: "auto" }}
          //         className="mx-3 my-3 pr-2 pt-3 tradingBackgroundColor"
          //       >
          //         <MDBCol md="12">
          //           <MDBCardHeader className="view view-cascade tradingCard d-flex middleVertical">
          //             <p className="h5-responsive quickSand mx-2 paragraphColor ">
          //               {user.screenName.slice(-1) === "s"
          //                 ? user.screenName + "' Stats"
          //                 : user.screenName + "'s Stats"}
          //             </p>
          //           </MDBCardHeader>
          //           {/* <MDBCard
          //         style={{ height: "auto" }}
          //         className="mx-2 my-2 borderStats border-white bg-transparent"
          //       >
          //         <MDBRow className="mx-3 my-2 middleVertical">
          //           <MDBCol md="12">
          //             <p className="quickSand h5 text-white middleVertical">
          //               Total Modules Published
          //             </p>
          //             <p className="quickSand h5 text-white middleVertical">10</p>
          //           </MDBCol>
          //         </MDBRow>
          //       </MDBCard> */}
          //           <MDBCard
          //             style={{ height: "auto" }}
          //             className="mx-2 my-2 borderStats border-white bg-transparent"
          //           >
          //             <MDBRow className="mx-3 my-2 middleVertical">
          //               <MDBCol md="12">
          //                 <p className="quickSand h5-responsive text-white middleVertical">
          //                   {moment()
          //                     .startOf("month")
          //                     .format("MMMM")}{" "}
          //                   {moment()
          //                     .startOf("year")
          //                     .format("YYYY")}{" "}
          //                   Points
          //                 </p>
          //                 <p className="quickSand h5-responsive text-white middleVertical">
          //                   {user.score}
          //                 </p>
          //               </MDBCol>
          //             </MDBRow>
          //           </MDBCard>
          //           {/* <MDBCard
          //             style={{ height: "auto" }}
          //             className="mx-2 my-2 borderStats border-white bg-transparent"
          //           >
          //             <MDBRow className="mx-3 my-2 middleVertical">
          //               <MDBCol md="12">
          //                 <p className="quickSand h5 text-white middleVertical">
          //                   Total Points
          //                 </p>
          //                 <p className="quickSand h5 text-white middleVertical">
          //                   {user.score}
          //                 </p>
          //               </MDBCol>
          //             </MDBRow>
          //           </MDBCard> */}
          //         </MDBCol>
          //       </MDBCard>
          //     </div>
          //   ) : null}

          //   {formData.stats === "monthly" ? (
          //     <div>
          //       <MDBCard
          //         narrow
          //         style={{ height: "auto" }}
          //         className="my-3  pt-3 tradingBackgroundColor"
          //       >
          //         <MDBCardHeader className="view view-cascade tradingCard d-flex middleVertical ">
          //           <p className="h5 quickSand mx-2 paragraphColor text-center">
          //             {moment()
          //               .startOf("month")
          //               .format("MMMM")}{" "}
          //             {moment()
          //               .startOf("year")
          //               .format("YYYY")}{" "}
          //             Points Leaderboard
          //           </p>
          //         </MDBCardHeader>
          //         {currentMonthScore &&
          //           currentMonthScore.topUsersMonthly.map((item, index) => {
          //             if (index === 0) {
          //               return (
          //                 <Link
          //                   key={item._id}
          //                   to={{
          //                     pathname: `/trading/profile/${item.userTutor._id}`
          //                   }}
          //                   style={{ color: "#000" }}
          //                 >
          //                   <MDBCard
          //                     style={{ height: "auto" }}
          //                     className="mx-2 my-2 whiteBackground cardScoreboard"
          //                   >
          //                     <MDBRow className="my-2 middleVertical5">
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={item.userTutor.avatar.url}
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                       <MDBCol className="widthFull" size="6">
          //                         <p className="quickSand text-black scoreboardFont text-center">
          //                           {item.userTutor.screenName} {item.score}{" "}
          //                           points
          //                         </p>
          //                       </MDBCol>
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={
          //                             "https://res.cloudinary.com/unnamed/image/upload/v1579693830/Topscholar_Gold_Icon_s5xvmq.png"
          //                           }
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                     </MDBRow>
          //                   </MDBCard>
          //                 </Link>
          //               );
          //             } else if (index === 1) {
          //               return (
          //                 <Link
          //                   key={item._id}
          //                   to={{
          //                     pathname: `/trading/profile/${item.userTutor._id}`
          //                   }}
          //                   style={{ color: "#000" }}
          //                 >
          //                   <MDBCard
          //                     style={{ height: "auto" }}
          //                     className="mx-2 my-2 whiteBackground cardScoreboard"
          //                   >
          //                     <MDBRow className="my-2 middleVertical5">
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={item.userTutor.avatar.url}
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                       <MDBCol className="widthFull" size="6">
          //                         <p className="quickSand text-black scoreboardFont text-center">
          //                           {item.userTutor.screenName} {item.score}{" "}
          //                           points
          //                         </p>
          //                       </MDBCol>
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={
          //                             "https://res.cloudinary.com/unnamed/image/upload/v1579694601/Topscholar_Silver_Icon_ojefoy.png"
          //                           }
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                     </MDBRow>
          //                   </MDBCard>
          //                 </Link>
          //               );
          //             } else if (index === 2) {
          //               return (
          //                 <Link
          //                   key={item._id}
          //                   to={{
          //                     pathname: `/trading/profile/${item.userTutor._id}`
          //                   }}
          //                   style={{ color: "#000" }}
          //                 >
          //                   <MDBCard
          //                     style={{ height: "auto" }}
          //                     className="mx-2 my-2 whiteBackground cardScoreboard"
          //                   >
          //                     <MDBRow className="my-2 middleVertical5">
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={item.userTutor.avatar.url}
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                       <MDBCol className="widthFull" size="6">
          //                         <p className="quickSand text-black scoreboardFont text-center">
          //                           {item.userTutor.screenName} {item.score}{" "}
          //                           points
          //                         </p>
          //                       </MDBCol>
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={
          //                             "https://res.cloudinary.com/unnamed/image/upload/v1579695414/Topscholar_Bronze_Icon_aacykp.png"
          //                           }
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                     </MDBRow>
          //                   </MDBCard>
          //                 </Link>
          //               );
          //             } else {
          //               return (
          //                 <Link
          //                   key={item._id}
          //                   to={{
          //                     pathname: `/trading/profile/${item.userTutor._id}`
          //                   }}
          //                   style={{ color: "#000" }}
          //                 >
          //                   <MDBCard
          //                     style={{ height: "auto" }}
          //                     className="mx-2 my-2 whiteBackground cardScoreboard"
          //                   >
          //                     <MDBRow className="my-2 middleVertical5">
          //                       <MDBCol size="3" className="text-center">
          //                         <img
          //                           style={{ width: "30px" }}
          //                           src={item.userTutor.avatar.url}
          //                           alt=""
          //                           className="rounded-circle z-depth-1-half "
          //                         />
          //                       </MDBCol>
          //                       <MDBCol className="widthFull" size="6">
          //                         <p className="quickSand text-black scoreboardFont text-center">
          //                           {item.userTutor.screenName} {item.score}{" "}
          //                           points
          //                         </p>
          //                       </MDBCol>
          //                       <MDBCol size="3" className="text-center">
          //                         {/* <img
          //                 style={{ width: "30px" }}
          //                 src={LogoGold}
          //                 alt=""
          //                 className="rounded-circle z-depth-1-half "
          //               /> */}
          //                       </MDBCol>
          //                     </MDBRow>
          //                   </MDBCard>
          //                 </Link>
          //               );
          //             }
          //           })}
          //       </MDBCard>
          //     </div>
          //   ) : null}

          //   <MDBRow center>
          //     <MDBBtn
          //       size="sm"
          //       className="lessonButton"
          //       rounded
          //       color="white"
          //       onClick={() => switchStats("monthly")}
          //     >
          //       Monthly Leaderboard
          //     </MDBBtn>
          //   </MDBRow>
          //   <MDBRow center>
          //     <MDBBtn
          //       size="sm"
          //       className="lessonButton"
          //       rounded
          //       color="white"
          //       onClick={() => switchStats("alltime")}
          //     >
          //       Previous Month
          //     </MDBBtn>
          //   </MDBRow>
          //   <MDBRow center>
          //     <MDBBtn
          //       size="sm"
          //       className="lessonButton"
          //       rounded
          //       color="white"
          //       onClick={() => switchStats("private")}
          //     >
          //       Your Stats
          //     </MDBBtn>
          //   </MDBRow>
          //   {/* <MDBRow className="mt-5" center>
          //     <MDBBtn size="sm" rounded color="black" onClick={openImageModal}>
          //       Leaderboard Info
          //     </MDBBtn>
          //   </MDBRow> */}
          // </div>
          null}

      {/* Different Views  */}

      {mode === "lessonView" ? (
        <div>
          <LessonView
            modalUploadEditFunc={modalUploadEditFunc}
            modalUploadEdit={modalUploadEdit}
            lessonContent={lessonContent}
            downloadLessonFunc={downloadLessonFunc}
          ></LessonView>
        </div>
      ) : null}

      {mode === "topicView" ? (
        <div>
          <TopicView
            modalUploadEditFunc={modalUploadEditFunc}
            topicContent={topicContent}
            downloadTopicFunc={downloadTopicFunc}
          ></TopicView>
        </div>
      ) : null}

      {mode === "examView" ? (
        <div>
          <ExamView
            modalUploadEditFunc={modalUploadEditFunc}
            examContent={examContent}
            downloadExamFunc={downloadExamFunc}
          ></ExamView>
        </div>
      ) : null}

      {mode === "gameView" ? (
        <div>
          <GameView
            modalUploadEditFunc={modalUploadEditFunc}
            gameContent={gameContent}
            downloadGameFunc={downloadGameFunc}
          ></GameView>
        </div>
      ) : null}

      {mode === "flashcardView" ? (
        <div>
          <FlashcardView
            modalUploadEditFunc={modalUploadEditFunc}
            deckContent={deckContent}
            downloadDeckFunc={downloadDeckFunc}
          ></FlashcardView>
        </div>
      ) : null}

      {mode === "seriesView" ? (
        <div>
          <SeriesView></SeriesView>
        </div>
      ) : null}

      {mode === "publish" ? (
        <div>
          <PublishRight
            publishTypeFunc={publishTypeFunc}
            publishType={publishType}
            getLessonsFunc={getLessonsFunc}
            getTopicsFunc={getTopicsFunc}
            getExamsFunc={getExamsFunc}
            getGamesFunc={getGamesFunc}
            getDecksFunc={getDecksFunc}
            getCurriculumsFunc={getCurriculumsFunc}
            lessons={lessons}
            topics={topics}
            exams={exams}
            games={games}
            decks={decks}
          ></PublishRight>
        </div>
      ) : null}

      {mode === "privateProfile" ? (
        <div>
          <PrivateProfileView></PrivateProfileView>
        </div>
      ) : null}

      {mode === "publicProfile" ? (
        <div>
          <PublicProfileView publicProfile={publicProfile}></PublicProfileView>
        </div>
      ) : null}

      {/* Filter View  */}

      {mode === "lessons" ||
      mode === "topics" ||
      mode === "exams" ||
      mode === "games" ||
      mode === "flashcards" ||
      mode === "series" ? (
        <div>
          <FilterRight mode={mode}></FilterRight>
        </div>
      ) : null}
    </Fragment>
  );
};

TradingRight.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading,
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, {})(TradingRight));
