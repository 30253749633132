import {
  CANVAS_ERROR,
  MAKE_CANVAS,
  GET_CANVASLIST,
  UPDATE_CANVAS,
  DELETE_CANVAS,
  CLEAR_CANVAS,
  SEARCH_CANVAS,
  CLEAR_CANVAS_SEARCH,
  SET_PAGINATION_MODE_CANVAS,
  SET_LOADING_BUTTON_CANVAS,
  SET_LOADING_DELETE_CANVAS,
  LOGOUT
} from "../actions/types";

const initialState = {
  listCanvas: [],
  updatedCanvas: "",
  deletedCanvas: "",
  loading: true,
  error: "",
  createCanvas: "",
  initialPageNumber: 1,
  initialNumberOfPages: 1,
  pageNumber: 1,
  numberOfPages: 1,
  keywords: "",
  searchCanvas: [],
  paginationModeCanvas: "date",
  loadingButtonCanvas: false,
  canvasLoading: true,
  loadingDeleteCanvas: false
};
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_CANVAS:
      return {
        ...state,

        updatedCanvas: "",
        deletedCanvas: "",
        createCanvas: ""
      };
    case CLEAR_CANVAS_SEARCH:
      return {
        ...state,
        searchCanvas: "",
        keywords: "",
        numberOfPages: "",
        pageNumber: "",
        paginationModeCanvas: "date"
      };
    case MAKE_CANVAS:
      return {
        ...state,
        createCanvas: payload.canvas,
        loadingButtonCanvas: false
      };
    case GET_CANVASLIST:
      return {
        ...state,
        listCanvas: payload.listCanvas,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber,
        loading: false,
        canvasLoading: false
      };
    case CANVAS_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonCanvas: false,
        loadingDeleteCanvas: false
      };
    case UPDATE_CANVAS:
      return {
        ...state,

        updatedCanvas: payload.canvas,
        loadingButtonCanvas: false
      };
    case SEARCH_CANVAS:
      return {
        ...state,
        searchCanvas: payload.searchCanvas,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        loading: false
      };
    case SET_PAGINATION_MODE_CANVAS:
      return {
        ...state,
        paginationModeCanvas: payload
      };
    case SET_LOADING_BUTTON_CANVAS:
      return {
        ...state,
        loadingButtonCanvas: payload
      };
    case DELETE_CANVAS:
      return {
        ...state,

        listCanvas: state.listCanvas.filter(
          canvas => canvas._id !== payload.canvas._id
        ),
        deletedCanvas: payload.canvas._id,
        loadingDeleteCanvas: false
      };
    case SET_LOADING_DELETE_CANVAS:
      return {
        ...state,
        loadingDeleteCanvas: payload
      };
    case LOGOUT:
      return {
        listCanvas: [],
        updatedCanvas: "",
        deletedCanvas: "",
        loading: true,
        error: "",
        createCanvas: "",
        initialPageNumber: 1,
        initialNumberOfPages: 1,
        pageNumber: 1,
        numberOfPages: 1,
        keywords: "",
        searchCanvas: [],
        paginationModeCanvas: "date",
        loadingButtonCanvas: false,
        canvasLoading: true,
        loadingDeleteCanvas: false
      };
    default:
      return state;
  }
}
