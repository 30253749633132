import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resendToken } from "../../actions/auth";
import { MDBInput } from "mdbreact";
import { withRouter } from "react-router-dom";

const ResendToken = ({ resendToken }) => {
  const [formData, setFormData] = useState({
    email: ""
  });

  const { email } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    resendToken(formData);
  };

  return (
    <Fragment>
      <form className='form' onSubmit={e => onSubmit(e)}>
        <div className='form-group'>
          <MDBInput
            type='email'
            label='Email Address'
            name='email'
            value={email}
            onChange={e => onChange(e)}
            required
          />
        </div>

        <input type='submit' className='btn btn-primary' value='Resend Token' />
      </form>
    </Fragment>
  );
};

ResendToken.propTypes = {
  resendToken: PropTypes.func.isRequired
};

export default withRouter(connect(null, { resendToken })(ResendToken));
