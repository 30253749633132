import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import "../layout/Landing.css";

const FindTutorsTerms = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Find Tutors Terms</title>

        <meta
          name="description"
          content="Topscholar Find Tutors terms and conditions"
        />
        <meta property="og:title" content="Topscholar: Find Tutors Terms" />
        <meta
          property="og:description"
          content="Topscholar Find Tutors terms and conditions"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link
          rel="canonical"
          href="https://www.topscholar.io/findtutorsterms"
        />
      </Helmet>
      <MDBContainer className="mt-5 mb-5 privacyBackground">
        <MDBRow className="pt-5 mt-3 ">
          <MDBCol md="12" className="text-center col-12">
            <h3 className="font-weight-bold monserratFont mb-3 cookieMarginsMobile">
              {t("findtutorsterms.1")}
            </h3>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.2")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.3")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("findtutorsterms.4")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.5")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("findtutorsterms.6")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.7")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.8")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.9")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("findtutorsterms.10")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.11")}{" "}
              <a href="https://stripe.com/docs/disputes/connect">
                {" "}
                stripe.com/docs/disputes/connect
              </a>
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("findtutorsterms.12")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.13")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.14")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("findtutorsterms.15")}
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default withRouter(FindTutorsTerms);
