import React, { Fragment, useEffect, useState } from "react";
import sum from "lodash/sum";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput
} from "mdbreact";

import {
  createComment,
  deleteComment,
  likeComment,
  setLoadingButtonComment
} from "../../../actions/trading";

const ExamView = ({
  history,
  mode,
  match,
  examContent,
  setLoadingButtonComment,
  trading: { comments, loadingButtonComment },
  auth: { user },
  createComment,
  deleteComment,
  likeComment
}) => {
  const [formData, setFormData] = useState({
    currentCorrectExam: {
      name: "",
      description: "",
      duration: "",
      students: [],
      components: [],
      date: "",
      image: "",
      englishLevel: [],
      examId: "",
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      }
    },
    showCommentButton: false,
    comment: ""
  });

  useEffect(() => {
    if (examContent) {
      setFormData(prevState => {
        return {
          ...prevState,
          currentCorrectExam: {
            ...prevState.currentCorrectExam,
            name: examContent.name,
            description: examContent.description,
            duration: examContent.duration,
            components: examContent.components,
            date: examContent.date,
            image: examContent.image,
            englishLevel: examContent.englishLevel,
            userTutor: examContent.userTutor
          }
        };
      });
    }
  }, [examContent]);

  const checkboxAnswer = (nr, index) => {
    const filter = formData.currentCorrectExam.components[index].answer.filter(
      item => item === nr
    );
    if (filter.length !== 0) {
      return true;
    } else return false;
  };

  const createCommentFunc = () => {
    setLoadingButtonComment(true);
    createComment({
      date: moment(new Date()).valueOf(),
      text: formData.comment,
      examId: match.params.id,
      module: "exam"
    });
  };

  const onChangeComment = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const showCommentButton = () => {
    setFormData({
      ...formData,
      showCommentButton: true
    });
  };

  const likeFarFunc = item => {
    const find = item.thumbsUp.find(item3 => item3.user === user._id);
    if (find) {
      if (find.score === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const dislikeFarFunc = item => {
    const find = item.thumbsUp.find(item3 => item3.user === user._id);
    if (find) {
      if (find.score === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        comment: "",
        showCommentButton: false
      };
    });
  }, [comments]);

  const viewProfile = item => {
    history.push(
      `/trading/profile/${formData.currentCorrectExam.userTutor._id}`
    );
  };

  const viewProfile2 = item => {
    history.push(`/trading/profile/${item}`);
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{formData.currentCorrectExam.name}</title>
        <meta
          name="description"
          content={formData.currentCorrectExam.description}
        />
        <meta property="og:title" content={formData.currentCorrectExam.name} />
        <meta
          property="og:description"
          content={formData.currentCorrectExam.description}
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link
          rel="canonical"
          href={`https://www.topscholar.io/trading/exam/${match.params.id}`}
        />
      </Helmet>
      <MDBRow className="middleVertical5 mx-2">
        <MDBCol sm="6">
          <MDBRow center>
            <MDBCol>
              <p className="quickSand mt-3 duruSans h5 text-center text-white">
                {formData.currentCorrectExam.name}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol sm="6">
          <MDBRow
            center
            className="middleVertical tradingNameHover"
            onClick={() =>
              viewProfile({ _id: formData.currentCorrectExam.userTutor._id })
            }
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ width: "35px" }}
              src={formData.currentCorrectExam.userTutor.avatar.url}
              alt=""
              className="rounded-circle z-depth-1-half"
            />
            <p className="mt-3 quickSand titleFontSizeTrading text-black text-white pl-2">
              Published by {formData.currentCorrectExam.userTutor.screenName}
            </p>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <hr />
      <MDBRow>
        <MDBCol>
          <p className="mt-1 quickSand h6 text-black text-white pl-2 mb-3 ml-2">
            Description: {formData.currentCorrectExam.description}
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          <MDBCard className="mx-4 my-4 backgroundTradingExam">
            <MDBCardBody>
              {formData.currentCorrectExam.components.map((item, index) => {
                if (item.type === "multipleChoice") {
                  return (
                    <Fragment key={item._id}>
                      <MDBRow
                        id={`multipleChoice${index}`}
                        className="pb-5 mb-5  z-depth-2"
                      >
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="12"
                        >
                          {/* Question */}
                          <MDBInput
                            disabled
                            name="question"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .question
                            }
                            label="Question"
                            labelClass="livvicFont"
                          />
                        </MDBCol>

                        <MDBCol className="text-center" size="6">
                          {/* Option 1 and 2 */}
                          <MDBRow>
                            <MDBCol>
                              <MDBInput
                                disabled
                                name="choice1"
                                type="text"
                                value={
                                  formData.currentCorrectExam.components[index]
                                    .choice1
                                }
                                label="Choice 1"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                            <div className="w-100"></div>
                            <MDBCol>
                              <MDBInput
                                disabled
                                name="choice2"
                                type="text"
                                value={
                                  formData.currentCorrectExam.components[index]
                                    .choice2
                                }
                                label="Choice 2"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                        {/* Option 2 and 3 */}
                        <MDBCol className="text-center" size="6">
                          <MDBRow>
                            <MDBCol>
                              <MDBInput
                                disabled
                                name="choice3"
                                type="text"
                                value={
                                  formData.currentCorrectExam.components[index]
                                    .choice3
                                }
                                label="Choice 3"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                            <div className="w-100"></div>
                            <MDBCol>
                              <MDBInput
                                disabled
                                name="choice4"
                                type="text"
                                value={
                                  formData.currentCorrectExam.components[index]
                                    .choice4
                                }
                                label="Choice 4"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                        {/* Answer */}

                        <MDBCol className="text-center  " size="12">
                          <p className="text-center h5 mt-2 livvicFont">
                            Answer
                          </p>
                          <MDBRow>
                            <MDBCol>
                              <MDBInput
                                disabled
                                checked={
                                  formData.currentCorrectExam.components[index]
                                    .answer === 1
                                    ? true
                                    : false
                                }
                                type="radio"
                                id={`radio1-${index}`}
                                label="1"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                                disabled
                                checked={
                                  formData.currentCorrectExam.components[index]
                                    .answer === 2
                                    ? true
                                    : false
                                }
                                type="radio"
                                id={`radio2-${index}`}
                                label="2"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                                disabled
                                checked={
                                  formData.currentCorrectExam.components[index]
                                    .answer === 3
                                    ? true
                                    : false
                                }
                                type="radio"
                                id={`radio3-${index}`}
                                label="3"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                                disabled
                                checked={
                                  formData.currentCorrectExam.components[index]
                                    .answer === 4
                                    ? true
                                    : false
                                }
                                type="radio"
                                id={`radio4-${index}`}
                                label="4"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </Fragment>
                  );
                } else if (item.type === "checkAllBoxes") {
                  return (
                    <Fragment key={item._id}>
                      <MDBRow
                        id={`checkAllBoxes${index}`}
                        className="pb-5 mb-5  z-depth-2"
                      >
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="12"
                        >
                          {/* Question */}
                          <MDBInput
                            disabled
                            name="question"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .question
                            }
                            label="Question"
                            labelClass="livvicFont"
                          />
                        </MDBCol>

                        <MDBCol className="text-center" size="6">
                          {/* Option 1 and 2 */}
                          <MDBRow>
                            <MDBCol>
                              <MDBInput
                                disabled
                                name="choice1"
                                type="text"
                                value={
                                  formData.currentCorrectExam.components[index]
                                    .choice1
                                }
                                label="Choice 1"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                            <div className="w-100"></div>
                            <MDBCol>
                              <MDBInput
                                disabled
                                name="choice2"
                                type="text"
                                value={
                                  formData.currentCorrectExam.components[index]
                                    .choice2
                                }
                                label="Choice 2"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                        {/* Option 2 and 3 */}
                        <MDBCol className="text-center" size="6">
                          <MDBRow>
                            <MDBCol>
                              <MDBInput
                                disabled
                                name="choice3"
                                type="text"
                                value={
                                  formData.currentCorrectExam.components[index]
                                    .choice3
                                }
                                label="Choice 3"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                            <div className="w-100"></div>
                            <MDBCol>
                              <MDBInput
                                disabled
                                name="choice4"
                                type="text"
                                value={
                                  formData.currentCorrectExam.components[index]
                                    .choice4
                                }
                                label="Choice 4"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                        {/* Answer */}

                        <MDBCol className="text-center  " size="12">
                          <p className="text-center h5 mt-2 livvicFont">
                            Answer
                          </p>
                          <MDBRow>
                            <MDBCol>
                              <MDBInput
                                disabled
                                checked={checkboxAnswer(1, index)}
                                type="checkbox"
                                id={`checkbox1-${index}`}
                                label="1"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                                disabled
                                checked={checkboxAnswer(2, index)}
                                type="checkbox"
                                id={`checkbox2-${index}`}
                                label="2"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                                disabled
                                checked={checkboxAnswer(3, index)}
                                type="checkbox"
                                id={`checkbox3-${index}`}
                                label="3"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                            <MDBCol>
                              <MDBInput
                                disabled
                                checked={checkboxAnswer(4, index)}
                                type="checkbox"
                                id={`checkbox4-${index}`}
                                label="4"
                                labelClass="livvicFont"
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </Fragment>
                  );
                } else if (item.type === "textArea") {
                  return (
                    <Fragment key={item._id}>
                      <MDBRow
                        id={`textArea${index}`}
                        className="pb-5 mb-5  z-depth-2"
                      >
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="12"
                        >
                          {/* Question */}
                          <MDBInput
                            disabled
                            name="question"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .question
                            }
                            label="Question"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="12"
                        >
                          {/* Question */}
                          <MDBInput
                            disabled
                            name="answer"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .answer
                            }
                            label="Answer (optional)"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                      </MDBRow>
                    </Fragment>
                  );
                } else if (item.type === "matching") {
                  return (
                    <Fragment key={item._id}>
                      <MDBRow
                        id={`matching${index}`}
                        className="pb-5 mb-5  z-depth-2"
                      >
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="12"
                        >
                          {/* Question */}
                          <MDBInput
                            disabled
                            name="question"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .question
                            }
                            label="Question"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match1a"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match1.match1a
                            }
                            label="Match 1a"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match1b"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match1.match1b
                            }
                            label="Match 1b"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match2a"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match2.match2a
                            }
                            label="Match 2a"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match2b"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match2.match2b
                            }
                            label="Match 2b"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match3a"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match3.match3a
                            }
                            label="Match 3a"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match3b"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match3.match3b
                            }
                            label="Match 3b"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match4a"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match4.match4a
                            }
                            label="Match 4a"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match4b"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match4.match4b
                            }
                            label="Match 4b"
                            labelClass="livvicFont"
                          />
                        </MDBCol>

                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match5a"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match5.match5a
                            }
                            label="Match 5a"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match5b"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match5.match5b
                            }
                            label="Match 5b"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match6a"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match6.match6a
                            }
                            label="Match 6a"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                        <MDBCol
                          className="text-center customizablesMarginBottom "
                          size="6"
                        >
                          <MDBInput
                            disabled
                            name="match6b"
                            type="textarea"
                            value={
                              formData.currentCorrectExam.components[index]
                                .match6.match6b
                            }
                            label="Match 6b"
                            labelClass="livvicFont"
                          />
                        </MDBCol>
                      </MDBRow>
                    </Fragment>
                  );
                } else {
                  return null;
                }
              })}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-5" center>
        <p className=" duruSans h5 text-center text-white">Comments</p>
      </MDBRow>
      <MDBRow center>
        <MDBCol size="12">
          <MDBInput
            style={{ overflowY: "auto" }}
            name="comment"
            type="textarea"
            value={formData.comment}
            label="Post a new comment"
            labelClass="livvicFont text-white"
            className="text-white"
            onChange={onChangeComment}
            onClick={showCommentButton}
          />
        </MDBCol>
        <MDBCol
          size="12"
          className={
            formData.showCommentButton === false ? "d-none" : "text-right"
          }
        >
          <MDBBtn
            onClick={() => {
              setFormData({
                ...formData,
                showCommentButton: false,
                comment: ""
              });
            }}
            outline
          >
            Cancel
          </MDBBtn>
          <MDBBtn disabled={loadingButtonComment} onClick={createCommentFunc}>
            {loadingButtonComment === true && (
              <div className="spinner-grow spinner-grow-sm" />
            )}
            Comment
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <hr />
      {comments.map((item, index) => {
        const thumb = item.thumbsUp.map((item2, index2) => {
          return item2.score;
        });
        let summation = sum(thumb);

        summation = summation <= 0 ? null : summation;

        return (
          <MDBRow className="mt-1 mb-4" key={item._id}>
            <MDBCol sm="4" md="2" className="col-4">
              <div className="label">
                <img
                  style={{ width: "35px", cursor: "pointer" }}
                  src={item.userTutor.avatar.url}
                  alt=""
                  className="rounded-circle z-depth-1-half"
                  onClick={() => viewProfile2(item.userTutor._id)}
                />
              </div>
            </MDBCol>
            <MDBCol sm="8" md="10" className="col-8">
              <div className="">
                <MDBRow className="middleVertical5">
                  <p
                    style={{ margin: "0rem", cursor: "pointer" }}
                    className="quickSand text-white"
                    onClick={() => viewProfile2(item.userTutor._id)}
                  >
                    {item.userTutor.screenName}
                  </p>

                  <div
                    className={
                      item.userTutor._id === user._id
                        ? "ml-auto mr-5"
                        : "d-none"
                    }
                  >
                    <MDBIcon
                      onClick={() => deleteComment({ commentId: item._id })}
                      style={{ cursor: "pointer" }}
                      className="text-danger"
                      icon="trash-alt"
                    />
                  </div>
                </MDBRow>
                <MDBRow>
                  <p style={{ marginBottom: "2px" }}>
                    {moment(item.date, "x").fromNow()}
                  </p>
                </MDBRow>
                <MDBRow>
                  <p
                    style={{ margin: "0rem" }}
                    className="text-white quickSand mb-1"
                  >
                    {item.text}
                  </p>
                </MDBRow>
                <MDBRow>
                  <p className="text-info font-weight-bold mr-2">{summation}</p>

                  <MDBIcon
                    style={{ cursor: "pointer" }}
                    className="mr-2 text-info"
                    far={likeFarFunc(item)}
                    icon="thumbs-up"
                    onClick={() => {
                      likeComment({ score: 1, commentId: item._id });
                    }}
                  />

                  <MDBIcon
                    style={{ cursor: "pointer" }}
                    className="text-info"
                    far={dislikeFarFunc(item)}
                    icon="thumbs-down "
                    onClick={() => {
                      likeComment({ score: -1, commentId: item._id });
                    }}
                  />
                </MDBRow>
              </div>
            </MDBCol>
          </MDBRow>
        );
      })}
    </Fragment>
  );
};

ExamView.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    createComment,
    deleteComment,
    likeComment,
    setLoadingButtonComment
  })(ExamView)
);
