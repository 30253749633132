import {
  GET_REMINDERS,
  GET_REMINDER,
  UPDATE_REMINDER,
  DELETE_REMINDER,
  CREATE_REMINDER,
  REMINDER_ERROR,
  SET_LOADING_BUTTON_REMINDERS,
  LOGOUT
} from "../actions/types";

const initialState = {
  reminder: {},
  reminders: [],
  loading: true,
  loadingButtonReminders: false,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REMINDERS:
      return {
        ...state,
        reminders: payload.reminders,
        loading: false
      };
    case UPDATE_REMINDER:
    case GET_REMINDER:
      return {
        ...state,
        reminder: payload,
        loading: false
      };
    case DELETE_REMINDER:
      return {
        ...state,
        reminders: payload.reminders,
        loadingButtonReminders: false
      };

    case CREATE_REMINDER:
      return {
        ...state,
        reminders: payload.reminders,
        loadingButtonReminders: false
      };
    case SET_LOADING_BUTTON_REMINDERS:
      return {
        ...state,
        loadingButtonReminders: payload
      };
    case REMINDER_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonReminders: false
      };
    case LOGOUT:
      return {
        reminder: {},
        reminders: [],
        loading: true,
        loadingButtonReminders: false,
        error: {}
      };

    default:
      return state;
  }
}
