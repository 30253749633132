import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import "./trading.css";
import "react-toastify/dist/ReactToastify.css";
import { orderBy } from "natural-orderby";

import {
  MDBRow,
  MDBCol,
  MDBScrollbar,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu
} from "mdbreact";

const PublishRight = ({
  history,
  mode,
  publishTypeFunc,
  publishType,
  getLessonsFunc,
  getTopicsFunc,
  getExamsFunc,
  getGamesFunc,
  getDecksFunc,
  getCurriculumsFunc,
  lessons,
  topics,
  exams,
  games,
  decks,
  trading: { curriculums }
}) => {
  const [formData, setFormData] = useState({
    component: ""
  });

  const showLessons = () => {
    setFormData({
      ...formData,
      component: "lessons"
    });
    getLessonsFunc();
  };
  const showTopics = () => {
    setFormData({
      ...formData,
      component: "topics"
    });
    getTopicsFunc();
  };
  const showExams = () => {
    setFormData({
      ...formData,
      component: "exams"
    });
    getExamsFunc();
  };
  const showGames = () => {
    setFormData({
      ...formData,
      component: "games"
    });
    getGamesFunc();
  };
  const showDecks = () => {
    setFormData({
      ...formData,
      component: "decks"
    });
    getDecksFunc();
  };
  const showCurriculum = () => {
    setFormData({
      ...formData,
      component: "curriculums"
    });
    getCurriculumsFunc();
  };

  return (
    <Fragment>
      <MDBRow center>
        <MDBCol>
          <p className="quickSand mt-3 duruSans h5 text-center text-white">
            Select a module
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow center>
        <MDBDropdown>
          <MDBDropdownToggle caret color="info">
            Choose Component
          </MDBDropdownToggle>
          <MDBDropdownMenu color="info" basic>
            <MDBDropdownItem
              onClick={showLessons}
              // onClick={() => publishTypeFunc("lesson")}
              className="quickSand"
            >
              Lessons
            </MDBDropdownItem>
            <MDBDropdownItem onClick={showTopics} className="quickSand">
              Topics
            </MDBDropdownItem>
            <MDBDropdownItem onClick={showExams} className="quickSand">
              Exams
            </MDBDropdownItem>
            <MDBDropdownItem onClick={showGames} className="quickSand">
              Games
            </MDBDropdownItem>
            <MDBDropdownItem onClick={showDecks} className="quickSand">
              Decks
            </MDBDropdownItem>
            <MDBDropdownItem onClick={showCurriculum} className="quickSand">
              Curriculum
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBRow>
      {formData.component === "lessons" ? (
        <div className={"mt-3"}>
          <MDBRow center>
            <p className="h5 mx-1 text-info quickSand">Choose a Lesson:</p>
          </MDBRow>
          <MDBCol size="12">
            <MDBScrollbar>
              <div style={{ height: "20rem" }}>
                {orderBy(lessons, [v => v.title], ["asc"]).map(
                  (item, index) => {
                    return (
                      <p
                        key={item._id}
                        style={{ cursor: "pointer" }}
                        className="text-white quickSand text-center"
                        onClick={() => publishTypeFunc("lesson", item)}
                      >
                        {item.title}
                      </p>
                    );
                  }
                )}
              </div>
            </MDBScrollbar>
          </MDBCol>
        </div>
      ) : null}

      {formData.component === "topics" ? (
        <div className={"mt-3"}>
          <MDBRow center>
            <p className="h5 mx-1 text-info quickSand">Choose a Topic:</p>
          </MDBRow>
          <MDBCol size="12">
            <MDBScrollbar>
              <div style={{ height: "20rem" }}>
                {orderBy(topics, [v => v.title], ["asc"]).map((item, index) => {
                  return (
                    <p
                      key={item._id}
                      style={{ cursor: "pointer" }}
                      className="text-white text-center quickSand"
                      onClick={() => publishTypeFunc("topic", item)}
                    >
                      {item.title}
                    </p>
                  );
                })}
              </div>
            </MDBScrollbar>
          </MDBCol>
        </div>
      ) : null}

      {formData.component === "curriculums" ? (
        <div className={"mt-3"}>
          <MDBRow center>
            <p className="h5 mx-1 text-info quickSand">Choose a Curriculum:</p>
          </MDBRow>
          <MDBCol size="12">
            <MDBScrollbar>
              <div style={{ height: "20rem" }}>
                {orderBy(curriculums, [v => v.title], ["asc"]).map(
                  (item, index) => {
                    return (
                      <p
                        key={item._id}
                        style={{ cursor: "pointer" }}
                        className="text-white text-center quickSand"
                        onClick={() => publishTypeFunc("curriculum", item)}
                      >
                        {item.title}
                      </p>
                    );
                  }
                )}
              </div>
            </MDBScrollbar>
          </MDBCol>
        </div>
      ) : null}

      {formData.component === "exams" ? (
        <div className={"mt-3"}>
          <MDBRow center>
            <p className="h5 mx-1 text-info quickSand">Choose an Exam:</p>
          </MDBRow>
          <MDBCol size="12">
            <MDBScrollbar>
              <div style={{ height: "20rem" }}>
                {orderBy(exams, [v => v.name], ["asc"]).map((item, index) => {
                  return (
                    <p
                      key={item._id}
                      style={{ cursor: "pointer" }}
                      className="text-white text-center quickSand"
                      onClick={() => publishTypeFunc("exam", item)}
                    >
                      {item.name}
                    </p>
                  );
                })}
              </div>
            </MDBScrollbar>
          </MDBCol>
        </div>
      ) : null}

      {formData.component === "games" ? (
        <div className={"mt-3"}>
          <MDBRow center>
            <p className="h5 mx-1 text-info quickSand">Choose a Game:</p>
          </MDBRow>
          <MDBCol size="12">
            <MDBScrollbar>
              <div style={{ height: "20rem" }}>
                {orderBy(games, [v => v.title], ["asc"]).map((item, index) => {
                  return (
                    <p
                      key={item._id}
                      style={{ cursor: "pointer" }}
                      className="text-white text-center quickSand"
                      onClick={() => publishTypeFunc("game", item)}
                    >
                      {item.title}
                    </p>
                  );
                })}
              </div>
            </MDBScrollbar>
          </MDBCol>
        </div>
      ) : null}

      {formData.component === "decks" ? (
        <div className={"mt-3"}>
          <MDBRow center>
            <p className="h5 mx-1 text-info quickSand">Choose a Deck:</p>
          </MDBRow>
          <MDBCol size="12">
            <MDBScrollbar>
              <div style={{ height: "20rem" }}>
                {orderBy(decks, [v => v.title], ["asc"]).map((item, index) => {
                  return (
                    <p
                      key={item._id}
                      style={{ cursor: "pointer" }}
                      className="text-white text-center quickSand"
                      onClick={() => publishTypeFunc("deck", item)}
                    >
                      {item.name}
                    </p>
                  );
                })}
              </div>
            </MDBScrollbar>
          </MDBCol>
        </div>
      ) : null}
    </Fragment>
  );
};

PublishRight.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(connect(mapStateToProps, {})(PublishRight));
