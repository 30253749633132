import React, { Fragment, useState } from "react";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import {
  downloadCurriculum,
  ratingCurriculum,
  setLoadingButtonDownload,
  setLoadingButtonRating
} from "../../../actions/trading";
import sum from "lodash/sum";

import Rating from "react-rating";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { MDBBtn, MDBRow, MDBIcon } from "mdbreact";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from "react-share";

const SeriesView = ({
  history,
  match,
  mode,
  downloadCurriculum,
  ratingCurriculum,
  setLoadingButtonDownload,
  setLoadingButtonRating,
  trading: { publishedCurriculum, loadingButtonDownload, loadingButtonRating },
  auth: { user }
}) => {
  const [formData, setFormData] = useState({
    date: "",
    ratingValue: ""
  });

  const handleClick = value => {
    setFormData({ ...formData, ratingValue: value });
  };

  const ratingCurriculumFunc = () => {
    const find = publishedCurriculum.rating.find(
      item => item.userId === user._id
    );
    if (!find) {
      setLoadingButtonRating(true);
      ratingCurriculum({
        value: formData.ratingValue,
        curriculumId: publishedCurriculum._id
      });
    }
  };

  const hideButton = () => {
    const find = publishedCurriculum.rating.find(
      item => item.userId === user._id
    );
    if (formData.ratingValue && !find) {
      return true;
    } else {
      return false;
    }
  };

  const findRating = () => {
    const find = publishedCurriculum.rating.find(
      item => item.userId === user._id
    );

    if (publishedCurriculum.userTutor._id === user._id) {
      return true;
    } else if (find) {
      return true;
    } else {
      return false;
    }
  };

  const initialRating = () => {
    const find = publishedCurriculum.rating.find(
      item => item.userId === user._id
    );

    if (find) {
      return find.value;
    } else {
      return formData.ratingValue;
    }
  };

  const countRatingStar = () => {
    const newArray = publishedCurriculum.rating.map((item, index) => {
      return item.value;
    });

    let summation = sum(newArray);
    summation = summation / publishedCurriculum.rating.length;
    return Math.floor(summation);
  };

  const countRating = () => {
    return publishedCurriculum.rating.length;
  };

  const downloadCurriculumFunc = () => {
    setLoadingButtonDownload(true);
    downloadCurriculum({
      tradingId: match.params.id,
      date: moment(new Date()).valueOf()
    });
  };

  // Share published curriculum to social media

  const shareUrl = `topscholar.io/trading/curriculum/${publishedCurriculum._id}`;
  const title = "Check out this curriculum published on TopScholar!";
  const title2 = `Check out this curriculum published on TopScholar! topscholar.io/trading/curriculum/${match.params.id}`;
  const twitterAccount = "Topscholarapp";

  return (
    <Fragment>
      <MDBRow className="mt-3" center>
        <p className="text-white h4 quickSand">Versions</p>
      </MDBRow>

      <MDBRow center>
        <MDBBtn
          onClick={downloadCurriculumFunc}
          color="pink"
          disabled={loadingButtonDownload}
        >
          {loadingButtonDownload === true && (
            <div className="spinner-grow spinner-grow-sm" />
          )}
          Download Curriculum
        </MDBBtn>
      </MDBRow>
      <MDBRow center>
        <p className="text-white quickSand">
          {" "}
          Downloads: {publishedCurriculum.downloads}
        </p>
      </MDBRow>

      <MDBRow center>
        <Rating
          readonly={true}
          emptySymbol="far fa-star fa-2x"
          fullSymbol="fas fa-star fa-2x pinkColor "
          initialRating={countRatingStar()}
        />
      </MDBRow>
      <MDBRow center>
        <p className="text-white quickSand">Ratings: {countRating()}</p>
      </MDBRow>
      <MDBRow className="mt-2" center>
        <Rating
          readonly={findRating()}
          initialRating={initialRating()}
          emptySymbol="far fa-star fa-2x"
          fullSymbol="fas fa-star fa-2x pinkColor "
          onClick={handleClick}
        />
      </MDBRow>
      {hideButton() ? (
        <MDBRow center my-2>
          <MDBBtn
            onClick={ratingCurriculumFunc}
            size="sm"
            color="red"
            disabled={loadingButtonRating}
          >
            {loadingButtonRating === true && (
              <div className="spinner-grow spinner-grow-sm" />
            )}
            Submit Rating
          </MDBBtn>
        </MDBRow>
      ) : null}
      <MDBRow center className="mb-1">
        <p className="text-white quickSand">Your rating</p>
      </MDBRow>
      <MDBRow center className="ml-3">
        <div className="mr-3 growMessageTrading" style={{ cursor: "pointer" }}>
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>
        </div>
        <div className="mr-3 growMessageTrading" style={{ cursor: "pointer" }}>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
        </div>
        <div className="mr-4 growMessageTrading" style={{ cursor: "pointer" }}>
          <TwitterShareButton
            url={shareUrl}
            title={title2}
            via={twitterAccount}
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
        </div>
        <div className="mt-1">
          <MDBIcon icon="share" className="shareArrowColor shareIconTrading" />
        </div>
      </MDBRow>
    </Fragment>
  );
};

SeriesView.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    downloadCurriculum,
    ratingCurriculum,
    setLoadingButtonDownload,
    setLoadingButtonRating
  })(SeriesView)
);
