import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import lessons from "./lessons";
import games from "./games";
import students from "./students";
import groups from "./groups";
import stripe from "./stripe";
import dashboard from "./dashboard";
import flashcards from "./flashcards";
import canvas from "./canvas";
import customizables from "./customizables";
import evaluations from "./evaluations";
import trading from "./trading";
import curriculum from "./curriculum";
import payment from "./payment";
import publicpage from "./publicpage";
import notification from "./notification";

export default combineReducers({
  alert,
  auth,
  lessons,
  games,
  students,
  groups,
  stripe,
  dashboard,
  flashcards,
  canvas,
  customizables,
  evaluations,
  trading,
  curriculum,
  payment,
  publicpage,
  notification
});
