import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { activateportal, hasStudentProfileFunc } from "../../actions/students";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import {
  MDBJumbotron,
  MDBBtn,
  MDBInput,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCardTitle,
  MDBScrollbar
} from "mdbreact";

const Register = ({
  hasStudentProfileFunc,
  setAlert,
  activateportal,
  history,
  match,
  auth: { user },
  students: { hasStudentProfile }
}) => {
  const [formData, setFormData] = useState({
    password: "",
    password2: "",
    checked: false
  });

  const { t, i18n } = useTranslation();

  const { password, password2 } = formData;
  const id = match.params.id;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert(t("studentRegister.1"), "error");
    } else if (formData.checked === false) {
      setAlert(t("terms.39"), "error");
    } else {
      activateportal(
        { id: id, password: formData.password, language: i18n.language },
        history
      );
    }
  };

  useEffect(() => {
    if (id) {
      hasStudentProfileFunc({ id: id });
    }
  }, [hasStudentProfileFunc, id]);

  useEffect(() => {
    if (hasStudentProfile === true) {
      history.push("/connect/login");
    }
  }, [hasStudentProfile, history]);

  const onChangeCheck = () => {
    setFormData({
      ...formData,
      checked: !formData.checked
    });
  };

  return (
    hasStudentProfile === false && (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Topscholar: Student Register</title>

          <meta name="robots" content="noindex" />
        </Helmet>
        <MDBContainer className="mt-5 text-center primary">
          <MDBRow center>
            <MDBCol md="8">
              <MDBJumbotron className="mt-5">
                <MDBCardBody>
                  <MDBCardTitle className="h2 quickSand">
                    {t("studentRegister.2")}
                  </MDBCardTitle>

                  <form onSubmit={e => onSubmit(e)}>
                    <MDBInput
                      label={t("studentRegister.3")}
                      type="password"
                      name="password"
                      value={password}
                      onChange={e => onChange(e)}
                    />

                    <MDBInput
                      label={t("studentRegister.4")}
                      type="password"
                      name="password2"
                      value={password2}
                      onChange={e => onChange(e)}
                    />
                    <div className="mb-4" style={{ height: "25rem" }}>
                      <MDBScrollbar>
                        <p className="font-weight-bold monserratFont h5 mb-3 cookieMarginsMobile">
                          {t("terms.1")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.2")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.3")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.4")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.5")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.6")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.7")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.8")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.9")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.10")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.11")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.12")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.13")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.14")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.15")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.16")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.17")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.18")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.19")}{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={"https://stripe.com/payment-terms/legal"}
                          >
                            stripe.com/payment-terms/legal.
                          </a>
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.20")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.21")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.22")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.23")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.24")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.25")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.26")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.27")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.28")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.29")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.30")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.31")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.32")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.33")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.34")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.35")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("terms.36")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.37")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("terms.38")}
                        </p>
                        <p className="font-weight-bold h5 monserratFont mb-3 cookieMarginsMobile">
                          {t("findtutorsterms.1")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("findtutorsterms.2")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("findtutorsterms.3")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("findtutorsterms.4")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("findtutorsterms.5")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("findtutorsterms.6")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("findtutorsterms.7")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("findtutorsterms.8")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("findtutorsterms.9")}
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("findtutorsterms.10")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("findtutorsterms.11")}{" "}
                          <a href="https://stripe.com/docs/disputes/connect">
                            {" "}
                            stripe.com/docs/disputes/connect
                          </a>
                        </p>
                        <p className="quickSand cookieFont cookieMarginsMobile">
                          {t("findtutorsterms.12")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("findtutorsterms.13")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("findtutorsterms.14")}
                        </p>
                        <p className="quickSand  cookieMarginsMobile justify-content-start privacyIndent">
                          {t("findtutorsterms.15")}
                        </p>
                      </MDBScrollbar>
                    </div>

                    <MDBInput
                      onChange={onChangeCheck}
                      type="checkbox"
                      checked={formData.checked}
                      id="1"
                      required
                      label={t("terms.39")}
                      labelClass="font-weight-bold"
                    ></MDBInput>

                    <MDBBtn
                      outline
                      color="primary"
                      type="submit"
                      value="Register"
                    >
                      {t("studentRegister.5")}
                    </MDBBtn>
                  </form>
                </MDBCardBody>
              </MDBJumbotron>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Fragment>
    )
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  activateportal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  students: state.students
});

export default connect(mapStateToProps, {
  setAlert,
  activateportal,
  hasStudentProfileFunc
})(withRouter(Register));
