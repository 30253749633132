import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../../lessonPlans/lessons.css";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import stripHtml from "string-strip-html";
import { Helmet } from "react-helmet";

import {
  MDBDataTable,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBBtn,
  MDBModal,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBPagination,
  MDBPageItem,
  MDBPageNav
} from "mdbreact";
import Spinner from "../../layout/Spinner";
import { connect } from "react-redux";
import { getStudentLessons } from "../../../actions/students";
import moment from "moment-timezone";
import urlParser from "js-video-url-parser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import cloudinary from "cloudinary-core";
var cl = cloudinary.Cloudinary.new({ cloud_name: "unnamed" });

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
const sanitizeHtml = require("sanitize-html");

const currentTimezone = moment.tz.guess() || "America/New_York";

const LessonData = ({
  getStudentLessons,
  students: { lessons, loading, user, numberOfPagesLessons, pageNumberLessons }
}) => {
  const [formData, setFormData] = useState({
    dataLessons: {
      columns: [
        {
          label: [
            <Fragment key={1}>
              <p className="quickSand font-weight-bold tableFont">
                {"Lesson Title"}
              </p>
            </Fragment>
          ],
          field: "title",
          sort: "asc",
          width: 150
        },
        {
          label: [
            <p key={2} className="quickSand font-weight-bold tableFont">
              {"Class"}
            </p>
          ],
          field: "class",
          sort: "asc",
          width: 270
        },
        {
          label: [
            <p key={3} className="quickSand font-weight-bold tableFont">
              {"Date (YYYY MM DD)"}
            </p>
          ],
          field: "date",
          sort: "asc",
          width: 150
        }
      ],
      rows: null
    },

    modalSeeLesson: false,
    modalSeeTopic: false,
    individualLesson: {
      title: "",
      comments: "",
      objective: "",
      date: "",
      groupIds: {
        name: ""
      },
      start: "",
      topicIds: []
    },
    topics: [],
    individualTopic: {
      title: "",
      text: "",
      level: "",
      vocabulary: [],
      grammar: [],
      youtube: [],
      links: [],
      documents: []
    }
  });
  const { t, i18n } = useTranslation();

  let commentsReplace = sanitizeHtml(formData.individualLesson.comments, {
    transformTags: {
      br: "",
      ol: "ul"
    }
  });

  const objectiveComment = htmlToPdfmake(
    `
  <p class="ptag"><strong>Lesson Objective: </strong> ${stripHtml(
    formData.individualLesson.objective
  )} </p>
  
  <p class="plabel">Lesson Comments: </p>
  <div class="ptag">
  ${commentsReplace}
  </div>
  `,
    {
      defaultStyles: {
        // change the default styles
        li: ""
      }
    }
  );

  const topicArrayHtml = formData.individualLesson.topicIds.map(
    (item, index) => {
      const commaVocabulary = item.vocabulary.join(", ");
      const commaGrammar = item.grammar.join(", ");
      let replaceText = sanitizeHtml(item.text, {
        transformTags: {
          br: "",
          ol: "ul"
        }
      });
      return htmlToPdfmake(
        `
  <p class="topicTitle">
    ${index + 1}. ${item.title.charAt(0).toUpperCase() + item.title.slice(1)}
    </p>
    <div class="lessonStyle">
    <p>Notes / Explanation: ${replaceText}</p>
    </div>
    <p class="lessonStyle">Vocabulary Words: ${commaVocabulary}</p>
    <p class="lessonStyle">Grammar: ${commaGrammar}</p>
    `,
        {
          defaultStyles: {
            // change the default styles
            li: ""
          }
        }
      );
    }
  );

  const getpdf = () => {
    // const topicArray = formData.individualLesson.topicIds.map((item, index) => {
    //   const commaVocabulary = item.vocabulary.join(", ");
    //   const commaGrammar = item.grammar.join(", ");
    //   return {
    //     columns: [
    //       {
    //         stack: [
    //           {
    //             text: `${index + 1}. ${item.title.charAt(0).toUpperCase() +
    //               item.title.slice(1)}`,
    //             style: "topicTitle"
    //           },
    //           {
    //             text: `Notes / Explanation: ${stripHtml(item.text)}`,
    //             style: "lessonStyle"
    //           },
    //           {
    //             text: `Vocabulary Words: ${commaVocabulary}`,
    //             style: "lessonStyle"
    //           },
    //           {
    //             text: `Grammar: ${commaGrammar}`,
    //             style: "lessonStyle"
    //           }
    //         ],
    //         style: "margin"
    //       }
    //     ]
    //   };
    // });
    const docDefinition = {
      footer: function(currentPage, pageCount) {
        return [
          {
            text: ` ${currentPage.toString()} of ${pageCount}`,
            style: "footer"
          }
        ];
      },
      header: function(currentPage, pageCount, pageSize) {
        return [
          {
            text:
              formData.individualLesson.title.charAt(0).toUpperCase() +
              formData.individualLesson.title.slice(1),
            style: "header"
          }
        ];
      },
      content: [
        {
          columns: [
            {
              stack: [
                {
                  text: [
                    { text: "Class:", style: "label" },
                    ` ${
                      formData.individualLesson.groupIds
                        ? formData.individualLesson.groupIds.name
                            .charAt(0)
                            .toUpperCase() +
                          formData.individualLesson.groupIds.name.slice(1)
                        : formData.individualLesson.student
                        ? formData.individualLesson.student.name
                            .charAt(0)
                            .toUpperCase() +
                          formData.individualLesson.student.name.slice(1)
                        : "Not Assigned"
                    }`
                  ],
                  style: "lessonStyleDate"
                },
                {
                  text: [
                    { text: "Lesson Title:", style: "label" },
                    ` ${formData.individualLesson.title
                      .charAt(0)
                      .toUpperCase() +
                      formData.individualLesson.title.slice(1)}`
                  ],
                  style: "lessonStyleDate"
                }
              ],
              style: "margin"
            },
            {
              stack: [
                {
                  text: [
                    {
                      text: "Start date: ",
                      style: "label"
                    },
                    ` ${
                      formData.individualLesson.dateBoolean === true
                        ? moment(formData.individualLesson.start, "x")
                            .tz(currentTimezone)
                            .format("llll")
                        : "No Date"
                    }`
                  ],
                  style: "lessonStyleDate"
                },
                {
                  text: [
                    {
                      text: "End date:",
                      style: "label"
                    },
                    ` ${
                      formData.individualLesson.dateBoolean === true
                        ? moment(formData.individualLesson.end, "x")
                            .tz(currentTimezone)
                            .format("llll")
                        : "No Date"
                    }`
                  ],
                  style: "lessonStyleDate"
                }
              ],
              style: "margin"
            }
          ]
        },
        {
          columns: [
            // {
            //   stack: [
            //     {
            //       text: [
            //         { text: `Lesson Objective:`, style: "label" },
            //         ` ${formData.individualLesson.objective}`
            //       ],
            //       style: "lessonStyle"
            //     },
            //     {
            //       text: [
            //         { text: `Lesson Comments:`, style: "label" },
            //         ` ${stripHtml(formData.individualLesson.comments)}`
            //       ],
            //       style: "lessonStyle"
            //     }
            //   ],
            //   style: "margin"
            // }
            objectiveComment
          ]
        },

        topicArrayHtml
      ],

      styles: {
        header: {
          fontSize: 15,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        footer: {
          fontSize: 10,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        margin: {
          margin: [0, 10]
        },
        margin2: {
          margin: [0, 0]
        },
        lessonStyle: {
          margin: [0, 5],
          fontSize: 10
        },
        lessonStyleDate: {
          margin: [0, 5],
          fontSize: 9
        },
        topicHeader: {
          margin: [0, 5],
          fontSize: 12,
          bold: true
        },
        topicTitle: {
          margin: [0, 5],
          fontSize: 10,
          bold: true,
          alignment: "center"
        },
        label: {
          bold: true
        },

        ptag: {
          fontSize: 10
        },
        plabel: {
          bold: true,
          fontSize: 10
        }
      },
      pageSize: "A5"
    };

    pdfMake.createPdf(docDefinition).open();
  };
  const downloadpdf = () => {
    const topicArray = formData.individualLesson.topicIds.map((item, index) => {
      const commaVocabulary = item.vocabulary.join(", ");
      const commaGrammar = item.grammar.join(", ");
      return {
        columns: [
          {
            stack: [
              {
                text: `${index + 1}. ${item.title.charAt(0).toUpperCase() +
                  item.title.slice(1)}`,
                style: "topicTitle"
              },
              {
                text: `Notes / Explanation: ${stripHtml(item.text)}`,
                style: "lessonStyle"
              },
              {
                text: `Vocabulary Words: ${commaVocabulary}`,
                style: "lessonStyle"
              },
              {
                text: `Grammar: ${commaGrammar}`,
                style: "lessonStyle"
              }
            ],
            style: "margin"
          }
        ]
      };
    });
    const docDefinition = {
      footer: function(currentPage, pageCount) {
        return [
          {
            text: ` ${currentPage.toString()} of ${pageCount}`,
            style: "footer"
          }
        ];
      },
      header: function(currentPage, pageCount, pageSize) {
        return [
          {
            text:
              formData.individualLesson.title.charAt(0).toUpperCase() +
              formData.individualLesson.title.slice(1),
            style: "header"
          }
        ];
      },
      content: [
        {
          columns: [
            {
              stack: [
                {
                  text: [
                    { text: "Class:", style: "label" },
                    ` ${
                      formData.individualLesson.groupIds
                        ? formData.individualLesson.groupIds.name
                            .charAt(0)
                            .toUpperCase() +
                          formData.individualLesson.groupIds.name.slice(1)
                        : formData.individualLesson.student
                        ? formData.individualLesson.student.name
                            .charAt(0)
                            .toUpperCase() +
                          formData.individualLesson.student.name.slice(1)
                        : "Not Assigned"
                    }`
                  ],
                  style: "lessonStyleDate"
                },
                {
                  text: [
                    { text: "Lesson Title:", style: "label" },
                    ` ${formData.individualLesson.title
                      .charAt(0)
                      .toUpperCase() +
                      formData.individualLesson.title.slice(1)}`
                  ],
                  style: "lessonStyleDate"
                }
              ],
              style: "margin"
            },
            {
              stack: [
                {
                  text: [
                    {
                      text: "Start date: ",
                      style: "label"
                    },
                    `${
                      formData.individualLesson.dateBoolean === true
                        ? moment(formData.individualLesson.start, "x")
                            .tz(currentTimezone)
                            .format("llll")
                        : "No Date"
                    }`
                  ],
                  style: "lessonStyleDate"
                },
                {
                  text: [
                    {
                      text: "End date:",
                      style: "label"
                    },
                    ` ${
                      formData.individualLesson.dateBoolean === true
                        ? moment(formData.individualLesson.end, "x")
                            .tz(currentTimezone)
                            .format("llll")
                        : "No Date"
                    }`
                  ],
                  style: "lessonStyleDate"
                }
              ],
              style: "margin"
            }
          ]
        },
        {
          columns: [
            {
              stack: [
                {
                  text: [
                    { text: `Lesson Objective:`, style: "label" },
                    ` ${formData.individualLesson.objective}`
                  ],
                  style: "lessonStyle"
                },
                {
                  text: [
                    { text: `Lesson Comments:`, style: "label" },
                    ` ${stripHtml(formData.individualLesson.comments)}`
                  ],
                  style: "lessonStyle"
                }
              ],
              style: "margin"
            }
          ]
        },

        topicArray
      ],

      styles: {
        header: {
          fontSize: 15,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        footer: {
          fontSize: 10,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        margin: {
          margin: [0, 10]
        },
        margin2: {
          margin: [0, 0]
        },
        lessonStyle: {
          margin: [0, 5],
          fontSize: 10
        },
        lessonStyleDate: {
          margin: [0, 5],
          fontSize: 9
        },
        topicHeader: {
          margin: [0, 5],
          fontSize: 12,
          bold: true
        },
        topicTitle: {
          margin: [0, 5],
          fontSize: 10,
          bold: true,
          alignment: "center"
        },
        label: {
          bold: true
        }
      },
      pageSize: "A5"
    };
    pdfMake.createPdf(docDefinition).download();
  };

  useEffect(() => {
    getStudentLessons({ pageNumberLessons: "first" });
  }, [getStudentLessons]);

  var styles = {
    cursor: "pointer"
  };

  if (user.view) {
    if (user.view.lessonPlans === false) styles = {};
  }

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        dataLessons: {
          ...prevState.dataLessons,
          columns: [
            {
              label: [
                <Fragment key={1}>
                  <p className="quickSand font-weight-bold tableFont">
                    {t("studentLessons.4")}
                  </p>
                </Fragment>
              ],
              field: "title",
              sort: "asc",
              width: 150
            },
            {
              label: [
                <p key={2} className="quickSand font-weight-bold tableFont">
                  {t("studentLessons.5")}
                </p>
              ],
              field: "class",
              sort: "asc",
              width: 270
            },
            {
              label: [
                <p key={3} className="quickSand font-weight-bold tableFont">
                  {t("studentLessons.6")}
                </p>
              ],
              field: "date",
              sort: "asc",
              width: 150
            }
          ]
        }
      };
    });
  }, [i18n.language, t]);

  useEffect(() => {
    const lessonClick = item => {
      getStudentLessons({ pageNumberLessons: "first" });
      setFormData(prevState => {
        return {
          ...prevState,
          modalCreateLesson: false,
          modalSeeLesson: true,
          lessonTopic: true,
          modalCreateTopic: false,
          modalSeeTopic: false,
          individualLesson: item
        };
      });
    };
    if (lessons === "No Results") {
      setFormData(prevState => {
        return {
          ...prevState,
          dataLessons: {
            ...prevState.dataLessons,
            rows: []
          }
        };
      });
    } else if (lessons) {
      const newLessonArray = lessons.map((item, index) => {
        return {
          title: item.title,
          class: item.groupIds
            ? item.groupIds.name.charAt(0).toUpperCase() +
              item.groupIds.name.slice(1)
            : item.student
            ? item.student.name.charAt(0).toUpperCase() +
              item.student.name.slice(1)
            : "Not Assigned",

          date:
            item.dateBoolean === false
              ? null
              : moment(item.start, "x")
                  .tz(currentTimezone)
                  .format("YYYY MM DD hh:mm a"),
          clickEvent: () => {
            lessonClick(item);
          }
        };
      });
      setFormData(prevState => {
        return {
          ...prevState,
          dataLessons: {
            ...prevState.dataLessons,
            rows: newLessonArray
          }
        };
      });
    }
  }, [lessons, getStudentLessons]);

  const lessonClickClose = item => {
    getStudentLessons({ pageNumberLessons: "first" });

    setFormData({
      ...formData,
      modalSeeLesson: false,
      individualLesson: {
        title: "",
        comments: "",
        objective: "",
        date: "",
        groupIds: {
          name: ""
        },
        start: "",
        topicIds: []
      }
    });
  };

  // Pagination Student Lessons

  const isActive1 = page => {
    if (pageNumberLessons === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationLessons = () => {
    getStudentLessons({ pageNumberLessons: "first" });
  };

  const previousPaginationLessons = () => {
    getStudentLessons({ pageNumberLessons: pageNumberLessons - 1 });
  };

  const currentPaginationLessons = index => {
    getStudentLessons({ pageNumberLessons: index + 1 });
  };

  const nextPaginationLessons = () => {
    getStudentLessons({ pageNumberLessons: pageNumberLessons + 1 });
  };

  const lastPaginationLessons = () => {
    getStudentLessons({ pageNumberLessons: "last" });
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Student Lessons</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className=" justify-content-center align-items-center mt-5 mb-5">
        <MDBRow className="pt-5 mt-3" center>
          <MDBCard narrow>
            <MDBCardHeader className="view view-cascade  primary-color d-flex justify-content-between align-items-center py-2 mx-4 mb-1 ">
              <MDBCol className="text-center">
                <p className="h5 quickSand white-text mx-2">
                  {t("studentLessons.1")}
                </p>
              </MDBCol>
            </MDBCardHeader>
            {user.view && user.view.lessons === false ? (
              <MDBCardBody cascade>
                <p className="quickSand black-text h5">
                  {t("studentLessons.2")}
                </p>
              </MDBCardBody>
            ) : (
              <Fragment>
                <div className="mx-4">
                  <MDBRow className="mt-5" center>
                    <p className="quickSand font-weight-bold">
                      <MDBIcon far icon="calendar-alt" />
                      {` Timezone: ${currentTimezone}`}
                    </p>
                  </MDBRow>
                  <div className="tableLesson" style={{ minHeight: "25rem" }}>
                    <MDBDataTable
                      paging={false}
                      searching={false}
                      sortable={false}
                      btn
                      small
                      fixed
                      className="text-center quickSand tableFont2"
                      info={false}
                      hover
                      data={formData.dataLessons}
                      bordered
                      theadColor="blue lighten-4"
                      style={styles}
                      noRecordsFoundLabel={t("studentLessons.3")}
                    />
                  </div>

                  {/* Pagination Student Lessons Medium Size*/}

                  <div className="middleVertical mt-4 mb-3 paginationLessonsMedium">
                    <MDBRow>
                      <MDBCol>
                        <MDBPagination circle>
                          <MDBPageItem onClick={firstPaginationLessons}>
                            <MDBPageNav className="page-link">
                              <span>First</span>
                            </MDBPageNav>
                          </MDBPageItem>
                          <MDBPageItem onClick={previousPaginationLessons}>
                            <MDBPageNav
                              className="page-link"
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span className="sr-only">Previous</span>
                            </MDBPageNav>
                          </MDBPageItem>
                          {[...Array(numberOfPagesLessons)].map(
                            (item, index) => {
                              if (index + 1 === pageNumberLessons) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    active={isActive1(index + 1)}
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons - 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons - 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons - 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons + 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons + 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons + 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                          <MDBPageItem onClick={nextPaginationLessons}>
                            <MDBPageNav className="page-link">
                              &raquo;
                            </MDBPageNav>
                          </MDBPageItem>
                          <MDBPageItem onClick={lastPaginationLessons}>
                            <MDBPageNav className="page-link">Last</MDBPageNav>
                          </MDBPageItem>
                        </MDBPagination>
                      </MDBCol>
                    </MDBRow>
                  </div>

                  {/* Pagination Lessons  Small Size*/}

                  <div className="middleVertical mt-4 mb-3 paginationLessonsSmall">
                    <MDBRow>
                      <MDBCol>
                        <MDBPagination circle size="sm">
                          <MDBPageItem onClick={firstPaginationLessons}>
                            <MDBPageNav className="page-link">
                              <span>First</span>
                            </MDBPageNav>
                          </MDBPageItem>
                          <MDBPageItem onClick={previousPaginationLessons}>
                            <MDBPageNav
                              className="page-link"
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span className="sr-only">Previous</span>
                            </MDBPageNav>
                          </MDBPageItem>
                          {[...Array(numberOfPagesLessons)].map(
                            (item, index) => {
                              if (index + 1 === pageNumberLessons) {
                                return (
                                  <MDBPageItem
                                    key={index}
                                    active={isActive1(index + 1)}
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons - 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons - 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons - 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons + 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons + 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumberLessons + 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationLessons(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                          <MDBPageItem onClick={nextPaginationLessons}>
                            <MDBPageNav className="page-link">
                              &raquo;
                            </MDBPageNav>
                          </MDBPageItem>
                          <MDBPageItem onClick={lastPaginationLessons}>
                            <MDBPageNav className="page-link">Last</MDBPageNav>
                          </MDBPageItem>
                        </MDBPagination>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </Fragment>
            )}
          </MDBCard>

          <MDBContainer className="modalBottomMargin rounded backgroundLesson2">
            <div className="lessonsModal">
              <MDBModal isOpen={formData.modalSeeLesson} className="modal-full">
                <div className="modal-full modal-content ">
                  <MDBRow center className="middleVertical py-3">
                    <p className="text-center quickSand text-white h2 text-center">
                      {formData.individualLesson.title.charAt(0).toUpperCase() +
                        formData.individualLesson.title.slice(1)}
                    </p>
                  </MDBRow>
                  <MDBContainer className="backgroundLesson2">
                    <MDBRow className="pt-5">
                      <MDBCol md="6" className="mr-auto text-center">
                        <p className="h5 pb-1 montserratFont">
                          Class:{" "}
                          <span className="lessonFontSize4">
                            <strong>
                              {formData.individualLesson.groupIds
                                ? formData.individualLesson.groupIds.name
                                    .charAt(0)
                                    .toUpperCase() +
                                  formData.individualLesson.groupIds.name.slice(
                                    1
                                  )
                                : formData.individualLesson.student
                                ? formData.individualLesson.student.name
                                    .charAt(0)
                                    .toUpperCase() +
                                  formData.individualLesson.student.name.slice(
                                    1
                                  )
                                : "Not Assigned"}
                            </strong>
                          </span>
                        </p>
                      </MDBCol>
                      {formData.individualLesson.dateBoolean === true ? (
                        <MDBCol md="6" className="text-center">
                          <p className="h5 pb-1 montserratFont">
                            Start Date:{" "}
                            <span className="lessonFontSize4">
                              <strong>
                                {moment(formData.individualLesson.start, "x")
                                  .tz(currentTimezone)
                                  .format("llll")}
                              </strong>
                            </span>
                          </p>
                          <p className="h5 pb-1 montserratFont">
                            End Date:{" "}
                            <span className="lessonFontSize4">
                              <strong>
                                {moment(formData.individualLesson.end, "x")
                                  .tz(currentTimezone)
                                  .format("llll")}
                              </strong>
                            </span>
                          </p>
                        </MDBCol>
                      ) : null}
                    </MDBRow>
                    <hr />
                  </MDBContainer>

                  <MDBContainer className="backgroundLesson2 modalBottomMargin">
                    <MDBRow>
                      <MDBCol
                        md="12"
                        className={
                          formData.individualLesson.objective !== ""
                            ? ""
                            : "d-none"
                        }
                      >
                        <p className="montserratFont lessonFontSize pb-1">
                          Objectives
                        </p>
                        <p className="lessonFontSize2 quickSand pb-1">
                          {formData.individualLesson.objective}
                        </p>
                      </MDBCol>
                      <MDBCol
                        md="12"
                        className={
                          formData.individualLesson.comments !== ""
                            ? ""
                            : "d-none"
                        }
                      >
                        <p className="pb-1 montserratFont lessonFontSize">
                          Comments
                        </p>
                        <div
                          className=" lessonFontSize2 ql-editor"
                          id="fixMargin"
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: formData.individualLesson.comments
                            }}
                          ></p>
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <hr />
                    {formData.individualLesson.topicIds.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          <MDBRow className="pt-2 mb-4">
                            <MDBCol md="12" className="mb-1">
                              <p className="text-center h5 montserratFont font-weight-bold">
                                {item.title.charAt(0).toUpperCase() +
                                  item.title.slice(1)}
                              </p>
                              <Fragment>
                                <div
                                  className={item.text !== "" ? "" : "d-none"}
                                >
                                  <p className="h6 pb-1 montserratFont lessonFontSize">
                                    Notes / Explanation
                                  </p>
                                </div>
                                <div
                                  className=" lessonFontSize2 ql-editor"
                                  id="fixMargin"
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.text
                                    }}
                                  ></p>
                                </div>
                              </Fragment>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow
                            className={
                              (item.vocabulary.length !== 0 &&
                                item.grammar.length === 0) ||
                              (item.vocabulary.length === 0 &&
                                item.grammar.length !== 0)
                                ? "align-self-start"
                                : item.vocabulary.length === 0 &&
                                  item.grammar.length === 0
                                ? "d-none"
                                : item.vocabulary.length !== 0 &&
                                  item.grammar.length !== 0
                                ? "middleVertical"
                                : "text-center"
                            }
                          >
                            <MDBCol
                              md="6"
                              className={
                                item.vocabulary.length !== 0 &&
                                item.grammar.length === 0
                                  ? "mb-1"
                                  : item.vocabulary.length !== 0 &&
                                    item.grammar.length !== 0
                                  ? "mb-1 text-center"
                                  : item.vocabulary.length === 0 &&
                                    item.grammar.length === 0
                                  ? "d-none"
                                  : "d-none"
                              }
                            >
                              <p className="pb-1 montserratFont lessonFontSize">
                                Vocabulary Words
                              </p>

                              <ul className="list-inline  quickSand lessonFontSize2 vocabularyCommas">
                                {item.vocabulary.map((item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className="list-inline-item"
                                    >
                                      {item}
                                    </li>
                                  );
                                })}
                              </ul>
                            </MDBCol>
                            <MDBCol
                              md="6"
                              className={
                                item.vocabulary.length === 0 &&
                                item.grammar.length !== 0
                                  ? " text-center"
                                  : item.vocabulary.length !== 0 &&
                                    item.grammar.length !== 0
                                  ? " text-center"
                                  : item.vocabulary.length === 0 &&
                                    item.grammar.length === 0
                                  ? "d-none"
                                  : "d-none"
                              }
                            >
                              <p className="montserratFont lessonFontSize ">
                                Grammar Topics
                              </p>
                              <ul className="list-inline lessonFontSize2 quickSand vocabularyCommas">
                                {item.grammar.map((item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className="list-inline-item"
                                    >
                                      {item}
                                    </li>
                                  );
                                })}
                              </ul>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol
                              md="12"
                              className={
                                item.youtube.length !== 0 ? "mb-4" : "d-none"
                              }
                            >
                              <p className="pb-1 text-center montserratFont lessonFontSize">
                                Youtube Videos
                              </p>
                            </MDBCol>
                            {item.youtube.map((item, index) => {
                              const emb = urlParser.parse(item.url);
                              if (typeof emb === "undefined") {
                                return null;
                              } else {
                                const embUrl = urlParser.create({
                                  videoInfo: emb,
                                  format: "embed"
                                });

                                return (
                                  <MDBCol key={index} md="6" className="mb-4">
                                    <div className="embed-responsive embed-responsive-16by9 mb-1">
                                      <iframe
                                        title="Embeds Page"
                                        className="embed-responsive-item"
                                        src={embUrl}
                                        allowFullScreen
                                      />
                                    </div>
                                  </MDBCol>
                                );
                              }
                            })}
                          </MDBRow>
                          <MDBRow
                            className={
                              (item.links.length !== 0 &&
                                item.documents.length === 0) ||
                              (item.links.length === 0 &&
                                item.documents.length !== 0)
                                ? "align-self-start"
                                : item.links.length === 0 &&
                                  item.documents.length === 0
                                ? "d-none"
                                : item.links.length !== 0 &&
                                  item.documents.length !== 0
                                ? "middleVertical"
                                : "middleVertical"
                            }
                          >
                            <MDBCol
                              md="6"
                              className={
                                item.links.length !== 0 ? "mb-4" : "d-none"
                              }
                            >
                              <Fragment>
                                <p className="text-center pb-1 montserratFont lessonFontSize">
                                  Website Links
                                </p>
                              </Fragment>
                              {item.links.map((item, index) => {
                                const hrefgood = item.url.replace(
                                  /(^\w+:|^)\/\//,
                                  ""
                                );

                                return (
                                  <ul
                                    key={index}
                                    className="fa-ul text-center lessonFontSize2 quickSand"
                                  >
                                    <li className="list-inline-item">
                                      <i className="fa-li fa fa-link" />

                                      <a
                                        href={`http://${hrefgood}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        <span className="lessonFontSize3">
                                          {item.title}
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                );
                              })}
                            </MDBCol>
                            <MDBCol
                              md="6"
                              className={
                                item.links.length === 0 &&
                                item.documents.length !== 0
                                  ? "mb-4 text-center"
                                  : item.links.length !== 0 &&
                                    item.documents.length !== 0
                                  ? "mb-4 text-center"
                                  : item.links.length === 0 &&
                                    item.documents.length === 0
                                  ? "d-none"
                                  : "d-none"
                              }
                            >
                              <Fragment>
                                <p className="montserratFont lessonFontSize text-center pb-1">
                                  Topic document
                                </p>
                              </Fragment>

                              {item.documents.map((item, index) => {
                                if (item.document_type === "video") {
                                  return (
                                    <MDBRow center>
                                      <MDBCol>
                                        <a
                                          key={index}
                                          href={`https://res.cloudinary.com/unnamed/${
                                            item.document_type
                                          }/upload/${
                                            item.document_id
                                          }${item.document_id.slice(-4)}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {" "}
                                          <span className="lessonFontSize3">
                                            {item.title
                                              ? item.title
                                              : " Link to your saved video"}
                                          </span>
                                        </a>
                                      </MDBCol>
                                    </MDBRow>
                                  );
                                } else if (item.document_type === "raw") {
                                  return (
                                    <MDBRow center>
                                      <MDBCol>
                                        <a
                                          key={index}
                                          href={`https://res.cloudinary.com/unnamed/${item.document_type}/upload/${item.document_id}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {" "}
                                          <span className="lessonFontSize3">
                                            {item.title
                                              ? item.title
                                              : "Download your saved file"}
                                          </span>{" "}
                                        </a>
                                      </MDBCol>
                                    </MDBRow>
                                  );
                                } else if (
                                  item.document_id.slice(-3) === "pdf"
                                ) {
                                  return (
                                    <MDBRow center>
                                      <MDBCol>
                                        <a
                                          key={index}
                                          href={`https://res.cloudinary.com/unnamed/${item.document_type}/upload/${item.document_id}.pdf`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {" "}
                                          <span className="lessonFontSize3">
                                            {item.title
                                              ? item.title
                                              : "Download your saved pdf"}
                                          </span>
                                        </a>
                                      </MDBCol>
                                    </MDBRow>
                                  );
                                } else {
                                  return (
                                    <MDBRow center>
                                      <MDBCol>
                                        <a
                                          key={index}
                                          href={cl.url(item.document_id)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {" "}
                                          <span className="lessonFontSize3">
                                            {item.title
                                              ? item.title
                                              : "Link to your saved file"}
                                          </span>{" "}
                                        </a>
                                      </MDBCol>
                                    </MDBRow>
                                  );
                                }
                              })}
                            </MDBCol>
                          </MDBRow>
                        </Fragment>
                      );
                    })}
                  </MDBContainer>
                  <MDBModalFooter className="backgroundLessons">
                    <MDBDropdown>
                      <MDBDropdownToggle caret color="primary">
                        {t("studentLessons.7")}
                      </MDBDropdownToggle>
                      <MDBDropdownMenu basic>
                        <MDBDropdownItem onClick={getpdf}>
                          {t("studentLessons.8")}
                        </MDBDropdownItem>
                        <MDBDropdownItem onClick={downloadpdf}>
                          {t("studentLessons.9")}
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                    <MDBBtn onClick={lessonClickClose} color="secondary">
                      {t("studentLessons.10")}
                    </MDBBtn>
                  </MDBModalFooter>
                </div>
              </MDBModal>
            </div>
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  students: state.students
});

export default withRouter(
  connect(mapStateToProps, {
    getStudentLessons
  })(withRouter(LessonData))
);
