import axios from "axios";
import { setAlert } from "./alert";

import {
  MAKE_EVALUATION,
  EVALUATION_ERROR,
  GET_EVALUATIONS,
  DELETE_EVALUATION,
  UPDATE_EVALUATION,
  SET_LOADING_BUTTON_EVALUATIONS
} from "./types";

// Save evaluation

export const makeEvaluation = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/evaluations", formData, config);

    dispatch({
      type: MAKE_EVALUATION,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: EVALUATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all evaluations
export const getEvaluationList = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post(`/api/evaluations/list`, formData, config);

    dispatch({
      type: GET_EVALUATIONS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: EVALUATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update Evaluation
export const updateEvaluation = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/evaluations/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_EVALUATION,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: EVALUATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// delete canvas

export const deleteEvaluation = id => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.delete(`/api/evaluations/${id}`, config);

    dispatch({
      type: DELETE_EVALUATION,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: EVALUATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Loading Button Evaluations

export const setLoadingButtonEvaluations = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_EVALUATIONS,
    payload: formData
  });
};
