import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import sum from "lodash/sum";
import { withRouter } from "react-router-dom";
import Rating from "react-rating";
import { Scrollbars } from "react-custom-scrollbars";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from "react-share";

import {
  ratingTopic,
  getPublishedTopic,
  setLoadingButtonRating,
  getPublishedEditedTopics
} from "../../../actions/trading";

import {
  MDBBtn,
  MDBRow,
  MDBListGroupItem,
  MDBListGroup,
  MDBIcon
} from "mdbreact";

const TopicView = ({
  history,
  mode,
  match,
  downloadTopicFunc,
  ratingTopic,
  topicContent,
  auth: { user },
  modalUploadEditFunc,
  setLoadingButtonRating,
  getPublishedEditedTopics,
  trading: {
    publishedTopicEdits,
    loadingButtonDownload,
    loadingButtonRating,
    topic,
    publishedTopic
  },
  getPublishedTopic
}) => {
  const [formData, setFormData] = useState({
    ratingValue: "",
    individualTopic: {
      title: "",
      text: "",
      level: "",
      vocabulary: [],
      grammar: [],
      youtube: [],
      links: [],
      documents: [],
      date: "",
      userTutor: {
        name: "",
        _id: ""
      },
      rating: [],
      downloads: ""
    }
  });
  useEffect(() => {
    if (topicContent) {
      setFormData(prevState => {
        return {
          ...prevState,
          individualTopic: {
            ...prevState.individualTopic,
            title: topicContent.title,
            text: topicContent.text,
            level: topicContent.level,
            vocabulary: topicContent.vocabulary,
            grammar: topicContent.grammar,
            youtube: topicContent.youtube,
            links: topicContent.links,
            documents: topicContent.documents,
            date: topicContent.date,
            userTutor: topicContent.userTutor,
            rating: topicContent.rating,
            _id: topicContent._id,
            downloads: topicContent.downloads
          },
          ratingValue: ""
        };
      });
    }
  }, [topicContent]);

  const handleClick = value => {
    setFormData({ ...formData, ratingValue: value });
  };

  const ratingTopicFunc = () => {
    const find = formData.individualTopic.rating.find(
      item => item.userId === user._id
    );
    if (!find) {
      setLoadingButtonRating(true);
      ratingTopic({
        value: formData.ratingValue,
        topicId: formData.individualTopic._id
      });
    }
  };

  const hideButton = () => {
    const find = formData.individualTopic.rating.find(
      item => item.userId === user._id
    );
    if (formData.ratingValue && !find) {
      return true;
    } else {
      return false;
    }
  };

  const findRating = () => {
    const find = formData.individualTopic.rating.find(
      item => item.userId === user._id
    );

    if (formData.individualTopic.userTutor._id === user._id) {
      return true;
    } else if (find) {
      return true;
    } else {
      return false;
    }
  };

  const initialRating = () => {
    const find = formData.individualTopic.rating.find(
      item => item.userId === user._id
    );

    if (find) {
      return find.value;
    } else {
      return formData.ratingValue;
    }
  };

  const countRatingStar = () => {
    const newArray = formData.individualTopic.rating.map((item, index) => {
      return item.value;
    });

    let summation = sum(newArray);
    summation = summation / formData.individualTopic.rating.length;
    return Math.floor(summation);
  };

  const countRating = () => {
    return formData.individualTopic.rating.length;
  };

  useEffect(() => {
    if (topic) {
      getPublishedEditedTopics({ originalId: match.params.id });
    }
  }, [getPublishedEditedTopics, match, topic]);

  // Share published topic to social media

  const shareUrl = `topscholar.io/trading/topic/${formData.individualTopic._id}`;
  const title = "Check out this topic published on TopScholar!";
  const title2 = `Check out this topic published on TopScholar! topscholar.io/trading/topic/${match.params.id}`;
  const twitterAccount = "Topscholarapp";

  return (
    <Fragment>
      <MDBRow className="mt-3" center>
        <p className="text-white h4 quickSand">Versions</p>
      </MDBRow>
      <MDBRow center>
        <MDBListGroup style={{ height: "15rem" }}>
          <MDBListGroupItem
            style={{ minWidth: "16rem", cursor: "pointer" }}
            className=" quickSand info-color-dark
                text-white h4 my-1 z-depth-2"
            onClick={() => getPublishedTopic(match.params.id)}
          >
            <p className="text-center">Original</p>
          </MDBListGroupItem>
          <Scrollbars autoHide autoHideTimeout={2000}>
            {publishedTopicEdits.map((item, index) => {
              return (
                <MDBListGroupItem
                  style={{ minWidth: "14rem", cursor: "pointer" }}
                  className=" quickSand  info-color text-white h6 my-1 z-depth-2"
                  onClick={() => getPublishedTopic(item._id)}
                >
                  <p className="text-center">Edited by {item.userTutor.name}</p>
                </MDBListGroupItem>
              );
            })}
          </Scrollbars>
        </MDBListGroup>
      </MDBRow>

      <hr />
      <MDBRow center className="uploadButtonPadding">
        <MDBBtn
          size="sm"
          onClick={() => modalUploadEditFunc("topics")}
          color="info"
        >
          Upload Edited Version
        </MDBBtn>
        <MDBBtn
          onClick={downloadTopicFunc}
          color="pink"
          disabled={loadingButtonDownload}
        >
          {loadingButtonDownload === true && (
            <div className="spinner-grow spinner-grow-sm" />
          )}
          Download Topic
        </MDBBtn>
      </MDBRow>
      <MDBRow center>
        <p className="text-white quickSand">
          {" "}
          Downloads: {formData.individualTopic.downloads}
        </p>
      </MDBRow>
      <div className={publishedTopic.original === true ? "" : "d-none"}>
        <MDBRow center>
          <Rating
            readonly={true}
            emptySymbol="far fa-star fa-2x"
            fullSymbol="fas fa-star fa-2x pinkColor "
            initialRating={countRatingStar()}
          />
        </MDBRow>
        <MDBRow center>
          <p className="text-white quickSand"> Ratings: {countRating()}</p>
        </MDBRow>
        <MDBRow className="mt-2" center>
          <Rating
            readonly={findRating()}
            initialRating={initialRating()}
            emptySymbol="far fa-star fa-2x"
            fullSymbol="fas fa-star fa-2x pinkColor "
            onClick={handleClick}
          />
        </MDBRow>
        {hideButton() ? (
          <MDBRow center my-2>
            <MDBBtn
              onClick={ratingTopicFunc}
              size="sm"
              color="red"
              disabled={loadingButtonRating}
            >
              {loadingButtonRating === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Submit Rating
            </MDBBtn>
          </MDBRow>
        ) : null}
        <MDBRow center className="mb-1">
          <p className="text-white quickSand">Your rating</p>
        </MDBRow>
      </div>
      <MDBRow center className="ml-3">
        <div className="mr-3 growMessageTrading" style={{ cursor: "pointer" }}>
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>
        </div>
        <div className="mr-3 growMessageTrading" style={{ cursor: "pointer" }}>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
        </div>
        <div className="mr-4 growMessageTrading" style={{ cursor: "pointer" }}>
          <TwitterShareButton
            url={shareUrl}
            title={title2}
            via={twitterAccount}
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
        </div>
        <div className="mt-1">
          <MDBIcon icon="share" className="shareArrowColor shareIconTrading" />
        </div>
      </MDBRow>
    </Fragment>
  );
};

TopicView.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    ratingTopic,
    getPublishedTopic,
    setLoadingButtonRating,
    getPublishedEditedTopics
  })(TopicView)
);
