import React, { Fragment } from "react";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBRow
} from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./customizables.css";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const CustomizablePDF = ({ newComponentArray, newExam: { name } }) => {
  const getPDF = () => {
    const questionArray = newComponentArray.map((item, index) => {
      if (item.type === "multipleChoice")
        return [
          {
            text: `${index + 1}. ${item.question.charAt(0).toUpperCase() +
              item.question.slice(1)}`,
            style: "lessonStyle"
          },
          {
            type: "none",
            ul: [
              {
                text: `a) ${item.choice1}`,
                width: "*",
                alignment: "justify",
                margin: [0, 8]
              },
              {
                text: `b) ${item.choice2}`,
                width: "*",
                alignment: "justify",
                margin: [0, 8]
              },
              {
                text: `c) ${item.choice3}`,
                width: "*",
                alignment: "justify",
                margin: [0, 8]
              },
              {
                text: `d) ${item.choice4}`,
                width: "*",
                alignment: "justify",
                margin: [0, 8]
              }
            ]
          }
        ];
      else if (item.type === "checkAllBoxes")
        return [
          `${index + 1}. ${item.question.charAt(0).toUpperCase() +
            item.question.slice(1)}`,
          {
            margin: [0, 8],
            columns: [
              {
                text: `[  ] ${item.choice1}`,
                width: "*",
                alignment: "center"
              },
              { text: `[  ] ${item.choice2}`, width: "*", alignment: "center" },
              { text: `[  ] ${item.choice3}`, width: "*", alignment: "center" },
              { text: `[  ] ${item.choice4}`, width: "*", alignment: "center" }
            ]
          }
        ];
      else if (item.type === "textArea")
        return [
          `${index + 1}. ${item.question.charAt(0).toUpperCase() +
            item.question.slice(1)}`,
          {
            margin: [0, 8],
            columns: [
              {
                style: "tableExample",
                table: {
                  widths: ["*"],

                  body: [
                    [{ text: "Answer", style: "tableHeader" }],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ]
                  ]
                },
                layout: "lightHorizontalLines"
              }
            ]
          }
        ];
      else if (item.type === "matching")
        return [
          `${index + 1}. ${item.question.charAt(0).toUpperCase() +
            item.question.slice(1)}`,

          {
            margin: [0, 8],
            columns: [
              {
                text: "1. " + item.match1.match1a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              },
              {
                text: "4. " + item.match4.match4a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              }
            ]
          },
          {
            margin: [0, 8],
            columns: [
              {
                text: "2. " + item.match2.match2a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              },
              {
                text: "5. " + item.match5.match5a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              }
            ]
          },
          {
            margin: [0, 8],
            columns: [
              {
                text: "3. " + item.match3.match3a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              },
              {
                text: "6. " + item.match6.match6a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              }
            ]
          },
          {
            margin: [0, 8],
            columns: [
              {
                text: "a) " + item.match1.match1b,
                width: "16%",
                style: "margin3"
              },
              {
                text: "b) " + item.match2.match2b,
                width: "16%",
                style: "margin3"
              },
              {
                text: "c) " + item.match3.match3b,
                width: "16%",
                style: "margin3"
              },
              {
                text: "d) " + item.match4.match4b,
                width: "16%",
                style: "margin3"
              },
              {
                text: "e) " + item.match5.match5b,
                width: "16%",
                style: "margin3"
              },
              {
                text: "f) " + item.match6.match6b,
                width: "16%",
                style: "margin3"
              }
            ]
          }
        ];
      else {
        return null;
      }
    });

    const docDefinition = {
      footer: function(currentPage, pageCount) {
        return [
          {
            text: ` ${currentPage.toString()} of ${pageCount}`,
            style: "footer"
          }
        ];
      },
      header: function(currentPage, pageCount, pageSize) {
        return [
          {
            text: name.charAt(0).toUpperCase() + name.slice(1),
            style: "header"
          }
        ];
      },
      content: [
        {
          columns: [questionArray]
        }
      ],

      styles: {
        header: {
          fontSize: 15,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        footer: {
          fontSize: 10,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        margin: {
          margin: [0, 5],
          fontSize: 13
        },
        margin2: {
          margin: [0, 5],
          fontSize: 11
        },
        margin3: {
          margin: [0, 5],
          fontSize: 9,
          alignment: "center"
        },
        lessonStyle: {
          margin: 5,
          fontSize: 12
        },
        topicHeader: {
          margin: [0, 5],
          fontSize: 12,
          bold: true
        },
        topicTitle: {
          margin: [20, 0],
          fontSize: 12,
          bold: true,
          width: "auto"
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black"
        },
        rows: {
          fontSize: 13,
          color: "white"
        },

        white: {
          margin: [0, 10],
          color: "white"
        },
        label: {
          bold: false,
          margin: [0, 0, 10, 0]
        }

        // icon: { font: "Fontello" }
      },
      pageSize: "A5"
    };

    pdfMake.createPdf(docDefinition).open();
  };

  const downloadPDF = () => {
    const questionArray = newComponentArray.map((item, index) => {
      if (item.type === "multipleChoice")
        return [
          {
            text: `${index + 1}. ${item.question.charAt(0).toUpperCase() +
              item.question.slice(1)}`,
            style: "lessonStyle"
          },
          {
            type: "none",
            ul: [
              {
                text: `a) ${item.choice1}`,
                width: "*",
                alignment: "justify",
                margin: [0, 8]
              },
              {
                text: `b) ${item.choice2}`,
                width: "*",
                alignment: "justify",
                margin: [0, 8]
              },
              {
                text: `c) ${item.choice3}`,
                width: "*",
                alignment: "justify",
                margin: [0, 8]
              },
              {
                text: `d) ${item.choice4}`,
                width: "*",
                alignment: "justify",
                margin: [0, 8]
              }
            ]
          }
        ];
      else if (item.type === "checkAllBoxes")
        return [
          `${index + 1}. ${item.question.charAt(0).toUpperCase() +
            item.question.slice(1)}`,
          {
            margin: [0, 8],
            columns: [
              {
                text: `[  ] ${item.choice1}`,
                width: "*",
                alignment: "center"
              },
              { text: `[  ] ${item.choice2}`, width: "*", alignment: "center" },
              { text: `[  ] ${item.choice3}`, width: "*", alignment: "center" },
              { text: `[  ] ${item.choice4}`, width: "*", alignment: "center" }
            ]
          }
        ];
      else if (item.type === "textArea")
        return [
          `${index + 1}. ${item.question.charAt(0).toUpperCase() +
            item.question.slice(1)}`,
          {
            margin: [0, 8],
            columns: [
              {
                style: "tableExample",
                table: {
                  widths: ["*"],

                  body: [
                    [{ text: "Answer", style: "tableHeader" }],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ],
                    [
                      {
                        text:
                          "'Sample value 1', 'Sample value 2', 'Sample value 3'",
                        style: "rows"
                      }
                    ]
                  ]
                },
                layout: "lightHorizontalLines"
              }
            ]
          }
        ];
      else if (item.type === "matching")
        return [
          `${index + 1}. ${item.question.charAt(0).toUpperCase() +
            item.question.slice(1)}`,

          {
            margin: [0, 8],
            columns: [
              {
                text: "1. " + item.match1.match1a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              },
              {
                text: "4. " + item.match4.match4a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              }
            ]
          },
          {
            margin: [0, 8],
            columns: [
              {
                text: "2. " + item.match2.match2a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              },
              {
                text: "5. " + item.match5.match5a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              }
            ]
          },
          {
            margin: [0, 8],
            columns: [
              {
                text: "3. " + item.match3.match3a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              },
              {
                text: "6. " + item.match6.match6a,
                width: "30%",
                style: "margin2"
              },
              {
                text: "_____",
                width: "20%",
                style: "margin2"
              }
            ]
          },
          {
            margin: [0, 8],
            columns: [
              {
                text: "a) " + item.match1.match1b,
                width: "16%",
                style: "margin3"
              },
              {
                text: "b) " + item.match2.match2b,
                width: "16%",
                style: "margin3"
              },
              {
                text: "c) " + item.match3.match3b,
                width: "16%",
                style: "margin3"
              },
              {
                text: "d) " + item.match4.match4b,
                width: "16%",
                style: "margin3"
              },
              {
                text: "e) " + item.match5.match5b,
                width: "16%",
                style: "margin3"
              },
              {
                text: "f) " + item.match6.match6b,
                width: "16%",
                style: "margin3"
              }
            ]
          }
        ];
      else {
        return null;
      }
    });

    const docDefinition = {
      footer: function(currentPage, pageCount) {
        return [
          {
            text: ` ${currentPage.toString()} of ${pageCount}`,
            style: "footer"
          }
        ];
      },
      header: function(currentPage, pageCount, pageSize) {
        return [
          {
            text: name.charAt(0).toUpperCase() + name.slice(1),
            style: "header"
          }
        ];
      },
      content: [
        {
          columns: [questionArray]
        }
      ],

      styles: {
        header: {
          fontSize: 15,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        footer: {
          fontSize: 10,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        margin: {
          margin: [0, 5],
          fontSize: 13
        },
        margin2: {
          margin: [0, 5],
          fontSize: 11
        },
        margin3: {
          margin: [0, 5],
          fontSize: 9,
          alignment: "center"
        },
        lessonStyle: {
          margin: 5,
          fontSize: 12
        },
        topicHeader: {
          margin: [0, 5],
          fontSize: 12,
          bold: true
        },
        topicTitle: {
          margin: [20, 0],
          fontSize: 12,
          bold: true,
          width: "auto"
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black"
        },
        rows: {
          fontSize: 13,
          color: "white"
        },

        white: {
          margin: [0, 10],
          color: "white"
        },
        label: {
          bold: false,
          margin: [0, 0, 10, 0]
        }

        // icon: { font: "Fontello" }
      },
      pageSize: "A5"
    };

    pdfMake.createPdf(docDefinition).download();
  };

  return (
    <Fragment>
      <MDBRow className="mx-1">
        <MDBDropdown>
          <MDBDropdownToggle
            className="quickSand"
            caret
            color="primary"
            size="sm"
          >
            Printer Friendly
          </MDBDropdownToggle>
          <MDBDropdownMenu basic>
            <MDBDropdownItem className="quickSand" onClick={getPDF}>
              View PDF
            </MDBDropdownItem>
            <MDBDropdownItem className="quickSand" onClick={downloadPDF}>
              Download PDF
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBRow>
    </Fragment>
  );
};
const mapStateToProps = state => ({
  customizables: state.customizables,
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, {})(CustomizablePDF));
