import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MDBCard, MDBCardBody } from "mdbreact";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useTranslation } from "react-i18next";
import "moment/locale/fr";
import "moment/locale/pt";
import "moment/locale/it";
import "moment/locale/id";
import "moment/locale/es";
import "moment/locale/hi";
import "moment/locale/de";
import "moment/locale/tr";
import "moment/locale/zh-cn";

import "react-big-calendar/lib/css/react-big-calendar.css";

import {
  getPublicPageURLConnect,
  classRequestConnect,
  setLoadingButtonAppointment,
  clearUpdatedMessage
} from "../../../actions/publicpage";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBSelect,
  MDBScrollbar,
  MDBIcon,
  MDBInput
} from "mdbreact";
import io from "socket.io-client";

const currentTimezone = moment.tz.guess() || "America/New_York";

const url = io("/messages/student", {
  transports: ["websocket"]
});
const CalendarSection = ({
  auth,
  getPublicPageURLConnect,
  publicpage: {
    publicPageURL,
    loadingButtonAppointment,
    connectRequestStatus,
    updateMsgAlert
  },
  students: { user },
  classRequestConnect,
  clearUpdatedMessage,
  setLoadingButtonAppointment
}) => {
  const [formData, setFormData] = useState({
    events: [],
    modal: false,
    event: {
      start: "",
      end: "",
      title: "",
      id: "",
      type: "",
      comments: "",
      objective: "",
      date: "",
      groupIds: {
        name: ""
      },
      topicIds: [],
      classTitle: ""
    },
    header: "",
    delete: "",
    timezones: [],
    selectedTimezone: currentTimezone,
    scrollToDate: new Date().setHours(8, 0, 0),
    selectedSlots: [],
    modalRequestClass: false,
    newMessage: {
      message: "",
      date: "",
      seen: false
    },
    message3:
      "Hello! I have reserved a tutor session on your calendar. I look forward to learning with you.",
    checked3: false,
    checked4: true,
    locale: "en"
  });

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === "fr") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "fr"
        };
      });
    } else if (i18n.language === "pt") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "pt"
        };
      });
    } else if (i18n.language === "it") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "it"
        };
      });
    } else if (i18n.language === "id") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "id"
        };
      });
    } else if (i18n.language === "es") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "es"
        };
      });
    } else if (i18n.language === "hi") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "hi"
        };
      });
    } else if (i18n.language === "de") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "de"
        };
      });
    } else if (i18n.language === "tr") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "tr"
        };
      });
    } else if (i18n.language === "zh_CN") {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "zh-cn"
        };
      });
    } else {
      setFormData(prevState => {
        return {
          ...prevState,
          locale: "en"
        };
      });
    }
  }, [i18n.language]);

  const calendarLanguages = {
    allDay: t("calendar.10"),
    previous: t("calendar.9"),
    next: t("calendar.8"),
    today: t("calendar.7"),
    month: t("calendar.6"),
    week: t("calendar.5"),
    day: t("calendar.4"),
    agenda: t("calendar.3"),
    date: t("calendar.1"),
    time: t("calendar.2"),
    noEventsInRange: t("calendar.11")
  };

  useEffect(() => {
    if (updateMsgAlert) {
      url.emit("updateLastedPublicpageMsg", {
        userTutor: updateMsgAlert.userTutor,
        latestPublicpageMsg: updateMsgAlert.latestPublicpageMsg
      });

      clearUpdatedMessage();
    }
  }, [updateMsgAlert, clearUpdatedMessage]);

  // const momentAdjusted = getMoment(formData.selectedTimezone);
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    const timezones = moment.tz.names().map((label, index) => {
      if (label === formData.selectedTimezone) {
        return {
          text: label,
          index: index,
          value: label,
          checked: true
        };
      } else {
        return {
          text: label,
          index: index,
          value: label,
          checked: false
        };
      }
    });

    setFormData(prevState => {
      return {
        ...prevState,
        timezones: timezones
      };
    });
  }, [formData.selectedTimezone]);

  useEffect(() => {
    if (user._id) {
      getPublicPageURLConnect({ studentId: user._id });
    }
  }, [getPublicPageURLConnect, user]);

  const selectTimezone = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        selectedTimezone: value[0]
      });
    }
  };

  const getDefaultDate = () => {
    const newDate = moment(new Date()).valueOf();
    const start1 = moment(newDate, "x")
      .tz(auth.user.defaultTimezone)
      .format("YYYY MM DD hh:mm a");

    const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();

    return start2;
  };

  const formats = {
    timeGutterFormat: "h a"
  };

  const handleEvent = event => {
    const find = formData.selectedSlots.find(item => item._id === event._id);

    if (find) {
      const filter = formData.selectedSlots.filter(
        item => item._id !== event._id
      );
      setFormData({
        ...formData,
        selectedSlots: filter
      });
    } else {
      setFormData({
        ...formData,
        selectedSlots: [event]
      });
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const find = formData.selectedSlots.find(item => item._id === event._id);

    if (find) {
      let newStyle = {
        backgroundColor: "#9c45eb",
        color: "white",
        borderRadius: "0px",
        fontFamily: "quickSand",
        borderColor: "black"
      };
      return {
        style: newStyle
      };
    } else if (event.title === "Available") {
      let newStyle = {
        backgroundColor: "#aed581 ",
        color: "black",
        borderRadius: "0px",
        fontFamily: "quickSand",
        borderColor: "black"
      };
      return {
        style: newStyle
      };
    } else if (event.title === "Pending") {
      let newStyle = {
        visibility: "hidden"
      };
      return {
        style: newStyle
      };
    }
  };

  const getEvents = () => {
    const newArray = publicPageURL.availability.map((item, index) => {
      const start1 = moment(item.start, "x")
        .tz(formData.selectedTimezone)
        .format("YYYY MM DD hh:mm a");
      const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();
      const end1 = moment(item.end, "x")
        .tz(formData.selectedTimezone)
        .format("YYYY MM DD hh:mm a");
      const end2 = moment(end1, "YYYY MM DD hh:mm a").toDate();

      return {
        ...item,
        start: start2,
        end: end2
      };
    });
    return newArray;
  };

  const onChange = e =>
    setFormData({
      ...formData,
      newMessage: {
        ...formData.newMessage,
        [e.target.name]: e.target.value
      }
    });

  const onClick3 = () => {
    setFormData({
      ...formData,
      checked1: false,
      checked2: false,
      checked3: true,
      checked4: false,
      newMessage: {
        ...formData.newMessage,
        message: formData.message3
      }
    });
  };
  const onClick4 = () => {
    setFormData({
      ...formData,
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: true,
      newMessage: {
        ...formData.newMessage,
        message: ""
      }
    });
  };

  const sendMessageWithAppointment = () => {
    const date = new Date();

    const newArray = formData.selectedSlots.map((item, index) => {
      const start1 = moment(item.start).format("YYYY MM DD hh:mm a");

      const start2 = moment
        .tz(start1, "YYYY MM DD hh:mm a", formData.selectedTimezone)
        .valueOf();
      const end1 = moment(item.end).format("YYYY MM DD hh:mm a");

      const end2 = moment
        .tz(end1, "YYYY MM DD hh:mm a", formData.selectedTimezone)
        .valueOf();
      return {
        start: start2,
        end: end2,
        availabilityId: item._id
      };
    });
    setLoadingButtonAppointment(true);
    classRequestConnect({
      ...formData.newMessage,
      studentId: user._id,
      date: date,
      appointment: newArray,
      language: i18n.language
    });
  };

  useEffect(() => {
    if (connectRequestStatus === "awaiting") {
      setFormData(prevState => {
        return {
          ...prevState,
          newMessage: {
            message: "",
            date: "",
            seen: false
          },
          checked3: false,
          checked4: true,
          modalRequestClass: false,
          selectedSlots: []
        };
      });
    }
  }, [publicPageURL, connectRequestStatus]);

  return publicPageURL.activatePublicPage === false ? null : (
    <Fragment>
      <MDBCard style={{ marginBottom: "5rem", paddingBottom: "8rem" }}>
        <MDBCardBody>
          <MDBRow center>
            <p className="quickSand h2">{t("other.6")}</p>
          </MDBRow>
          <MDBRow center>
            <MDBSelect
              search
              className="quickSand black-text font-weight-bold   mt-3"
              options={formData.timezones}
              labelClass="black-text font-weight-bold quickSand"
              label="Timezone"
              outline
              size="sm"
              getValue={selectTimezone}
            />
            <div className="w-100"></div>
            <div
              // touchStart={changeCssScroll}
              // touchMove={changeCssScroll}
              // className="mobileCalendarScroll customScroll"
              className="customScrollColorCalendar"
              // id="test100"
              style={{ overflowX: "auto" }}
            >
              <div style={{ minWidth: "30rem" }} className="customScroll">
                <Calendar
                  messages={calendarLanguages}
                  culture={formData.locale}
                  localizer={localizer}
                  // defaultDate={getDefaultDate()}
                  defaultView="week"
                  views={["week", "day", "month", "agenda"]}
                  events={getEvents()}
                  style={{
                    height: "25rem",
                    fontFamily: "Quicksand",
                    minWidth: "30rem"
                  }}
                  onSelectEvent={event => handleEvent(event)}
                  formats={formats}
                  getNow={getDefaultDate}
                  // showMultiDayTimes={false}

                  // scrollToTime={getStartDate()}
                  eventPropGetter={eventStyleGetter}
                />
              </div>
            </div>
            <div className="w-100"></div>
            {formData.selectedSlots.length === 1 ? (
              <MDBBtn
                onClick={() => {
                  setFormData({
                    ...formData,
                    modalRequestClass: true
                  });
                }}
                color="danger"
              >
                {t("studentCalendar.1")}
              </MDBBtn>
            ) : null}
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <MDBContainer>
        <MDBModal
          isOpen={formData.modalRequestClass}
          toggle={() => null}
          cascading
        >
          <MDBModalHeader
            toggle={() => {
              setFormData({
                ...formData,
                modalRequestClass: false
              });
            }}
            className="messageBtnPublicPage white-text"
          >
            {t("studentCalendar.1")}
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <p className="text-center quickSand h5-responsive">
                {t("studentCalendar.2")}
              </p>

              {formData.selectedSlots.map((item, index) => {
                const start1 = moment(item.start).format("YYYY MM DD hh:mm a");

                const start2 = moment
                  .tz(start1, "YYYY MM DD hh:mm a", formData.selectedTimezone)
                  .valueOf();
                const end1 = moment(item.end).format("YYYY MM DD hh:mm a");

                const end2 = moment
                  .tz(end1, "YYYY MM DD hh:mm a", formData.selectedTimezone)
                  .valueOf();
                return (
                  <p className="quickSand">
                    {index + 1}:{" "}
                    {moment(start2, "x")
                      .tz(formData.selectedTimezone)
                      .format("llll")}{" "}
                    -{" "}
                    {moment(end2, "x")
                      .tz(formData.selectedTimezone)
                      .format("llll")}
                  </p>
                );
              })}

              <MDBCol className="mb-1">
                <MDBInput
                  label={formData.message3}
                  type="radio"
                  id="radio3"
                  labelClass="text-black quickSand"
                  onClick={onClick3}
                  checked={formData.checked3}
                />
              </MDBCol>
              <MDBCol className="mb-1">
                <MDBInput
                  label={t("studentCalendar.3")}
                  type="radio"
                  id="radio4"
                  labelClass="text-black quickSand"
                  onClick={onClick4}
                  checked={formData.checked4}
                />
              </MDBCol>
              <MDBInput
                type="textarea"
                label={t("studentCalendar.4")}
                name="message"
                className="quickSand text-black hintColor"
                value={formData.newMessage.message}
                onChange={e => onChange(e)}
                required
                rows={10}
              />
            </div>
          </MDBModalBody>
          <MDBModalFooter center>
            <MDBBtn
              outline
              color="secondary"
              onClick={() => {
                setFormData({
                  ...formData,
                  modalRequestClass: false
                });
              }}
            >
              {t("studentCalendar.5")}
            </MDBBtn>
            <MDBBtn
              disabled={loadingButtonAppointment}
              className="messageBtnPublicPage"
              onClick={sendMessageWithAppointment}
            >
              {loadingButtonAppointment === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              {t("studentCalendar.6")}

              <MDBIcon icon="paper-plane" className="ml-1 " />
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

CalendarSection.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  publicpage: state.publicpage,
  students: state.students
});

export default connect(mapStateToProps, {
  getPublicPageURLConnect,
  setLoadingButtonAppointment,
  classRequestConnect,
  clearUpdatedMessage
})(withRouter(CalendarSection));
