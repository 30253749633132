import {
  GET_LESSONS,
  GET_LESSONS_ALL,
  GET_LESSON,
  LESSONS_ERROR,
  LESSONS_DELETE,
  ADD_LESSONS,
  ADD_TOPIC,
  GET_TOPICS,
  GET_TOPIC,
  TOPICS_DELETE,
  UPDATE_LESSON,
  UPDATE_TOPIC,
  DOCUMENT_DELETE,
  TOPIC_PUSH,
  CLEAR_LESSON,
  SEARCH_LESSONS,
  SET_PAGINATION_MODE_LESSONS,
  SET_LOADING_BUTTON_LESSONS,
  CLEAR_LESSONS_SEARCH,
  SEARCH_TOPICS,
  SET_PAGINATION_MODE_TOPICS,
  SET_LOADING_BUTTON_TOPICS,
  CLEAR_TOPICS_SEARCH,
  GET_CLASSES,
  GET_LESSONS_PUSH,
  LESSONS_CALENDAR,
  PUBLICPAGE_APPOINTMENT_ACCEPT,
  SET_LOADING_DELETE_LESSON,
  SET_LOADING_DELETE_TOPIC,
  LOGOUT,
  CLEAR_DOCUMENT_DELETE
} from "../actions/types";

const initialState = {
  lessons: [],
  lessonsPush: [],
  lessonsAll: [],
  topics: [],
  lesson: null,
  topic: null,
  loading: true,
  error: {},
  documentDelete: false,
  updated: false,
  updatedLesson: false,
  pdf: null,
  lessonDeleted: "",
  updatedTopic: "",
  prevLesson: "",
  topicLesson: "",
  individualLesson: "",
  individualTopic: "",
  initialPageNumberLessons: 1,
  initialNumberOfPagesLessons: 1,
  initialNumberOfPagesTopics: 1,
  initialPageNumberTopics: 1,
  pageNumberLessons: 1,
  numberOfPagesLessons: 1,
  pageNumberTopics: 1,
  numberOfPagesTopics: 1,
  searchLessons: [],
  searchTopics: [],
  paginationModeLessons: "date",
  paginationModeTopics: "date",
  keywordsLessons: "",
  keywordsTopics: "",
  groups: [],
  calendarLessons: [],
  loadingButtonLessons: false,
  loadingButtonTopics: false,
  lessonLoading: true,
  topicLoading: true,
  loadingLessonDelete: false,
  loadingTopicDelete: false,
  removeDocumentTopic: ""
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_LESSON:
      return {
        ...state,
        prevLesson: "",
        updatedTopic: "",
        lessonDeleted: "",
        updatedLesson: "",
        topic: "",
        lesson: ""
      };
    case CLEAR_LESSONS_SEARCH:
      return {
        ...state,
        searchLessons: "",
        keywordsLessons: "",
        numberOfPagesLessons: "",
        pageNumberLessons: "",
        paginationModeLessons: "date"
      };
    case CLEAR_TOPICS_SEARCH:
      return {
        ...state,
        searchTopics: "",
        keywordsTopics: "",
        numberOfPagesTopics: "",
        pageNumberTopics: "",
        paginationModeTopics: "date"
      };
    case GET_LESSONS:
      return {
        ...state,
        lessons: payload.lessons,
        initialNumberOfPagesLessons: payload.initialNumberOfPagesLessons,
        initialPageNumberLessons: payload.initialPageNumberLessons,
        loading: false,
        lessonLoading: false
      };
    case GET_LESSONS_ALL:
      return {
        ...state,
        lessonsAll: payload,
        loading: false
      };
    case GET_LESSONS_PUSH:
      return {
        ...state,
        lessonsPush: payload,
        loading: false
      };

    case GET_TOPICS:
      return {
        ...state,
        topics: payload.topics,
        initialNumberOfPagesTopics: payload.initialNumberOfPagesTopics,
        initialPageNumberTopics: payload.initialPageNumberTopics,
        loading: false,
        documentDelete: false,
        topicLoading: false
      };
    case UPDATE_LESSON:
      const newArray = state.lessons.map((item, index) => {
        if (item._id === payload.lesson._id) {
          return payload.lesson;
        } else {
          return item;
        }
      });
      return {
        ...state,
        lessons: newArray,
        updatedLesson: payload.lesson,
        loadingButtonLessons: false
      };
    case GET_LESSON:
      return {
        ...state,
        individualLesson: payload
      };

    case GET_TOPIC:
      return {
        ...state,
        individualTopic: payload
      };
    case UPDATE_TOPIC:
      const newArray2 = state.topics.map((item, index) => {
        if (item._id === payload.topic._id) {
          return payload.topic;
        } else {
          return item;
        }
      });

      const lessonArray = state.lessons.map((item, index) => {
        const topicArray = item.topicIds.map((item2, index2) => {
          if (item2._id === payload.topic._id) {
            return payload.topic;
          } else {
            return item2;
          }
        });

        return {
          ...item,
          topicIds: topicArray
        };
      });
      return {
        ...state,
        topics: newArray2,
        updatedTopic: payload.topic,
        documentDelete: false,
        loadingButtonTopics: false,
        lessons: lessonArray
      };

    case ADD_LESSONS:
      return {
        ...state,
        lesson: payload.lesson,
        lessons: [payload.lesson, ...state.lessons],
        loading: false,
        loadingButtonLessons: false
      };
    case LESSONS_DELETE:
      return {
        ...state,
        lessons: state.lessons.filter(
          lessons => lessons._id !== payload.lesson._id
        ),
        lessonDeleted: payload.lesson,
        loading: false,
        loadingLessonDelete: false
      };
    case LESSONS_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonLessons: false,
        loadingButtonTopics: false,
        loadingLessonDelete: false,
        loadingTopicDelete: false
      };

    case ADD_TOPIC:
      if (payload.lesson) {
        const newArray = state.lessons.map((item, index) => {
          if (item._id === payload.lesson._id) {
            return payload.lesson;
          } else {
            return item;
          }
        });
        return {
          ...state,
          lessons: newArray,
          updatedLesson: payload.lesson,
          topicLesson: payload.topic,
          topics: [payload.topic, ...state.topics],
          loadingButtonTopics: false
        };
      } else {
        return {
          ...state,
          topic: payload.topic,
          topics: [payload.topic, ...state.topics],
          loading: false,
          loadingButtonTopics: false
        };
      }

    case TOPICS_DELETE:
      const lessonArray2 = state.lessons.map((item, index) => {
        const topicArray = item.topicIds.filter((item2, index2) => {
          return item2._id !== payload.topic._id;
        });

        return {
          ...item,
          topicIds: topicArray
        };
      });
      return {
        ...state,
        topics: state.topics.filter(topics => topics._id !== payload.topic._id),
        topicDeleted: payload.topic,
        loading: false,
        lessons: lessonArray2,
        loadingTopicDelete: false
      };

    case DOCUMENT_DELETE:
      const updatedTopic = state.topics.map((item, index) => {
        if (item._id === payload.topic._id) {
          return payload.topic;
        } else {
          return item;
        }
      });
      return {
        ...state,
        documentDelete: true,
        topics: updatedTopic,
        removeDocumentTopic: payload.topic
      };
    case TOPIC_PUSH:
      const newArraypush = state.lessons.map((item, index) => {
        if (item._id === payload.previousLesson._id) {
          return payload.previousLesson;
        } else if (item._id === payload.nextLesson._id) {
          return payload.nextLesson;
        } else {
          return item;
        }
      });
      return {
        ...state,
        lessons: newArraypush,
        prevLesson: payload.previousLesson
      };
    case CLEAR_DOCUMENT_DELETE:
      return {
        ...state,
        documentDelete: false,
        removeDocumentTopic: ""
      };
    case SEARCH_LESSONS:
      return {
        ...state,
        searchLessons: payload.searchLessons,
        keywordsLessons: payload.keywordsLessons,
        pageNumberLessons: payload.pageNumberLessons,
        numberOfPagesLessons: payload.numberOfPagesLessons,
        loading: false
      };
    case SET_PAGINATION_MODE_LESSONS:
      return {
        ...state,
        paginationModeLessons: payload
      };
    case SET_LOADING_BUTTON_LESSONS:
      return {
        ...state,
        loadingButtonLessons: payload
      };
    case SET_LOADING_BUTTON_TOPICS:
      return {
        ...state,
        loadingButtonTopics: payload
      };
    case SEARCH_TOPICS:
      return {
        ...state,
        searchTopics: payload.searchTopics,
        keywordsTopics: payload.keywordsTopics,
        pageNumberTopics: payload.pageNumberTopics,
        numberOfPagesTopics: payload.numberOfPagesTopics,
        loading: false
      };
    case SET_PAGINATION_MODE_TOPICS:
      return {
        ...state,
        paginationModeTopics: payload
      };
    case GET_CLASSES:
      return {
        ...state,
        groups: payload,
        loading: false
      };
    case LESSONS_CALENDAR:
      return {
        ...state,
        calendarLessons: payload
      };
    case PUBLICPAGE_APPOINTMENT_ACCEPT:
      return {
        ...state,
        calendarLessons: payload.lessons
      };
    case SET_LOADING_DELETE_LESSON:
      return {
        ...state,
        loadingLessonDelete: payload
      };
    case SET_LOADING_DELETE_TOPIC:
      return {
        ...state,
        loadingTopicDelete: payload
      };
    case LOGOUT:
      return {
        lessons: [],
        lessonsAll: [],
        lessonsPush: [],
        topics: [],
        lesson: null,
        topic: null,
        loading: true,
        error: {},
        documentDelete: false,
        updated: false,
        updatedLesson: false,
        pdf: null,
        lessonDeleted: "",
        updatedTopic: "",
        prevLesson: "",
        topicLesson: "",
        individualLesson: "",
        individualTopic: "",
        initialPageNumberLessons: 1,
        initialNumberOfPagesLessons: 1,
        initialNumberOfPagesTopics: 1,
        initialPageNumberTopics: 1,
        pageNumberLessons: 1,
        numberOfPagesLessons: 1,
        pageNumberTopics: 1,
        numberOfPagesTopics: 1,
        searchLessons: [],
        searchTopics: [],
        paginationModeLessons: "date",
        paginationModeTopics: "date",
        keywordsLessons: "",
        keywordsTopics: "",
        groups: [],
        calendarLessons: [],
        loadingButtonLessons: false,
        loadingButtonTopics: false,
        lessonLoading: true,
        topicLoading: true,
        loadingLessonDelete: false,
        loadingTopicDelete: false
      };

    default:
      return state;
  }
}
