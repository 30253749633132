import axios from "axios";
import { setAlert } from "./alert";

import {
  MAKE_DECK,
  DECK_ERROR,
  GET_DECKS,
  SEARCH_DECKS,
  SEARCH_CARDS,
  CLEAR_DECKS_SEARCH,
  CLEAR_CARDS_SEARCH,
  UPDATE_DECK,
  DELETE_DECK,
  SET_LOADING_BUTTON_DECKS,
  SET_LOADING_BUTTON_CARDS,
  MAKE_CARD,
  UPDATE_DECK_CARDS,
  UPDATE_FLASHCARD,
  UPDATE_SPACED,
  GET_FLASHCARDS,
  DELETE_FLASHCARD,
  SHARE_DECK,
  CLEAR_FLASHCARD,
  SET_PAGINATION_MODE_DECKS,
  SET_PAGINATION_MODE_CARDS,
  SET_LOADING_DELETE_DECK,
  SET_LOADING_DELETE_CARD
} from "./types";

// Make a Deck

export const makeDeck = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/flashcards/deck", formData, config);

    dispatch({
      type: MAKE_DECK,
      payload: res.data.deck
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all decks
export const getDecks = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/flashcards/decks", formData, config);

    dispatch({
      type: GET_DECKS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Decks

export const searchDecksFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/flashcards/decks/search",
      formData,
      config
    );

    dispatch({
      type: SEARCH_DECKS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Decks

export const setPaginationModeDecks = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_DECKS,
    payload: formData
  });
};

// Clear Deck Search Results

export const clearDecksSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_DECKS_SEARCH
  });
};

// Update deck
export const updateDeck = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/flashcards/decks/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_DECK,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Save Cards to Deck

export const saveCardsToDeck = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/flashcards/decks/cards/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_DECK_CARDS,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Save Cards to Deck -SPACED REPETITION

export const updateSpaced = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/flashcards/decks/spaced/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_SPACED,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// delete deck

export const deleteDeck = id => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.delete(`/api/flashcards/decks/${id}`, config);

    dispatch({
      type: DELETE_DECK,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Make a Card

export const makeCard = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/flashcards/cards", formData, config);

    dispatch({
      type: MAKE_CARD,
      payload: res.data.card
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update deck
export const updateCard = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/flashcards/cards/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_FLASHCARD,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all cards
export const getCards = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post(`/api/flashcards/get/cards`, formData, config);

    dispatch({
      type: GET_FLASHCARDS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Cards

export const searchCardsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/flashcards/cards/search",
      formData,
      config
    );

    dispatch({
      type: SEARCH_CARDS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear Card Search Results

export const clearCardsSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_CARDS_SEARCH
  });
};

// Set Pagination Mode Cards

export const setPaginationModeCards = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_CARDS,
    payload: formData
  });
};

// delete card

export const deleteCard = id => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.delete(`/api/flashcards/cards/${id}`, config);

    dispatch({
      type: DELETE_FLASHCARD,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Share deck
export const saveShare = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/flashcards/share/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: SHARE_DECK,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: DECK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const clearFlashCardReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_FLASHCARD
  });
};

// Set Loading Button Decks

export const setLoadingButtonDecks = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_DECKS,
    payload: formData
  });
};

// Set Loading Button Cards

export const setLoadingButtonCards = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_CARDS,
    payload: formData
  });
};

export const setLoadingDeleteDeck = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_DELETE_DECK,
    payload: formData
  });
};
export const setLoadingDeleteCard = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_DELETE_CARD,
    payload: formData
  });
};
