import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBAvatar,
  MDBCardBody,
  MDBIcon,
  MDBBtn
} from "mdbreact";

import "./Landing.css";

const Team = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Team</title>
        <meta name="description" content="Meet Topscholar's team" />
        <meta property="og:title" content="Topscholar: Team" />
        <meta property="og:description" content="Meet Topscholar's team" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/team" />
      </Helmet>
      <MDBCard className="my-5 px-5 pb-5 text-center ">
        <MDBCardBody>
          <h2 className="h1-responsive font-weight-bold my-5 purple-text">
            Our Team
          </h2>
          <h5 className="black-text w-responsive mx-auto mb-5">
            Topscholar is focused on transforming the way people learn and teach
            English. Through its user-friendly web and mobile applications,
            Topscholar seeks to be at the forefront in the ongoing intertwining
            between technology and education in the 21st century.
          </h5>
          <MDBRow className="row h-100 justify-content-center ">
            <MDBCol lg="3" md="6" className="mb-lg-0 mb-5 vcenter">
              <MDBAvatar
                tag="img"
                src="https://res.cloudinary.com/unnamed/image/upload/c_scale,w_426/v1578135786/phillip_s_clean_photo_1_hsxrwx.jpg"
                className="rounded-circle z-depth-1 img-circle justin"
              />
              <h5 className="font-weight-bold mt-4 mb-3">Phillip Lang</h5>
              <p className="text-uppercase purple-text">Co-Founder</p>
              <p className="black-text vcenter1">
                Welcome to Topscholar! My name is Phillip and I am a co-founder
                of this company. After graduating with a Bachelor’s from The
                John Molson School of Business, I took an interest in language
                learning and teaching. From taking ASL courses to obtaining my
                CELTA, my passion for languages continued to grow. It was when I
                started teaching that I realized much of my wasted time was
                spent recreating lesson plans and not having a place to store my
                work. This company is the culmination of those teaching
                experiences and thousands of hours of coding. We have created an
                app that increases a teacher’s productivity and elevates the
                enjoyment of lesson preparation. With our app you can establish
                yourself as a private tutor or increase the ease of making
                lesson plans if you work in a language academy. I hope you join
                us in the quest of expanding the field of language education.
                All the best!
              </p>
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-lg-0 mb-5 vcenter">
              <MDBAvatar
                tag="img"
                src="https://res.cloudinary.com/unnamed/image/upload/c_scale,w_426/v1578135777/justin_s_clean_photo_2_zlxc9b.jpg"
                className="img-circle rounded-circle z-depth-1 justin"
              />
              <h5 className="font-weight-bold mt-4 mb-3">Justin Ferrari</h5>
              <p className="text-uppercase purple-text">Co-Founder</p>
              <p className="black-text vcenter1">
                Hi. I’m Justin Ferrari. Thank you for taking an interest in
                Topscholar and for visiting our website! I co-founded this
                wonderful organization with my friend Phillip, and I am very
                proud to see what it has become. I truly believe in its value
                and resourcefulness for aspiring English tutors and language
                academies, and for those looking for a better way to teach
                English. I possess a Bachelor’s Degree in Business
                Administration, and cumulate two years in IT recruiting and two
                years as an account manager for an IT recruiting company as
                well. My strong knowledge of business development and in the IT
                sphere have been translated into the Topscholar Tutor App,
                allowing it to be impactful in the language education field, and
                promoting technological advancements in that sector.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
        <MDBCardBody cascade className="text-center">
          <MDBRow center>
            <p className="h3-responsive font-weight-bold">
              Follow Us On Social Media!
            </p>
          </MDBRow>
          <MDBRow center>
            <a
              href="https://www.facebook.com/topscholarapp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDBBtn className="btn-fb waves-light">
                <MDBIcon fab icon="facebook-f" className="pr-2" />
                Facebook
              </MDBBtn>
            </a>

            <a
              href="https://twitter.com/Topscholarapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDBBtn className="btn-tw waves-light">
                <MDBIcon fab icon="twitter" className="pr-2" />
                Twitter
              </MDBBtn>
            </a>

            <a
              href="https://www.linkedin.com/company/topscholar/about/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDBBtn className="btn-li waves-light">
                <MDBIcon fab icon="linkedin-in" className="pr-2" />
                Linkedin
              </MDBBtn>
            </a>

            <a
              href="https://www.instagram.com/topscholarapp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDBBtn className="btn-ins waves-light">
                <MDBIcon fab icon="instagram" className="pr-2" />
                Instagram
              </MDBBtn>
            </a>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </Fragment>
  );
};

export default withRouter(Team);
