import React, { Fragment, useState, useEffect } from "react";
import {
  MDBDataTable,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBSelect,
  MDBIcon,
  MDBPageItem,
  MDBPageNav,
  MDBPagination,
  MDBInput
} from "mdbreact";
import "./lessons.css";
import { toast } from "react-toastify";
import union from "lodash/union";
import "react-toastify/dist/ReactToastify.css";

import stripHtml from "string-strip-html";
import sum from "lodash/sum";
import Dingdong from "../../sounds/Dingdong.wav";
import { withRouter } from "react-router-dom";

import Spinner from "../layout/Spinner";
import { connect } from "react-redux";

import {
  getLessons,
  getLessonsAll,
  addLessons,
  getTopics,
  deleteLesson,
  deleteTopic,
  pushTopic,
  clearLessonReducer,
  getLesson,
  searchLessonsFunction,
  setPaginationModeLessons,
  clearLessonsSearchReducer,
  searchTopicsFunction,
  setPaginationModeTopics,
  clearTopicsSearchReducer,
  getLessonsPush,
  setLoadingDeleteLesson,
  setLoadingDeleteTopic,
  setLoadingButtonLessons,
  updateLesson,
  clearDocumentDelete
} from "../../actions/lessons";
import { getGroupsSelect } from "../../actions/groups";
import { getStudentsSelect } from "../../actions/students";
import urlParser from "js-video-url-parser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CreateLesson from "./CreateLesson";
import CreateTopic from "./CreateTopic";
import cloudinary from "cloudinary-core";
import Timer from "react-compound-timer";
import moment from "moment-timezone";

var cl = cloudinary.Cloudinary.new({ cloud_name: "unnamed" });

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
const sanitizeHtml = require("sanitize-html");

const LessonData = ({
  getLessons,
  getLessonsAll,
  getLesson,
  addLessons,
  getTopics,
  clearLessonReducer,
  pushTopic,
  getLessonsPush,
  auth: { user },
  groups: { groupsSelect },
  students: { studentsSelect },
  lessons: {
    lessonDeleted,
    lessons,
    lessonsAll,
    lesson,
    topicLesson,
    topic,
    topics,
    documentDelete,
    updatedLesson,
    pdf,
    loading,
    updatedTopic,
    topicDeleted,
    prevLesson,
    individualLesson,
    initialPageNumberLessons,
    initialNumberOfPagesLessons,
    initialPageNumberTopics,
    initialNumberOfPagesTopics,
    pageNumberLessons,
    numberOfPagesLessons,
    pageNumberTopics,
    numberOfPagesTopics,
    searchLessons,
    searchTopics,
    paginationModeLessons,
    paginationModeTopics,
    groups,
    lessonsPush,
    lessonLoading,
    topicLoading,
    loadingLessonDelete,
    loadingTopicDelete,
    loadingButtonLessons,
    loadingButtonTopics,
    removeDocumentTopic
  },
  history,
  deleteLesson,
  deleteTopic,
  start,
  location,
  searchLessonsFunction,
  setPaginationModeLessons,
  clearLessonsSearchReducer,
  searchTopicsFunction,
  setPaginationModeTopics,
  clearTopicsSearchReducer,
  getGroupsSelect,
  getStudentsSelect,
  setLoadingDeleteLesson,
  setLoadingDeleteTopic,
  setLoadingButtonLessons,
  updateLesson,
  clearDocumentDelete
}) => {
  const [formData, setFormData] = useState({
    dataLessons: {
      columns: [
        {
          label: [
            <p key={1} className="quickSand font-weight-bold tableFont">
              {"Lesson Title"}
            </p>
          ],
          field: "title",
          sort: "desc",
          width: 150
        },
        {
          label: [
            <p key={2} className="quickSand font-weight-bold tableFont">
              {"Class/Student"}
            </p>
          ],
          field: "class",
          sort: "desc",
          width: 270
        },
        {
          label: [
            <p key={3} className="quickSand font-weight-bold tableFont">
              {"Date (YYYY MM DD)"}
            </p>
          ],
          field: "date",
          sort: "desc",
          width: 150
        }
      ],
      rows: []
    },
    dataTopics: {
      columns: [
        {
          label: [
            <p key={4} className="quickSand font-weight-bold tableFont">
              {"Topic Title"}
            </p>
          ],
          field: "title",
          sort: "asc",
          width: 150
        },
        {
          label: [
            <p key={5} className="quickSand font-weight-bold tableFont">
              {"Level (A1...C2)"}
            </p>
          ],
          field: "level",
          sort: "asc",
          width: 270
        },
        {
          label: [
            <p key={6} className="quickSand font-weight-bold tableFont">
              {"Grammar"}
            </p>
          ],
          field: "grammar",
          sort: "asc",
          width: 150
        },
        {
          label: [
            <p key={7} className="quickSand font-weight-bold tableFont">
              {"Vocabulary"}
            </p>
          ],
          field: "vocabulary",
          sort: "asc",
          width: 150
        }
      ],
      rows: []
    },
    // lessonList: [],
    topicList: [],
    lessonTopic: true,
    modalCreateLesson: false,
    modalCreateTopic: false,
    modalSeeLesson: false,
    modalSeeTopic: false,
    individualLesson: {
      title: "",
      comments: "",
      objective: "",
      date: "",
      groupIds: {
        name: ""
      },
      student: {
        name: ""
      },
      start: "",
      timer: "",
      topicIds: [],
      timerArray: [],
      switch: false,
      vibrate: false,
      sound: false,
      assignedType: "none"
    },
    topics: [],
    individualTopic: {
      title: "",
      text: "",
      level: "",
      vocabulary: [],
      grammar: [],
      youtube: [],
      links: [],
      documents: []
    },
    modalDeleteLesson: false,
    modalDeleteTopic: false,
    pushList: [],
    pushTopic: "",
    timerState: "notStarted",
    modalChooseLesson: false,
    lessonChosen: "",
    lessonOptions: [
      {
        text: "",
        value: ""
      }
    ],
    searchLessons: [],
    searchValueLessons: "",
    pageNumberLessons: 1,
    searchTopics: [],
    searchValueTopics: "",
    pageNumberTopics: 1,
    classOptions: [],
    studentOptions: [],
    mergedArrays: [],
    filterArrayId: "",
    filterArrayType: "",
    noDate: false,
    selectedTimezone: "",
    preferredVideo: "",
    skype: "",
    whatsapp: "",
    wechat: "",
    saveLessonBoolean: false,
    saveTopicBoolean: false
  });

  // moment.tz.setDefault(user.defaultTimezone);

  // let commentsReplace = formData.individualLesson.comments.replace(/[<]br[^>]*[>]/gi,"");
  let commentsReplace = sanitizeHtml(formData.individualLesson.comments, {
    transformTags: {
      br: "",
      ol: "ul"
    }
  });
  const objectiveComment = htmlToPdfmake(
    `
  <p class="ptag"><strong>Lesson Objective: </strong> ${stripHtml(
    formData.individualLesson.objective
  )} </p>
  
  <p class="plabel">Lesson Comments: </p>
  <div class="ptag">
  ${commentsReplace}
  </div>
  `,
    {
      defaultStyles: {
        // change the default styles
        li: ""
      }
    }
  );

  const topicArrayHtml = formData.individualLesson.topicIds.map(
    (item, index) => {
      const commaVocabulary = item.vocabulary.join(", ");
      const commaGrammar = item.grammar.join(", ");
      let replaceText = sanitizeHtml(item.text, {
        transformTags: {
          br: "",
          ol: "ul"
        }
      });

      return htmlToPdfmake(
        `
  <p class="topicTitle">
    ${index + 1}. ${item.title.charAt(0).toUpperCase() + item.title.slice(1)}
    </p>
    <div class="lessonStyle">
    <p>Notes / Explanation: ${replaceText}</p>
    </div>
    <p class="lessonStyle">Vocabulary Words: ${commaVocabulary}</p>
    <p class="lessonStyle">Grammar: ${commaGrammar}</p>
    `,
        {
          defaultStyles: {
            // change the default styles
            li: ""
          }
        }
      );
    }
  );

  const getpdf = () => {
    // const topicArray = formData.individualLesson.topicIds.map((item, index) => {
    //   const commaVocabulary = item.vocabulary.join(", ");
    //   const commaGrammar = item.grammar.join(", ");
    //   return {
    //     columns: [
    //       {
    //         stack: [
    //           {
    //             text: `${index + 1}. ${item.title.charAt(0).toUpperCase() +
    //               item.title.slice(1)}`,
    //             style: "topicTitle"
    //           },
    //           {
    //             text: `Notes / Explanation: ${stripHtml(item.text)}`,
    //             style: "lessonStyle"
    //           },
    //           {
    //             text: `Vocabulary Words: ${commaVocabulary}`,
    //             style: "lessonStyle"
    //           },
    //           {
    //             text: `Grammar: ${commaGrammar}`,
    //             style: "lessonStyle"
    //           }
    //         ],
    //         style: "margin"
    //       }
    //     ]
    //   };
    // });
    const docDefinition = {
      footer: function(currentPage, pageCount) {
        return [
          {
            text: ` ${currentPage.toString()} of ${pageCount}`,
            style: "footer"
          }
        ];
      },
      header: function(currentPage, pageCount, pageSize) {
        return [
          {
            text:
              formData.individualLesson.title.charAt(0).toUpperCase() +
              formData.individualLesson.title.slice(1),
            style: "header"
          }
        ];
      },
      content: [
        {
          columns: [
            {
              stack: [
                {
                  text: [
                    { text: "Class:", style: "label" },
                    ` ${
                      formData.individualLesson.groupIds
                        ? formData.individualLesson.groupIds.name
                            .charAt(0)
                            .toUpperCase() +
                          formData.individualLesson.groupIds.name.slice(1)
                        : formData.individualLesson.student
                        ? formData.individualLesson.student.name
                            .charAt(0)
                            .toUpperCase() +
                          formData.individualLesson.student.name.slice(1)
                        : "Not Assigned"
                    }`
                  ],
                  style: "lessonStyleDate"
                },
                {
                  text: [
                    { text: "Lesson Title:", style: "label" },
                    ` ${formData.individualLesson.title
                      .charAt(0)
                      .toUpperCase() +
                      formData.individualLesson.title.slice(1)}`
                  ],
                  style: "lessonStyleDate"
                }
              ],
              style: "margin"
            },
            {
              stack: [
                {
                  text: [
                    {
                      text: "Start date: ",
                      style: "label"
                    },
                    `${
                      formData.individualLesson.dateBoolean === true
                        ? moment(formData.individualLesson.start, "x")
                            .tz(user.defaultTimezone)
                            .format("llll")
                        : "No Date"
                    }`
                  ],
                  style: "lessonStyleDate"
                },
                {
                  text: [
                    {
                      text: "End date:",
                      style: "label"
                    },
                    ` ${
                      formData.individualLesson.dateBoolean === true
                        ? moment(formData.individualLesson.end, "x")
                            .tz(user.defaultTimezone)
                            .format("llll")
                        : "No Date"
                    }`
                  ],
                  style: "lessonStyleDate"
                }
              ],
              style: "margin"
            }
          ]
        },
        {
          columns: [
            // {
            //   stack: [
            //     {
            //       text: [
            //         { text: `Lesson Objective:`, style: "label" },
            //         ` ${formData.individualLesson.objective}`
            //       ],
            //       style: "lessonStyle"
            //     },
            //     {
            //       text: [
            //         { text: `Lesson Comments:`, style: "label" },
            //         ` ${stripHtml(formData.individualLesson.comments)}`
            //       ],
            //       style: "lessonStyle"
            //     }
            //   ],

            //   style: "margin"
            // },
            objectiveComment
          ]
        },

        topicArrayHtml
      ],

      styles: {
        header: {
          fontSize: 15,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        footer: {
          fontSize: 10,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        margin: {
          margin: [0, 10]
        },
        margin2: {
          margin: [0, 0]
        },
        lessonStyle: {
          margin: [0, 5],
          fontSize: 10
        },
        lessonStyleDate: {
          margin: [0, 5],
          fontSize: 9
        },
        topicHeader: {
          margin: [0, 5],
          fontSize: 12,
          bold: true
        },
        topicTitle: {
          margin: [0, 5],
          fontSize: 10,
          bold: true,
          alignment: "center"
        },

        label: {
          bold: true
        },
        ptag: {
          fontSize: 10
        },
        plabel: {
          bold: true,
          fontSize: 10
        }
      },
      pageSize: "A5"
    };

    pdfMake.createPdf(docDefinition).open();
  };
  const downloadpdf = () => {
    const topicArray = formData.individualLesson.topicIds.map((item, index) => {
      const commaVocabulary = item.vocabulary.join(", ");
      const commaGrammar = item.grammar.join(", ");
      return {
        columns: [
          {
            stack: [
              {
                text: `${index + 1}. ${item.title.charAt(0).toUpperCase() +
                  item.title.slice(1)}`,
                style: "topicTitle"
              },
              {
                text: `Notes / Explanation: ${stripHtml(item.text)}`,
                style: "lessonStyle"
              },
              {
                text: `Vocabulary Words: ${commaVocabulary}`,
                style: "lessonStyle"
              },
              {
                text: `Grammar: ${commaGrammar}`,
                style: "lessonStyle"
              }
            ],
            style: "margin"
          }
        ]
      };
    });
    const docDefinition = {
      footer: function(currentPage, pageCount) {
        return [
          {
            text: ` ${currentPage.toString()} of ${pageCount}`,
            style: "footer"
          }
        ];
      },
      header: function(currentPage, pageCount, pageSize) {
        return [
          {
            text:
              formData.individualLesson.title.charAt(0).toUpperCase() +
              formData.individualLesson.title.slice(1),
            style: "header"
          }
        ];
      },
      content: [
        {
          columns: [
            {
              stack: [
                {
                  text: [
                    { text: "Class:", style: "label" },
                    ` ${
                      formData.individualLesson.groupIds
                        ? formData.individualLesson.groupIds.name
                            .charAt(0)
                            .toUpperCase() +
                          formData.individualLesson.groupIds.name.slice(1)
                        : formData.individualLesson.student
                        ? formData.individualLesson.student.name
                            .charAt(0)
                            .toUpperCase() +
                          formData.individualLesson.student.name.slice(1)
                        : "Not Assigned"
                    }`
                  ],
                  style: "lessonStyleDate"
                },
                {
                  text: [
                    { text: "Lesson Title:", style: "label" },
                    ` ${formData.individualLesson.title
                      .charAt(0)
                      .toUpperCase() +
                      formData.individualLesson.title.slice(1)}`
                  ],
                  style: "lessonStyleDate"
                }
              ],
              style: "margin"
            },
            {
              stack: [
                {
                  text: [
                    {
                      text: "Start date: ",
                      style: "label"
                    },
                    `${
                      formData.individualLesson.dateBoolean === true
                        ? moment(formData.individualLesson.start, "x")
                            .tz(user.defaultTimezone)
                            .format("llll")
                        : "No Date"
                    }`
                  ],
                  style: "lessonStyleDate"
                },
                {
                  text: [
                    {
                      text: "End date:",
                      style: "label"
                    },
                    ` ${
                      formData.individualLesson.dateBoolean === true
                        ? moment(formData.individualLesson.end, "x")
                            .tz(user.defaultTimezone)
                            .format("llll")
                        : "No Date"
                    }`
                  ],
                  style: "lessonStyleDate"
                }
              ],
              style: "margin"
            }
          ]
        },
        {
          columns: [
            {
              stack: [
                {
                  text: [
                    { text: `Lesson Objective:`, style: "label" },
                    ` ${formData.individualLesson.objective}`
                  ],
                  style: "lessonStyle"
                },
                {
                  text: [
                    { text: `Lesson Comments:`, style: "label" },
                    ` ${stripHtml(formData.individualLesson.comments)}`
                  ],
                  style: "lessonStyle"
                }
              ],
              style: "margin"
            }
          ]
        },

        topicArray
      ],

      styles: {
        header: {
          fontSize: 15,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        footer: {
          fontSize: 10,
          bold: true,
          alignment: "center",
          margin: [5, 11]
        },
        margin: {
          margin: [0, 10]
        },
        margin2: {
          margin: [0, 0]
        },
        lessonStyle: {
          margin: [0, 5],
          fontSize: 10
        },
        lessonStyleDate: {
          margin: [0, 5],
          fontSize: 9
        },
        topicHeader: {
          margin: [0, 5],
          fontSize: 12,
          bold: true
        },
        topicTitle: {
          margin: [0, 5],
          fontSize: 10,
          bold: true,
          alignment: "center"
        },
        label: {
          bold: true
        }
      },
      pageSize: "A5"
    };
    pdfMake.createPdf(docDefinition).download();
  };

  useEffect(() => {
    getLessons({ initialPageNumberLessons: "first" });
  }, [getLessons]);

  // Get all lessons for the create topic and add to lesson dropdown select

  useEffect(() => {
    getTopics({ initialPageNumberTopics: "first" });
  }, [getTopics]);

  var styles = {
    cursor: "pointer"
  };

  // Search Lessons

  useEffect(() => {
    if (searchLessons) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchLessons: searchLessons
        };
      });
    }
  }, [searchLessons]);

  const onChangeLessons = e =>
    setFormData({
      ...formData,
      searchValueLessons: e.target.value
    });

  const lessonsSearchFunc = () => {
    searchLessonsFunction({
      keywordsLessons: formData.searchValueLessons,
      arrayId: formData.filterArrayId,
      arrayType: formData.filterArrayType,
      dateBoolean: formData.noDate,
      pageNumberLessons: formData.pageNumberLessons
    });
    setPaginationModeLessons("search");
  };

  console.log(formData);

  const onKeyPressEnterLessons = e => {
    if (e.key === "Enter") {
      lessonsSearchFunc();
    }
  };

  const clearLessonsFunc = () => {
    const newArray = formData.mergedArrays.map((item, index) => {
      return {
        ...item,
        checked: false
      };
    });
    setFormData({
      ...formData,
      searchValueLessons: "",
      mergedArrays: newArray,
      searchLessons: [],
      filterArrayId: "",
      noDate: false
    });
    clearLessonsSearchReducer();
    setPaginationModeLessons("date");
    return lessons;
  };

  // Search Topics

  useEffect(() => {
    if (searchTopics) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchTopics: searchTopics
        };
      });
    }
  }, [searchTopics]);

  const onChangeTopics = e =>
    setFormData({
      ...formData,
      searchValueTopics: e.target.value
    });

  const topicsSearchFunc = () => {
    searchTopicsFunction({
      keywordsTopics: formData.searchValueTopics,
      pageNumberTopics: formData.pageNumberTopics
    });
    setPaginationModeTopics("search");
  };

  const onKeyPressEnterTopics = e => {
    if (e.key === "Enter") {
      topicsSearchFunc();
    }
  };

  const clearTopicsFunc = () => {
    setFormData({
      ...formData,
      searchValueTopics: "",
      searchTopics: []
    });
    clearTopicsSearchReducer();
    setPaginationModeTopics("date");
    return topics;
  };

  // Lessons Map

  useEffect(() => {
    const searchLessonsMap = () => {
      if (formData.searchLessons === "No Results") {
        return [];
      } else if (formData.searchLessons.length !== 0) {
        return formData.searchLessons;
      } else {
        return lessons;
      }
    };
    if (user.defaultTimezone) {
      const lessonClick = item => {
        setFormData(prevState => {
          return {
            ...prevState,
            modalCreateLesson: false,
            modalSeeLesson: true,
            lessonTopic: true,
            modalCreateTopic: false,
            modalSeeTopic: false,
            individualLesson: item
          };
        });
      };
      const newLessonArray = searchLessonsMap().map((item, index) => {
        return {
          title: item.title,
          class: item.groupIds
            ? item.groupIds.name
            : item.student
            ? item.student.name
            : "Not Assigned",

          date:
            item.dateBoolean === false
              ? "No date"
              : moment(item.start, "x")
                  .tz(user.defaultTimezone)
                  .format("YYYY MM DD hh:mm a"),
          clickEvent: () => {
            lessonClick(item);
          }
        };
      });

      setFormData(prevState => {
        return {
          ...prevState,
          dataLessons: {
            ...prevState.dataLessons,
            rows: newLessonArray
          },

          modalCreateLesson: prevLesson ? true : false,
          modalDeleteLesson: false,
          modalCreateTopic: false,
          modalSeeLesson: false,
          modalSeeTopic: false,
          individualLesson: {
            _id: prevLesson ? prevState.individualLesson._id : "",
            title: "",
            comments: "",
            objective: "",
            date: "",
            groupIds: {
              name: ""
            },
            student: {
              name: ""
            },
            start: "",
            topicIds: [],
            timerArray: [],
            timer: "",
            switch: false,
            vibrate: false,
            sound: false,
            assignedType: "none"
          }
        };
      });
    }
  }, [lessons, formData.searchLessons, user.defaultTimezone]);

  useEffect(() => {
    const newArray = lessonsAll.map((item, index) => {
      return {
        text: item.title,
        value: item._id
      };
    });
    setFormData(prevState => {
      return {
        ...prevState,

        lessonOptions: newArray
      };
    });
  }, [lessonsAll]);

  // Topics Map

  useEffect(() => {
    if (documentDelete) {
      clearDocumentDelete();
      const searchTopicsMap = () => {
        if (formData.searchTopics === "No Results") {
          return [];
        } else if (formData.searchTopics.length !== 0) {
          return formData.searchTopics;
        } else {
          return topics;
        }
      };

      const topicClick = item => {
        setFormData(prevState => {
          return {
            ...prevState,
            modalCreateLesson: false,
            lessonTopic: false,
            modalCreateTopic: false,
            modalSeeTopic: true,
            individualTopic: item
          };
        });
      };

      const newTopicArray = searchTopicsMap().map((item2, index) => {
        return {
          title: item2.title,
          level: item2.level,
          grammar: item2.grammar.map((item, index, { length }) => {
            if (index === length - 1) {
              return item;
            } else {
              return item + ", ";
            }
          }),
          vocabulary: item2.vocabulary.map((item, index, { length }) => {
            if (index === length - 1) {
              return item;
            } else {
              return item + ", ";
            }
          }),
          clickEvent: () => {
            topicClick(item2);
          }
        };
      });
      setFormData(prevState => {
        const newLessonArray = prevState.lessonOptions.map((item, index) => {
          return {
            ...item,
            checked: false
          };
        });
        return {
          ...prevState,
          dataTopics: {
            ...prevState.dataTopics,
            rows: newTopicArray
          },

          individualTopic: removeDocumentTopic,

          modalDeleteTopic: false,

          modalCreateLesson: false,
          modalSeeLesson: false,
          modalSeeTopic: false,
          lessonOptions: newLessonArray,
          lessonChosen: ""
        };
      });
    } else {
      const searchTopicsMap = () => {
        if (formData.searchTopics === "No Results") {
          return [];
        } else if (formData.searchTopics.length !== 0) {
          return formData.searchTopics;
        } else {
          return topics;
        }
      };

      const topicClick = item => {
        setFormData(prevState => {
          return {
            ...prevState,
            modalCreateLesson: false,
            lessonTopic: false,
            modalCreateTopic: false,
            modalSeeTopic: true,
            individualTopic: item
          };
        });
      };

      const newTopicArray = searchTopicsMap().map((item2, index) => {
        return {
          title: item2.title,
          level: item2.level,
          grammar: item2.grammar.map((item, index, { length }) => {
            if (index === length - 1) {
              return item;
            } else {
              return item + ", ";
            }
          }),
          vocabulary: item2.vocabulary.map((item, index, { length }) => {
            if (index === length - 1) {
              return item;
            } else {
              return item + ", ";
            }
          }),
          clickEvent: () => {
            topicClick(item2);
          }
        };
      });
      setFormData(prevState => {
        const newLessonArray = prevState.lessonOptions.map((item, index) => {
          return {
            ...item,
            checked: false
          };
        });
        return {
          ...prevState,
          dataTopics: {
            ...prevState.dataTopics,
            rows: newTopicArray
          },
          modalCreateTopic: false,
          individualTopic: {
            title: "",
            text: "",
            level: "",
            vocabulary: [],
            grammar: [],
            youtube: [],
            links: [],
            documents: []
          },

          modalDeleteTopic: false,

          modalCreateLesson: false,
          modalSeeLesson: false,
          modalSeeTopic: false,
          lessonOptions: newLessonArray,
          lessonChosen: ""
        };
      });
    }
  }, [topics, formData.searchTopics]);

  useEffect(() => {
    if (prevLesson) {
      setFormData(prevState => {
        const newArray = prevState.pushList.map((item, index) => {
          return {
            ...item,
            selected: false
          };
        });
        return {
          ...prevState,
          pushList: newArray,
          pushTopic: {
            _id: "",
            title: ""
          }
        };
      });
    }
  }, [prevLesson]);

  const lessonClickClose = item => {
    setFormData({
      ...formData,
      modalSeeLesson: false,
      timerState: "notStarted",
      individualLesson: {
        title: "",
        comments: "",
        objective: "",
        date: "",
        groupIds: {
          name: ""
        },
        student: {
          name: ""
        },
        start: "",
        topicIds: [],
        timer: "",
        timerArray: [],
        switch: false,
        vibrate: false,
        sound: false,
        assignedType: "none"
      }
    });
  };
  const topicClickClose = item => {
    setFormData({
      ...formData,
      modalSeeTopic: false,
      individualTopic: {
        title: "",
        text: "",
        level: "",
        vocabulary: [],
        grammar: [],
        youtube: [],
        links: [],
        documents: []
      }
    });
  };

  const lessonPage = () => {
    setFormData({
      ...formData,
      lessonTopic: true
    });
  };

  const topicPage = () => {
    setFormData({
      ...formData,
      lessonTopic: false
    });
  };
  const createLessonModal = () => {
    setFormData({
      ...formData,
      modalCreateLesson: !formData.modalCreateLesson,
      modalSeeLesson: false
    });
  };
  const createLessonModalClose = () => {
    setFormData({
      ...formData,
      modalCreateLesson: false,
      timerState: "notStarted",
      individualLesson: {
        title: "",
        comments: "",
        objective: "",
        date: "",
        groupIds: {
          name: ""
        },
        student: {
          name: ""
        },
        start: "",
        end: "",
        topicIds: [],
        timer: "",
        timerArray: [],
        switch: false,
        vibrate: false,
        sound: false,
        assignedType: "none"
      }
    });
  };

  const createTopicModalClose = () => {
    const newArray = formData.lessonOptions.map((item, index) => {
      return {
        ...item,
        checked: false
      };
    });
    setFormData(prevState => {
      return {
        ...prevState,
        modalCreateTopic: false,
        lessonChosen: "",
        lessonOptions: newArray,
        individualTopic: {
          title: "",
          text: "",
          level: "",
          vocabulary: [],
          grammar: [],
          youtube: [],
          links: [],
          documents: []
        }
      };
    });
  };

  const createTopicModal = () => {
    setFormData({
      ...formData,
      modalSeeTopic: false,
      modalCreateTopic: true
    });
  };

  const lessonOnDelete = () => {
    setLoadingDeleteLesson(true);
    deleteLesson(formData.individualLesson._id);
  };
  const topicOnDelete = () => {
    setLoadingDeleteTopic(true);
    deleteTopic(formData.individualTopic._id);
  };

  useEffect(() => {
    if (location.state) {
      setFormData(prevState => {
        return {
          ...prevState,
          lessonTopic: location.state.lessonTopic
        };
      });
    }

    if (location.search) {
      const lessonId = location.search.slice(1);
      getLesson(lessonId);
    }
  }, [location, getLesson]);

  // useEffect(() => {
  //   if (location.search) {
  //     const lessonId = location.search.slice(1);
  //     getLesson(lessonId);
  //   }
  // }, [location.search, getLesson]);

  const deleteLessonModalOpen = () => {
    setFormData({
      ...formData,
      modalDeleteLesson: !formData.modalDeleteLesson
    });
  };
  const deleteTopicModalOpen = () => {
    setFormData({
      ...formData,
      modalDeleteTopic: !formData.modalDeleteTopic
    });
  };
  const chooseLessonModalOpen = () => {
    if (formData.lessonChosen === "") {
      getLessonsAll();
    }

    setFormData({
      ...formData,
      modalChooseLesson: !formData.modalChooseLesson
    });
  };

  const lessonsPushFunc = () => {
    if (formData.individualLesson.assignedType === "class") {
      getLessonsPush({
        groupId: formData.individualLesson.groupIds._id,
        start: formData.individualLesson.start
      });
    } else if (formData.individualLesson.assignedType === "student") {
      getLessonsPush({
        studentId: formData.individualLesson.student._id,
        start: formData.individualLesson.start
      });
    }
  };

  const pushTopicModalOpen = (newLesson, topic) => {
    lessonsPushFunc();
    setFormData({
      ...formData,
      modalPushTopic: !formData.modalPushTopic,

      pushTopic: topic
    });
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        pushList: lessonsPush
      };
    });
  }, [lessonsPush]);
  const pushTopicModalClose = () => {
    setFormData({
      ...formData,
      modalPushTopic: false
    });
  };

  const pushTopicFunc = () => {
    const find = formData.pushList.find(item => item.selected === true);
    if (!find) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <p>Please choose a lesson</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      pushTopic({
        topicId: formData.pushTopic._id,
        toLessonId: find._id,
        currentLessonId: formData.individualLesson._id
      });
    }
  };

  const selectPush = lesson => {
    const newArray = formData.pushList.map((item, index) => {
      if (item._id === lesson._id) {
        return {
          ...item,
          selected: true
        };
      } else {
        return {
          ...item,
          selected: false
        };
      }
    });
    setFormData({
      ...formData,
      pushList: newArray
    });
  };

  const duplicateLesson = () => {
    addLessons({
      title: formData.individualLesson.title + " copy",
      start: moment(new Date()).valueOf(),
      end: moment(new Date()).valueOf(),
      groupIds: formData.individualLesson.groupIds,
      student: formData.individualLesson.student,
      comments: formData.individualLesson.comments,
      objective: formData.individualLesson.objective,
      topicIds: formData.individualLesson.topicIds,
      dateBoolean: false,
      timer: formData.individualLesson.timer,
      timerArray: formData.individualLesson.timerArray,
      switch: formData.individualLesson.switch,
      sound: formData.individualLesson.sound,
      vibrate: formData.individualLesson.vibrate,
      assignedType: formData.individualLesson.assignedType
    });
  };

  const playAudio = () => {
    let audio = new Audio(Dingdong);
    audio.play();
  };

  // remove double scroll bar when in modal mode
  useEffect(() => {
    if (
      formData.modalSeeTopic === true ||
      formData.modalSeeLesson === true ||
      formData.modalCreateLesson === true ||
      formData.modalCreateTopic === true
    ) {
      document.body.style.cssText = "overflow-y: hidden !important;";
      // document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
    }
  }, [
    formData.modalSeeTopic,
    formData.modalSeeLesson,
    formData.modalCreateLesson,
    formData.modalCreateTopic
  ]);

  const timerFunction = (start, stop, resume, pause) => {
    if (formData.timerState === "notStarted") {
      return (
        <MDBBtn color="success" onClick={start} size="sm">
          Start
        </MDBBtn>
      );
    } else if (formData.timerState === "start") {
      return (
        <MDBBtn color="red" onClick={pause} size="sm">
          Pause
        </MDBBtn>
      );
    } else if (formData.timerState === "pause") {
      return (
        <MDBBtn color="yellow" onClick={resume} size="sm">
          Resume
        </MDBBtn>
      );
    }
  };

  const resetTimer = (stop, reset) => {
    stop();
    reset();
  };

  const lessonValue = value => {
    const find = formData.lessonOptions.find(item => item.value === value[0]);
    setFormData({
      ...formData,
      lessonChosen: find
    });
  };

  const timerConversion = () => {
    return parseInt(formData.individualLesson.timer, 10) * 60 * 1000;
  };

  const clearPickLesson = () => {
    const newArray = formData.lessonOptions.map((item, index) => {
      return {
        ...item,
        checked: false
      };
    });

    setFormData({
      ...formData,
      lessonChosen: "",
      lessonOptions: newArray
    });
  };

  const messageCheckpoint = item => {
    if (formData.individualLesson.vibrate === true) {
      navigator.vibrate(1500);
    }
    if (formData.individualLesson.sound === true) {
      playAudio();
    }

    return (
      <Fragment>
        <MDBRow>
          <MDBCol>
            <p>{item} has started</p>
          </MDBCol>
        </MDBRow>
      </Fragment>
    );
  };
  const messageFinished = () => {
    if (formData.individualLesson.vibrate === true) {
      navigator.vibrate(1500);
    }
    if (formData.individualLesson.sound === true) {
      playAudio();
    }

    return (
      <Fragment>
        <MDBRow>
          <MDBCol>
            <p>The lesson has finished</p>
          </MDBCol>
        </MDBRow>
      </Fragment>
    );
  };

  const checkpointFunction = () => {
    let array = [
      {
        time: 0,
        callback: () => {
          return toast.info(messageFinished);
        }
      }
    ];

    formData.individualLesson.timerArray.map((item, index) => {
      const newArray = formData.individualLesson.timerArray
        .slice(0, index)
        .map((item2, index) => {
          return item2.timer;
        });

      const currentDuration = sum(newArray);
      if (index === 0) {
        const object = {
          time:
            formData.individualLesson.timer * 1000 * 60 -
            (currentDuration + 1000),
          callback: () => {
            return toast.success(() => messageCheckpoint(item.title));
          }
        };
        return array.push(object);
      } else {
        const object = {
          time: (formData.individualLesson.timer - currentDuration) * 60 * 1000,
          callback: () => {
            return toast.success(() => messageCheckpoint(item.title));
          }
        };
        return array.push(object);
      }
    });

    return array;
  };

  useEffect(() => {
    if (individualLesson) {
      setFormData(prevState => {
        return {
          ...prevState,
          modalSeeLesson: true,
          individualLesson: individualLesson
        };
      });
    }
  }, [individualLesson, user.defaultTimezone]);

  // Filtering

  useEffect(() => {
    getGroupsSelect();
    getStudentsSelect();
  }, [getGroupsSelect, getStudentsSelect]);

  useEffect(() => {
    if (groupsSelect || studentsSelect) {
      let classArray = groupsSelect.map((item, index) => {
        return {
          text: item.name,
          value: {
            id: item._id,
            type: "class"
          }
        };
      });
      let studentsArray = studentsSelect.map((item, index) => {
        return {
          text: item.name,
          value: {
            id: item._id,
            type: "student"
          }
        };
      });
      const newArray = union(studentsArray, classArray);
      setFormData(prevState => {
        return {
          ...prevState,
          mergedArrays: newArray
        };
      });
    }
  }, [groupsSelect, studentsSelect]);

  const handleSelectClass = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        filterArrayId: value[0].id,
        filterArrayType: value[0].type
      });
    }
  };

  const onChangeCheck = e => {
    setFormData({
      ...formData,
      noDate: !formData.noDate
    });
  };

  // Launch Skype Video

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://secure.skypeassets.com/i/scom/js/skype-uri.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Pagination Lessons

  const isActive1 = page => {
    if (initialPageNumberLessons === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumberLessons === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationLessons = () => {
    if (paginationModeLessons === "date") {
      getLessons({ initialPageNumberLessons: "first" });
    } else if (paginationModeLessons === "search") {
      searchLessonsFunction({
        pageNumberLessons: "first",
        arrayId: formData.filterArrayId,
        arrayType: formData.filterArrayType,
        keywordsLessons: formData.searchValueLessons,
        dateBoolean: formData.noDate
      });
    }
  };

  const previousPaginationLessons = () => {
    if (paginationModeLessons === "date") {
      getLessons({ initialPageNumberLessons: initialPageNumberLessons - 1 });
    } else if (paginationModeLessons === "search") {
      searchLessonsFunction({
        pageNumberLessons: pageNumberLessons - 1,
        arrayId: formData.filterArrayId,
        arrayType: formData.filterArrayType,
        keywordsLessons: formData.searchValueLessons,
        dateBoolean: formData.noDate
      });
    }
  };

  const currentPaginationLessons = index => {
    if (paginationModeLessons === "date") {
      getLessons({ initialPageNumberLessons: index + 1 });
    } else if (paginationModeLessons === "search") {
      searchLessonsFunction({
        pageNumberLessons: index + 1,
        arrayId: formData.filterArrayId,
        arrayType: formData.filterArrayType,
        keywordsLessons: formData.searchValueLessons,
        dateBoolean: formData.noDate
      });
    }
  };

  const nextPaginationLessons = () => {
    if (paginationModeLessons === "date") {
      getLessons({ initialPageNumberLessons: initialPageNumberLessons + 1 });
    } else if (paginationModeLessons === "search") {
      searchLessonsFunction({
        pageNumberLessons: pageNumberLessons + 1,
        arrayId: formData.filterArrayId,
        arrayType: formData.filterArrayType,
        keywordsLessons: formData.searchValueLessons,
        dateBoolean: formData.noDate
      });
    }
  };

  const lastPaginationLessons = () => {
    if (paginationModeLessons === "date") {
      getLessons({ initialPageNumberLessons: "last" });
    } else if (paginationModeLessons === "search") {
      searchLessonsFunction({
        pageNumberLessons: "last",
        arrayId: formData.filterArrayId,
        arrayType: formData.filterArrayType,
        keywordsLessons: formData.searchValueLessons,
        dateBoolean: formData.noDate
      });
    }
  };

  // Pagination Topics

  const isActive3 = page => {
    if (initialPageNumberTopics === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive4 = page => {
    if (pageNumberTopics === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationTopics = () => {
    if (paginationModeTopics === "date") {
      getTopics({ initialPageNumberTopics: "first" });
    } else if (paginationModeTopics === "search") {
      searchTopicsFunction({
        pageNumberTopics: "first",
        keywordsTopics: formData.searchValueTopics
      });
    }
  };

  const previousPaginationTopics = () => {
    if (paginationModeTopics === "date") {
      getTopics({ initialPageNumberTopics: initialPageNumberTopics - 1 });
    } else if (paginationModeTopics === "search") {
      searchTopicsFunction({
        pageNumberTopics: pageNumberTopics - 1,
        keywordsTopics: formData.searchValueTopics
      });
    }
  };

  const currentPaginationTopics = index => {
    if (paginationModeTopics === "date") {
      getTopics({ initialPageNumberTopics: index + 1 });
    } else if (paginationModeTopics === "search") {
      searchTopicsFunction({
        pageNumberTopics: index + 1,
        keywordsTopics: formData.searchValueTopics
      });
    }
  };

  const nextPaginationTopics = () => {
    if (paginationModeTopics === "date") {
      getTopics({ initialPageNumberTopics: initialPageNumberTopics + 1 });
    } else if (paginationModeTopics === "search") {
      searchTopicsFunction({
        pageNumberTopics: pageNumberTopics + 1,
        keywordsTopics: formData.searchValueTopics
      });
    }
  };

  const lastPaginationTopics = () => {
    if (paginationModeTopics === "date") {
      getTopics({ initialPageNumberTopics: "last" });
    } else if (paginationModeTopics === "search") {
      searchTopicsFunction({
        pageNumberTopics: "last",
        keywordsTopics: formData.searchValueTopics
      });
    }
  };

  const clearsaveLessonBoolean = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        saveLessonBoolean: false
      };
    });
  };
  const clearsaveTopicBoolean = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        saveTopicBoolean: false
      };
    });
  };
  const lessonSave = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        saveLessonBoolean: true
      };
    });
  };
  const topicSave = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        saveTopicBoolean: true
      };
    });
  };

  // before return

  return loading ? (
    <Spinner />
  ) : !user.defaultTimezone ? (
    <Spinner />
  ) : (
    // Tables

    <Fragment>
      <MDBCard narrow>
        <MDBCardHeader className="view view-cascade  primary-color d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
          <div>
            <MDBBtn
              onClick={lessonPage}
              outline={formData.lessonTopic === true ? false : true}
              size="sm"
              color="white"
              className="quickSand px-2"
            >
              Lessons
            </MDBBtn>
            <MDBBtn
              onClick={topicPage}
              outline={formData.lessonTopic === true ? true : false}
              size="sm"
              color="white"
              className="px-2 quickSand"
            >
              Topics
            </MDBBtn>
          </div>
          <p className="h5 quickSand white-text mx-2">Lesson Planning</p>
          <div>
            <MDBBtn
              onClick={
                formData.lessonTopic === true
                  ? createLessonModal
                  : createTopicModal
              }
              outline
              rounded
              size="sm"
              color="white"
              className="h6 quickSand px-2"
            >
              <i className="fas fa-plus mt-0" />
              {formData.lessonTopic === true
                ? " Add new lesson"
                : " Add new topic"}
            </MDBBtn>
          </div>
        </MDBCardHeader>
        <MDBCardBody cascade className="hideDoubleScroll">
          {formData.lessonTopic === true ? (
            <Fragment>
              <MDBRow className="middleVertical" end>
                <MDBCol sm="2" className="col-12 text-center">
                  <div className="inputBlueLesson mt-2 krubFont">
                    <MDBInput
                      label="No Date"
                      checked={formData.noDate}
                      type="checkbox"
                      id="checkbox2"
                      onChange={onChangeCheck}
                    />
                  </div>
                </MDBCol>
                <MDBCol className="col-5" sm="3">
                  <div className="inputBlueLesson krubFont dropDownStudents">
                    <MDBSelect
                      options={formData.mergedArrays}
                      search
                      getValue={handleSelectClass}
                      label="Filter by class or student"
                      className="blue-text"
                      labelClass="blue-text"
                    />
                  </div>
                </MDBCol>
                <MDBCol sm="4" className="col-7">
                  <div className="krubFont">
                    <MDBInput
                      className="blue-text"
                      label="Search Lesson Titles"
                      labelClass="blue-text"
                      type="text"
                      value={formData.searchValueLessons}
                      onChange={onChangeLessons}
                      onKeyPress={onKeyPressEnterLessons}
                    />
                  </div>
                </MDBCol>

                <MDBCol className="col-12 middleVertical" sm="3">
                  <MDBRow>
                    <div className="krubFont">
                      <MDBBtn
                        size="sm"
                        onClick={lessonsSearchFunc}
                        color="blue darken-4"
                      >
                        Filter
                      </MDBBtn>
                    </div>
                    <div className="krubFont">
                      <MDBBtn size="sm" onClick={clearLessonsFunc} color="info">
                        Clear
                      </MDBBtn>
                    </div>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow center>
                <p className="quickSand font-weight-bold">
                  <MDBIcon far icon="calendar-alt" />
                  {` Timezone: ${user.defaultTimezone}`}
                </p>
              </MDBRow>
              {(formData.searchLessons.length === 0 || "No Results") &&
              lessons.length === 0 &&
              lessonLoading === false ? (
                <div className="cardWidth text-center">
                  <img
                    className="imageFlex"
                    src={
                      "https://res.cloudinary.com/unnamed/image/upload/v1578413363/No_lessons_found_twqsuy.png"
                    }
                    alt=""
                  />
                </div>
              ) : (
                <div className="tableLesson" style={{ minHeight: "30rem" }}>
                  <MDBDataTable
                    paging={false}
                    searching={false}
                    sortable={false}
                    btn
                    small
                    fixed
                    className="text-center quickSand tableFont2"
                    info={false}
                    hover
                    data={formData.dataLessons}
                    bordered
                    theadColor="blue lighten-4"
                    style={styles}
                  />
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <MDBRow className=" middleVertical5" center>
                <MDBCol className="col-2" sm="2"></MDBCol>
                <MDBCol sm="4" className="col-6">
                  <MDBInput
                    className="blue-text"
                    label="Search your topics"
                    labelClass="blue-text"
                    type="text"
                    containerClass="active-pink active-pink-2 mt-0 mb-3"
                    value={formData.searchValueTopics}
                    onChange={onChangeTopics}
                    onKeyPress={onKeyPressEnterTopics}
                  />
                </MDBCol>
                <MDBCol className="col-2" sm="2">
                  <MDBRow className="align-items-center">
                    <div
                      className={
                        formData.searchValueTopics.length !== 0
                          ? "mx-1"
                          : "d-none"
                      }
                    >
                      <MDBIcon
                        icon="search"
                        onClick={topicsSearchFunc}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      className={
                        formData.searchTopics.length !== 0 ||
                        formData.searchTopics === "No Results"
                          ? "mx-1"
                          : "d-none"
                      }
                    >
                      <MDBIcon
                        icon="times-circle"
                        onClick={clearTopicsFunc}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              {(formData.searchTopics.length === 0 || "No Results") &&
              topics.length === 0 &&
              topicLoading === false ? (
                <div className="cardWidth text-center">
                  <img
                    className="imageFlex"
                    src={
                      "https://res.cloudinary.com/unnamed/image/upload/v1578416915/No_topics_found_libdks.png"
                    }
                    alt=""
                  />
                </div>
              ) : (
                <div className="tableLesson" style={{ minHeight: "26rem" }}>
                  <MDBDataTable
                    btn
                    small
                    fixed
                    className="text-center quickSand tableFont2"
                    info={false}
                    hover
                    data={formData.dataTopics}
                    bordered
                    theadColor="blue lighten-4"
                    style={styles}
                    paging={false}
                    searching={false}
                    sortable={false}
                  />
                </div>
              )}
            </Fragment>
          )}
        </MDBCardBody>

        {/* Pagination Lessons Medium Size*/}

        <div
          className={
            formData.lessonTopic === true
              ? "middleVertical mt-4 mb-3 paginationLessonsMedium"
              : "d-none"
          }
        >
          <MDBRow>
            <MDBCol>
              <MDBPagination circle>
                <MDBPageItem onClick={firstPaginationLessons}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationLessons}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationModeLessons === "date"
                  ? [...Array(initialNumberOfPagesLessons)].map(
                      (item, index) => {
                        if (index + 1 === initialPageNumberLessons) {
                          return (
                            <MDBPageItem
                              key={index}
                              active={isActive1(index + 1)}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons - 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons - 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons - 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons + 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons + 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons + 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else {
                          return null;
                        }
                      }
                    )
                  : [...Array(numberOfPagesLessons)].map((item, index) => {
                      if (index + 1 === pageNumberLessons) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationLessons}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationLessons}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>

        {/* Pagination Lessons  Small Size*/}

        <div
          className={
            formData.lessonTopic === true
              ? "middleVertical mt-4 mb-3 paginationLessonsSmall"
              : "d-none"
          }
        >
          <MDBRow>
            <MDBCol>
              <MDBPagination circle size="sm">
                <MDBPageItem onClick={firstPaginationLessons}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationLessons}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationModeLessons === "date"
                  ? [...Array(initialNumberOfPagesLessons)].map(
                      (item, index) => {
                        if (index + 1 === initialPageNumberLessons) {
                          return (
                            <MDBPageItem
                              key={index}
                              active={isActive1(index + 1)}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons - 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons - 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons - 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons + 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons + 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberLessons + 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationLessons(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else {
                          return null;
                        }
                      }
                    )
                  : [...Array(numberOfPagesLessons)].map((item, index) => {
                      if (index + 1 === pageNumberLessons) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive2(index + 1)}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberLessons + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationLessons(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationLessons}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationLessons}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>

        {/* Pagination Topics Medium Size*/}

        <div
          className={
            formData.lessonTopic === false
              ? "middleVertical mt-4 mb-3 paginationLessonsMedium"
              : "d-none"
          }
        >
          <MDBRow>
            <MDBCol>
              <MDBPagination circle>
                <MDBPageItem onClick={firstPaginationTopics}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationTopics}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationModeTopics === "date"
                  ? [...Array(initialNumberOfPagesTopics)].map(
                      (item, index) => {
                        if (index + 1 === initialPageNumberTopics) {
                          return (
                            <MDBPageItem
                              key={index}
                              active={isActive3(index + 1)}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics - 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics - 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics - 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics + 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics + 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics + 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else {
                          return null;
                        }
                      }
                    )
                  : [...Array(numberOfPagesTopics)].map((item, index) => {
                      if (index + 1 === pageNumberTopics) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive4(index + 1)}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationTopics}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationTopics}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>

        {/* Pagination Topics Small Size*/}

        <div
          className={
            formData.lessonTopic === false
              ? "middleVertical mt-4 mb-3 paginationLessonsSmall"
              : "d-none"
          }
        >
          <MDBRow>
            <MDBCol>
              <MDBPagination circle size="sm">
                <MDBPageItem onClick={firstPaginationTopics}>
                  <MDBPageNav className="page-link">
                    <span>First</span>
                  </MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={previousPaginationTopics}>
                  <MDBPageNav className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </MDBPageNav>
                </MDBPageItem>
                {paginationModeTopics === "date"
                  ? [...Array(initialNumberOfPagesTopics)].map(
                      (item, index) => {
                        if (index + 1 === initialPageNumberTopics) {
                          return (
                            <MDBPageItem
                              key={index}
                              active={isActive3(index + 1)}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics - 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics - 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics - 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics + 1) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics + 2) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else if (index + 1 === initialPageNumberTopics + 3) {
                          return (
                            <MDBPageItem
                              key={index}
                              onClick={() => currentPaginationTopics(index)}
                            >
                              <MDBPageNav className="page-link">
                                {index + 1}{" "}
                                <span className="sr-only">(current)</span>
                              </MDBPageNav>
                            </MDBPageItem>
                          );
                        } else {
                          return null;
                        }
                      }
                    )
                  : [...Array(numberOfPagesTopics)].map((item, index) => {
                      if (index + 1 === pageNumberTopics) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive4(index + 1)}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumberTopics + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationTopics(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                <MDBPageItem onClick={nextPaginationTopics}>
                  <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                </MDBPageItem>
                <MDBPageItem onClick={lastPaginationTopics}>
                  <MDBPageNav className="page-link">Last</MDBPageNav>
                </MDBPageItem>
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBCard>

      {/* Create Lesson */}

      <MDBContainer>
        <div className="lessonsModal">
          <MDBModal isOpen={formData.modalCreateLesson} className="modal-full">
            <div className="modal-full modal-content ">
              <MDBRow center className="middleVertical py-3">
                <p className="text-center quickSand text-white h2">
                  {formData.individualLesson.title
                    ? "Edit Lesson"
                    : "Create a New Lesson"}
                </p>
              </MDBRow>

              <CreateLesson
                saveLessonBoolean={formData.saveLessonBoolean}
                clearsaveLessonBoolean={clearsaveLessonBoolean}
                individualLesson={formData.individualLesson}
                pushTopicModalOpen={pushTopicModalOpen}
                pushTopicModalClose={pushTopicModalClose}
              />

              <MDBModalFooter className="backgroundLessons lessonFooterZ">
                {formData.individualLesson.title ? (
                  <MDBBtn
                    className="mr-auto p-2 col-example quickSand"
                    onClick={deleteLessonModalOpen}
                    color="danger"
                  >
                    Delete this lesson
                  </MDBBtn>
                ) : null}

                <MDBBtn
                  className="p-2 col-example mr-3 quickSand"
                  onClick={lessonSave}
                  color="primary"
                  disabled={loadingButtonLessons}
                >
                  {loadingButtonLessons === true && (
                    <div className="spinner-grow spinner-grow-sm" />
                  )}
                  Save Changes
                </MDBBtn>

                <MDBBtn
                  className="p-2 col-example mr-3 quickSand"
                  onClick={
                    formData.lessonTopic === true
                      ? createLessonModalClose
                      : createTopicModal
                  }
                  color="color-one"
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </div>

        {/* Create Topic   */}

        <div className="lessonsModal">
          <MDBModal isOpen={formData.modalCreateTopic} className="modal-full">
            <div className="modal-full modal-content ">
              <MDBRow center className="middleVertical py-3">
                <p className="text-center quickSand text-white h2">
                  {formData.individualTopic.title
                    ? "Edit Topic"
                    : "Create a New Topic"}
                </p>
              </MDBRow>

              <CreateTopic
                saveTopicBoolean={formData.saveTopicBoolean}
                clearsaveTopicBoolean={clearsaveTopicBoolean}
                lessonChosen={formData.lessonChosen}
                individualTopic={formData.individualTopic}
              />

              <MDBModalFooter className="backgroundLessons">
                {formData.individualTopic.title ? (
                  <MDBBtn
                    className="mr-auto p-2 col-example quickSand"
                    onClick={deleteTopicModalOpen}
                    color="danger"
                  >
                    Delete this topic
                  </MDBBtn>
                ) : (
                  <Fragment>
                    <MDBBtn
                      className="mr-auto p-2 col-example"
                      onClick={chooseLessonModalOpen}
                      color="color-one"
                    >
                      {formData.lessonChosen
                        ? formData.lessonChosen.text
                        : "Choose Lesson"}
                    </MDBBtn>
                  </Fragment>
                )}
                <MDBBtn
                  className="p-2 col-example mr-3 quickSand"
                  onClick={topicSave}
                  color="primary"
                  disabled={loadingButtonTopics}
                >
                  {loadingButtonTopics === true && (
                    <div className="spinner-grow spinner-grow-sm" />
                  )}
                  {formData.lessonChosen ? "Save/Add to Lesson" : "Save"}
                </MDBBtn>

                <MDBBtn
                  className="p-2 col-example mr-3 quickSand"
                  onClick={createTopicModalClose}
                  color="color-one"
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </div>
        <div className="lessonsModal hideDoubleScroll">
          <MDBModal isOpen={formData.modalSeeLesson} className="modal-full">
            <div className="modal-full modal-content ">
              <MDBRow center className="middleVertical py-3">
                <p className="quickSand text-white h2 text-center">
                  {formData.individualLesson.title.charAt(0).toUpperCase() +
                    formData.individualLesson.title.slice(1)}
                </p>
              </MDBRow>

              <div className="modalBottomMargin rounded backgroundLesson2 mx-3">
                <MDBContainer>
                  <MDBRow className="pt-5">
                    <MDBCol md="6" className="mr-auto text-center">
                      {formData.individualLesson.groupIds ? (
                        <p className="h5 pb-1 montserratFont">
                          Class:{" "}
                          <span className="lessonFontSize4">
                            <strong>
                              {formData.individualLesson.groupIds.name
                                .charAt(0)
                                .toUpperCase() +
                                formData.individualLesson.groupIds.name.slice(
                                  1
                                )}
                            </strong>
                          </span>
                        </p>
                      ) : formData.individualLesson.student ? (
                        <p className="h5 pb-1 montserratFont">
                          Student:{" "}
                          <span className="lessonFontSize4">
                            <strong>
                              {formData.individualLesson.student.name
                                .charAt(0)
                                .toUpperCase() +
                                formData.individualLesson.student.name.slice(1)}
                            </strong>
                          </span>
                        </p>
                      ) : (
                        <p className="h5 pb-1 montserratFont black-text">
                          No student or class assigned
                        </p>
                      )}

                      {formData.individualLesson.student ? (
                        <MDBRow className="middleVertical">
                          <p className="h5 pb-2 montserratFont mr-1">
                            {formData.individualLesson.student.preferredVideo
                              ? `${formData.individualLesson.student.preferredVideo}:`
                              : ""}
                          </p>
                          <p className="lessonFontSize4 font-weight-bold">
                            {formData.individualLesson.student
                              .preferredVideo === "Skype"
                              ? formData.individualLesson.student.skype
                              : formData.individualLesson.student
                                  .preferredVideo === "Whatsapp"
                              ? formData.individualLesson.student.whatsapp
                              : formData.individualLesson.student
                                  .preferredVideo === "Wechat"
                              ? formData.individualLesson.student.wechat
                              : ""}
                          </p>
                          <div
                            id="MyDiv"
                            className={
                              formData.individualLesson.student
                                .preferredVideo === "Skype"
                                ? "ml-2 mb-2"
                                : "d-none"
                            }
                          >
                            <a
                              onclick="Skype.tryAnalyzeSkypeUri('call', '0');"
                              href={`skype:live:${formData.individualLesson.student.skype}?call`}
                            >
                              <MDBIcon
                                fab
                                icon="skype"
                                className="skypeColor growSkype"
                                size="lg"
                              />
                            </a>
                          </div>
                        </MDBRow>
                      ) : null}
                    </MDBCol>
                    {formData.individualLesson.dateBoolean === true ? (
                      <MDBCol md="6" className="text-center">
                        <p className="h6 pb-1 montserratFont">
                          Start Date:{" "}
                          <span className="tableFont2 quickSand">
                            <strong>
                              {moment(formData.individualLesson.start, "x")
                                .tz(user.defaultTimezone)
                                .format("llll")}
                            </strong>
                          </span>
                        </p>
                        <p className="h6 pb-1 montserratFont">
                          End Date:{" "}
                          <span className="tableFont2 quickSand">
                            <strong>
                              {moment(formData.individualLesson.end, "x")
                                .tz(user.defaultTimezone)
                                .format("llll")}
                            </strong>
                          </span>
                        </p>
                      </MDBCol>
                    ) : null}
                  </MDBRow>
                </MDBContainer>
                <hr />
                <MDBContainer>
                  <MDBRow>
                    <MDBCol
                      md="12"
                      className={
                        formData.individualLesson.objective !== ""
                          ? ""
                          : "d-none"
                      }
                    >
                      <p className="montserratFont lessonFontSize pb-1">
                        Objectives
                      </p>
                      <p className="lessonFontSize2 quickSand pb-1">
                        {formData.individualLesson.objective}
                      </p>
                    </MDBCol>
                    <MDBCol
                      md="12"
                      className={
                        formData.individualLesson.comments !== ""
                          ? ""
                          : "d-none"
                      }
                    >
                      <p className="pb-1 montserratFont lessonFontSize">
                        Comments
                      </p>
                      <div className="ql-editor" id="fixMargin">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formData.individualLesson.comments
                          }}
                          className="lessonFontSize2"
                        ></p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
                {formData.individualLesson.comments !== "" ||
                formData.individualLesson.objective !== "" ? (
                  <hr />
                ) : null}
                {formData.individualLesson.topicIds.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <MDBContainer className="rounded backgroundLesson2 mb-5">
                        <MDBRow className="pt-2 ">
                          <MDBCol md="12" className="mb-1">
                            <p className="text-center h5 montserratFont font-weight-bold">
                              {item.title.charAt(0).toUpperCase() +
                                item.title.slice(1)}
                            </p>
                            <Fragment>
                              <div className={item.text !== "" ? "" : "d-none"}>
                                <p className="h6 pb-1 montserratFont lessonFontSize">
                                  Notes / Explanation
                                </p>
                                <div className="ql-editor " id="fixMargin">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.text
                                    }}
                                    className="lessonFontSize2"
                                  ></p>
                                </div>
                              </div>
                            </Fragment>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow
                          className={
                            (item.vocabulary.length !== 0 &&
                              item.grammar.length === 0) ||
                            (item.vocabulary.length === 0 &&
                              item.grammar.length !== 0)
                              ? "align-self-start"
                              : item.vocabulary.length === 0 &&
                                item.grammar.length === 0
                              ? "d-none"
                              : item.vocabulary.length !== 0 &&
                                item.grammar.length !== 0
                              ? "middleVertical"
                              : "middleVertical"
                          }
                        >
                          <MDBCol
                            md="6"
                            className={
                              item.vocabulary.length !== 0 &&
                              item.grammar.length === 0
                                ? "mb-1"
                                : item.vocabulary.length !== 0 &&
                                  item.grammar.length !== 0
                                ? "mb-1 text-center"
                                : item.vocabulary.length === 0 &&
                                  item.grammar.length === 0
                                ? "d-none"
                                : "d-none"
                            }
                          >
                            <p className="pb-1 montserratFont lessonFontSize">
                              Vocabulary Words
                            </p>

                            <ul className="list-inline  quickSand lessonFontSize2 vocabularyCommas">
                              {item.vocabulary.map((item, index) => {
                                return (
                                  <li key={index} className="list-inline-item">
                                    {item}
                                  </li>
                                );
                              })}
                            </ul>
                          </MDBCol>
                          <MDBCol
                            md="6"
                            className={
                              item.vocabulary.length === 0 &&
                              item.grammar.length !== 0
                                ? ""
                                : item.vocabulary.length !== 0 &&
                                  item.grammar.length !== 0
                                ? "text-center"
                                : item.vocabulary.length === 0 &&
                                  item.grammar.length === 0
                                ? "d-none"
                                : "d-none"
                            }
                          >
                            <p className="montserratFont lessonFontSize pb-1">
                              Grammar Topics
                            </p>
                            <ul className="list-inline lessonFontSize2 quickSand vocabularyCommas">
                              {item.grammar.map((item, index) => {
                                return (
                                  <li key={index} className="list-inline-item">
                                    {item}
                                  </li>
                                );
                              })}
                            </ul>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol
                            md="12"
                            className={
                              item.youtube.length !== 0 ? "mb-4" : "d-none"
                            }
                          >
                            <p className="pb-1 text-center montserratFont lessonFontSize">
                              Youtube Videos
                            </p>
                          </MDBCol>
                          {item.youtube.map((item, index) => {
                            const emb = urlParser.parse(item.url);
                            if (typeof emb === "undefined") {
                              return (
                                <MDBCol key={index} md="6" className="mb-4">
                                  <p>
                                    A Youtube link is not showing since it is an
                                    invalid link. Please remove it in its topics
                                    component.
                                  </p>
                                </MDBCol>
                              );
                            } else {
                              const embUrl = urlParser.create({
                                videoInfo: emb,
                                format: "embed"
                              });

                              return (
                                <MDBCol key={index} md="6" className="mb-4">
                                  <div className="embed-responsive embed-responsive-16by9 mb-1">
                                    <iframe
                                      title="Embeds Page"
                                      className="embed-responsive-item"
                                      src={embUrl}
                                      allowFullScreen
                                    />
                                  </div>
                                </MDBCol>
                              );
                            }
                          })}
                        </MDBRow>
                        <MDBRow
                          className={
                            (item.links.length !== 0 &&
                              item.documents.length === 0) ||
                            (item.links.length === 0 &&
                              item.documents.length !== 0)
                              ? "align-self-start"
                              : item.links.length === 0 &&
                                item.documents.length === 0
                              ? "d-none"
                              : item.links.length !== 0 &&
                                item.documents.length !== 0
                              ? "middleVertical"
                              : "middleVertical"
                          }
                        >
                          <MDBCol
                            md="6"
                            className={
                              item.links.length !== 0 ? "mb-4" : "d-none"
                            }
                          >
                            <Fragment>
                              <p className="text-center pb-1 montserratFont lessonFontSize">
                                Website Links
                              </p>
                            </Fragment>
                            {item.links.map((item, index) => {
                              const hrefgood = item.url.replace(
                                /(^\w+:|^)\/\//,
                                ""
                              );

                              return (
                                <ul
                                  key={index}
                                  className="fa-ul text-center lessonFontSize2 quickSand"
                                >
                                  <li className="list-inline-item">
                                    <i className="fa-li fa fa-link" />

                                    <a
                                      href={`http://${hrefgood}`}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      <span className="lessonFontSize3">
                                        {item.title}
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              );
                            })}
                          </MDBCol>
                          <MDBCol
                            md="6"
                            className={
                              item.documents.length !== 0
                                ? "mb-4 text-center"
                                : "d-none"
                            }
                          >
                            <Fragment>
                              <p className="montserratFont lessonFontSize text-center pb-1">
                                Topic documents
                              </p>
                            </Fragment>

                            {item.documents.map((item, index) => {
                              if (item.document_type === "video") {
                                return (
                                  <MDBRow center>
                                    <MDBCol>
                                      <a
                                        key={index}
                                        href={`https://res.cloudinary.com/unnamed/${
                                          item.document_type
                                        }/upload/${
                                          item.document_id
                                        }${item.document_id.slice(-4)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {" "}
                                        <span className="lessonFontSize3">
                                          {item.title
                                            ? item.title
                                            : " Link to your saved video"}
                                        </span>
                                      </a>
                                    </MDBCol>
                                  </MDBRow>
                                );
                              } else if (item.document_type === "raw") {
                                return (
                                  <MDBRow center>
                                    <MDBCol>
                                      <a
                                        key={index}
                                        href={`https://res.cloudinary.com/unnamed/${item.document_type}/upload/${item.document_id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="lessonFontSize2"
                                      >
                                        {" "}
                                        <span className="lessonFontSize3">
                                          {item.title
                                            ? item.title
                                            : "Download your saved file"}
                                        </span>
                                      </a>
                                    </MDBCol>
                                  </MDBRow>
                                );
                              } else if (item.document_id.slice(-3) === "pdf") {
                                return (
                                  <MDBRow center>
                                    <MDBCol>
                                      <a
                                        key={index}
                                        href={`https://res.cloudinary.com/unnamed/${item.document_type}/upload/${item.document_id}.pdf`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {" "}
                                        <span className="lessonFontSize3">
                                          {item.title
                                            ? item.title
                                            : "Download your saved pdf"}
                                        </span>
                                      </a>
                                    </MDBCol>
                                  </MDBRow>
                                );
                              } else {
                                return (
                                  <MDBRow center>
                                    <MDBCol>
                                      <a
                                        key={index}
                                        href={cl.url(item.document_id)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {" "}
                                        <span className="lessonFontSize3">
                                          {item.title
                                            ? item.title
                                            : "Link to your saved file"}
                                        </span>
                                      </a>
                                    </MDBCol>
                                  </MDBRow>
                                );
                              }
                            })}
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </Fragment>
                  );
                })}
              </div>

              <MDBModalFooter className="backgroundLessons footerMobile">
                <MDBCol size="12">
                  <MDBRow>
                    <Timer
                      initialTime={timerConversion()}
                      checkpoints={checkpointFunction()}
                      startImmediately={false}
                      direction="backward"
                      onStart={() =>
                        setFormData({
                          ...formData,
                          timerState: "start"
                        })
                      }
                      onResume={() =>
                        setFormData({
                          ...formData,
                          timerState: "start"
                        })
                      }
                      onPause={() =>
                        setFormData({
                          ...formData,
                          timerState: "pause"
                        })
                      }
                      onStop={() =>
                        setFormData({
                          ...formData,
                          timerState: "notStarted"
                        })
                      }
                      onReset={() => console.log("onReset hook")}
                    >
                      {({ start, resume, pause, stop, reset, timerState }) => (
                        <React.Fragment>
                          <div
                            className={
                              formData.individualLesson.switch === true
                                ? "success mr-auto text-white quickSand"
                                : "d-none"
                            }
                          >
                            {timerFunction(start, stop, resume, pause)}
                            <Timer.Minutes /> minutes and <Timer.Seconds />{" "}
                            seconds
                            <MDBIcon
                              className="ml-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => resetTimer(stop, reset)}
                              icon="redo"
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </Timer>
                    <MDBRow
                      className={
                        formData.individualLesson.switch === false
                          ? "ml-auto mr-2"
                          : "mr-2"
                      }
                    >
                      <MDBBtn
                        onClick={duplicateLesson}
                        color="primary"
                        size="sm"
                        className="quickSand"
                      >
                        Duplicate Lesson
                      </MDBBtn>
                      <MDBBtn
                        onClick={createLessonModal}
                        color="primary"
                        size="sm"
                        className="quickSand"
                      >
                        Edit Lesson
                      </MDBBtn>
                      <MDBDropdown>
                        <MDBDropdownToggle
                          caret
                          color="primary"
                          size="sm"
                          className="quickSand"
                        >
                          Printer Friendly
                        </MDBDropdownToggle>
                        <MDBDropdownMenu basic>
                          <MDBDropdownItem
                            className="quickSand"
                            onClick={getpdf}
                          >
                            View PDF
                          </MDBDropdownItem>
                          <MDBDropdownItem
                            className="quickSand"
                            onClick={downloadpdf}
                          >
                            Download PDF
                          </MDBDropdownItem>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                      <MDBBtn
                        size="sm"
                        onClick={
                          formData.lessonTopic === true
                            ? lessonClickClose
                            : topicClickClose
                        }
                        color="secondary"
                        className="quickSand"
                      >
                        Close
                      </MDBBtn>
                    </MDBRow>
                  </MDBRow>
                </MDBCol>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </div>

        {/* Topics View  */}

        <div className="lessonsModal">
          <MDBModal isOpen={formData.modalSeeTopic} className="modal-full">
            <div className="modal-full modal-content ">
              <MDBRow center className="middleVertical py-3">
                <p className="quickSand text-white h2 text-center">
                  {formData.individualTopic.title.charAt(0).toUpperCase() +
                    formData.individualTopic.title.slice(1)}
                </p>
              </MDBRow>

              <Fragment>
                <div className=" modalBottomMargin rounded backgroundLesson2 mx-3">
                  <MDBCol size="12"></MDBCol>
                  <MDBRow className="pt-2">
                    <MDBCol
                      md="6"
                      className={
                        formData.individualTopic.text !== ""
                          ? "mb-1 text-center"
                          : "d-none"
                      }
                    >
                      <Fragment>
                        <p className="pb-1 montserratFont lessonFontSize">
                          Notes / Explanation
                        </p>
                        <div className="ql-editor" id="fixMargin">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: formData.individualTopic.text
                            }}
                            className="ml-2 text-left lessonFontSize2"
                          ></p>
                        </div>
                      </Fragment>
                    </MDBCol>
                    <MDBCol md="6" className="mb-1 text-center">
                      <Fragment>
                        <p className="pb-1 montserratFont lessonFontSize">
                          Topic level
                        </p>
                        <p className="quickSand lessonFontSize2">
                          {formData.individualTopic.level}
                        </p>
                      </Fragment>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow
                    className={
                      (formData.individualTopic.vocabulary.length !== 0 &&
                        formData.individualTopic.grammar.length === 0) ||
                      (formData.individualTopic.vocabulary.length === 0 &&
                        formData.individualTopic.grammar.length !== 0)
                        ? "align-self-start"
                        : formData.individualTopic.vocabulary.length === 0 &&
                          formData.individualTopic.grammar.length === 0
                        ? "d-none"
                        : formData.individualTopic.vocabulary.length !== 0 &&
                          formData.individualTopic.grammar.length !== 0
                        ? "middleVertical"
                        : "middleVertical"
                    }
                  >
                    <MDBCol
                      md="6"
                      className={
                        formData.individualTopic.vocabulary.length !== 0 &&
                        formData.individualTopic.grammar.length === 0
                          ? "mb-1 text-center"
                          : formData.individualTopic.vocabulary.length !== 0 &&
                            formData.individualTopic.grammar.length !== 0
                          ? "mb-1 text-center"
                          : formData.individualTopic.vocabulary.length === 0 &&
                            formData.individualTopic.grammar.length === 0
                          ? "d-none"
                          : "d-none"
                      }
                    >
                      <p className="montserratFont lessonFontSize pb-1">
                        Vocabulary Words
                      </p>

                      <ul className="list-inline lessonFontSize2 quickSand vocabularyCommas">
                        {formData.individualTopic.vocabulary.map(
                          (item, index) => {
                            return (
                              <li key={index} className="list-inline-item">
                                {item}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </MDBCol>
                    <MDBCol
                      md="6"
                      className={
                        formData.individualTopic.vocabulary.length === 0 &&
                        formData.individualTopic.grammar.length !== 0
                          ? "text-center"
                          : formData.individualTopic.vocabulary.length !== 0 &&
                            formData.individualTopic.grammar.length !== 0
                          ? "text-center"
                          : formData.individualTopic.vocabulary.length === 0 &&
                            formData.individualTopic.grammar.length === 0
                          ? "d-none"
                          : "d-none"
                      }
                    >
                      <p className="montserratFont lessonFontSize pb-1">
                        Grammar Topics
                      </p>
                      <ul className="list-inline lessonFontSize2 quickSand vocabularyCommas">
                        {formData.individualTopic.grammar.map((item, index) => {
                          return (
                            <li key={index} className="list-inline-item">
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mx-2">
                    <MDBCol md="12" className="mb-4">
                      {formData.individualTopic.youtube.length !== 0 ? (
                        <Fragment>
                          <p className="montserratFont lessonFontSize pb-1 text-center">
                            Youtube Videos
                          </p>
                        </Fragment>
                      ) : null}
                    </MDBCol>
                    {formData.individualTopic.youtube.map((item, index) => {
                      const emb = urlParser.parse(item.url);
                      if (typeof emb === "undefined") {
                        return (
                          <MDBCol key={index} md="6" className="mb-4">
                            <p className="quickSand lessonFontSize2">
                              A Youtube is not showing since it is an invalid
                              link. Please remove it in its topics component.
                            </p>
                          </MDBCol>
                        );
                      } else {
                        const embUrl = urlParser.create({
                          videoInfo: emb,
                          format: "embed"
                        });

                        return (
                          <MDBCol key={index} md="6" className="mb-4">
                            <div
                              className="embed-responsive embed-responsive-16by9 mb-1"
                              style={{ height: "90%", width: "90%" }}
                            >
                              <iframe
                                title="Embeds Page"
                                className="embed-responsive-item"
                                src={embUrl}
                                allowFullScreen
                              />
                            </div>
                          </MDBCol>
                        );
                      }
                    })}
                  </MDBRow>
                  <MDBRow
                    className={
                      (formData.individualTopic.links.length === 0 &&
                        formData.individualTopic.documents.length !== 0) ||
                      (formData.individualTopic.links.length !== 0 &&
                        formData.individualTopic.documents.length === 0)
                        ? "mb-4 text-center"
                        : formData.individualTopic.links.length !== 0 &&
                          formData.individualTopic.documents.length !== 0
                        ? "mb-4 text-center"
                        : formData.individualTopic.links.length === 0 &&
                          formData.individualTopic.documents.length === 0
                        ? "d-none"
                        : "d-none"
                    }
                  >
                    <MDBCol
                      md="6"
                      className={
                        formData.individualTopic.links.length !== 0 &&
                        formData.individualTopic.documents.length === 0
                          ? "mb-4 text-center"
                          : formData.individualTopic.links.length !== 0 &&
                            formData.individualTopic.documents.length !== 0
                          ? "mb-4 text-center"
                          : formData.individualTopic.links.length === 0 &&
                            formData.individualTopic.documents.length === 0
                          ? "d-none"
                          : "d-none"
                      }
                    >
                      <Fragment>
                        <p className="montserratFont lessonFontSize text-center pb-1">
                          Website Links
                        </p>
                      </Fragment>
                      {formData.individualTopic.links.map((item, index) => {
                        const hrefgood = item.url.replace(/(^\w+:|^)\/\//, "");

                        return (
                          <ul key={index} className="fa-ul text-center">
                            <li className="list-inline-item">
                              <i className="fa-li fa fa-link" />

                              <a
                                href={`http://${hrefgood}`}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <span className="lessonFontSize3">
                                  {item.title}
                                </span>
                              </a>
                            </li>
                          </ul>
                        );
                      })}
                    </MDBCol>
                    <MDBCol
                      md="6"
                      className={
                        formData.individualTopic.links.length === 0 &&
                        formData.individualTopic.documents.length !== 0
                          ? "mb-4 text-center"
                          : formData.individualTopic.links.length !== 0 &&
                            formData.individualTopic.documents.length !== 0
                          ? "mb-4 text-center"
                          : formData.individualTopic.links.length === 0 &&
                            formData.individualTopic.documents.length === 0
                          ? "d-none"
                          : "d-none"
                      }
                    >
                      <Fragment>
                        <p className="text-center pb-1 montserratFont lessonFontSize">
                          Saved files
                        </p>
                      </Fragment>

                      {formData.individualTopic.documents.map((item, index) => {
                        if (item.document_type === "video") {
                          return (
                            <MDBRow center>
                              <MDBCol>
                                <a
                                  key={index}
                                  href={`https://res.cloudinary.com/unnamed/${
                                    item.document_type
                                  }/upload/${
                                    item.document_id
                                  }${item.document_id.slice(-4)}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  <span className="lessonFontSize3">
                                    {item.title
                                      ? item.title
                                      : " Link to your saved video"}
                                  </span>
                                </a>
                              </MDBCol>
                            </MDBRow>
                          );
                        } else if (item.document_type === "raw") {
                          return (
                            <MDBRow center>
                              <MDBCol>
                                <a
                                  key={index}
                                  href={`https://res.cloudinary.com/unnamed/${item.document_type}/upload/${item.document_id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  <span className="lessonFontSize3">
                                    {item.title
                                      ? item.title
                                      : "Download your saved file"}
                                  </span>
                                </a>
                              </MDBCol>
                            </MDBRow>
                          );
                        } else if (item.document_id.slice(-3) === "pdf") {
                          return (
                            <MDBRow center>
                              <MDBCol>
                                <a
                                  key={index}
                                  href={`https://res.cloudinary.com/unnamed/${item.document_type}/upload/${item.document_id}.pdf`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  <span className="lessonFontSize3">
                                    {item.title
                                      ? item.title
                                      : "Download your saved pdf"}
                                  </span>
                                </a>
                              </MDBCol>
                            </MDBRow>
                          );
                        } else {
                          return (
                            <MDBRow center>
                              <MDBCol>
                                <a
                                  key={index}
                                  href={cl.url(item.document_id)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  <span className="lessonFontSize3">
                                    {item.title
                                      ? item.title
                                      : "Link to your saved file"}
                                  </span>
                                </a>
                              </MDBCol>
                            </MDBRow>
                          );
                        }
                      })}
                    </MDBCol>
                  </MDBRow>
                </div>
              </Fragment>
              <MDBModalFooter className="backgroundLessons">
                <MDBRow className="mr-2 topicFooterMobile">
                  <MDBBtn
                    onClick={createTopicModal}
                    color="primary"
                    size="sm"
                    className="quickSand"
                  >
                    Edit Topic
                  </MDBBtn>
                  <MDBBtn
                    onClick={topicClickClose}
                    color="secondary"
                    size="sm"
                    className="quickSand"
                  >
                    Close
                  </MDBBtn>
                </MDBRow>
              </MDBModalFooter>
            </div>
          </MDBModal>
        </div>
      </MDBContainer>

      <MDBContainer>
        <MDBModal
          isOpen={formData.modalDeleteLesson}
          toggle={deleteLessonModalOpen}
        >
          <MDBModalHeader>Confirm Delete</MDBModalHeader>
          <MDBModalBody>
            If you would like to delete this lesson please click on the
            'confirm' button below.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={deleteLessonModalOpen} color="dark">
              Close
            </MDBBtn>
            <MDBBtn
              disabled={loadingLessonDelete}
              onClick={lessonOnDelete}
              color="danger"
            >
              {loadingLessonDelete === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Confirm
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          isOpen={formData.modalDeleteTopic}
          toggle={deleteTopicModalOpen}
        >
          <MDBModalHeader>Confirm Delete</MDBModalHeader>
          <MDBModalBody>
            If you would like to delete this topic please click on the 'confirm'
            button below.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={deleteTopicModalOpen} color="dark">
              Close
            </MDBBtn>
            <MDBBtn
              disabled={loadingTopicDelete}
              onClick={topicOnDelete}
              color="danger"
            >
              {loadingTopicDelete === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Confirm
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <div className="tableLesson">
        <MDBContainer>
          <MDBModal
            isOpen={formData.modalChooseLesson}
            toggle={chooseLessonModalOpen}
          >
            <MDBModalBody>
              <div className="dropDownStudents">
                <MDBSelect
                  search
                  options={formData.lessonOptions}
                  label={"Choose Lesson"}
                  getValue={lessonValue}
                  className="quickSand"
                />
              </div>
              <p
                className="green-text text-center quickSand"
                style={{ cursor: "pointer" }}
                onClick={clearPickLesson}
              >
                Clear Lesson
              </p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={chooseLessonModalOpen} size="sm" color="dark">
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
      <MDBContainer>
        <MDBModal isOpen={formData.modalPushTopic} toggle={() => null}>
          <MDBModalHeader>Push Topic</MDBModalHeader>
          <MDBModalBody>
            <MDBCol size="12">
              <p className="text-center quickSand pt-3">
                Make sure you update/save your lesson before pushing. You can
                push this topic to one of the following lessons.
              </p>
              <p className="quickSand h5 text-center mx-1">
                {formData.individualLesson.assignedType === "none"
                  ? "No Student/Class chosen. Make sure you choose one and update the lesson before pushing."
                  : formData.individualLesson.assignedType === "class"
                  ? `Lessons assigned to ${formData.individualLesson.groupIds.name}`
                  : `Lessons assigned to ${formData.individualLesson.student.name}`}
              </p>
              {formData.pushList.map((item, index) => {
                return (
                  <MDBRow
                    key={item._id}
                    style={{
                      cursor: "pointer"
                    }}
                    center
                    onClick={() => selectPush(item)}
                    className={
                      item.selected === true
                        ? "info-color rounded z-depth-1"
                        : "rounded"
                    }
                  >
                    <p className={item.selected === true ? "white-text" : ""}>
                      {item.title}
                    </p>
                  </MDBRow>
                );
              })}
            </MDBCol>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={pushTopicModalClose} size="sm" color="dark">
              Close
            </MDBBtn>
            <MDBBtn onClick={pushTopicFunc} size="sm" color="info">
              Push
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  lessons: state.lessons,
  auth: state.auth,
  students: state.students,
  groups: state.groups
});

export default withRouter(
  connect(mapStateToProps, {
    getLessons,
    getLessonsAll,
    addLessons,
    getTopics,
    deleteLesson,
    deleteTopic,
    pushTopic,
    clearLessonReducer,
    getLesson,
    searchLessonsFunction,
    setPaginationModeLessons,
    clearLessonsSearchReducer,
    searchTopicsFunction,
    setPaginationModeTopics,
    clearTopicsSearchReducer,
    getGroupsSelect,
    getStudentsSelect,
    getLessonsPush,
    setLoadingDeleteLesson,
    setLoadingDeleteTopic,
    setLoadingButtonLessons,
    updateLesson,
    clearDocumentDelete
  })(LessonData)
);
