import axios from "axios";
import { setAlert } from "./alert";

import {
  CURRICULUM_ERROR,
  MAKE_CURRICULUM,
  GET_CURRICULUMLIST,
  UPDATE_CURRICULUM,
  DELETE_CURRICULUM,
  SEARCH_CURRICULUMS,
  CLEAR_CURRICULUMS_SEARCH,
  SET_PAGINATION_MODE_CURRICULUMS,
  SET_LOADING_BUTTON_CURRICULUMS,
  SET_LOADING_DELETE_CURRICULUM
} from "./types";

// make curriculum

export const makeCurriculum = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/curriculum/", formData, config);

    dispatch({
      type: MAKE_CURRICULUM,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CURRICULUM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all curriculums
export const getCurriculumList = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/curriculum/get`, formData, config);

    dispatch({
      type: GET_CURRICULUMLIST,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CURRICULUM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update curriculum
export const updateCurriculum = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/curriculum/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_CURRICULUM,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CURRICULUM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// delete curriculum

export const deleteCurriculum = id => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.delete(`/api/curriculum/${id}`, config);

    dispatch({
      type: DELETE_CURRICULUM,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CURRICULUM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Curriculums

export const searchCurriculumsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/curriculum/search", formData, config);

    dispatch({
      type: SEARCH_CURRICULUMS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CURRICULUM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Curriculums

export const setPaginationModeCurriculums = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_CURRICULUMS,
    payload: formData
  });
};

// Clear Curriculums Search Results

export const clearCurriculumsSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_CURRICULUMS_SEARCH
  });
};

// Set Loading Button Curriculums

export const setLoadingButtonCurriculums = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_CURRICULUMS,
    payload: formData
  });
};

export const setLoadingDeleteCurriculum = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_DELETE_CURRICULUM,
    payload: formData
  });
};
