import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import "./documentation.css";
import BulletPoints from "./BulletPoints.json";
import urlParser from "js-video-url-parser";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBCard,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCardBody,
  MDBModalHeader
} from "mdbreact";

const Documentation = () => {
  const [formData, setFormData] = useState({
    bulletPoints: BulletPoints,
    modalOpen: false,
    videoTitle: "",
    videoLink: "",
    bulletPoint1: "",
    bulletPoint2: "",
    bulletPoint3: "",
    bulletPoint4: "",
    bulletPoint5: ""
  });

  const embedVideo = () => {
    const emb = urlParser.parse(formData.videoLink);
    if (typeof emb === "undefined") {
      return null;
    } else {
      const embUrl = urlParser.create({
        videoInfo: emb,
        format: "embed"
      });
      return (
        <div className="embed-responsive embed-responsive-16by9 mb-1">
          <iframe
            title="Embeds Page"
            className="embed-responsive-item"
            src={embUrl}
            allowFullScreen
          />
        </div>
      );
    }
  };

  // Remove double scroll bar for modal
  useEffect(() => {
    if (formData.modalOpen === true) {
      document.body.style.cssText = "overflow-y: hidden !important;";
    } else {
      document.body.style.overflowY = "";
    }
  }, [formData.modalOpen]);

  // Before Return

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Documentation</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow className="mt-3 " center>
          <MDBCol md="12">
            <MDBCard
              className="mt-5 mb-1 documentationBackground documentationHover"
              style={{ minHeight: "30rem" }}
            >
              <MDBCardBody>
                <section>
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        Getting Started
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.gettingStarted.purpose.one,
                            videoLink:
                              formData.bulletPoints.gettingStarted.purpose.two,
                            bulletPoint1:
                              formData.bulletPoints.gettingStarted.purpose
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.gettingStarted.purpose.four,
                            bulletPoint3:
                              formData.bulletPoints.gettingStarted.purpose.five,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Introduction to Topscholar
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579215229/Documentation/capture_36_r9obzr.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>

                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.gettingStarted.navigation
                                .one,
                            videoLink:
                              formData.bulletPoints.gettingStarted.navigation
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.gettingStarted.navigation
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.gettingStarted.navigation
                                .four,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Navigation overview
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:0f0e0e,e_sharpen:100/v1577228113/Documentation/nav_mkk3sk.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.gettingStarted.dashboard
                                .one,
                            videoLink:
                              formData.bulletPoints.gettingStarted.dashboard
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.gettingStarted.dashboard
                                .three,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Dashboard overview
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:0d0d0d,e_sharpen:100/v1577228751/Documentation/dash_kwxpsv.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.gettingStarted.calendar.one,
                            videoLink:
                              formData.bulletPoints.gettingStarted.calendar.two,
                            bulletPoint1:
                              formData.bulletPoints.gettingStarted.calendar
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.gettingStarted.calendar
                                .four,
                            bulletPoint3:
                              formData.bulletPoints.gettingStarted.calendar
                                .five,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Using your calendar
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:090909,e_sharpen:100/v1577229398/Documentation/calendar_t22zzl.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>

                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Students & Classes`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="col-12 mt-3">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.studentsClasses.creating
                                .one,
                            videoLink:
                              formData.bulletPoints.studentsClasses.creating
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.studentsClasses.creating
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.studentsClasses.creating
                                .four,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold documentationFontMobile">
                          Creating classes and students
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:040303,e_sharpen:100/v1577231447/Documentation/classes_z0xmyk.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="col-12 mt-3">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.studentsClasses.profile.one,
                            videoLink:
                              formData.bulletPoints.studentsClasses.profile.two,
                            bulletPoint1:
                              formData.bulletPoints.studentsClasses.profile
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.studentsClasses.profile
                                .four,
                            bulletPoint3:
                              formData.bulletPoints.studentsClasses.profile
                                .five,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Student profile
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:110f0f/v1577232690/Documentation/studentProfile2_dpnrwp.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="col-12 mt-3">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.studentsClasses.evaluation
                                .one,
                            videoLink:
                              formData.bulletPoints.studentsClasses.evaluation
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.studentsClasses.evaluation
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.studentsClasses.evaluation
                                .four,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Student evaluations
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:0b0b0b/v1577232262/Documentation/evaluation_th43ew.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        Lesson Planning
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.lessons.createlesson.one,
                            videoLink:
                              formData.bulletPoints.lessons.createlesson.two,
                            bulletPoint1:
                              formData.bulletPoints.lessons.createlesson.three,
                            bulletPoint2:
                              formData.bulletPoints.lessons.createlesson.four,
                            bulletPoint3:
                              formData.bulletPoints.lessons.createlesson.five,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Creating lessons
                        </p>

                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:070707/v1577233342/Documentation/createLessons_ztjncq.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.lessons.createtopic.one,
                            videoLink:
                              formData.bulletPoints.lessons.createtopic.two,
                            bulletPoint1:
                              formData.bulletPoints.lessons.createtopic.three,
                            bulletPoint2:
                              formData.bulletPoints.lessons.createtopic.four,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Creating topics
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:0b0b0b/v1577234078/Documentation/topics_sl0ock.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.lessons.usinglessons.one,
                            videoLink:
                              formData.bulletPoints.lessons.usinglessons.two,
                            bulletPoint1:
                              formData.bulletPoints.lessons.usinglessons.three,
                            bulletPoint2:
                              formData.bulletPoints.lessons.usinglessons.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Using lessons
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:090909/v1577234933/Documentation/usingLessons_mriyed.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                {/* <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Your Profile`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.profile.profile.one,
                            videoLink:
                              formData.bulletPoints.profile.profile.two,
                            bulletPoint1:
                              formData.bulletPoints.profile.profile.three,
                            bulletPoint2:
                              formData.bulletPoints.profile.profile.four,
                            bulletPoint3:
                              formData.bulletPoints.profile.profile.five,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Profile overview
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:060505/v1577246443/Documentation/profileoverview_mszji9.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section> */}
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Chat`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle: formData.bulletPoints.chat.chat.one,
                            videoLink: formData.bulletPoints.chat.chat.two,
                            bulletPoint1: formData.bulletPoints.chat.chat.three,
                            bulletPoint2: formData.bulletPoints.chat.chat.four,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Chat overview
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:090808/v1577246799/Documentation/chat_olrqbd.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Flashcards`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.decks.creatingDecks.one,
                            videoLink:
                              formData.bulletPoints.decks.creatingDecks.two,
                            bulletPoint1:
                              formData.bulletPoints.decks.creatingDecks.three,
                            bulletPoint2:
                              formData.bulletPoints.decks.creatingDecks.four,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Creating cards and decks
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:070707/v1577248021/Documentation/createdeck_hwa6aj.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.decks.studyDecks.one,
                            videoLink:
                              formData.bulletPoints.decks.studyDecks.two,
                            bulletPoint1:
                              formData.bulletPoints.decks.studyDecks.three,
                            bulletPoint2:
                              formData.bulletPoints.decks.studyDecks.four,
                            bulletPoint3:
                              formData.bulletPoints.decks.studyDecks.five,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Study decks
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:060505/v1577248202/Documentation/playdeck_rmiqde.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.decks.shareDecks.one,
                            videoLink:
                              formData.bulletPoints.decks.shareDecks.two,
                            bulletPoint1:
                              formData.bulletPoints.decks.shareDecks.three,
                            bulletPoint2:
                              formData.bulletPoints.decks.shareDecks.four,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Share decks
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:090909/v1577248444/Documentation/sharedeck_rmn5dy.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Whiteboard`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.whiteboard.whiteboard.one,
                            videoLink:
                              formData.bulletPoints.whiteboard.whiteboard.two,
                            bulletPoint1:
                              formData.bulletPoints.whiteboard.whiteboard.three,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Whiteboard overview
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:0d0d0d/v1577248735/Documentation/canvasoverview_ppkrdh.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.whiteboard.whiteboardShare
                                .one,
                            videoLink:
                              formData.bulletPoints.whiteboard.whiteboardShare
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.whiteboard.whiteboardShare
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.whiteboard.whiteboardShare
                                .four,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          Share whiteboard
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:070707/v1577248955/Documentation/canvasshare_vinyla.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Payments`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    {/* <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.payments.endTrial.one,
                            videoLink:
                              formData.bulletPoints.payments.endTrial.two,
                            bulletPoint1:
                              formData.bulletPoints.payments.endTrial.three,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.endTrial.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579127665/Capture_24_dbxwqx.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol> */}
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.payments.createAccount.one,
                            videoLink:
                              formData.bulletPoints.payments.createAccount.two,
                            bulletPoint1:
                              formData.bulletPoints.payments.createAccount
                                .three,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.createAccount.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_improve/v1579129011/Documentation/Capture_25_zayfyp.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.payments.verifyAccount.one,
                            videoLink:
                              formData.bulletPoints.payments.verifyAccount.two,
                            bulletPoint1:
                              formData.bulletPoints.payments.verifyAccount
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.payments.verifyAccount.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.verifyAccount.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579130383/Documentation/Capture_26_usrrxn.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.payments.disconnectStripe
                                .one,
                            videoLink:
                              formData.bulletPoints.payments.disconnectStripe
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.payments.disconnectStripe
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.payments.disconnectStripe
                                .four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.disconnectStripe.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579131044/Documentation/Capture_27_nq0frb.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.payments.viewBalances.one,
                            videoLink:
                              formData.bulletPoints.payments.viewBalances.two,
                            bulletPoint1:
                              formData.bulletPoints.payments.viewBalances.three,
                            bulletPoint2:
                              formData.bulletPoints.payments.viewBalances.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.viewBalances.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579131752/Documentation/Capture_28_tvju69.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.payments.createPayment.one,
                            videoLink:
                              formData.bulletPoints.payments.createPayment.two,
                            bulletPoint1:
                              formData.bulletPoints.payments.createPayment
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.payments.createPayment.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.createPayment.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579133562/Documentation/capture_29_wjol19.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.payments.viewPayment.one,
                            videoLink:
                              formData.bulletPoints.payments.viewPayment.two,
                            bulletPoint1:
                              formData.bulletPoints.payments.viewPayment.three,
                            bulletPoint2:
                              formData.bulletPoints.payments.viewPayment.four,
                            bulletPoint3:
                              formData.bulletPoints.payments.viewPayment.five,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.viewPayment.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579173321/Documentation/Capture_30_umkgb6.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.payments.draft.one,
                            videoLink: formData.bulletPoints.payments.draft.two,
                            bulletPoint1:
                              formData.bulletPoints.payments.draft.three,
                            bulletPoint2:
                              formData.bulletPoints.payments.draft.four,
                            bulletPoint3:
                              formData.bulletPoints.payments.draft.five,
                            bulletPoint4:
                              formData.bulletPoints.payments.draft.six,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.draft.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579182189/Documentation/capture_34_qnm6yt.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.payments.refund.one,
                            videoLink:
                              formData.bulletPoints.payments.refund.two,
                            bulletPoint1:
                              formData.bulletPoints.payments.refund.three,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.refund.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579174635/Documentation/Capture_31_max3iv.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle: formData.bulletPoints.payments.tax.one,
                            videoLink: formData.bulletPoints.payments.tax.two,
                            bulletPoint1:
                              formData.bulletPoints.payments.tax.three,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.tax.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579176230/Documentation/Capture_32_b3koyz.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.payments.currency.one,
                            videoLink:
                              formData.bulletPoints.payments.currency.two,
                            bulletPoint1:
                              formData.bulletPoints.payments.currency.three,
                            bulletPoint2:
                              formData.bulletPoints.payments.currency.four,
                            bulletPoint3:
                              formData.bulletPoints.payments.currency.five,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold ">
                          {formData.bulletPoints.payments.currency.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579178588/Documentation/Capture_33_onjw57.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Online Exams`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.onlineExams.createExam.one,
                            videoLink:
                              formData.bulletPoints.onlineExams.createExam.two,
                            bulletPoint1:
                              formData.bulletPoints.onlineExams.createExam
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.onlineExams.createExam.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.onlineExams.createExam.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:010005,e_sharpen:100,q_100,r_0/v1577224970/Documentation/Capture_1_ibaksd.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.onlineExams.modifyingExam
                                .one,
                            videoLink:
                              formData.bulletPoints.onlineExams.modifyingExam
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.onlineExams.modifyingExam
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.onlineExams.modifyingExam
                                .four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.onlineExams.modifyingExam.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_improve/v1577228912/Documentation/capture_2_mtenye.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.onlineExams.playingExam.one,
                            videoLink:
                              formData.bulletPoints.onlineExams.playingExam.two,
                            bulletPoint1:
                              formData.bulletPoints.onlineExams.playingExam
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.onlineExams.playingExam
                                .four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.onlineExams.playingExam.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577229349/Documentation/capture_3_nruwcr.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.onlineExams.assigningExam
                                .one,
                            videoLink:
                              formData.bulletPoints.onlineExams.assigningExam
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.onlineExams.assigningExam
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.onlineExams.assigningExam
                                .four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.onlineExams.assigningExam.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577229886/Documentation/capture_4_tahig6.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Games`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.games.creatingGame.one,
                            videoLink:
                              formData.bulletPoints.games.creatingGame.two,
                            bulletPoint1:
                              formData.bulletPoints.games.creatingGame.three,
                            bulletPoint2:
                              formData.bulletPoints.games.creatingGame.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.games.creatingGame.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577230470/Documentation/capture_5_k0qpmn.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.games.modifyingGame.one,
                            videoLink:
                              formData.bulletPoints.games.modifyingGame.two,
                            bulletPoint1:
                              formData.bulletPoints.games.modifyingGame.three,
                            bulletPoint2:
                              formData.bulletPoints.games.modifyingGame.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.games.modifyingGame.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_improve/v1577231593/Documentation/capture_6_ioaxxv.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Trading`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.trading.publishingModule
                                .one,
                            videoLink:
                              formData.bulletPoints.trading.publishingModule
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.trading.publishingModule
                                .three,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.trading.publishingModule.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577232372/Documentation/capture_7_d2ysys.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.trading.searchingModule.one,
                            videoLink:
                              formData.bulletPoints.trading.searchingModule.two,
                            bulletPoint1:
                              formData.bulletPoints.trading.searchingModule
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.trading.searchingModule
                                .four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.trading.searchingModule.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577276912/Documentation/capture_8_d3f6dn.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.trading.navigating.one,
                            videoLink:
                              formData.bulletPoints.trading.navigating.two,
                            bulletPoint1:
                              formData.bulletPoints.trading.navigating.three,
                            bulletPoint2:
                              formData.bulletPoints.trading.navigating.four,
                            bulletPoint3:
                              formData.bulletPoints.trading.navigating.five,
                            bulletPoint4:
                              formData.bulletPoints.trading.navigating.six,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold documentationFontMobile">
                          {formData.bulletPoints.trading.navigating.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_improve/v1577277096/Documentation/capture_9_xyqutz.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.trading.uploading.one,
                            videoLink:
                              formData.bulletPoints.trading.uploading.two,
                            bulletPoint1:
                              formData.bulletPoints.trading.uploading.three,
                            bulletPoint2:
                              formData.bulletPoints.trading.uploading.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.trading.uploading.one}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_improve/v1577277669/Documentation/capture_10_wxjxlh.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Curriculum`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.curriculum
                                .creatingCurriculum.one,
                            videoLink:
                              formData.bulletPoints.curriculum
                                .creatingCurriculum.two,
                            bulletPoint1:
                              formData.bulletPoints.curriculum
                                .creatingCurriculum.three,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {
                            formData.bulletPoints.curriculum.creatingCurriculum
                              .one
                          }
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577277988/Documentation/capture_11_idn0ep.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Public Page`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.publicPage
                                .creatingPublicPage.one,
                            videoLink:
                              formData.bulletPoints.publicPage
                                .creatingPublicPage.two,
                            bulletPoint1:
                              formData.bulletPoints.publicPage
                                .creatingPublicPage.three,
                            bulletPoint2:
                              formData.bulletPoints.publicPage
                                .creatingPublicPage.four,
                            bulletPoint3:
                              formData.bulletPoints.publicPage
                                .creatingPublicPage.five,
                            bulletPoint4:
                              formData.bulletPoints.publicPage
                                .creatingPublicPage.six,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {
                            formData.bulletPoints.publicPage.creatingPublicPage
                              .one
                          }
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_improve/v1577281166/Documentation/capture_12_llv0un.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.publicPage.availabilities
                                .one,
                            videoLink:
                              formData.bulletPoints.publicPage.availabilities
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.publicPage.availabilities
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.publicPage.availabilities
                                .four,
                            bulletPoint3:
                              formData.bulletPoints.publicPage.availabilities
                                .five,
                            bulletPoint4:
                              formData.bulletPoints.publicPage.availabilities
                                .six,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.publicPage.availabilities.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_improve/v1577281428/Documentation/capture_13_mh3gtj.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.publicPage.studentRequests
                                .one,
                            videoLink:
                              formData.bulletPoints.publicPage.studentRequests
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.publicPage.studentRequests
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.publicPage.studentRequests
                                .four,
                            bulletPoint3:
                              formData.bulletPoints.publicPage.studentRequests
                                .five,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.publicPage.studentRequests.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_improve/v1577281664/Documentation/capture_14_c4rqhi.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.publicPage.publicPageWork
                                .one,
                            videoLink:
                              formData.bulletPoints.publicPage.publicPageWork
                                .two,
                            bulletPoint1:
                              formData.bulletPoints.publicPage.publicPageWork
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.publicPage.publicPageWork
                                .four,
                            bulletPoint3:
                              formData.bulletPoints.publicPage.publicPageWork
                                .five,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold documentationFontMobile">
                          {formData.bulletPoints.publicPage.publicPageWork.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577282532/Documentation/capture_15_dtmajx.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.publicPage.findTutors.one,
                            videoLink:
                              formData.bulletPoints.publicPage.findTutors.two,
                            bulletPoint1:
                              formData.bulletPoints.publicPage.findTutors.three,
                            bulletPoint2:
                              formData.bulletPoints.publicPage.findTutors.four,
                            bulletPoint3:
                              formData.bulletPoints.publicPage.findTutors.five,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.publicPage.findTutors.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577622221/Documentation/capture_23_pnekdf.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.publicPage.reportUser.one,
                            videoLink:
                              formData.bulletPoints.publicPage.reportUser.two,
                            bulletPoint1:
                              formData.bulletPoints.publicPage.reportUser.three,
                            bulletPoint2:
                              formData.bulletPoints.publicPage.reportUser.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.publicPage.reportUser.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1579193433/Documentation/capture_35_rizgzf.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Topscholar-Connect`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle: formData.bulletPoints.connect.about.one,
                            videoLink: formData.bulletPoints.connect.about.two,
                            bulletPoint1:
                              formData.bulletPoints.connect.about.three,
                            bulletPoint2:
                              formData.bulletPoints.connect.about.four,
                            bulletPoint3:
                              formData.bulletPoints.connect.about.five,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.connect.about.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_improve/v1577315970/Documentation/capture_16_vmtjga.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.connect.lessonPlans.one,
                            videoLink:
                              formData.bulletPoints.connect.lessonPlans.two,
                            bulletPoint1:
                              formData.bulletPoints.connect.lessonPlans.three,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.connect.lessonPlans.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1578672990/Documentation/lessons_snip_it_w18jja.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.connect.flashcards.one,
                            videoLink:
                              formData.bulletPoints.connect.flashcards.two,
                            bulletPoint1:
                              formData.bulletPoints.connect.flashcards.three,
                            bulletPoint2:
                              formData.bulletPoints.connect.flashcards.four,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.connect.flashcards.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_improve/v1577354506/Documentation/capture_17_cel31n.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.connect.whiteboard.one,
                            videoLink:
                              formData.bulletPoints.connect.whiteboard.two,
                            bulletPoint1:
                              formData.bulletPoints.connect.whiteboard.three,
                            bulletPoint2:
                              formData.bulletPoints.connect.whiteboard.four,

                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.connect.whiteboard.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577360333/Documentation/capture_18_sozuq5.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.connect.onlineExams.one,
                            videoLink:
                              formData.bulletPoints.connect.onlineExams.two,
                            bulletPoint1:
                              formData.bulletPoints.connect.onlineExams.three,
                            bulletPoint2:
                              formData.bulletPoints.connect.onlineExams.four,
                            bulletPoint3:
                              formData.bulletPoints.connect.onlineExams.five,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold documentationFontMobile">
                          {formData.bulletPoints.connect.onlineExams.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577361196/Documentation/capture_19_jsjgie.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle:
                              formData.bulletPoints.connect.availabilities.one,
                            videoLink:
                              formData.bulletPoints.connect.availabilities.two,
                            bulletPoint1:
                              formData.bulletPoints.connect.availabilities
                                .three,
                            bulletPoint2:
                              formData.bulletPoints.connect.availabilities.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold documentationFontMobile">
                          {formData.bulletPoints.connect.availabilities.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577362150/Documentation/capture_20_hnlzcm.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle: formData.bulletPoints.connect.chat.one,
                            videoLink: formData.bulletPoints.connect.chat.two,
                            bulletPoint1:
                              formData.bulletPoints.connect.chat.three,
                            bulletPoint2:
                              formData.bulletPoints.connect.chat.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold documentationFontMobile">
                          {formData.bulletPoints.connect.chat.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577362415/Documentation/capture_21_mc4rnq.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
                <section className="mt-4">
                  <MDBRow>
                    <MDBCol size="12">
                      <p className="quickSand h4-responsive white-text">
                        {`Miscellaneous`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="3" lg="4" md="6" sm="6" className="mt-3 col-12">
                      <MDBCard
                        className="documentationBackground documentationHover"
                        style={{ height: "10rem", cursor: "pointer" }}
                        onClick={() => {
                          setFormData({
                            ...formData,
                            videoTitle: formData.bulletPoints.misc.delete.one,
                            videoLink: formData.bulletPoints.misc.delete.two,
                            bulletPoint1:
                              formData.bulletPoints.misc.delete.three,
                            bulletPoint2:
                              formData.bulletPoints.misc.delete.four,
                            modalOpen: !formData.modalOpen
                          });
                        }}
                      >
                        <p className="text-center quickSand font-weight-bold">
                          {formData.bulletPoints.misc.delete.one}{" "}
                        </p>
                        <div className="mx-2 text-center mb-2">
                          <img
                            style={{ height: "7rem" }}
                            src={
                              "https://res.cloudinary.com/unnamed/image/upload/bo_10px_solid_rgb:000,e_sharpen:100/v1577362702/Documentation/capture_22_vnzs5k.png"
                            }
                            alt=""
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </section>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          isOpen={formData.modalOpen}
          toggle={() =>
            setFormData({
              ...formData,
              videoTitle: "",
              videoLink: "",
              bulletPoint1: "",
              bulletPoint2: "",
              bulletPoint3: "",
              bulletPoint4: "",
              bulletPoint5: "",
              modalOpen: !formData.modalOpen
            })
          }
          cascading
          size="lg"
        >
          <MDBModalHeader
            toggle={() => {
              setFormData({
                ...formData,
                modalOpen: false,
                videoTitle: "",
                videoLink: "",
                bulletPoint1: "",
                bulletPoint2: "",
                bulletPoint3: "",
                bulletPoint4: "",
                bulletPoint5: ""
              });
            }}
            className="documentationModalHeader white-text"
          >
            {formData.videoTitle}
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <MDBCol size="12" className="mb-1">
                <MDBRow>
                  <p className="montserratFont timeStampFont">Video Summary</p>
                </MDBRow>
                <MDBRow>
                  <p className="quickSand documentationFont">
                    <MDBIcon
                      icon="fas fa-dot-circle"
                      className="mr-2 mt-1 documentationIcon"
                    />
                    {formData.bulletPoint1}
                  </p>
                </MDBRow>
                <MDBRow
                  className={formData.bulletPoint2 === "" ? "d-none" : ""}
                >
                  <p className="quickSand documentationFont">
                    <MDBIcon
                      icon="fas fa-dot-circle"
                      className="mr-2 mt-1 documentationIcon"
                    />
                    {formData.bulletPoint2}
                  </p>
                </MDBRow>
                <MDBRow
                  className={formData.bulletPoint3 === "" ? "d-none" : ""}
                >
                  <p className="quickSand documentationFont">
                    <MDBIcon
                      icon="fas fa-dot-circle"
                      className="mr-2 mt-1 documentationIcon"
                    />
                    {formData.bulletPoint3}
                  </p>
                </MDBRow>
                <MDBRow
                  className={formData.bulletPoint4 === "" ? "d-none" : ""}
                >
                  <p className="quickSand documentationFont">
                    <MDBIcon
                      icon="fas fa-dot-circle"
                      className="mr-2 mt-1 documentationIcon"
                    />
                    {formData.bulletPoint4}
                  </p>
                </MDBRow>
                <MDBRow>{embedVideo()}</MDBRow>
              </MDBCol>
            </div>
          </MDBModalBody>
          <MDBModalFooter center>
            <MDBBtn
              outline
              color="orange"
              onClick={() => {
                setFormData({
                  ...formData,
                  modalOpen: false,
                  videoTitle: "",
                  videoLink: "",
                  bulletPoint1: "",
                  bulletPoint2: "",
                  bulletPoint3: "",
                  bulletPoint4: "",
                  bulletPoint5: ""
                });
              }}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

Documentation.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  trading: state.trading,
  curriculum: state.curriculum
});

export default withRouter(connect(mapStateToProps, {})(Documentation));
