import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "./trading.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { getUserModules } from "../../actions/trading";
import { withRouter } from "react-router-dom";

import {
  MDBContainer,
  MDBAvatar,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBPageItem,
  MDBPageNav,
  MDBPagination
} from "mdbreact";

const PrivateProfileView = ({
  history,
  mode,
  switchMode,
  modalDescription,
  getUserModules,
  trading: { userModules, follow, pageNumber, numberOfPages },
  auth: { user },
  modalFollow,
  modalFollowing
}) => {
  useEffect(() => {
    getUserModules({ pageNumber: "first" });
  }, [getUserModules]);

  const viewLesson = item => {
    history.push(`/trading/lesson/${item._id}`);
  };
  const viewTopic = item => {
    history.push(`/trading/topic/${item._id}`);
  };
  const viewExam = item => {
    history.push(`/trading/exam/${item._id}`);
  };
  const viewGame = item => {
    history.push(`/trading/game/${item._id}`);
  };
  const viewDeck = item => {
    history.push(`/trading/deck/${item._id}`);
  };
  const viewProfile = item => {
    history.push(`/trading/profile/${item._id}`);
  };
  const viewCurriculum = item => {
    history.push(`/trading/curriculum/${item._id}`);
  };

  const isActive1 = page => {
    if (pageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationModules = () => {
    getUserModules({ pageNumber: "first" });
  };

  const previousPaginationModules = () => {
    getUserModules({ pageNumber: pageNumber - 1 });
  };

  const currentPaginationModules = index => {
    getUserModules({ pageNumber: index + 1 });
  };

  const nextPaginationModules = () => {
    getUserModules({ pageNumber: pageNumber + 1 });
  };

  const lastPaginationModules = () => {
    getUserModules({ pageNumber: "last" });
  };

  return (
    // Left Side

    <Fragment>
      <MDBContainer fluid className="mb-5">
        <MDBRow center className="text-center">
          <MDBCol md="8" className="mb-r">
            <MDBCard
              style={{ height: "auto" }}
              className="mx-2 my-2 borderStats border-white bg-transparent"
            >
              <MDBRow className="mx-3 my-2 middleVertical">
                <MDBCol md="12">
                  <p className="livvicFont h5 text-white middleVertical">
                    Recent Modules Published
                  </p>
                </MDBCol>
              </MDBRow>{" "}
            </MDBCard>

            {/* Modules published */}

            <div style={{ minHeight: "35rem" }}>
              {userModules.map((item, index) => {
                if (item.type === "lessons") {
                  return (
                    <MDBCard
                      key={item._id}
                      style={{ height: "auto", cursor: "pointer" }}
                      className="tradingCard mx-2 my-2"
                      onClick={() => {
                        viewLesson({ _id: item._id });
                      }}
                    >
                      <MDBRow className="widthFull2">
                        <MDBCol sm="1">
                          <MDBIcon icon="clone fas" size="sm" />
                        </MDBCol>
                        <MDBCol sm="2" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.title}
                          </p>
                        </MDBCol>
                        <MDBCol sm="6" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.description}
                          </p>
                        </MDBCol>
                        <MDBCol sm="3" className="mt-2">
                          <p className="quickSand text-black scoreboardFont">
                            {moment(item.date, "x").fromNow()}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  );
                } else if (item.type === "topics") {
                  return (
                    <MDBCard
                      key={item._id}
                      style={{ height: "auto", cursor: "pointer" }}
                      className="tradingCard5 mx-2 my-2"
                      onClick={() => {
                        viewTopic({ _id: item._id });
                      }}
                    >
                      <MDBRow className="widthFull2">
                        <MDBCol sm="1">
                          <MDBIcon icon="graduation-cap" size="sm" />
                        </MDBCol>
                        <MDBCol sm="2" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.title}
                          </p>
                        </MDBCol>
                        <MDBCol sm="6" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.description}
                          </p>
                        </MDBCol>
                        <MDBCol sm="3" className="mt-2">
                          <p className="quickSand text-black scoreboardFont">
                            {moment(item.date, "x").fromNow()}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  );
                } else if (item.type === "exams") {
                  return (
                    <MDBCard
                      key={item._id}
                      style={{ height: "auto", cursor: "pointer" }}
                      className="tradingCard3 mx-2 my-2"
                      onClick={() => {
                        viewExam({ _id: item._id });
                      }}
                    >
                      <MDBRow className="widthFull2">
                        <MDBCol sm="1">
                          <MDBIcon icon="th" size="sm" />
                        </MDBCol>
                        <MDBCol sm="2" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.name}
                          </p>
                        </MDBCol>
                        <MDBCol sm="6" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.description}
                          </p>
                        </MDBCol>
                        <MDBCol sm="3" className="mt-2">
                          <p className="quickSand text-black scoreboardFont">
                            {moment(item.date, "x").fromNow()}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  );
                } else if (item.type === "games") {
                  return (
                    <MDBCard
                      key={item._id}
                      style={{ height: "auto", cursor: "pointer" }}
                      className="tradingCard1 mx-2 my-2"
                      onClick={() => {
                        viewGame({ _id: item._id });
                      }}
                    >
                      <MDBRow className="widthFull2">
                        <MDBCol sm="1">
                          <MDBIcon icon="trophy" size="sm" />
                        </MDBCol>
                        <MDBCol sm="2" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.title}
                          </p>
                        </MDBCol>
                        <MDBCol sm="6" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.description}
                          </p>
                        </MDBCol>
                        <MDBCol sm="3" className="mt-2">
                          <p className="quickSand text-black scoreboardFont">
                            {moment(item.date, "x").fromNow()}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  );
                } else if (item.type === "decks") {
                  return (
                    <MDBCard
                      key={item._id}
                      style={{ height: "auto", cursor: "pointer" }}
                      className="tradingCard2 mx-2 my-2"
                      onClick={() => {
                        viewDeck({ _id: item._id });
                      }}
                    >
                      <MDBRow className="widthFull2">
                        <MDBCol sm="1">
                          <MDBIcon icon="brain" size="sm" />
                        </MDBCol>
                        <MDBCol sm="2" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.name}
                          </p>
                        </MDBCol>
                        <MDBCol sm="6" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.description}
                          </p>
                        </MDBCol>
                        <MDBCol sm="3" className="mt-2">
                          <p className="quickSand text-black scoreboardFont">
                            {moment(item.date, "x").fromNow()}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  );
                } else if (item.type === "curriculums") {
                  return (
                    <MDBCard
                      key={item._id}
                      style={{ height: "auto", cursor: "pointer" }}
                      className="tradingCard4 mx-2 my-2"
                      onClick={() => {
                        viewCurriculum({ _id: item._id });
                      }}
                    >
                      <MDBRow className="widthFull2">
                        <MDBCol sm="1">
                          <MDBIcon icon="university" size="sm" />
                        </MDBCol>
                        <MDBCol sm="2" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.title}
                          </p>
                        </MDBCol>
                        <MDBCol sm="6" className="mt-2">
                          <p className="quickSand text-black scoreboardFont text-truncate">
                            {item.description}
                          </p>
                        </MDBCol>
                        <MDBCol sm="3" className="mt-2">
                          <p className="quickSand text-black scoreboardFont">
                            {moment(item.date, "x").fromNow()}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  );
                } else {
                  return null;
                }
              })}
            </div>

            {/* Modules Pagination Medium Size  */}

            <div className="paginationTradingMedium">
              <MDBRow>
                <MDBCol>
                  <MDBPagination circle>
                    <MDBPageItem onClick={firstPaginationModules}>
                      <MDBPageNav className="page-link">
                        <span>First</span>
                      </MDBPageNav>
                    </MDBPageItem>
                    <MDBPageItem onClick={previousPaginationModules}>
                      <MDBPageNav className="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </MDBPageNav>
                    </MDBPageItem>
                    {[...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            key={index}
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                    <MDBPageItem onClick={nextPaginationModules}>
                      <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                    </MDBPageItem>
                    <MDBPageItem onClick={lastPaginationModules}>
                      <MDBPageNav className="page-link">Last</MDBPageNav>
                    </MDBPageItem>
                  </MDBPagination>
                </MDBCol>
              </MDBRow>
            </div>

            {/* Modules Pagination Small Size  */}

            <div className="paginationTradingSmall">
              <MDBRow>
                <MDBCol>
                  <MDBPagination circle size="sm">
                    <MDBPageItem onClick={firstPaginationModules}>
                      <MDBPageNav className="page-link">
                        <span>First</span>
                      </MDBPageNav>
                    </MDBPageItem>
                    <MDBPageItem onClick={previousPaginationModules}>
                      <MDBPageNav className="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </MDBPageNav>
                    </MDBPageItem>
                    {[...Array(numberOfPages)].map((item, index) => {
                      if (index + 1 === pageNumber) {
                        return (
                          <MDBPageItem
                            key={index}
                            active={isActive1(index + 1)}
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber - 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 1) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 2) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else if (index + 1 === pageNumber + 3) {
                        return (
                          <MDBPageItem
                            onClick={() => currentPaginationModules(index)}
                          >
                            <MDBPageNav className="page-link">
                              {index + 1}{" "}
                              <span className="sr-only">(current)</span>
                            </MDBPageNav>
                          </MDBPageItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                    <MDBPageItem onClick={nextPaginationModules}>
                      <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                    </MDBPageItem>
                    <MDBPageItem onClick={lastPaginationModules}>
                      <MDBPageNav className="page-link">Last</MDBPageNav>
                    </MDBPageItem>
                  </MDBPagination>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCol>

          {/* Right Side  */}

          <MDBCol md="4" className="mb-r">
            <MDBCard className="tradingCard mb-3" style={{ height: "auto" }}>
              <MDBRow>
                <MDBCol size="3"></MDBCol>
                <MDBCol size="6" className="text-center">
                  <MDBAvatar
                    tag="img"
                    src={user.avatar.url}
                    className="rounded-circle z-depth-1 profileAvatarWidth mr-2 mt-1"
                    alt="avatar"
                  />
                </MDBCol>
                <MDBCol size="3" className="text-right">
                  <MDBIcon
                    size="1x"
                    icon="pen"
                    style={{ cursor: "pointer" }}
                    className=" cyan-text mr-3 mt-2"
                    onClick={modalDescription}
                  />
                </MDBCol>
              </MDBRow>
              <MDBCardBody className="pt-0 mt-0">
                <MDBCol className="mr-auto text-center">
                  <p className="quickSand h4 text-white">{user.name}</p>
                </MDBCol>
                <h5 className="font-bold cyan-text mb-4 quickSand">
                  Screen Name: {user.screenName}
                </h5>
                <p className="mt-4 dark-grey-text quickSand text-truncate">
                  {user.description}
                </p>
                <p className="quickSand h4 cyan-text">{user.score} points</p>
              </MDBCardBody>
            </MDBCard>
            <div className="followersCard" style={{ height: "auto" }}>
              <MDBRow center>
                <p className="text-white h6-responsive quickSand">Followers</p>
              </MDBRow>
              <MDBRow center>
                {follow.followed.map((item, index) => {
                  if (index === 0) {
                    return (
                      <MDBAvatar
                        key={item._id}
                        tag="img"
                        src={item.avatar.url}
                        className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                        alt="avatar"
                        onClick={() => viewProfile({ _id: item._id })}
                        style={{ cursor: "pointer", width: "55px" }}
                      />
                    );
                  } else if (index === 1) {
                    return (
                      <MDBAvatar
                        tag="img"
                        src={item.avatar.url}
                        className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                        alt="avatar"
                        onClick={() => viewProfile({ _id: item._id })}
                        style={{ cursor: "pointer", width: "55px" }}
                      />
                    );
                  } else if (index === 2) {
                    return (
                      <MDBAvatar
                        tag="img"
                        src={item.avatar.url}
                        className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                        alt="avatar"
                        onClick={() => viewProfile({ _id: item._id })}
                        style={{ cursor: "pointer", width: "55px" }}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </MDBRow>
              <MDBRow center>
                {follow.followed.map((item, index) => {
                  if (index === 3) {
                    return (
                      <MDBAvatar
                        key={item._id}
                        tag="img"
                        src={item.avatar.url}
                        className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                        alt="avatar"
                        onClick={() => viewProfile({ _id: item._id })}
                        style={{ cursor: "pointer", width: "55px" }}
                      />
                    );
                  } else if (index === 4) {
                    return (
                      <MDBAvatar
                        tag="img"
                        src={item.avatar.url}
                        className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                        alt="avatar"
                        onClick={() => viewProfile({ _id: item._id })}
                        style={{ cursor: "pointer", width: "55px" }}
                      />
                    );
                  } else if (index === 5) {
                    return (
                      <MDBAvatar
                        tag="img"
                        src={item.avatar.url}
                        className="rounded-circle z-depth-1 avatarWidth mx-1 my-1"
                        alt="avatar"
                        onClick={() => viewProfile({ _id: item._id })}
                        style={{ cursor: "pointer", width: "55px" }}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </MDBRow>

              <MDBRow center>
                <p
                  className="badScript text-white mx-2 my-2"
                  style={{ cursor: "pointer" }}
                  onClick={modalFollow}
                >
                  {`${follow.followed.length} tutors are following you`}
                </p>
              </MDBRow>
            </div>
            <hr />
            <MDBRow
              style={{ cursor: "pointer" }}
              onClick={modalFollowing}
              center
              className="mt-2 "
            >
              <p className="text-info h5-responsive quickSand followListHover">
                Follow List
              </p>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

PrivateProfileView.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, { getUserModules })(PrivateProfileView)
);
