import { REMOVE_ALERT, ALERT_ERROR, ALERT_SUCCESS } from "../actions/types";

const initialState = {
  alertError: "",
  alertSuccess: ""
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALERT_ERROR:
      return {
        ...state,
        alertError: payload
      };
    case ALERT_SUCCESS:
      return {
        ...state,
        alertSuccess: payload
      };
    case REMOVE_ALERT:
      return {
        ...state,
        alertSuccess: "",
        alertError: ""
      };

    default:
      return state;
  }
}
