import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import "./PublicPage.css";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import io from "socket.io-client";

import {
  MDBContainer,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBJumbotron
} from "mdbreact";

import {
  verifyEmailMessage,
  clearUpdatedMessage
} from "../../actions/publicpage";

const url = io("/messages/student", {
  transports: ["websocket"]
});

const PublicPageURL = ({
  match,

  publicpage: { verifyMessageEmail, verifyMessageEmailLoading, updateMsgAlert },
  verifyEmailMessage,
  clearUpdatedMessage
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    verifyEmailMessage({
      messageId: match.params.id
    });
  }, [verifyEmailMessage, match.params.id]);

  useEffect(() => {
    if (updateMsgAlert) {
      url.emit("updateLastedPublicpageMsg", {
        userTutor: updateMsgAlert.userTutor,
        latestPublicpageMsg: updateMsgAlert.latestPublicpageMsg
      });

      clearUpdatedMessage();
    }
  }, [updateMsgAlert, clearUpdatedMessage]);

  // before return
  return verifyMessageEmailLoading === true ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Message</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 text-center">
        <MDBRow>
          <MDBCol md="10" lg="10" xl="10" sm="12" className="mt-5 mx-auto">
            <MDBJumbotron className="mt-5">
              <MDBCardBody color="blue">
                <h1 className="x-large appointmentFontColor railwayFonty">
                  {t("messages.3")}
                </h1>
                {verifyMessageEmail === true ? (
                  <p className="quickSand2">
                    <MDBIcon
                      icon="check"
                      size="md"
                      className="mr-2 appointmentFontColor"
                    />
                    {t("messages.1")}
                  </p>
                ) : (
                  <p className="quickSand2">
                    <MDBIcon
                      icon="times"
                      size="md"
                      className="mr-2 appointmentFontColor2"
                    />
                    {t("messages.2")}
                  </p>
                )}
              </MDBCardBody>
            </MDBJumbotron>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

PublicPageURL.propTypes = {};

const mapStateToProps = state => ({
  publicpage: state.publicpage
});

export default withRouter(
  connect(mapStateToProps, { verifyEmailMessage, clearUpdatedMessage })(
    PublicPageURL
  )
);
