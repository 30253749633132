import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import { Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";

const Alert = ({ alert }) => {
  const messageError = () => {
    return (
      <Fragment>
        <MDBRow>
          <MDBCol>
            <MDBIcon icon='exclamation' />
            <p>{alert.alertError.msg}</p>
          </MDBCol>
        </MDBRow>
      </Fragment>
    );
  };
  const messageSuccess = () => {
    return (
      <Fragment>
        <MDBRow>
          <MDBCol>
            <MDBIcon icon='check' />
            <p>{alert.alertSuccess.msg}</p>
          </MDBCol>
        </MDBRow>
      </Fragment>
    );
  };

  if (alert.alertError) {
    toast.error(messageError);
  }
  if (alert.alertSuccess) {
    toast.success(messageSuccess);
  }

  return (
    <ToastContainer
      className='zIndexToast'
      transition={Zoom}
      position='bottom-right'
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange={false}
      draggable
      pauseOnHover
    />
  );
};

Alert.propTypes = {
  alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  alert: state.alert
});

export default withRouter(connect(mapStateToProps)(Alert));
