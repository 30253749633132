import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ResendToken from "./ResendToken";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  MDBJumbotron,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle
} from "mdbreact";

const ActivationWelcome = ({ auth }) => {
  if (auth.user) {
    if (auth.user.active === true) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Welcome</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 text-center">
        <MDBRow>
          <MDBCol>
            <MDBJumbotron className="mt-5">
              <MDBCardBody>
                <MDBCardTitle className="h2 Prata mainColor">
                  Thank you!
                </MDBCardTitle>
                <p className="my-4 font-weight-bold quickSand mainEditFontSize2">
                  A confirmation link has been sent to your email.
                </p>
                <MDBCardText className="quickSand">
                  An activation link has been send to your account. Please open
                  your email and click the link to complete the registration of
                  your account. Double check your spam folder just in case your
                  email service provider placed the email there.
                </MDBCardText>
                <MDBCardText className="quickSand">
                  If you have not received an email, please create a new
                  activation link by submitting your registered email below. For
                  those that have outlook, the email might get block. We suggest
                  using another email service provider if you don't receive a
                  confirmation email.
                </MDBCardText>
                <hr className="my-4" />
                <div className="pt-2">
                  <ResendToken />
                  {auth.resendToken ? <p> {auth.resendToken.message}</p> : ""}
                </div>
              </MDBCardBody>
            </MDBJumbotron>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

ActivationWelcome.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, {})(ActivationWelcome));
