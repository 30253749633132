import {
  CUSTOMIZABLES_ERROR,
  MAKE_CUSTOMIZABLE,
  DELETE_CUSTOMIZABLE,
  GET_CUS_EXAM_LIST,
  UPDATE_CUSTOMIZABLE,
  GET_CUS_EXAM_EVALUATIONS,
  UPDATE_CUSEXAM_EVAL_TEACHER,
  DELETE_CUSTOMIZABLE_EVAL,
  GET_ALL_CUS_EVALS,
  CLEAR_CUSTOMIZABLES,
  GET_CUS_EXAM,
  SEARCH_CUSTOMIZABLES,
  SET_PAGINATION_MODE_CUSTOMIZABLES,
  SET_LOADING_BUTTON_CUSTOMIZABLES,
  SET_LOADING_BUTTON_CUSTOMIZABLES_EVAL,
  SET_LOADING_BUTTON_DELETE_CUSTOMIZABLES,
  CLEAR_CUSTOMIZABLES_SEARCH,
  LOGOUT
} from "../actions/types";

const initialState = {
  loading: true,
  error: "",
  makeExam: "",
  listExams: [],
  updatedCusExam: "",
  deletedCusExam: "",
  examEvalList: [],
  cusExamEvalUpdate: "",
  cusExamEvalDeleted: "",
  cusEvalList: [],
  individualExam: "",
  initialPageNumber: 1,
  initialNumberOfPages: 1,
  searchExams: [],
  paginationModeExams: "date",
  keywords: "",
  numberOfPages: 1,
  pageNumber: 1,
  initialPageNumberCus: 1,
  initialNumberOfPagesCus: 1,
  loadingButtonCustomizables: false,
  loadingButtonCustomizablesEval: false,
  loadingButtonDeleteCustomizables: false,
  examLoading: true
};
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_CUSTOMIZABLES:
      return {
        ...state,
        makeExam: "",
        updatedCusExam: "",
        deletedCusExam: "",
        cusExamEvalUpdate: "",
        cusExamEvalDeleted: ""
      };
    case CLEAR_CUSTOMIZABLES_SEARCH:
      return {
        ...state,
        searchExams: "",
        keywords: "",
        numberOfPages: "",
        pageNumber: "",
        paginationModeExams: "date"
      };
    case MAKE_CUSTOMIZABLE:
      return {
        ...state,
        makeExam: payload.customizableExam,
        // listExams: [...state.listExams, payload.customizableExam],
        updatedCusExam: "",
        deletedCusExam: "",
        loadingButtonCustomizables: false
      };
    case GET_CUS_EXAM_LIST:
      return {
        ...state,
        listExams: payload.examList,
        updatedCusExam: "",
        makeExam: "",
        loading: false,
        deletedCusExam: "",
        initialPageNumber: payload.initialPageNumber,
        initialNumberOfPages: payload.initialNumberOfPages,
        examLoading: false
      };
    case CUSTOMIZABLES_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonCustomizables: false,
        loadingButtonCustomizablesEval: false,
        loadingButtonDeleteCustomizables: false
      };
    case UPDATE_CUSTOMIZABLE:
      return {
        ...state,

        makeExam: "",
        deletedCusExam: "",
        updatedCusExam: payload.customizableExamUpdate,
        loadingButtonCustomizables: false
      };
    case DELETE_CUSTOMIZABLE:
      return {
        ...state,

        listExams: state.listExams.filter(
          exam => exam._id !== payload.customizableExamDeleted._id
        ),
        deletedCusExam: payload.customizableExamDeleted._id,
        updatedCusExam: "",
        makeExam: "",
        loadingButtonDeleteCustomizables: false
      };
    case DELETE_CUSTOMIZABLE_EVAL:
      return {
        ...state,
        cusExamEvalDeleted: payload.evalExamDeleted._id,
        cusExamEvalUpdate: ""
      };
    case GET_CUS_EXAM_EVALUATIONS:
      return {
        ...state,
        examEvalList: payload
      };
    case UPDATE_CUSEXAM_EVAL_TEACHER:
      return {
        ...state,
        cusExamEvalUpdate: payload.exam,
        loadingButtonCustomizablesEval: false
        // cusExamEvalList: newArrayUpdate
      };
    case GET_ALL_CUS_EVALS:
      return {
        ...state,
        cusEvalList: payload.cusEvalList,
        initialPageNumberCus: payload.initialPageNumberCus,
        initialNumberOfPagesCus: payload.initialNumberOfPagesCus
      };
    case GET_CUS_EXAM:
      return {
        ...state,
        individualExam: payload
      };
    case SEARCH_CUSTOMIZABLES:
      return {
        ...state,
        searchExams: payload.searchExams,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        loading: false
      };
    case SET_PAGINATION_MODE_CUSTOMIZABLES:
      return {
        ...state,
        paginationModeExams: payload
      };
    case SET_LOADING_BUTTON_CUSTOMIZABLES:
      return {
        ...state,
        loadingButtonCustomizables: payload
      };
    case SET_LOADING_BUTTON_DELETE_CUSTOMIZABLES:
      return {
        ...state,
        loadingButtonDeleteCustomizables: payload
      };
    case SET_LOADING_BUTTON_CUSTOMIZABLES_EVAL:
      return {
        ...state,
        loadingButtonCustomizablesEval: payload
      };
    case LOGOUT:
      return {
        loading: true,
        error: "",
        makeExam: "",
        listExams: [],
        updatedCusExam: "",
        deletedCusExam: "",
        examEvalList: [],
        cusExamEvalUpdate: "",
        cusExamEvalDeleted: "",
        cusEvalList: [],
        individualExam: "",
        initialPageNumber: 1,
        initialNumberOfPages: 1,
        searchExams: [],
        paginationModeExams: "date",
        keywords: "",
        numberOfPages: 1,
        pageNumber: 1,
        initialPageNumberCus: 1,
        initialNumberOfPagesCus: 1,
        loadingButtonCustomizables: false,
        loadingButtonCustomizablesEval: false,
        loadingButtonDeleteCustomizables: false,
        examLoading: true
      };
    default:
      return state;
  }
}
