import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import TradingMain from "../TradingMain";
import { getPublicProfile, downloadTopic } from "../../../actions/trading";

const PublicProfile = ({
  history,
  mode,
  match,
  getPublicProfile,
  trading: { publicProfile },
  downloadTopic
}) => {
  const [formData, setFormData] = useState({
    mode: "publicProfile"
  });

  useEffect(() => {
    getPublicProfile({ id: match.params.id, pageNumber: "first" });
  }, [getPublicProfile, match]);

  useEffect(() => {
    if (publicProfile) {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "publicProfile"
        };
      });
    }
  }, [publicProfile]);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Public profile</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <TradingMain
        publicProfile={publicProfile}
        url={formData.mode}
      ></TradingMain>
    </Fragment>
  );
};

PublicProfile.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, { getPublicProfile, downloadTopic })(PublicProfile)
);
