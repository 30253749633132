import React, { Fragment, useEffect, useState } from "react";
import sum from "lodash/sum";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBCard
} from "mdbreact";

import {
  createComment,
  deleteComment,
  likeComment,
  setLoadingButtonComment
} from "../../../actions/trading";

const FlashcardView = ({
  history,
  mode,
  match,
  deckContent,
  createComment,
  deleteComment,
  likeComment,
  setLoadingButtonComment,
  trading: { comments, loadingButtonComment },
  auth: { user }
}) => {
  const [formData, setFormData] = useState({
    individualDeck: {
      name: "",
      description: "",
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      },
      cards: [],
      englishLevel: []
    },
    showCommentButton: false,
    comment: ""
  });
  useEffect(() => {
    if (deckContent) {
      setFormData(prevState => {
        return {
          ...prevState,
          individualDeck: {
            ...prevState.individualDeck,
            name: deckContent.name,
            description: deckContent.description,
            userTutor: deckContent.userTutor,
            cards: deckContent.cards,
            englishLevel: deckContent.englishLevel
          }
        };
      });
    }
  }, [deckContent]);

  const createCommentFunc = () => {
    setLoadingButtonComment(true);
    createComment({
      date: moment(new Date()).valueOf(),
      text: formData.comment,
      deckId: match.params.id,
      module: "deck"
    });
  };

  const onChangeComment = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const showCommentButton = () => {
    setFormData({
      ...formData,
      showCommentButton: true
    });
  };

  const likeFarFunc = item => {
    const find = item.thumbsUp.find(item3 => item3.user === user._id);
    if (find) {
      if (find.score === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const dislikeFarFunc = item => {
    const find = item.thumbsUp.find(item3 => item3.user === user._id);
    if (find) {
      if (find.score === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        comment: "",
        showCommentButton: false
      };
    });
  }, [comments]);

  const viewProfile = item => {
    history.push(`/trading/profile/${formData.individualDeck.userTutor._id}`);
  };

  const viewProfile2 = item => {
    history.push(`/trading/profile/${item}`);
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{formData.individualDeck.name}</title>
        <meta
          name="description"
          content={formData.individualDeck.description}
        />
        <meta property="og:title" content={formData.individualDeck.name} />
        <meta
          property="og:description"
          content={formData.individualDeck.description}
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link
          rel="canonical"
          href={`https://www.topscholar.io/trading/deck/${match.params.id}`}
        />
      </Helmet>
      <MDBRow className="middleVertical5 mx-2">
        <MDBCol sm="6">
          <MDBRow center>
            <MDBCol>
              <p className="quickSand mt-3 duruSans h5 text-center text-white">
                {formData.individualDeck.name}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol sm="6">
          <MDBRow
            center
            className="middleVertical tradingNameHover"
            onClick={() =>
              viewProfile({ _id: formData.individualDeck.userTutor._id })
            }
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ width: "35px" }}
              src={formData.individualDeck.userTutor.avatar.url}
              alt=""
              className="rounded-circle z-depth-1-half"
            />
            <p className="mt-3 quickSand titleFontSizeTrading text-black text-white pl-2">
              Published by {formData.individualDeck.userTutor.screenName}
            </p>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <hr />
      <MDBRow>
        <MDBCol>
          <p className="mt-1 quickSand h6 text-black text-white pl-2 mb-3 ml-2">
            Description: {formData.individualDeck.description}
          </p>
        </MDBCol>
      </MDBRow>

      <MDBContainer className=" mb-5 rounded backgroundTradingDeck">
        <hr />
        <MDBRow className="pt-2">
          <MDBCol size="6">
            <p className="text-center montserratFont tradingFontSize2">Front</p>
          </MDBCol>
          <MDBCol size="6">
            <p className="text-center montserratFont tradingFontSize2">Back</p>
          </MDBCol>
        </MDBRow>
        <hr />

        {formData.individualDeck.cards.map((item, index) => {
          return (
            <Fragment key={item._id}>
              <MDBRow className="pt-2">
                <MDBCol size="6" className="middleVertical mb-3">
                  <MDBCard className="backgroundTradingDeck2 tradingDeckCard">
                    <p className="text-center font-weight-bold tradingFontSize5 mt-2">
                      {item.front}
                    </p>
                  </MDBCard>
                </MDBCol>
                <MDBCol size="6" className="middleVertical mb-3">
                  <MDBCard className="backgroundTradingDeck3 tradingDeckCard">
                    <p className="text-center font-weight-bold tradingFontSize5 mt-2">
                      {item.back}
                    </p>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        })}
      </MDBContainer>
      <MDBRow center>
        <p className=" duruSans h5 text-center text-white">Comments</p>
      </MDBRow>
      <MDBRow center>
        <MDBCol size="12">
          <MDBInput
            style={{ overflowY: "auto" }}
            name="comment"
            type="textarea"
            value={formData.comment}
            label="Post a new comment"
            labelClass="livvicFont text-white"
            className="text-white"
            onChange={onChangeComment}
            onClick={showCommentButton}
          />
        </MDBCol>
        <MDBCol
          size="12"
          className={
            formData.showCommentButton === false ? "d-none" : "text-right"
          }
        >
          <MDBBtn
            onClick={() => {
              setFormData({
                ...formData,
                showCommentButton: false,
                comment: ""
              });
            }}
            outline
          >
            Cancel
          </MDBBtn>
          <MDBBtn disabled={loadingButtonComment} onClick={createCommentFunc}>
            {loadingButtonComment === true && (
              <div className="spinner-grow spinner-grow-sm" />
            )}
            Comment
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <hr />
      {comments.map((item, index) => {
        const thumb = item.thumbsUp.map((item2, index2) => {
          return item2.score;
        });
        let summation = sum(thumb);

        summation = summation <= 0 ? null : summation;

        return (
          <MDBRow className="mt-1 mb-4" key={item._id}>
            <MDBCol sm="4" md="2" className="col-4">
              <div className="label">
                <img
                  style={{ width: "35px", cursor: "pointer" }}
                  src={item.userTutor.avatar.url}
                  alt=""
                  className="rounded-circle z-depth-1-half"
                  onClick={() => viewProfile2(item.userTutor._id)}
                />
              </div>
            </MDBCol>
            <MDBCol sm="8" md="10" className="col-8">
              <div className="">
                <MDBRow className="middleVertical5">
                  <p
                    style={{ margin: "0rem", cursor: "pointer" }}
                    className="quickSand text-white"
                    onClick={() => viewProfile2(item.userTutor._id)}
                  >
                    {item.userTutor.screenName}
                  </p>

                  <div
                    className={
                      item.userTutor._id === user._id
                        ? "ml-auto mr-5"
                        : "d-none"
                    }
                  >
                    <MDBIcon
                      onClick={() => deleteComment({ commentId: item._id })}
                      style={{ cursor: "pointer" }}
                      className="text-danger"
                      icon="trash-alt"
                    />
                  </div>
                </MDBRow>
                <MDBRow>
                  <p style={{ marginBottom: "2px" }}>
                    {moment(item.date, "x").fromNow()}
                  </p>
                </MDBRow>
                <MDBRow>
                  <p
                    style={{ margin: "0rem" }}
                    className="text-white quickSand mb-1"
                  >
                    {item.text}
                  </p>
                </MDBRow>
                <MDBRow>
                  <p className="text-info font-weight-bold mr-2">{summation}</p>

                  <MDBIcon
                    style={{ cursor: "pointer" }}
                    className="mr-2 text-info"
                    far={likeFarFunc(item)}
                    icon="thumbs-up"
                    onClick={() => {
                      likeComment({ score: 1, commentId: item._id });
                    }}
                  />

                  <MDBIcon
                    style={{ cursor: "pointer" }}
                    className="text-info"
                    far={dislikeFarFunc(item)}
                    icon="thumbs-down "
                    onClick={() => {
                      likeComment({ score: -1, commentId: item._id });
                    }}
                  />
                </MDBRow>
              </div>
            </MDBCol>
          </MDBRow>
        );
      })}
    </Fragment>
  );
};

FlashcardView.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    createComment,
    deleteComment,
    likeComment,
    setLoadingButtonComment
  })(FlashcardView)
);
