import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MDBCard, MDBCardBody } from "mdbreact";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import union from "lodash/union";
import { Calendar, momentLocalizer } from "react-big-calendar";
import stripHtml from "string-strip-html";
import "../lessonPlans/lessons.css";
import "./Dashboard.css";

import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  makeReminder,
  getAllReminders,
  deleteReminder,
  setLoadingButtonReminders
} from "../../actions/dashboard";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBSelect,
  MDBInput,
  MDBScrollbar
} from "mdbreact";
import { getLessonsCalendar } from "../../actions/lessons";

// const localizer = momentLocalizer(moment);

// const removeUnderscore = function(str = "") {
//   return str.replace(/[\_]/gi, " ");
// };

// const currentTimezone = moment.tz.guess();

// const getMoment = (timezone = currentTimezone) => {
//   const m = (...args) => moment.tz(...args, timezone);
//   m.localeData = moment.localeData;
//   return m;
// };

const CalendarSection = ({
  makeReminder,
  dashboard: { reminders, loadingButtonReminders },
  getAllReminders,
  auth,
  deleteReminder,
  setLoadingButtonReminders,
  lessons: { lesson, calendarLessons, updatedLesson, lessonDeleted },
  getLessonsCalendar
}) => {
  const [formData, setFormData] = useState({
    events: [],
    modal: false,
    event: {
      start: "",
      end: "",
      title: "",
      id: "",
      type: "",
      comments: "",
      objective: "",
      date: "",
      groupIds: {
        name: ""
      },
      topicIds: [],
      classTitle: ""
    },
    header: "",
    delete: "",

    timezones: [],
    selectedTimezone: "",
    modalCreateReminder: false
  });

  // const momentAdjusted = getMoment(formData.selectedTimezone);
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    if (auth.user._id) {
      const timezones = moment.tz.names().map((label, index) => {
        if (label === auth.user.defaultTimezone) {
          return {
            text: label,
            index: index,
            value: label,
            checked: true
          };
        } else {
          return {
            text: label,
            index: index,
            value: label,
            checked: false
          };
        }
      });

      setFormData(prevState => {
        return {
          ...prevState,
          timezones: timezones,
          selectedTimezone: auth.user.defaultTimezone
        };
      });
    }
  }, [reminders, auth.user]);

  useEffect(() => {
    getAllReminders();
    getLessonsCalendar();
  }, [getLessonsCalendar, getAllReminders]);

  useEffect(() => {
    if (formData.selectedTimezone) {
      const classLessons = calendarLessons.map((item, index) => {
        const start1 = moment(item.start, "x")
          .tz(formData.selectedTimezone)
          .format("YYYY MM DD hh:mm a");
        const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();
        const end1 = moment(item.end, "x")
          .tz(formData.selectedTimezone)
          .format("YYYY MM DD hh:mm a");
        const end2 = moment(end1, "YYYY MM DD hh:mm a").toDate();

        return {
          classTitle: item.groupIds ? item.groupIds.name : "Not Assigned",
          title: item.title,
          body: item.body,
          start: start2,
          end: end2,
          id: item._id,
          type: "lesson",
          comments: item.comments,
          objective: item.objective,
          date: item.date,
          groupIds: item.groupIds,
          topicIds: item.topicIds
        };
      });
      const remindersArray = reminders.map((item, index) => {
        const start1 = moment(item.start, "x")
          .tz(formData.selectedTimezone)
          .format("YYYY MM DD hh:mm a");
        const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();
        const end1 = moment(item.end, "x")
          .tz(formData.selectedTimezone)
          .format("YYYY MM DD hh:mm a");
        const end2 = moment(end1, "YYYY MM DD hh:mm a").toDate();

        return {
          title: item.title,
          body: item.body,
          start: start2,
          end: end2,
          id: item._id,
          type: "reminder"
        };
      });

      const combinedArray = union(classLessons, remindersArray);
      setFormData(prevState => {
        return {
          ...prevState,
          events: combinedArray,
          modal: false,
          modalCreateReminder: false,
          event: {
            start: "",
            end: "",
            title: "",
            body: ""
          }
        };
      });
    }
  }, [calendarLessons, reminders, formData.selectedTimezone]);

  const toggle = () => {
    setFormData({
      ...formData,
      modal: !formData.modal,
      event: {
        start: "",
        end: "",
        title: "",
        id: "",
        type: "",
        comments: "",
        objective: "",
        date: "",
        groupIds: {
          name: ""
        },
        topicIds: [],
        classTitle: ""
      }
    });
  };

  const toggleReminderModal = () => {
    setFormData({
      ...formData,
      modalCreateReminder: !formData.modalCreateReminder
    });
  };

  const handleSelect = ({ start, end, type }) => {
    const start1 = moment(start).format("YYYY MM DD hh:mm a");

    const start2 = moment
      .tz(start1, "YYYY MM DD hh:mm a", formData.selectedTimezone)
      .valueOf();
    const end1 = moment(end).format("YYYY MM DD hh:mm a");

    const end2 = moment
      .tz(end1, "YYYY MM DD hh:mm a", formData.selectedTimezone)
      .valueOf();

    setFormData({
      ...formData,
      events: [...formData.events],
      modalCreateReminder: true,
      event: {
        ...formData.event,
        start: start2,
        end: end2,
        type: "reminder"
      }
    });
  };

  const deleteReminderFunc = async event => {
    await setLoadingButtonReminders(true);
    deleteReminder(event);
  };

  const handleEdit = event => {
    setFormData({
      ...formData,
      modal: !formData.modal,
      event: event
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    await setLoadingButtonReminders(true);
    makeReminder(formData.event);
  };

  const onChange = e =>
    setFormData({
      ...formData,
      event: {
        ...formData.event,
        [e.target.name]: e.target.value
      }
    });

  const selectTimezone = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        selectedTimezone: value[0]
      });
    }
  };

  const getDefaultDate = () => {
    const newDate = moment(new Date()).valueOf();
    const start1 = moment(newDate, "x")
      .tz(auth.user.defaultTimezone)
      .format("YYYY MM DD hh:mm a");

    const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();

    return start2;
  };

  const formats = {
    timeGutterFormat: "h a"
  };

  let components = {};

  const eventStyleGetter = (event, start, end, isSelected) => {
    if (event.type === "lesson") {
      let newStyle = {
        backgroundColor: "#9c45eb ",
        color: "white",
        borderRadius: "0px",
        fontFamily: "quickSand",
        borderColor: "black"
      };
      return {
        style: newStyle
      };
    } else if (event.type === "reminder") {
      let newStyle = {
        backgroundColor: "#e65100",
        color: "white",
        borderRadius: "0px",
        fontFamily: "quickSand",
        borderColor: "black"
      };
      return {
        style: newStyle
      };
    } else {
      let newStyle = {
        backgroundColor: "#9c45eb ",
        color: "black",
        borderRadius: "0px",
        fontFamily: "quickSand",
        borderColor: "black"
      };
      return {
        style: newStyle
      };
    }
  };

  return (
    <Fragment>
      <MDBCard style={{ marginBottom: "5rem", paddingBottom: "5rem" }}>
        <MDBCardBody>
          <div className="quickSand">
            <MDBSelect
              search
              className="quickSand black-text font-weight-bold   mt-3"
              options={formData.timezones}
              labelClass="black-text font-weight-bold quickSand"
              label="Timezone"
              outline
              size="sm"
              getValue={selectTimezone}
            />
          </div>
          {/* <MDBScrollbar> */}
          <div
            // touchStart={changeCssScroll}
            // touchMove={changeCssScroll}
            // className="mobileCalendarScroll customScroll"
            className="customScrollColorCalendar"
            // id="test100"
            style={{ overflowX: "auto" }}
          >
            <div style={{ minWidth: "30rem" }}>
              <Calendar
                localizer={localizer}
                // defaultDate={getDefaultDate()}
                defaultView="agenda"
                views={["agenda", "day", "week", "month"]}
                events={formData.events}
                style={{
                  height: "28rem",
                  fontFamily: "Quicksand"
                }}
                onSelectEvent={event => handleEdit(event)}
                onSelectSlot={handleSelect}
                formats={formats}
                getNow={getDefaultDate}
                // showMultiDayTimes={false}
                components={components}
                // scrollToTime={getStartDate()}
                // onSelecting={selectDisable}
                selectable={
                  formData.modal === true ||
                  formData.modalCreateReminder === true
                    ? null
                    : "ignoreEvents"
                }
                eventPropGetter={eventStyleGetter}
              />
            </div>
          </div>
          {/* </MDBScrollbar> */}
        </MDBCardBody>
      </MDBCard>

      {formData.modal === false ? (
        ""
      ) : (
        <MDBContainer>
          <MDBModal size="lg" isOpen={formData.modal} toggle={toggle}>
            {formData.event.type === "reminder" ? (
              <Fragment>
                <MDBModalBody>
                  <MDBContainer className="row d-flex justify-content-center">
                    <MDBCol md="6" className="text-center">
                      <p className="pb-1 montserratFont reminderFontSize5">
                        Title
                      </p>
                      <p className="reminderFontSize6 quickSand">
                        {formData.event.title}
                      </p>

                      <p className="pb-1 montserratFont reminderFontSize5">
                        Comment
                      </p>
                      <p className="reminderFontSize6 quickSand">
                        {formData.event.body}
                      </p>
                      <MDBBtn
                        disabled={loadingButtonReminders}
                        color="danger"
                        size="sm"
                        onClick={() => deleteReminderFunc(formData.event.id)}
                      >
                        {loadingButtonReminders === true && (
                          <div className="spinner-grow spinner-grow-sm" />
                        )}
                        Delete Reminder
                      </MDBBtn>
                    </MDBCol>
                  </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn
                    className="quickSand"
                    color="secondary"
                    onClick={toggle}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </Fragment>
            ) : (
              <Fragment>
                <MDBModalHeader className="text-center">
                  {formData.event.title.charAt(0).toUpperCase() +
                    formData.event.title.slice(1)}
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBContainer>
                    <MDBCardBody>
                      <MDBRow>
                        <MDBCol md="12">
                          <p className="h5 pb-1 montserratFont">
                            Class:{" "}
                            <span className="reminderFontSize4">
                              <strong>
                                {formData.event.groupIds
                                  ? formData.event.groupIds.name
                                      .charAt(0)
                                      .toUpperCase() +
                                    formData.event.groupIds.name.slice(1)
                                  : "Not Assigned"}
                              </strong>
                            </span>
                          </p>
                        </MDBCol>
                        <MDBCol md="12">
                          <p className="h5 pb-1 montserratFont">
                            Start Date:{" "}
                            <span className="reminderFontSize4">
                              <strong>
                                {moment(formData.event.start, "x").format(
                                  "llll"
                                )}
                              </strong>
                            </span>
                          </p>
                          <p className="h5 pb-1 montserratFont">
                            End Date:{" "}
                            <span className="reminderFontSize4">
                              <strong>
                                {moment(formData.event.end, "x").format("llll")}
                              </strong>
                            </span>
                          </p>
                        </MDBCol>
                      </MDBRow>

                      <hr />

                      <MDBRow>
                        <MDBCol md="12">
                          <p className="montserratFont reminderFontSize pb-1">
                            Objectives
                          </p>
                          <p className="reminderFontSize2 quickSand pb-1">
                            {formData.event.objective}
                          </p>
                        </MDBCol>
                        {formData.event.comments ? (
                          <MDBCol md="12">
                            <p className="pb-1 montserratFont reminderFontSize">
                              Lesson Comments
                            </p>
                            <div className="quickSand reminderFontSize2">
                              {stripHtml(formData.event.comments)}
                            </div>
                          </MDBCol>
                        ) : null}
                      </MDBRow>
                    </MDBCardBody>
                  </MDBContainer>
                </MDBModalBody>

                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={toggle}>
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </Fragment>
            )}
          </MDBModal>
        </MDBContainer>
      )}
      <MDBContainer>
        <MDBModal
          size="lg"
          isOpen={formData.modalCreateReminder}
          toggle={toggleReminderModal}
        >
          <Fragment>
            <form onSubmit={e => onSubmit(e)}>
              <MDBModalBody>
                <MDBContainer className="row d-flex justify-content-center">
                  <MDBCol md="6" className="text-center">
                    <p className="pb-1 montserratFont reminderFontSize">
                      Title
                    </p>
                    <MDBInput
                      label="Title"
                      type="text"
                      id="defaultFormContactNameEx"
                      className="form-control quickSand"
                      labelClass="quickSand"
                      name="title"
                      onChange={e => onChange(e)}
                      value={formData.event.title}
                      required
                    ></MDBInput>

                    <br />

                    <p
                      className="pb-1 montserratFont reminderFontSize"
                      htmlFor="defaultFormContactMessageEx"
                    >
                      Comment
                    </p>
                    <MDBInput
                      type="textarea"
                      id="defaultFormContactMessageEx"
                      className="form-control quickSand"
                      name="body"
                      onChange={e => onChange(e)}
                      value={formData.event.body}
                      outline
                      rows={3}
                    />
                  </MDBCol>
                </MDBContainer>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  className="quickSand"
                  type="submit"
                  color="primary"
                  disabled={loadingButtonReminders}
                >
                  {loadingButtonReminders === true && (
                    <div className="spinner-grow spinner-grow-sm" />
                  )}
                  Save
                </MDBBtn>
                <MDBBtn
                  className="quickSand"
                  color="secondary"
                  onClick={() => {
                    setFormData({ ...formData, modalCreateReminder: false });
                  }}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </Fragment>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

CalendarSection.propTypes = {
  makeReminder: PropTypes.func.isRequired,
  getAllReminders: PropTypes.func.isRequired,
  deleteReminder: PropTypes.func.isRequired,
  getLessonsCalendar: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  auth: state.auth,
  lessons: state.lessons
});

export default connect(mapStateToProps, {
  makeReminder,
  getAllReminders,
  deleteReminder,
  getLessonsCalendar,
  setLoadingButtonReminders
})(withRouter(CalendarSection));
