import {
  CURRICULUM_ERROR,
  MAKE_CURRICULUM,
  GET_CURRICULUMLIST,
  UPDATE_CURRICULUM,
  DELETE_CURRICULUM,
  SEARCH_CURRICULUMS,
  SET_PAGINATION_MODE_CURRICULUMS,
  SET_LOADING_BUTTON_CURRICULUMS,
  CLEAR_CURRICULUMS_SEARCH,
  SET_LOADING_DELETE_CURRICULUM,
  LOGOUT
} from "../actions/types";

const initialState = {
  listCurriculum: [],
  deletedCurriculum: "",
  loading: true,
  error: "",
  curriculum: "",
  searchCurriculums: [],
  initialNumberOfPages: 1,
  initialPageNumber: 1,
  numberOfPages: 1,
  pageNumber: 1,
  paginationModeCurriculums: "date",
  loadingButtonCurriculums: false,
  curriculumsLoading: true,
  loadingDeleteCurriculum: false
};
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_CURRICULUMS_SEARCH:
      return {
        ...state,
        searchCurriculums: "",
        keywords: "",
        numberOfPages: "",
        pageNumber: "",
        paginationModeCurriculums: "date"
      };
    case MAKE_CURRICULUM:
      return {
        ...state,
        listCurriculum: [...state.listCurriculum, payload.curriculum],
        curriculum: payload.curriculum,
        loadingButtonCurriculums: false
      };
    case GET_CURRICULUMLIST:
      return {
        ...state,
        listCurriculum: payload.listCurriculum,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber,
        curriculumsLoading: false
      };
    case CURRICULUM_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonCurriculums: false,
        loadingDeleteCurriculum: false
      };
    case UPDATE_CURRICULUM:
      const newArray = state.listCurriculum.map((item, index) => {
        if (item._id === payload.curriculum._id) {
          return payload.curriculum;
        } else {
          return item;
        }
      });
      return {
        ...state,
        listCurriculum: newArray,
        loadingButtonCurriculums: false
      };
    case DELETE_CURRICULUM:
      return {
        ...state,
        deletedCurriculum: payload.curriculum,
        listCurriculum: state.listCurriculum.filter(
          curriculum => curriculum._id !== payload.curriculum._id
        ),
        loadingDeleteCurriculum: false
      };
    case SEARCH_CURRICULUMS:
      return {
        ...state,
        searchCurriculums: payload.searchCurriculums,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        loading: false
      };
    case SET_PAGINATION_MODE_CURRICULUMS:
      return {
        ...state,
        paginationModeCurriculums: payload
      };
    case SET_LOADING_BUTTON_CURRICULUMS:
      return {
        ...state,
        loadingButtonCurriculums: payload
      };
    case SET_LOADING_DELETE_CURRICULUM:
      return {
        ...state,
        loadingDeleteCurriculum: payload
      };
    case LOGOUT:
      return {
        listCurriculum: [],
        deletedCurriculum: "",
        loading: true,
        error: "",
        curriculum: "",
        searchCurriculums: [],
        initialNumberOfPages: 1,
        initialPageNumber: 1,
        numberOfPages: 1,
        pageNumber: 1,
        paginationModeCurriculums: "date",
        loadingButtonCurriculums: false,
        curriculumsLoading: true,
        loadingDeleteCurriculum: false
      };
    default:
      return state;
  }
}
