import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import "./Landing.css";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Privacy</title>
        <meta name="description" content="Topscholar's privacy policy" />
        <meta property="og:title" content="Topscholar: Privacy" />
        <meta property="og:description" content="Topscholar's privacy policy" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/privacy" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5 privacyBackground">
        <MDBRow className="pt-5 mt-3 ">
          <MDBCol md="12" className="text-center col-12">
            <h3 className="font-weight-bold monserratFont mb-3 cookieMarginsMobile">
              {t("privacy.1")}
            </h3>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("privacy.2")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("privacy.3")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("privacy.4")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.5")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("privacy.6")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("privacy.7")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.8")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("privacy.9")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              {t("privacy.10")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent italicFont">
              {t("privacy.11")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.12")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent italicFont">
              {t("privacy.13")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.14")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent italicFont">
              {t("privacy.15")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.16")}
            </p>
            <p
              className="quickSand
            copyrightFont
            cookieMarginsMobile
            privacyIndent
            italicFont"
            >
              {t("privacy.17")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.18")}
            </p>

            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.19")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.20")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.21")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.22")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.23")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.24")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.25")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.26")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.27")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.28")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.29")}
              <a href="mailto:support@topscholar.io"> support@topscholar.io.</a>
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.30")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.31")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.32")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.33")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.34")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.35")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent italicFont">
              {t("privacy.36")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.37")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.38")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.39")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent italicFont">
              {t("privacy.40")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.41")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.42")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.43")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.44")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.45")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.46")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.47")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.48")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.49")}
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.50")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.51")}
              <a href="mailto:support@topscholar.io"> support@topscholar.io</a>.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              {t("privacy.52")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.53")}
              <a href="mailto:support@topscholar.io"> support@topscholar.io</a>.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.54")}
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile privacyIndent">
              {t("privacy.55")}
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default withRouter(Privacy);
