import {
  PAYMENT_ERROR,
  GET_LIST_CHARGES,
  GET_LIST_INVOICES,
  GET_LIST_CUSTOMERS,
  GET_PAYMENT_CUSTOMER,
  GET_LIST_INVOICES_INDIVIDUAL,
  GET_LIST_CHARGES_INDIVIDUAL,
  CONNECT_STRIPE_CHECKOUT,
  CONNECT_STRIPE_INVOICE,
  CONNECT_STRIPE_PAYMENTINTENT,
  CONNECT_STRIPE_GETSTUDENTS,
  CONNECT_STRIPE_GET_SUBSCRIPTION,
  CONNECT_STRIPE_GET_SUBSCRIPTION_LIST,
  SEARCH_PAYMENTS,
  SET_PAGINATION_MODE_PAYMENTS,
  CLEAR_PAYMENTS_SEARCH,
  CONNECT_STRIPE_GET_TAXRATES,
  CONNECT_STRIPE_CREATE_TAXRATE,
  CONNECT_STRIPE_UPDATE_TAXRATE,
  CONNECT_STRIPE_GET_PLANS,
  CONNECT_STRIPE_CREATE_PLAN,
  CONNECT_STRIPE_DELETE_PLAN,
  CONNECT_STRIPE_PLANS_SELECT,
  PAYMENTS_LOADING_TRUE,
  CONNECT_STRIPE_TAXRATES_SELECT,
  CONNECT_STRIPE_NEWINVOICE,
  CONNECT_STRIPE_CREATE_SUBSCRIPTION,
  GET_CONNECT_BALANCE,
  GET_CONNECT_BALANCE_TRANSACTIONS,
  GET_IP_COUNTRY,
  CONNECT_STRIPE_CHECKOUT_UPDATE,
  LOGOUT,
  SET_LOADING_BUTTON_SEND_INVOICE,
  SET_LOADING_BUTTON_CREATE_SUBSCRIPTION,
  SET_LOADING_BUTTON_ADD_TAXRATE,
  SET_LOADING_BUTTON_ADD_PLAN,
  SET_LOADING_REFUND,
  CLEAR_NEW_INVOICE_VAR
} from "../actions/types";

const initialState = {
  error: "",
  listCharges: {
    data: []
  },
  listInvoices: {
    data: []
  },
  listCustomers: {
    data: []
  },
  individualCustomer: {
    id: "",
    name: "",
    email: "",
    phone: "",
    balance: "",
    currency: "",
    preferred_locales: []
  },
  listInvoicesIndividual: {
    data: []
  },
  listChargesIndividual: {
    data: []
  },
  checkoutSession: {
    id: ""
  },
  individualInvoice: {
    id: "",
    payment_intent: "",
    subscription: ""
  },
  payment_intent: {
    status: "",
    charges: {
      data: []
    }
  },
  students: [],
  refund: "",
  subscription: {
    id: "",
    plan: {
      amount: "",
      interval: ""
    }
  },
  subscriptionList: {
    data: []
  },
  taxRatesList: {
    data: []
  },
  planList: {
    data: []
  },
  initialPageNumber: 1,
  initialNumberOfPages: 1,
  pageNumber: 1,
  numberOfPages: 1,
  searchPayments: [],
  paginationModePayments: "date",
  keywords: "",
  planListSelect: [],
  taxRatesListSelect: [],
  loading: false,
  loadingListInvoices: true,
  loadingGetStudents: true,
  loadingGetBalance: true,
  loadingGetBalanceTransactions: true,
  invoicePagination: {
    arrowInvoice: "next",
    initialPageInvoice: true
  },
  invoicePaginationIndividual: {
    arrowInvoicesIndividual: "next",
    initialPageInvoicesIndividual: true
  },
  subscriptionPaginationIndividual: {
    arrowSubscriptionsIndividual: "next",
    initialPageSubscriptionsIndividual: true
  },
  taxesPaginationIndividual: {
    arrowTaxesManage: "next",
    initialPageTaxesManage: true
  },
  plansPaginationIndividual: {
    arrowPlansManage: "next",
    initialPagePlansManage: true
  },
  balance: {
    available: [],
    pending: []
  },
  balanceTransactions: {
    data: []
  },
  ipCountry: "",
  taxRate: {
    type: "",
    rate: "",
    charge: {
      direct: "",
      reverse: ""
    }
  },
  loadingSendInvoice: false,
  loadingCreateSubscription: false,
  loadingAddTaxRate: false,
  loadingAddPlan: false,
  loadingRefund: false
};
export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_PAYMENTS_SEARCH:
      return {
        ...state,
        searchPayments: "",
        keywords: "",
        numberOfPages: "",
        pageNumber: "",
        paginationModePayments: "date"
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        error: payload,
        loadingSendInvoice: false,
        loadingCreateSubscription: false,
        loadingAddTaxRate: false,
        loadingAddPlan: false,
        loadingRefund: payload
      };
    case GET_LIST_CHARGES:
      return {
        ...state,
        listCharges: payload
      };
    case GET_LIST_INVOICES:
      return {
        ...state,
        loadingListInvoices: false,
        listInvoices: payload.invoices,
        invoicePagination: payload.pagination
      };
    case GET_LIST_CUSTOMERS:
      return {
        ...state,
        listCustomers: payload
      };
    case GET_PAYMENT_CUSTOMER:
      return {
        ...state,
        individualCustomer: payload
      };
    case GET_LIST_INVOICES_INDIVIDUAL:
      return {
        ...state,
        listInvoicesIndividual: payload.invoices,

        invoicePaginationIndividual: payload.pagination
      };
    case GET_LIST_CHARGES_INDIVIDUAL:
      return {
        ...state,
        listChargesIndividual: payload
      };
    case CONNECT_STRIPE_CHECKOUT:
      return {
        ...state,
        checkoutSession: payload.session
      };
    case CONNECT_STRIPE_CHECKOUT_UPDATE:
      return {
        ...state,
        checkoutSession: payload
      };
    case CONNECT_STRIPE_INVOICE:
      return {
        ...state,
        individualInvoice: payload
      };
    case CONNECT_STRIPE_PAYMENTINTENT:
      return {
        ...state,
        payment_intent: payload,
        loadingRefund: false
      };
    case CONNECT_STRIPE_GETSTUDENTS:
      return {
        ...state,
        loadingGetStudents: false,
        students: payload.students,
        initialPageNumber: payload.initialPageNumber,
        initialNumberOfPages: payload.initialNumberOfPages
      };
    case SEARCH_PAYMENTS:
      return {
        ...state,
        searchPayments: payload.searchPayments,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        loading: false
      };
    case SET_PAGINATION_MODE_PAYMENTS:
      return {
        ...state,
        paginationModePayments: payload
      };
    case CONNECT_STRIPE_GET_SUBSCRIPTION:
      return {
        ...state,
        subscription: payload
      };
    case CONNECT_STRIPE_GET_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscriptionList: payload.subscriptions,
        subscriptionPaginationIndividual: payload.pagination
      };
    case CONNECT_STRIPE_GET_TAXRATES:
      return {
        ...state,
        taxRatesList: payload.taxes,
        taxesPaginationIndividual: payload.pagination
      };
    case CONNECT_STRIPE_GET_PLANS:
      return {
        ...state,
        planList: payload.plans,
        plansPaginationIndividual: payload.pagination
      };
    case CONNECT_STRIPE_CREATE_TAXRATE:
      return {
        ...state,
        taxRatesList: {
          ...state.taxRatesList,
          data: [payload.taxRate, ...state.taxRatesList.data.slice(0, 4)]
        },
        loadingAddTaxRate: false
      };
    case CONNECT_STRIPE_CREATE_PLAN:
      return {
        ...state,
        planList: {
          ...state.planList,
          data: [payload.plan, ...state.planList.data.slice(0, 4)]
        },
        loadingAddPlan: false
      };
    case CONNECT_STRIPE_UPDATE_TAXRATE:
      const newArray = state.taxRatesList.data.map((item, index) => {
        if (item.id === payload.taxRate.id) {
          return payload.taxRate;
        } else {
          return item;
        }
      });
      return {
        ...state,
        taxRatesList: {
          ...state.taxRatesList,
          data: newArray
        }
      };
    case CONNECT_STRIPE_DELETE_PLAN:
      return {
        ...state,
        planList: payload.planList
      };
    case CONNECT_STRIPE_PLANS_SELECT:
      return {
        ...state,
        planListSelect: payload,
        loading: false
      };
    case CONNECT_STRIPE_TAXRATES_SELECT:
      return {
        ...state,
        taxRatesListSelect: payload,
        loading: false
      };

    case CONNECT_STRIPE_NEWINVOICE:
      return {
        ...state,
        listInvoicesIndividual: {
          ...state.listInvoicesIndividual,
          data: [
            payload.invoice,
            ...state.listInvoicesIndividual.data.slice(0, 4)
          ]
        },
        loadingSendInvoice: false,
        newInvoice: true
      };
    case CONNECT_STRIPE_CREATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptionList: {
          ...state.subscriptionList,
          data: [
            payload.subscription,
            ...state.subscriptionList.data.slice(0, 4)
          ]
        },
        loadingCreateSubscription: false,
        newInvoice: true
      };

    case PAYMENTS_LOADING_TRUE:
      return {
        ...state,
        loading: true
      };
    case GET_CONNECT_BALANCE:
      return {
        ...state,
        balance: payload,
        loadingGetBalance: false
      };
    case GET_CONNECT_BALANCE_TRANSACTIONS:
      return {
        ...state,
        balanceTransactions: payload,
        loadingGetBalanceTransactions: false
      };
    case GET_IP_COUNTRY:
      return {
        ...state,
        ipCountry: payload.ipCountry,
        taxRate: payload.taxRate
      };
    case SET_LOADING_BUTTON_SEND_INVOICE:
      return {
        ...state,
        loadingSendInvoice: payload
      };
    case SET_LOADING_BUTTON_CREATE_SUBSCRIPTION:
      return {
        ...state,
        loadingCreateSubscription: payload
      };
    case SET_LOADING_BUTTON_ADD_TAXRATE:
      return {
        ...state,
        loadingAddTaxRate: payload
      };
    case SET_LOADING_BUTTON_ADD_PLAN:
      return {
        ...state,
        loadingAddPlan: payload
      };
    case SET_LOADING_REFUND:
      return {
        ...state,
        loadingRefund: payload
      };
    case CLEAR_NEW_INVOICE_VAR:
      return {
        ...state,
        newInvoice: false
      };
    case LOGOUT:
      return {
        error: "",
        listCharges: {
          data: []
        },
        listInvoices: {
          data: []
        },
        listCustomers: {
          data: []
        },
        individualCustomer: {
          id: "",
          name: "",
          email: "",
          phone: "",
          balance: "",
          currency: "",
          preferred_locales: []
        },
        listInvoicesIndividual: {
          data: []
        },
        listChargesIndividual: {
          data: []
        },
        checkoutSession: {
          id: ""
        },
        individualInvoice: {
          id: "",
          payment_intent: "",
          subscription: ""
        },
        payment_intent: {
          status: "",
          charges: {
            data: []
          }
        },
        students: [],
        refund: "",
        subscription: {
          id: "",
          plan: {
            amount: "",
            interval: ""
          }
        },
        subscriptionList: {
          data: []
        },
        taxRatesList: {
          data: []
        },
        planList: {
          data: []
        },
        initialPageNumber: 1,
        initialNumberOfPages: 1,
        pageNumber: 1,
        numberOfPages: 1,
        searchPayments: [],
        paginationModePayments: "date",
        keywords: "",
        planListSelect: [],
        taxRatesListSelect: [],
        loading: false,
        loadingListInvoices: true,
        loadingGetStudents: true,
        loadingGetBalance: true,
        loadingGetBalanceTransactions: true,
        invoicePagination: {
          arrowInvoice: "next",
          initialPageInvoice: true
        },
        invoicePaginationIndividual: {
          arrowInvoicesIndividual: "next",
          initialPageInvoicesIndividual: true
        },
        subscriptionPaginationIndividual: {
          arrowSubscriptionsIndividual: "next",
          initialPageSubscriptionsIndividual: true
        },
        taxesPaginationIndividual: {
          arrowTaxesManage: "next",
          initialPageTaxesManage: true
        },
        plansPaginationIndividual: {
          arrowPlansManage: "next",
          initialPagePlansManage: true
        },
        balance: {
          available: [],
          pending: []
        },
        balanceTransactions: {
          data: []
        },
        ipCountry: "",
        taxRate: {
          type: "",
          rate: "",
          charge: {
            direct: "",
            reverse: ""
          }
        },
        loadingSendInvoice: false,
        loadingCreateSubscription: false,
        loadingAddTaxRate: false,
        loadingAddPlan: false,
        loadingRefund: false
      };
    default:
      return state;
  }
}
