import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "../layout/Spinner";
import unionBy from "lodash/unionBy";
import property from "lodash/property";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { DateTimePicker } from "react-widgets";
import momentLocalizer from "react-widgets-moment";
import NumericInput from "react-numeric-input";
import moment from "moment-timezone";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSelect,
  MDBBtn,
  MDBIcon,
  MDBListGroupItem,
  MDBListGroup,
  MDBSwitch
} from "mdbreact";
// import LinkCard from "../LinkCard";
import { getGroupsSelect } from "../../actions/groups";
import { getStudentsSelect } from "../../actions/students";
import {
  addLessons,
  updateLesson,
  clearLessonReducer,
  setLoadingButtonLessons
} from "../../actions/lessons";
import { getTopics } from "../../actions/trading";
import "react-widgets/dist/css/react-widgets.css";

// momentTimezone.locale("en");
momentLocalizer();

const CreateLesson = ({
  getStudentsSelect,
  pushTopicModalOpen,
  pushTopicModalClose,
  clearLessonReducer,
  getGroupsSelect,
  addLessons,
  updateLesson,
  getTopics,
  setLoadingButtonLessons,
  groups: { groupsSelect, loading },
  lessons: { topic, prevLesson, loadingButtonLessons },
  trading: { topics },
  students: { studentsSelect },
  history,
  individualLesson,
  auth: { user },
  saveLessonBoolean,
  clearsaveLessonBoolean
}) => {
  const [formData, setFormData] = useState({
    classOptions: [
      {
        text: "",
        value: ""
      }
    ],
    topicOptions: [],
    newLesson: {
      title: "",
      start: moment(new Date()).valueOf(),
      end: moment(new Date()).valueOf(),
      timer: 0,
      timerArray: [],
      groupIds: "",
      student: "",
      comments: "",
      objective: "By the end of the lesson, my students will have...",
      date: "",
      topicIds: [],
      switch: false,
      vibrate: true,
      sound: true,
      assignedType: "none",
      dateBoolean: true
    },

    topicShow: [],
    lessonError: "",
    loading: false,
    dragColor: false,
    modalPushTopic: false,
    selectedClass: [],
    selectedTimezone: ""
  });

  const [formData2, setFormData2] = useState({
    text: ""
  });

  // moment.tz.setDefault(user.defaultTimezone);

  useEffect(() => {
    setFormData(prevState => {
      const start1 = moment(prevState.newLesson.start, "x")
        .tz(user.defaultTimezone)
        .format("YYYY MM DD hh:mm a");

      const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();
      const end1 = moment(prevState.newLesson.end, "x")
        .tz(user.defaultTimezone)
        .format("YYYY MM DD hh:mm a");
      const end2 = moment(end1, "YYYY MM DD hh:mm a").toDate();

      return {
        ...prevState,
        newLesson: {
          ...prevState.newLesson,
          start: start2,
          end: end2
        }
      };
    });
  }, [user.defaultTimezone]);

  useEffect(() => {
    getGroupsSelect();
    getStudentsSelect();
    getTopics();
  }, [getGroupsSelect, getStudentsSelect, getTopics]);
  useEffect(() => {
    if (groupsSelect) {
      if (individualLesson.assignedType === "class") {
        var classArray = groupsSelect.map((item, index) => {
          return {
            text: item.name,
            value: item._id
          };
        });
        var studentsArray = studentsSelect.map((item, index) => {
          return {
            text: item.name,
            value: item._id
          };
        });
        const noClass = { text: "Not Assigned", value: null };
        classArray.push(noClass);
        const newArray2 =
          individualLesson.groupIds === null
            ? classArray.map((item, index) => {
                if (item.value === null) {
                  return {
                    ...item,
                    checked: true
                  };
                } else {
                  return item;
                }
              })
            : classArray.map((item, index) => {
                if (item.value === individualLesson.groupIds._id) {
                  return {
                    ...item,
                    checked: true
                  };
                } else {
                  return item;
                }
              });
        setFormData(prevState => {
          return {
            ...prevState,
            classOptions: newArray2,
            studentOptions: studentsArray
          };
        });
      } else if (individualLesson.assignedType === "student") {
        var classArray2 = groupsSelect.map((item, index) => {
          return {
            text: item.name,
            value: item._id
          };
        });
        var studentsArray = studentsSelect.map((item, index) => {
          return {
            text: item.name,
            value: item._id
          };
        });

        const studentsArray2 = studentsArray.map((item, index) => {
          if (item.value === individualLesson.student._id) {
            return {
              ...item,
              checked: true
            };
          } else {
            return {
              ...item,
              checked: false
            };
          }
        });

        setFormData(prevState => {
          return {
            ...prevState,
            classOptions: classArray2,
            studentOptions: studentsArray2
          };
        });
      } else {
        var classArray3 = groupsSelect.map((item, index) => {
          return {
            text: item.name,
            value: item._id
          };
        });
        var studentsArray3 = studentsSelect.map((item, index) => {
          return {
            text: item.name,
            value: item._id
          };
        });
        setFormData(prevState => {
          return {
            ...prevState,
            classOptions: classArray3,
            studentOptions: studentsArray3
          };
        });
      }
    }
  }, [groupsSelect, individualLesson, studentsSelect]);

  useEffect(() => {
    if (topics) {
      const topicArray = topics.map((item, index) => {
        return {
          text: item.title,
          value: {
            id: item._id,
            title: item.title
          }
        };
      });

      setFormData(prevState => {
        return {
          ...prevState,
          topicOptions: unionBy(
            prevState.topicOptions,
            topicArray,
            property("value.id")
          )
        };
      });
    }
  }, [topics]);

  const getPickerValueDateStart = value => {
    if (value) {
      const mystring = moment(value).valueOf();

      setFormData({
        ...formData,

        newLesson: {
          ...formData.newLesson,
          start: mystring
        }
      });
    }
  };
  const getPickerValueDateEnd = value => {
    if (value) {
      // const time1 = moment(value)
      //   .tz(user.defaultTimezone)
      //   .format("YYYY MM DD hh:mm a");
      const time2 = moment(value).valueOf();
      // const mystring = moment(value).valueOf();

      setFormData({
        ...formData,

        newLesson: {
          ...formData.newLesson,

          end: time2
        }
      });
    }
  };

  const classValue = value => {
    setFormData({
      ...formData,
      selectedClass: value
    });
  };
  const studentValue = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        newLesson: {
          ...formData.newLesson,
          student: value
        }
      });
    }
  };

  useEffect(() => {
    if (formData.selectedClass.length !== 0) {
      let classArray = formData.selectedClass[0];

      setFormData(prevState => {
        return {
          ...prevState,
          newLesson: {
            ...prevState.newLesson,
            groupIds: classArray
          }
        };
      });
    }
  }, [formData.selectedClass]);

  useEffect(() => {
    if (individualLesson.title) {
      const bb = individualLesson.topicIds.map((item, index) => {
        return {
          text: item.title,
          value: {
            id: item._id,
            title: item.title
          },
          checked: true
        };
      });

      const start1 = moment(individualLesson.start, "x")
        .tz(user.defaultTimezone)
        .format("YYYY MM DD hh:mm a");
      const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();

      const end1 = moment(individualLesson.end, "x")
        .tz(user.defaultTimezone)
        .format("YYYY MM DD hh:mm a");
      const end2 = moment(end1, "YYYY MM DD hh:mm a").toDate();

      setFormData(prevState => {
        const newArray = unionBy(
          bb,
          prevState.topicOptions,
          property("value.id")
        );
        return {
          ...prevState,

          newLesson: {
            title: individualLesson.title,
            start: start2,
            end: end2,
            groupIds: individualLesson.groupIds
              ? individualLesson.groupIds
              : "",
            student: individualLesson.student ? individualLesson.student : "",
            assignedType: individualLesson.assignedType,
            comments: individualLesson.comments,
            objective: individualLesson.objective,
            date: individualLesson.date,
            timer: individualLesson.timer,
            topicIds: individualLesson.topicIds,
            timerArray: individualLesson.timerArray,
            switch: individualLesson.switch,
            sound: individualLesson.sound,
            vibrate: individualLesson.vibrate,
            dateBoolean: individualLesson.dateBoolean
          },
          topicOptions: newArray
        };
      });
    }
  }, [individualLesson, user.defaultTimezone]);

  useEffect(() => {
    if (individualLesson) {
      setFormData2(prevState => {
        return {
          ...prevState,
          text: individualLesson.comments
        };
      });
      // setFormData2({
      //   ...formData2,
      //   text: individualLesson.comments
      // });
    }
  }, [individualLesson]);

  const topicValue = value => {
    const newArray = value.map((item, index) => {
      return {
        _id: item.id,
        title: item.title
      };
    });

    setFormData({
      ...formData,
      newLesson: {
        ...formData.newLesson,
        topicIds: newArray
      },
      topicShow: value
    });
  };

  const onChange = e =>
    setFormData({
      ...formData,
      newLesson: {
        ...formData.newLesson,
        [e.target.name]: e.target.value
      }
    });
  const onChange2 = (content, delta, source, editor) => {
    setFormData2({
      ...formData2,
      text: content
    });
  };

  const setTimerNumberArray = (value, id) => {
    if (value === null) {
      const newArray = formData.newLesson.timerArray.map((item, index) => {
        if (item.topicId === id) {
          return {
            ...item,
            timer: ""
          };
        } else {
          return item;
        }
      });
      setFormData({
        ...formData,
        newLesson: {
          ...formData.newLesson,
          timerArray: newArray
        }
      });
    } else {
      let q = Math.floor(value);
      const newArray = formData.newLesson.timerArray.map((item, index) => {
        if (item.topicId === id) {
          return {
            ...item,
            timer: q
          };
        } else {
          return item;
        }
      });
      setFormData({
        ...formData,
        newLesson: {
          ...formData.newLesson,
          timerArray: newArray
        }
      });
    }
  };

  const formatTimer = o => {
    let q = o;

    q = Math.floor(o);

    return q;
  };

  useEffect(() => {
    if (formData.newLesson.timerArray.length !== 0) {
      const newArray = formData.newLesson.timerArray.map((item, index) => {
        return item.timer;
      });

      const reducer = (accumulator, currentValue) => accumulator + currentValue;

      const total = newArray.reduce(reducer);

      setFormData(prevState => {
        return {
          ...prevState,
          newLesson: {
            ...prevState.newLesson,
            timer: total
          }
        };
      });
    }
  }, [formData.newLesson.timerArray]);

  const dragColor = index => {
    if (formData.dragColor !== index) {
      return "CarterOne cyanBackground text-white my-1 z-depth-2";
    } else {
      return "CarterOne dragBackground text-white my-1 z-depth-2";
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      setFormData({
        ...formData,

        dragColor: ""
      });
    } else if (result.destination.index === result.source.index) {
      setFormData({
        ...formData,
        dragColor: ""
      });
    } else {
      const newArray = reorder(
        formData.newLesson.topicIds,
        result.source.index,
        result.destination.index
      );

      const newArray2 = reorder(
        formData.newLesson.timerArray,
        result.source.index,
        result.destination.index
      );

      setFormData({
        ...formData,
        newLesson: {
          ...formData.newLesson,
          topicIds: newArray,
          timerArray: newArray2
        },

        dragColor: ""
      });
    }
  };

  const onDragStart = result => {
    const index = result.source.index;
    setFormData({
      ...formData,
      dragColor: index
    });
  };

  useEffect(() => {
    if (prevLesson) {
      setFormData(prevState => {
        const newArray = prevState.topicOptions.map((item, index) => {
          const find = prevLesson.topicIds.find(
            item2 => item2._id === item.value.id
          );
          if (find) {
            return {
              ...item,
              checked: true
            };
          } else {
            return {
              ...item,
              checked: false
            };
          }
        });
        return {
          ...prevState,
          topicOptions: newArray,
          newLesson: { ...prevState.newLesson, topicIds: prevLesson.topicIds }
        };
      });
      clearLessonReducer();
      pushTopicModalClose();
    }
  }, [prevLesson, clearLessonReducer, pushTopicModalClose]);

  useEffect(() => {
    if (formData.newLesson.topicIds.length !== 0) {
      setFormData(prevState => {
        const newArray = prevState.newLesson.topicIds.map((item, index) => {
          const find = prevState.newLesson.timerArray.find(
            item2 => item2.topicId === item._id
          );

          if (find) {
            return find;
          } else {
            return {
              timer: 0,
              topicId: item._id,
              title: item.title
            };
          }
        });

        // const newArray2 = unionBy(
        //   prevState.newLesson.timerArray,
        //   newArray,
        //   property("topicId")
        // );
        return {
          ...prevState,
          newLesson: {
            ...prevState.newLesson,
            timerArray: newArray
          }
        };
      });
    }
  }, [formData.newLesson.topicIds]);

  const handleSwitchChange = () => {
    setFormData({
      ...formData,
      newLesson: {
        ...formData.newLesson,
        switch: !formData.newLesson.switch
      }
    });
  };

  const radioFunc1 = () => {
    setFormData({
      ...formData,
      newLesson: {
        ...formData.newLesson,
        sound: !formData.newLesson.sound
      }
    });
  };

  const radioFunc2 = () => {
    setFormData({
      ...formData,
      newLesson: {
        ...formData.newLesson,
        vibrate: !formData.newLesson.vibrate
      }
    });
  };

  const switchAssignedType = type => {
    setFormData({
      ...formData,
      newLesson: {
        ...formData.newLesson,
        assignedType: type,
        student: "",
        groupIds: ""
      }
    });
  };

  const onChangeCheck = e => {
    if (formData.newLesson.dateBoolean === true) {
      setFormData({
        ...formData,
        newLesson: {
          ...formData.newLesson,
          dateBoolean: !formData.newLesson.dateBoolean,
          start: "315529200000",
          end: "315529200000"
        }
      });
    } else {
      const start1 = moment(moment(new Date()).valueOf(), "x")
        .tz(user.defaultTimezone)
        .format("YYYY MM DD hh:mm a");
      const start2 = moment(start1, "YYYY MM DD hh:mm a").toDate();
      const end1 = moment(moment(new Date()).valueOf(), "x")
        .tz(user.defaultTimezone)
        .format("YYYY MM DD hh:mm a");
      const end2 = moment(end1, "YYYY MM DD hh:mm a").toDate();
      setFormData({
        ...formData,
        newLesson: {
          ...formData.newLesson,
          dateBoolean: !formData.newLesson.dateBoolean,
          start: start2,
          end: end2
        }
      });
    }
  };

  const addDuration = value => {
    const submitStartFormat = () => {
      const start1 = moment(formData.newLesson.start, "x")
        // .tz(user.defaultTimezone)
        .format("YYYY MM DD hh:mm a");

      const start2 = moment
        .tz(start1, "YYYY MM DD hh:mm a", user.defaultTimezone)
        .valueOf();

      return start2;
    };
    const endDate = submitStartFormat() + 60 * 1000 * value;

    const end3 = moment(endDate, "x")
      .tz(user.defaultTimezone)
      .format("YYYY MM DD hh:mm a");

    const end4 = moment(end3, "YYYY MM DD hh:mm a").toDate();

    setFormData(prevState => {
      return {
        ...prevState,
        newLesson: {
          ...prevState.newLesson,

          end: end4
        }
      };
    });
  };

  useEffect(() => {
    if (saveLessonBoolean === true) {
      const onSubmitLesson = () => {
        setLoadingButtonLessons(true);
        const submitStartFormat = () => {
          const start1 = moment(formData.newLesson.start, "x")
            // .tz(user.defaultTimezone)
            .format("YYYY MM DD hh:mm a");

          const start2 = moment
            .tz(start1, "YYYY MM DD hh:mm a", user.defaultTimezone)
            .valueOf();

          return start2;
        };
        const submitEndFormat = () => {
          const start1 = moment(formData.newLesson.end, "x").format(
            "YYYY MM DD hh:mm a"
          );
          const start2 = moment
            .tz(start1, "YYYY MM DD hh:mm a", user.defaultTimezone)
            .valueOf();

          return start2;
        };
        if (individualLesson._id) {
          const newArray = formData.newLesson.topicIds.map((item, index) => {
            return item._id;
          });

          updateLesson(individualLesson._id, {
            ...formData.newLesson,
            start: submitStartFormat(),
            end: submitEndFormat(),
            topicIds: newArray,
            comments: formData2.text,
            groupIds: !formData.newLesson.groupIds
              ? null
              : formData.newLesson.groupIds,
            assignedType:
              !formData.newLesson.groupIds && !formData.newLesson.student
                ? "none"
                : formData.newLesson.assignedType
          });
          setFormData({
            ...formData,
            lessonError: ""
          });
        } else {
          const newArray = formData.newLesson.topicIds.map((item, index) => {
            return item._id;
          });
          addLessons({
            ...formData.newLesson,
            start: submitStartFormat(),
            end: submitEndFormat(),
            topicIds: newArray,
            comments: formData2.text,
            groupIds: !formData.newLesson.groupIds
              ? null
              : formData.newLesson.groupIds,
            assignedType:
              !formData.newLesson.groupIds && !formData.newLesson.student
                ? "none"
                : formData.newLesson.assignedType
          });
        }
      };
      onSubmitLesson();
      clearsaveLessonBoolean();
    }
  }, [saveLessonBoolean]);

  return loading ? (
    <Spinner />
  ) : (
    <section>
      <MDBCard className="mx-3 modalBottomMargin">
        <MDBCardBody>
          <MDBRow className="middleVertical center">
            <p className="h5 font-weight-bold quickSand pb-2">Class and date</p>
          </MDBRow>

          <MDBRow>
            <MDBCol md="4" className="mb-4">
              <MDBRow>
                <MDBInput
                  onClick={() => switchAssignedType("class")}
                  checked={
                    formData.newLesson.assignedType === "class" ? true : false
                  }
                  label="Class"
                  type="radio"
                  id="radio10"
                  containerClass="mr-5"
                  labelClass="quickSand"
                />

                <MDBInput
                  onClick={() => switchAssignedType("student")}
                  checked={
                    formData.newLesson.assignedType === "student" ? true : false
                  }
                  label="Student"
                  type="radio"
                  id="radio11"
                  containerClass="mr-5"
                  labelClass="quickSand"
                />
                <MDBInput
                  onClick={() => switchAssignedType("none")}
                  checked={
                    formData.newLesson.assignedType === "none" ? true : false
                  }
                  label="None"
                  type="radio"
                  id="radio12"
                  containerClass="mr-5"
                  labelClass="quickSand"
                />
              </MDBRow>
              {formData.newLesson.assignedType === "class" && (
                <div className="lessonInput quickSand">
                  <MDBSelect
                    search
                    options={formData.classOptions}
                    label={"Choose class"}
                    getValue={classValue}
                  />
                </div>
              )}
              {formData.newLesson.assignedType === "student" && (
                <div className="lessonInput quickSand">
                  <MDBSelect
                    search
                    options={formData.studentOptions}
                    label={"Choose student"}
                    getValue={studentValue}
                  />
                </div>
              )}
              {formData.newLesson.assignedType === "none" && (
                <p className="quickSand ">Assigned to: None</p>
              )}

              <MDBInput
                name="title"
                onChange={e => onChange(e)}
                value={formData.newLesson.title}
                label="Lesson Title"
                required
                labelClass="quickSand"
                className="quickSand"
              ></MDBInput>
            </MDBCol>
            <MDBCol md="4" className="mb-4" />
            <MDBCol md="4" className="mb-4 ">
              {formData.newLesson.dateBoolean ? (
                <p className="quickSand font-weight-bold">
                  <MDBIcon far icon="calendar-alt" />
                  {` Timezone: ${user.defaultTimezone}`}
                </p>
              ) : null}

              <MDBRow end className="mb-1 mr-1">
                <MDBInput
                  className="quickSand"
                  label="Include Date"
                  checked={formData.newLesson.dateBoolean}
                  type="checkbox"
                  id="2"
                  onChange={onChangeCheck}
                />
              </MDBRow>
              {formData.newLesson.dateBoolean ? (
                <Fragment>
                  <div className="mb-3">
                    <DateTimePicker
                      value={moment(formData.newLesson.start, "x").toDate()}
                      // value={getStartDate()}
                      onChange={getPickerValueDateStart}
                      format={"llll"}
                    />
                    <span className="quickSand text-center">Start Time</span>
                  </div>

                  <MDBBtn
                    className="btn-sm  py-1 px-1"
                    onClick={() => addDuration(30)}
                    color="dark"
                  >
                    30 mins
                  </MDBBtn>

                  <MDBBtn
                    color="dark"
                    className="btn-sm  py-1 px-1"
                    onClick={() => addDuration(45)}
                  >
                    45 mins
                  </MDBBtn>
                  <MDBBtn
                    color="dark"
                    className="btn-sm  py-1 px-1"
                    onClick={() => addDuration(60)}
                  >
                    60 mins
                  </MDBBtn>
                  <MDBBtn
                    color="dark"
                    className="btn-sm  py-1 px-1"
                    onClick={() => addDuration(90)}
                  >
                    90 mins
                  </MDBBtn>
                  <DateTimePicker
                    format={"llll"}
                    value={moment(formData.newLesson.end, "x").toDate()}
                    // value={getEndDate()}
                    onChange={getPickerValueDateEnd}
                  />
                  <span className="quickSand ">End Time</span>
                </Fragment>
              ) : null}
            </MDBCol>
          </MDBRow>

          <MDBRow className="middleVertical center">
            <p className="h5 font-weight-bold quickSand pb-2">
              Lesson objective
            </p>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <div className="lessonScrollInput">
                <MDBInput
                  style={{ overflowY: "auto" }}
                  name="objective"
                  onChange={e => onChange(e)}
                  value={formData.newLesson.objective}
                  type="textarea"
                  rows={5}
                  label="Lesson Objective"
                  labelClass="quickSand"
                  className="quickSand"
                />
              </div>
            </MDBCol>
            <MDBCol md="6" className="mb-4">
              <div>
                <ReactQuill
                  className="quill"
                  theme="bubble"
                  name="comments"
                  onChange={onChange2}
                  value={formData2.text}
                  placeholder="Extra Comments"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" }
                      ],

                      ["clean"],
                      [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" }
                      ]
                    ],

                    clipboard: {
                      matchVisual: false
                    }
                  }}
                />
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="middleVertical center">
            <p className="h5 font-weight-bold quickSand pb-2">Topics</p>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <div className="lessonInput dropDownStudents">
                <MDBSelect
                  multiple
                  search
                  options={formData.topicOptions}
                  selectAll
                  label="Add existing topic"
                  color="primary"
                  getValue={topicValue}
                />
              </div>
              <MDBRow className="middleVertical middleVertical3 center pt-5">
                <p className="h5 font-weight-bold quickSand pb-2">Timer</p>
              </MDBRow>
              <MDBSwitch
                className="cyanText"
                checked={formData.newLesson.switch}
                onChange={handleSwitchChange}
              />
              <div
                className={formData.newLesson.switch === true ? "" : "d-none"}
              >
                <MDBInput
                  onClick={radioFunc1}
                  checked={formData.newLesson.sound}
                  label="Sound Alert"
                  type="radio"
                  id="radio1"
                />
                <MDBInput
                  onClick={radioFunc2}
                  checked={formData.newLesson.vibrate}
                  label="Mobile Vibrate Alert"
                  type="radio"
                  id="radio2"
                />
                <MDBRow className="middleVertical middleVertical3 center pt-1">
                  <p className="h6 quickSand pb-2">
                    Please enter in minutes. Only integers allowed.
                  </p>{" "}
                </MDBRow>
                <MDBRow className="middleVertical">
                  <MDBCol>
                    <p className="cyanText quickSand h5 ">{`Total Duration: ${formData.newLesson.timer} mins`}</p>
                  </MDBCol>
                  <MDBCol>
                    {/* <NumericInput
                      className="form-control"
                      min={0}
                      disabled
                      max={500}
                      format={formatTimer}
                      value={formData.newLesson.timer}
                      onChange={setTimerNumber}
                    /> */}
                  </MDBCol>
                </MDBRow>
                {formData.newLesson.timerArray.map((item, index) => {
                  return (
                    <MDBRow className="middleVertical">
                      <MDBCol>
                        <p>Duration- {item.title}</p>
                      </MDBCol>
                      <MDBCol className="mb-4">
                        <NumericInput
                          className="form-control"
                          min={0}
                          max={500}
                          format={formatTimer}
                          value={item.timer}
                          onChange={value =>
                            setTimerNumberArray(value, item.topicId)
                          }
                        />
                      </MDBCol>
                    </MDBRow>
                  );
                })}
              </div>
            </MDBCol>

            <MDBCol md="6" className="mb-4">
              <MDBRow center className="mx-3 ">
                <div className="customizablesScroll">
                  <div style={{ overflowY: "auto" }}>
                    <MDBListGroup style={{ height: "23rem" }}>
                      <DragDropContext
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                      >
                        <Droppable droppableId="list">
                          {provided => (
                            <div
                              className="Hero-List"
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {formData.newLesson.topicIds.map(
                                (item, index) => {
                                  return (
                                    <Draggable
                                      key={item._id}
                                      draggableId={item._id}
                                      index={index}
                                    >
                                      {provided => (
                                        <div
                                          className="Container"
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                        >
                                          <MDBListGroupItem
                                            className={dragColor(index)}
                                          >
                                            <MDBRow
                                              style={{
                                                cursor: "pointer",
                                                minWidth: "12rem"
                                              }}
                                              className="middleVertical"
                                              onClick={() =>
                                                pushTopicModalOpen(
                                                  formData.newLesson,
                                                  item
                                                )
                                              }
                                            >
                                              <MDBCol size="12">
                                                <p className=" h6 text-white text-truncate font-weight-bold  quickSand text-center">
                                                  {`${index + 1} : ${
                                                    item.title
                                                  }`}
                                                </p>
                                              </MDBCol>
                                            </MDBRow>
                                            {/* </HashLink> */}
                                          </MDBListGroupItem>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }
                              )}

                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </MDBListGroup>
                  </div>
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </section>
  );
};

const mapStateToProps = state => ({
  groups: state.groups,
  lessons: state.lessons,
  students: state.students,
  auth: state.auth,
  trading: state.trading
});

export default connect(mapStateToProps, {
  getGroupsSelect,
  addLessons,
  getTopics,
  updateLesson,
  clearLessonReducer,
  setLoadingButtonLessons,
  getStudentsSelect
})(withRouter(CreateLesson));
