import React, { Fragment, useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { logoutStudent } from "../../actions/students";
import { withRouter } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import ReactGa from "react-ga";
import urlParser from "js-video-url-parser";

import "./Landing.css";

import {
  latestChatMsgFunc,
  latestChatMsgStudentFunc
} from "../../actions/students";
import {
  latestPublicpageMsgStudentFunc,
  getMessageList,
  getPublicPage
} from "../../actions/publicpage";
import { deferredPrompt } from "../../actions/subscription";
import { notificationSeen } from "../../actions/auth";
import moment from "moment-timezone";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBSideNavNav,
  MDBSideNav,
  MDBSideNavLink,
  MDBIcon,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdown,
  MDBDropdownToggle,
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from "mdbreact";
import "./Landing.css";
import io from "socket.io-client";
import { useTranslation } from "react-i18next";
import "moment/locale/fr";
import "moment/locale/pt";
import "moment/locale/it";
import "moment/locale/hi";
import "moment/locale/id";
import "moment/locale/es";
import "moment/locale/de";
import "moment/locale/tr";
import "moment/locale/zh-cn";

// import logo from "../../images/horizontalLogo4.png";
import logo from "../../images/TopscholarLogo.png";

const url = io("/messages/student", {
  transports: ["websocket"]
});

const Navbar = ({
  location,
  auth: {
    isAuthenticated,
    loading,
    user,
    latestChatMsg,
    latestPublicpageMsg,
    latestNotificationMsg
  },
  students: { user: userStudent, latestChatMsg: latestChatMsgStudent },
  logout,
  students,
  logoutStudent,
  history,
  latestChatMsgFunc,
  latestChatMsgStudentFunc,
  latestPublicpageMsgStudentFunc,
  getMessageList,
  getPublicPage,
  deferredPrompt,
  notificationSeen
}) => {
  const [formData, setFormData] = useState({
    collapseID: false,
    sideNavLeft: false,
    sideNavRight: false,
    announcementModal: false,
    passwordProtect: true
  });
  const { t, i18n } = useTranslation();

  const announcementModal = () => {
    if (latestNotificationMsg.message) {
      if (latestNotificationMsg.seen === false) {
        notificationSeen();
        setFormData({
          ...formData,
          announcementModal: !formData.announcementModal
        });
      } else {
        setFormData({
          ...formData,
          announcementModal: !formData.announcementModal
        });
      }
    }
  };

  useEffect(() => {
    if (i18n.language === "fr") {
      moment.locale("fr");
    } else if (i18n.language === "pt") {
      moment.locale("pt");
    } else if (i18n.language === "it") {
      moment.locale("it");
    } else if (i18n.language === "id") {
      moment.locale("id");
    } else if (i18n.language === "es") {
      moment.locale("es");
    } else if (i18n.language === "hi") {
      moment.locale("hi");
    } else if (i18n.language === "de") {
      moment.locale("de");
    } else if (i18n.language === "tr") {
      moment.locale("tr");
    } else if (i18n.language === "zh_CN") {
      moment.locale("zh-cn");
    } else {
      moment.locale("en");
    }
  }, [i18n.language]);

  const sidenavToggle = sidenavId => () => {
    const sidenavNr = `sideNav${sidenavId}`;
    setFormData({
      ...formData,
      [sidenavNr]: !formData[sidenavNr]
    });
  };

  const linkProfile = () => {
    return history.push("/profile");
  };
  const linkChat = () => {
    return history.push("/messages");
  };
  const linkPublicpage = () => {
    if (user.activatePublicPage === true) {
      return history.push({
        pathname: "/tutors",
        state: { studentRequests: true }
      });
    }
  };
  const linkChatStudent = () => {
    return history.push("/connect/messages");
  };

  const youtubeFunc = () => {
    const emb = urlParser.parse(latestNotificationMsg.youtube);
    if (typeof emb === "undefined") {
      return null;
    } else {
      const embUrl = urlParser.create({
        videoInfo: emb,
        format: "embed"
      });

      return (
        <MDBRow center className="mt-3">
          <div className="embed-responsive embed-responsive-16by9 mb-1">
            <iframe
              title="Embeds Page"
              className="embed-responsive-item"
              src={embUrl}
              allowFullScreen
            />
          </div>
        </MDBRow>
      );
    }
  };

  const authLinksRight = (
    <Fragment>
      <MDBNavItem>
        <MDBDropdown>
          <MDBDropdownToggle nav caret>
            <MDBBadge color="red" className="mr-2">
              {latestPublicpageMsg.seen === false &&
              latestChatMsg.seen === false &&
              latestNotificationMsg.seen === false
                ? 3
                : latestPublicpageMsg.seen === false &&
                  latestChatMsg.seen === false
                ? 2
                : latestChatMsg.seen === false &&
                  latestNotificationMsg.seen === false
                ? 2
                : latestPublicpageMsg.seen === false &&
                  latestNotificationMsg.seen === false
                ? 2
                : latestChatMsg.seen === false
                ? 1
                : latestPublicpageMsg.seen === false
                ? 1
                : latestNotificationMsg.seen === false
                ? 1
                : null}
            </MDBBadge>
            <MDBIcon icon="bell" />{" "}
            <span className=" d-md-inline quickSand">{t("other.1")}</span>
          </MDBDropdownToggle>
          <MDBDropdownMenu right style={{ minWidth: "275px" }}>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              className={
                latestChatMsg.seen === true
                  ? "quickSand"
                  : latestChatMsg.seen === false
                  ? "red white-text"
                  : "quickSand"
              }
              onClick={linkChat}
            >
              <span className="float-left">
                <MDBIcon icon="comments" className="mr-2 " />
              </span>
              <span
                style={{ maxWidth: "100px" }}
                className="d-inline-block text-truncate"
              >
                {latestChatMsg.msg ? latestChatMsg.msg : "No Messages"}
              </span>
              {latestChatMsg.date ? (
                <span
                  style={{ maxWidth: "100px" }}
                  className="float-right d-inline-block text-truncate"
                >
                  <MDBIcon icon="clock" />{" "}
                  {moment(latestChatMsg.date, "x").fromNow()}
                </span>
              ) : null}
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              className={
                latestPublicpageMsg.seen === true
                  ? "quickSand"
                  : latestPublicpageMsg.seen === false
                  ? "red white-text"
                  : "quickSand"
              }
              onClick={linkPublicpage}
            >
              <span className="float-left">
                <MDBIcon icon="address-card" className="mr-2 " />
              </span>
              <span
                style={{ maxWidth: "100px" }}
                className="d-inline-block text-truncate"
              >
                {latestPublicpageMsg.msg
                  ? latestPublicpageMsg.msg
                  : "No Messages"}
              </span>
              {latestPublicpageMsg.date ? (
                <span
                  style={{ maxWidth: "100px" }}
                  className="float-right d-inline-block text-truncate"
                >
                  <MDBIcon icon="clock" />{" "}
                  {moment(latestPublicpageMsg.date, "x").fromNow()}
                </span>
              ) : null}
            </MDBDropdownItem>

            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              className={
                latestNotificationMsg.seen === true
                  ? "quickSand"
                  : latestNotificationMsg.seen === false
                  ? "red white-text"
                  : "quickSand"
              }
              onClick={announcementModal}
            >
              <span className="float-left">
                <MDBIcon icon="bullhorn" className="mr-2 " />
              </span>
              <span
                style={{ maxWidth: "200px" }}
                className="d-inline-block text-truncate"
              >
                {latestNotificationMsg.message
                  ? latestNotificationMsg.message
                  : "No announcements"}
              </span>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavItem>
      <MDBNavItem>
        <MDBDropdown>
          <MDBDropdownToggle nav>
            <img
              style={{ width: "27px" }}
              src={user.avatar.url}
              alt=""
              className="rounded-circle z-depth-1-half"
            />{" "}
            <span className="d-md-inline quickSand">My Account</span>
          </MDBDropdownToggle>
          <MDBDropdownMenu right style={{ minWidth: "275px" }}>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={linkProfile}
              className="quickSand"
            >
              My Profile
            </MDBDropdownItem>

            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={logout}
              className="quickSand"
            >
              Logout
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavItem>
      {/* <MDBNavItem>
        <MDBNavLink to="/profile">Profile</MDBNavLink>
      </MDBNavItem>
      <MDBNavItem>
        <MDBNavLink onClick={logout} to="/login">
          Logout
        </MDBNavLink>
      </MDBNavItem> */}
    </Fragment>
  );
  const studentAuthLinksRight = (
    <Fragment>
      <MDBNavItem className="middleVertical5">
        <MDBDropdown>
          <MDBDropdownToggle nav caret>
            <MDBBadge color="red" className="mr-2">
              {latestChatMsgStudent.seen === false ? 1 : null}
            </MDBBadge>
            <MDBIcon icon="bell" />{" "}
            <span className=" d-md-inline quickSand">{t("other.1")}</span>
          </MDBDropdownToggle>
          <MDBDropdownMenu right style={{ minWidth: "275px" }}>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              className={
                latestChatMsgStudent.seen === true
                  ? "quickSand"
                  : latestChatMsgStudent.seen === false
                  ? "red white-text"
                  : "quickSand"
              }
              onClick={linkChatStudent}
            >
              <span className="float-left">
                <MDBIcon icon="comments" className="mr-2 " />
              </span>
              <span
                style={{ maxWidth: "100px" }}
                className="d-inline-block text-truncate"
              >
                {latestChatMsgStudent.msg
                  ? latestChatMsgStudent.msg
                  : "No Messages"}
              </span>
              {latestChatMsgStudent.date ? (
                <span
                  style={{ maxWidth: "100px" }}
                  className="float-right d-inline-block text-truncate"
                >
                  <MDBIcon icon="clock" />{" "}
                  {moment(latestChatMsgStudent.date, "x").fromNow()}
                </span>
              ) : null}
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavItem>
      <MDBNavItem
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        className="middleVertical5"
      >
        <MDBNavLink to="/connect/login" onClick={() => logoutStudent()}>
          {t("navbar.5")}
        </MDBNavLink>
      </MDBNavItem>
      <MDBNavItem className="middleVertical5">
        <MDBDropdown>
          <MDBDropdownToggle nav className="languageButton">
            <MDBIcon className="white-text ml-3" size="2x" icon="language" />
          </MDBDropdownToggle>
          <MDBDropdownMenu right>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("en")}
              className="quickSand"
            >
              English
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("es")}
              className="quickSand"
            >
              Español
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("fr")}
              className="quickSand"
            >
              Français
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("pt")}
              className="quickSand"
            >
              Português
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("it")}
              className="quickSand"
            >
              Italiano
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("id")}
              className="quickSand"
            >
              Bahasa Indonesia
            </MDBDropdownItem>

            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("hi")}
              className="quickSand"
            >
              हिन्दी
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("de")}
              className="quickSand"
            >
              Deutsch
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("tr")}
              className="quickSand"
            >
              Türkçe
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("zh_CN")}
              className="quickSand"
            >
              中文
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavItem>
    </Fragment>
  );
  const studentAuthLinksLeft = (
    <Fragment>
      <MDBNavItem
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        className="middleVertical5"
      >
        <MDBNavLink to="/connect/dashboard">{t("navbar.4")}</MDBNavLink>
      </MDBNavItem>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <MDBNavItem
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        className="middleVertical5"
      >
        <HashLink className="nav-item nav-link" to="/#landing">
          {t("navbar.2")}
        </HashLink>
      </MDBNavItem>
      <MDBNavItem
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        className="middleVertical5"
      >
        <MDBNavLink to="/login">{t("navbar.3")}</MDBNavLink>
      </MDBNavItem>
      <MDBNavItem className="middleVertical5">
        <MDBDropdown>
          <MDBDropdownToggle nav className="languageButton">
            <MDBIcon className="white-text ml-3" size="2x" icon="language" />
          </MDBDropdownToggle>
          <MDBDropdownMenu right>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("en")}
              className="quickSand"
            >
              English
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("es")}
              className="quickSand"
            >
              Español
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("fr")}
              className="quickSand"
            >
              Français
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("pt")}
              className="quickSand"
            >
              Português
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("it")}
              className="quickSand"
            >
              Italiano
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("id")}
              className="quickSand"
            >
              Bahasa Indonesia
            </MDBDropdownItem>

            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("hi")}
              className="quickSand"
            >
              हिन्दी
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("de")}
              className="quickSand"
            >
              Deutsch
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("tr")}
              className="quickSand"
            >
              Türkçe
            </MDBDropdownItem>
            <MDBDropdownItem
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              onClick={() => chooseLanguage("zh_CN")}
              className="quickSand"
            >
              中文
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavItem>
    </Fragment>
  );
  const guestLinksLeft = (
    <Fragment>
      <MDBNavItem
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        className="middleVertical5"
      >
        <MDBNavLink to="/findtutors">{t("navbar.1")}</MDBNavLink>
      </MDBNavItem>
    </Fragment>
  );

  const toggleCollapse = () => () =>
    setFormData(prevState => ({
      ...formData,
      collapseID: !prevState.collapseID
    }));

  var styles = {
    cursor: "pointer"
  };

  const updatelatestChatMsg = data => {
    if (data._id === user._id) {
      latestChatMsgFunc(data.latestChatMsg);
    }
  };
  useEffect(() => {
    url.on("updatelatestChatMsg", updatelatestChatMsg);

    return () => {
      url.off("updatelatestChatMsg", updatelatestChatMsg);
    };
  });
  const updatelatestChatMsgStudent = data => {
    latestChatMsgStudentFunc(data.latestChatMsg);
  };
  useEffect(() => {
    url.on("updatelatestChatMsgStudent", updatelatestChatMsgStudent);

    return () => {
      url.off("updatelatestChatMsgStudent", updatelatestChatMsgStudent);
    };
  });
  const updateLastedPublicpageMsg2 = data => {
    latestPublicpageMsgStudentFunc(data);
    getMessageList({ initialPageNumber: "first" });
    getPublicPage();
  };
  useEffect(() => {
    url.on("updateLastedPublicpageMsg2", updateLastedPublicpageMsg2);

    return () => {
      url.off("updateLastedPublicpageMsg2", updateLastedPublicpageMsg2);
    };
  });

  useEffect(() => {
    if (user._id) {
      url.emit("joinRooms", {
        userId: user._id
      });
    }
  }, [user._id]);

  useEffect(() => {
    if (userStudent._id) {
      url.emit("joinRoomStudent", {
        userId: userStudent._id
      });
    }
  }, [userStudent._id]);

  // Cookies Learn More Button

  const learnMore = () => {
    history.push(`/cookies`);
  };

  // Defer Install Prompt

  window.addEventListener("beforeinstallprompt", async function(event) {
    console.log("beforeinstallprompt fired");
    event.preventDefault();
    await deferredPrompt(event);
    return false;
  });

  const chooseLanguage = language => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    ReactGa.initialize("UA-155878366-1");
  }, []);

  useEffect(() => {
    ReactGa.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (isAuthenticated === true) {
      i18n.changeLanguage("en");
    }
  }, [isAuthenticated, i18n]);

  return (
    <Fragment>
      <div id="landing">
        <MDBNavbar dark expand="sm" fixed="top" scrolling color="color-one">
          <MDBContainer>
            {!loading && (
              <Fragment>
                {isAuthenticated ? (
                  <MDBIcon
                    style={styles}
                    size="2x"
                    id="sideButton"
                    onClick={sidenavToggle("Left")}
                    icon="caret-right"
                    className="white-text "
                  />
                ) : null}
              </Fragment>
            )}

            <MDBNavbarBrand>
              {!loading ? (
                <Fragment>
                  {isAuthenticated ? (
                    <div
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                      className="nav-item"
                    >
                      <MDBNavLink className="white-text" to="/dashboard">
                        <img src={logo} alt="" style={{ height: "56px" }} />
                      </MDBNavLink>
                    </div>
                  ) : students.isAuthenticated === true ? (
                    <MDBNavLink className="white-text" to="/connect/dashboard">
                      <img src={logo} alt="" style={{ height: "56px" }} />
                    </MDBNavLink>
                  ) : (
                    <MDBNavLink className="white-text" to="/">
                      <img src={logo} alt="" style={{ height: "56px" }} />
                    </MDBNavLink>
                  )}
                </Fragment>
              ) : (
                <MDBNavLink className="white-text" to="/">
                  <img src={logo} alt="" style={{ height: "56px" }} />
                </MDBNavLink>
              )}
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={toggleCollapse("navbarCollapse")} />
            <MDBCollapse
              id="navbarCollapse"
              isOpen={formData.collapseID}
              navbar
              style={{ overflow: "visible" }}
            >
              <MDBNavbarNav left>
                {!loading && (
                  <Fragment>
                    {isAuthenticated
                      ? null
                      : students.isAuthenticated === true
                      ? studentAuthLinksLeft
                      : guestLinksLeft}
                  </Fragment>
                )}
              </MDBNavbarNav>
              <MDBNavbarNav right>
                {!loading && (
                  <Fragment>
                    {isAuthenticated
                      ? authLinksRight
                      : students.isAuthenticated === true
                      ? studentAuthLinksRight
                      : guestLinks}
                  </Fragment>
                )}
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </div>
      {location.pathname.startsWith(
        "/tutors/"
      ) ? null : location.pathname.startsWith(
          "/findtutors"
        ) ? null : isAuthenticated ? (
        <MDBContainer>
          <MDBSideNav
            fixed
            slim
            triggerOpening={formData.sideNavLeft}
            breakWidth={1300}
            className="color-one mr-5"
          >
            <MDBSideNavNav className="mt-5 pt-4">
              <MDBSideNavLink
                to={{
                  pathname: "/lessonplans",
                  state: { lessonTopic: true }
                }}
                topLevel
              >
                <MDBIcon icon="chalkboard" className="mr-2" />
                Lessons
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/lessonplans",
                  state: { lessonTopic: false }
                }}
                topLevel
              >
                <MDBIcon icon="pencil-alt" className="mr-2" />
                Topics
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/classes",
                  state: { activeItemOuterTabs: "1" }
                }}
                topLevel
              >
                <MDBIcon icon="school" className="mr-2" />
                Classes
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/classes",
                  state: { activeItemOuterTabs: "2" }
                }}
                topLevel
              >
                <MDBIcon icon="user-graduate" className="mr-2" />
                Students
              </MDBSideNavLink>

              <MDBSideNavLink to="/payment" topLevel>
                <MDBIcon icon="money-bill" className="mr-2" />
                Payments
              </MDBSideNavLink>
              <MDBSideNavLink to="/profile" topLevel>
                <MDBIcon icon="user" className="mr-2" />
                {" My Profile"}
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/messages"
                }}
                topLevel
              >
                <MDBIcon icon="comments" className="mr-2" />
                Chat
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/cards"
                }}
                topLevel
              >
                <MDBIcon icon="brain" className="mr-2" />
                Flashcards
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/whiteboard"
                }}
                topLevel
              >
                <MDBIcon icon="chalkboard" className="mr-2" />
                Whiteboard
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/customizables"
                }}
                topLevel
              >
                <MDBIcon icon="th" className="mr-2" />
                Exams
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/games"
                }}
                topLevel
              >
                <MDBIcon icon="trophy" className="mr-2" />
                Games
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/trading"
                }}
                topLevel
              >
                <MDBIcon icon="balance-scale" className="mr-2" />
                Trading
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/curriculum"
                }}
                topLevel
              >
                <MDBIcon icon="university" className="mr-2" />
                Curriculum
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/tutors"
                }}
                topLevel
              >
                <MDBIcon icon="address-card" className="mr-2" />
                Public Page
              </MDBSideNavLink>
              <MDBSideNavLink
                to={{
                  pathname: "/documentation"
                }}
                topLevel
              >
                <MDBIcon icon="question-circle" className="mr-2" />
                Documentation
              </MDBSideNavLink>
            </MDBSideNavNav>
          </MDBSideNav>
        </MDBContainer>
      ) : null}
      <MDBContainer>
        <CookieConsent
          style={{
            alignItems: "baseline",
            background: "#fff0ff",
            color: "black",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            left: "0",
            position: "fixed",
            width: "100%",
            zIndex: "999",
            opacity: "0.9"
          }}
          // debug={true}
          location="bottom"
          buttonClasses="landingColorBackground buttonFontColor"
          containerClasses="col-lg-12 alert"
          acceptOnScroll={true}
          acceptOnScrollPercentage={25}
          onAccept={() => console.log("Cookie Confirmation")}
          enableDeclineButton={true}
          onDecline={() => learnMore()}
          declineButtonText={t("cookieButton.1")}
          buttonText={t("cookieButton.2")}
          declineButtonClasses="buttonFontColor2"
        >
          <MDBRow className="middleVertical">
            <MDBCol sm="12" className="col-12">
              <p className="cookieFooterFont">{t("cookieButton.3")}</p>
              <p className="cookieFooterFont2">{t("cookieButton.4")}</p>
            </MDBCol>
          </MDBRow>
        </CookieConsent>
      </MDBContainer>

      {/* Push Notification Announcement Modal  */}

      <MDBContainer>
        <MDBModal
          className="modal-notify modal-info text-white"
          isOpen={formData.announcementModal}
          toggle={announcementModal}
        >
          <MDBModalHeader
            className="text-center h4 colorBackgroundBottom livvicFont"
            titleClass="w-100"
            tag="p"
          >
            {latestNotificationMsg.title}
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <MDBRow center className="mb-3">
              <img
                style={{ width: "12rem", cursor: "pointer" }}
                src={latestNotificationMsg.image}
                alt=""
                onClick={() => {
                  window.open(latestNotificationMsg.image);
                }}
              />
            </MDBRow>

            <p>{latestNotificationMsg.message}</p>
            {youtubeFunc()}
          </MDBModalBody>
          <MDBModalFooter className="justify-content-center">
            <MDBBtn color="primary" onClick={announcementModal}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      {/* <MDBContainer>
        <MDBModal
          className="modal-notify modal-info text-white"
          backdrop={false}
          isOpen={formData.passwordProtect}
          toggle={() => null}
        >
          <MDBModalBody>
            <MDBRow center>
              <MDBCol size="7" className="text-center">
                <p className="text-center my-3 quickSand h5">Testing Mode</p>
                <MDBInput
                  label="Password"
                  type="password"
                  name="password"
                  onChange={onChangePassword}
                />
                <MDBBtn
                  color="primary"
                  size="md"
                  outline
                  onClick={submitPassword}
                >
                  Submit
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer> */}
    </Fragment>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  logoutStudent: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  students: state.students
});

export default withRouter(
  connect(mapStateToProps, {
    logout,
    logoutStudent,
    latestChatMsgFunc,
    latestChatMsgStudentFunc,
    latestPublicpageMsgStudentFunc,
    getMessageList,
    getPublicPage,
    deferredPrompt,
    notificationSeen
  })(Navbar)
);
