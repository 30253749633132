import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Login from "./components/auth/Login";
import Alert from "./components/layout/Alert";
import Dashboard from "./components/dashboard/Dashboard";
import PrivateRoute from "./components/routing/PrivateRoute";
import TradingPrivateRoute from "./components/routing/TradingPrivateRoute";
// import PrivateRouteStep3 from "./components/routing/PrivateRouteStep3";
import PrivateRouteStep1 from "./components/routing/PrivateRouteStep1";
// import PrivateRoute2 from "./components/routing/PrivateRoute2";
// import PrivateRouteProfile from "./components/routing/PrivateRouteProfile";
import PrivateRouteStudent from "./components/routing/PrivateRouteStudent";
import GroupList from "./components/groups/GroupList";
import ShowStudent from "./components/students/ShowStudent";
// import StripePage from "./components/paymentIntent/StripePage";
import Step1 from "./components/paymentIntent/Step1";
// import Step3 from "./components/paymentIntent/Step3";
// import Affiliates from "./components/affiliates/Affiliates";
import Promotion from "./components/promotion/Promotion";
import Blog from "./components/blog/Blog";
import BlogList from "./components/blog/BlogList";

import Profile from "./components/Profile/Profile";
import Activate from "./components/auth/Activate";
import Extend from "./components/auth/Extend";
import Activation from "./components/auth/ActivationExpired";
import ActivationWelcome from "./components/auth/ActivationWelcome";
import PasswordReset from "./components/auth/PasswordReset";
import LessonPlans from "./components/lessonPlans/LessonPlans";
import Games from "./components/games/Games";
import NotFound from "./components/layout/NotFound";
import Offline from "./components/layout/Offline";
import FAQ from "./components/layout/FAQ";
import Terms from "./components/layout/Terms";
// import AffiliateTerms from "./components/affiliates/AffiliateTerms";
import studentTerms from "./components/layout/StudentTerms";
import Privacy from "./components/layout/Privacy";
import Team from "./components/layout/Team";
import Copyright from "./components/layout/Copyright";
import Cookies from "./components/layout/Cookies";
import StudentLogin from "./components/students/Login";
import studentRegister from "./components/students/Register";
import studentDashboard from "./components/students/Dashboard";
import studentLessons from "./components/students/connect/Lessons";
import studentEvaluations from "./components/students/connect/Evaluations";
import studentProfile from "./components/students/connect/Profile";
import studentResetPasswork from "./components/students/connect/ResetPassword";

// Public Page
import PublicPage from "./components/publicPage/PublicPage";
import FindTutors from "./components/publicPage/FindTutors";
import PublicPageURL from "./components/publicPage/PublicPageURL";
import Appointment from "./components/publicPage/Appointment";
import Message from "./components/publicPage/Message";
import FindTutorsTerms from "./components/publicPage/FindTutorsTerms";

// Socket.io messaging
import Chat from "./components/chat/Chat";
import studentChat from "./components/students/connect/studentChat";
import CanvasDash from "./components/canvas/CanvasDash";
import CanvasDashStudent from "./components/students/connect/CanvasDash";

//Flash Cards
import FlashCards from "./components/flashCards/FlashCards";
import FlashCardsStudent from "./components/students/connect/FlashCards";

// Customizables
import Customizables from "./components/customizables/Customizables";
import CustomizablesStudent from "./components/students/connect/Customizables";

// Trading
import Trading from "./components/trading/TradingMain";
import LessonView from "./components/trading/url/Lesson";
import TopicView from "./components/trading/url/Topic";
import ExamView from "./components/trading/url/Exam";
import GameView from "./components/trading/url/Game";
import DeckView from "./components/trading/url/Deck";
import CurriculumView from "./components/trading/url/Curriculum";
import PublicProfile from "./components/trading/url/PublicProfile";

// Curriculum
import Curriculum from "./components/curriculum/Curriculum";

// Payment
import PaymentConnect from "./components/paymentConnect/PaymentConnect";

// Documentation
import Documentation from "./components/documentation/Documentation";

// ScrollToTop
import ScrollToTop from "./ScrollToTop";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import { loadStudentUser } from "./actions/students";

import "./App.css";

// Admin page
import Admin from "./components/admin/Admin";

if (localStorage.topscholarusertoken) {
  setAuthToken(localStorage.topscholarusertoken);
}
if (localStorage.topscholarstudenttoken) {
  setAuthToken(localStorage.topscholarstudenttoken);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  useEffect(() => {
    store.dispatch(loadStudentUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Fragment>
          <Alert />
          <Navbar />
          <section>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/studentterms" component={studentTerms} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/team" component={Team} />
              <Route exact path="/copyright" component={Copyright} />
              <Route exact path="/cookies" component={Cookies} />
              {/* <Route exact path="/affiliates" component={Affiliates} />
              <Route exact path="/affiliateterms" component={AffiliateTerms} /> */}

              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute
                exact
                path="/documentation"
                component={Documentation}
              />

              <PrivateRoute exact path="/classes" component={GroupList} />
              <PrivateRoute
                exact
                path="/students/:id"
                component={ShowStudent}
              />

              {/* Trading */}

              <TradingPrivateRoute
                exact
                path="/trading/lesson/:id"
                component={LessonView}
              />
              <TradingPrivateRoute
                exact
                path="/trading/topic/:id"
                component={TopicView}
              />
              <TradingPrivateRoute
                exact
                path="/trading/exam/:id"
                component={ExamView}
              />
              <TradingPrivateRoute
                exact
                path="/trading/game/:id"
                component={GameView}
              />
              <TradingPrivateRoute
                exact
                path="/trading/deck/:id"
                component={DeckView}
              />
              <TradingPrivateRoute
                exact
                path="/trading/curriculum/:id"
                component={CurriculumView}
              />
              <PrivateRoute
                exact
                path="/trading/profile/:id"
                component={PublicProfile}
              />
              {/* start:  */}

              {/* step 2: subscribe to subscription plan */}
              {/* <PrivateRoute2 exact path="/subscribe" component={StripePage} /> */}
              {/* step 3: payment due */}
              {/* <PrivateRouteStep3 exact path="/paymentdue" component={Step3} /> */}
              {/* step 1 : start free trial */}
              <PrivateRouteStep1 exact path="/step1" component={Step1} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <Route exact path="/activate/:id" component={Activate} />
              <Route exact path="/welcomeback/:id" component={Extend} />
              <Route
                exact
                path="/activatestudent/:id"
                component={studentRegister}
              />

              <Route exact path="/activation/" component={Activation} />
              <Route exact path="/blog/" component={BlogList} />
              <Route exact path="/blog/:id" component={Blog} />
              <Route
                exact
                path="/activationwelcome/"
                component={ActivationWelcome}
              />
              <Route exact path="/freeguide/" component={Promotion} />
              <Route exact path="/reset/:id" component={PasswordReset} />

              <PrivateRoute
                exact
                path="/lessonplans/"
                component={LessonPlans}
              />
              <PrivateRoute exact path="/games/" component={Games} />
              <PrivateRoute exact path="/messages/" component={Chat} />
              <PrivateRoute exact path="/whiteboard/" component={CanvasDash} />
              <PrivateRoute exact path="/cards/" component={FlashCards} />
              <PrivateRoute exact path="/trading/" component={Trading} />
              <PrivateRoute exact path="/tutors/" component={PublicPage} />
              <PrivateRoute exact path="/admin/" component={Admin} />
              <Route exact path="/findtutors" component={FindTutors} />
              <Route
                exact
                path="/findtutorsterms"
                component={FindTutorsTerms}
              />
              <Route exact path="/tutors/:id" component={PublicPageURL} />
              <Route exact path="/appointment/:id" component={Appointment} />
              <Route exact path="/message/:id" component={Message} />
              <PrivateRoute
                exact
                path="/customizables/"
                component={Customizables}
              />
              <PrivateRoute exact path="/curriculum/" component={Curriculum} />
              <PrivateRoute exact path="/payment/" component={PaymentConnect} />
              <Route exact path="/connect/login" component={StudentLogin} />
              <PrivateRouteStudent
                exact
                path="/connect/dashboard"
                component={studentDashboard}
              />
              <PrivateRouteStudent
                exact
                path="/connect/lessons"
                component={studentLessons}
              />
              <PrivateRouteStudent
                exact
                path="/connect/evaluations"
                component={studentEvaluations}
              />
              <PrivateRouteStudent
                exact
                path="/connect/profile"
                component={studentProfile}
              />
              <PrivateRouteStudent
                exact
                path="/connect/messages"
                component={studentChat}
              />
              <PrivateRouteStudent
                exact
                path="/connect/whiteboard"
                component={CanvasDashStudent}
              />
              <PrivateRouteStudent
                exact
                path="/connect/cards"
                component={FlashCardsStudent}
              />
              <PrivateRouteStudent
                exact
                path="/connect/customizables"
                component={CustomizablesStudent}
              />
              <Route
                exact
                path="/connect/reset/:id"
                component={studentResetPasswork}
              />

              <Route component={NotFound} />
              <Route component={Offline} />
            </Switch>
          </section>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
