import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

import {
  ADD_STUDENT,
  STUDENT_ERROR,
  GET_STUDENTS,
  GET_STUDENTS_SELECT,
  GET_STUDENT,
  UPDATE_STUDENT,
  STUDENTS_DELETE,
  GET_STUDENTHOMEWORKEVALUATIONS,
  CLEAR_STUDENT_MESSAGE,
  STUDENT_LOGIN_FAIL,
  STUDENT_LOGIN_SUCCESS,
  STUDENT_LOGOUT,
  LOGOUT,
  STUDENT_USER_LOADED,
  GET_STUDENT_LESSONS,
  STUDENT_RESET_PASSWORD,
  STUDENT_PASSWORD_UPDATE,
  UPDATE_PREFERENCES,
  TOPSCHOLARCONNECT,
  GET_SEEN,
  GET_SEENSTUDENT,
  MAKE_DECK_STUDENT,
  GET_DECKS_STUDENT,
  UPDATE_DECK_STUDENT,
  DELETE_DECK_STUDENT,
  MAKE_CARD_STUDENT,
  UPDATE_DECK_CARDS_STUDENT,
  UPDATE_CARD_STUDENT,
  UPDATE_SPACED_STUDENT,
  GET_CARDS_STUDENT,
  DELETE_CARD_STUDENT,
  GET_SHARED_DECKS_STUDENT,
  GET_CUSEXAM_STUDENT,
  MAKE_CUSEXAM_EVAL,
  GET_CUSEXAM_EVAL,
  UPDATE_CUSEXAM_EVAL,
  CLEAR_STUDENT,
  SEARCH_STUDENTS,
  CLEAR_STUDENTS_SEARCH,
  SET_PAGINATION_MODE_STUDENTS,
  SET_LOADING_BUTTON_STUDENTS,
  SET_LOADING_BUTTON_DELETE_STUDENT,
  STUDENT_AUTH_ERROR,
  TOPSCHOLARCONNECT_GET_TEACHERS,
  ACTIVATE_PORTAL_2,
  LATEST_CHAT_MSG,
  GET_STUDENTS_CHAT,
  LATEST_CHAT_MSG_STUDENT,
  RESET_STUDENT_LOGIN,
  SEARCH_DECKS_STUDENTS,
  SET_PAGINATION_MODE_DECKS_STUDENTS,
  CLEAR_DECKS_SEARCH_STUDENTS,
  SET_LOADING_BUTTON_CARDS_STUDENTS,
  SET_LOADING_BUTTON_DECKS_STUDENTS,
  SEARCH_CARDS_STUDENTS,
  CLEAR_CARDS_SEARCH_STUDENTS,
  SET_PAGINATION_MODE_CARDS_STUDENTS,
  SET_LOADING_DELETE_CARD_STUDENT,
  SET_LOADING_DELETE_DECK_STUDENT
} from "./types";

// Add Student

export const addStudent = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/students", formData, config);

    dispatch({
      type: ADD_STUDENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all students
export const getStudents = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/students/get", formData, config);

    dispatch({
      type: GET_STUDENTS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all students for frontend select
export const getStudentsSelect = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/students/select", formData, config);

    dispatch({
      type: GET_STUDENTS_SELECT,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get all students for chat
export const getStudentsChat = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/students/chat", formData, config);

    dispatch({
      type: GET_STUDENTS_CHAT,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current Student

export const getStudent = id => async dispatch => {
  try {
    const res = await axios.get(`/api/students/${id}`);

    dispatch({
      type: GET_STUDENT,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update student

export const updateStudent = (id, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/students/${id}`, formData, config);

    dispatch({
      type: UPDATE_STUDENT,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete Student

export const deleteStudent = (id, history) => async dispatch => {
  try {
    const res = await axios.delete(`/api/students/${id}`);

    dispatch({
      type: STUDENTS_DELETE,
      payload: id
    });

    if (history) {
      history.push("/classes");
    }
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all student homework evaluations
export const getHomeworkEvaluations = (id, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post(
      `/api/homework/evaluation/${id}`,
      formData,
      config
    );

    dispatch({
      type: GET_STUDENTHOMEWORKEVALUATIONS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear update message
export const clearMessage = id => async dispatch => {
  try {
    dispatch({
      type: CLEAR_STUDENT_MESSAGE,
      payload: ""
    });
  } catch (err) {
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Send Topscholar-Connect activation link

export const activationLink = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/students/connect`, formData, config);

    dispatch({
      type: TOPSCHOLARCONNECT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
  }
};

// Activate Topscholar-Connect portal

export const activateportal = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/activateportal`,
      formData,
      config
    );

    dispatch({
      type: TOPSCHOLARCONNECT,
      payload: res.data
    });

    if (history) {
      history.push(`/connect/login`);
    }

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
  }
};
// Get teacher options

export const getTeacherOptions = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/user/teachers`,
      formData,
      config
    );

    dispatch({
      type: TOPSCHOLARCONNECT_GET_TEACHERS,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
  }
};

// Student Login

export const login = (email, password, teacher, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ email, password, teacher });

  try {
    const res = await axios.post("/api/students/login", body, config);
    dispatch({
      type: STUDENT_LOGIN_SUCCESS,
      payload: res.data
    });
    dispatch({ type: LOGOUT });

    dispatch(loadStudentUser());

    // if (history) {
    //   history.push(`/dashboard`);
    // }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: STUDENT_LOGIN_FAIL
    });
  }
};

// Load User

export const loadStudentUser = () => async dispatch => {
  if (localStorage.topscholarstudenttoken) {
    setAuthToken(localStorage.topscholarstudenttoken);
  }

  try {
    const res = await axios.get("/api/students/user");

    dispatch({
      type: STUDENT_USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: STUDENT_AUTH_ERROR
    });
  }
};

// Get all Student Lessons

export const getStudentLessons = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(`/api/students/lessons/`, formData, config);

    dispatch({
      type: GET_STUDENT_LESSONS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Logout / Clear Profile
export const logoutStudent = history => async dispatch => {
  await dispatch({ type: STUDENT_LOGOUT });
  if (history) {
    history.push(`/connect/login`);
  }
  dispatch(setAlert("You have been successfully logged out", "success"));
};

// Update student

export const updateStudentConnect = (id, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/connect/${id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_STUDENT,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Reset Student password

export const reset = (formData, id, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/students/reset/${id}`, formData, config);

    dispatch({
      type: STUDENT_PASSWORD_UPDATE,
      payload: res.data
    });

    if (history) {
      history.push(`/connect/login`);
    }

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Reset Student password Token

export const resetTokenAction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/students/reset/", formData, config);

    dispatch({
      type: STUDENT_RESET_PASSWORD,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update student

export const studentPreferences = (id, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/preferences/${id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_PREFERENCES,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Disactivate student portal

export const disactivatePortal = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/connect/disactivate/${formData.id}`,
      formData,
      config
    );

    dispatch({
      type: TOPSCHOLARCONNECT,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all students with seen (CHAT TEACHER)
export const getSeen = () => async dispatch => {
  try {
    const res = await axios.get("/api/students/seen");

    dispatch({
      type: GET_SEEN,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get all students with seen (CHAT TEACHER)
export const getSeenStudent = () => async dispatch => {
  try {
    const res = await axios.get("/api/students/seenstudent");

    dispatch({
      type: GET_SEENSTUDENT,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Make a Deck

export const makeDeck = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/students/flashcards/deck",
      formData,
      config
    );

    dispatch({
      type: MAKE_DECK_STUDENT,
      payload: res.data.deck
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all decks
export const getDecks = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post(
      `/api/students/flashcards/decks`,
      formData,
      config
    );

    dispatch({
      type: GET_DECKS_STUDENT,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get all shared decks
export const getSharedDecks = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      `/api/students/flashcards/sharedDecks`,
      formData,
      config
    );

    dispatch({
      type: GET_SHARED_DECKS_STUDENT,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get all cards
export const getCards = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post(
      `/api/students/flashcards/cards/get`,
      formData,
      config
    );

    dispatch({
      type: GET_CARDS_STUDENT,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Cards

export const searchCardsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/students/flashcards/cards/search",
      formData,
      config
    );

    dispatch({
      type: SEARCH_CARDS_STUDENTS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear Card Search Results

export const clearCardsSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_CARDS_SEARCH_STUDENTS
  });
};

// Set Pagination Mode Cards

export const setPaginationModeCards = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_CARDS_STUDENTS,
    payload: formData
  });
};

// Update deck
export const updateDeck = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/flashcards/decks/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_DECK_STUDENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Save Cards to Deck

export const saveCardsToDeck = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/flashcards/decks/cards/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_DECK_CARDS_STUDENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Save Cards to Deck -SPACED REPETITION

export const updateSpaced = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/flashcards/decks/spaced/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_SPACED_STUDENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// delete deck

export const deleteDeck = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/flashcards/decks/delete/${formData.deckId}`,
      formData,
      config
    );

    dispatch({
      type: DELETE_DECK_STUDENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Decks Students

export const searchDecksFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/students/flashcards/decks/search",
      formData,
      config
    );

    dispatch({
      type: SEARCH_DECKS_STUDENTS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Decks Students

export const setPaginationModeDecks = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_DECKS_STUDENTS,
    payload: formData
  });
};

// Set Loading Button Decks

export const setLoadingButtonDecks = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_DECKS_STUDENTS,
    payload: formData
  });
};

// Set Loading Button Cards

export const setLoadingButtonCards = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_CARDS_STUDENTS,
    payload: formData
  });
};

// Clear Deck Search Results Students

export const clearDecksSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_DECKS_SEARCH_STUDENTS
  });
};

// Make a Card

export const makeCard = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/students/flashcards/cards",
      formData,
      config
    );

    dispatch({
      type: MAKE_CARD_STUDENT,
      payload: res.data.card
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update deck
export const updateCard = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/flashcards/cards/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_CARD_STUDENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// delete card

export const deleteCard = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/students/flashcards/cards/delete/${formData.cardId}`,
      formData,
      config
    );

    dispatch({
      type: DELETE_CARD_STUDENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get available customizable exams

export const getCusExams = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(`/api/students/cusexams/`, formData, config);

    dispatch({
      type: GET_CUSEXAM_STUDENT,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create customizable exam evaluation

export const createEval = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(`/api/cusexameval/`, formData, config);

    dispatch({
      type: MAKE_CUSEXAM_EVAL,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// update customizable exam evaluation

export const updateEval = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      `/api/cusexameval/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_CUSEXAM_EVAL,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Create customizable exam evaluation

export const getEvalList = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.get(`/api/cusexameval/`, formData, config);

    dispatch({
      type: GET_CUSEXAM_EVAL,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const clearStudentReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_STUDENT
  });
};

// Post Search Results Students

export const searchStudentsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/students/search", formData, config);

    dispatch({
      type: SEARCH_STUDENTS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Activate student. see if hasStudentProfile is true or false

export const hasStudentProfileFunc = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/students/activateportal2",
      formData,
      config
    );

    dispatch({
      type: ACTIVATE_PORTAL_2,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Students

export const setPaginationModeStudents = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_STUDENTS,
    payload: formData
  });
};

// Set Loading Button Students

export const setLoadingButtonStudents = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_STUDENTS,
    payload: formData
  });
};

// Set Loading Button Delete Student

export const setLoadingButtonDeleteStudent = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_DELETE_STUDENT,
    payload: formData
  });
};

// Rest student login
export const resetStudentLogin = formData => async dispatch => {
  dispatch({
    type: RESET_STUDENT_LOGIN
  });
};

// Clear Student Search Results

export const clearStudentsSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_STUDENTS_SEARCH
  });
};
// update latestChatMsg from chat

export const latestChatMsgFunc = formData => async dispatch => {
  dispatch({
    type: LATEST_CHAT_MSG,
    payload: formData
  });
};

export const latestChatMsgStudentFunc = formData => async dispatch => {
  dispatch({
    type: LATEST_CHAT_MSG_STUDENT,
    payload: formData
  });
};

export const setLoadingDeleteCard = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_DELETE_CARD_STUDENT,
    payload: formData
  });
};
export const setLoadingDeleteDeck = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_DELETE_DECK_STUDENT,
    payload: formData
  });
};
