import React, { Fragment } from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import "./Landing.css";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

const Copyright = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Copyright</title>
        <meta name="description" content="Topscholar's copyright agreement" />
        <meta property="og:title" content="Topscholar: Copyright" />
        <meta
          property="og:description"
          content="Topscholar's copyright agreement"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/copyright" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5 text-center">
        <MDBRow className="pt-5 mt-3 justify-content-center">
          <h3 className="font-weight-bold montserratFont mb-3 cookieMarginsMobile">
            Copyright
          </h3>
        </MDBRow>
        <MDBRow>
          <p className="quickSand copyrightFont cookieMarginsMobile">
            © European Union (2020), © 2020 Copyright holder. All rights
            reserved. No part of the application and/or publication can be
            reproduced, stored in a retrieval system or transmitted in any form
            or by any means, electronic, mechanical or photocopying, recording,
            or otherwise without the prior permission of the publisher.
          </p>
          <p className="quickSand copyrightFont cookieMarginsMobile align-items-start textLeft">
            Topscholar's Name and Logo are trademarked in the European Union
            pending approval.
          </p>
          <p className="quickSand copyrightFont cookieMarginsMobile">
            In the international landscape, software copyright is protected as
            any other copyright work by the Berne Convention. At European Union
            (EU) level, protection is secured by Directive 2009/24/EC (the
            “Directive”).
          </p>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default withRouter(Copyright);
