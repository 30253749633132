import React, { Fragment, useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { extend } from "../../actions/auth";

import {} from "mdbreact";

const Extend = ({ extend, match, history }) => {
  useEffect(() => {
    if (match.params.id) {
      extend(match.params.id, history);
    }
  }, [extend, match, history]);

  return <Fragment></Fragment>;
};

Extend.propTypes = {
  auth: PropTypes.object.isRequired,
  activate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, { extend })(Extend));
