import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { reset } from "../../actions/auth";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SetPassword from "./SetPassword";

import {
  MDBJumbotron,
  MDBBtn,
  MDBInput,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCardTitle
} from "mdbreact";

const PasswordReset = ({
  setAlert,
  reset,
  history,
  match,
  auth: { updatePassword }
}) => {
  const [formData, setFormData] = useState({
    password: "",
    password2: ""
  });

  const { password, password2 } = formData;
  const id = match.params.id;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwords do not match", "error");
    } else {
      reset(formData, id, history);
    }
  };

  if (updatePassword) {
    if (updatePassword.success === true) {
      return <p>{updatePassword.message}</p>;
    } else {
      return (
        <Fragment>
          <MDBContainer className="mt-5 text-center primary">
            <MDBRow center>
              <MDBJumbotron className="mt-5">
                <MDBCardBody>
                  <MDBCardTitle className="h3">
                    {updatePassword.message}
                  </MDBCardTitle>

                  <MDBCol md="12" className="text-center">
                    <SetPassword />
                  </MDBCol>
                </MDBCardBody>
              </MDBJumbotron>
            </MDBRow>
          </MDBContainer>
        </Fragment>
      );
    }
  } else {
    return (
      <Fragment>
        <MDBContainer className="mt-5 text-center primary">
          <MDBRow center>
            <MDBCol md="8">
              <MDBJumbotron className="mt-5">
                <MDBCardBody>
                  <MDBCardTitle className="h2 montserratFont">
                    Reset Password
                  </MDBCardTitle>

                  <form onSubmit={e => onSubmit(e)}>
                    <MDBInput
                      label="New Password"
                      labelClass="quickSand black-text"
                      className="quickSand"
                      type="password"
                      name="password"
                      value={password}
                      onChange={e => onChange(e)}
                    />

                    <MDBInput
                      label="Confirm your new password"
                      labelClass="quickSand black-text"
                      className="quickSand"
                      type="password"
                      name="password2"
                      value={password2}
                      onChange={e => onChange(e)}
                    />

                    <MDBBtn
                      outline
                      color="primary"
                      type="submit"
                      value="Register"
                      className="quickSand"
                    >
                      Update Password
                    </MDBBtn>
                  </form>
                </MDBCardBody>
              </MDBJumbotron>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Fragment>
    );
  }
};

PasswordReset.propTypes = {
  setAlert: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert, reset })(
  withRouter(PasswordReset)
);
