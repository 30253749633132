import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import "./paymentConnect.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import connectStripeImage from "../../images/connectStripe.png";
import queryString from "query-string";
import validator from "validator";
import { DateTimePicker } from "react-widgets";
import currencies from "./currencies.json";
import stripeLanguage from "./stripeLanguage.json";
import { Helmet } from "react-helmet";

import {
  getCredentials,
  getListInvoices,
  getCustomer,
  getListInvoicesIndividual,
  checkout,
  sendInvoice,
  getInvoice,
  getPaymentIntent,
  invoiceVoid,
  invoiceUncollectible,
  getStudents,
  refund,
  createSubscription,
  getSubscription,
  getSubscriptionList,
  searchPaymentsFunction,
  clearPaymentsSearchReducer,
  setPaginationModePayments,
  cancelSubscriptionImmediately,
  cancelSubscriptionEnd,
  finalizeInvoice,
  getTaxRates,
  createTaxRate,
  updateTaxRate,
  getPlans,
  createPlan,
  deletePlan,
  getPlansSelect,
  loadingTrue,
  getTaxRatesSelect,
  addCurrency,
  setLanguage,
  getBalance,
  getBalanceTransactions,
  disconnectStripe,
  setLoadingButtonSendInvoice,
  setLoadingButtonCreateSubscription,
  setLoadingButtonAddTaxrate,
  setLoadingButtonAddPlan,
  setLoadingRefund,
  clearNewInvoiceVar
} from "../../actions/payment";
import { endTrial } from "../../actions/stripe";

import moment from "moment";

import {
  MDBContainer,
  MDBCard,
  MDBInput,
  MDBBtn,
  MDBSelect,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBDropdownItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBSwitch,
  MDBStepper,
  MDBStep,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBModalHeader,
  MDBDataTable
} from "mdbreact";

const PaymentConnect = ({
  auth: { user, loadingUserTutor },
  payment: {
    listCharges,
    listInvoices,
    listCustomers,
    individualCustomer,
    listInvoicesIndividual,
    listChargesIndividual,
    checkoutSession,
    individualInvoice,
    payment_intent,
    students,
    subscriptionList,
    subscription,
    initialPageNumber,
    initialNumberOfPages,
    pageNumber,
    numberOfPages,
    searchPayments,
    paginationModePayments,
    taxRatesList,
    planList,
    planListSelect,
    taxRatesListSelect,
    loading,
    invoicePagination,
    invoicePaginationIndividual,
    subscriptionPaginationIndividual,
    taxesPaginationIndividual,
    plansPaginationIndividual,
    balance,
    balanceTransactions,
    loadingListInvoices,
    loadingGetBalanceTransactions,
    loadingGetStudents,
    loadingGetBalance,
    loadingSendInvoice,
    loadingCreateSubscription,
    loadingAddTaxRate,
    loadingAddPlan,
    loadingRefund,
    newInvoice
  },
  disconnectStripe,
  history,
  match,
  location,
  getCredentials,
  getListCharges,
  getListInvoices,
  getStudents,
  getCustomer,
  getListInvoicesIndividual,
  checkout,
  sendInvoice,
  getInvoice,
  getPaymentIntent,
  invoiceVoid,
  invoiceUncollectible,
  refund,
  createSubscription,
  getSubscription,
  getSubscriptionList,
  searchPaymentsFunction,
  clearPaymentsSearchReducer,
  setPaginationModePayments,
  cancelSubscriptionImmediately,
  cancelSubscriptionEnd,
  finalizeInvoice,
  getTaxRates,
  createTaxRate,
  updateTaxRate,
  getPlans,
  createPlan,
  deletePlan,
  getPlansSelect,
  getTaxRatesSelect,
  loadingTrue,
  addCurrency,
  setLanguage,
  getBalance,
  getBalanceTransactions,
  endTrial,
  setLoadingButtonSendInvoice,
  setLoadingButtonCreateSubscription,
  setLoadingButtonAddTaxrate,
  setLoadingButtonAddPlan,
  setLoadingRefund,
  clearNewInvoiceVar
}) => {
  const [formData, setFormData] = useState({
    mode: "invoices",
    searchCustomer: "",
    arrowInvoice: "next",
    invoiceStatus: "all",
    initialPageInvoice: true,
    arrowCharges: "next",
    arrowTaxesManage: "next",
    arrowPlansManage: "next",
    initialPageCharges: true,
    initialPageTaxesManage: true,
    initialPagePlansManage: true,
    invoiceStatusIndividual: "all",
    arrowInvoicesIndividual: "next",
    initialPageInvoicesIndividual: true,
    arrowSubscriptionsIndividual: "next",
    initialPageSubscriptionsIndividual: true,
    modalInvoice: false,
    modalInvoiceMode: "",
    due: "",
    amount: "",
    description: "",

    switch: false,
    newTaxRate: false,
    newTaxRateObject: {
      displayName: "",
      percentage: "",
      description: "",
      jurisdiction: ""
    },
    editTaxRateObject: {
      displayName: "",

      description: "",
      jurisdiction: ""
    },
    selectTaxRateId: "",
    individualInvoiceMode: false,
    refundAmount: "",
    modalRefund: false,
    refundWhole: false,
    refundPart: false,
    newPlan: false,
    newPlanObject: {
      productName: "",
      interval: "",
      billing_cycle_anchor: moment(new Date())
        .add(1, "days")
        .unix(),
      amount: "",
      due: ""
    },
    editPlanObject: {
      productName: "",
      interval: "",

      amount: ""
    },
    intervalOptions: [
      {
        text: "Day",
        value: "day"
      },
      {
        text: "Week",
        value: "week"
      },
      {
        text: "Month",
        value: "month"
      },
      {
        text: "Year",
        value: "year"
      }
    ],
    pageNumber: 1,
    searchPayments: [],
    modalOpenManage: false,
    modalManageMode: "taxRates",
    taxRateId: "",
    newMakePlan: false,
    showTaxRate: false,
    showPlan: false,
    planId: "",
    planOptions: [
      {
        text: "Load List",
        value: "load"
      }
    ],
    taxRateOptions: [
      {
        text: "Load List",
        value: "load"
      }
    ],
    selectedPlan: "",
    currencyOptions: currencies,

    selectedCurrency: {
      iso: ""
    },
    warnCurrencySelect: false,
    languageOptions: stripeLanguage,
    subImmediate: true,
    seeBalances: false,
    openTrialModal: false,
    data: {
      columns: [
        {
          label: "Amount (Stripe fee)",
          field: "amount",
          sort: "asc",
          width: 150
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 270
        },
        {
          label: "Date Available",
          field: "date",
          sort: "asc",
          width: 200
        }
      ],
      rows: []
    },
    modalDisconnect: false
  });

  // Before

  /* global Stripe */
  const stripe = Stripe("pk_live_cJme1GQD9YHBb2iXteFJ1Kaa00qwryEK2N");

  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      getCredentials(
        {
          code: parsed.code
        },
        history
      );
    }
  }, [location, getCredentials, history]);

  useEffect(() => {
    if (user.stripe_user_id) {
      getListInvoices({
        stripe_user_id: user.stripe_user_id
      });
      getStudents({ initialPageNumber: "first" });
      getBalance({ stripe_account: user.stripe_user_id });
    }
  }, [getListInvoices, user, getStudents, getBalance]);

  const modeFunc = mode => {
    setFormData({
      ...formData,
      mode: mode
    });
  };

  const onChangeSearchCustomer = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const listInvoicesFuncStatus = status => {
    setFormData({
      ...formData,
      invoiceStatus: status
    });
    getListInvoices({ stripe_user_id: user.stripe_user_id, status: status });
  };

  const invoicesStartingAfter = () => {
    if (formData.invoiceStatus === "all") {
      const object = listInvoices.data.slice(-1)[0];

      getListInvoices({
        stripe_user_id: user.stripe_user_id,
        starting_after: object.id
      });
    } else if (formData.invoiceStatus === "open") {
      const object = listInvoices.data.slice(-1)[0];

      getListInvoices({
        stripe_user_id: user.stripe_user_id,
        starting_after: object.id,

        status: formData.invoiceStatus
      });
    } else if (formData.invoiceStatus === "paid") {
      const object = listInvoices.data.slice(-1)[0];

      getListInvoices({
        stripe_user_id: user.stripe_user_id,
        starting_after: object.id,

        status: formData.invoiceStatus
      });
    } else if (formData.invoiceStatus === "uncollectible") {
      const object = listInvoices.data.slice(-1)[0];

      getListInvoices({
        stripe_user_id: user.stripe_user_id,
        starting_after: object.id,

        status: formData.invoiceStatus
      });
    } else if (formData.invoiceStatus === "void") {
      const object = listInvoices.data.slice(-1)[0];

      getListInvoices({
        stripe_user_id: user.stripe_user_id,
        starting_after: object.id,

        status: formData.invoiceStatus
      });
    }
  };

  useEffect(() => {
    const newArray = balanceTransactions.data.map((item, index) => {
      const find = currencies.find(item2 => item2.value.iso === item.currency);

      const currency =
        find.value.zeroDecimal === false ? item.amount / 100 : item.amount;
      return {
        amount: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: item.currency
        }).format(currency),
        status: item.status,
        date: moment.unix(item.available_on).format("MM/DD/YY")
      };
    });

    setFormData(prevState => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          rows: newArray
        }
      };
    });
  }, [balanceTransactions]);

  const invoicesEndingBefore = () => {
    if (formData.invoiceStatus === "all") {
      const object = listInvoices.data.slice(0)[0];

      getListInvoices({
        stripe_user_id: user.stripe_user_id,
        ending_before: object.id
      });
    } else if (formData.invoiceStatus === "open") {
      const object = listInvoices.data.slice(0)[0];

      getListInvoices({
        stripe_user_id: user.stripe_user_id,
        ending_before: object.id,

        status: formData.invoiceStatus
      });
    } else if (formData.invoiceStatus === "paid") {
      const object = listInvoices.data.slice(0)[0];

      getListInvoices({
        stripe_user_id: user.stripe_user_id,
        ending_before: object.id,
        status: formData.invoiceStatus
      });
    } else if (formData.invoiceStatus === "uncollectible") {
      const object = listInvoices.data.slice(0)[0];

      getListInvoices({
        stripe_user_id: user.stripe_user_id,
        ending_before: object.id,
        status: formData.invoiceStatus
      });
    } else if (formData.invoiceStatus === "void") {
      const object = listInvoices.data.slice(0)[0];

      getListInvoices({
        stripe_user_id: user.stripe_user_id,
        ending_before: object.id,
        status: formData.invoiceStatus
      });
    }
  };

  const listInvoicesFuncStatusIndividual = status => {
    setFormData({
      ...formData,
      invoiceStatusIndividual: status,
      initialPageInvoicesIndividual: true
    });
    getListInvoicesIndividual({
      stripe_user_id: user.stripe_user_id,
      status: status,
      customerId: individualCustomer.id
    });
  };

  const invoicesStartingAfterIndividual = () => {
    if (formData.invoiceStatusIndividual === "all") {
      const object = listInvoicesIndividual.data.slice(-1)[0];

      getListInvoicesIndividual({
        stripe_user_id: user.stripe_user_id,
        starting_after: object.id,
        customerId: individualCustomer.id
      });
    } else if (formData.invoiceStatusIndividual === "open") {
      const object = listInvoicesIndividual.data.slice(-1)[0];

      getListInvoicesIndividual({
        stripe_user_id: user.stripe_user_id,
        starting_after: object.id,
        customerId: individualCustomer.id,
        status: formData.invoiceStatusIndividual
      });
    } else if (formData.invoiceStatusIndividual === "paid") {
      const object = listInvoicesIndividual.data.slice(-1)[0];

      getListInvoicesIndividual({
        stripe_user_id: user.stripe_user_id,
        starting_after: object.id,
        customerId: individualCustomer.id,
        status: formData.invoiceStatusIndividual
      });
    } else if (formData.invoiceStatusIndividual === "uncollectible") {
      const object = listInvoicesIndividual.data.slice(-1)[0];

      getListInvoicesIndividual({
        stripe_user_id: user.stripe_user_id,
        starting_after: object.id,
        customerId: individualCustomer.id,
        status: formData.invoiceStatusIndividual
      });
    } else if (formData.invoiceStatusIndividual === "void") {
      const object = listInvoicesIndividual.data.slice(-1)[0];

      getListInvoicesIndividual({
        stripe_user_id: user.stripe_user_id,
        starting_after: object.id,
        customerId: individualCustomer.id,
        status: formData.invoiceStatusIndividual
      });
    }
  };

  const invoicesEndingBeforeInividual = () => {
    if (formData.invoiceStatusIndividual === "all") {
      const object = listInvoicesIndividual.data.slice(0)[0];

      getListInvoicesIndividual({
        customerId: individualCustomer.id,
        stripe_user_id: user.stripe_user_id,
        ending_before: object.id
      });
    } else if (formData.invoiceStatusIndividual === "open") {
      const object = listInvoicesIndividual.data.slice(0)[0];

      getListInvoicesIndividual({
        stripe_user_id: user.stripe_user_id,
        ending_before: object.id,
        customerId: individualCustomer.id,
        status: formData.invoiceStatusIndividual
      });
    } else if (formData.invoiceStatusInidivual === "paid") {
      const object = listInvoicesIndividual.data.slice(0)[0];

      getListInvoicesIndividual({
        stripe_user_id: user.stripe_user_id,
        ending_before: object.id,
        customerId: individualCustomer.id,
        status: formData.invoiceStatusIndividual
      });
    } else if (formData.invoiceStatusIndividual === "uncollectible") {
      const object = listInvoicesIndividual.data.slice(0)[0];

      getListInvoicesIndividual({
        stripe_user_id: user.stripe_user_id,
        ending_before: object.id,
        customerId: individualCustomer.id,
        status: formData.invoiceStatusIndividual
      });
    } else if (formData.invoiceStatusIndividual === "void") {
      const object = listInvoicesIndividual.data.slice(0)[0];

      getListInvoicesIndividual({
        stripe_user_id: user.stripe_user_id,
        ending_before: object.id,
        customerId: individualCustomer.id,
        status: formData.invoiceStatusIndividual
      });
    }
  };

  const taxesManageStartingAfter = () => {
    const object = taxRatesList.data.slice(-1)[0];

    getTaxRates({
      stripe_account: user.stripe_user_id,
      starting_after: object.id
    });
  };
  const plansManageStartingAfter = () => {
    const object = planList.data.slice(-1)[0];

    getPlans({
      stripe_account: user.stripe_user_id,
      starting_after: object.id
    });
  };

  const taxesManageEndingBefore = () => {
    const object = taxRatesList.data.slice(0)[0];

    getTaxRates({
      stripe_account: user.stripe_user_id,
      ending_before: object.id
    });
  };
  const plansManageEndingBefore = () => {
    const object = planList.data.slice(0)[0];

    getPlans({
      stripe_account: user.stripe_user_id,
      ending_before: object.id
    });
  };
  const subscriptionsStartingAfterIndividual = () => {
    const object = subscriptionList.data.slice(-1)[0];

    getSubscriptionList({
      stripe_account: user.stripe_user_id,
      starting_after: object.id,
      customerId: individualCustomer.id
    });
  };
  const subscriptionsEndingBeforeIndividual = () => {
    const object = subscriptionList.data.slice(0)[0];

    getSubscriptionList({
      stripe_account: user.stripe_user_id,
      ending_before: object.id,
      customerId: individualCustomer.id
    });
  };

  const getListInvoicesFunc = invoiceStatus => {
    setFormData({
      ...formData,
      initialPageInvoice: true,
      invoiceStatus: "all"
    });
    getListInvoices({
      stripe_user_id: user.stripe_user_id
    });
  };
  const getListInvoicesFuncIndividual = () => {
    getListInvoicesIndividual({
      stripe_user_id: user.stripe_user_id,
      customerId: individualCustomer.id
    });
    setFormData({
      ...formData,
      initialPageInvoicesIndividual: true,
      invoiceStatusIndividual: "all"
    });
  };

  const showIndividualCustomer = item => {
    getCustomer({
      customerId: item.stripeCustomerId,
      stripe_account: user.stripe_user_id
    });
    getListInvoicesIndividual({
      stripe_user_id: user.stripe_user_id,

      customerId: item.stripeCustomerId
    });

    getSubscriptionList({
      stripe_account: user.stripe_user_id,
      customerId: item.stripeCustomerId
    });

    setFormData({
      ...formData,
      mode: "individualCustomer"
    });
  };

  useEffect(() => {
    if (formData.modalManage === true || formData.modalInvoice === true) {
      document.body.style.cssText = "overflow-y: hidden !important;";
    } else {
      document.body.style.overflowY = "";
    }
  }, [formData.modalManage, formData.modalInvoice]);

  // useEffect(() => {
  //   if (checkoutSession.id) {
  //     stripe
  //       .redirectToCheckout({
  //         sessionId: checkoutSession.id
  //       })
  //       .then(function(result) {
  //       });
  //   }
  // }, [checkoutSession, stripe]);

  const modalOpenInvoice = () => {
    const intervalOptions = formData.intervalOptions.map((item, index) => {
      return {
        ...item,
        checked: false
      };
    });
    const currencyOptions = formData.currencyOptions.map((item, index) => {
      return {
        ...item,
        checked: false
      };
    });

    setFormData({
      ...formData,
      modalInvoice: !formData.modalInvoice,
      newPlan: false,

      modalInvoiceMode: "oneTimePayment",
      subImmediate: true,
      newPlanObject: {
        productName: "",
        interval: "",
        billing_cycle_anchor: moment(new Date())
          .add(1, "days")
          .unix(),
        amount: "",
        due: ""
      },
      intervalOptions: intervalOptions,
      currencyOptions: currencyOptions,
      selectedCurrency: {
        iso: ""
      },
      planOptions: [
        {
          text: "Load List",
          value: "load"
        }
      ],

      amount: "",
      description: "",
      due: "",
      newTaxRateObject: {
        description: "",
        displayName: "",
        jurisdiction: "",
        percentage: ""
      },

      taxRateOptions: [
        {
          text: "Load List",
          value: "load"
        }
      ],
      newTaxRate: false,
      switch: false
    });
  };
  const modalOpenManage = () => {
    const currencyOptions = formData.currencyOptions.map((item, index) => {
      return {
        ...item,
        checked: false
      };
    });
    const intervalOptions = formData.intervalOptions.map((item, index) => {
      return {
        ...item,
        checked: false
      };
    });
    getTaxRates({
      stripe_account: user.stripe_user_id
    });
    getPlans({ stripe_account: user.stripe_user_id });
    setFormData({
      ...formData,
      modalManage: !formData.modalManage,
      newPlanObject: {
        productName: "",
        interval: "",
        billing_cycle_anchor: moment(new Date())
          .add(1, "days")
          .unix(),
        amount: "",
        due: ""
      },
      newPlan: false,
      newMakePlan: false,
      currencyOptions,
      selectedCurrency: {
        iso: ""
      },
      intervalOptions: intervalOptions,
      newTaxRateObject: {
        displayName: "",
        percentage: "",
        description: "",
        jurisdiction: ""
      },
      newTaxRate: false
    });
  };

  const sendInvoiceFunc = () => {
    let amount = roundTo(parseFloat(formData.amount), 2);
    const iso = individualCustomer.currency
      ? individualCustomer.currency
      : formData.selectedCurrency.iso;

    if (!iso) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>Please select a currency</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      const zeroDecimal = currencies.find(item => item.value.iso === iso);

      const currencyObject = {
        iso: iso,
        zeroDecimal: zeroDecimal.value.zeroDecimal
      };

      amount =
        currencyObject.zeroDecimal === false
          ? amount * 100
          : Math.trunc(amount);
      if (formData.switch === true) {
        if (formData.newTaxRate === true) {
          const checkDue = validator.isInt(formData.due);
          const checkAmount = validator.isFloat(formData.amount);
          const checkPercentage = validator.isFloat(
            formData.newTaxRateObject.percentage
          );

          if (checkDue === false) {
            const messageError = () => {
              return (
                <Fragment>
                  <MDBRow>
                    <MDBCol>
                      <MDBIcon icon="exclamation" />
                      <p>
                        Days due field only accepts integers. Example: 5 is
                        accepted 5.2 is not accepted
                      </p>
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              );
            };
            toast.error(messageError);
          } else if (parseInt(formData.due) === 0) {
            const messageError = () => {
              return (
                <Fragment>
                  <MDBRow>
                    <MDBCol>
                      <MDBIcon icon="exclamation" />
                      <p>Days until due must be greater than 0</p>
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              );
            };
            toast.error(messageError);
          } else if (checkAmount === false) {
            const messageError = () => {
              return (
                <Fragment>
                  <MDBRow>
                    <MDBCol>
                      <MDBIcon icon="exclamation" />
                      <p>A number is required in the 'amount' field</p>
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              );
            };
            toast.error(messageError);
          } else if (checkPercentage === false) {
            const messageError = () => {
              return (
                <Fragment>
                  <MDBRow>
                    <MDBCol>
                      <MDBIcon icon="exclamation" />
                      <p>
                        A number is required in the 'percentage' field. Example:
                        14.5
                      </p>
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              );
            };
            toast.error(messageError);
          } else {
            setLoadingButtonSendInvoice(true);
            sendInvoice({
              due: parseInt(formData.due),
              amount: amount,
              customerId: individualCustomer.id,
              description: formData.description,
              selectedCurrency: currencyObject,
              stripe_user_id: user.stripe_user_id,
              taxRate: true,
              newTaxRate: true,
              newTaxRateObject: {
                ...formData.newTaxRateObject,
                percentage: parseFloat(formData.newTaxRateObject.percentage)
              }
            });
          }
        } else {
          const checkDue = validator.isInt(formData.due);
          const checkAmount = validator.isFloat(formData.amount);

          if (checkDue === false) {
            const messageError = () => {
              return (
                <Fragment>
                  <MDBRow>
                    <MDBCol>
                      <MDBIcon icon="exclamation" />
                      <p>
                        Days due field only accepts integers. Example: 5 is
                        accepted 5.2 is not accepted
                      </p>
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              );
            };
            toast.error(messageError);
          } else if (parseInt(formData.due) === 0) {
            const messageError = () => {
              return (
                <Fragment>
                  <MDBRow>
                    <MDBCol>
                      <MDBIcon icon="exclamation" />
                      <p>Days until due must be greater than 0</p>
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              );
            };
            toast.error(messageError);
          } else if (checkAmount === false) {
            const messageError = () => {
              return (
                <Fragment>
                  <MDBRow>
                    <MDBCol>
                      <MDBIcon icon="exclamation" />
                      <p>A number is required in the 'amount' field</p>
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              );
            };
            toast.error(messageError);
          } else if (
            !formData.selectTaxRateId ||
            formData.selectTaxRateId === "load"
          ) {
            const messageError = () => {
              return (
                <Fragment>
                  <MDBRow>
                    <MDBCol>
                      <MDBIcon icon="exclamation" />
                      <p className="black-text">
                        Please select a tax rate or turn off 'Add tax rate'
                      </p>
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              );
            };
            toast.error(messageError);
          } else {
            setLoadingButtonSendInvoice(true);
            sendInvoice({
              due: parseInt(formData.due),
              amount: amount,
              customerId: individualCustomer.id,
              description: formData.description,
              selectedCurrency: currencyObject,
              stripe_user_id: user.stripe_user_id,
              taxRate: true,
              newTaxRate: false,
              taxRateId: formData.selectTaxRateId
            });
          }
        }
      } else {
        const checkDue = validator.isInt(formData.due);
        const checkAmount = validator.isFloat(formData.amount);

        if (checkDue === false) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>
                      Days due field only accepts integers. Example: 5 is
                      accepted 5.2 is not accepted
                    </p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (parseInt(formData.due) === 0) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>Days until due must be greater than 0</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (checkAmount === false) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>A number is required in the 'amount' field</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else {
          setLoadingButtonSendInvoice(true);
          sendInvoice({
            due: parseInt(formData.due),
            amount: amount,
            customerId: individualCustomer.id,
            description: formData.description,
            selectedCurrency: currencyObject,
            stripe_user_id: user.stripe_user_id,
            taxRate: false
          });
        }
      }
    }
  };

  const onChangeHandler = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const onChangeSwitch = () => {
    setFormData({
      ...formData,
      switch: !formData.switch
    });
  };

  const newTaxRateFunc = () => {
    setFormData({
      ...formData,
      newTaxRate: !formData.newTaxRate
    });
  };
  const newMakePlanFunc = () => {
    setFormData({
      ...formData,
      newMakePlan: !formData.newMakePlan
    });
  };
  const newPlanFunc = () => {
    setFormData({
      ...formData,
      newPlan: !formData.newPlan,
      warnCurrencySelect: false
    });
  };

  const onChangeHandlerNewTax = e => {
    setFormData({
      ...formData,
      newTaxRateObject: {
        ...formData.newTaxRateObject,
        [e.target.name]: e.target.value
      }
    });
  };

  const onChangeHandlerEditTax = e => {
    setFormData({
      ...formData,
      editTaxRateObject: {
        ...formData.editTaxRateObject,
        [e.target.name]: e.target.value
      }
    });
  };

  const getInvoiceFunc = (item, type) => {
    getInvoice({
      stripe_account: user.stripe_user_id,
      invoiceId: item.id
    });
    if (type === "customer") {
      setFormData({
        ...formData,
        individualInvoiceMode: false,
        mode: "individualInvoice"
      });
    } else {
      setFormData({
        ...formData,
        individualInvoiceMode: true,
        mode: "individualInvoice"
      });
    }
  };

  useEffect(() => {
    if (individualInvoice.payment_intent)
      getPaymentIntent({
        paymentIntentId: individualInvoice.payment_intent,
        stripe_account: user.stripe_user_id
      });
  }, [individualInvoice, getPaymentIntent, user.stripe_user_id]);

  const paymentMethodStatus1 = () => {
    if (payment_intent.status === "requires_payment_method") {
      return "active";
    } else {
      return "";
    }
  };
  const paymentMethodStatus2 = () => {
    if (
      payment_intent.status === "requires_confirmation" ||
      payment_intent.status === "requires_action"
    ) {
      return "active";
    } else {
      return "";
    }
  };
  const paymentMethodStatus3 = () => {
    if (payment_intent.status === "processing") {
      return "active";
    } else {
      return "";
    }
  };
  const paymentMethodStatus4 = () => {
    if (payment_intent.status === "succeeded") {
      return "active";
    } else {
      return "";
    }
  };

  const modalOpenRefund = item => {
    setFormData({
      ...formData,
      modalRefund: true,
      chargeId: item.id,
      amountLeftRefund: item.amount - item.amount_refunded,
      refundCurrency: item.currency
    });
  };
  const modalCloseRefund = () => {
    setFormData({
      ...formData,
      modalRefund: false
    });
  };

  const refundMode = item => {
    if (item === "whole") {
      setFormData({
        ...formData,
        refundWhole: true,
        refundPart: false
      });
    } else if (item === "part") {
      setFormData({
        ...formData,
        refundWhole: false,
        refundPart: true
      });
    }
  };

  const onChangeHandlerRefund = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const refundFunc = () => {
    if (formData.refundWhole === true) {
      setLoadingRefund(true);
      refund({
        stripe_account: user.stripe_user_id,
        chargeId: formData.chargeId,
        paymentIntentId: payment_intent.id
      });
    } else if (formData.refundPart === true) {
      let amount = roundTo(parseFloat(formData.refundAmount), 2);

      const iso = formData.refundCurrency;
      const zeroDecimal = currencies.find(item => item.value.iso === iso);

      const currencyObject = {
        iso: iso,
        zeroDecimal: zeroDecimal.value.zeroDecimal
      };

      amount =
        currencyObject.zeroDecimal === false
          ? amount * 100
          : Math.trunc(amount);

      const checkAmount = validator.isFloat(formData.refundAmount);
      if (checkAmount === false) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>Amount only accepts numbers. Example: 5.50</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else if (amount > formData.amountLeftRefund) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>
                    The amount you entered is greater than the amount available
                    to refund. You have {formData.amountLeftRefund / 100} left
                    to refund.
                  </p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else {
        setLoadingRefund(true);
        refund({
          stripe_account: user.stripe_user_id,
          chargeId: formData.chargeId,
          amount: amount,
          paymentIntentId: payment_intent.id
        });
      }
    }
  };

  const selectInterval = value => {
    setFormData({
      ...formData,
      newPlanObject: {
        ...formData.newPlanObject,
        interval: value[0]
      }
    });
  };

  const getPickerValueDateStart = value => {
    if (value) {
      const mystring = moment(value).unix();

      setFormData({
        ...formData,

        newPlanObject: {
          ...formData.newPlanObject,
          billing_cycle_anchor: mystring
        }
      });
    }
  };

  const createSubscriptionFunc = () => {
    if (individualCustomer.currency) {
      if (formData.newPlan === true) {
        const checkDue = validator.isInt(formData.newPlanObject.due);
        const checkAmount = validator.isFloat(formData.newPlanObject.amount);

        if (checkDue === false) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>
                      Days due field only accepts integers. Example: 5 is
                      accepted 5.2 is not accepted
                    </p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (parseInt(formData.newPlanObject.due) === 0) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>Days until due must be greater than 0</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (checkAmount === false) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>A number is required in the 'amount' field</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (!formData.newPlanObject.interval) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>An interval is required</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (!formData.newPlanObject.productName) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>A plan name is required</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else {
          let amount = roundTo(parseFloat(formData.newPlanObject.amount), 2);

          const iso = individualCustomer.currency
            ? individualCustomer.currency
            : formData.selectedCurrency.iso;
          const zeroDecimal = currencies.find(item => item.value.iso === iso);

          const currencyObject = {
            iso: iso,
            zeroDecimal: zeroDecimal.value.zeroDecimal
          };
          amount =
            currencyObject.zeroDecimal === false
              ? amount * 100
              : Math.trunc(amount);
          const newPlanObject = {
            ...formData.newPlanObject,
            amount: amount
          };
          setLoadingButtonCreateSubscription(true);
          createSubscription({
            newPlanObject: newPlanObject,
            customerId: individualCustomer.id,
            stripe_account: user.stripe_user_id,
            selectedCurrency: currencyObject,
            subImmediate: formData.subImmediate
          });
        }
      } else if (formData.newPlan === false) {
        const checkDue = validator.isInt(formData.newPlanObject.due);

        if (checkDue === false) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>
                      Days due field only accepts integers. Example: 5 is
                      accepted 5.2 is not accepted
                    </p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (parseInt(formData.newPlanObject.due) === 0) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>Days until due must be greater than 0</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (!formData.selectedPlan) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>Please select a subscription plan 1.</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else {
          setLoadingButtonCreateSubscription(true);
          createSubscription({
            newPlanObject: formData.newPlanObject,

            planId: formData.selectedPlan,
            customerId: individualCustomer.id,
            stripe_account: user.stripe_user_id,
            subImmediate: formData.subImmediate
          });
        }
      }
    } else {
      if (formData.newPlan === true) {
        const checkDue = validator.isInt(formData.newPlanObject.due);
        const checkAmount = validator.isFloat(formData.newPlanObject.amount);

        if (checkDue === false) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>
                      Days due field only accepts integers. Example: 5 is
                      accepted 5.2 is not accepted
                    </p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (parseInt(formData.newPlanObject.due) === 0) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>Days until due must be greater than 0</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (checkAmount === false) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>A number is required in the 'amount' field</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (!formData.newPlanObject.interval) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>An interval is required</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (!formData.newPlanObject.productName) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>A plan name is required</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else {
          setFormData({
            ...formData,
            warnCurrencySelect: true
          });
        }
      } else if (formData.newPlan === false) {
        const checkDue = validator.isInt(formData.newPlanObject.due);

        if (checkDue === false) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>
                      Days due field only accepts integers. Example: 5 is
                      accepted 5.2 is not accepted
                    </p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (parseInt(formData.newPlanObject.due) === 0) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>Days until due must be greater than 0</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else if (!formData.selectedPlan) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>Please select a subscription plan 3.</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else {
          setFormData({
            ...formData,
            warnCurrencySelect: true
          });
        }
      }
    }
  };
  const confirmCreateSubscriptionFunc = () => {
    if (formData.newPlan === true) {
      const checkDue = validator.isInt(formData.newPlanObject.due);
      const checkAmount = validator.isFloat(formData.newPlanObject.amount);

      if (checkDue === false) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>
                    Days due field only accepts integers. Example: 5 is accepted
                    5.2 is not accepted
                  </p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else if (parseInt(formData.newPlanObject.due) === 0) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>Days until due must be greater than 0</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else if (checkAmount === false) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>A number is required in the 'amount' field</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else if (!formData.newPlanObject.interval) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>An interval is required</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else if (!formData.newPlanObject.productName) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>A plan name is required</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else if (!formData.selectedCurrency.iso) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>Please select a currency</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else {
        let amount = roundTo(parseFloat(formData.newPlanObject.amount), 2);

        const iso = individualCustomer.currency
          ? individualCustomer.currency
          : formData.selectedCurrency.iso;
        if (!iso) {
          const messageError = () => {
            return (
              <Fragment>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon icon="exclamation" />
                    <p>Please select a currency</p>
                  </MDBCol>
                </MDBRow>
              </Fragment>
            );
          };
          toast.error(messageError);
        } else {
          const zeroDecimal = currencies.find(item => item.value.iso === iso);

          const currencyObject = {
            iso: iso,
            zeroDecimal: zeroDecimal.value.zeroDecimal
          };

          amount =
            currencyObject.zeroDecimal === false
              ? amount * 100
              : Math.trunc(amount);
          const newPlanObject = {
            ...formData.newPlanObject,
            amount: amount
          };
          createSubscription({
            newPlanObject: newPlanObject,
            customerId: individualCustomer.id,
            stripe_account: user.stripe_user_id,
            selectedCurrency: currencyObject,
            subImmediate: formData.subImmediate
          });
        }
      }
    } else if (formData.newPlan === false) {
      const checkDue = validator.isInt(formData.newPlanObject.due);

      if (checkDue === false) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>
                    Days due field only accepts integers. Example: 5 is accepted
                    5.2 is not accepted
                  </p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else if (parseInt(formData.newPlanObject.due) === 0) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>Days until due must be greater than 0</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else if (!formData.selectedPlan) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>Please select a subscription plan 4.</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else {
        createSubscription({
          newPlanObject: formData.newPlanObject,

          planId: formData.selectedPlan,
          customerId: individualCustomer.id,
          stripe_account: user.stripe_user_id,
          subImmediate: formData.subImmediate
        });
      }
    }
  };

  const onChangePlan = e => {
    setFormData({
      ...formData,
      newPlanObject: {
        ...formData.newPlanObject,
        [e.target.name]: e.target.value
      }
    });
  };

  useEffect(() => {
    if (individualInvoice.subscription) {
      getSubscription({
        subscriptionId: individualInvoice.subscription,
        stripe_account: user.stripe_user_id
      });
    }
  }, [individualInvoice.subscription, getSubscription, user.stripe_user_id]);

  const openTrialModal = () => {
    setFormData({
      ...formData,
      openTrialModal: !formData.openTrialModal
    });
  };

  const showSubscription = item => {
    getSubscription({
      subscriptionId: item.id,
      stripe_account: user.stripe_user_id
    });

    setFormData({
      ...formData,
      mode: "individualSubscription"
    });
  };

  const showSubscriptionInvoice = () => {
    getSubscription({
      subscriptionId: individualInvoice.subscription,
      stripe_account: user.stripe_user_id
    });

    setFormData({
      ...formData,
      mode: "individualSubscription"
    });
  };
  // Search Groups

  useEffect(() => {
    if (searchPayments) {
      setFormData(prevState => {
        return {
          ...prevState,
          searchPayments: searchPayments
        };
      });
    }
  }, [searchPayments]);

  const paymentsSearchFunc = () => {
    searchPaymentsFunction({
      keywords: formData.searchCustomer,
      pageNumber: formData.pageNumber
    });
    setPaginationModePayments("search");
  };

  const clearPaymentsFunc = () => {
    setFormData({
      ...formData,
      searchCustomer: "",
      searchPayments: []
    });
    clearPaymentsSearchReducer();
    setPaginationModePayments("date");
    return students;
  };

  const searchPaymentsMap = () => {
    if (searchPayments === "No Results") {
      return [];
    } else if (searchPayments.length !== 0) {
      return searchPayments;
    } else {
      return students;
    }
  };

  // Pagination Payments

  const isActive1 = page => {
    if (initialPageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const isActive2 = page => {
    if (pageNumber === page) {
      return true;
    } else {
      return false;
    }
  };

  const firstPaginationPayments = () => {
    if (paginationModePayments === "date") {
      getStudents({ initialPageNumber: "first" });
    } else if (paginationModePayments === "search") {
      searchPaymentsFunction({
        pageNumber: "first",
        keywords: formData.searchCustomer
      });
    }
  };

  const previousPaginationPayments = () => {
    if (paginationModePayments === "date") {
      getStudents({ initialPageNumber: initialPageNumber - 1 });
    } else if (paginationModePayments === "search") {
      searchPaymentsFunction({
        pageNumber: pageNumber - 1,
        keywords: formData.searchCustomer
      });
    }
  };

  const currentPaginationPayments = index => {
    if (paginationModePayments === "date") {
      getStudents({ initialPageNumber: index + 1 });
    } else if (paginationModePayments === "search") {
      searchPaymentsFunction({
        pageNumber: index + 1,
        keywords: formData.searchCustomer
      });
    }
  };

  const nextPaginationPayments = () => {
    if (paginationModePayments === "date") {
      getStudents({ initialPageNumber: initialPageNumber + 1 });
    } else if (paginationModePayments === "search") {
      searchPaymentsFunction({
        pageNumber: pageNumber + 1,
        keywords: formData.searchCustomer
      });
    }
  };

  const lastPaginationPayments = () => {
    if (paginationModePayments === "date") {
      getStudents({ initialPageNumber: "last" });
    } else if (paginationModePayments === "search") {
      searchPaymentsFunction({
        pageNumber: "last",
        keywords: formData.searchCustomer
      });
    }
  };

  const createTaxRateFunc = () => {
    const checkPercentage = validator.isFloat(
      formData.newTaxRateObject.percentage
    );

    const percentage = parseFloat(formData.newTaxRateObject.percentage);

    if (!formData.newTaxRateObject.displayName) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>A display name is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (!formData.newTaxRateObject.description) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>A description is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (!formData.newTaxRateObject.jurisdiction) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>A jurisdiction is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (!formData.newTaxRateObject.percentage) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>A tax percentage is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (checkPercentage === false) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>
                  A number is required in the 'percentage' field. Example: 14.5
                </p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (percentage > 99 || percentage < 1) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>Percentage must be between 0 and 100</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      setLoadingButtonAddTaxrate(true);
      createTaxRate({
        newTaxRateObject: formData.newTaxRateObject,
        stripe_account: user.stripe_user_id
      });
    }
  };
  const updateTaxRateFunc = () => {
    if (!formData.editTaxRateObject.displayName) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>A display name is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (!formData.editTaxRateObject.description) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>A description is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (!formData.editTaxRateObject.jurisdiction) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>A jurisdiction is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      updateTaxRate({
        editTaxRateObject: formData.editTaxRateObject,
        stripe_account: user.stripe_user_id,
        taxRateId: formData.taxRateId
      });
    }
  };
  const deletePlanFunc = () => {
    deletePlan({
      stripe_account: user.stripe_user_id,
      planId: formData.planId
    });
  };

  const showTaxRateFunc = item => {
    setFormData({
      ...formData,
      showTaxRate: true,
      editTaxRateObject: {
        displayName: item.display_name,
        percentage: item.percentage,
        description: item.description,
        jurisdiction: item.jurisdiction
      },
      taxRateId: item.id
    });
  };
  const showPlanFunc = item => {
    const find = currencies.find(item2 => item2.value.iso === item.currency);

    const currency =
      find.value.zeroDecimal === false ? item.amount / 100 : item.amount;
    setFormData({
      ...formData,
      showPlan: true,
      editPlanObject: {
        nickname: item.nickname,
        interval: item.interval,

        amount: currency
      },

      planId: item.id
    });
  };

  const createPlanFunc = () => {
    if (!formData.selectedCurrency.iso) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="exclamation" />
                <p>Please select a currency</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      let amount = roundTo(parseFloat(formData.newPlanObject.amount), 2);

      amount =
        formData.selectedCurrency.zeroDecimal === false
          ? amount * 100
          : Math.trunc(amount);
      const checkAmount = validator.isFloat(formData.newPlanObject.amount);

      if (checkAmount === false) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>A number is required in the 'amount' field</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else if (!formData.newPlanObject.interval) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>An interval is required</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else if (!formData.newPlanObject.productName) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="exclamation" />
                  <p>A plan name is required</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else {
        setLoadingButtonAddPlan(true);
        createPlan({
          newPlanObject: { ...formData.newPlanObject, amount: amount },
          stripe_account: user.stripe_user_id,
          selectedCurrency: formData.selectedCurrency
        });
      }
    }
  };

  useEffect(() => {
    if (planListSelect.length !== 0) {
      setFormData(prevState => {
        return {
          ...prevState,
          planOptions: planListSelect
        };
      });
    }
  }, [planListSelect]);
  useEffect(() => {
    if (taxRatesListSelect.length !== 0) {
      setFormData(prevState => {
        return {
          ...prevState,
          taxRateOptions: taxRatesListSelect
        };
      });
    }
  }, [taxRatesListSelect]);

  const selectedPlanFunc = value => {
    setFormData({
      ...formData,
      selectedPlan: value[0]
    });
  };
  const selectTaxRateIdFunc = value => {
    setFormData({
      ...formData,
      selectTaxRateId: value[0]
    });
  };

  useEffect(() => {
    if (formData.selectedPlan === "load") {
      loadingTrue();
      getPlansSelect({
        stripe_account: user.stripe_user_id,
        currency: individualCustomer.currency
      });
    }
  }, [
    formData.selectedPlan,
    getPlansSelect,
    individualCustomer.currency,
    loadingTrue,
    user.stripe_user_id
  ]);

  useEffect(() => {
    if (formData.selectTaxRateId === "load") {
      loadingTrue();
      getTaxRatesSelect({
        stripe_account: user.stripe_user_id
      });
    }
  }, [
    formData.selectTaxRateId,
    getTaxRatesSelect,
    loadingTrue,
    user.stripe_user_id
  ]);

  const selectCurrency = value => {
    setFormData({
      ...formData,
      selectedCurrency: value[0]
    });
  };

  const modalOpenCurrency = () => {
    setFormData({
      ...formData,
      modalCurrency: true
    });
  };
  const modalCloseCurrency = () => {
    setFormData({
      ...formData,
      modalCurrency: false,
      selectedCurrency: { iso: "" }
    });
  };

  const amount_remaining = () => {
    const find = currencies.find(
      item2 => item2.value.iso === individualInvoice.currency
    );
    if (find) {
      const currency =
        find.value.zeroDecimal === false
          ? individualInvoice.amount_remaining / 100
          : individualInvoice.amount_remaining;
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: individualInvoice.currency
      }).format(currency);
    } else {
      return null;
    }
  };
  const individualInvoiceAmountDue = () => {
    const find = currencies.find(
      item2 => item2.value.iso === individualInvoice.currency
    );
    if (find) {
      const currency =
        find.value.zeroDecimal === false
          ? individualInvoice.amount_due / 100
          : individualInvoice.amount_due;
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: individualInvoice.currency
      }).format(currency);
    } else {
      return null;
    }
  };
  const subscriptionPlanAmount = () => {
    const find = currencies.find(
      item2 => item2.value.iso === subscription.plan.currency
    );
    if (find) {
      const currency =
        find.value.zeroDecimal === false
          ? subscription.plan.amount / 100
          : subscription.plan.amount;
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: subscription.plan.currency
      }).format(currency);
    } else {
      return null;
    }
  };
  const individualCustomerBalance = () => {
    const find = currencies.find(
      item2 => item2.value.iso === individualCustomer.currency
    );
    if (find) {
      const currency =
        find.value.zeroDecimal === false
          ? individualCustomer.balance / 100
          : individualCustomer.balance;
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: individualCustomer.currency
      }).format(currency);
    } else {
      return null;
    }
  };

  const getTotalBalances = item => {
    const find = currencies.find(item2 => item2.value.iso === item.currency);
    const currency =
      find.value.zeroDecimal === false ? item.amount / 100 : item.amount;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: item.currency
    }).format(currency);
  };

  const seeBalancesFuncOpen = () => {
    getBalanceTransactions({ stripe_account: user.stripe_user_id });
    setFormData({
      ...formData,
      seeBalances: true
    });
  };
  const seeBalancesFuncClose = () => {
    setFormData({
      ...formData,
      seeBalances: false
    });
  };

  function roundTo(n, digits) {
    var negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(2);
    if (negative) {
      n = (n * -1).toFixed(2);
    }
    return n;
  }

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        initialPageInvoice: invoicePagination.initialPageInvoice,
        arrowInvoice: invoicePagination.arrowInvoice
      };
    });
  }, [invoicePagination]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        initialPageTaxesManage:
          taxesPaginationIndividual.initialPageTaxesManage,
        arrowTaxesManage: taxesPaginationIndividual.arrowTaxesManage
      };
    });
  }, [taxesPaginationIndividual]);
  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        initialPagePlansManage:
          plansPaginationIndividual.initialPagePlansManage,
        arrowPlansManage: plansPaginationIndividual.arrowPlansManage
      };
    });
  }, [plansPaginationIndividual]);

  useEffect(() => {
    setFormData(prevState => {
      const currencyOptions = prevState.currencyOptions.map((item, index) => {
        return {
          ...item,
          checked: false
        };
      });

      return {
        ...prevState,
        modalInvoice: false,
        amount: "",
        description: "",
        due: "",
        newTaxRateObject: {
          description: "",
          displayName: "",
          jurisdiction: "",
          percentage: ""
        },
        selectedCurrency: {
          iso: ""
        },
        currencyOptions: currencyOptions,
        taxRateOptions: [
          {
            text: "Load List",
            value: "load"
          }
        ],
        newTaxRate: false,
        switch: false
      };
    });
  }, [listInvoicesIndividual]);

  useEffect(() => {
    if (individualCustomer.id && newInvoice === true) {
      getCustomer({
        customerId: individualCustomer.id,
        stripe_account: user.stripe_user_id
      });
      getListInvoicesIndividual({
        stripe_user_id: user.stripe_user_id,
        customerId: individualCustomer.id
      });
      clearNewInvoiceVar();
    }
  }, [
    newInvoice,
    getCustomer,
    user.stripe_user_id,
    individualCustomer.id,
    clearNewInvoiceVar,
    getListInvoicesIndividual
  ]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        initialPageInvoicesIndividual:
          invoicePaginationIndividual.initialPageInvoice,
        arrowInvoicesIndividual: invoicePaginationIndividual.arrowInvoice
      };
    });
  }, [invoicePaginationIndividual]);

  useEffect(() => {
    setFormData(prevState => {
      const intervalOptions = prevState.intervalOptions.map((item, index) => {
        return {
          ...item,
          checked: false
        };
      });
      const currencyOptions = prevState.currencyOptions.map((item, index) => {
        return {
          ...item,
          checked: false
        };
      });
      return {
        ...prevState,
        newPlan: false,
        modalInvoice: false,
        modalInvoiceMode: "oneTimePayment",
        subImmediate: true,
        warnCurrencySelect: false,
        newPlanObject: {
          productName: "",
          interval: "",
          billing_cycle_anchor: moment(new Date())
            .add(1, "days")
            .unix(),
          amount: "",
          due: ""
        },
        intervalOptions: intervalOptions,
        currencyOptions: currencyOptions,
        selectedCurrency: {
          iso: ""
        },
        planOptions: [
          {
            text: "Load List",
            value: "load"
          }
        ]
      };
    });
  }, [subscriptionList]);

  // useEffect(() => {
  //   if (individualCustomer.id) {
  //     getCustomer({
  //       customerId: individualCustomer.id,
  //       stripe_account: user.stripe_user_id
  //     });
  //   }
  // }, [
  //   subscriptionList,
  //   getCustomer,
  //   individualCustomer.id,
  //   user.stripe_user_id
  // ]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        initialPageSubscriptionsIndividual:
          subscriptionPaginationIndividual.initialPageSubscriptionsIndividual,
        arrowSubscriptionsIndividual:
          subscriptionPaginationIndividual.arrowSubscriptionsIndividual
      };
    });
  }, [subscriptionPaginationIndividual]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        newTaxRateObject: {
          displayName: "",
          percentage: "",
          description: "",
          jurisdiction: ""
        },
        newTaxRate: false
      };
    });
  }, [taxRatesList]);
  useEffect(() => {
    setFormData(prevState => {
      const currencyOptions = prevState.currencyOptions.map((item, index) => {
        return {
          ...item,
          checked: false
        };
      });
      const intervalOptions = prevState.intervalOptions.map((item, index) => {
        return {
          ...item,
          checked: false
        };
      });
      return {
        ...prevState,
        newPlanObject: {
          productName: "",
          interval: "",
          billing_cycle_anchor: moment(new Date())
            .add(1, "days")
            .unix(),
          amount: "",
          due: ""
        },
        newPlan: false,
        newMakePlan: false,
        currencyOptions,
        selectedCurrency: {
          iso: ""
        },
        intervalOptions: intervalOptions,
        editPlanObject: {
          productName: "",
          interval: "",

          amount: ""
        },
        showPlan: false
      };
    });
  }, [planList]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        modalRefund: false,
        refundAmount: "",
        refundPart: false,
        refundWhole: false
      };
    });
  }, [payment_intent]);

  const modalDisconnect = () => {
    setFormData({
      ...formData,
      modalDisconnect: !formData.modalDisconnect
    });
  };

  return loadingUserTutor ? (
    <Spinner></Spinner>
  ) : // : user.subscribed !== true ? (
  //   <Fragment>
  //     <Helmet>
  //       <meta charSet="utf-8" />
  //       <title>Topscholar: Payment</title>

  //       <meta name="robots" content="noindex" />
  //     </Helmet>
  //     <MDBContainer className="mt-5 mb-5">
  //       <MDBRow className="mt-3" center>
  //         <MDBCol md="12">
  //           <MDBCard style={{ minHeight: "30rem" }} className="mt-5 mb-5 ">
  //             <div className="blue pb-4">
  //               <MDBCol size="12">
  //                 <MDBRow center className="mt-2">
  //                   <p className="quickSand white-text h1-responsive">
  //                     Payments
  //                   </p>
  //                 </MDBRow>
  //                 <MDBRow center className="mt-3">
  //                   <MDBCol className="text-center">
  //                     <p className="white-text quickSand font-weight-bold">
  //                       This section is only available to paying subscribers.
  //                       Those on the free trial do not have access.
  //                     </p>
  //                   </MDBCol>
  //                 </MDBRow>
  //               </MDBCol>
  //             </div>
  //             <MDBCol size="12">
  //               <MDBRow center className="mt-4">
  //                 <p className="quickSand h3-responsive font-weight-bold">
  //                   How it works
  //                 </p>
  //               </MDBRow>
  //               <MDBRow className="mt-2 ">
  //                 <p className="quickSand  h5 mx-3">
  //                   {" "}
  //                   1) Become a paying subscriber. If you are on the free trial
  //                   either wait for your free trial to end or end it immediately{" "}
  //                   <span
  //                     style={{ cursor: "pointer" }}
  //                     className="quickSand h5 red-text font-weight-bold"
  //                     onClick={openTrialModal}
  //                   >
  //                     by clicking here.
  //                   </span>
  //                 </p>
  //               </MDBRow>
  //               <MDBRow className="mt-2">
  //                 <p className="quickSand mx-3 h5">
  //                   {" "}
  //                   2) Connect your Stripe accont with Topscholar. Stripe is
  //                   available if you are resident in{" "}
  //                   <a
  //                     href="https://stripe.com/global"
  //                     rel="noopener noreferrer"
  //                     target="_blank"
  //                   >
  //                     one of these countries.
  //                   </a>{" "}
  //                 </p>
  //               </MDBRow>
  //               <MDBRow className="mt-2">
  //                 <p className="quickSand mx-3 h5">
  //                   {" "}
  //                   3) After connecting your Stripe account with Topscholar you
  //                   can send invoices to students through email. The email will
  //                   have a link to a Stripe powered SCA compliant payment page.
  //                 </p>
  //               </MDBRow>
  //               <MDBRow className="mt-2">
  //                 <p className="quickSand mx-3 h5">
  //                   {" "}
  //                   4) To transfer your earnings log in to Stripe to transfer
  //                   your balance to your chosen bank account.
  //                 </p>
  //               </MDBRow>
  //             </MDBCol>
  //           </MDBCard>
  //         </MDBCol>
  //       </MDBRow>
  //     </MDBContainer>
  //     <MDBContainer>
  //       <MDBModal isOpen={formData.openTrialModal} toggle={openTrialModal}>
  //         <MDBModalHeader className="montserratFont h4 black-text">
  //           Confirm End Trial
  //         </MDBModalHeader>
  //         <MDBModalBody className="quickSand h5 black-text">
  //           If you would like to end your free trial please click on the
  //           'confirm' button below.
  //         </MDBModalBody>
  //         <MDBModalFooter>
  //           <MDBBtn onClick={openTrialModal} color="dark" className="quickSand">
  //             Close
  //           </MDBBtn>
  //           <MDBBtn
  //             onClick={() => endTrial(history)}
  //             color="danger"
  //             className="quickSand"
  //           >
  //             Confirm
  //           </MDBBtn>
  //         </MDBModalFooter>
  //       </MDBModal>
  //     </MDBContainer>
  //   </Fragment>
  // )

  !user.stripe_user_id ? (
    <Fragment>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow className="mt-3" center>
          <MDBCol md="12">
            <MDBCard style={{ minHeight: "30rem" }} className="mt-5 mb-5 ">
              <div className="blue pb-4">
                <MDBCol size="12">
                  <MDBRow center className="mt-2">
                    <p className="quickSand white-text h1-responsive">
                      Payments
                    </p>
                  </MDBRow>
                  <MDBRow center className="mt-3">
                    <MDBCol className="text-center">
                      <a href="https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_G2KhtA4U5zyhK793Msqu73JlpwemrT6L&scope=read_write">
                        <img
                          border="0"
                          alt="W3Schools"
                          src={connectStripeImage}
                        />
                      </a>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </div>
              <MDBCol size="12">
                <MDBRow center className="mt-4">
                  <p className="quickSand h3-responsive font-weight-bold">
                    How it works
                  </p>
                </MDBRow>
                <MDBRow className="mt-2">
                  <p className="quickSand mx-3 h5">
                    {" "}
                    1) Connect your Stripe accont with Topscholar by clicking
                    the connect button above. Stripe is available if you are
                    resident in{" "}
                    <a
                      href="https://stripe.com/global"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      one of these countries.
                    </a>{" "}
                  </p>
                </MDBRow>
                <MDBRow className="mt-2">
                  <p className="quickSand mx-3 h5">
                    {" "}
                    2) After connecting your Stripe account with Topscholar you
                    can send invoices to students through email. The email will
                    have a link to a Stripe powered SCA compliant payment page.
                  </p>
                </MDBRow>
                <MDBRow className="mt-2">
                  <p className="quickSand mx-3 h5">
                    {" "}
                    3) To transfer your earnings log in to Stripe to transfer
                    your balance to your chosen bank account.
                  </p>
                </MDBRow>
              </MDBCol>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  ) : (user.stripe_user_id && loadingListInvoices) ||
    (user.stripe_user_id && loadingGetStudents) ||
    (user.stripe_user_id && loadingGetBalance) ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow className="mt-3" center>
          <MDBCol md="12">
            <MDBCard style={{ minHeight: "30rem" }} className="mt-5 mb-5 ">
              <MDBCol size="12">
                {formData.mode === "individualCustomer" &&
                formData.individualInvoiceMode === false ? (
                  <MDBRow center className="mt-3">
                    <MDBCol>
                      <MDBIcon
                        pull="left"
                        size="2x"
                        icon="arrow-left"
                        style={{ cursor: "pointer" }}
                        className=" black-text pl-3"
                        onClick={() =>
                          setFormData({ ...formData, mode: "customers" })
                        }
                      />

                      <MDBIcon
                        pull="right"
                        size="2x"
                        icon="file-invoice-dollar"
                        style={{ cursor: "pointer" }}
                        className=" black-text pr-3"
                        onClick={modalOpenInvoice}
                      />
                    </MDBCol>
                  </MDBRow>
                ) : null}
                {formData.mode === "individualCustomer" &&
                formData.individualInvoiceMode === true ? (
                  <MDBRow center className="mt-3">
                    <MDBCol>
                      <MDBIcon
                        pull="left"
                        size="2x"
                        icon="arrow-left"
                        style={{ cursor: "pointer" }}
                        className=" black-text pl-3"
                        onClick={() =>
                          setFormData({
                            ...formData,
                            individualInvoiceMode: false
                          })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                ) : null}
                {formData.mode === "individualInvoice" &&
                formData.individualInvoiceMode === true ? (
                  <MDBRow center className="mt-3">
                    <MDBCol>
                      <MDBIcon
                        pull="left"
                        size="2x"
                        icon="arrow-left"
                        style={{ cursor: "pointer" }}
                        className=" black-text pl-3"
                        onClick={() =>
                          setFormData({
                            ...formData,
                            mode: "invoices",
                            individualInvoiceMode: false
                          })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                ) : null}
                {formData.mode === "individualInvoice" &&
                formData.individualInvoiceMode === false ? (
                  <MDBRow center className="mt-3">
                    <MDBCol>
                      <MDBIcon
                        pull="left"
                        size="2x"
                        icon="arrow-left"
                        style={{ cursor: "pointer" }}
                        className=" black-text pl-3"
                        onClick={() =>
                          setFormData({
                            ...formData,
                            mode: "individualCustomer",
                            individualInvoiceMode: false
                          })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                ) : null}
                {formData.mode === "individualSubscription" &&
                formData.individualInvoiceMode === false ? (
                  <MDBRow center className="mt-3">
                    <MDBCol>
                      <MDBIcon
                        pull="left"
                        size="2x"
                        icon="arrow-left"
                        style={{ cursor: "pointer" }}
                        className=" black-text pl-3"
                        onClick={() =>
                          setFormData({
                            ...formData,
                            mode: "individualCustomer"
                          })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                ) : formData.mode === "individualSubscription" &&
                  formData.individualInvoiceMode === true ? (
                  <MDBRow center className="mt-3">
                    <MDBCol>
                      <MDBIcon
                        pull="left"
                        size="2x"
                        icon="arrow-left"
                        style={{ cursor: "pointer" }}
                        className=" black-text pl-3"
                        onClick={() =>
                          setFormData({
                            ...formData,
                            mode: "individualInvoice"
                          })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                ) : null}
                {user.stripe_user_id ? null : (
                  <MDBRow center className="mt-3">
                    <MDBCol className="text-center">
                      <a href="https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_G2KhtA4U5zyhK793Msqu73JlpwemrT6L&scope=read_write">
                        <img
                          border="0"
                          alt="W3Schools"
                          src={connectStripeImage}
                        />
                      </a>
                    </MDBCol>
                  </MDBRow>
                )}

                {formData.mode === "invoices" ||
                formData.mode === "customers" ? (
                  <Fragment>
                    <MDBRow center className="mt-3">
                      <p className="h4 quickSand font-weight-bold">
                        Your Payments
                      </p>
                    </MDBRow>
                    <MDBRow className=" mt-3 ">
                      <MDBCol sm="6" className="col-12">
                        <p
                          className="h5 quickSand font-weight-bold paymentHover"
                          onClick={
                            formData.seeBalances === true
                              ? seeBalancesFuncClose
                              : seeBalancesFuncOpen
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {formData.seeBalances === true
                            ? "Hide Balances"
                            : "Show Balances"}
                          <MDBIcon
                            style={{ cursor: "pointer" }}
                            icon="cash-register"
                            className="ml-3"
                          />
                        </p>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBIcon
                          pull="right"
                          size="2x"
                          icon="cog"
                          style={{ cursor: "pointer" }}
                          className=" black-text mr-3"
                          onClick={modalOpenManage}
                        />
                      </MDBCol>
                    </MDBRow>
                    {(formData.mode === "invoices" &&
                      formData.seeBalances === true) ||
                    (formData.mode === "customers" &&
                      formData.seeBalances === true) ? (
                      <Fragment>
                        <MDBRow>
                          <MDBCol md="4" className="col-12">
                            <MDBRow className=" mx-1 mt-3">
                              <p className="text-primary font-weight-bold quickSand">
                                Available Balances:
                              </p>
                            </MDBRow>

                            {balance.available.map((item, index) => {
                              return (
                                <MDBRow key={index} className="mx-1">
                                  <p className="quickSand font-weight-bold text-black ">
                                    {" "}
                                    Amount:
                                  </p>{" "}
                                  <p className="quickSand text-black  ml-1">
                                    {" "}
                                    {getTotalBalances(item)}
                                  </p>
                                </MDBRow>
                              );
                            })}
                            <MDBRow className=" mx-1 mt-3">
                              <p className="text-primary font-weight-bold quickSand">
                                Pending Balances:
                              </p>
                            </MDBRow>

                            {balance.pending.map((item, index) => {
                              return (
                                <MDBRow key={index} className="mx-1">
                                  <p className="quickSand font-weight-bold text-black ">
                                    {" "}
                                    Amount:
                                  </p>{" "}
                                  <p className="quickSand text-black  ml-1">
                                    {" "}
                                    {getTotalBalances(item)}
                                  </p>
                                </MDBRow>
                              );
                            })}
                          </MDBCol>
                          <MDBCol md="8" className="col-12">
                            <MDBRow className=" mx-1 mt-3">
                              <p className="text-primary font-weight-bold quickSand">
                                100 Most Recent Balance Transactions:
                              </p>
                            </MDBRow>
                            {loadingGetBalanceTransactions ? (
                              <Spinner></Spinner>
                            ) : (
                              <Fragment>
                                <MDBDataTable
                                  searching={false}
                                  sortable={false}
                                  info={false}
                                  small
                                  btn
                                  className="tableLesson text-center quickSand tableFont2"
                                  striped
                                  bordered
                                  data={formData.data}
                                />
                              </Fragment>
                            )}
                          </MDBCol>
                        </MDBRow>
                      </Fragment>
                    ) : null}

                    <MDBRow className="pt-3">
                      <MDBBtn
                        onClick={() => modeFunc("invoices")}
                        className="quickSand"
                        size="md"
                        color={
                          formData.mode === "invoices" ? "elegant" : "blue-grey"
                        }
                      >
                        Invoices
                        <MDBIcon icon="file-invoice-dollar" className="ml-2" />
                      </MDBBtn>

                      <MDBBtn
                        onClick={() => modeFunc("customers")}
                        className="quickSand"
                        size="md"
                        color={
                          formData.mode === "customers"
                            ? "elegant"
                            : "blue-grey"
                        }
                      >
                        Customers
                        <MDBIcon icon="users" className="ml-2" />
                      </MDBBtn>
                    </MDBRow>
                  </Fragment>
                ) : null}
                <hr />

                <div className={formData.mode === "invoices" ? "" : "d-none"}>
                  <MDBRow center className="mb-3">
                    <MDBDropdown size="sm">
                      <MDBDropdownToggle caret color="primary">
                        Filter by status
                      </MDBDropdownToggle>
                      <MDBDropdownMenu basic>
                        <MDBDropdownItem
                          onClick={() => getListInvoicesFunc("all")}
                        >
                          All
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          onClick={() => listInvoicesFuncStatus("open")}
                        >
                          Open
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          onClick={() => listInvoicesFuncStatus("paid")}
                        >
                          Paid
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          onClick={() =>
                            listInvoicesFuncStatus("uncollectible")
                          }
                        >
                          Uncollectible
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          onClick={() => listInvoicesFuncStatus("void")}
                        >
                          Void
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="3">
                      <p className="text-black font-weight-bold quickSand ">
                        Customer Email
                      </p>
                    </MDBCol>
                    <MDBCol size="3">
                      <p className="text-black font-weight-bold quickSand ">
                        Amount
                      </p>
                    </MDBCol>
                    <MDBCol size="3">
                      <p className="text-black font-weight-bold quickSand ">
                        Status
                      </p>
                    </MDBCol>

                    <MDBCol size="3">
                      <p className="text-black font-weight-bold quickSand ">
                        Date created
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <hr />

                  {listInvoices.data.map((item, index) => {
                    const find = currencies.find(
                      item2 => item2.value.iso === item.currency
                    );

                    const currency =
                      find.value.zeroDecimal === false
                        ? item.amount_due / 100
                        : item.amount_due;
                    return (
                      <MDBRow
                        key={item.id}
                        onClick={() => getInvoiceFunc(item, "invoice")}
                        style={{ cursor: "pointer" }}
                      >
                        <MDBCol size="3">
                          <p className="text-black quickSand ">
                            {item.customer_email}
                          </p>
                        </MDBCol>
                        <MDBCol size="3">
                          <p className="text-black quickSand ">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: item.currency
                            }).format(currency)}
                          </p>
                        </MDBCol>
                        <MDBCol size="3">
                          <p className="text-black quickSand ">{item.status}</p>
                        </MDBCol>

                        <MDBCol size="3">
                          <p className="text-black quickSand ">
                            {moment.unix(item.created).format("MM/DD/YY HH:mm")}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}
                  <MDBRow center>
                    <MDBBtn
                      disabled={
                        (listInvoices.has_more === false &&
                          formData.arrowInvoice === "previous") ||
                        formData.initialPageInvoice === true
                          ? true
                          : false
                      }
                      onClick={invoicesEndingBefore}
                      size="sm"
                      color="elegant"
                    >
                      Previous Page
                    </MDBBtn>
                    <MDBBtn
                      disabled={
                        listInvoices.has_more === false &&
                        formData.arrowInvoice === "next"
                          ? true
                          : false
                      }
                      onClick={invoicesStartingAfter}
                      size="sm"
                      color="elegant"
                    >
                      Next Page
                    </MDBBtn>
                  </MDBRow>
                  <MDBRow end className="mt-5 mr-1">
                    <MDBBtn size="sm" onClick={modalDisconnect} color="danger">
                      Disconnect Stripe Account
                    </MDBBtn>
                  </MDBRow>
                </div>
                <div
                  className={formData.mode === "customers" ? "" : "d-none"}
                  style={{ minHeight: "34rem" }}
                >
                  <MDBRow center className="middleVertical5">
                    <MDBCol className="col-2" sm="2"></MDBCol>
                    <MDBCol sm="4" className="col-6">
                      <MDBInput
                        name="searchCustomer"
                        className="black-text quickSand  mt-3"
                        label="Search by email"
                        labelClass="black-text"
                        type="text"
                        containerClass="active-pink active-pink-2 mt-0 mb-3"
                        value={formData.searchCustomer}
                        onChange={onChangeSearchCustomer}
                        onKeyPress={e => {
                          if (e.key === "Enter") {
                            paymentsSearchFunc();
                          }
                        }}
                      />
                    </MDBCol>
                    <MDBCol className="col-2" sm="2">
                      <MDBRow className="align-items-center">
                        <div
                          className={
                            formData.searchCustomer.length !== 0
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="search"
                            onClick={paymentsSearchFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className={
                            searchPayments.length !== 0 ||
                            searchPayments === "No Results"
                              ? "mx-1"
                              : "d-none"
                          }
                        >
                          <MDBIcon
                            icon="times-circle"
                            onClick={clearPaymentsFunc}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="6">
                      <p className="text-black font-weight-bold quickSand ">
                        Customer Email
                      </p>
                    </MDBCol>
                    <MDBCol size="6">
                      <p className="text-black font-weight-bold quickSand ">
                        Student Name
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  {students.length === 0 ? (
                    <p className="h6 quickSand text-black text-center">
                      No results
                    </p>
                  ) : null}
                  <div
                    className={
                      searchPayments === "No Results" ? "mt-2" : "d-none"
                    }
                  >
                    <p className="quickSand h4 black-text text-center">
                      You have no students that match that search
                    </p>
                  </div>
                  {searchPaymentsMap().map((item, index) => {
                    return (
                      <MDBRow
                        key={index}
                        onClick={() => showIndividualCustomer(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <MDBCol size="6">
                          <p className="text-black quickSand ">{item.email}</p>
                        </MDBCol>
                        <MDBCol size="6">
                          <p className="text-black quickSand text-black">
                            {item.name}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}
                </div>

                {/* Pagination Payments */}

                <div
                  className={
                    formData.mode === "customers"
                      ? "middleVertical mt-5"
                      : "d-none"
                  }
                >
                  <MDBRow>
                    <MDBCol>
                      <MDBPagination size="sm" circle>
                        <MDBPageItem onClick={firstPaginationPayments}>
                          <MDBPageNav className="page-link">
                            <span>First</span>
                          </MDBPageNav>
                        </MDBPageItem>
                        <MDBPageItem onClick={previousPaginationPayments}>
                          <MDBPageNav
                            className="page-link"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                          </MDBPageNav>
                        </MDBPageItem>
                        {paginationModePayments === "date"
                          ? [...Array(initialNumberOfPages)].map(
                              (item, index) => {
                                if (index + 1 === initialPageNumber) {
                                  return (
                                    <MDBPageItem
                                      key={index}
                                      active={isActive1(index + 1)}
                                      onClick={() =>
                                        currentPaginationPayments(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber - 1
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationPayments(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber - 2
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationPayments(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber - 3
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationPayments(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber + 1
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationPayments(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber + 2
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationPayments(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else if (
                                  index + 1 ===
                                  initialPageNumber + 3
                                ) {
                                  return (
                                    <MDBPageItem
                                      onClick={() =>
                                        currentPaginationPayments(index)
                                      }
                                    >
                                      <MDBPageNav className="page-link">
                                        {index + 1}{" "}
                                        <span className="sr-only">
                                          (current)
                                        </span>
                                      </MDBPageNav>
                                    </MDBPageItem>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )
                          : [...Array(numberOfPages)].map((item, index) => {
                              if (index + 1 === pageNumber) {
                                return (
                                  <MDBPageItem
                                    active={isActive2(index + 1)}
                                    onClick={() =>
                                      currentPaginationPayments(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber - 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationPayments(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber - 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationPayments(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber - 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationPayments(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber + 1) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationPayments(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber + 2) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationPayments(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else if (index + 1 === pageNumber + 3) {
                                return (
                                  <MDBPageItem
                                    onClick={() =>
                                      currentPaginationPayments(index)
                                    }
                                  >
                                    <MDBPageNav className="page-link">
                                      {index + 1}{" "}
                                      <span className="sr-only">(current)</span>
                                    </MDBPageNav>
                                  </MDBPageItem>
                                );
                              } else {
                                return null;
                              }
                            })}
                        <MDBPageItem onClick={nextPaginationPayments}>
                          <MDBPageNav className="page-link">&raquo;</MDBPageNav>
                        </MDBPageItem>
                        <MDBPageItem onClick={lastPaginationPayments}>
                          <MDBPageNav className="page-link">Last</MDBPageNav>
                        </MDBPageItem>
                      </MDBPagination>
                    </MDBCol>
                  </MDBRow>
                </div>
                {formData.mode === "individualCustomer" &&
                formData.individualInvoiceMode === false ? (
                  <Fragment>
                    {individualCustomer.email === null ? (
                      <MDBRow center className="mt-4 mx-2">
                        <p className="quickSand h5 text-black">
                          This student does not have an email address. In order
                          to use Stripe Payments with this customer please add
                          an email to this student. To add an email go to the
                          class component and edit the student profile by adding
                          an email.
                        </p>
                      </MDBRow>
                    ) : (
                      <Fragment>
                        <MDBRow center className="mb-4">
                          <p className="text-black font-weight-bold quickSand h5">
                            Account Info
                          </p>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="6" className="col-12">
                            <MDBRow className="mx-1">
                              <p className="quickSand font-weight-bold text-black ">
                                {" "}
                                Customer Name:
                              </p>{" "}
                              <p className="quickSand text-black  ml-1">
                                {" "}
                                {individualCustomer.name}
                              </p>
                            </MDBRow>
                          </MDBCol>
                          <MDBCol sm="6" className="col-12">
                            <MDBRow className="mx-1">
                              <p className="quickSand font-weight-bold text-black ">
                                {" "}
                                Email:
                              </p>{" "}
                              <p className="quickSand text-black  ml-1">
                                {" "}
                                {individualCustomer.email}
                              </p>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="6" className="col-12">
                            <MDBRow className="mx-1">
                              <p className="quickSand font-weight-bold text-black ">
                                {" "}
                                Balance:
                              </p>{" "}
                              <p className="quickSand text-black  ml-1">
                                {" "}
                                {individualCustomerBalance()}
                              </p>
                            </MDBRow>
                          </MDBCol>
                          <MDBCol sm="6" className="col-12">
                            <MDBRow className="mx-1">
                              <p className="quickSand font-weight-bold text-black ">
                                {" "}
                                Phone:
                              </p>{" "}
                              <p className="quickSand text-black  ml-1">
                                {" "}
                                {individualCustomer.phone}
                              </p>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol sm="6" className="col-12">
                            {individualCustomer.currency ? (
                              <MDBRow className="mx-1">
                                <p className="quickSand font-weight-bold text-black ">
                                  {" "}
                                  Currency:
                                </p>{" "}
                                <p className="quickSand text-black  ml-1">
                                  {" "}
                                  {individualCustomer.currency}
                                </p>
                              </MDBRow>
                            ) : (
                              <MDBRow className="mx-1">
                                <p className="quickSand font-weight-bold text-black ">
                                  {" "}
                                  Currency:
                                </p>{" "}
                                <p className="quickSand text-black  ml-1">
                                  {" "}
                                  None
                                </p>
                              </MDBRow>
                            )}
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow center>
                          <p className="text-black quickSand h5">Invoices</p>
                        </MDBRow>
                        <MDBRow className="mb-3">
                          <MDBDropdown size="sm">
                            <MDBDropdownToggle caret color="primary">
                              Filter by status
                            </MDBDropdownToggle>
                            <MDBDropdownMenu basic>
                              <MDBDropdownItem
                                onClick={getListInvoicesFuncIndividual}
                              >
                                All
                              </MDBDropdownItem>
                              <MDBDropdownItem
                                onClick={() =>
                                  listInvoicesFuncStatusIndividual("open")
                                }
                              >
                                Open
                              </MDBDropdownItem>
                              <MDBDropdownItem
                                onClick={() =>
                                  listInvoicesFuncStatusIndividual("paid")
                                }
                              >
                                Paid
                              </MDBDropdownItem>
                              <MDBDropdownItem
                                onClick={() =>
                                  listInvoicesFuncStatusIndividual(
                                    "uncollectible"
                                  )
                                }
                              >
                                Uncollectible
                              </MDBDropdownItem>
                              <MDBDropdownItem
                                onClick={() =>
                                  listInvoicesFuncStatusIndividual("void")
                                }
                              >
                                Void
                              </MDBDropdownItem>
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </MDBRow>
                        {listInvoicesIndividual.data.length !== 0 ? (
                          <Fragment>
                            <MDBRow>
                              <MDBCol size="4">
                                <p className="text-black quickSand font-weight-bold">
                                  Amount
                                </p>
                              </MDBCol>
                              <MDBCol size="4">
                                <p className="text-black quickSand font-weight-bold">
                                  Status
                                </p>
                              </MDBCol>

                              <MDBCol size="4">
                                <p className="text-black quickSand font-weight-bold">
                                  Date created
                                </p>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                          </Fragment>
                        ) : (
                          <p className="text-black text-center quickSand">
                            No Invoices
                          </p>
                        )}

                        {listInvoicesIndividual.data.map((item, index) => {
                          const find = currencies.find(
                            item2 => item2.value.iso === item.currency
                          );

                          const currency =
                            find.value.zeroDecimal === false
                              ? item.amount_due / 100
                              : item.amount_due;
                          return (
                            <MDBRow
                              key={item.id}
                              onClick={() => getInvoiceFunc(item, "customer")}
                              style={{ cursor: "pointer" }}
                            >
                              <MDBCol size="4">
                                <p className="text-black quickSand ">
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: item.currency
                                  }).format(currency)}
                                </p>
                              </MDBCol>
                              <MDBCol size="4">
                                <p className="text-black quickSand ">
                                  {item.status}
                                </p>
                              </MDBCol>

                              <MDBCol size="4">
                                <p className="text-black quickSand">
                                  {moment
                                    .unix(item.created)
                                    .format("MM/DD/YY HH:mm")}
                                </p>
                              </MDBCol>
                            </MDBRow>
                          );
                        })}
                        {listInvoicesIndividual.data.length !== 0 ? (
                          <Fragment>
                            <div style={{ minHeight: "25rem" }}>
                              <MDBRow center>
                                <MDBBtn
                                  disabled={
                                    (listInvoicesIndividual.has_more ===
                                      false &&
                                      formData.arrowInvoicesIndividual ===
                                        "previous") ||
                                    formData.initialPageInvoicesIndividual ===
                                      true
                                      ? true
                                      : false
                                  }
                                  onClick={invoicesEndingBeforeInividual}
                                  size="sm"
                                  color="elegant"
                                >
                                  Previous Page
                                </MDBBtn>
                                <MDBBtn
                                  disabled={
                                    listInvoicesIndividual.has_more === false &&
                                    formData.arrowInvoicesIndividual === "next"
                                      ? true
                                      : false
                                  }
                                  onClick={invoicesStartingAfterIndividual}
                                  size="sm"
                                  color="elegant"
                                >
                                  Next Page
                                </MDBBtn>
                              </MDBRow>
                            </div>
                          </Fragment>
                        ) : null}
                        <MDBRow center className="my-4">
                          <p className="text-black quickSand h5">
                            Subscriptions
                          </p>
                        </MDBRow>
                        {subscriptionList.data.length !== 0 ? (
                          <Fragment>
                            <MDBRow>
                              <MDBCol size="4">
                                <p className="text-black quickSand font-weight-bold">
                                  Plan Name
                                </p>
                              </MDBCol>
                              <MDBCol size="4">
                                <p className="text-black quickSand font-weight-bold">
                                  Current period start
                                </p>
                              </MDBCol>

                              <MDBCol size="4">
                                <p className="text-black quickSand font-weight-bold">
                                  Current period end
                                </p>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                          </Fragment>
                        ) : (
                          <p className="quickSand text-black text-center">
                            No Subscriptions
                          </p>
                        )}

                        {subscriptionList.data.map((item, index) => {
                          return (
                            <MDBRow
                              key={item.id}
                              onClick={() => showSubscription(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <MDBCol size="4">
                                <p className="text-black quickSand ">
                                  {item.plan.nickname}
                                </p>
                              </MDBCol>
                              <MDBCol size="4">
                                <p className="text-black quickSand ">
                                  {moment
                                    .unix(item.current_period_start)
                                    .format("MM/DD/YY HH:mm")}
                                </p>
                              </MDBCol>

                              <MDBCol size="4">
                                <p className="text-black quickSand ">
                                  {moment
                                    .unix(item.current_period_end)
                                    .format("MM/DD/YY HH:mm")}
                                </p>
                              </MDBCol>
                            </MDBRow>
                          );
                        })}
                        {subscriptionList.data.length !== 0 ? (
                          <Fragment>
                            <MDBRow center>
                              <MDBBtn
                                disabled={
                                  (subscriptionList.has_more === false &&
                                    formData.arrowSubscriptionsIndividual ===
                                      "previous") ||
                                  formData.initialPageSubscriptionsIndividual ===
                                    true
                                    ? true
                                    : false
                                }
                                onClick={subscriptionsEndingBeforeIndividual}
                                size="sm"
                                color="elegant"
                              >
                                Previous Page
                              </MDBBtn>
                              <MDBBtn
                                disabled={
                                  subscriptionList.has_more === false &&
                                  formData.arrowSubscriptionsIndividual ===
                                    "next"
                                    ? true
                                    : false
                                }
                                onClick={subscriptionsStartingAfterIndividual}
                                size="sm"
                                color="elegant"
                              >
                                Next Page
                              </MDBBtn>
                            </MDBRow>
                          </Fragment>
                        ) : null}
                      </Fragment>
                    )}
                  </Fragment>
                ) : null}
                {formData.mode === "individualInvoice" ? (
                  <Fragment>
                    <MDBRow center>
                      <p className="text-black quickSand h5">
                        Invoice #: {individualInvoice.number}
                      </p>
                    </MDBRow>
                    <MDBRow className="mt-3">
                      <MDBCol sm="6" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Customer Name:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {individualInvoice.customer_name}
                          </p>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol sm="6" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Email:
                          </p>{" "}
                          <p className="quickSand text-black ml-1">
                            {" "}
                            {individualInvoice.customer_email}
                          </p>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="6" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Amount:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {individualInvoiceAmountDue()}
                          </p>
                        </MDBRow>
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Subscription:
                          </p>

                          {individualInvoice.subscription ? (
                            <p
                              className="quickSand  text-black ml-1"
                              style={{ cursor: "pointer" }}
                              onClick={showSubscriptionInvoice}
                            >
                              {individualInvoice.subscription}
                            </p>
                          ) : (
                            <p className="quickSand text-black  ml-1">N/A</p>
                          )}
                        </MDBRow>
                      </MDBCol>
                      <MDBCol sm="6" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Date Created:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {moment
                              .unix(individualInvoice.created)
                              .format("MM/DD/YY HH:mm")}
                          </p>
                        </MDBRow>
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Due Date:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {moment
                              .unix(individualInvoice.due_date)
                              .format("MM/DD/YY HH:mm")}
                          </p>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="6" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            <a
                              className="linkColor"
                              href={individualInvoice.hosted_invoice_url}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Invoice link
                            </a>
                          </p>{" "}
                        </MDBRow>
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            <a
                              className="linkColor"
                              href={individualInvoice.invoice_pdf}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Invoice pdf
                            </a>
                          </p>{" "}
                        </MDBRow>
                      </MDBCol>
                      <MDBCol sm="6" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Status:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {individualInvoice.status}
                          </p>
                        </MDBRow>
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Left to pay:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {amount_remaining()}
                          </p>
                        </MDBRow>

                        <Fragment>
                          {individualInvoice.status === "draft" ? (
                            <MDBBtn
                              onClick={() =>
                                finalizeInvoice({
                                  stripe_account: user.stripe_user_id,
                                  invoiceId: individualInvoice.id
                                })
                              }
                              size="sm"
                            >
                              Finalize Invoice
                            </MDBBtn>
                          ) : (
                            <Fragment>
                              <MDBBtn
                                onClick={() =>
                                  invoiceVoid({
                                    stripe_account: user.stripe_user_id,
                                    invoiceId: individualInvoice.id
                                  })
                                }
                                size="sm"
                                disabled={
                                  individualInvoice.status === "open"
                                    ? false
                                    : true
                                }
                              >
                                Void Invoice
                              </MDBBtn>
                              <MDBBtn
                                onClick={() =>
                                  invoiceUncollectible({
                                    stripe_account: user.stripe_user_id,
                                    invoiceId: individualInvoice.id
                                  })
                                }
                                size="sm"
                                disabled={
                                  individualInvoice.status === "open"
                                    ? false
                                    : true
                                }
                              >
                                Mark Invoice as Uncollectible
                              </MDBBtn>
                            </Fragment>
                          )}
                        </Fragment>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow center>
                      <p className="text-white text-center quickSand h5">
                        Payment Intent
                      </p>
                    </MDBRow>
                    <MDBRow center className="mx-3">
                      <MDBCol md="6">
                        <MDBRow center>
                          <p className="quickSand text-black h5 ">
                            Payment Status
                          </p>
                        </MDBRow>
                        <MDBRow>
                          <MDBStepper vertical>
                            <MDBStep className={paymentMethodStatus1()}>
                              <a style={{ cursor: "default" }} href="#!">
                                <span className="circle">1</span>
                                <span className="label">Payment Method</span>
                              </a>
                            </MDBStep>

                            <MDBStep className={paymentMethodStatus2()}>
                              <a style={{ cursor: "default" }} href="#!">
                                <span className="circle">2</span>
                                <span className="label">Authentication</span>
                              </a>
                            </MDBStep>
                            <MDBStep className={paymentMethodStatus3()}>
                              <a style={{ cursor: "default" }} href="#!">
                                <span className="circle">3</span>
                                <span className="label">Processing</span>
                              </a>
                            </MDBStep>
                            <MDBStep className={paymentMethodStatus4()}>
                              <a style={{ cursor: "default" }} href="#!">
                                <span className="circle">4</span>
                                <span className="label">Succeeded</span>
                              </a>
                            </MDBStep>
                            {payment_intent.status === "canceled" ? (
                              <MDBStep>
                                <p className="quickSand red-text">
                                  <MDBIcon
                                    color="red-text"
                                    icon="times-circle"
                                  />
                                  Canceled
                                </p>
                              </MDBStep>
                            ) : null}
                          </MDBStepper>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol md="6">
                        <MDBRow center>
                          <p className="quickSand text-black h5 ">Charges</p>
                        </MDBRow>
                        {payment_intent.charges.data.length === 0 && (
                          <MDBRow center>
                            <p className="quickSand text-black h6">
                              No Charges
                            </p>
                          </MDBRow>
                        )}
                        {payment_intent.charges.data.map((item, index) => {
                          const find = currencies.find(
                            item2 => item2.value.iso === item.currency
                          );
                          const currency =
                            find.value.zeroDecimal === false
                              ? item.amount / 100
                              : item.amount;
                          const currency2 =
                            find.value.zeroDecimal === false
                              ? item.amount_refunded / 100
                              : item.amount_refunded;
                          return (
                            <Fragment>
                              <MDBRow className="ml-2">
                                <p className="quickSand font-weight-bold text-black ">
                                  {" "}
                                  Amount:
                                </p>{" "}
                                <p className="quickSand text-black  ml-1">
                                  {" "}
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: item.currency
                                  }).format(currency)}
                                </p>
                              </MDBRow>
                              <MDBRow className="ml-2">
                                <p className="quickSand font-weight-bold text-black ">
                                  {" "}
                                  Amount Refunded:
                                </p>{" "}
                                <p className="quickSand text-black  ml-1">
                                  {" "}
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: item.currency
                                  }).format(currency2)}
                                </p>
                              </MDBRow>
                              <MDBRow className="ml-2">
                                <p className="quickSand font-weight-bold text-black ">
                                  {" "}
                                  Outcome:
                                </p>{" "}
                                <p className="quickSand text-black  ml-1">
                                  {" "}
                                  {item.outcome.network_status}
                                </p>
                              </MDBRow>

                              <MDBRow>
                                <MDBBtn
                                  onClick={() => modalOpenRefund(item)}
                                  size="sm"
                                  disabled={
                                    item.refunded === true ? true : false
                                  }
                                >
                                  Refund Charge
                                </MDBBtn>
                              </MDBRow>
                              {item.refunds.data.length === 0 ? (
                                <MDBRow center className="mt-2">
                                  <p className="quickSand text-black h6">
                                    No Refunds
                                  </p>
                                </MDBRow>
                              ) : (
                                <Fragment>
                                  <MDBRow center className="mt-2">
                                    <p className="quickSand text-black h6">
                                      Refunds
                                    </p>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol size="4">
                                      <p className="text-black quickSand font-weight-bold">
                                        Amount
                                      </p>
                                    </MDBCol>
                                    <MDBCol size="4">
                                      <p className="text-black quickSand font-weight-bold">
                                        Date
                                      </p>
                                    </MDBCol>

                                    <MDBCol size="4">
                                      <p className="text-black quickSand font-weight-bold">
                                        Status
                                      </p>
                                    </MDBCol>
                                  </MDBRow>
                                  {item.refunds.data.map((item2, index) => {
                                    const find = currencies.find(
                                      item3 =>
                                        item3.value.iso === item2.currency
                                    );
                                    const currency =
                                      find.value.zeroDecimal === false
                                        ? item2.amount / 100
                                        : item2.amount;
                                    return (
                                      <MDBRow>
                                        <MDBCol size="4">
                                          <p>
                                            {new Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: item.currency
                                            }).format(currency)}
                                          </p>
                                        </MDBCol>
                                        <MDBCol size="4">
                                          <p>
                                            {moment
                                              .unix(item2.created)
                                              .format("MM/DD/YY")}
                                          </p>
                                        </MDBCol>
                                        <MDBCol size="4">
                                          <p>{item2.status}</p>
                                        </MDBCol>
                                      </MDBRow>
                                    );
                                  })}
                                </Fragment>
                              )}
                            </Fragment>
                          );
                        })}
                      </MDBCol>
                    </MDBRow>
                  </Fragment>
                ) : null}
                {formData.mode === "individualSubscription" ? (
                  <Fragment>
                    <MDBRow center className="mx-1">
                      <p className="text-black quickSand h5-responsive">
                        Subscription Id: {subscription.id}
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="6" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Customer Name:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {individualInvoice.customer_name}
                          </p>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol sm="6" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Email:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {individualInvoice.customer_email}
                          </p>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="6" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Start Date:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {moment
                              .unix(subscription.start_date)
                              .format("MM/DD/YY HH:mm")}
                          </p>
                        </MDBRow>
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Status:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {subscription.status}
                          </p>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol sm="6" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Current Period Start:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {moment
                              .unix(subscription.current_period_start)
                              .format("MM/DD/YY HH:mm")}
                          </p>
                        </MDBRow>
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Current Period End:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {moment
                              .unix(subscription.current_period_end)
                              .format("MM/DD/YY HH:mm")}
                          </p>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow center>
                      <MDBBtn
                        onClick={() =>
                          cancelSubscriptionImmediately({
                            stripe_account: user.stripe_user_id,
                            subscriptionId: subscription.id
                          })
                        }
                        size="sm"
                        disabled={
                          subscription.status === "canceled" ? true : false
                        }
                      >
                        Cancel immediately
                      </MDBBtn>
                      <MDBBtn
                        onClick={() =>
                          subscription.cancel_at_period_end === true
                            ? cancelSubscriptionEnd({
                                stripe_account: user.stripe_user_id,
                                subscriptionId: subscription.id
                              })
                            : cancelSubscriptionEnd({
                                stripe_account: user.stripe_user_id,
                                subscriptionId: subscription.id,
                                option: "cancel"
                              })
                        }
                        size="sm"
                        disabled={
                          subscription.status === "canceled" ? true : false
                        }
                      >
                        Cancel at end of period
                      </MDBBtn>
                    </MDBRow>

                    <MDBRow center className="my-4">
                      <p className="text-black font-weight-bold quickSand h5 ">
                        Subscription Plan
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="4" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Name:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {subscription.plan.nickname}
                          </p>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol sm="4" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Amount:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {subscriptionPlanAmount()}
                          </p>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol sm="4" className="col-12">
                        <MDBRow className="mx-1">
                          <p className="quickSand font-weight-bold text-black ">
                            {" "}
                            Interval:
                          </p>{" "}
                          <p className="quickSand text-black  ml-1">
                            {" "}
                            {subscription.plan.interval}
                          </p>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </Fragment>
                ) : null}
              </MDBCol>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal
            size="lg"
            isOpen={formData.modalInvoice}
            toggle={() => null}
          >
            <MDBModalBody style={{ minHeight: "30rem" }}>
              <MDBRow center>
                <MDBBtn
                  onClick={() =>
                    setFormData({
                      ...formData,
                      modalInvoiceMode: "oneTimePayment"
                    })
                  }
                  size="sm"
                  color={
                    formData.modalInvoiceMode === "oneTimePayment"
                      ? "elegant"
                      : "blue-grey"
                  }
                >
                  One-time payment
                </MDBBtn>

                <MDBBtn
                  onClick={() =>
                    setFormData({
                      ...formData,
                      modalInvoiceMode: "subscription"
                    })
                  }
                  size="sm"
                  color={
                    formData.modalInvoiceMode === "subscription"
                      ? "elegant"
                      : "blue-grey"
                  }
                >
                  New Subscription
                </MDBBtn>
              </MDBRow>
              <hr />
              {formData.modalInvoiceMode === "oneTimePayment" ? (
                <Fragment>
                  <MDBRow center>
                    <MDBCol md="6" sm="10">
                      <MDBInput
                        name="amount"
                        className="quickSand black-text font-weight-bold   mt-3"
                        labelClass="black-text font-weight-bold quickSand"
                        label="Amount"
                        onChange={onChangeHandler}
                        value={formData.amount}
                        size="sm"
                        type="number"
                        step="0.01"
                        outline
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow center>
                    <MDBCol md="6" sm="10">
                      <MDBInput
                        name="description"
                        className="quickSand black-text font-weight-bold   mt-3"
                        labelClass="black-text font-weight-bold quickSand"
                        label="Description"
                        onChange={onChangeHandler}
                        value={formData.description}
                        size="sm"
                        type="text"
                        outline
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow center>
                    <MDBCol md="6" sm="10">
                      <MDBInput
                        name="due"
                        className="quickSand black-text font-weight-bold   mt-3"
                        labelClass="black-text font-weight-bold quickSand"
                        label="Days until due"
                        onChange={onChangeHandler}
                        value={formData.due}
                        outline
                        size="sm"
                        type="number"
                      />
                    </MDBCol>
                  </MDBRow>
                  {formData.selectedCurrency.iso ? (
                    <MDBRow center>
                      <p>Selected currency:{formData.selectedCurrency.iso} </p>
                    </MDBRow>
                  ) : individualCustomer.currency === null ? (
                    <MDBRow center>
                      <p className="quickSand black-text text-center">
                        No customer currency:{" "}
                        <MDBBtn
                          onClick={modalOpenCurrency}
                          size="sm"
                          color="warning"
                        >
                          Add a currency
                        </MDBBtn>
                      </p>
                    </MDBRow>
                  ) : (
                    <MDBRow center>
                      <p className="quickSand text-black font-weight-bold">
                        <MDBIcon icon="money-bill" />{" "}
                        {`Customer currency:  ${individualCustomer.currency}`}
                      </p>
                    </MDBRow>
                  )}

                  <MDBRow center>
                    <p className="black-text quickSand">Add tax rate</p>
                  </MDBRow>
                  <MDBRow center>
                    <MDBSwitch
                      checked={formData.switch}
                      onChange={onChangeSwitch}
                    />
                  </MDBRow>

                  {formData.switch === true ? (
                    <Fragment>
                      {formData.newTaxRate === true ? null : (
                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <div className="dropDownStudents">
                              <MDBSelect
                                className="quickSand black-text font-weight-bold   mt-3 "
                                options={formData.taxRateOptions}
                                labelClass="black-text font-weight-bold quickSand"
                                label="Select Tax Rate "
                                outline
                                size="sm"
                                getValue={selectTaxRateIdFunc}
                              />
                              {loading === true ? <Spinner></Spinner> : null}
                            </div>
                          </MDBCol>
                        </MDBRow>
                      )}

                      <MDBRow center>
                        <MDBBtn flat onClick={newTaxRateFunc} size="sm">
                          {formData.newTaxRate === true
                            ? "Existing tax rate"
                            : "New tax rate"}
                        </MDBBtn>
                      </MDBRow>
                      {formData.newTaxRate === true ? (
                        <Fragment>
                          <MDBRow center>
                            <MDBCol md="6" sm="10">
                              <MDBInput
                                name="displayName"
                                className="quickSand black-text font-weight-bold   mt-3"
                                labelClass="black-text font-weight-bold quickSand"
                                label="Display Name"
                                onChange={onChangeHandlerNewTax}
                                value={formData.newTaxRateObject.displayName}
                                size="sm"
                                type="text"
                                outline
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow center>
                            <MDBCol md="6" sm="10">
                              <MDBInput
                                name="description"
                                className="quickSand black-text font-weight-bold   mt-3"
                                labelClass="black-text font-weight-bold quickSand"
                                label="Description"
                                onChange={onChangeHandlerNewTax}
                                value={formData.newTaxRateObject.description}
                                size="sm"
                                type="text"
                                outline
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow center>
                            <MDBCol md="6" sm="10">
                              <MDBInput
                                name="jurisdiction"
                                className="quickSand black-text font-weight-bold   mt-3"
                                labelClass="black-text font-weight-bold quickSand"
                                label="Jurisdiction"
                                onChange={onChangeHandlerNewTax}
                                value={formData.newTaxRateObject.jurisdiction}
                                size="sm"
                                type="text"
                                outline
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow center>
                            <MDBCol md="6" sm="10">
                              <MDBInput
                                name="percentage"
                                className="quickSand black-text font-weight-bold   mt-3"
                                labelClass="black-text font-weight-bold quickSand"
                                label="Tax percentage (e.g. 21.5)"
                                onChange={onChangeHandlerNewTax}
                                value={formData.newTaxRateObject.percentage}
                                size="sm"
                                type="number"
                                outline
                              />
                            </MDBCol>
                          </MDBRow>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ) : null}
                  <hr />
                  <MDBRow center>
                    <MDBBtn
                      onClick={sendInvoiceFunc}
                      size="sm"
                      color="success"
                      disabled={loadingSendInvoice}
                      className="quickSand"
                    >
                      {loadingSendInvoice === true && (
                        <div className="spinner-grow spinner-grow-sm" />
                      )}
                      Send Invoice
                    </MDBBtn>
                  </MDBRow>
                </Fragment>
              ) : null}
              {formData.modalInvoiceMode === "subscription" ? (
                <Fragment>
                  <MDBRow center>
                    <MDBCol md="6" sm="10">
                      <MDBInput
                        name="due"
                        className="quickSand black-text font-weight-bold   mt-3"
                        labelClass="black-text font-weight-bold quickSand"
                        label="Days until due"
                        onChange={onChangePlan}
                        value={formData.newPlanObject.due}
                        outline
                        size="sm"
                        type="number"
                      />
                    </MDBCol>
                  </MDBRow>

                  <Fragment>
                    {formData.newPlan === true ? null : (
                      <MDBRow center>
                        <MDBCol md="6" sm="10">
                          <div className="dropDownStudents quickSand">
                            <MDBSelect
                              search
                              className="quickSand black-text font-weight-bold mt-3"
                              options={formData.planOptions}
                              labelClass="black-text font-weight-bold quickSand"
                              label="Select Subscription Plan"
                              outline
                              size="sm"
                              getValue={selectedPlanFunc}
                            />
                          </div>
                          {loading === true && <Spinner></Spinner>}
                        </MDBCol>
                      </MDBRow>
                    )}
                    <MDBRow center>
                      <MDBBtn flat onClick={newPlanFunc} size="sm">
                        {formData.newPlan === true
                          ? "Existing subscription plan"
                          : "New subscription plan"}
                      </MDBBtn>
                    </MDBRow>

                    {formData.newPlan === true ? (
                      <Fragment>
                        <MDBRow center>
                          <p className="text-white quickSand mt-3">
                            Create a new plan
                          </p>
                        </MDBRow>
                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBInput
                              name="amount"
                              className="quickSand black-text font-weight-bold   mt-3"
                              labelClass="black-text font-weight-bold quickSand"
                              label="Amount"
                              onChange={onChangePlan}
                              value={formData.newPlanObject.amount}
                              outline
                              size="sm"
                              type="number"
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBSelect
                              className="quickSand black-text font-weight-bold   mt-3"
                              options={formData.intervalOptions}
                              labelClass="black-text font-weight-bold quickSand"
                              label="Interval"
                              outline
                              size="sm"
                              getValue={selectInterval}
                            />
                          </MDBCol>
                        </MDBRow>
                        {formData.selectedCurrency.iso ? (
                          <MDBRow center>
                            <p>
                              Selected currency:
                              {formData.selectedCurrency.iso}{" "}
                            </p>
                          </MDBRow>
                        ) : individualCustomer.currency === null ? (
                          <MDBRow center>
                            <p className="quickSand black-text text-center">
                              No customer currency:{" "}
                              <MDBBtn
                                onClick={modalOpenCurrency}
                                size="sm"
                                color="warning"
                              >
                                Add a currency
                              </MDBBtn>
                            </p>
                          </MDBRow>
                        ) : (
                          <MDBRow center>
                            <p className="quickSand text-black font-weight-bold">
                              <MDBIcon icon="money-bill" />{" "}
                              {`Customer currency:  ${individualCustomer.currency}`}
                            </p>
                          </MDBRow>
                        )}

                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBInput
                              name="productName"
                              className="quickSand black-text font-weight-bold   mt-3"
                              labelClass="black-text font-weight-bold quickSand"
                              label="Plan Name"
                              onChange={onChangePlan}
                              value={formData.newPlanObject.productName}
                              size="sm"
                              type="text"
                              outline
                            />
                          </MDBCol>
                        </MDBRow>
                      </Fragment>
                    ) : null}
                  </Fragment>

                  <hr />
                  {formData.warnCurrencySelect === true ? (
                    <Fragment>
                      <MDBRow center>
                        <MDBBtn
                          onClick={confirmCreateSubscriptionFunc}
                          size="sm"
                          color="danger"
                        >
                          Confirm Create
                        </MDBBtn>

                        <MDBBtn
                          onClick={() => {
                            setFormData({
                              ...formData,
                              warnCurrencySelect: false
                            });
                          }}
                          size="sm"
                          color="warning"
                        >
                          undo
                        </MDBBtn>
                      </MDBRow>
                    </Fragment>
                  ) : (
                    <MDBRow center>
                      <MDBBtn
                        onClick={createSubscriptionFunc}
                        size="sm"
                        color="success"
                        disabled={loadingCreateSubscription}
                        className="quickSand"
                      >
                        {loadingCreateSubscription === true && (
                          <div className="spinner-grow spinner-grow-sm" />
                        )}
                        Create subscription
                      </MDBBtn>
                    </MDBRow>
                  )}
                </Fragment>
              ) : null}
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                className="quickSand"
                onClick={modalOpenInvoice}
                size="sm"
                color="blue"
              >
                Cancel
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
      <div className="modalClearBorder ">
        <MDBContainer>
          <MDBModal size="lg" isOpen={formData.modalManage} toggle={() => null}>
            <MDBModalBody style={{ minHeight: "30rem" }}>
              <MDBRow center>
                <MDBBtn
                  onClick={() =>
                    setFormData({
                      ...formData,
                      modalManageMode: "taxRates"
                    })
                  }
                  size="sm"
                  color={
                    formData.modalManageMode === "taxRates"
                      ? "elegant"
                      : "blue-grey"
                  }
                >
                  Tax Rates
                </MDBBtn>

                <MDBBtn
                  onClick={() =>
                    setFormData({
                      ...formData,
                      modalManageMode: "plans"
                    })
                  }
                  size="sm"
                  color={
                    formData.modalManageMode === "plans"
                      ? "elegant"
                      : "blue-grey"
                  }
                >
                  Plans
                </MDBBtn>
              </MDBRow>
              {formData.modalManageMode === "taxRates" ? (
                <Fragment>
                  <MDBRow className="mt-4">
                    <MDBCol size="4">
                      <p className="text-black font-weight-bold quickSand ">
                        Display Name
                      </p>
                    </MDBCol>
                    <MDBCol size="4">
                      <p className="text-black font-weight-bold quickSand ">
                        Jurisdiction
                      </p>
                    </MDBCol>

                    <MDBCol size="4">
                      <p className="text-black font-weight-bold quickSand ">
                        Tax Rate
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  {taxRatesList.data.map((item, index) => {
                    return (
                      <MDBRow
                        key={item.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => showTaxRateFunc(item)}
                      >
                        <MDBCol size="4">
                          <p className="text-black quickSand ">
                            {item.display_name}
                          </p>
                        </MDBCol>
                        <MDBCol size="4">
                          <p className="text-black quickSand ">
                            {item.jurisdiction}
                          </p>
                        </MDBCol>

                        <MDBCol size="4">
                          <p className="text-black quickSand ">
                            {item.percentage}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}

                  <MDBRow center>
                    <MDBBtn
                      disabled={
                        (taxRatesList.has_more === false &&
                          formData.arrowTaxesManage === "previous") ||
                        formData.initialPageTaxesManage === true
                          ? true
                          : false
                      }
                      onClick={taxesManageEndingBefore}
                      size="sm"
                    >
                      Previous Page
                    </MDBBtn>
                    <MDBBtn
                      disabled={
                        taxRatesList.has_more === false &&
                        formData.arrowTaxesManage === "next"
                          ? true
                          : false
                      }
                      onClick={taxesManageStartingAfter}
                      size="sm"
                    >
                      Next Page
                    </MDBBtn>
                  </MDBRow>

                  {formData.showTaxRate === true ? (
                    <Fragment>
                      <MDBRow center>
                        <MDBCol md="6" sm="10">
                          <MDBInput
                            name="displayName"
                            className="quickSand black-text font-weight-bold   mt-3"
                            labelClass="black-text font-weight-bold quickSand"
                            label="Display Name"
                            onChange={onChangeHandlerEditTax}
                            value={formData.editTaxRateObject.displayName}
                            size="sm"
                            type="text"
                            outline
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow center>
                        <MDBCol md="6" sm="10">
                          <MDBInput
                            name="description"
                            className="quickSand black-text font-weight-bold   mt-3"
                            labelClass="black-text font-weight-bold quickSand"
                            label="Description"
                            onChange={onChangeHandlerEditTax}
                            value={formData.editTaxRateObject.description}
                            size="sm"
                            type="text"
                            outline
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow center>
                        <MDBCol md="6" sm="10">
                          <MDBInput
                            name="jurisdiction"
                            className="quickSand black-text font-weight-bold   mt-3"
                            labelClass="black-text font-weight-bold quickSand"
                            label="Jurisdiction"
                            onChange={onChangeHandlerEditTax}
                            value={formData.editTaxRateObject.jurisdiction}
                            size="sm"
                            type="text"
                            outline
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow center>
                        <MDBBtn onClick={updateTaxRateFunc} size="sm">
                          Update tax rate
                        </MDBBtn>
                      </MDBRow>
                    </Fragment>
                  ) : null}

                  <Fragment>
                    <MDBRow center>
                      <MDBBtn flat onClick={newTaxRateFunc}>
                        {formData.newTaxRate === true ? "Hide" : "New tax rate"}
                      </MDBBtn>
                    </MDBRow>
                    {formData.newTaxRate === true ? (
                      <Fragment>
                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBInput
                              name="displayName"
                              className="quickSand black-text font-weight-bold   mt-3"
                              labelClass="black-text font-weight-bold quickSand"
                              label="Display Name"
                              onChange={onChangeHandlerNewTax}
                              value={formData.newTaxRateObject.displayName}
                              size="sm"
                              type="text"
                              outline
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBInput
                              name="description"
                              className="quickSand black-text font-weight-bold   mt-3"
                              labelClass="black-text font-weight-bold quickSand"
                              label="Description"
                              onChange={onChangeHandlerNewTax}
                              value={formData.newTaxRateObject.description}
                              size="sm"
                              type="text"
                              outline
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBInput
                              name="jurisdiction"
                              className="quickSand black-text font-weight-bold   mt-3"
                              labelClass="black-text font-weight-bold quickSand"
                              label="Jurisdiction"
                              onChange={onChangeHandlerNewTax}
                              value={formData.newTaxRateObject.jurisdiction}
                              size="sm"
                              type="text"
                              outline
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBInput
                              name="percentage"
                              className="quickSand black-text font-weight-bold   mt-3"
                              labelClass="black-text font-weight-bold quickSand"
                              label="Tax percentage (e.g. 21.5)"
                              onChange={onChangeHandlerNewTax}
                              value={formData.newTaxRateObject.percentage}
                              size="sm"
                              type="number"
                              outline
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow center>
                          <MDBBtn
                            onClick={createTaxRateFunc}
                            size="sm"
                            disabled={loadingAddTaxRate}
                          >
                            {loadingAddTaxRate === true && (
                              <div className="spinner-grow spinner-grow-sm" />
                            )}
                            Create new tax rate
                          </MDBBtn>
                        </MDBRow>
                      </Fragment>
                    ) : null}
                  </Fragment>
                </Fragment>
              ) : null}
              {formData.modalManageMode === "plans" ? (
                <Fragment>
                  <MDBRow className="mt-4">
                    <MDBCol size="4">
                      <p className="text-black font-weight-bold quickSand ">
                        Name
                      </p>
                    </MDBCol>
                    <MDBCol size="4">
                      <p className="text-black font-weight-bold quickSand ">
                        Amount
                      </p>
                    </MDBCol>

                    <MDBCol size="2">
                      <p className="text-black font-weight-bold quickSand ">
                        Interval
                      </p>
                    </MDBCol>
                    <MDBCol size="2">
                      <p className="text-black font-weight-bold quickSand ">
                        Currency
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  {planList.data.map((item, index) => {
                    const find = currencies.find(
                      item2 => item2.value.iso === item.currency
                    );
                    const currency =
                      find.value.zeroDecimal === false
                        ? item.amount / 100
                        : item.amount;
                    return (
                      <MDBRow
                        key={item.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => showPlanFunc(item)}
                      >
                        <MDBCol size="4">
                          <p className="text-black quickSand ">
                            {item.nickname}
                          </p>
                        </MDBCol>
                        <MDBCol size="4">
                          <p className="text-black quickSand ">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: item.currency
                            }).format(currency)}
                          </p>
                        </MDBCol>

                        <MDBCol size="2">
                          <p className="text-black quickSand ">
                            {item.interval}
                          </p>
                        </MDBCol>
                        <MDBCol size="2">
                          <p className="text-black quickSand ">
                            {item.currency}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}

                  <MDBRow center>
                    <MDBBtn
                      disabled={
                        (planList.has_more === false &&
                          formData.arrowPlansManage === "previous") ||
                        formData.initialPagePlansManage === true
                          ? true
                          : false
                      }
                      onClick={plansManageEndingBefore}
                      size="sm"
                    >
                      Previous Page
                    </MDBBtn>
                    <MDBBtn
                      disabled={
                        planList.has_more === false &&
                        formData.arrowPlansManage === "next"
                          ? true
                          : false
                      }
                      onClick={plansManageStartingAfter}
                      size="sm"
                    >
                      Next Page
                    </MDBBtn>
                  </MDBRow>

                  {formData.showTaxRate === true &&
                  formData.modalManageMode === "taxRates" ? (
                    <Fragment>
                      <MDBRow center>
                        <MDBCol md="6" sm="10">
                          <MDBInput
                            name="displayName"
                            className="quickSand black-text font-weight-bold   mt-3"
                            labelClass="black-text font-weight-bold quickSand"
                            label="Display Name"
                            onChange={onChangeHandlerEditTax}
                            value={formData.editTaxRateObject.displayName}
                            size="sm"
                            type="text"
                            outline
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow center>
                        <MDBCol md="6" sm="10">
                          <MDBInput
                            name="description"
                            className="quickSand black-text font-weight-bold   mt-3"
                            labelClass="black-text font-weight-bold quickSand"
                            label="Description"
                            onChange={onChangeHandlerEditTax}
                            value={formData.editTaxRateObject.description}
                            size="sm"
                            type="text"
                            outline
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow center>
                        <MDBCol md="6" sm="10">
                          <MDBInput
                            name="jurisdiction"
                            className="quickSand black-text font-weight-bold   mt-3"
                            labelClass="black-text font-weight-bold quickSand"
                            label="Jurisdiction"
                            onChange={onChangeHandlerEditTax}
                            value={formData.editTaxRateObject.jurisdiction}
                            size="sm"
                            type="text"
                            outline
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow center>
                        <MDBBtn onClick={updateTaxRateFunc} size="sm">
                          Update tax rate
                        </MDBBtn>
                      </MDBRow>
                    </Fragment>
                  ) : null}
                  {formData.showPlan === true &&
                  formData.modalManageMode === "plans" ? (
                    <Fragment>
                      <MDBRow center>
                        <MDBCol md="6" sm="10">
                          <MDBInput
                            disabled={true}
                            name="amount"
                            className="quickSand black-text font-weight-bold   mt-3"
                            labelClass="black-text font-weight-bold quickSand"
                            label="Amount"
                            value={formData.editPlanObject.amount}
                            outline
                            size="sm"
                            type="number"
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow center>
                        <MDBCol md="6" sm="10">
                          <MDBInput
                            disabled={true}
                            className="quickSand black-text font-weight-bold   mt-3"
                            labelClass="black-text font-weight-bold quickSand"
                            label="Interval"
                            value={formData.editPlanObject.interval}
                            outline
                            size="sm"
                            type="text"
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow center>
                        <MDBCol md="6" sm="10">
                          <MDBInput
                            disabled={true}
                            name="nickname"
                            className="quickSand black-text font-weight-bold   mt-3"
                            labelClass="black-text font-weight-bold quickSand"
                            label="Plan Name"
                            value={formData.editPlanObject.nickname}
                            size="sm"
                            type="text"
                            outline
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow center>
                        <MDBBtn
                          color="danger"
                          onClick={deletePlanFunc}
                          size="sm"
                        >
                          Delete plan
                        </MDBBtn>
                      </MDBRow>
                    </Fragment>
                  ) : null}

                  <MDBRow center>
                    <p className="text-white quickSand">Add plan</p>
                  </MDBRow>

                  <Fragment>
                    <MDBRow center>
                      <MDBBtn flat onClick={newMakePlanFunc}>
                        {formData.newMakePlan === true ? "Hide" : "New plan"}
                      </MDBBtn>
                    </MDBRow>
                    {formData.newMakePlan === true ? (
                      <Fragment>
                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBInput
                              name="amount"
                              className="quickSand black-text font-weight-bold   mt-3"
                              labelClass="black-text font-weight-bold quickSand"
                              label="Amount"
                              onChange={onChangePlan}
                              value={formData.newPlanObject.amount}
                              outline
                              size="sm"
                              type="number"
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBSelect
                              className="quickSand black-text font-weight-bold   mt-3"
                              options={formData.intervalOptions}
                              labelClass="black-text font-weight-bold quickSand"
                              label="Interval"
                              outline
                              size="sm"
                              getValue={selectInterval}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBSelect
                              className="quickSand black-text font-weight-bold   mt-3"
                              options={formData.currencyOptions}
                              labelClass="black-text font-weight-bold quickSand"
                              label="Select Currency"
                              outline
                              size="sm"
                              getValue={selectCurrency}
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow center>
                          <MDBCol md="6" sm="10">
                            <MDBInput
                              name="productName"
                              className="quickSand black-text font-weight-bold   mt-3"
                              labelClass="black-text font-weight-bold quickSand"
                              label="Plan Name"
                              onChange={onChangePlan}
                              value={formData.newPlanObject.productName}
                              size="sm"
                              type="text"
                              outline
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow center>
                          <MDBBtn
                            onClick={createPlanFunc}
                            size="sm"
                            color="success"
                            disabled={loadingAddPlan}
                          >
                            {loadingAddPlan === true && (
                              <div className="spinner-grow spinner-grow-sm" />
                            )}
                            Create Plan
                          </MDBBtn>
                        </MDBRow>
                      </Fragment>
                    ) : null}
                  </Fragment>
                </Fragment>
              ) : null}
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn onClick={modalOpenManage} size="sm" color="blue">
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
      <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal size="sm" isOpen={formData.modalRefund} toggle={() => null}>
            <MDBModalBody style={{ minHeight: "15rem" }} className="">
              <MDBRow className="ml-2">
                <MDBInput
                  onClick={() => refundMode("whole")}
                  checked={formData.refundWhole === true ? true : false}
                  label="Full Refund"
                  type="radio"
                  id="radio1"
                />
              </MDBRow>
              <MDBRow className="ml-2">
                <MDBInput
                  onClick={() => refundMode("part")}
                  checked={formData.refundPart === true ? true : false}
                  label="Partial Refund"
                  type="radio"
                  id="radio2"
                  className="quickSand"
                />
              </MDBRow>
              {formData.refundPart === true ? (
                <MDBRow center>
                  <MDBCol size="12">
                    <MDBInput
                      name="refundAmount"
                      className="quickSand black-text font-weight-bold   mt-3"
                      labelClass="black-text font-weight-bold quickSand"
                      label="Amount"
                      onChange={onChangeHandlerRefund}
                      value={formData.refundAmount}
                      size="sm"
                      type="number"
                      outline
                    />
                  </MDBCol>
                </MDBRow>
              ) : null}
            </MDBModalBody>

            <MDBModalFooter className="">
              <MDBBtn disabled={loadingRefund} onClick={refundFunc} size="sm">
                {loadingRefund === true && (
                  <div className="spinner-grow spinner-grow-sm" />
                )}
                Submit
              </MDBBtn>
              <MDBBtn onClick={modalCloseRefund} size="sm" color="blue">
                Cancel
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
      <div className="modalClearBorder">
        <MDBContainer>
          <MDBModal
            size="sm"
            isOpen={formData.modalCurrency}
            toggle={() => null}
          >
            <MDBModalBody style={{ minHeight: "15rem" }} className="">
              <MDBRow center>
                <MDBCol>
                  <MDBSelect
                    className="quickSand black-text font-weight-bold   mt-3"
                    options={formData.currencyOptions}
                    labelClass="black-text font-weight-bold quickSand"
                    label="Select Currency"
                    outline
                    size="sm"
                    getValue={selectCurrency}
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>

            <MDBModalFooter className="">
              <MDBBtn
                onClick={() => {
                  setFormData({
                    ...formData,

                    modalCurrency: false
                  });
                }}
                size="sm"
              >
                Select
              </MDBBtn>

              <MDBBtn onClick={modalCloseCurrency} size="sm" color="blue">
                Cancel
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        <MDBContainer>
          <MDBModal
            className="modal-notify modal-info text-white"
            isOpen={formData.modalDisconnect}
            toggle={modalDisconnect}
          >
            <MDBModalHeader className="text-center" titleClass="w-100" tag="p">
              Disconnect Stripe Account
            </MDBModalHeader>
            <MDBModalBody className="text-center">
              <p className="quickSand">
                {" "}
                Are you sure you want to disconnect Stripe? If you disconnect
                all your current customer data will be removed from Topscholar.
                You will have to manage your existing invoices and subscriptions
                directly on the Stripe website. To proceed click on confirm
                below.
              </p>
            </MDBModalBody>
            <MDBModalFooter className="justify-content-center">
              <MDBBtn color="primary" onClick={modalDisconnect}>
                Close
              </MDBBtn>
              <MDBBtn
                color="warning"
                onClick={() => {
                  disconnectStripe({
                    stripe_user_id: user.stripe_user_id
                  });
                }}
              >
                Confirm and remove data
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
    </Fragment>
  );
};

PaymentConnect.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  payment: state.payment,
  students: state.students
});

export default withRouter(
  connect(mapStateToProps, {
    getCredentials,

    getListInvoices,
    getStudents,
    getCustomer,
    getListInvoicesIndividual,
    checkout,
    sendInvoice,
    getInvoice,
    getPaymentIntent,
    invoiceVoid,
    invoiceUncollectible,
    refund,
    createSubscription,
    getSubscription,
    getSubscriptionList,
    searchPaymentsFunction,
    clearPaymentsSearchReducer,
    setPaginationModePayments,
    cancelSubscriptionImmediately,
    cancelSubscriptionEnd,
    finalizeInvoice,
    getTaxRates,
    createTaxRate,
    updateTaxRate,
    getPlans,
    createPlan,
    deletePlan,
    getPlansSelect,
    getTaxRatesSelect,
    loadingTrue,
    addCurrency,
    setLanguage,
    getBalance,
    getBalanceTransactions,
    endTrial,
    disconnectStripe,
    setLoadingButtonSendInvoice,
    setLoadingButtonCreateSubscription,
    setLoadingButtonAddTaxrate,
    setLoadingButtonAddPlan,
    setLoadingRefund,
    clearNewInvoiceVar
  })(PaymentConnect)
);
