import axios from "axios";
import { setAlert } from "./alert";

import {
  ADD_GROUP,
  GROUP_ERROR,
  GET_GROUPS,
  GET_GROUPS_SELECT,
  GET_GROUP,
  UPDATE_GROUP,
  GROUPS_DELETE,
  SEARCH_GROUPS,
  SET_PAGINATION_MODE_GROUPS,
  SET_LOADING_BUTTON_GROUPS,
  CLEAR_GROUPS_SEARCH,
  SET_LOADING_BUTTON_DELETE_GROUP
} from "./types";

// Add Group

export const addGroup = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/groups", formData, config);

    dispatch({
      type: ADD_GROUP,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GROUP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all groups
export const getGroups = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post("/api/groups/get", formData, config);

    dispatch({
      type: GET_GROUPS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GROUP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all groups for frontend select

export const getGroupsSelect = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/groups/select", formData, config);

    dispatch({
      type: GET_GROUPS_SELECT,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GROUP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current Group
export const getGroup = id => async dispatch => {
  try {
    const res = await axios.get(`/api/groups/${id}`);

    dispatch({
      type: GET_GROUP,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GROUP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update group

export const updateGroup = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/groups/${formData.id}`,
      formData,
      config
    );

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }

    dispatch({
      type: UPDATE_GROUP,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GROUP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete Group

export const deleteGroup = id => async dispatch => {
  try {
    const res = await axios.delete(`/api/groups/${id}`);

    dispatch({
      type: GROUPS_DELETE,
      payload: id
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GROUP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Groups

export const searchGroupsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/groups/search", formData, config);

    dispatch({
      type: SEARCH_GROUPS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: GROUP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Groups

export const setPaginationModeGroups = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_GROUPS,
    payload: formData
  });
};

// Set Loading Button Groups

export const setLoadingButtonGroups = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_GROUPS,
    payload: formData
  });
};

// Clear Group Search Results

export const clearGroupsSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_GROUPS_SEARCH
  });
};

export const setLoadingDeleteGroup = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_DELETE_GROUP,
    payload: formData
  });
};
