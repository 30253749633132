import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { reset } from "../../../actions/students";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  MDBJumbotron,
  MDBBtn,
  MDBInput,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCardTitle
} from "mdbreact";

const PasswordReset = ({
  setAlert,
  reset,
  history,
  match,
  auth: { updatePassword }
}) => {
  const [formData, setFormData] = useState({
    password: "",
    password2: ""
  });

  const { t, i18n } = useTranslation();

  const { password, password2 } = formData;
  const id = match.params.id;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert(t("studentRegister.1"), "error");
    } else {
      reset({ ...formData, language: i18n.language }, id, history);
    }
  };

  return (
    <Fragment>
      <MDBContainer className="mt-5 text-center primary">
        <MDBRow center>
          <MDBCol md="8">
            <MDBJumbotron className="mt-5">
              <MDBCardBody>
                <MDBCardTitle className="h2">
                  {t("studentRegister.6")}
                </MDBCardTitle>

                <form onSubmit={e => onSubmit(e)}>
                  <MDBInput
                    label={t("studentRegister.7")}
                    type="password"
                    name="password"
                    value={password}
                    onChange={e => onChange(e)}
                  />

                  <MDBInput
                    label={t("studentRegister.8")}
                    type="password"
                    name="password2"
                    value={password2}
                    onChange={e => onChange(e)}
                  />

                  <MDBBtn
                    outline
                    color="primary"
                    type="submit"
                    value="Register"
                  >
                    {t("studentRegister.9")}
                  </MDBBtn>
                </form>
              </MDBCardBody>
            </MDBJumbotron>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

PasswordReset.propTypes = {
  setAlert: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert, reset })(
  withRouter(PasswordReset)
);
