import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CalendarSection from "./CalendarSection";
import { getSeen } from "../../actions/students";
import { withRouter } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import queryString from "query-string";
import { checkoutSession } from "../../actions/payment";
import { closeWelcomeModalFunc } from "../../actions/auth";
import { send } from "../../notification";
import { Helmet } from "react-helmet";
import { isIOS } from "react-device-detect";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBModalFooter,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";

const Dashboard = ({
  auth: { user },
  students: { seen },
  getSeen,
  location,
  checkoutSession,
  closeWelcomeModalFunc,
  history,
  notification: { deferredPrompt }
}) => {
  const [formData, setFormData] = useState({
    seen: [],
    buttonStyle: {
      transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
      opacity: "0"
    },
    bottomShow2: false,
    bottomShow: false,
    enableNotifications: false,
    installedApp: false
  });

  useEffect(() => {
    if (user._id) {
      getSeen();
    }
  }, [user, getSeen]);

  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed.session_id) {
        checkoutSession({ sessionId: parsed.session_id }, history);
      }
    }
  }, [location, checkoutSession, history]);

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        seen: seen
      };
    });
  }, [seen]);
  window.onfocus = function() {
    document.title = "Topscholar";
  };

  const bottomShowFunc = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        bottomShow: !prevState.bottomShow
      };
    });
  };

  const props2 = useSpring({
    opacity:
      formData.bottomShow === false
        ? 0
        : 1 || formData.bottomShow === true
        ? 1
        : 0,

    config: { duration: 600 }
  });

  const welcomeModalClose = () => {
    closeWelcomeModalFunc();
  };

  // Enable push notifications at the welcome modal

  function checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleEnableNotifications = async () => {
    // function to actually ask the permissions
    function handlePermission(permission) {
      // Whatever the user answers, we make sure Chrome stores the information
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }

      if (
        Notification.permission === "denied" ||
        Notification.permission === "default"
      ) {
        console.log("No notification permission granted!");
      } else {
        send();
        setFormData({
          ...formData,
          enableNotifications: true
        });
      }
    }
    // Let's check if the browser supports notifications
    if (!"Notification" in window) {
      console.log("This browser does not support notifications.");
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then(permission => {
          handlePermission(permission);
        });
      } else {
        Notification.requestPermission(function(permission) {
          handlePermission(permission);
        });
      }
    }
  };

  // Install Banner Prompt

  const installPrompt = async () => {
    // Install to homescreen prompt
    if (deferredPrompt) {
      await deferredPrompt.prompt();

      deferredPrompt.userChoice.then(function(choiceResult) {
        console.log(choiceResult.outcome);
        if (choiceResult.outcome === "dismissed") {
          console.log("User cancelled installation");
        } else {
          console.log("User added to home screen");
        }
      });
      deferredPrompt = null;
      setFormData({
        ...formData,
        installedApp: true
      });
    }
  };

  // Remove double scroll bar for welcome modal
  useEffect(() => {
    if (user.welcomeModal) {
      document.body.style.cssText = "overflow-y: hidden !important;";
    } else {
      document.body.style.overflowY = "";
    }
  }, [user.welcomeModal]);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Dashboard</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="justify-content-center align-items-center mt-5 mb-5 zIndexPaginationDashboard">
        <MDBRow className="pt-5 dashboardMarginTop">
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/lessonplans">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="clone fas" className="primary-color" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Lesson Plans
                    </p>
                  </div>
                </div>
                <MDBCardBody cascade>
                  <p className="card-text dashboardInfoFontColor montserratFont">
                    Manage your lesson plans
                  </p>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>

          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/classes">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="user-graduate fas" className="color-one" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      My Classes
                    </p>
                  </div>
                </div>
                <MDBCardBody cascade>
                  <p className="card-text dashboardInfoFontColor montserratFont">
                    Manage your classes
                  </p>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>

          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/payment">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="money-bill" className="red accent-2" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Payments
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <p className="card-text dashboardInfoFontColor montserratFont">
                    Manage your payments
                  </p>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/profile">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="user fas" className="unique-color-dark" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      My Profile
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <p className="card-text dashboardInfoFontColor montserratFont">
                    Update your account preferences
                  </p>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/messages">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="comments" className="indigo lighten-1" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Chat
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Send Messages To Your Students
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/cards">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="brain" className="pink" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Flash Cards
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Build your own flash cards
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/whiteboard">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="chalkboard" className=" green darken-1" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Whiteboard
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Draw out your lessons
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/customizables">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="th" className="cyan" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Online Exams
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Create your own exams and assignments for your students
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/games">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="trophy" className="yellow" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Games
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Create your own games for your students
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/trading">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon
                    icon="balance-scale"
                    className="tradingBackgroundColor"
                  />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Trading
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Trade lessons, topics and exams with other teachers
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/curriculum">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon
                    icon="university"
                    className="blue-gradient
"
                  />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Curriculum
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Organize your modules into a resuable curriculum
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/tutors">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="address-card" className="grey" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Public Page
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Create your very own public profile that you can use to
                        market your tutoring services
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/documentation">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="question-circle" className="deep-orange" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Documentation
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Tutorials on Topscholar
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <Link to="/blog">
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="blog" className="green darken-4" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Blog
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Check out the Topscholar Blog
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </Link>
          </MDBCol>
          <MDBCol xl="3" md="4" sm="6" className="mb-5">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/groups/topscholar"
            >
              <MDBCard
                cascade
                className="cascading-admin-card shadow-box-example hoverable"
              >
                <div className="admin-up">
                  <MDBIcon icon="users" className="facebookDashboard" />
                  <div className="data">
                    <p className="fontFlexDashboard dashboardFont dashboardFontColor">
                      Facebook
                    </p>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBRow>
                    <MDBCol size="auto" className="mr-auto">
                      <p className="card-text dashboardInfoFontColor montserratFont">
                        Join the discussion about everything Topscholar in our
                        Facebook Group
                      </p>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </a>
          </MDBCol>
        </MDBRow>

        <CalendarSection />
      </MDBContainer>
      {formData.bottomShow === false ? (
        <div className="removeUl hideBottom">
          <MDBBtn
            className="btnFixed"
            floating
            color="color-one"
            style={{ bottom: "24px", right: "24px" }}
            onClick={bottomShowFunc}
            size="sm"
          >
            <MDBIcon icon="info-circle"></MDBIcon>
          </MDBBtn>
        </div>
      ) : null}
      <animated.div style={props2}>
        {formData.bottomShow === true ? (
          <div className="float-wrap text-center z-depth-1-half">
            <MDBCard style={{ height: "16rem" }}>
              <MDBIcon
                onClick={bottomShowFunc}
                style={{ cursor: "pointer" }}
                size="2x"
                className="text-right mainColor bottomButton mr-1 mt-1"
                icon="window-close"
              />
              <MDBRow>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Navigation Overview
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Navigation Overview"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/cpPo2QP07VU?rel=0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Dashboard Overview
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Dashboard Overview"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/6G8UTyWKvs4"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Using your Calendar
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Using your calendar"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/Y8Vas-HsDKY"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        ) : null}
      </animated.div>
      <MDBContainer>
        <MDBModal
          className="modal-notify modal-info text-white"
          isOpen={user.welcomeModal}
          size="lg"
        >
          <MDBModalHeader
            className="text-center h4 colorBackgroundBottom livvicFont"
            titleClass="w-100"
            tag="p"
          >
            Welcome to Topscholar!
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <MDBRow center className="mb-3">
              <MDBCol sm="12" className="col-12">
                {/* test */}
                <div className="embed-responsive embed-responsive-1by1 videoDimensions">
                  <iframe
                    style={{ height: "100%", width: "100%" }}
                    title="Introduction to Topscholar"
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/HsqiPRYo7rE"
                    allowFullScreen
                  ></iframe>
                </div>
              </MDBCol>{" "}
            </MDBRow>
            {isIOS ? null : (
              <Fragment>
                <MDBRow className="middleVertical">
                  <p className="quickSand reminderFontSize2">
                    Feel free to turn on notifications by clicking below to
                    receive push notifications from us. We will inform you of
                    the monthly contest winners (see trading system leaderboard
                    info for more information) and other important updates.
                  </p>
                  <MDBBtn
                    size="md"
                    color="success"
                    className="quickSand"
                    onClick={handleEnableNotifications}
                    disabled={formData.enableNotifications === true}
                  >
                    Enable notifications
                  </MDBBtn>
                </MDBRow>
                <MDBRow className="middleVertical mt-2">
                  <p className="quickSand reminderFontSize2">
                    Only for Window and Android users
                  </p>
                  <p className="quickSand reminderFontSize2">
                    Click on the button below to install Topscholar to the
                    homescreen of your device. This will improve your user
                    experience while using our site and can lead to better
                    performance too. You can always do this later by visiting
                    the Chrome Settings > Install Topscholar...
                  </p>
                  <MDBBtn
                    size="md"
                    color="secondary"
                    className="quickSand"
                    onClick={installPrompt}
                    disabled={formData.installedApp === true}
                  >
                    Install Topscholar
                  </MDBBtn>
                </MDBRow>
              </Fragment>
            )}
          </MDBModalBody>
          <MDBModalFooter className="justify-content-center">
            <MDBBtn color="primary" onClick={welcomeModalClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  students: state.students,
  notification: state.notification
});

export default withRouter(
  connect(mapStateToProps, { getSeen, checkoutSession, closeWelcomeModalFunc })(
    Dashboard
  )
);
