import React, { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import queryString from "query-string";
import { Helmet } from "react-helmet";

// notification (service workers)
import { send } from "../../notification";
import { unsubscribeUser } from "../../notification";

import CountryData from "../publicPage/CountryData.json";
import { checkoutupdate, checkoutUpdateDefault } from "../../actions/payment";

import {
  base64StringtoFile,
  extractImageFileExtensionFromBase64
} from "./imageUtils";
import {
  getCustomer,
  getCards,
  getCard,
  cancelSubscription
} from "../../actions/stripe";
import {
  updateUser,
  deleteUser,
  loadUser,
  setLoadingUploadAvatar,
  setLoadingDeleteAccount
} from "../../actions/auth";
import { updateAvatar } from "../../actions/trading";
import moment from "moment";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "react-image-crop/lib/ReactCrop.scss";
import { defaultCard, deleteCard } from "../../actions/stripe";
import momentTimezone from "../dashboard/momentTimezone";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBView,
  MDBCardBody,
  MDBInput,
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBFileInput,
  MDBIcon,
  MDBSelect
} from "mdbreact";
import SetPassword from "../auth/SetPassword";

const Profile = ({
  getCustomer,
  updateUser,
  loadUser,
  deleteUser,
  getCards,
  cancelSubscription,
  auth: { user, loadingUpdateAvatar, loadingDeleteAccount },
  stripe: {
    customer,
    loading,
    cards,
    defaultCardStore,
    deletedCard,
    card,
    paymentIntent
  },
  stripe,
  defaultCard,
  deleteCard,
  getCard,
  updateAvatar,
  payment: { checkoutSession },
  checkoutupdate,
  checkoutUpdateDefault,
  location,
  setLoadingUploadAvatar,
  setLoadingDeleteAccount
}) => {
  const [formData, setFormData] = useState({
    updateUser: {
      name: "",
      email: "",
      screenName: "",
      selectedTimezone: "",
      timezones: []
    },
    defaultCardStore: "",
    modal: false,
    subscriptionInfo: {
      status: "",
      start: "",
      end: "",
      cancelPeriodEnd: ""
    },
    confirmationModal: false,
    confirmationModalCancel: false,
    passwordModal: false,
    loading: false,
    modalUpdateAvatar: false,
    document: "",
    croppedDocument: "",
    crop: {
      unit: "%",
      width: 50,
      aspect: 1 / 1
    },

    imgSrc: "",
    croppedBase64: "",
    countries: [],
    enableNotifications: false,
    bottomShow: false
  });

  const cropImageRef = useRef(null);

  // useEffect(() => {
  //   getCustomer();
  // }, [getCustomer, stripe.cancelSubscription]);
  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    if (user) {
      const timezones = momentTimezone.tz.names().map((label, index) => {
        if (label === user.defaultTimezone) {
          return {
            text: label,
            index: index,
            value: label,
            checked: true
          };
        } else {
          return {
            text: label,
            index: index,
            value: label,
            checked: false
          };
        }
      });

      const countryArray = CountryData.map((item, index) => {
        if (item.value === user.country) {
          return {
            ...item,
            checked: true
          };
        } else {
          return {
            ...item,
            checked: false
          };
        }
      });

      setFormData(prevState => {
        return {
          ...prevState,
          updateUser: {
            name: user.name,
            email: user.email,
            screenName: user.screenName,
            selectedTimezone: user.defaultTimezone
          },
          modalUpdateAvatar: false,
          timezones: timezones,
          countries: countryArray
        };
      });
    }
  }, [user]);

  // useEffect(() => {
  //   if (customer.subscriptions) {
  //     if (customer.subscriptions.data.length !== 0) {
  //       setFormData(prevState => {
  //         return {
  //           ...prevState,
  //           subscriptionInfo: {
  //             status: customer.subscriptions.data[0].status,
  //             start: customer.subscriptions.data[0].current_period_start,
  //             end: customer.subscriptions.data[0].current_period_end,
  //             cancelPeriodEnd:
  //               customer.subscriptions.data[0].cancel_at_period_end
  //           }
  //         };
  //       });
  //     } else {
  //       setFormData(prevState => {
  //         return {
  //           ...prevState,
  //           subscriptionInfo: {
  //             status: "Free Trial",
  //             start: user.trialStart,
  //             end: user.trialEnd
  //           }
  //         };
  //       });
  //     }
  //   }
  // }, [customer.subscriptions, user]);

  // useEffect(() => {
  //   getCards();
  // }, [getCards, deletedCard, card, paymentIntent]);
  // useEffect(() => {
  //   getCard();
  // }, [getCard, customer]);

  // useEffect(() => {
  //   if (defaultCardStore) {
  //     setFormData(prevState => {
  //       return {
  //         ...prevState,
  //         defaultCardStore: defaultCardStore
  //       };
  //     });
  //   }
  // }, [defaultCardStore]);

  const toggle = () => {
    setFormData({
      ...formData,
      modal: !formData.modal
    });
  };

  const clickUpdate = async () => {
    await setFormData({
      ...formData,
      loading: true
    });
    await updateUser(user._id, formData.updateUser);
    setFormData({
      ...formData,
      loading: false
    });
  };

  const onChange = e =>
    setFormData({
      ...formData,
      updateUser: {
        ...formData.updateUser,
        [e.target.name]: e.target.value
      }
    });

  const toggleConfirmation = () => {
    setFormData({
      ...formData,
      confirmationModal: true
    });
  };
  const toggleResetPassword = () => {
    setFormData({
      ...formData,
      passwordModal: true
    });
  };
  const toggleResetPasswordClose = () => {
    setFormData({
      ...formData,
      passwordModal: false
    });
  };

  const toggleConfirmationCancel = () => {
    setFormData({
      ...formData,
      confirmationModalCancel: true
    });
  };

  const toggleConfirmationClose = () => {
    setFormData({
      ...formData,
      confirmationModal: false
    });
  };
  const toggleConfirmationCloseCancel = () => {
    setFormData({
      ...formData,
      confirmationModalCancel: false
    });
  };

  const clickCancel = data => {
    cancelSubscription(data);
    setFormData({
      ...formData,
      confirmationModalCancel: false
    });
  };

  const toggleUpdateAvatar = () => {
    setFormData({
      ...formData,
      modalUpdateAvatar: !formData.modalUpdateAvatar
    });
  };

  // thth

  const fileInputHandler = document => {
    if (document) {
      const currentImage = document[0];
      if (
        currentImage.type === "image/png" ||
        currentImage.type === "image/jpeg" ||
        currentImage.type === "image/jpg" ||
        currentImage.type === "image/x-png" ||
        currentImage.type === "image/gif" ||
        currentImage.type === "image/svg+xml"
      ) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            setFormData({
              ...formData,
              imgSrc: reader.result,
              document: document[0]
            });
          },
          false
        );
        reader.readAsDataURL(currentImage);
      } else {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="times" />
                  <p>
                    Invalid image file. Please choose an image. Jpeg, Jpg, Gif
                    and PNG are accepted.
                  </p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      }
    }
  };

  const submitAvatar = async () => {
    if (!formData.croppedBase64) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>Please choose an image</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      const fileExtension = await extractImageFileExtensionFromBase64(
        formData.croppedBase64
      );
      const myFilename = "newAvatar." + fileExtension;

      const myNewCroppedFile = await base64StringtoFile(
        formData.croppedBase64,
        myFilename
      );
      if (myNewCroppedFile.size > 10000000) {
        const messageError = () => {
          return (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="times" />
                  <p>Your file exceeds 10Mb. Please select a smaller image</p>
                </MDBCol>
              </MDBRow>
            </Fragment>
          );
        };
        toast.error(messageError);
      } else {
        setLoadingUploadAvatar(true);
        updateAvatar(myNewCroppedFile);
      }
    }
  };

  const cropFunc = async () => {
    const imageData64 = await cropImageRef.current
      .getCroppedCanvas()
      .toDataURL();

    await setFormData(prevState => {
      return {
        ...prevState,
        croppedBase64: imageData64
      };
    });
  };

  const selectTimezone = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        updateUser: {
          ...formData.updateUser,
          selectedTimezone: value[0]
        }
      });
    }
  };
  const selectCountry = value => {
    if (value.length !== 0) {
      setFormData({
        ...formData,
        updateUser: {
          ...formData.updateUser,
          country: value[0]
        }
      });
    }
  };

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      const register = navigator.serviceWorker.register(
        "/sw.js",
        navigator.serviceWorker.ready
          // Check to see if we have a subscription
          .then(function(swreg) {
            return swreg.pushManager.getSubscription();
          })
          .then(function(sub) {
            if (sub !== null) {
              return sub;
            } else {
              setFormData({
                ...formData,
                enbableNotifications: false
              });
            }
          })
          .then(
            setFormData({
              ...formData,
              enableNotifications: true
            })
          )
      );
    } else {
      console.log("Your browser does not support the Service-Worker!");
    }
  }, [unsubscribeUser, send]);

  // Handle Notifications

  function checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  }

  const handleEnableNotifications = async () => {
    // function to actually ask the permissions
    function handlePermission(permission) {
      // Whatever the user answers, we make sure Chrome stores the information
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }

      if (
        Notification.permission === "denied" ||
        Notification.permission === "default"
      ) {
        console.log("No notification permission granted!");
      } else {
        send();
        setFormData({
          ...formData,
          enableNotifications: true
        });
      }
    }
    // Let's check if the browser supports notifications
    if (!"Notification" in window) {
      console.log("This browser does not support notifications.");
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then(permission => {
          handlePermission(permission);
        });
      } else {
        Notification.requestPermission(function(permission) {
          handlePermission(permission);
        });
      }
    }
  };

  const handleDisableNotifications = async () => {
    unsubscribeUser();
    setFormData({
      ...formData,
      enableNotifications: false
    });
  };

  function displayConfirmationNotification() {
    if ("serviceWorker" in navigator) {
      var options = {
        body: "You have successfully subscribed to our Notification service!",
        icon:
          "https://res.cloudinary.com/unnamed/image/upload/v1576238300/Favicon_qccdpj.png",
        actions: [
          {
            action: "confirm",
            title: "Okay",
            icon:
              "https://res.cloudinary.com/unnamed/image/upload/v1576238300/Favicon_qccdpj.png"
          },
          {
            action: "cancel",
            title: "Cancel",
            icon:
              "https://res.cloudinary.com/unnamed/image/upload/v1576238300/Favicon_qccdpj.png"
          }
        ]
      };

      navigator.serviceWorker.ready.then(function(swreg) {
        swreg.showNotification("Successfully subscribed!", options);
      });
    }
  }

  const props2 = useSpring({
    opacity:
      formData.bottomShow === false
        ? 0
        : 1 || formData.bottomShow === true
        ? 1
        : 0,

    config: { duration: 600 }
  });

  const bottomShowFunc = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        bottomShow: !prevState.bottomShow
      };
    });
  };

  // eslint-disable-next-line no-undef
  const stripe2 = Stripe("pk_live_cJme1GQD9YHBb2iXteFJ1Kaa00qwryEK2N");

  useEffect(() => {
    if (checkoutSession.id) {
      stripe2
        .redirectToCheckout({
          sessionId: checkoutSession.id
        })
        .then(function(result) {});
    }
  }, [checkoutSession, stripe2]);

  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed.session_id) {
        checkoutUpdateDefault({ sessionId: parsed.session_id });
      }
    }
  }, [location, checkoutUpdateDefault]);

  const deleteUserFunc = () => {
    setLoadingDeleteAccount(true);
    deleteUser();
  };

  return loading || !user ? (
    <Spinner />
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Profile</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5">
        <MDBRow center className="pt-5 mt-3">
          <MDBCol lg="8" className="mb-r">
            <MDBCard narrow>
              <MDBView cascade className="info-color card-header">
                <h5 className="mb-0 font-weight-bold text-center text-white montserratFont">
                  Edit Account Details
                </h5>
              </MDBView>
              <MDBCardBody className="text-center">
                <MDBRow>
                  <MDBCol md="6">
                    <MDBInput
                      type="text"
                      name="name"
                      label="Name"
                      value={formData.updateUser.name}
                      onChange={onChange}
                      labelClass="quickSand black-text"
                      className="quickSand"
                    />
                  </MDBCol>
                  <MDBCol md="6">
                    <MDBInput
                      type="email"
                      name="email"
                      label="Email"
                      value={formData.updateUser.email}
                      onChange={onChange}
                      labelClass="quickSand black-text"
                      className="quickSand"
                    />
                    {formData.enableNotifications === false ? (
                      <MDBBtn
                        size="sm"
                        color="success"
                        className="quickSand"
                        onClick={handleEnableNotifications}
                      >
                        Enable Notifications
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        size="sm"
                        color="success"
                        className="quickSand"
                        onClick={handleDisableNotifications}
                      >
                        Disable Notifications
                      </MDBBtn>
                    )}
                  </MDBCol>
                </MDBRow>
                <MDBRow className="middleVertical5">
                  <MDBCol md="6">
                    <MDBInput
                      type="text"
                      name="screenName"
                      label="Screen Name"
                      value={formData.updateUser.screenName}
                      onChange={onChange}
                      labelClass="quickSand black-text"
                      className="quickSand"
                    />
                    <div className="quickSand">
                      <MDBSelect
                        search
                        required
                        className="quickSand black-text font-weight-bold mt-3"
                        options={formData.timezones}
                        labelClass="black-text font-weight-bold quickSand"
                        label="Default Timezone"
                        outline
                        size="sm"
                        getValue={selectTimezone}
                      />
                    </div>
                    <div className="quickSand">
                      <MDBSelect
                        search
                        required
                        className="quickSand black-text font-weight-bold mt-3"
                        options={formData.countries}
                        labelClass="black-text font-weight-bold quickSand"
                        label="Country"
                        outline
                        size="sm"
                        getValue={selectCountry}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="6">
                    <img
                      style={{ width: "45px" }}
                      src={user.avatar.url}
                      alt=""
                      className="rounded-circle z-depth-1-half"
                    />
                    <p
                      style={{ cursor: "pointer" }}
                      className="quickSand text-info mt-1"
                      onClick={toggleUpdateAvatar}
                    >
                      Change Avatar
                    </p>
                    <MDBBtn
                      onClick={() => toggleResetPassword()}
                      className="mt-4 quickSand"
                      size="sm"
                      color="primary"
                    >
                      Reset Password
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
                {/* <MDBRow>
                  <MDBCol md="6">
                    <MDBInput
                      type="text"
                      value={formData.subscriptionInfo.status}
                      label="Subscription status"
                      labelClass="quickSand"
                      className="quickSand"
                      disabled
                    />
                  </MDBCol>
                  {formData.subscriptionInfo.status === "Free Trial" ? (
                    <MDBCol md="6" className="text-center">
                      <div className="w-100" />
                      <MDBBtn
                        onClick={() => toggleResetPassword()}
                        className="mt-4 quickSand"
                        size="sm"
                        color="primary"
                      >
                        Reset Password
                      </MDBBtn>
                    </MDBCol>
                  ) : (
                    <MDBCol md="6" className="text-center">
                      {formData.subscriptionInfo.cancelPeriodEnd === true ? (
                        <Fragment>
                          <p>
                            We're sorry to see you go. Your subscription will be
                            cancelled at the end of the current billing cycle.
                            If your subscription stays cancelled after 4 months
                            of the cancellation date, your account details and
                            data will be permanently deleted.
                          </p>
                          <MDBBtn
                            onClick={() => clickCancel({ cancel: false })}
                            className="mt-1 quickSand"
                            size="sm"
                            color="info"
                          >
                            Undo your cancellation
                          </MDBBtn>
                        </Fragment>
                      ) : (
                        <MDBBtn
                          onClick={() => toggleConfirmationCancel()}
                          className="mt-4 quickSand"
                          size="sm"
                          color="danger"
                        >
                          Cancel subscription
                        </MDBBtn>
                      )}
                      <div className="w-100" />
                      <MDBBtn
                        onClick={() => toggleResetPassword()}
                        className="mt-4 quickSand"
                        size="sm"
                        color="primary"
                      >
                        Reset Password
                      </MDBBtn>
                    </MDBCol>
                  )}
                </MDBRow> */}
                {/* <MDBRow>
                  <MDBCol md="6">
                    {formData.subscriptionInfo.status === "Free Trial" ? (
                      <MDBInput
                        type="text"
                        label="Start of current billing cycle"
                        labelClass="quickSand"
                        className="quickSand"
                        value={
                          moment(formData.subscriptionInfo.start, "x").format(
                            "LL"
                          )
                          // moment
                          // .unix(formData.subscriptionInfo.start)
                          // .format("YYYY MM DD")}
                        }
                        disabled
                      />
                    ) : (
                      <MDBInput
                        type="text"
                        label="Start of current billing cycle"
                        labelClass="quickSand"
                        className="quickSand"
                        value={moment
                          .unix(formData.subscriptionInfo.start)
                          .format("LL")}
                        disabled
                      />
                    )}
                  </MDBCol>
                </MDBRow> */}
                {/* <MDBRow>
                  <MDBCol md="6">
                    {formData.subscriptionInfo.status === "Free Trial" ? (
                      <MDBInput
                        type="text"
                        label="End of current billing cycle"
                        labelClass="quickSand"
                        className="quickSand"
                        value={moment(
                          formData.subscriptionInfo.end,
                          "x"
                        ).format("LL")}
                        disabled
                      />
                    ) : (
                      <MDBInput
                        type="text"
                        label="End of current billing cycle"
                        labelClass="quickSand"
                        className="quickSand"
                        value={moment
                          .unix(formData.subscriptionInfo.end)
                          .format("LL")}
                        disabled
                      />
                    )}
                  </MDBCol>
                </MDBRow> */}
                <MDBRow>
                  <MDBCol md="12" className="text-center">
                    <MDBBtn
                      color="info"
                      onClick={clickUpdate}
                      rounded
                      disabled={formData.loading}
                      className="quickSand"
                    >
                      {formData.loading === true && (
                        <div className="spinner-grow spinner-grow-sm" />
                      )}
                      Update account
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="12" className="text-center mt-3">
                    <MDBBtn
                      size="sm"
                      color="danger"
                      onClick={toggleConfirmation}
                      className="quickSand"
                    >
                      Delete account
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      {/* {formData.subscriptionInfo.status ===
      "Free Trial" ? null : user.stripeStatus === "active" ? (
        <MDBContainer className="mt-5 mb-5">
          <MDBRow center className="pt-5 mt-3">
            <MDBCol lg="8" className="mb-r">
              <MDBCard narrow>
                <MDBView cascade className="info-color card-header">
                  <h5 className="mb-0 font-weight-bold text-center text-white montserratFont">
                    Payment Method (Billing)
                  </h5>
                </MDBView>
                <MDBCardBody className="text-center">
                  <Fragment>
                    <MDBBtn
                      onClick={() =>
                        checkoutupdate({
                          customerId: user.stripeCustomerId
                        })
                      }
                      className="quickSand"
                    >
                      Change payment method
                    </MDBBtn>
                   
                  </Fragment>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      ) : null} */}

      <MDBContainer>
        <MDBModal
          isOpen={formData.confirmationModal}
          toggle={toggleConfirmationClose}
          size="sm"
        >
          <MDBModalHeader
            className="montserratFont h4 black-text"
            toggle={toggleConfirmationClose}
          >
            Confirm Delete
          </MDBModalHeader>
          <MDBModalBody className="quickSand h5 black-text">
            Warning! If you delete your account all your data will be lost.
            Click 'Confirm Delete' to continue.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              size="sm"
              onClick={toggleConfirmationClose}
              className="quickSand"
            >
              Close
            </MDBBtn>
            <MDBBtn
              disabled={loadingDeleteAccount}
              onClick={deleteUserFunc}
              color="danger"
              size="sm"
              className="quickSand"
            >
              {loadingDeleteAccount === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Confirm Delete
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          isOpen={formData.modalUpdateAvatar}
          toggle={() => null}
          size="md"
        >
          <MDBModalBody>
            <MDBRow center>
              <p className="h6 quickSand pb-2">
                Upload an image (Max file size: 10 Mb)
              </p>
            </MDBRow>
            <MDBRow center>
              <Cropper
                ref={cropImageRef}
                src={formData.imgSrc}
                style={{ height: 250, width: "100%" }}
                aspectRatio={1 / 1}
                guides={false}
                crop={cropFunc}
              />

              <MDBCol md="6" className="mb-4">
                <MDBFileInput
                  reset
                  textFieldTitle=""
                  btnColor="outline-info"
                  btn-size="sm"
                  getValue={fileInputHandler}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow center>
              <p className="quickSand h6 text-info">Avatar Preview</p>
            </MDBRow>
            <MDBRow center className="mb-2">
              <img
                style={{ width: "80px" }}
                src={formData.croppedBase64}
                alt=""
                className="rounded-circle z-depth-1-half"
              />
            </MDBRow>
            <MDBRow center>
              <p className="quickSand">
                A square image works best. PNG and jpeg supported.
              </p>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="success"
              size="sm"
              onClick={submitAvatar}
              disabled={loadingUpdateAvatar}
            >
              {loadingUpdateAvatar === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Save
            </MDBBtn>
            <MDBBtn color="secondary" size="sm" onClick={toggleUpdateAvatar}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          isOpen={formData.confirmationModalCancel}
          toggle={toggleConfirmationCloseCancel}
          size="md"
        >
          <MDBModalHeader
            className="montserratFont h4 black-text"
            toggle={toggleConfirmationCloseCancel}
          >
            Confirm Cancellation
          </MDBModalHeader>
          <MDBModalBody className="quickSand h5 black-text">
            Warning! If you cancel your subscription you won't be able to access
            your saved material unless you decide to restart the subscription.
            Your account will be cancelled at the end of the current billing
            period. After four months of cancellation, your account will be
            deleted. If you wish continue the cancellation of your subscription,
            please click "confirm cancellation". Sorry to see you go.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              size="sm"
              onClick={toggleConfirmationCloseCancel}
              className="quickSand"
            >
              Close
            </MDBBtn>
            <MDBBtn
              onClick={() => clickCancel({ cancel: true })}
              color="danger"
              size="sm"
              className="quickSand"
            >
              Confirm Cancellation
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      <MDBContainer>
        <MDBModal
          isOpen={formData.passwordModal}
          toggle={toggleResetPasswordClose}
          size="sm"
        >
          <MDBModalHeader
            toggle={toggleResetPasswordClose}
            className="montserratFont h4 footerFont"
          >
            Reset Password
          </MDBModalHeader>
          <MDBModalBody>
            <MDBCol md="12" className="text-center">
              <SetPassword />
            </MDBCol>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              size="sm"
              onClick={toggleResetPasswordClose}
              className="quickSand"
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      {/* {formData.bottomShow === false ? (
        <div className="removeUl hideBottom">
          <MDBBtn
            floating
            color="color-one"
            icon="info-circle"
            style={{ bottom: "24px", right: "24px" }}
            onClick={bottomShowFunc}
            className="btnFixed"
            size="sm"
          >
            <MDBIcon icon="info-circle"></MDBIcon>
          </MDBBtn>
        </div>
      ) : null}

      <animated.div style={props2}>
        {formData.bottomShow === true ? (
          <div className="float-wrap text-center z-depth-1-half">
            <MDBCard style={{ height: "16rem" }}>
              <MDBIcon
                onClick={bottomShowFunc}
                style={{ cursor: "pointer" }}
                size="2x"
                className="text-right mainColor bottomButton mr-1 mt-1"
                icon="window-close"
              />
              <MDBRow center>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Profile Overview
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Profile Overview"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/s8jr49-v-N8"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        ) : null}
      </animated.div> */}
    </Fragment>
  );
};

Profile.propTypes = {
  getCustomer: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  stripe: state.stripe,
  notification: state.notification,
  payment: state.payment
});

export default withRouter(
  connect(mapStateToProps, {
    getCustomer,
    getCards,
    defaultCard,
    deleteCard,
    getCard,
    updateUser,
    cancelSubscription,
    deleteUser,
    loadUser,
    updateAvatar,
    checkoutupdate,
    checkoutUpdateDefault,
    setLoadingUploadAvatar,
    setLoadingDeleteAccount
  })(Profile)
);
