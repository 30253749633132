import React from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { access } from "../../actions/stripe";
import moment from "moment";

const PrivateRoute = ({
  component: Component,
  access,
  stripe: { haveAccess },
  auth: { isAuthenticated, loading, user },
  auth,
  ...rest
}) => {
  if (!isAuthenticated && !loading) {
    return (
      <Route
        {...rest}
        render={props => <Component {...props} block={true} />}
      />
    );
  } else if (user.active === false) {
    return (
      <Route
        {...rest}
        render={props => <Component {...props} block={true} />}
      />
    );
  } else if (user.freeTrialStart === false) {
    return (
      <Route
        {...rest}
        render={props => <Component {...props} block={true} />}
      />
    );
  }

  // else if (user.freeTrialStart === true) {
  //   if (parseInt(user.trialEnd) > moment(new Date()).valueOf()) {
  //     return (
  //       <Route
  //         {...rest}
  //         render={props => <Component {...props} block={false} />}
  //       />
  //     );
  //   } else if (parseInt(user.trialEnd) < moment(new Date()).valueOf()) {
  //     if (user.subscribed === true) {
  //       if (
  //         user.stripeStatus === "incomplete_expired" ||
  //         user.stripeStatus === "canceled" ||
  //         user.stripeStatus === "incomplete"
  //       ) {
  //         // link to pay invoice
  //         return <Redirect to="/subscribe" />;
  //       } else if (
  //         user.stripeStatus === "unpaid" ||
  //         user.stripeStatus === "past_due"
  //       ) {
  //         return <Redirect to="/paymentdue" />;
  //       } else {
  //         return (
  //           <Route
  //             {...rest}
  //             render={props => <Component {...props} block={false} />}
  //           />
  //         );
  //       }
  //     } else {
  //       // link to checkout
  //       return <Redirect to="/subscribe" />;
  //     }
  //   }
  // }
  else {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }
};
//   return <Redirect to="/step1" />;
// } else if (user.subscribed === true) {
//   if (
//     user.stripeStatus === "incomplete" ||
//     user.stripeStatus === "incomplete_expired" ||
//     user.stripeStatus === "past_due" ||
//     user.stripeStatus === "canceled" ||
//     user.stripeStatus === "unpaid"
//   ) {
//     return <Redirect to="/step2" />;
//   } else {
//     return <Route {...rest} render={props => <Component {...props} />} />;
//   }
// } else {
//   return <Route {...rest} render={props => <Component {...props} />} />;
// }

// return (
//   <Route
//     {...rest}
//     render={props =>
//       !isAuthenticated && !loading ? (
//         <Redirect to="/login" />
//       ) : (
//         <Component {...props} />
//       )
//     }
//   />
// );

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  stripe: state.stripe
});

export default withRouter(connect(mapStateToProps, { access })(PrivateRoute));
