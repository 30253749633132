import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  MDBJumbotron,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody
} from "mdbreact";

const NotFound = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Page not found</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 text-center">
        <MDBRow>
          <MDBCol md="10" lg="10" xl="10" sm="12" className="mt-5 mx-auto">
            <MDBJumbotron className="mt-5">
              <MDBCardBody>
                <h3 className="quickSand notFoundFontColor">
                  <i className="fas fa-exclamation-triangle mr-2" />
                  Page Not Found
                </h3>
                <p className="h5 montserratFont text-black mt-2">
                  Sorry, this page does not exist
                </p>
              </MDBCardBody>
            </MDBJumbotron>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default withRouter(NotFound);
