import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import TradingMain from "../TradingMain";
import {
  getPublishedCurriculum,
  downloadCurriculum,
  setLoadingButtonDownload
} from "../../../actions/trading";

const Curriculum = ({
  history,
  mode,
  match,
  getPublishedCurriculum,
  setLoadingButtonDownload,
  trading: { publishedCurriculum },
  downloadCurriculum,
  block
}) => {
  const [formData, setFormData] = useState({
    mode: "series",
    block: false
  });

  useEffect(() => {
    getPublishedCurriculum(match.params.id);
  }, [getPublishedCurriculum, match]);

  useEffect(() => {
    if (publishedCurriculum) {
      setFormData(prevState => {
        return {
          ...prevState,
          mode: "series"
        };
      });
    }
  }, [publishedCurriculum]);

  useEffect(() => {
    if (block === true || block === false) {
      setFormData(prevState => {
        return {
          ...prevState,
          block: block
        };
      });
    }
  }, [block]);

  return (
    <Fragment>
      <TradingMain
        curriculumContent={publishedCurriculum}
        url={formData.mode}
        block={formData.block}
      ></TradingMain>
    </Fragment>
  );
};

Curriculum.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    getPublishedCurriculum,
    downloadCurriculum,
    setLoadingButtonDownload
  })(Curriculum)
);
