import React, { Fragment, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { getBlog, resetBlogLoader } from "../../actions/auth";
import Spinner from "../layout/Spinner";

import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon
} from "mdbreact";

const Blog = ({
  activate,
  match,
  history,
  auth: { blog, loadingBlog },
  getBlog,
  resetBlogLoader
}) => {
  useEffect(() => {
    if (match.params.id) {
      console.log(match.params.id);
      getBlog({ _id: match.params.id });
    }
  }, [match.params.id, getBlog]);

  return loadingBlog ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blog.title}</title>
        <meta name="description" content={blog.text} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.text} />
        <meta property="og:image" content={blog.image} />
        <link
          rel="canonical"
          href={`https://www.topscholar.io/blog/${match.params.id}`}
        />
      </Helmet>
      <div style={{ marginTop: "7rem" }}>
        <MDBContainer>
          <Link to="/blog">
            <MDBBtn size="sm" className="quickSand">
              Back to list of posts
            </MDBBtn>
          </Link>

          <div className="mx-1 mb-3">
            <MDBRow center className="my-2 mb-4">
              {" "}
              <h1 className="h1-responsive quickSand font-weight-bold">
                {blog.title}
              </h1>
            </MDBRow>
            <MDBRow center>
              <MDBCol lg="10" md="10" className="col-12">
                <div className="text-center">
                  {blog.resource ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={blog.resource}
                    >
                      <div className="trialHover pt-1 rounded">
                        <img
                          src={blog.image}
                          className="rounded-top img-fluid"
                          alt=""
                        />
                        <MDBRow center className="middleVertical">
                          <p className="text-center trialFont">
                            <MDBIcon
                              far
                              icon="hand-pointer"
                              size="md"
                              className="trialIcon mr-2"
                            />
                            Download
                          </p>
                        </MDBRow>
                      </div>
                    </a>
                  ) : (
                    <img
                      src={blog.image}
                      className="rounded-top img-fluid"
                      alt=""
                    />
                  )}
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow center className="mt-3">
              <MDBCol lg="10" md="10" className="col-12">
                <div
                  className="quickSand mt-1"
                  style={{ fontSize: "17px" }}
                  dangerouslySetInnerHTML={{
                    __html: blog.text
                  }}
                ></div>
              </MDBCol>
            </MDBRow>
          </div>
          <div className="mx-1 mb-5 mt-2">
            <MDBRow center className="my-2">
              {" "}
              <h2 className="h2-responsive quickSand">{blog.header1}</h2>
            </MDBRow>
            <MDBRow center>
              <MDBCol lg="10" md="10" className="col-12">
                <div
                  className="quickSand mt-1"
                  style={{ fontSize: "17px" }}
                  dangerouslySetInnerHTML={{
                    __html: blog.headerText1
                  }}
                ></div>
              </MDBCol>
            </MDBRow>
          </div>
          <div className="mx-1 mb-5 mt-2">
            <MDBRow center className="my-2">
              {" "}
              <h2 className="h2-responsive quickSand">{blog.header2}</h2>
            </MDBRow>
            <MDBRow center>
              <MDBCol lg="10" md="10" className="col-12">
                <div
                  className="quickSand mt-1"
                  style={{ fontSize: "17px" }}
                  dangerouslySetInnerHTML={{
                    __html: blog.headerText2
                  }}
                ></div>
              </MDBCol>
            </MDBRow>
          </div>
          <div className="mx-1 mb-5 mt-2">
            <MDBRow center className="my-2">
              {" "}
              <h2 className="h2-responsive quickSand">{blog.header3}</h2>
            </MDBRow>
            <MDBRow center>
              <MDBCol lg="10" md="10" className="col-12">
                <div
                  className="quickSand mt-1"
                  style={{ fontSize: "17px" }}
                  dangerouslySetInnerHTML={{
                    __html: blog.headerText1
                  }}
                ></div>
              </MDBCol>
            </MDBRow>
          </div>

          {/* <MDBCard className="mb-3">
            <MDBRow>
              <MDBCol lg="3" md="6" sm="12" className="col-12">
                <img
                  src={
                    "https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
                  }
                  className="img-fluid"
                  alt=""
                ></img>
              </MDBCol>
              <MDBCol lg="9" md="6" sm="12" className="col-12">
                <p className="montserratFont black-text h">
                  Topscholar has all the tools you need to take your English
                  teaching to the next level. Create a public page to make some
                  extra money with tutoring on the side, or have access to
                  unlimited English materials posted by other teachers in the
                  Trading System. Explore these modules and much more for only
                  €9 per month (VAT included). Start your 7 day free trial
                  today!
                </p>
              </MDBCol>
            </MDBRow>
          </MDBCard> */}
        </MDBContainer>
      </div>
    </Fragment>
  );
};

Blog.propTypes = {
  auth: PropTypes.object.isRequired,
  activate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, { getBlog, resetBlogLoader })(Blog)
);
