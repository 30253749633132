import React, { Fragment } from "react";
import { withRouter, Link } from "react-router-dom";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import "./Landing.css";
import { Helmet } from "react-helmet";

const Terms = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Tutor Terms</title>

        <meta
          name="description"
          content="Topscholar terms and conditions for teachers/tutors"
        />
        <meta property="og:title" content="Topscholar: Tutor Terms" />
        <meta
          property="og:description"
          content="Topscholar terms and conditions for teachers/tutors"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link rel="canonical" href="https://www.topscholar.io/terms" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5 fill">
        <MDBRow className="pt-5 mt-3 ">
          <MDBCol md="12" className="text-center">
            <h3 className="font-weight-bold monserratFont mb-3 cookieMarginsMobile">
              Terms & Conditions
            </h3>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Welcome to Topscholar OÜ.'s website, topscholar.io (the "Site").
              These Terms and Conditions ("Terms") are between Topscholar OÜ.
              ("Topscholar", "us", "we", or "our") and a user who accesses the
              Site or uses Topscholar's Services ("you" or "your"). These Terms
              outline the terms and conditions of our relationship with you, as
              supplemented by our Privacy Policy. By using the Site or
              Topscholar's Services, you agree to be bound by the following
              terms and all applicable laws and regulations.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Be aware that if you do not wish to be bound by these Terms, do
              not use or access the Site or use our Services. Your use or access
              of the Site in any way signifies your constructive and actual
              notice and knowledge of, and your agreement to be bound by, the
              most current version of the Terms as posted on the Site and our
              Privacy Policy. It is solely your responsibility to ensure that
              your use of any and all third-party website, including but not
              limited to, Stripe and Stripe Connect, or content is in compliance
              with any and all third-party requirements.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Access to portions of the Site, including but not limited to,
              Stripe and Stripe Connect, may require that you agree to
              additional terms ("Specific Terms") that, together with these
              Terms, will govern your use of the relevant site portions. You
              must agree to all terms stipulated in the “Stripe Connected
              Account Agreement”. Any relevant Specific Terms will be provided
              to you before you will be granted first access. Your continued
              access shall constitute your continued acceptance to any relevant
              Specific Terms.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Ownership and Limited License
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              For so long as you are our customer and paying the Service Fees
              required by these Terms, Topscholar grants to you a non-exclusive
              right to use the Site and our Services, subject to the
              restrictions set forth in these Terms and any other restrictions
              communicated by us in writing. Nothing in these Terms shall
              prohibit Topscholar from furnishing our Services to others,
              including your competitors. Subject to the limited rights
              expressly granted hereunder, we reserve all rights, title, and
              interest in and to the Site and our Services, including all
              related intellectual property rights. No rights are granted to you
              hereunder other than as expressly set forth herein. You agree not
              to reproduce, duplicate, copy, sell, resell or exploit any portion
              of the Services, use of the Services, or access to the Services
              without our express written consent. The look and feel of the Site
              and our Services is copyright ©2020 Topscholar Tutor App OÜ. All
              rights reserved. You may not duplicate, copy or reuse any portion
              of the visual design elements without our express written consent.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Restrictions on Use
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              You may use the Site only for purposes expressly permitted by the
              Site. You may not use the Site for any other purpose, including
              any other commercial purpose, without our express prior written
              consent. For example, you may not (and may not authorize any other
              party to) co-brand the Site or frame the Site without our express
              prior written permission.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              User Registration
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              To obtain full access to the Site and our Services, you will be
              required to complete a registration. You will be asked to (a)
              provide certain registration details or other information and (b)
              to establish an account specific to you ("Account"). By signing up
              for your Account, you understand that the website is free to use.
              To create your Account, to login to the Site, and/or to
              participate in any Services offered by the Site, you must qualify
              and agree to the conditions set forth below.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Failure to qualify and continuously abide by any of the following
              conditions constitutes a breach of these Terms and may result in
              the termination of your Account and authorization to use the Site
              and the Services. You acknowledge that, in establishing your
              Account and using our Services, you have provided us with certain
              personally identifiable information about you or your customers.
              Please see our Privacy Policy for the subject matter of the
              information collected and the duration of retention of said
              information; All information that you provide in your registration
              form with us for the purposes of establishing your Account is true
              and correct and you will promptly notify us of any changes to such
              information; All information that you provide to us about your
              customers has been provided with the consent of your customers.
              You will use the information fields only to transmit information
              required to perform the Services requested and will not provide
              any additional personally identifiable information about you or
              your customers to us.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              It is a breach of these Terms for you to provide personally
              identifiable information to us which is not required to perform
              the Services requested; Your Account is for your sole use only and
              must not be used by any third party. You shall not allow any third
              party to use your Account, password, login, or user ID to access
              or use the Site, to arrange, enter, and/or participate in
              Services, or for any other purposes. We take no responsibility for
              any third-party access to your Account. You shall notify us
              promptly of any unauthorized use of your password and
              identification and/or breach. You accept responsibility for all
              activities that occur under your Account, user ID, or password and
              all such use shall be deemed to be authorized by you; You have
              verified and determined that your use of the Site and the Services
              does not violate any law or regulation in any jurisdiction that
              applies to you. It is your sole responsibility to ensure that this
              is the case; You will not use the Site or the Services for
              fraudulent or otherwise illegal purposes; We may suspend or
              terminate your access to the Site and the Services without notice
              to you in the event that you do not use the Site or the Services
              for an extended period of time.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Your Account
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Your Account must be registered under your current full legal
              name, your email address, and a confidential password. It is your
              responsibility to keep your e-mail address current. If you need to
              update your email address, please contact us at
              support@topscholar.io. To access the full functions of the Site,
              you must have a valid Account established. Furthermore, you must
              meet all the conditions described throughout these Terms.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Verification of the Account Information
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              We reserve the right to conduct a review, at any time, to validate
              your Account information and/or to ensure that your participation
              in the Site and use of the Services does not breach these Terms
              and/or any applicable law. You authorize us and our employees to
              make any inquiries of you and for us to use and disclose to any
              third party we consider necessary to validate this information. To
              facilitate the foregoing validation, you agree to provide
              sufficient information or documentation as we, in our discretion,
              may request. If you do not provide such information within thirty
              (30) days of our request, if your responses are incomplete or
              otherwise insufficient, or if we cannot verify the information
              applicable to your Account, your Account may be terminated.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Processing of Account Payments through Stripe
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              We use the Stripe and Stripe Connect for our electronic payment
              processing and to process financial transactions. When initially
              setting up your Account, you will have to accept the Terms and
              Conditions found within the “Stripe Connected Account Agreement”
              and “Stripe Services Agreement”. By accepting the former, you
              authorize us, as necessary, to instruct Stripe to handle such
              transactions. You authorize us to give such instructions on your
              behalf in accordance with your requests as submitted on the Site
              for the use of Services. You acknowledge that Stripe has its own
              terms and conditions of use and that we are not responsible for
              said terms and conditions. In the event or conflict between these
              Terms and Stripe's terms and conditions regarding the Site or the
              Services, these Terms shall prevail. Anything having to do with
              Stripe connect is not part of our core offering. If Stripe is not
              available for your country, we are not responsible for the lack of
              service of not having Stripe.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">Renewals</p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Termination or Cancellations of Accounts
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              We may terminate these Terms, terminate your access to all or part
              of the Site and/or Services, or suspend any user's access to all
              or part of the Site and/or Services, at any time, without notice
              to you, if we believe, in our sole judgment, that you or any user
              connected to you have breached or may breach any term or condition
              of these Terms, if we believe it is required by law, or otherwise.
              We may delete any Account Data or other materials relating to your
              use of the Site and/or our Services on our servers or otherwise in
              our possession. You acknowledge that we shall not be liable to you
              or to any third party for any termination of your access to the
              Site and/or our Services.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              We reserve the right to terminate your Account for inactivity
              after by one hundred and twenty (120) days after deactivation of
              your Account. If we terminate your Account, all of your Account
              Data will be deleted. After the first month, you may cancel your
              Account before the Renewal Date, and your account will not be
              renewed, but you will be charged for the use of Services up to the
              date you cancel your Account.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Modification and Termination of These Terms
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              We may modify these Terms from time to time. We will notify you
              via email or posting on the Site of such modification and will
              note the date of the last modification. If you use the Site or the
              Services after those updates are posted, you are deemed to accept
              and agree to be bound by those changes. These Terms will continue
              to apply until terminated either by you or by us as previously
              described in these Terms.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Service Use & Limitations
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              We will make commercially reasonable efforts to keep the Services
              operational twenty-four (24) hours a day, seven (7) days a week,
              except for: (a) planned downtime (for which we will provide at
              least eight (8) hours prior notice); or (b) any unavailability
              caused by circumstances beyond our control, including but not
              limited to, acts of God, acts of government, flood, fire,
              earthquakes, civil unrest, acts of terror, strikes or other labor
              problems, or Internet service provider failures or delays.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              You acknowledge and agree that: (a) we will not be processing
              payment transactions on your behalf or on behalf of your
              customers; (b) we are not a bank or other chartered depository
              institution; and (c) we will not hold any monies for you or for
              your customers. Accordingly, you agree that we will not be
              responsible or liable for any amounts related to any credit card
              or online payment transaction. We will be sending information to
              Stripe and Stripe Connect; however, we make no representation as
              to the availability of your payment gateway service provider, and
              we are not responsible for any downtime or system outage of your
              payment gateway service provider and/or of Stripe and Stripe
              Connect. Furthermore, we make no guarantee that you will be
              successfully underwritten and approved by Stripe and Stripe
              Connect. If you are not approved by Stripe and Stripe Connect, you
              will be unable to take full advantage of our Site's functionality
              or our Services.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              No Warranty; Disclaimer & Limitation of Liability
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              We have no obligation to check whether users are using the Site or
              the Services in accordance with these Terms or any other
              agreement. You understand that we cannot and do not guarantee or
              warrant that files available for downloading from the Internet
              will be free of viruses, worms, Trojan horses, or other code that
              may manifest contaminating or destructive properties. You are
              responsible for implementing sufficient procedures and checkpoints
              to satisfy your particular requirements for accuracy of data input
              and output, and for maintaining a means external to the Site and
              the Services for the reconstruction of any lost data. We do not
              assume any responsibility or risk for your use of the Internet.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Our Services and all materials on the site are provided "as is"
              and without warranties of any kind, either express or implied,
              including implied warranties of merchantability and fitness for a
              particular purpose. Topscholar makes no representations or
              warranties about the accuracy, completeness, or suitability of any
              of the material on the site, or on any website or websites
              "linked" to the site. Topscholar makes no warranty that the site
              and our services will be available, uninterrupted, error free, or
              free of viruses or other harmful components.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              To the fullest extent permitted by applicable law, under no
              circumstances will Topscholar or its affiliates, contractors, or
              their respective employees have any liability to you for direct,
              indirect, incidental, special, consequential, or exemplary
              damages, including but not limited to damages for loss of profits,
              goodwill, use, data or other intangible losses (even if advised of
              the possibility of such damages) (whether based upon principles of
              contract, warranty, negligence or other tort, breach of any
              statutory duty, the failure of any limited remedy to achieve its
              essential purpose, or otherwise) arising out of or in connection
              with your use of the Site or our Services or your inability to use
              the Site, our Services, Site content, or any products or Services,
              or any failure of performance, error, omission, interruption,
              effect, delay in operation or transmission, computer virus, line
              system failure, loss of data, or loss of use related to the site,
              our services, or any website operated by any third party. If you
              are dissatisfied with us, the Site, our Services, or these terms,
              your sole and exclusive remedy is to discontinue your use of the
              Site and our Services.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">Release</p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              You agree that Topscholar shall not be responsible or liable for
              any loss or damage of any sort incurred as a result of your use of
              the Site or our Services. You hereby release Topscholar, its
              officers, employees, agents, and successors in rights from claims,
              demands, and damages (actual and consequential) of every kind or
              nature, known or unknown, suspected and unsuspected, disclosed and
              undisclosed, arising out of or in any way related to our Site or
              our Services.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Indemnification
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              You agree to defend, indemnify and hold Topscholar, its
              subsidiaries, affiliates, licensors, content providers, service
              providers, employees, agents, officers, directors, and contractors
              (the "Indemnified Parties") from and against any and all
              liability, loss or damage, cost or expense, including but not
              limited to court costs, attorneys' fees, and any awards or damages
              caused by, relating to or incident to: (a) your use of our
              Services; (b) the Site; (c) claims by your customers or Stripe or
              Stripe Connect; or (d) the products and/or services offered
              through the Site.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Third Party Websites
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              We may provide links and pointers to Internet sites maintained by
              others who are independent from Topscholar ("Third-Party Sites").
              We have not reviewed all of the Third-Party Sites linked to the
              Site and are not responsible for the content of or any products or
              services offered on such Third-Party Sites. Topscholar makes no
              representation or warranty as to the accuracy, completeness or
              authenticity of the information contained in any such website.
              Accessing any other website from the Site is at your own risk.
              From time to time, Topscholar may display or otherwise make
              available promotions, advertisements and/or offers provided by
              third parties ("Third-Party Promotions"). You understand and agree
              to hold Topscholar harmless and agree that Topscholar shall have
              no liability whatsoever for such Third-Party Promotions. If you
              participate in, click on, or otherwise link to such Third-Party
              Promotions you do so solely at your own risk. Your sole remedy in
              connection with such Third-Party Promotions will be with the third
              party.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Dispute resolution
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              You agree that, except as may otherwise be provided in regard to
              specific Third Party Materials on the Site in any specific Terms
              applicable to those Third Party Materials, the sole and exclusive
              forum and remedy for any and all disputes and claims relating to
              or arising out of these Terms, the Site, the User Content, Site
              Content, and Third Party Materials (including your visit to or use
              of the Site) shall be final and binding arbitration, except that,
              to the extent that either of us has in any manner infringed upon
              or violated or threatened to infringe upon or violate the other
              party's patent, copyright, trademark, or trade secret rights, or
              you have otherwise violated any of the user conduct rules set
              forth above, then the parties acknowledge that arbitration is not
              an adequate remedy at law and that injunctive or other appropriate
              relief may be sought before, during, or after the pendency of any
              arbitration proceeding brought pursuant to these Terms, or in lieu
              of such proceedings. Any controversy or claim arising out of or
              relating to this contract, or the breach thereof, shall be settled
              by arbitration administered by the European Court of Arbitration,
              and judgment on the award rendered by the arbitrator(s) may be
              entered in any court having jurisdiction thereof.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              The allocation of costs and fees for the arbitration shall be
              determined in accordance with the European Court of Arbitration
              Rules and shall be subject to the limitations in these Terms. The
              arbitration panel's award shall be binding and may be entered as a
              judgment in any court of competent jurisdiction, provided,
              however, that errors of law may be appealed to a court of
              competent jurisdiction for review. Any award in arbitration shall
              be subject to all dollar and other limitations set forth in these
              Terms. To the fullest extent permitted by applicable law, no
              arbitration or claim under these terms shall be joined to any
              other arbitration or claim, including any arbitration or claim
              involving any other current or former User of the Site, and no
              class arbitration proceedings shall be permitted. In no event
              shall any claim, action, or proceeding by you related in any way
              to the Site (including your visit to or use of the Site) be
              instituted more than one (1) year after the cause of action arose.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Trademarks, Trade Names, And Service Marks
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Unless otherwise indicated, all logos, names, package designs, and
              marks on the Site are trademarks or service marks owned or used
              under license by us or our business partners. The use or misuse of
              any of these marks or other information is strictly prohibited. We
              grant you permission to print individual pages from the Site,
              unless otherwise expressly noted, for your noncommercial use in
              learning about and evaluating the services or products offered on
              the Site. No other permission is granted to you to print, copy,
              reproduce, distribute, license, transfer, sell, transmit, upload,
              download, store, display in public, alter, modify, or create
              derivative works of these materials. This grant of permission is
              not a transfer of title, and under this permission you may not:
              remove any copyright, trademark, or other proprietary notations
              from the materials; or transfer the materials to another person or
              "mirror" the materials on any other server, except as permitted by
              law.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              We make no warranties or representations to you that your use of
              any materials displayed on the Site will not infringe the rights
              of third parties. In addition, you may not remove or alter, or
              cause to be removed or altered, any copyright, trademark, trade
              name, service mark, or any other proprietary notice or legend
              appearing on any of the content. Modification or use of this
              content except as expressly provided in these Terms violates
              Topscholar's intellectual property rights. Neither title nor
              intellectual property rights are transferred to you by your access
              to the Site.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Copyright & Intellectual Property
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Topscholar understands the value and importance of intellectual
              property rights and makes every effort to respect the legitimate
              intellectual property rights of others. Topscholar does not review
              all of the material posted on or submitted through the Site and
              has no responsibility for any content that you may find or access
              when using the Site, products, or the Services. Topscholar takes
              all concerns related to intellectual property seriously and it is
              our policy, at our discretion and in appropriate circumstances, to
              cancel, disable or terminate any user's Account who infringes or
              appears to infringe the intellectual property rights of others.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              If you believe that any material on this Website infringes a
              copyright, or any valid intellectual property right, please
              provide the following information to us: A physical or electronic
              signature that you are authorized to act on behalf of the owner of
              an exclusive right that is allegedly infringed; Identification of
              the copyrighted work claimed to have been infringed, or, if
              multiple copyrighted works at a single online site are covered by
              a single notification, a representative list of such works on our
              website; Identification of the material that is claimed to be
              infringing or to be the subject of infringing activity and that is
              to be removed or access to which is to be disabled, and
              information reasonably sufficient to permit us to locate the
              material; Information reasonably sufficient to permit us to
              contact the complaining party, such as an address, telephone
              number, and, if available, an electronic mail address at which the
              complaining party may be contacted; A statement that you have a
              good faith belief that use of the material in the manner
              complained of is not authorized by the copyright owner, its agent,
              or the law; and A statement that the information in the
              notification is accurate, and under penalty of perjury, that you
              are authorized to act on behalf of the owner of an exclusive right
              that is allegedly infringed.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Submitted Information
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Except for Personal Data (as defined in our Privacy Policy), if
              you submit any other information to us, including any data,
              variables, comments, remarks, suggestions, ideas, notes, drawings,
              graphics, concepts, or other information, you are giving that
              information, and all your rights in it, to us free of charge, and
              that information will be treated as non-confidential and
              non-proprietary and may be used by us for any purpose, without
              your consent or any compensation to you or anyone else. This is
              true whether you submit such information to us by email, through a
              form on the Site, on a bulletin board, on our Site’s various
              social media platforms, our Site’s topic-trading section, or in
              any other manner.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              We may from time to time monitor, review, and, in our sole
              discretion, modify or delete any postings you make on the Site,
              however, we are not obligated to do so. You agree not to submit or
              transmit any material that is unlawful, threatening, libelous,
              defamatory, obscene, pornographic, profane, or might in any other
              way violate any law, regulation, or rule, or these Terms. You are
              solely responsible for any material you submit to the Site. You
              further agree not to upload, email, post or transmit to, or
              distribute or otherwise publish through the Site any material
              which disrupts the normal operation of the Site or the Services,
              including posting or otherwise transmitting material that is not
              related to the subject at issue or otherwise restricts or inhibits
              any other user from using the Site or the Services. Through your
              usage of the Site and/or the Services, you may submit and/or we
              may gather certain limited information about you and your website
              usage in accordance with our Privacy Policy. Subject to the terms
              of our Privacy Policy, we are free to use such information for any
              purpose we deem appropriate, we will not be required to treat any
              such submission as confidential, and we may use any submission
              (including without limitation, for products or advertising)
              without incurring any liability for royalties or any other
              consideration of any kind, and will not incur any liability as a
              result.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Terms & Conditions for publishing modules
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              When you choose to use Topscholar’s trading system for the
              publishing, exchanging, and downloading of learning materials for
              teaching English, you agree to a list of terms and conditions
              listed below.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Upon publishing content to the trading system, you relinquish any
              intellectual property rights to the teaching material, and grant
              us the right to modify, transfer, distribute, copy, display,
              translate, and create derivative works of your teaching material.
              In other words, your published content (including any lesson,
              topic, game, exam, flashcard deck or curriculum) will be
              accessible to all other teachers using Topscholar for them to
              edit, download, comment, rate, and share your content, which may
              include to third parties, including but not limited to, Facebook,
              Linkedin and Twitter. All of this without any further consent,
              notice and/or compensation to you or others.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              When publishing a module, you agree to publish content that does
              not contain vulgar or offensive language, copyrighted information
              from third parties, or other content that may violate the
              intellectual property rights of others. You also agree that the
              content you publish will be in English only. You agree that we may
              remove any content we deem to violate these terms at our own
              discretion without any prior notice to you.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              When including links to third-party websites or content in your
              modules, you agree to only add links to authentic and genuine
              websites, and those that add value to your module. You agree to
              avoid posting spammy links, pornographic links, or other harmful
              or irrelevant content.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              You also agree when publishing your content that your screen name
              will be displayed above the published content. You thus give us
              permission to display this information to other users of
              Topscholar, and agree that this information will remain on the
              published material even after the deletion of your account.
              Furthermore, when you choose to follow a user or to post a comment
              on somebody else’s published module, you agree that your screen
              name remains visible even after the deletion of your account.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              It is important to note that deleting one’s account, does not
              remove any published content on the trading system. Therefore,
              once you decide to publish content, it will be available
              indefinitely even beyond the deletion of your account.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Terms & Conditions for using the public page
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              When you choose to use Topscholar’s Public Page for displaying and
              selling your services as an English tutor, you agree to a list of
              terms and conditions listed below.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              You agree that any information you include in your public page
              will be available to any visitor of Topscholar Find Tutors,
              including those not registered with Topscholar. Therefore, you
              should only share information that you are comfortable with
              declaring to others. You agree that this information is truthful
              and representative of yourself, and that you will not mislead or
              otherwise deceive potential students by mischaracterizing your
              profile and qualifications.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              In regards to privacy, you agree to the terms set in our privacy
              policy (topscholar.io/privacy) when choosing to use Topscholar’s
              Public Page. In essence, you agree that any information shared on
              your public page will be available on Topscholar’s “Find Tutors”,
              and will be indexed in order to be later searchable. Your profile
              will also be searchable through external search engines.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              In regards to privacy, you agree to the terms set in our
              <Link to="/privacy"> privacy policy</Link>
              when choosing to use Topscholar’s Public Page. In essence, you
              agree that any information shared on your public page will be
              available on Topscholar’s “Find Tutors”, and will be indexed in
              order to be later searchable. Your profile will also be searchable
              through external search engines.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              You agree to not posting obscene or pornographic avatar images,
              vulgar or crude language in your profile description or any links
              to third party websites which may include any of the above.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Using your public page means you will be able to charge students
              using our integrated Stripe Connect payment system. When you
              choosing to use Stripe Connect, you agree to the terms and
              conditions listed on{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://stripe.com/payment-terms/legal"}
              >
                stripe.com/en-es/connect/legal.{" "}
              </a>
              Any payment transaction made between you and other students are
              your own undertaking, and Topscholar will not be held accountable
              for problems arising from it. Depending on which country you
              reside in, you may not have access to the Strip Connect features.
              Please verify if your country is on the following list to learn
              more:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://stripe.com/global"}
              >
                stripe.com/global.
              </a>
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              You are also responsible for any messages you send to students
              using our messaging service. The contents of the messages you send
              are entirely your own, and we will not be held responsible for any
              problems arising from these messages. Furthermore, Topscholar will
              not be held liable for any disputes between users of its service.
              Topscholar is also not responsible for any harassment, threatening
              or otherwise unethical messaging from a student. Topscholar has
              added a blocked email feature in order to prevent such kind of
              actions.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Topscholar is in no way an employer of its tutors, and acts simply
              as a platform to facilitate the tutors’ services. As such, its
              users are entirely responsible for the costs incurred from
              performing their tutoring services. You cannot make agreements or
              sign contracts on our behalf.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Topscholar also offers the possibility of meeting students
              in-person for tutoring in local cities. There are always risks and
              dangers associated with meeting somebody in person. Topscholar is
              not responsible for any incidents that may occur between a tutor
              and a student when they meet through Topscholar’s Find Tutors.
              Tutors must always take precautions when meeting a student in
              person, and must take necessary steps to avoid any problems.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Finally, Topscholar is not responsible for helping you find
              students for your tutoring services. The public page is put in
              place in order to facilitate finding students for you, but does
              not guarantee any results from it. You will not hold Topscholar
              accountable for not obtaining students from using its platform.
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Topscholar reserves the right to terminate your account without
              refund at any time if you do not comply with the terms above, or
              use the public page in any way which may cause problems to
              Topscholar.
            </p>
            <p className="quickSand cookieFont cookieMarginsMobile">
              Miscellaneous Terms
            </p>
            <p className="quickSand copyrightFont cookieMarginsMobile justify-content-start privacyIndent">
              Our failure to strictly enforce any right against you shall not
              constitute a waiver thereof. If any provision of these Terms is
              deemed invalid, illegal, or unenforceable, such provision will be
              deemed amended to conform to applicable laws and the remainder of
              the Terms shall remain in full force and effect to the fullest
              extent permitted by law. Your use of the Site and our Services is
              subject to our Privacy Policy. These Terms and the Privacy Policy
              represent the full understanding and agreement of the parties with
              respect to the subject matter hereof and supersede all prior or
              contemporaneous oral or written communications with respect to the
              subject matter. Our relationship shall be that of independent
              contractors, and no agency, partnership, joint venture or
              employee-employer relationship is intended or created between us
              by these Terms. Neither party shall have the power to obligate or
              bind the other party.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

export default withRouter(Terms);
