import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resetTokenAction } from "../../actions/auth";
import { MDBRow, MDBCol, MDBInput } from "mdbreact";
import { withRouter } from "react-router-dom";

const SetPassword = ({ resetTokenAction, auth: { resetToken } }) => {
  const [formData, setFormData] = useState({
    email: ""
  });

  const { email } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    resetTokenAction(formData);
  };

  return (
    <Fragment>
      <MDBRow mt="2" center>
        <MDBCol>
          <p className="text-center quickSand reminderFontSize2">
            Enter your registered email to receive a reset password link
          </p>
        </MDBCol>
        <div className="w-100" />
        <MDBCol>
          <form className="form" onSubmit={e => onSubmit(e)}>
            <div className="form-group">
              <MDBInput
                type="email"
                label="Email Address"
                labelClass="quickSand"
                name="email"
                value={email}
                onChange={e => onChange(e)}
                required
                outline
              />
            </div>

            <input
              type="submit"
              className="btn btn-primary quickSand"
              value="Resend Token"
            />
          </form>
        </MDBCol>
        <div className="w-100" />
        {!resetToken ? (
          ""
        ) : (
          <p className="text-center">{resetToken.message}</p>
        )}{" "}
      </MDBRow>
    </Fragment>
  );
};

SetPassword.propTypes = {
  resetTokenAction: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, { resetTokenAction })(SetPassword)
);
