import React, { Fragment, useState, useEffect } from "react";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBIcon,
  MDBJumbotron
} from "mdbreact";
import "../lessonPlans/lessons.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import shortid from "shortid";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";

import {
  sendNotification,
  setLoadingButtonNotifications
} from "../../actions/subscription";
import { createCodes, createBlog } from "../../actions/auth";

const Admin = ({
  sendNotification,
  setLoadingButtonNotifications,
  notification: { loadingButtonNotifications },
  createCodes,
  auth: { createCode, user, createBlogVar },
  createBlog
}) => {
  const [formData, setFormData] = useState({
    title: "",
    message: "",
    icon: "",
    image: "",
    badge: "",
    vibration: "",
    youtube: "",
    titleCode: "",
    number: "",
    blogImage: "",
    blogTitle: "",
    blogText: "",
    blogUrl: "",
    blogResource: "",
    blogHeader1: "",
    blogHeader2: "",
    blogHeader3: "",
    blogHeaderText1: "",
    blogHeaderText2: "",
    blogHeaderText3: ""
  });

  const onChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const onSubmit = async e => {
    const convertVibrationArray = formData.vibration.split(" ");

    const newArray = convertVibrationArray.map((item, index) => {
      return parseInt(item, 10);
    });
    e.preventDefault();
    await setLoadingButtonNotifications();

    sendNotification({
      ...formData,
      vibration: newArray
    });
  };

  const onSubmit2 = () => {
    if (!formData.titleCode) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>Title is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (!formData.number) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A number is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      const codes = [...Array(parseInt(formData.number))].map((item, index) => {
        return shortid.generate();
      });

      createCodes({
        title: formData.titleCode,
        codes: codes
      });
    }
  };
  const onSubmit3 = () => {
    if (!formData.blogTitle) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>Title is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (!formData.blogText) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A text is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (!formData.blogImage) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>An image is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else if (!formData.blogUrl) {
      const messageError = () => {
        return (
          <Fragment>
            <MDBRow>
              <MDBCol>
                <MDBIcon icon="times" />
                <p>A url is required</p>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      };
      toast.error(messageError);
    } else {
      createBlog({
        blogTitle: formData.blogTitle,
        blogText: formData.blogText,
        blogImage: formData.blogImage,
        blogUrl: formData.blogUrl,
        blogResource: formData.blogResource,
        blogHeader1: formData.blogHeader1,
        blogHeader2: formData.blogHeader2,
        blogHeader3: formData.blogHeader3,
        blogHeaderText1: formData.blogHeaderText1,
        blogHeaderText2: formData.blogHeaderText2,
        blogHeaderText3: formData.blogHeaderText3
      });
    }
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        titleCode: "",
        number: ""
      };
    });
  }, [createCode]);
  useEffect(() => {
    if (createBlogVar) {
      setFormData(prevState => {
        return {
          ...prevState,
          blogTitle: "",
          blogText: "",
          blogImage: "",
          blogUrl: "",
          blogResource: "",
          blogHeader1: "",
          blogHeader2: "",
          blogHeader3: "",
          blogHeaderText1: "",
          blogHeaderText2: "",
          blogHeaderText3: ""
        };
      });
    }
  }, [createBlogVar]);

  // before return

  return user.admin === true ? (
    <MDBContainer>
      <MDBCard className="modalBottomMargin mx-3 adminPageMarginTop">
        <MDBCardBody>
          <p className="h4 pb-2 quickSand text-center">Admin Page</p>

          <MDBRow className="middleVertical text-center">
            <MDBCol md="6">
              <MDBInput
                name="title"
                onChange={e => onChange(e)}
                value={formData.title}
                label="Push Title"
                labelClass="quickSand"
                className="quickSand"
                required
              />
            </MDBCol>
            <MDBCol md="6" className="align-items-center">
              <MDBInput
                name="message"
                onChange={e => onChange(e)}
                value={formData.message}
                type="textarea"
                label="Push Message"
                labelClass="quickSand"
                className="quickSand"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <MDBInput
                onChange={e => onChange(e)}
                value={formData.icon}
                name="icon"
                label="Paste Icon URL"
                labelClass="quickSand"
                className="quickSand"
                background
                size="sm"
              />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                onChange={e => onChange(e)}
                value={formData.image}
                name="image"
                label="Paste Image URL"
                labelClass="quickSand"
                className="quickSand"
                background
                size="sm"
              />
              <p className="quickSand text-center h6">
                An image of width 1350px or more would be a good bet.
              </p>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <MDBInput
                onChange={e => onChange(e)}
                value={formData.badge}
                name="badge"
                label="Paste Badge URL"
                labelClass="quickSand"
                className="quickSand"
                background
                size="sm"
              />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                onChange={e => onChange(e)}
                value={formData.vibration}
                name="vibration"
                label="Set notification vibration"
                labelClass="quickSand"
                className="quickSand"
                background
                size="sm"
              />
              <p className="quickSand text-center h6">
                Give Vibrate Sequence in ms (Seperate numbers with space. Ex:
                100 60 200)
              </p>
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                onChange={e => onChange(e)}
                value={formData.youtube}
                name="youtube"
                label="Youtube URL"
                labelClass="quickSand"
                className="quickSand"
                background
                size="sm"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow center className="mt-3">
            <MDBBtn
              className="quickSand submitButtonAdmin"
              size="lg"
              onClick={onSubmit}
              disabled={loadingButtonNotifications}
            >
              {loadingButtonNotifications === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Submit
            </MDBBtn>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="modalBottomMargin mx-3 adminPageMarginTop">
        <MDBCardBody>
          <p className="h4 pb-2 quickSand text-center">Add Codes</p>

          <MDBRow className="middleVertical text-center">
            <MDBCol md="6">
              <MDBInput
                name="titleCode"
                onChange={e => onChange(e)}
                value={formData.titleCode}
                label="Push Title"
                labelClass="quickSand"
                className="quickSand"
                required
              />
            </MDBCol>
            <MDBCol md="6" className="align-items-center">
              <MDBInput
                name="number"
                onChange={e => onChange(e)}
                value={formData.number}
                type="number"
                label="Number of codes"
                labelClass="quickSand"
                className="quickSand"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow center className="mt-3">
            <MDBBtn
              className="quickSand submitButtonAdmin"
              size="lg"
              onClick={onSubmit2}
            >
              Submit
            </MDBBtn>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="modalBottomMargin mx-3 adminPageMarginTop">
        <MDBCardBody>
          <p className="h4 pb-2 quickSand text-center">Add Blog Post</p>

          <MDBRow className="middleVertical text-center">
            <MDBCol md="6">
              <MDBInput
                name="blogTitle"
                onChange={e => onChange(e)}
                value={formData.blogTitle}
                label="Blog Title"
                labelClass="quickSand"
                className="quickSand"
                type="text"
              />
            </MDBCol>
            <MDBCol md="6" className="align-items-center">
              <MDBInput
                name="blogImage"
                onChange={e => onChange(e)}
                value={formData.blogImage}
                type="text"
                label="Blog image url"
                labelClass="quickSand"
                className="quickSand"
              />
            </MDBCol>
            <MDBCol md="6" className="align-items-center">
              <MDBInput
                name="blogText"
                onChange={e => onChange(e)}
                value={formData.blogText}
                type="text"
                label="Blog text"
                labelClass="quickSand"
                className="quickSand"
              />
              <MDBInput
                name="blogHeader1"
                onChange={e => onChange(e)}
                value={formData.blogHeader1}
                type="text"
                label="Blog header 1"
                labelClass="quickSand"
                className="quickSand"
              />
              <MDBInput
                name="blogHeaderText1"
                onChange={e => onChange(e)}
                value={formData.blogHeaderText1}
                type="text"
                label="Blog header text 1"
                labelClass="quickSand"
                className="quickSand"
              />
              <MDBInput
                name="blogHeader2"
                onChange={e => onChange(e)}
                value={formData.blogHeader2}
                type="text"
                label="Blog header 2"
                labelClass="quickSand"
                className="quickSand"
              />
              <MDBInput
                name="blogHeaderText2"
                onChange={e => onChange(e)}
                value={formData.blogHeaderText2}
                type="text"
                label="Blog header text 2"
                labelClass="quickSand"
                className="quickSand"
              />
              <MDBInput
                name="blogHeader3"
                onChange={e => onChange(e)}
                value={formData.blogHeader3}
                type="text"
                label="Blog header 3"
                labelClass="quickSand"
                className="quickSand"
              />
              <MDBInput
                name="blogHeaderText3"
                onChange={e => onChange(e)}
                value={formData.blogHeaderText3}
                type="text"
                label="Blog header text 3"
                labelClass="quickSand"
                className="quickSand"
              />
            </MDBCol>
            <MDBCol md="6" className="align-items-center">
              <MDBInput
                name="blogUrl"
                onChange={e => onChange(e)}
                value={formData.blogUrl}
                type="text"
                label="blog url"
                labelClass="quickSand"
                className="quickSand"
              />
            </MDBCol>
            <MDBCol md="6" className="align-items-center">
              <MDBInput
                name="blogResource"
                onChange={e => onChange(e)}
                value={formData.blogResource}
                type="text"
                label="resource url"
                labelClass="quickSand"
                className="quickSand"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow center className="mt-3">
            <MDBBtn
              className="quickSand submitButtonAdmin"
              size="lg"
              onClick={onSubmit3}
            >
              Submit
            </MDBBtn>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  ) : (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Admin</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 text-center">
        <MDBRow>
          <MDBCol md="10" lg="10" xl="10" sm="12" className="mt-5 mx-auto">
            <MDBJumbotron className="mt-5">
              <MDBCardBody>
                <h3 className="quickSand notFoundFontColor">
                  <i className="fas fa-exclamation-triangle mr-2" />
                  Page Not Found
                </h3>
                <p className="h5 montserratFont text-black mt-2">
                  Sorry, this page does not exist
                </p>
              </MDBCardBody>
            </MDBJumbotron>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  notification: state.notification,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    sendNotification,
    setLoadingButtonNotifications,
    createCodes,
    createBlog
  })(Admin)
);
