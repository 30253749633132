import React, { Fragment, useEffect, useState } from "react";
import sum from "lodash/sum";
import { Scrollbars } from "react-custom-scrollbars";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import {
  ratingExam,
  getPublishedExam,
  setLoadingButtonRating,
  getPublishedEditedExams
} from "../../../actions/trading";
import Rating from "react-rating";
import { withRouter } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from "react-share";

import {
  MDBBtn,
  MDBRow,
  MDBListGroupItem,
  MDBListGroup,
  MDBIcon
} from "mdbreact";

const ExamView = ({
  history,
  mode,
  match,
  downloadExamFunc,
  ratingExam,
  examContent,
  auth: { user },
  modalUploadEditFunc,
  setLoadingButtonRating,
  getPublishedEditedExams,
  trading: {
    publishedExamEdits,
    loadingButtonDownload,
    loadingButtonRating,
    exam,
    publishedExam
  },
  getPublishedExam
}) => {
  const [formData, setFormData] = useState({
    ratingValue: "",
    currentCorrectExam: {
      name: "",
      description: "",
      duration: "",
      students: [],
      components: [],
      date: "",
      image: "",
      englishLevel: [],
      examId: "",
      userTutor: {
        name: "",
        _id: ""
      },
      rating: [],
      downloads: ""
    }
  });

  useEffect(() => {
    if (examContent) {
      setFormData(prevState => {
        return {
          ...prevState,
          currentCorrectExam: {
            ...prevState.currentCorrectExam,
            name: examContent.name,
            description: examContent.description,
            duration: examContent.duration,
            components: examContent.components,
            date: examContent.date,
            image: examContent.image,
            englishLevel: examContent.englishLevel,
            userTutor: examContent.userTutor,
            rating: examContent.rating,
            downloads: examContent.downloads,
            _id: examContent._id
          },
          ratingValue: ""
        };
      });
    }
  }, [examContent]);

  const handleClick = value => {
    setFormData({ ...formData, ratingValue: value });
  };

  const ratingExamFunc = () => {
    const find = formData.currentCorrectExam.rating.find(
      item => item.userId === user._id
    );
    if (!find) {
      setLoadingButtonRating(true);
      ratingExam({
        value: formData.ratingValue,
        examId: formData.currentCorrectExam._id
      });
    }
  };

  const hideButton = () => {
    const find = formData.currentCorrectExam.rating.find(
      item => item.userId === user._id
    );
    if (formData.ratingValue && !find) {
      return true;
    } else {
      return false;
    }
  };

  const findRating = () => {
    const find = formData.currentCorrectExam.rating.find(
      item => item.userId === user._id
    );

    if (formData.currentCorrectExam.userTutor._id === user._id) {
      return true;
    } else if (find) {
      return true;
    } else {
      return false;
    }
  };

  const initialRating = () => {
    const find = formData.currentCorrectExam.rating.find(
      item => item.userId === user._id
    );

    if (find) {
      return find.value;
    } else {
      return formData.ratingValue;
    }
  };

  const countRatingStar = () => {
    const newArray = formData.currentCorrectExam.rating.map((item, index) => {
      return item.value;
    });

    let summation = sum(newArray);
    summation = summation / formData.currentCorrectExam.rating.length;
    return Math.floor(summation);
  };

  const countRating = () => {
    return formData.currentCorrectExam.rating.length;
  };

  useEffect(() => {
    if (exam) {
      getPublishedEditedExams({ originalId: match.params.id });
    }
  }, [getPublishedEditedExams, match, exam]);

  // Share published exam to social media

  const shareUrl = `topscholar.io/trading/exam/${formData.currentCorrectExam._id}`;
  const title = "Check out this exam published on TopScholar!";
  const title2 = `Check out this exam published on TopScholar! topscholar.io/trading/exam/${match.params.id}`;
  const twitterAccount = "Topscholarapp";

  return (
    <Fragment>
      <MDBRow className="mt-3" center>
        <p className="text-white h4 quickSand">Versions</p>
      </MDBRow>

      <MDBRow center>
        <MDBListGroup style={{ height: "15rem" }}>
          <MDBListGroupItem
            style={{ minWidth: "16rem", cursor: "pointer" }}
            className=" quickSand info-color-dark
                text-white h4 my-1 z-depth-2"
            onClick={() => getPublishedExam(match.params.id)}
          >
            <p className="text-center">Original</p>
          </MDBListGroupItem>
          <Scrollbars autoHide autoHideTimeout={2000}>
            {publishedExamEdits.map((item, index) => {
              return (
                <MDBListGroupItem
                  style={{ minWidth: "14rem", cursor: "pointer" }}
                  className=" quickSand  info-color text-white h6 my-1 z-depth-2"
                  onClick={() => getPublishedExam(item._id)}
                >
                  <p className="text-center">Edited by {item.userTutor.name}</p>
                </MDBListGroupItem>
              );
            })}
          </Scrollbars>
        </MDBListGroup>
      </MDBRow>
      <hr />
      <MDBRow center className="uploadButtonPadding">
        <MDBBtn
          size="sm"
          onClick={() => modalUploadEditFunc("exams")}
          color="info"
        >
          Upload Edited Version
        </MDBBtn>
        <MDBBtn
          onClick={downloadExamFunc}
          color="pink"
          disabled={loadingButtonDownload}
        >
          {loadingButtonDownload === true && (
            <div className="spinner-grow spinner-grow-sm" />
          )}
          Download Exam
        </MDBBtn>
      </MDBRow>
      <MDBRow center>
        <p className="text-white quickSand">
          Downloads: {formData.currentCorrectExam.downloads}
        </p>
      </MDBRow>
      <div className={publishedExam.original === true ? "" : "d-none"}>
        <MDBRow center>
          <Rating
            readonly={true}
            emptySymbol="far fa-star fa-2x"
            fullSymbol="fas fa-star fa-2x pinkColor "
            initialRating={countRatingStar()}
            onClick={handleClick}
          />
        </MDBRow>

        <MDBRow center>
          <p className="text-white quickSand">Ratings: {countRating()}</p>
        </MDBRow>

        <MDBRow className="mt-2" center>
          <Rating
            readonly={findRating()}
            initialRating={initialRating()}
            emptySymbol="far fa-star fa-2x"
            fullSymbol="fas fa-star fa-2x pinkColor "
            onClick={handleClick}
          />
        </MDBRow>
        {hideButton() ? (
          <MDBRow center my-2>
            <MDBBtn
              onClick={ratingExamFunc}
              size="sm"
              color="red"
              disabled={loadingButtonRating}
            >
              {loadingButtonRating === true && (
                <div className="spinner-grow spinner-grow-sm" />
              )}
              Submit Rating
            </MDBBtn>
          </MDBRow>
        ) : null}
        <MDBRow center className="mb-1">
          <p className="text-white quickSand">Your rating</p>
        </MDBRow>
      </div>
      <MDBRow center className="ml-3">
        <div className="mr-3 growMessageTrading" style={{ cursor: "pointer" }}>
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>
        </div>
        <div className="mr-3 growMessageTrading" style={{ cursor: "pointer" }}>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
        </div>
        <div className="mr-4 growMessageTrading" style={{ cursor: "pointer" }}>
          <TwitterShareButton
            url={shareUrl}
            title={title2}
            via={twitterAccount}
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
        </div>
        <div className="mt-1">
          <MDBIcon icon="share" className="shareArrowColor shareIconTrading" />
        </div>
      </MDBRow>
    </Fragment>
  );
};

ExamView.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    ratingExam,
    getPublishedExam,
    setLoadingButtonRating,
    getPublishedEditedExams
  })(ExamView)
);
