import axios from "axios";
import { setAlert } from "./alert";

import {
  SEND_NOTIFICATION,
  NOTIFICATION_ERROR,
  SET_LOADING_BUTTON_NOTIFICATIONS,
  DEFERRED_PROMPT
} from "./types";

// Send Notification

export const sendNotification = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/subscription/send", formData, config);

    dispatch({
      type: SEND_NOTIFICATION,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Loading Button Notifications

export const setLoadingButtonNotifications = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_NOTIFICATIONS,
    payload: formData
  });
};

// Defer installation banner prompt

export const deferredPrompt = formData => async dispatch => {
  dispatch({
    type: DEFERRED_PROMPT,
    payload: formData
  });
};
