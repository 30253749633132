import React from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { access } from "../../actions/stripe";
import moment from "moment";

const PrivateRoute = ({
  component: Component,
  access,
  stripe: { haveAccess },
  auth: { isAuthenticated, loading, user },
  auth,
  ...rest
}) => {
  if (!isAuthenticated && !loading) {
    return <Redirect to="/login" />;
  } else if (user.active === false) {
    return <Redirect to="/activationwelcome" />;
  } else if (user.freeTrialStart === false) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else {
    return <Redirect to="/dashboard" />;
  }
};
PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  stripe: state.stripe
});

export default withRouter(connect(mapStateToProps, { access })(PrivateRoute));
