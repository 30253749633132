import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import "../trading.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import urlParser from "js-video-url-parser";
import cloudinary from "cloudinary-core";
import parse from "html-react-parser";
import sum from "lodash/sum";
import { Helmet } from "react-helmet";

import {
  createComment,
  getComments,
  deleteComment,
  likeComment,
  setLoadingButtonComment
} from "../../../actions/trading";
import { withRouter } from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput
} from "mdbreact";

var cl = cloudinary.Cloudinary.new({ cloud_name: "unnamed" });

const LessonView = ({
  history,
  mode,
  match,
  lessonContent,
  createComment,
  getComments,
  setLoadingButtonComment,
  trading: { comments, loadingButtonComment },
  auth: { user },
  deleteComment,
  likeComment
}) => {
  const [formData, setFormData] = useState({
    individualLesson: {
      title: "",
      comments: "",
      objective: "",
      date: "",
      groupIds: {
        name: ""
      },
      level: "",
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      },
      topicIds: []
    },
    comment: "",
    showCommentButton: false
  });

  useEffect(() => {
    if (lessonContent) {
      setFormData(prevState => {
        return {
          ...prevState,
          individualLesson: {
            ...prevState.individualLesson,
            title: lessonContent.title,
            comments: lessonContent.comments,
            objective: lessonContent.objective,
            date: lessonContent.date,
            level: lessonContent.level,
            userTutor: lessonContent.userTutor,
            topicIds: lessonContent.topicIds,
            description: lessonContent.description
          }
        };
      });
    }
  }, [lessonContent]);

  const onChangeComment = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const showCommentButton = () => {
    setFormData({
      ...formData,
      showCommentButton: true
    });
  };

  const createCommentFunc = () => {
    setLoadingButtonComment(true);
    createComment({
      date: moment(new Date()).valueOf(),
      text: formData.comment,
      lessonId: match.params.id,
      module: "lesson"
    });
  };

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        comment: "",
        showCommentButton: false
      };
    });
  }, [comments]);

  const likeFarFunc = item => {
    const find = item.thumbsUp.find(item3 => item3.user === user._id);
    if (find) {
      if (find.score === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const dislikeFarFunc = item => {
    const find = item.thumbsUp.find(item3 => item3.user === user._id);
    if (find) {
      if (find.score === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const viewProfile = item => {
    history.push(`/trading/profile/${formData.individualLesson.userTutor._id}`);
  };

  const viewProfile2 = item => {
    history.push(`/trading/profile/${item}`);
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{formData.individualLesson.title}</title>
        <meta
          name="description"
          content={formData.individualLesson.description}
        />
        <meta property="og:title" content={formData.individualLesson.title} />
        <meta
          property="og:description"
          content={formData.individualLesson.description}
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/unnamed/image/upload/v1579526973/topscholar_256x256_clean_ierlx1.png"
        />
        <link
          rel="canonical"
          href={`https://www.topscholar.io/trading/lesson/${match.params.id}`}
        />
      </Helmet>
      <MDBRow className="middleVertical5 mx-2">
        <MDBCol sm="6">
          <MDBRow center>
            <MDBCol>
              <p className="quickSand mt-3 duruSans h5 text-center text-white">
                {formData.individualLesson.title}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol sm="6">
          <MDBRow
            center
            className="middleVertical tradingNameHover"
            onClick={() =>
              viewProfile({ _id: formData.individualLesson.userTutor._id })
            }
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ width: "35px" }}
              src={formData.individualLesson.userTutor.avatar.url}
              alt=""
              className="rounded-circle z-depth-1-half"
            />
            <p className="mt-3 quickSand titleFontSizeTrading text-black text-white pl-2">
              Published by {formData.individualLesson.userTutor.screenName}
            </p>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <hr />
      <MDBRow>
        <MDBCol>
          <p className="mt-1 tradingFontSize3  text-white pl-2 mb-3 ml-2">
            Description: {formData.individualLesson.description}
          </p>
        </MDBCol>
      </MDBRow>
      <MDBContainer className="mb-2 rounded backgroundTradingLesson">
        <hr />
        <MDBContainer>
          <MDBRow>
            <MDBCol
              md="12"
              className={
                formData.individualLesson.objective !== "" ? "" : "d-none"
              }
            >
              <p className="montserratFont tradingFontSize pb-1">Objectives</p>
              <p className="tradingFontSize2 quickSand pb-1">
                {formData.individualLesson.objective}
              </p>
            </MDBCol>
            <MDBCol
              md="12"
              className={
                formData.individualLesson.comments !== "" ? "" : "d-none"
              }
            >
              <p className="pb-1 montserratFont tradingFontSize">Comments</p>
              <div className="ql-editor " id="fixMargin">
                <p
                  dangerouslySetInnerHTML={{
                    __html: formData.individualLesson.comments
                  }}
                  className="tradingFontSize2"
                ></p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <hr />
        {formData.individualLesson.topicIds.map((item, index) => {
          return (
            <Fragment key={index}>
              <MDBContainer className=" rounded backgroundTradingLesson mb-2">
                <MDBRow className="pt-2">
                  <MDBCol md="12" className="mb-1">
                    <p className="text-center h5 quickSand font-weight-bold">
                      {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                    </p>
                    <Fragment>
                      <div className={item.text !== "" ? "" : "d-none"}>
                        <p className="pb-1 montserratFont tradingFontSize">
                          Notes / Explanation
                        </p>
                      </div>
                      <div className="ql-editor " id="fixMargin">
                        <p
                          className="tradingFontSize2"
                          dangerouslySetInnerHTML={{
                            __html: item.text
                          }}
                        ></p>
                      </div>
                    </Fragment>
                  </MDBCol>
                </MDBRow>
                <MDBRow
                  className={
                    (item.vocabulary.length !== 0 &&
                      item.grammar.length === 0) ||
                    (item.vocabulary.length === 0 && item.grammar.length !== 0)
                      ? "align-self-start"
                      : item.vocabulary.length === 0 &&
                        item.grammar.length === 0
                      ? "d-none"
                      : item.vocabulary.length !== 0 &&
                        item.grammar.length !== 0
                      ? "middleVertical"
                      : "middleVertical"
                  }
                >
                  <MDBCol
                    md="6"
                    className={
                      item.vocabulary.length !== 0 && item.grammar.length === 0
                        ? "mb-1"
                        : item.vocabulary.length !== 0 &&
                          item.grammar.length !== 0
                        ? "mb-1 text-center"
                        : item.vocabulary.length === 0 &&
                          item.grammar.length === 0
                        ? "d-none"
                        : "d-none"
                    }
                  >
                    <p className="pb-1 montserratFont tradingFontSize">
                      Vocabulary Words
                    </p>

                    <ul className="list-inline quickSand tradingFontSize2 vocabularyCommas">
                      {item.vocabulary.map((item, index) => {
                        return (
                          <li key={index} className="list-inline-item">
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </MDBCol>
                  <MDBCol
                    md="6"
                    className={
                      item.vocabulary.length === 0 && item.grammar.length !== 0
                        ? ""
                        : item.vocabulary.length !== 0 &&
                          item.grammar.length !== 0
                        ? "text-center"
                        : item.vocabulary.length === 0 &&
                          item.grammar.length === 0
                        ? "d-none"
                        : "d-none"
                    }
                  >
                    <p className="montserratFont lessonFontSize pb-1">
                      Grammar Topics
                    </p>
                    <ul className="list-inline  tradingFontSize2 quickSand vocabularyCommas">
                      {item.grammar.map((item, index) => {
                        return (
                          <li key={index} className="list-inline-item">
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol
                    md="12"
                    className={item.youtube.length !== 0 ? "mb-4" : "d-none"}
                  >
                    <Fragment>
                      <p className="pb-1 text-center montserratFont tradingFontSize">
                        Youtube Videos
                      </p>
                    </Fragment>
                  </MDBCol>
                  {item.youtube.map((item, index) => {
                    const emb = urlParser.parse(item.url);
                    if (typeof emb === "undefined") {
                      return null;
                    } else {
                      const embUrl = urlParser.create({
                        videoInfo: emb,
                        format: "embed"
                      });

                      return (
                        <MDBCol key={index} md="6" className="mb-4">
                          <div className="embed-responsive embed-responsive-16by9 mb-1">
                            <iframe
                              title="Embeds Page"
                              className="embed-responsive-item"
                              src={embUrl}
                              allowFullScreen
                            />
                          </div>
                        </MDBCol>
                      );
                    }
                  })}
                </MDBRow>
                <MDBRow
                  className={
                    (item.links.length !== 0 && item.documents.length === 0) ||
                    (item.links.length === 0 && item.documents.length !== 0)
                      ? "align-self-start"
                      : item.links.length === 0 && item.documents.length === 0
                      ? "d-none"
                      : item.links.length !== 0 && item.documents.length !== 0
                      ? "middleVertical"
                      : "middleVertical"
                  }
                >
                  <MDBCol
                    md="6"
                    className={item.links.length !== 0 ? "mb-4" : "d-none"}
                  >
                    <Fragment>
                      <p className="text-center pb-1 montserratFont tradingFontSize">
                        Website Links
                      </p>
                    </Fragment>
                    {item.links.map((item, index) => {
                      const hrefgood = item.url.replace(/(^\w+:|^)\/\//, "");

                      return (
                        <ul
                          key={index}
                          className="fa-ul text-center tradingFontSize2 quickSand"
                        >
                          <li className="list-inline-item">
                            <i className="fa-li fa fa-link" />

                            <a
                              href={`http://${hrefgood}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <span className="tradingFontSize3">
                                {item.title}
                              </span>
                            </a>
                          </li>
                        </ul>
                      );
                    })}
                  </MDBCol>
                  <MDBCol
                    md="6"
                    className={
                      item.documents.length !== 0
                        ? "mb-4 text-center"
                        : "d-none"
                    }
                  >
                    <Fragment>
                      <p className="montserratFont tradingFontSize text-center pb-1">
                        Topic document
                      </p>
                    </Fragment>

                    {item.documents.map((item, index) => {
                      if (item.document_type === "video") {
                        return (
                          <MDBRow center>
                            <MDBCol>
                              <a
                                key={index}
                                href={`https://res.cloudinary.com/unnamed/${
                                  item.document_type
                                }/upload/${
                                  item.document_id
                                }${item.document_id.slice(-4)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="tradingFontSize3">
                                  {item.title
                                    ? item.title
                                    : " Link to your saved video"}
                                </span>
                              </a>
                            </MDBCol>
                          </MDBRow>
                        );
                      } else if (item.document_type === "raw") {
                        return (
                          <MDBRow center>
                            <MDBCol>
                              <a
                                key={index}
                                href={`https://res.cloudinary.com/unnamed/${item.document_type}/upload/${item.document_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                <span className="tradingFontSize3">
                                  {item.title
                                    ? item.title
                                    : "Download your saved file"}
                                </span>
                              </a>
                            </MDBCol>
                          </MDBRow>
                        );
                      } else if (item.document_id.slice(-3) === "pdf") {
                        return (
                          <MDBRow center>
                            <MDBCol>
                              <a
                                key={index}
                                href={`https://res.cloudinary.com/unnamed/${item.document_type}/upload/${item.document_id}.pdf`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                <span className="tradingFontSize3">
                                  {item.title
                                    ? item.title
                                    : "Download your saved pdf"}
                                </span>
                              </a>
                            </MDBCol>
                          </MDBRow>
                        );
                      } else {
                        return (
                          <MDBRow center>
                            <MDBCol>
                              <a
                                key={index}
                                href={cl.url(item.document_id)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                <span className="tradingFontSize3">
                                  {item.title
                                    ? item.title
                                    : "Link to your saved file"}
                                </span>
                              </a>
                            </MDBCol>
                          </MDBRow>
                        );
                      }
                    })}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </Fragment>
          );
        })}
      </MDBContainer>
      <MDBRow center>
        <p className=" duruSans h5 text-center text-white">Comments</p>
      </MDBRow>
      <MDBRow center>
        <MDBCol size="12">
          <MDBInput
            style={{ overflowY: "auto" }}
            name="comment"
            type="textarea"
            value={formData.comment}
            label="Post a new comment"
            labelClass="livvicFont text-white"
            className="text-white"
            onChange={onChangeComment}
            onClick={showCommentButton}
          />
        </MDBCol>
        <MDBCol
          size="12"
          className={
            formData.showCommentButton === false ? "d-none" : "text-right"
          }
        >
          <MDBBtn
            onClick={() => {
              setFormData({
                ...formData,
                showCommentButton: false,
                comment: ""
              });
            }}
            outline
          >
            Cancel
          </MDBBtn>
          <MDBBtn disabled={loadingButtonComment} onClick={createCommentFunc}>
            {loadingButtonComment === true && (
              <div className="spinner-grow spinner-grow-sm" />
            )}
            Comment
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <hr />

      {comments.map((item, index) => {
        const thumb = item.thumbsUp.map((item2, index2) => {
          return item2.score;
        });
        let summation = sum(thumb);

        summation = summation <= 0 ? null : summation;

        return (
          <MDBRow className="mt-1 mb-4" key={item._id}>
            <MDBCol sm="4" md="2" className="col-4">
              <div className="label">
                <img
                  style={{ width: "35px", cursor: "pointer" }}
                  src={item.userTutor.avatar.url}
                  alt=""
                  className="rounded-circle z-depth-1-half"
                  onClick={() => viewProfile2(item.userTutor._id)}
                />
              </div>
            </MDBCol>
            <MDBCol sm="8" md="10" className="col-8">
              <div className="">
                <MDBRow className="middleVertical5">
                  <p
                    style={{ margin: "0rem", cursor: "pointer" }}
                    className="quickSand text-white"
                    onClick={() => viewProfile2(item.userTutor._id)}
                  >
                    {item.userTutor.screenName}
                  </p>

                  <div
                    className={
                      item.userTutor._id === user._id
                        ? "ml-auto mr-5"
                        : "d-none"
                    }
                  >
                    <MDBIcon
                      onClick={() => deleteComment({ commentId: item._id })}
                      style={{ cursor: "pointer" }}
                      className="text-danger"
                      icon="trash-alt"
                    />
                  </div>
                </MDBRow>
                <MDBRow>
                  <p style={{ marginBottom: "2px" }}>
                    {moment(item.date, "x").fromNow()}
                  </p>
                </MDBRow>
                <MDBRow>
                  <p
                    style={{ margin: "0rem" }}
                    className="text-white quickSand mb-1"
                  >
                    {item.text}
                  </p>
                </MDBRow>
                <MDBRow>
                  <p className="text-info font-weight-bold mr-2">{summation}</p>

                  <MDBIcon
                    style={{ cursor: "pointer" }}
                    className="mr-2 text-info"
                    far={likeFarFunc(item)}
                    icon="thumbs-up"
                    onClick={() => {
                      likeComment({ score: 1, commentId: item._id });
                    }}
                  />

                  <MDBIcon
                    style={{ cursor: "pointer" }}
                    className="text-info"
                    far={dislikeFarFunc(item)}
                    icon="thumbs-down "
                    onClick={() => {
                      likeComment({ score: -1, commentId: item._id });
                    }}
                  />
                </MDBRow>
              </div>
            </MDBCol>
          </MDBRow>
        );
      })}
    </Fragment>
  );
};

LessonView.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading,
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, {
    createComment,
    getComments,
    deleteComment,
    likeComment,
    setLoadingButtonComment
  })(LessonView)
);
