import axios from "axios";
import { setAlert } from "./alert";
import { loadUser } from "./auth";
import ReactGa from "react-ga";
import ReactPixel from "react-facebook-pixel";

import {
  CUSTOMER_ERROR,
  CREATE_PAYMENTINTENT,
  ACCESS,
  GET_CARDS,
  GET_CARD,
  UPDATE_CARD,
  DELETE_CARD,
  MAKE_CARDDEFAULT,
  GET_CUSTOMER,
  START_TRIAL,
  CANCEL_SUBSCRIPTION
} from "./types";

// Create a PaymentIntent on the server

export const chargeCustomer = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/stripe/customer", formData, config);

    dispatch({
      type: CREATE_PAYMENTINTENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Subscribe and Start Trial

export const startTrial = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/stripe/customer/trial",
      formData,
      config
    );

    ReactGa.event({
      category: "Free Trial",
      action: "start trial"
    });

    ReactPixel.init("472592427013249");
    ReactPixel.trackCustom("track", "StartTrial");

    dispatch({
      type: START_TRIAL,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// unsubscribe immediately

export const endTrial = history => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/stripe/customer/trial/end", config);

    dispatch(loadUser());

    if (history) {
      history.push("/subscribe");
    }

    // dispatch({
    //   type: END_TRIAL,
    //   payload: res.data
    // });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create card by Saving customer's token (card information)

export const saveToken = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/stripe/customer/token",
      formData,
      config
    );

    dispatch({
      type: CREATE_PAYMENTINTENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get information on the user's subscription status

export const access = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.get(
      "/api/stripe/customer/access",
      formData,
      config
    );

    dispatch({
      type: ACCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get the customer's  cards
export const getCards = () => async dispatch => {
  try {
    const res = await axios.get("/api/stripe/customer/cards");

    dispatch({
      type: GET_CARDS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get a default card
export const getCard = () => async dispatch => {
  try {
    const res = await axios.get("/api/stripe/customer/card");

    dispatch({
      type: GET_CARD,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update card

export const updateCard = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/stripe/customer/card`, formData, config);

    dispatch({
      type: UPDATE_CARD,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Delete card

export const deleteCard = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/stripe/customer/deletecard`,
      formData,
      config
    );

    dispatch({
      type: DELETE_CARD,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// make a card the default

export const defaultCard = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/stripe/customer/card/default`,
      formData,
      config
    );

    dispatch({
      type: MAKE_CARDDEFAULT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get customer
export const getCustomer = () => async dispatch => {
  try {
    const res = await axios.get("/api/stripe/customer");

    dispatch({
      type: GET_CUSTOMER,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Cancel subscription
export const cancelSubscription = data => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/stripe/cancel", data, config);

    dispatch({
      type: CANCEL_SUBSCRIPTION,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: CUSTOMER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
