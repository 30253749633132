import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ResendToken from "./ResendToken";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  MDBJumbotron,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle
} from "mdbreact";

const ActivationExpired = ({ auth }) => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Activation link expired</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 text-center">
        <MDBRow>
          <MDBCol md="10" lg="10" xl="10" sm="12" className="mt-5 mx-auto">
            <MDBJumbotron className="mt-5">
              <MDBCardBody color="blue">
                <MDBCardTitle className="h2">
                  Create New Activation Link
                </MDBCardTitle>
                <p className="blue-text my-4 font-weight-bold">
                  Your activation link has expired OR you have already confirmed
                  your account.
                </p>
                <MDBCardText>
                  To complete registration please create a new activation link
                  by submitting your registered email below.
                </MDBCardText>
                <MDBCardText>
                  If you have already confirmed your account, you can access
                  your dashboard.
                </MDBCardText>
                <hr className="my-4" />
                <div className="pt-2">
                  <MDBCol
                    md="10"
                    lg="7"
                    xl="7"
                    sm="12"
                    className="mt-2 mx-auto"
                  >
                    <ResendToken />
                    {auth.resendToken ? <p> {auth.resendToken.message}</p> : ""}
                  </MDBCol>
                </div>
              </MDBCardBody>
            </MDBJumbotron>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};

ActivationExpired.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, {})(ActivationExpired));
