import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import moment from "moment";
import Linkify from "react-linkify";
import { useSpring, animated } from "react-spring";
import { Helmet } from "react-helmet";

import { orderBy } from "natural-orderby";
import { withRouter } from "react-router-dom";
import { latestChatMsgFunc, getStudentsChat } from "../../actions/students";
import {
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBInput,
  MDBIcon,
  MDBBtn,
  MDBScrollbar,
  MDBContainer
} from "mdbreact";
import "./Chat.css";

// removed :5000
let socket = io("/", {
  transports: ["websocket"]
});

const url = io("/messages/student", {
  transports: ["websocket"]
});

var isFocused = true;

function onFocus() {
  isFocused = true;
}

function onBlur() {
  isFocused = false;
}

window.onfocus = onFocus;
window.onblur = onBlur;

const Chat = ({
  auth: { user, latestChatMsg },
  students: { studentsChat, chatLoading },
  latestChatMsgFunc,
  getStudentsChat
}) => {
  const [formData, setFormData] = useState({
    students: [],
    filteredSet: [],
    messages: [],
    newMessage: "",
    user: "",
    userId: "",
    userType: "",
    studentId: "",
    notSeen: "",
    searchValue: "",
    checkedSendEmail: false,
    pressEnter: true,
    bottomShow: false
  });

  useEffect(() => {
    if (user) {
      if (user._id) {
        setFormData(prevState => {
          return {
            ...prevState,
            userType: user.userType,
            user: user
          };
        });

        socket.open();
        url.open();
        url.emit("joinRooms", {
          userId: user._id
        });

        url.emit("getId", user);

        return () => {
          socket.close();
          url.close();
        };
      }
    }
  }, [user]);

  useEffect(() => {
    getStudentsChat();
  }, [getStudentsChat]);

  window.onfocus = function() {
    document.title = "Topscholar";
    if (formData.studentId) {
      const newMessagesArray = formData.messages.map((item, index) => {
        if (item.seen === false) {
          return { ...item, seen: true, onFocus: true };
        }
        return item;
      });
      const newStudentsArray = formData.students.map((item, index) => {
        if (item.id === formData.studentId) {
          return { ...item, seen: true };
        }
        return item;
      });
      const sortedRooms = orderBy(
        newStudentsArray,
        [v => v.noChat, v => v.seen, v => v.when],
        ["asc", "asc", "desc"]
      );

      setFormData(prevState => {
        return {
          ...prevState,
          messages: newMessagesArray,
          students: sortedRooms,
          filteredSet: sortedRooms
        };
      });
    }
  };
  // Socketio listener handlers

  const updateMessages = (data, latestChatMsg) => {
    if (data.userId === formData.studentId) {
      if (isFocused) {
        const newdata = {
          author: data.author,
          avatar: data.avatar,
          message: data.message,
          seen: true,
          touserId: data.touserId,
          userId: data.userId,
          when: data.when,
          onFocus: true
        };

        setFormData(prevState => {
          return {
            ...prevState,
            messages: [...formData.messages, newdata],
            newMessage: "",
            checkedSendEmail: false
          };
        });
      } else {
        const newdata = {
          author: data.author,
          avatar: data.avatar,
          message: data.message,
          seen: data.seen,
          touserId: data.touserId,
          userId: data.userId,
          when: data.when,
          onFocus: false
        };

        setFormData(prevState => {
          return {
            ...prevState,
            messages: [...formData.messages, newdata],
            newMessage: "",
            checkedSendEmail: false
          };
        });
      }
    } else if (formData.studentId === data.touserId) {
      setFormData(prevState => {
        return {
          ...prevState,
          messages: [...formData.messages, data],
          newMessage: "",
          checkedSendEmail: false
        };
      });
      scrollToBottom();
    }
  };

  const notifications = (msg, latestChatMsg) => {
    // if (msg.touserId === formData.userId) {
    document.title = "New Message";
    // }

    const when = msg.when;

    const index2 = formData.students.findIndex(x => x.id === msg.userId);

    if (index2 !== -1) {
      const newStudent = {
        when: when,
        id: msg.userId,
        toRespond: 1,
        // namespace: "Student",
        seen: msg.seen,
        name: msg.author,
        message: msg.message,
        noChat: false
      };
      const newArray = formData.students.map((item, index) => {
        if (index === index2) {
          return newStudent;
        }

        return item;
      });

      const sortedRooms = orderBy(
        newArray,
        [v => v.noChat, v => v.seen, v => v.when],
        ["asc", "asc", "desc"]
      );

      setFormData(prevState => {
        return {
          ...prevState,
          students: sortedRooms,
          filteredSet: sortedRooms
        };
      });
    }
  };

  useEffect(() => {
    const rooms = studentsChat.map((item, index) => {
      const notSeen = item.history.filter(singleHistory => {
        return (
          // singleHistory.seen === false &&
          singleHistory.touserId === user._id
        );
      });

      const when = notSeen.length === 0 ? "" : notSeen[notSeen.length - 1].when;

      return {
        name: item.name,
        icon: "user-graduate",
        id: item._id,
        // namespace: "Student",
        message:
          notSeen.length === 0 ? "" : notSeen[notSeen.length - 1].message,
        when: when,
        toRespond: notSeen.length,
        seen: notSeen.length === 0 ? "" : notSeen[notSeen.length - 1].seen,
        noChat: notSeen.length === 0 ? true : false
      };
    });
    const sortedRooms = orderBy(
      rooms,
      [v => v.noChat, v => v.seen, v => v.when],
      ["asc", "asc", "desc"]
    );

    setFormData(prevState => {
      return {
        ...prevState,
        students: sortedRooms,
        filteredSet: sortedRooms
      };
    });
  }, [studentsChat, user._id]);

  // when a messages is sent it updates the student panel on the left. so not really a namespace update
  useEffect(() => {
    url.on("namespaceUpdate", notifications);

    return () => {
      url.off("namespaceUpdate", notifications);
    };
  });

  useEffect(() => {
    url.on("messageToClients", updateMessages);

    return () => {
      url.off("messageToClients", updateMessages);
    };
  });

  const historyCatchUp = async history => {
    const historyArray = history.history.map((item, index) => {
      return {
        author: item.author,
        icon: item.icon,
        when: item.when,
        message: item.message,
        userId: item.userId,
        seen: item.seen
      };
    });

    await setFormData(prevState => {
      return {
        ...prevState,
        messages: historyArray,
        studentId: history.studentId
      };
    });

    const newStudentsArray = formData.students.map((item, index) => {
      if (item.id === history.studentId) {
        return { ...item, seen: true };
      }
      return item;
    });

    setFormData(prevState => {
      return {
        ...prevState,
        students: newStudentsArray,
        filteredSet: newStudentsArray
      };
    });
  };

  useEffect(() => {
    url.on("historyCatchUp", historyCatchUp);

    return () => {
      url.off("historyCatchUp", historyCatchUp);
    };
  });

  const switchStudent = studentId => {
    url.emit("teacherGetHistory", {
      studentId: studentId,
      userId: user._id,
      latestChatMsg: latestChatMsg
    });
  };

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (formData.newMessage === "") {
      return null;
    } else {
      url.emit("newMessageToServer", {
        text: formData.newMessage,
        userTutor: user.name,
        userId: user._id,
        studentId: formData.studentId,
        userType: user.userType,
        checkedSendEmail: formData.checkedSendEmail
      });
    }
  };
  // Cleanup effect- update seen

  useEffect(() => {
    return () => {
      const notSeen = formData.messages.filter(singleMessage => {
        return singleMessage.seen === true && singleMessage.onFocus === true;
      });
      if (formData.studentId && user._id && notSeen.length !== 0) {
        if (latestChatMsg.seen === false) {
          url.emit("updateSeen", {
            studentId: formData.studentId,
            userId: user._id,
            notSeenArray: notSeen,
            latestChatMsg: latestChatMsg
          });
        }
      }
    };
  }, [formData.messages, formData.studentId, user._id, latestChatMsg]);

  const scrollToBottom = () => {
    window.location.href = "#scrolltobottom";
  };

  useEffect(scrollToBottom, [formData.studentId]);

  //Search function

  const handleSearch = event =>
    setFormData({ ...formData, searchValue: event.target.value });

  useEffect(() => {
    const searchForStudent = () => {
      setFormData(prevState => {
        return {
          ...prevState,
          filteredSet: prevState.students.filter(item =>
            item.name.toLowerCase().match(formData.searchValue.toLowerCase())
          )
        };
      });
    };

    searchForStudent();
  }, [formData.searchValue]);

  const Friend = ({ friend: { name, message, when, seen, id }, onClick }) => {
    const datedisplay = () => {
      return moment(when, "x").fromNow();
    };

    const activeOrNot = () => {
      if (id === formData.studentId) {
        return "border-light indigo lighten-1 text-white studentListMargin";
      } else {
        return "border-light indigo lighten-2 text-white chatMessageHover studentListMargin";
      }
    };
    return (
      <MDBListGroupItem
        className={activeOrNot()}
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <div className="text-center krubFont messageTextSize5">{name}</div>
        <div className="w-100" />
        <div className="p-1">
          <MDBRow className="middleVertical">
            {message && (
              <p className="mb-0 mr-2 text-white robotoFont messageCardBorder">
                {message.slice(0, 15)}
              </p>
            )}
            {message &&
              (!seen ? (
                <span className="text-muted float-right">
                  <MDBIcon icon="comment" className="chatBubble" />
                </span>
              ) : (
                ""
              ))}
          </MDBRow>
          <MDBRow className="middleVertical">
            {message && (
              <p className=" mb-0 text-white" style={{ fontSize: "0.75rem" }}>
                Last Message: {datedisplay()}
              </p>
            )}
          </MDBRow>
        </div>
      </MDBListGroupItem>
    );
  };

  // Press enter to send message

  const onChangeEnter = e => {
    setFormData({
      ...formData,
      pressEnter: !formData.pressEnter
    });
  };
  // Send email message

  const onChangeCheck = e => {
    setFormData({
      ...formData,
      checkedSendEmail: !formData.checkedSendEmail
    });
  };

  const props2 = useSpring({
    opacity:
      formData.bottomShow === false
        ? 0
        : 1 || formData.bottomShow === true
        ? 1
        : 0,

    config: { duration: 600 }
  });

  const bottomShowFunc = () => {
    setFormData(prevState => {
      return {
        ...prevState,
        bottomShow: !prevState.bottomShow
      };
    });
  };

  // Key Press Enter

  const onKeyPressEnterChat = e => {
    if (formData.pressEnter === true) {
      if (e.key === "Enter") {
        onSubmit(e);
      }
    } else {
      return;
    }
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Topscholar: Chat</title>

        <meta name="robots" content="noindex" />
      </Helmet>
      <MDBContainer className="mt-5 mb-5 ">
        <MDBRow className="pt-5 mt-3 " center>
          <MDBCol>
            <MDBCard className="grey lighten-3 chat-room cardMobile">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="5" md="3" className=" mb-md-0 mb-3 mx-xs-3">
                    <h6 className="font-weight-bold mb-3 text-center studentFontSize quickSand">
                      Students
                    </h6>
                    <div className="text-center quickSand">
                      <MDBInput
                        value={formData.searchValue}
                        onChange={handleSearch}
                        hint="Search for student"
                        type="text"
                        containerClass="mt-0"
                      />
                    </div>
                    <div className="friends-list">
                      <MDBScrollbar>
                        <MDBListGroup>
                          {formData.filteredSet.map((friend, index) => (
                            <Friend
                              key={index}
                              friend={friend}
                              studentId={formData.studentId}
                              onClick={() => switchStudent(friend.id)}
                            />
                          ))}
                        </MDBListGroup>
                      </MDBScrollbar>
                    </div>
                  </MDBCol>
                  <MDBCol sm="7" md="9" className="pl-md-3  mt-md-0 px-lg-auto">
                    {formData.students.length === 0 && chatLoading === false ? (
                      <div className="text-center hideHorizontalScroll">
                        <img
                          className="imageFlex"
                          src={
                            "https://res.cloudinary.com/unnamed/image/upload/v1579474244/No_chat_found_1_aazgkc.png"
                          }
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="scrollable-chat pb-0">
                        <MDBScrollbar>
                          <MDBListGroup className="mobileChatScroll">
                            {formData.messages.length !== 0 &&
                              formData.messages.map((message, index) => (
                                <ChatMessage
                                  key={index}
                                  message={message}
                                  user={user}
                                />
                              ))}
                          </MDBListGroup>
                          <div id="scrolltobottom" />
                        </MDBScrollbar>
                      </div>
                    )}
                    {formData.studentId && (
                      <div>
                        <MDBInput
                          onChange={e => onChange(e)}
                          name="newMessage"
                          value={formData.newMessage}
                          className=" pl-2 my-0 mb-3 robotoFont textAreaMobile borderChat textdimension"
                          type="textarea"
                          id="exampleFormControlTextarea2"
                          rows="3"
                          onKeyPress={e => onKeyPressEnterChat(e)}
                          autoFocus
                          outline
                        />
                        <MDBBtn
                          onClick={e => onSubmit(e)}
                          color="indigo"
                          rounded
                          size="sm"
                          className="float-right mt-4 robotoFont"
                        >
                          Send
                        </MDBBtn>
                        <span className="d-inline-block quickSand mt-4 robotoFont">
                          <MDBInput
                            label="Email Alert"
                            checked={formData.checkedSendEmail}
                            type="checkbox"
                            id="checkbox2"
                            onChange={onChangeCheck}
                          />
                        </span>
                        <span className="quickSand pressEnterMargin">
                          <MDBInput
                            label="Press enter to send"
                            checked={formData.pressEnter}
                            type="checkbox"
                            id="checkbox3"
                            onChange={onChangeEnter}
                          />
                        </span>
                      </div>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      {formData.bottomShow === false && !formData.studentId ? (
        <div className="removeUl hideBottom">
          <MDBBtn
            floating
            color="color-one"
            icon="info-circle"
            style={{ bottom: "24px", right: "24px" }}
            onClick={bottomShowFunc}
            className="btnFixed"
            size="sm"
          >
            <MDBIcon icon="info-circle"></MDBIcon>
          </MDBBtn>
        </div>
      ) : null}

      {formData.bottomShow === true ? (
        <animated.div style={props2}>
          <div className="float-wrap text-center z-depth-1-half">
            <MDBCard style={{ height: "16rem" }}>
              <MDBIcon
                onClick={bottomShowFunc}
                style={{ cursor: "pointer" }}
                size="2x"
                className="text-right mainColor bottomButton mr-1 mt-1"
                icon="window-close"
              />
              <MDBRow center>
                <MDBCol className="col-4">
                  <MDBCard style={{ height: "14rem" }}>
                    <p className="text-center krubFont footerFont">
                      Chat Overview
                    </p>
                    <div className="embed-responsive embed-responsive-1by1 py-3">
                      <iframe
                        style={{ height: "11rem" }}
                        title="Chat Overview"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/j6srHnkeQVA"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        </animated.div>
      ) : null}
    </Fragment>
  );
};

const ChatMessage = ({ message: { author, when, message, userId }, user }) => {
  // var today = moment();
  // var yesterday = moment().subtract(1, "day");

  // var engagementDate = when;
  const datedisplay = () => {
    return moment(when, "x").fromNow();
    // if (moment(engagementDate).isSame(today, "day")) {
    //   return moment(when).format("h:mm");
    // } else if (moment(engagementDate).isSame(yesterday, "day")) {
    //   return moment(when).format("YYYY MM DD");
    // }
  };

  const backgroundcolor = () => {
    if (userId === user._id) {
      return "primary-color messageCardBorder z-depth-1-half h-100 messageCardWidth";
    } else {
      return "messageCardBorder z-depth-1-half";
    }
  };
  const textcolor = () => {
    if (userId === user._id) {
      return "text-white robotoFont messageTextSize2";
    } else {
      return "";
    }
  };

  const position = () => {
    if (userId === user._id) {
      return "offset-md-4 middleVertical col-12";
    } else {
      return "middleVertical col-12";
    }
  };
  const linked = () => {
    if (userId === user._id) {
      return (
        <div className="linkdiv">
          <Linkify>{message}</Linkify>
        </div>
      );
    } else {
      return (
        <div className="robotoFont messageTextSize2">
          <Linkify>{message}</Linkify>
        </div>
      );
    }
  };

  var links = document.links;
  for (var i = 0; i < links.length; i++) {
    links[i].target = "_blank";
  }

  return (
    <li className="mb-2">
      <MDBCol md="8" className={position()}>
        <MDBCol md="1" className="col-1">
          <MDBRow center>
            {userId === user._id ? (
              <div className="middleVertical">
                <img
                  style={{ width: "43px" }}
                  src={user.avatar.url}
                  alt=""
                  className="rounded-circle z-depth-1-half"
                />{" "}
              </div>
            ) : (
              <div>
                <MDBCard className="chatMessageCard middleVertical">
                  <p className="mt-3 messageTextSize3 questrialFont">
                    {author.charAt(0).toUpperCase()}
                  </p>
                </MDBCard>
              </div>
            )}
          </MDBRow>
        </MDBCol>
        <MDBCol md="11" className="col-11">
          <MDBCard className={backgroundcolor()}>
            <MDBCardBody className={textcolor()}>{linked()}</MDBCardBody>
            <p className="ml-auto pr-2 mb-0 pb-1 quickSand messageTextSize4">
              {" "}
              {datedisplay()}
            </p>
          </MDBCard>
        </MDBCol>
      </MDBCol>
    </li>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  students: state.students
});

export default withRouter(
  connect(mapStateToProps, { latestChatMsgFunc, getStudentsChat })(Chat)
);
