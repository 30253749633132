import axios from "axios";
import { setAlert } from "./alert";

import {
  TRADING_GET_LESSONS,
  TRADING_ERROR,
  TRADING_GET_TOPICS,
  TRADING_GET_EXAMS,
  TRADING_GET_GAMES,
  TRADING_GET_DECKS,
  PUBLISH_LESSON_O,
  TRADING_GET_PUBLISHED_LESSONS,
  TRADING_GET_PUBLISHED_TOPICS,
  TRADING_GET_PUBLISHED_EXAMS,
  TRADING_GET_PUBLISHED_GAMES,
  TRADING_GET_PUBLISHED_DECKS,
  PUBLISH_TOPIC_O,
  PUBLISH_EXAM_O,
  PUBLISH_GAME_O,
  PUBLISH_DECK_O,
  TRADING_GET_PUBLISHED_LESSON,
  TRADING_GET_PUBLISHED_TOPIC,
  TRADING_GET_PUBLISHED_EXAM,
  TRADING_GET_PUBLISHED_DECK,
  TRADING_GET_PUBLISHED_GAME,
  TRADING_GET_PUBLISHED_CURRICULUMS,
  TRADING_LESSON_SEARCH,
  TRADING_TOPIC_SEARCH,
  TRADING_EXAM_SEARCH,
  TRADING_GAME_SEARCH,
  TRADING_DECK_SEARCH,
  TRADING_CURRICULUM_SEARCH,
  CLEAR_SEARCH,
  CLEAR_FILTER,
  TRADING_LESSON_FILTER,
  TRADING_TOPIC_FILTER,
  TRADING_EXAM_FILTER,
  TRADING_GAME_FILTER,
  TRADING_DECK_FILTER,
  TRADING_CURRICULUM_FILTER,
  TOPIC_RATING,
  LESSON_RATING,
  DECK_RATING,
  EXAM_RATING,
  GAME_RATING,
  DOWNLOAD_TOPIC,
  DOWNLOAD_LESSON,
  DOWNLOAD_DECK,
  DOWNLOAD_EXAM,
  DOWNLOAD_GAME,
  DOWNLOAD_CURRICULUM,
  GET_MAIN,
  GET_USER_MODULES,
  TRADING_TOP_USERS,
  TRADING_GET_PUBLICPROFILE,
  TRADING_SAVE_DESCRIPTION,
  TRADING_GET_FOLLOW,
  TRADING_FOLLOW_USER,
  TRADING_UNFOLLOW_USER,
  UPDATE_AVATAR,
  TRADING_GET_EDITED_LESSONS_USER,
  TRADING_GET_EDITED_TOPICS_USER,
  TRADING_GET_EDITED_EXAMS_USER,
  TRADING_GET_EDITED_GAMES_USER,
  TRADING_GET_EDITED_DECKS_USER,
  TRADING_GET_PUBLISHED_DECKS_EDITED,
  TRADING_GET_PUBLISHED_LESSONS_EDITED,
  TRADING_GET_PUBLISHED_TOPICS_EDITED,
  TRADING_GET_PUBLISHED_EXAMS_EDITED,
  TRADING_GET_PUBLISHED_GAMES_EDITED,
  TRADING_GET_COMMENTS,
  TRADING_CREATE_COMMENT,
  TRADING_UPDATE_COMMENT,
  TRADING_DELETE_COMMENT,
  TRADING_LIKE_COMMENT,
  TRADING_GET_FOLLOW_PUBLIC,
  SET_PAGINATION_MODE,
  TRADING_GET_CURRICULUMS,
  PUBLISH_CURRICULUM_O,
  TRADING_GET_PUBLISHED_CURRICULUM,
  CURRICULUM_RATING,
  SET_LOADING_BUTTON_PUBLISH,
  SET_LOADING_BUTTON_DOWNLOAD,
  SET_LOADING_BUTTON_RATING,
  SET_LOADING_BUTTON_COMMENT,
  SET_LOADING_BUTTON_PUBLISH_EDITED,
  SET_LOADING_BUTTON_FOLLOW,
  SET_LOADING_BUTTON_REPORT_TRADING,
  REPORT_USER_TRADING,
  CLEAR_PUBLISHED_MODULE
} from "./types";

// Get current lessons
export const getLessons = () => async dispatch => {
  try {
    const res = await axios.get("/api/trading/lessons");

    dispatch({
      type: TRADING_GET_LESSONS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get edited lessons
export const getEditedLessonsUser = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/trading/lessons/edited",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_EDITED_LESSONS_USER,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get current topics
export const getTopics = () => async dispatch => {
  try {
    const res = await axios.get("/api/trading/topics");

    dispatch({
      type: TRADING_GET_TOPICS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get edited topics
export const getEditedTopicsUser = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      "/api/trading/topics/edited",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_EDITED_TOPICS_USER,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current exams
export const getExams = () => async dispatch => {
  try {
    const res = await axios.get("/api/trading/exams");

    dispatch({
      type: TRADING_GET_EXAMS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get edited exams
export const getEditedExamsUser = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post("/api/trading/exams/edited", formData, config);

    dispatch({
      type: TRADING_GET_EDITED_EXAMS_USER,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current games
export const getGames = () => async dispatch => {
  try {
    const res = await axios.get("/api/trading/games");

    dispatch({
      type: TRADING_GET_GAMES,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get edited games
export const getEditedGamesUser = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post("/api/trading/games/edited", formData, config);

    dispatch({
      type: TRADING_GET_EDITED_GAMES_USER,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current decks
export const getDecks = () => async dispatch => {
  try {
    const res = await axios.get("/api/trading/decks");

    dispatch({
      type: TRADING_GET_DECKS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get current decks
export const getCurriculums = () => async dispatch => {
  try {
    const res = await axios.get("/api/trading/curriculums");

    dispatch({
      type: TRADING_GET_CURRICULUMS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get edited decks
export const getEditedDecksUser = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post("/api/trading/decks/edited", formData, config);

    dispatch({
      type: TRADING_GET_EDITED_DECKS_USER,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Publish Original lesson

export const publishLessonO = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/lessons/original",
      formData,
      config
    );

    dispatch({
      type: PUBLISH_LESSON_O,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Publish Original topic

export const publishTopicO = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/topics/original",
      formData,
      config
    );

    dispatch({
      type: PUBLISH_TOPIC_O,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Publish Original exam

export const publishExamO = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/exams/original",
      formData,
      config
    );

    dispatch({
      type: PUBLISH_EXAM_O,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Publish Original game

export const publishGameO = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/games/original",
      formData,
      config
    );

    dispatch({
      type: PUBLISH_GAME_O,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Publish Deck game

export const publishDeckO = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/decks/original",
      formData,
      config
    );

    dispatch({
      type: PUBLISH_DECK_O,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Publish curriculum

export const publishCurriculumO = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/curriculums/original",
      formData,
      config
    );

    dispatch({
      type: PUBLISH_CURRICULUM_O,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Lessons

export const searchLessonsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/lessons/search",
      formData,
      config
    );

    dispatch({
      type: TRADING_LESSON_SEARCH,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Topics

export const searchTopicsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/topics/search",
      formData,
      config
    );

    dispatch({
      type: TRADING_TOPIC_SEARCH,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Exams

export const searchExamsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/trading/exams/search", formData, config);

    dispatch({
      type: TRADING_EXAM_SEARCH,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Games

export const searchGamesFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/trading/games/search", formData, config);

    dispatch({
      type: TRADING_GAME_SEARCH,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Decks

export const searchDecksFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/trading/decks/search", formData, config);

    dispatch({
      type: TRADING_DECK_SEARCH,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post Search Results Curriculums

export const searchCurriculumsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/curriculums/search",
      formData,
      config
    );

    dispatch({
      type: TRADING_CURRICULUM_SEARCH,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear Search Results

export const clearSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_SEARCH
  });
};

// Filter Lessons

export const filterLessons = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/lessons/filter",
      formData,
      config
    );

    dispatch({
      type: TRADING_LESSON_FILTER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Filter Topics

export const filterTopics = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/topics/filter",
      formData,
      config
    );

    dispatch({
      type: TRADING_TOPIC_FILTER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Filter Exams

export const filterExams = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/trading/exams/filter", formData, config);

    dispatch({
      type: TRADING_EXAM_FILTER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Filter Games

export const filterGames = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/trading/games/filter", formData, config);

    dispatch({
      type: TRADING_GAME_FILTER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Filter Decks

export const filterDecks = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/trading/decks/filter", formData, config);

    dispatch({
      type: TRADING_DECK_FILTER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Filter Curriculums

export const filterCurriculums = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/curriculums/filter",
      formData,
      config
    );

    dispatch({
      type: TRADING_CURRICULUM_FILTER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode

export const setPaginationMode = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE,
    payload: formData
  });
};

// Clear Filter

export const clearFilterReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_FILTER
  });
};

// Get published lessons
export const getPublishedLessons = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/trading/lessons/published",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_LESSONS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get published edited lessons for a published lesson
export const getPublishedEditedLessons = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/trading/lessons/published/edited",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_LESSONS_EDITED,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get published topics
export const getPublishedTopics = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      "/api/trading/topics/published",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_TOPICS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get published edited lessons for a published topic
export const getPublishedEditedTopics = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/trading/topics/published/edited",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_TOPICS_EDITED,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get published exams
export const getPublishedExams = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      "/api/trading/exams/published",
      formData,
      config
    );
    dispatch({
      type: TRADING_GET_PUBLISHED_EXAMS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get published edited lessons for a published EXAM
export const getPublishedEditedExams = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/trading/exams/published/edited",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_EXAMS_EDITED,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get published games
export const getPublishedGames = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/trading/games/published",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_GAMES,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get published curriculums
export const getPublishedCurriculums = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      "/api/trading/curriculums/published",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_CURRICULUMS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get published edited games for a published game
export const getPublishedEditedGames = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/trading/games/published/edited",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_GAMES_EDITED,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get published decks
export const getPublishedDecks = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/trading/decks/published",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_DECKS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get published edited decks for a published deck
export const getPublishedEditedDecks = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/trading/decks/published/edited",
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_DECKS_EDITED,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get a published lesson
export const getPublishedLesson = formData => async dispatch => {
  try {
    const res = await axios.get(`/api/trading/lessons/published/${formData}`);

    dispatch({
      type: TRADING_GET_PUBLISHED_LESSON,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get a published lesson
export const getPublishedCurriculum = formData => async dispatch => {
  try {
    const res = await axios.get(
      `/api/trading/curriculums/published/${formData}`
    );

    dispatch({
      type: TRADING_GET_PUBLISHED_CURRICULUM,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get a published topic
export const getPublishedTopic = formData => async dispatch => {
  try {
    const res = await axios.get(`/api/trading/topics/published/${formData}`);

    dispatch({
      type: TRADING_GET_PUBLISHED_TOPIC,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get a published exam
export const getPublishedExam = formData => async dispatch => {
  try {
    const res = await axios.get(`/api/trading/exams/published/${formData}`);

    dispatch({
      type: TRADING_GET_PUBLISHED_EXAM,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get a published game
export const getPublishedGame = formData => async dispatch => {
  try {
    const res = await axios.get(`/api/trading/games/published/${formData}`);

    dispatch({
      type: TRADING_GET_PUBLISHED_GAME,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get a published deck
export const getPublishedDeck = formData => async dispatch => {
  try {
    const res = await axios.get(`/api/trading/decks/published/${formData}`);

    dispatch({
      type: TRADING_GET_PUBLISHED_DECK,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const downloadTopic = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/topics/download",
      formData,
      config
    );

    dispatch({
      type: DOWNLOAD_TOPIC,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const downloadExam = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/exams/download",
      formData,
      config
    );

    dispatch({
      type: DOWNLOAD_EXAM,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const downloadGame = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/games/download",
      formData,
      config
    );

    dispatch({
      type: DOWNLOAD_GAME,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const downloadDeck = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/decks/download",
      formData,
      config
    );

    dispatch({
      type: DOWNLOAD_DECK,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const downloadLesson = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/lessons/download",
      formData,
      config
    );

    dispatch({
      type: DOWNLOAD_LESSON,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const downloadCurriculum = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/trading/curriculums/download",
      formData,
      config
    );

    dispatch({
      type: DOWNLOAD_CURRICULUM,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// submit topic rating
export const ratingTopic = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/trading/topics/rating/${formData.topicId}`,
      formData,
      config
    );

    dispatch({
      type: TOPIC_RATING,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// submit lesson rating
export const ratingLesson = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/trading/lessons/rating/${formData.lessonId}`,
      formData,
      config
    );

    dispatch({
      type: LESSON_RATING,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// submit exam rating
export const ratingExam = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/trading/exams/rating/${formData.examId}`,
      formData,
      config
    );

    dispatch({
      type: EXAM_RATING,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// submit game rating
export const ratingGame = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/trading/games/rating/${formData.gameId}`,
      formData,
      config
    );

    dispatch({
      type: GAME_RATING,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// submit deck rating
export const ratingDeck = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/trading/decks/rating/${formData.deckId}`,
      formData,
      config
    );

    dispatch({
      type: DECK_RATING,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// submit curriculum rating
export const ratingCurriculum = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/trading/curriculums/rating/${formData.curriculumId}`,
      formData,
      config
    );

    dispatch({
      type: CURRICULUM_RATING,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// submit deck rating
export const getMain = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.get(`/api/trading/main`, formData, config);

    dispatch({
      type: GET_MAIN,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get User Modules

export const getUserModules = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/trading/modules`, formData, config);

    dispatch({
      type: GET_USER_MODULES,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get top users
export const getTopUsers = () => async dispatch => {
  try {
    const res = await axios.get("/api/trading/topusers");

    dispatch({
      type: TRADING_TOP_USERS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Public Profile

export const getPublicProfile = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      `/api/trading/publicprofile/${formData.id}`,
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_PUBLICPROFILE,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Save description
export const saveDescription = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(`/api/trading/description`, formData, config);

    dispatch({
      type: TRADING_SAVE_DESCRIPTION,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get follow object

export const getFollow = formData => async dispatch => {
  try {
    const res = await axios.get(`/api/trading/follow`);

    dispatch({
      type: TRADING_GET_FOLLOW,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Get follow object public

export const getFollowPublic = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      `/api/trading/follow/public`,
      formData,
      config
    );

    dispatch({
      type: TRADING_GET_FOLLOW_PUBLIC,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// follow a user
export const followUser = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/trading/follow/follow/${formData.userId}`,
      formData,
      config
    );

    dispatch({
      type: TRADING_FOLLOW_USER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// follow a user
export const unFollowUser = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/trading/follow/unfollow/${formData.userId}`,
      formData,
      config
    );

    dispatch({
      type: TRADING_UNFOLLOW_USER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const updateAvatar = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  let formData2 = new FormData();

  formData2.append("document", formData);

  try {
    const res = await axios.post(`/api/document/avatar`, formData2, config);

    dispatch({
      type: UPDATE_AVATAR,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create a comment
export const createComment = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      "/api/trading/comment/create",
      formData,
      config
    );

    dispatch({
      type: TRADING_CREATE_COMMENT,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update a comment
export const updateComment = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      "/api/trading/comment/update",
      formData,
      config
    );

    dispatch({
      type: TRADING_UPDATE_COMMENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// delete a comment
export const deleteComment = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(
      "/api/trading/comment/remove",
      formData,
      config
    );

    dispatch({
      type: TRADING_DELETE_COMMENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all comments
export const getComments = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post("/api/trading/comment/get", formData, config);

    dispatch({
      type: TRADING_GET_COMMENTS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Post a like
export const likeComment = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post("/api/trading/comment/like", formData, config);

    dispatch({
      type: TRADING_LIKE_COMMENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Send Report User Trading System

export const reportUser = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post("/api/trading/report", formData, config);

    dispatch({
      type: REPORT_USER_TRADING,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: TRADING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Loading Button Publish

export const setLoadingButtonPublish = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_PUBLISH,
    payload: formData
  });
};

// Set Loading Button Download

export const setLoadingButtonDownload = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_DOWNLOAD,
    payload: formData
  });
};

// Set Loading Button Rating

export const setLoadingButtonRating = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_RATING,
    payload: formData
  });
};

// Set Loading Button Comments

export const setLoadingButtonComment = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_COMMENT,
    payload: formData
  });
};

// Set Loading Button Publish Edited

export const setLoadingButtonPublishEdited = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_PUBLISH_EDITED,
    payload: formData
  });
};

// Set Loading Button Report User Trading

export const setLoadingButtonReportTrading = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_REPORT_TRADING,
    payload: formData
  });
};

// Set Loading Button Follow

export const setLoadingButtonFollow = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_FOLLOW,
    payload: formData
  });
};

// clear published module

export const clearPublishedModule = () => async dispatch => {
  dispatch({
    type: CLEAR_PUBLISHED_MODULE
  });
};
