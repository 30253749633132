import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import SetPassword from "../auth/SetPassword";
import { useTranslation } from "react-i18next";

import {
  MDBInput,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCol,
  MDBModalFooter
} from "mdbreact";

const Login = ({ login, isAuthenticated, activateProp, history }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    passwordModal: false
  });

  const { t } = useTranslation();

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password, history);
  };

  // Redirect if logged in

  if (isAuthenticated) {
    if (activateProp) {
    } else {
      return <Redirect to="/dashboard" />;
    }
  }

  const toggleResetPassword = () => {
    setFormData({
      ...formData,
      passwordModal: true
    });
  };
  const toggleResetPasswordClose = () => {
    setFormData({
      ...formData,
      passwordModal: false
    });
  };

  var styles = {
    cursor: "pointer",
    fontSize: "12px"
  };

  return (
    <Fragment>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group loginForm">
          <MDBInput
            type="email"
            name="email"
            value={email}
            onChange={e => onChange(e)}
            required
            label="Your email"
            labelClass="deep-purple-text quickSand"
            icon="envelope"
            iconClass="deep-purple-text"
            className="deep-purple-text"
          />
        </div>
        <div className="form-group">
          <MDBInput
            type="password"
            name="password"
            value={password}
            onChange={e => onChange(e)}
            label="Your password"
            labelClass="deep-purple-text quickSand"
            icon="lock"
            iconClass="deep-purple-text"
            required
            className="deep-purple-text"
          />
        </div>
        <p
          className="deep-purple-text quickSand"
          style={styles}
          onClick={() => toggleResetPassword()}
        >
          Lost your password? Click here to reset it
        </p>
        <div className="text-center mt-3 black-text">
          <MDBBtn className="loginbtn" size="lg" type="submit" value="Login">
            Log in
          </MDBBtn>
          <p className="my-1 mt-2 quickSand" style={styles}>
            Don't have an account?{" "}
            <HashLink to="/#landing" className="deep-purple-text">
              Sign Up
            </HashLink>
          </p>
          <hr />
        </div>
      </form>

      <p className="my-1 mr-1 quickSand">
        {t("teacherLogin.1")}
        <HashLink to="/connect/login" className="deep-purple-text">
          {t("teacherLogin.2")}
        </HashLink>
      </p>
      <MDBContainer>
        <MDBModal
          isOpen={formData.passwordModal}
          toggle={toggleResetPasswordClose}
          size="sm"
        >
          <MDBModalHeader toggle={toggleResetPasswordClose}>
            Reset Password
          </MDBModalHeader>
          <MDBModalBody>
            <MDBCol md="12" className="text-center">
              <SetPassword />
            </MDBCol>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              size="sm"
              onClick={toggleResetPasswordClose}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  activateProp: PropTypes.string
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(withRouter(Login));
