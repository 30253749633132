import {
  GET_GAMES,
  GAMES_ERROR,
  GAMES_DELETE,
  ADD_GAMES,
  UPDATE_GAME,
  CLEAR_GAMES,
  SEARCH_GAMES,
  SET_PAGINATION_MODE_GAMES,
  CLEAR_GAMES_SEARCH,
  SET_LOADING_BUTTON_GAMES,
  SET_LOADING_DELETE_GAME,
  LOGOUT
} from "../actions/types";

const initialState = {
  games: [],
  game: "",
  loading: true,
  error: {},
  updated: false,
  updatedGame: "",
  gameDeleted: "",
  initialPageNumber: 1,
  initialNumberOfPages: 1,
  searchGames: [],
  paginationModeGames: "date",
  keywords: "",
  numberOfPages: 1,
  pageNumber: 1,
  loadingButtonGames: false,
  gamesLoading: true,
  loadingDeleteGame: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_GAMES:
      return {
        ...state,
        game: "",
        gameDeleted: "",
        updatedGame: ""
      };
    case CLEAR_GAMES_SEARCH:
      return {
        ...state,
        searchGames: "",
        keywords: "",
        numberOfPages: "",
        pageNumber: "",
        paginationModeGames: "date"
      };
    case GET_GAMES:
      return {
        ...state,
        games: payload.games,
        initialPageNumber: payload.initialPageNumber,
        initialNumberOfPages: payload.initialNumberOfPages,
        loading: false,
        gamesLoading: false
      };

    case UPDATE_GAME:
      const newArray = state.games.map((item, index) => {
        if (item._id === payload.game._id) {
          return payload.game;
        } else {
          return item;
        }
      });
      return {
        ...state,
        games: newArray,
        updatedGame: payload.game,
        loadingButtonGames: false
      };

    case ADD_GAMES:
      return {
        ...state,
        game: payload.game,
        games: [payload.game, ...state.games],
        loading: false,
        loadingButtonGames: false
      };
    case GAMES_DELETE:
      return {
        ...state,
        games: state.games.filter(games => games._id !== payload.game),
        gameDeleted: payload.game,
        loading: false,
        loadingDeleteGame: false
      };
    case GAMES_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonGames: false,
        loadingDeleteGame: false
      };
    case SEARCH_GAMES:
      return {
        ...state,
        searchGames: payload.searchGames,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        loading: false
      };
    case SET_PAGINATION_MODE_GAMES:
      return {
        ...state,
        paginationModeGames: payload
      };
    case SET_LOADING_BUTTON_GAMES:
      return {
        ...state,
        loadingButtonGames: payload
      };
    case SET_LOADING_DELETE_GAME:
      return {
        ...state,
        loadingDeleteGame: payload
      };
    case LOGOUT:
      return {
        games: [],
        game: "",
        loading: true,
        error: {},
        updated: false,
        updatedGame: "",
        gameDeleted: "",
        initialPageNumber: 1,
        initialNumberOfPages: 1,
        searchGames: [],
        paginationModeGames: "date",
        keywords: "",
        numberOfPages: 1,
        pageNumber: 1,
        loadingButtonGames: false,
        gamesLoading: true,
        loadingDeleteGame: false
      };
    default:
      return state;
  }
}
