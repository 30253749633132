import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./trading.css";
import { withRouter } from "react-router-dom";

import Rating from "react-rating";

import {
  MDBContainer,
  MDBCardHeader,
  MDBInput,
  MDBCard,
  MDBSelect,
  MDBBtn,
  MDBRow,
  MDBCol
} from "mdbreact";

import {
  filterLessons,
  filterTopics,
  filterExams,
  filterGames,
  filterDecks,
  filterCurriculums,
  getPublishedLessons,
  getPublishedTopics,
  getPublishedExams,
  getPublishedGames,
  getPublishedDecks,
  getPublishedCurriculums,
  clearFilterReducer,
  setPaginationMode
} from "../../actions/trading";

const FilterRight = ({
  mode,
  publishedLessons,
  publishedTopics,
  publishedExams,
  publishedGames,
  filterLessons,
  filterTopics,
  filterExams,
  filterGames,
  filterDecks,
  filterCurriculums,
  getPublishedLessons,
  getPublishedTopics,
  getPublishedExams,
  getPublishedGames,
  getPublishedDecks,
  getPublishedCurriculums,
  clearFilterReducer,
  setPaginationMode,
  trading: {
    keywords,
    paginationMode,
    filterPreferences,
    searchLessons,
    searchTopics,
    searchExams,
    searchGames,
    searchDecks,
    searchCurriculums
  }
}) => {
  const [formData, setFormData] = useState({
    levelOptions: [
      {
        text: "A1",
        value: "A1"
      },
      {
        text: "A2",
        value: "A2"
      },
      {
        text: "B1",
        value: "B1"
      },
      {
        text: "B2",
        value: "B2"
      },
      {
        text: "C1",
        value: "C1"
      },
      {
        text: "C2",
        value: "C2"
      },
      {
        text: "No level",
        value: "No level"
      }
    ],
    date: "descending",
    level: "",
    rating: "",
    pageNumber: 1,
    paginationMode: "date"
  });

  const setFilterLevel = value => {
    const find = formData.levelOptions.find(item => item.checked === true);

    setFormData({
      ...formData,
      level: find ? find.value : ""
    });
  };

  const setRating = value => {
    setFormData({ ...formData, rating: value });
  };

  const setFilterDate = () => {
    setFormData({
      ...formData,
      date: "ascending"
    });
  };

  const setFilterDate2 = () => {
    setFormData({
      ...formData,
      date: "descending"
    });
  };

  const filterLessonsFunc = () => {
    filterLessons({
      level: formData.level,
      date: formData.date,
      rating: formData.rating,
      keywords: keywords,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("filter");
  };

  const filterTopicsFunc = () => {
    filterTopics({
      level: formData.level,
      date: formData.date,
      rating: formData.rating,
      keywords: keywords,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("filter");
  };

  const filterExamsFunc = () => {
    filterExams({
      level: formData.level,
      date: formData.date,
      rating: formData.rating,
      keywords: keywords,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("filter");
  };

  const filterGamesFunc = () => {
    filterGames({
      level: formData.level,
      date: formData.date,
      rating: formData.rating,
      keywords: keywords,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("filter");
  };

  const filterDecksFunc = () => {
    filterDecks({
      level: formData.level,
      date: formData.date,
      rating: formData.rating,
      keywords: keywords,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("filter");
  };

  const filterCurriculumsFunc = () => {
    filterCurriculums({
      level: formData.level,
      date: formData.date,
      rating: formData.rating,
      keywords: keywords,
      pageNumber: formData.pageNumber
    });
    setPaginationMode("filter");
  };

  const filterModulesFunc = () => {
    if (mode === "lessons") {
      return filterLessonsFunc();
    } else if (mode === "topics") {
      return filterTopicsFunc();
    } else if (mode === "exams") {
      return filterExamsFunc();
    } else if (mode === "games") {
      return filterGamesFunc();
    } else if (mode === "flashcards") {
      return filterDecksFunc();
    } else if (mode === "series") {
      return filterCurriculumsFunc();
    }
  };

  const clearFilterFunc = () => {
    const newArray = formData.levelOptions.map((item, index) => {
      return {
        ...item,
        checked: false
      };
    });
    setFormData({
      ...formData,
      date: "ascending",
      rating: "",
      level: "",
      levelOptions: newArray
    });
    clearFilterReducer();
    if (
      searchLessons.length !== 0 ||
      searchTopics.length !== 0 ||
      searchExams.length !== 0 ||
      searchGames.length !== 0 ||
      searchDecks.length !== 0 ||
      searchCurriculums.length !== 0
    ) {
      setPaginationMode("search");
    } else {
      setPaginationMode("date");
    }
  };

  useEffect(() => {
    setFormData(prevState => {
      const newArray =
        filterPreferences.level === ""
          ? prevState.levelOptions.map((item, index) => {
              return {
                ...item,
                checked: false
              };
            })
          : prevState.levelOptions;
      return {
        ...prevState,
        date: filterPreferences.date,
        level: filterPreferences.level,
        rating: filterPreferences.rating,
        levelOptions: newArray
      };
    });
  }, [filterPreferences]);

  return (
    //Filters

    <Fragment>
      <MDBCard
        narrow
        style={{ height: "auto" }}
        className="mx-3 my-3 pr-2 pt-3 tradingBackgroundColor"
      >
        <MDBCol md="12">
          <MDBCardHeader className="view view-cascade tradingCard d-flex middleVertical">
            <p className="h5 quickSand mx-2 paragraphColor text-center">
              Filters
            </p>
          </MDBCardHeader>
          <MDBCard
            style={{ height: "auto" }}
            className="mx-2 my-2 borderFilter rounded-0 bg-transparent"
          >
            <MDBRow className="mx-3 my-2 middleVertical">
              <MDBCol md="12">
                <p className="quickSand h5 text-white middleVertical">Date</p>
              </MDBCol>
            </MDBRow>
          </MDBCard>
          <MDBContainer className="mt-2 row justify-content-start quickSand">
            <MDBRow center>
              <MDBCol>
                <MDBInput
                  label="Ascending"
                  type="radio"
                  id="radio1"
                  labelClass="text-white"
                  onClick={() => setFilterDate("ascending")}
                  checked={formData.date === "ascending" ? true : false}
                />
              </MDBCol>

              <MDBCol>
                <MDBInput
                  label="Descending"
                  type="radio"
                  id="radio2"
                  labelClass="text-white"
                  onClick={() => setFilterDate2("descending")}
                  checked={formData.date === "descending" ? true : false}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBCard
            style={{ height: "auto" }}
            className="mx-2 borderFilter rounded-0 bg-transparent"
          >
            <MDBRow className="mx-3 my-2 middleVertical">
              <MDBCol md="12">
                <p className="quickSand h5 text-white middleVertical">Level</p>
              </MDBCol>
            </MDBRow>
          </MDBCard>
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <MDBSelect
                  name="level"
                  options={formData.levelOptions}
                  getValue={setFilterLevel}
                  labelClass="white-text"
                  label={"Choose Level"}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBCard
            style={{ height: "auto" }}
            className="mx-2 borderFilter rounded-0 bg-transparent"
          >
            <MDBRow className="mx-3 my-2 middleVertical">
              <MDBCol md="12">
                <p className="quickSand h5 text-white middleVertical">Rating</p>
              </MDBCol>
            </MDBRow>
          </MDBCard>
          <MDBContainer>
            <MDBRow className="mt-2 mb-3" center>
              <Rating
                emptySymbol="far fa-star fa-2x"
                fullSymbol="fas fa-star fa-2x pinkColor "
                onClick={setRating}
                initialRating={formData.rating}
              />
            </MDBRow>
          </MDBContainer>
          <MDBRow center className="mx-3 my-2 ">
            <div>
              <MDBBtn
                size="md"
                className="lessonButton"
                rounded
                color="secondary"
                onClick={filterModulesFunc}
              >
                Filter
              </MDBBtn>
            </div>
            <div>
              <MDBBtn
                size="md"
                rounded
                className="flashCardButton"
                onClick={clearFilterFunc}
              >
                Clear
              </MDBBtn>
            </div>
          </MDBRow>
        </MDBCol>
      </MDBCard>
    </Fragment>
  );
};

FilterRight.propTypes = {};

const mapStateToProps = state => ({
  trading: state.trading
});

export default withRouter(
  connect(mapStateToProps, {
    filterLessons,
    filterTopics,
    filterExams,
    filterGames,
    filterDecks,
    filterCurriculums,
    getPublishedLessons,
    getPublishedTopics,
    getPublishedExams,
    getPublishedGames,
    getPublishedDecks,
    getPublishedCurriculums,
    clearFilterReducer,
    setPaginationMode
  })(FilterRight)
);
