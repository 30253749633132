import axios from "axios";
import { setAlert } from "./alert";
import ReactGa from "react-ga";
import ReactPixel from "react-facebook-pixel";

import {
  GET_CREDENTIALS,
  PAYMENT_ERROR,
  GET_LIST_INVOICES,
  GET_PAYMENT_CUSTOMER,
  GET_LIST_INVOICES_INDIVIDUAL,
  CONNECT_STRIPE_CHECKOUT,
  CONNECT_STRIPE_CHECKOUT_UPDATE,
  CONNECT_STRIPE_NEWINVOICE,
  CONNECT_STRIPE_INVOICE,
  CONNECT_STRIPE_PAYMENTINTENT,
  CONNECT_STRIPE_GETSTUDENTS,
  CONNECT_STRIPE_GET_SUBSCRIPTION,
  CONNECT_STRIPE_GET_SUBSCRIPTION_LIST,
  SEARCH_PAYMENTS,
  SET_PAGINATION_MODE_PAYMENTS,
  CLEAR_PAYMENTS_SEARCH,
  CONNECT_STRIPE_GET_TAXRATES,
  CONNECT_STRIPE_CREATE_TAXRATE,
  CONNECT_STRIPE_UPDATE_TAXRATE,
  CONNECT_STRIPE_GET_PLANS,
  CONNECT_STRIPE_CREATE_PLAN,
  CONNECT_STRIPE_DELETE_PLAN,
  CONNECT_STRIPE_PLANS_SELECT,
  PAYMENTS_LOADING_TRUE,
  CONNECT_STRIPE_TAXRATES_SELECT,
  CONNECT_STRIPE_CREATE_SUBSCRIPTION,
  GET_CONNECT_BALANCE,
  GET_CONNECT_BALANCE_TRANSACTIONS,
  GET_IP_COUNTRY,
  CONNECT_STRIPE_CHECKOUT_UPDATE_DEFAULT,
  DISCONNECT_STRIPE,
  PAYMENT_ERROR_DISCONNECT_STRIPE,
  SET_LOADING_BUTTON_SEND_INVOICE,
  SET_LOADING_BUTTON_CREATE_SUBSCRIPTION,
  SET_LOADING_BUTTON_ADD_TAXRATE,
  SET_LOADING_BUTTON_ADD_PLAN,
  SET_LOADING_REFUND,
  CLEAR_NEW_INVOICE_VAR
} from "./types";

// Get credentials

export const getCredentials = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/credentials", formData, config);

    dispatch({
      type: GET_CREDENTIALS,
      payload: res.data
    });

    if (history) {
      history.push("/payment");
    }

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get list of invoices
export const getListInvoices = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/listinvoices", formData, config);

    dispatch({
      type: GET_LIST_INVOICES,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    if (err.response.data.user) {
      dispatch({
        type: PAYMENT_ERROR_DISCONNECT_STRIPE,
        payload: {
          user: err.response.data.user
        }
      });
    } else {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "error")));
      }
      dispatch({
        type: PAYMENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};
// Get list of invoices inidivual customer
export const getListInvoicesIndividual = (
  formData,
  history
) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/listinvoicesIndividual",
      formData,
      config
    );

    dispatch({
      type: GET_LIST_INVOICES_INDIVIDUAL,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get list of invoices
export const getCustomer = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/individualCustomer",
      formData,
      config
    );

    dispatch({
      type: GET_PAYMENT_CUSTOMER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Checkout
export const checkout = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/checkout", formData, config);

    dispatch({
      type: CONNECT_STRIPE_CHECKOUT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Checkout UPDATE
export const checkoutupdate = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/checkoutupdate",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_CHECKOUT_UPDATE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Checkout UPDATE default payment for subscriptions and customer object
export const checkoutUpdateDefault = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/checkoutupdatedefault",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_CHECKOUT_UPDATE_DEFAULT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Send Invoice
export const sendInvoice = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/newinvoice", formData, config);

    dispatch({
      type: CONNECT_STRIPE_NEWINVOICE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// View individual Invoice
export const getInvoice = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/invoice", formData, config);

    dispatch({
      type: CONNECT_STRIPE_INVOICE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// finalize invoice
export const finalizeInvoice = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/invoice/finalize",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_INVOICE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// uncollectible individual Invoice
export const invoiceUncollectible = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/invoice/uncollectible",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_INVOICE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Void individual Invoice
export const invoiceVoid = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/invoice/void", formData, config);

    dispatch({
      type: CONNECT_STRIPE_INVOICE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// get paymentIntent
export const getPaymentIntent = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/paymentIntent",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_PAYMENTINTENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// get students
export const getStudents = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/students", formData, config);

    dispatch({
      type: CONNECT_STRIPE_GETSTUDENTS,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// refund
export const refund = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/refund", formData, config);

    dispatch({
      type: CONNECT_STRIPE_PAYMENTINTENT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// create new subscription
export const createSubscription = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/subscription/create",
      formData,
      config
    );

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }

    dispatch({
      type: CONNECT_STRIPE_CREATE_SUBSCRIPTION,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const searchPaymentsFunction = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post("/api/payment/search", formData, config);

    dispatch({
      type: SEARCH_PAYMENTS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// create new subscription
export const getSubscription = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/subscription/retrieve",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_GET_SUBSCRIPTION,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// create new subscription
export const getSubscriptionList = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/subscription/list",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_GET_SUBSCRIPTION_LIST,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// cancel subscription immediately
export const cancelSubscriptionImmediately = (
  formData,
  history
) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/subscription/cancelnow",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_GET_SUBSCRIPTION,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// cancel subscription end of period
export const cancelSubscriptionEnd = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/subscription/cancelend",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_GET_SUBSCRIPTION,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// list tax rates
export const getTaxRates = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/taxrates/list",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_GET_TAXRATES,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// list tax rates
export const getPlans = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/plans/list", formData, config);

    dispatch({
      type: CONNECT_STRIPE_GET_PLANS,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// list tax rates
export const createTaxRate = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/taxrates/create",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_CREATE_TAXRATE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// list tax rates
export const createPlan = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/plans/create", formData, config);

    dispatch({
      type: CONNECT_STRIPE_CREATE_PLAN,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// list tax rates
export const updateTaxRate = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/taxrates/update",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_UPDATE_TAXRATE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// list tax rates
export const deletePlan = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/plans/delete", formData, config);

    dispatch({
      type: CONNECT_STRIPE_DELETE_PLAN,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// list plans for select
export const getPlansSelect = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/plans/selectlist",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_PLANS_SELECT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// list tax rates for select
export const getTaxRatesSelect = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/taxRates/selectlist",
      formData,
      config
    );

    dispatch({
      type: CONNECT_STRIPE_TAXRATES_SELECT,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// add currency to customer
export const addCurrency = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/currency/add", formData, config);

    dispatch({
      type: GET_PAYMENT_CUSTOMER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// set language customer
export const setLanguage = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/stripe/language",
      formData,
      config
    );

    dispatch({
      type: GET_PAYMENT_CUSTOMER,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// get balance
export const getBalance = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/stripe/balance",
      formData,
      config
    );

    dispatch({
      type: GET_CONNECT_BALANCE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    console.log(err.response.data);
    if (err.response.data.user) {
    } else {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "error")));
      }
      dispatch({
        type: PAYMENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};
// get balance transactions
export const getBalanceTransactions = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/stripe/transactions",
      formData,
      config
    );

    dispatch({
      type: GET_CONNECT_BALANCE_TRANSACTIONS,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// verify country by ip
export const getIpCountry = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/payment/ipverify", formData, config);

    dispatch({
      type: GET_IP_COUNTRY,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// verify country by ip
export const checkoutSession = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/checkout/session",
      formData,
      config
    );

    ReactGa.event({
      category: "Subscription",
      action: "start subscription"
    });

    ReactPixel.init("472592427013249");
    ReactPixel.trackCustom("track", "Subscribe");

    if (history) {
      history.push("/dashboard");
    }

    // dispatch({
    //   type: GET_IP_COUNTRY,
    //   payload: res.data
    // });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// verify country by ip
export const disconnectStripe = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/payment/stripeconnect/disconnect",
      formData,
      config
    );

    dispatch({
      type: DISCONNECT_STRIPE,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Payments

export const setPaginationModePayments = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_PAYMENTS,
    payload: formData
  });
};

// Clear Group Search Results

export const clearPaymentsSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_PAYMENTS_SEARCH
  });
};

// loading === true

export const loadingTrue = () => async dispatch => {
  dispatch({
    type: PAYMENTS_LOADING_TRUE
  });
};

// Set Loading Button payments

export const setLoadingButtonSendInvoice = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_SEND_INVOICE,
    payload: formData
  });
};
export const setLoadingButtonCreateSubscription = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_CREATE_SUBSCRIPTION,
    payload: formData
  });
};
export const setLoadingButtonAddTaxrate = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_ADD_TAXRATE,
    payload: formData
  });
};
export const setLoadingButtonAddPlan = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_ADD_PLAN,
    payload: formData
  });
};
export const setLoadingRefund = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_REFUND,
    payload: formData
  });
};
export const clearNewInvoiceVar = formData => async dispatch => {
  dispatch({
    type: CLEAR_NEW_INVOICE_VAR,
    payload: formData
  });
};
