import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_LESSONS,
  GET_LESSONS_ALL,
  GET_LESSON,
  LESSONS_ERROR,
  LESSONS_DELETE,
  ADD_LESSONS,
  ADD_TOPIC,
  // REMOVE_TOPIC,
  GET_TOPICS,
  GET_TOPIC,
  TOPICS_DELETE,
  UPDATE_LESSON,
  UPDATE_TOPIC,
  DOCUMENT_DELETE,
  TOPIC_PUSH,
  CLEAR_LESSON,
  SEARCH_LESSONS,
  SET_PAGINATION_MODE_LESSONS,
  CLEAR_LESSONS_SEARCH,
  SEARCH_TOPICS,
  SET_PAGINATION_MODE_TOPICS,
  SET_LOADING_BUTTON_LESSONS,
  SET_LOADING_BUTTON_TOPICS,
  CLEAR_TOPICS_SEARCH,
  GET_CLASSES,
  LESSONS_CALENDAR,
  GET_LESSONS_PUSH,
  SET_LOADING_DELETE_LESSON,
  SET_LOADING_DELETE_TOPIC,
  CLEAR_DOCUMENT_DELETE
} from "./types";

// Get current lessons
export const getLessons = formData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(`/api/lessons/get`, formData, config);

    dispatch({
      type: GET_LESSONS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete lessons

export const deleteLesson = (id, history) => async dispatch => {
  try {
    const res = await axios.delete(`/api/lessons/${id}`);

    dispatch({
      type: LESSONS_DELETE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
    if (history) {
      history.push("/lessons");
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add lessons

export const addLessons = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/lessons", formData, config);

    dispatch({
      type: ADD_LESSONS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current lesson (NOT USED)
export const getLesson = id => async dispatch => {
  try {
    const res = await axios.get(`/api/lessons/${id}`);

    dispatch({
      type: GET_LESSON,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current topics
export const getTopics = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/topics/get", formData, config);

    dispatch({
      type: GET_TOPICS,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current Topic
export const getTopic = id => async dispatch => {
  try {
    const res = await axios.get(`/api/topics/${id}`);

    dispatch({
      type: GET_TOPIC,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete topics

export const deleteTopic = (id, history) => async dispatch => {
  try {
    const res = await axios.delete(`/api/topics/${id}`);

    dispatch({
      type: TOPICS_DELETE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
    if (history) {
      history.push("/topics");
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update lesson

export const updateLesson = (id, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(`/api/lessons/${id}`, formData, config);

    dispatch({
      type: UPDATE_LESSON,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Update topic

export const updateTopic = (id, formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  let formData2 = new FormData();

  formData2.append("documents", formData.documents);
  formData2.append("documents2", formData.documents2);
  formData2.append("documents3", formData.documents3);
  formData2.append("text", formData.text);
  formData2.append("title", formData.title);
  formData2.append("level", formData.level);
  formData2.append("vocabulary", JSON.stringify(formData.vocabulary));
  formData2.append("grammar", JSON.stringify(formData.grammar));
  formData2.append("youtube", JSON.stringify(formData.youtube));
  formData2.append("links", JSON.stringify(formData.links));
  formData2.append("topicId", JSON.stringify(formData.topicId));

  try {
    const res = await axios.post(`/api/topics/${id}`, formData2, config);

    dispatch({
      type: UPDATE_TOPIC,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add Topic (NOT WITHIN A LESSON)

export const addTopicNL = (formData, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  let formData2 = new FormData();

  formData2.append("documents", formData.documents);
  formData2.append("documents2", formData.documents2);
  formData2.append("documents3", formData.documents3);
  formData2.append("text", formData.text);
  formData2.append("lessonChosen", formData.lessonChosen);
  formData2.append("title", formData.title);
  formData2.append("level", formData.level);
  formData2.append("vocabulary", JSON.stringify(formData.vocabulary));
  formData2.append("grammar", JSON.stringify(formData.grammar));
  formData2.append("youtube", JSON.stringify(formData.youtube));
  formData2.append("links", JSON.stringify(formData.links));

  try {
    const res = await axios.post(`/api/topics`, formData2, config);

    dispatch({
      type: ADD_TOPIC,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
    if (history) {
      history.push("/topics");
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Remove Topic from Lesson
// NOT IMPLEMENT YET

export const removeTopicFromLessonAction = (
  lessonId,
  topicId
) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      `/api/lessons/removetopic/${lessonId}`,
      topicId,
      config
    );

    dispatch({
      type: GET_LESSONS,
      payload: res.data
    });

    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete document from topic

export const deleteDocument = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post(
      "/api/document/documentdelete",
      formData,
      config
    );

    dispatch({
      type: DOCUMENT_DELETE,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// PUSH a topic from one lesson to another

export const pushTopic = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post("/api/lessons/pushtopic", formData, config);

    dispatch({
      type: TOPIC_PUSH,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const clearLessonReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_LESSON
  });
};

// Post Search Results Lessons

export const searchLessonsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post(
      "/api/lessons/searchlessons",
      formData,
      config
    );

    dispatch({
      type: SEARCH_LESSONS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Lessons

export const setPaginationModeLessons = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_LESSONS,
    payload: formData
  });
};

// Clear Lesson Search Results

export const clearLessonsSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_LESSONS_SEARCH
  });
};

// Post Search Results Topics

export const searchTopicsFunction = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/topics/searchtopics", formData, config);

    dispatch({
      type: SEARCH_TOPICS,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Pagination Mode Topics

export const setPaginationModeTopics = formData => async dispatch => {
  dispatch({
    type: SET_PAGINATION_MODE_TOPICS,
    payload: formData
  });
};

// Get all the lessons for the calendar

export const getLessonsCalendar = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/lessons/calendar", formData, config);

    dispatch({
      type: LESSONS_CALENDAR,
      payload: res.data
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, "success"));
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Clear Topic Search Results

export const clearTopicsSearchReducer = () => async dispatch => {
  dispatch({
    type: CLEAR_TOPICS_SEARCH
  });
};

// Get all classes for the filtering

export const getClasses = () => async dispatch => {
  try {
    const res = await axios.get("/api/lessons/groups");

    dispatch({
      type: GET_CLASSES,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all lessons for the push topic to next class

export const getLessonsPush = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post("/api/lessons/push", formData, config);

    dispatch({
      type: GET_LESSONS_PUSH,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all lessons for the create topic and add to lesson

export const getLessonsAll = formData => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const res = await axios.post("/api/lessons/all", formData, config);

    dispatch({
      type: GET_LESSONS_ALL,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LESSONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Set Loading Button Lessons

export const setLoadingButtonLessons = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_LESSONS,
    payload: formData
  });
};

// Set Loading Button Topics

export const setLoadingButtonTopics = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_BUTTON_TOPICS,
    payload: formData
  });
};

export const setLoadingDeleteLesson = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_DELETE_LESSON,
    payload: formData
  });
};
export const setLoadingDeleteTopic = formData => async dispatch => {
  dispatch({
    type: SET_LOADING_DELETE_TOPIC,
    payload: formData
  });
};
export const clearDocumentDelete = () => async dispatch => {
  dispatch({
    type: CLEAR_DOCUMENT_DELETE
  });
};
