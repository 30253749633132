import {
  TRADING_GET_LESSONS,
  TRADING_GET_TOPICS,
  TRADING_GET_EXAMS,
  TRADING_GET_GAMES,
  TRADING_GET_DECKS,
  PUBLISH_LESSON_O,
  TRADING_GET_PUBLISHED_LESSONS,
  TRADING_GET_PUBLISHED_TOPICS,
  TRADING_GET_PUBLISHED_EXAMS,
  TRADING_GET_PUBLISHED_GAMES,
  TRADING_GET_PUBLISHED_DECKS,
  TRADING_GET_PUBLISHED_LESSON,
  PUBLISH_TOPIC_O,
  PUBLISH_EXAM_O,
  PUBLISH_GAME_O,
  PUBLISH_DECK_O,
  PUBLISH_CURRICULUM_O,
  TRADING_GET_PUBLISHED_TOPIC,
  TRADING_GET_PUBLISHED_EXAM,
  TRADING_GET_PUBLISHED_DECK,
  TRADING_GET_PUBLISHED_GAME,
  TRADING_LESSON_SEARCH,
  TRADING_TOPIC_SEARCH,
  TRADING_EXAM_SEARCH,
  TRADING_GAME_SEARCH,
  TRADING_DECK_SEARCH,
  TRADING_CURRICULUM_SEARCH,
  CLEAR_SEARCH,
  TRADING_LESSON_FILTER,
  TRADING_TOPIC_FILTER,
  TRADING_EXAM_FILTER,
  TRADING_GAME_FILTER,
  TRADING_DECK_FILTER,
  TRADING_CURRICULUM_FILTER,
  SET_PAGINATION_MODE,
  TOPIC_RATING,
  LESSON_RATING,
  DECK_RATING,
  EXAM_RATING,
  GAME_RATING,
  DOWNLOAD_TOPIC,
  DOWNLOAD_LESSON,
  DOWNLOAD_DECK,
  DOWNLOAD_EXAM,
  DOWNLOAD_GAME,
  GET_MAIN,
  GET_USER_MODULES,
  CLEAR_FILTER,
  TRADING_TOP_USERS,
  TRADING_GET_PUBLICPROFILE,
  TRADING_GET_FOLLOW,
  TRADING_FOLLOW_USER,
  TRADING_UNFOLLOW_USER,
  TRADING_GET_EDITED_LESSONS_USER,
  TRADING_GET_EDITED_TOPICS_USER,
  TRADING_GET_EDITED_EXAMS_USER,
  TRADING_GET_EDITED_GAMES_USER,
  TRADING_GET_EDITED_DECKS_USER,
  TRADING_GET_PUBLISHED_DECKS_EDITED,
  TRADING_GET_PUBLISHED_LESSONS_EDITED,
  TRADING_GET_PUBLISHED_TOPICS_EDITED,
  TRADING_GET_PUBLISHED_EXAMS_EDITED,
  TRADING_GET_PUBLISHED_GAMES_EDITED,
  TRADING_GET_COMMENTS,
  TRADING_CREATE_COMMENT,
  TRADING_UPDATE_COMMENT,
  TRADING_DELETE_COMMENT,
  TRADING_LIKE_COMMENT,
  TRADING_GET_FOLLOW_PUBLIC,
  TRADING_GET_CURRICULUMS,
  TRADING_GET_PUBLISHED_CURRICULUMS,
  TRADING_GET_PUBLISHED_CURRICULUM,
  DOWNLOAD_CURRICULUM,
  CURRICULUM_RATING,
  SET_LOADING_BUTTON_PUBLISH,
  SET_LOADING_BUTTON_DOWNLOAD,
  SET_LOADING_BUTTON_RATING,
  SET_LOADING_BUTTON_COMMENT,
  SET_LOADING_BUTTON_PUBLISH_EDITED,
  SET_LOADING_BUTTON_REPORT_TRADING,
  SET_LOADING_BUTTON_FOLLOW,
  REPORT_USER_TRADING,
  TRADING_ERROR,
  CLEAR_PUBLISHED_MODULE,
  LOGOUT
} from "../actions/types";

const initialState = {
  lessons: [],
  editedLessons: [],
  topics: [],
  editedTopics: [],
  exams: [],
  editedExams: [],
  games: [],
  editedGames: [],
  decks: [],
  editedDecks: [],
  curriculums: [],
  publishedLessons: [],
  publishedTopics: [],
  publishedExams: [],
  publishedGames: [],
  publishedDecks: [],
  publishedCurriculums: [],
  publishedLesson: "",
  publishedTopic: "",
  publishedExam: "",
  publishedGame: "",
  publishedDeck: "",
  publishedCurriculum: {
    title: "",
    description: "",
    userTutor: {
      avatar: {
        url: ""
      },
      screenName: ""
    },
    rating: [],
    curriculumList: [],
    type: "",
    messageBoard: [],
    level: "",
    date: ""
  },
  publishedLessonEdits: [],
  publishedTopicEdits: [],
  publishedExamEdits: [],
  publishedGameEdits: [],
  publishedDeckEdits: [],
  lesson: "",
  topic: "",
  exam: "",
  game: "",
  deck: "",
  curriculum: "",
  searchLessons: [],
  searchTopics: [],
  searchExams: [],
  searchGames: [],
  searchDecks: [],
  searchCurriculums: [],
  keywords: "",
  lessonsFilter: [],
  topicsFilter: [],
  examsFilter: [],
  gamesFilter: [],
  decksFilter: [],
  curriculumsFilter: [],
  publicProfile: {
    score: "",
    name: "",
    modules: [],
    screenName: "",
    description: "",
    avatar: {
      url: ""
    }
  },
  main: {
    topic: {
      title: "",
      date: "",
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      }
    },
    lesson: {
      title: "",
      date: "",
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      }
    },
    exam: {
      title: "",
      date: "",
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      }
    },
    deck: {
      title: "",
      date: "",
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      }
    },
    game: {
      title: "",
      date: "",
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      }
    },
    curriculum: {
      title: "",
      date: "",
      userTutor: {
        screenName: "",
        avatar: {
          url: ""
        }
      }
    }
  },
  userModules: [],
  currentMonthScore: {
    topUsersMonthly: []
  },
  previousMonthScore: {
    topUsersMonthly: []
  },

  follow: {
    following: [],
    followed: []
  },
  followPublic: {
    following: [],
    followed: []
  },
  initialNumberOfPages: 1,
  initialPageNumber: 1,
  numberOfPages: 1,
  pageNumber: 1,
  paginationMode: "date",
  comments: [],
  filterPreferences: {
    date: "descending",
    level: "",
    rating: ""
  },
  error: {},
  loadingButtonPublish: false,
  loadingButtonDownload: false,
  loadingButtonRating: false,
  loadingButtonComment: false,
  loadingButtonPublishEdited: false,
  loadingButtonFollow: false,
  loadingButtonReportTrading: false,
  loadingGetMain: true
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_SEARCH:
      return {
        ...state,
        searchLessons: "",
        searchTopics: "",
        searchExams: "",
        searchGames: "",
        searchDecks: "",
        searchCurriculums: "",
        keywords: "",
        numberOfPages: "",
        pageNumber: "",
        paginationMode: "date"
      };
    case CLEAR_FILTER:
      return {
        ...state,
        lessonsFilter: [],
        topicsFilter: [],
        examsFilter: [],
        gamesFilter: [],
        decksFilter: [],
        curriculumsFilter: [],
        paginationMode: "date",
        filterPreferences: { date: "descending", level: "", rating: "" }
      };
    case TRADING_GET_LESSONS:
      return {
        ...state,
        lessons: payload
      };
    case TRADING_GET_EDITED_LESSONS_USER:
      return {
        ...state,
        editedLessons: payload
      };
    case TRADING_GET_TOPICS:
      return {
        ...state,
        topics: payload
      };
    case TRADING_GET_EDITED_TOPICS_USER:
      return {
        ...state,
        editedTopics: payload
      };
    case TRADING_GET_EXAMS:
      return {
        ...state,
        exams: payload
      };
    case TRADING_GET_EDITED_EXAMS_USER:
      return {
        ...state,
        editedExams: payload
      };
    case TRADING_GET_GAMES:
      return {
        ...state,
        games: payload
      };
    case TRADING_GET_EDITED_GAMES_USER:
      return {
        ...state,
        editedGames: payload
      };
    case TRADING_GET_DECKS:
      return {
        ...state,
        decks: payload
      };
    case TRADING_GET_CURRICULUMS:
      return {
        ...state,
        curriculums: payload
      };
    case TRADING_GET_EDITED_DECKS_USER:
      return {
        ...state,
        editedDecks: payload
      };
    case PUBLISH_LESSON_O:
      return {
        ...state,
        lesson: payload.lesson,
        loadingButtonPublish: false,
        loadingButtonPublishEdited: false
      };
    case PUBLISH_TOPIC_O:
      return {
        ...state,
        topic: payload.topic,
        loadingButtonPublish: false,
        loadingButtonPublishEdited: false
      };
    case PUBLISH_EXAM_O:
      return {
        ...state,
        exam: payload.exam,
        loadingButtonPublish: false,
        loadingButtonPublishEdited: false
      };
    case PUBLISH_GAME_O:
      return {
        ...state,
        game: payload.game,
        loadingButtonPublish: false,
        loadingButtonPublishEdited: false
      };
    case PUBLISH_DECK_O:
      return {
        ...state,
        deck: payload.deck,
        loadingButtonPublish: false,
        loadingButtonPublishEdited: false
      };
    case PUBLISH_CURRICULUM_O:
      return {
        ...state,
        curriculum: payload.curriculum,
        loadingButtonPublish: false,
        loadingButtonPublishEdited: false
      };

    // case TRADING_LESSON_FILTER:
    case TRADING_GET_PUBLISHED_LESSONS:
      return {
        ...state,
        publishedLessons: payload.lessons,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber
      };
    case TRADING_GET_PUBLISHED_TOPICS:
      return {
        ...state,
        publishedTopics: payload.topics,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber
      };
    case TRADING_GET_PUBLISHED_EXAMS:
      return {
        ...state,
        publishedExams: payload.exams,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber
      };
    case TRADING_GET_PUBLISHED_GAMES:
      return {
        ...state,
        publishedGames: payload.games,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber
      };
    case TRADING_GET_PUBLISHED_DECKS:
      return {
        ...state,
        publishedDecks: payload.decks,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber
      };
    case TRADING_GET_PUBLISHED_CURRICULUMS:
      return {
        ...state,
        publishedCurriculums: payload.curriculums,
        initialNumberOfPages: payload.initialNumberOfPages,
        initialPageNumber: payload.initialPageNumber
      };
    case TRADING_GET_PUBLISHED_LESSON:
      return {
        ...state,
        publishedLesson: payload
      };
    case TRADING_GET_PUBLISHED_CURRICULUM:
      return {
        ...state,
        publishedCurriculum: payload
      };
    case TRADING_GET_PUBLISHED_TOPIC:
      return {
        ...state,
        publishedTopic: payload
      };
    case TRADING_GET_PUBLISHED_EXAM:
      return {
        ...state,
        publishedExam: payload
      };
    case TRADING_GET_PUBLISHED_GAME:
      return {
        ...state,
        publishedGame: payload
      };
    case TRADING_GET_PUBLISHED_DECK:
      return {
        ...state,
        publishedDeck: payload
      };
    case TRADING_LESSON_SEARCH:
      return {
        ...state,
        searchLessons: payload.searchLessons,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages
      };
    case TRADING_TOPIC_SEARCH:
      return {
        ...state,
        searchTopics: payload.searchTopics,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages
      };
    case TRADING_EXAM_SEARCH:
      return {
        ...state,
        searchExams: payload.searchExams,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages
      };
    case TRADING_GAME_SEARCH:
      return {
        ...state,
        searchGames: payload.searchGames,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages
      };
    case TRADING_DECK_SEARCH:
      return {
        ...state,
        searchDecks: payload.searchDecks,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages
      };
    case TRADING_CURRICULUM_SEARCH:
      return {
        ...state,
        searchCurriculums: payload.searchCurriculums,
        keywords: payload.keywords,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages
      };
    case SET_PAGINATION_MODE:
      return {
        ...state,
        paginationMode: payload
      };
    case TRADING_LESSON_FILTER:
      return {
        ...state,
        lessonsFilter: payload.lessonsFilter,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        filterPreferences: payload.filterPreferences
      };
    case TRADING_TOPIC_FILTER:
      return {
        ...state,
        topicsFilter: payload.topicsFilter,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        filterPreferences: payload.filterPreferences
      };
    case TRADING_EXAM_FILTER:
      return {
        ...state,
        examsFilter: payload.examsFilter,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        filterPreferences: payload.filterPreferences
      };
    case TRADING_GAME_FILTER:
      return {
        ...state,
        gamesFilter: payload.gamesFilter,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        filterPreferences: payload.filterPreferences
      };
    case TRADING_DECK_FILTER:
      return {
        ...state,
        decksFilter: payload.decksFilter,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        filterPreferences: payload.filterPreferences
      };
    case TRADING_CURRICULUM_FILTER:
      return {
        ...state,
        curriculumsFilter: payload.curriculumsFilter,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages,
        filterPreferences: payload.filterPreferences
      };
    case TOPIC_RATING:
      return {
        ...state,
        publishedTopic: {
          ...state.publishedTopic,
          rating: payload.rating
        }
      };
    case CURRICULUM_RATING:
      return {
        ...state,
        publishedCurriculum: {
          ...state.publishedCurriculum,
          rating: payload.rating
        },
        loadingButtonRating: false
      };
    case LESSON_RATING:
      return {
        ...state,
        publishedLesson: {
          ...state.publishedLesson,
          rating: payload.rating
        },
        loadingButtonRating: false
      };
    case EXAM_RATING:
      return {
        ...state,
        publishedExam: {
          ...state.publishedExam,
          rating: payload.rating
        },
        loadingButtonRating: false
      };
    case GAME_RATING:
      return {
        ...state,
        publishedGame: {
          ...state.publishedGame,
          rating: payload.rating
        },
        loadingButtonRating: false
      };
    case DECK_RATING:
      return {
        ...state,
        publishedDeck: {
          ...state.publishedDeck,
          rating: payload.rating
        },
        loadingButtonRating: false
      };
    case DOWNLOAD_TOPIC:
      return {
        ...state,
        publishedTopic: {
          ...state.publishedTopic,
          downloads: payload.downloads
        },
        loadingButtonDownload: false
      };
    case DOWNLOAD_LESSON:
      return {
        ...state,
        publishedLesson: {
          ...state.publishedLesson,
          downloads: payload.downloads
        },
        loadingButtonDownload: false
      };
    case DOWNLOAD_EXAM:
      return {
        ...state,
        publishedExam: {
          ...state.publishedExam,
          downloads: payload.downloads
        },
        loadingButtonDownload: false
      };
    case DOWNLOAD_GAME:
      return {
        ...state,
        publishedGame: {
          ...state.publishedGame,
          downloads: payload.downloads
        },
        loadingButtonDownload: false
      };
    case DOWNLOAD_DECK:
      return {
        ...state,
        publishedDeck: {
          ...state.publishedDeck,
          downloads: payload.downloads
        },
        loadingButtonDownload: false
      };
    case DOWNLOAD_CURRICULUM:
      return {
        ...state,
        publishedCurriculum: {
          ...state.publishedCurriculum,
          downloads: payload.downloads
        },
        loadingButtonDownload: false
      };
    case GET_MAIN:
      return {
        ...state,
        main: payload,
        loadingGetMain: false
      };
    case GET_USER_MODULES:
      return {
        ...state,
        userModules: payload.userModules,
        pageNumber: payload.pageNumber,
        numberOfPages: payload.numberOfPages
      };
    case TRADING_TOP_USERS:
      return {
        ...state,
        currentMonthScore: payload.currentMonthScore,
        previousMonthScore: payload.previousMonthScore
      };
    case TRADING_GET_PUBLICPROFILE:
      return {
        ...state,
        publicProfile: payload
      };
    case TRADING_GET_FOLLOW:
      return {
        ...state,
        follow: payload
      };
    case TRADING_GET_FOLLOW_PUBLIC:
      return {
        ...state,
        followPublic: payload
      };
    case TRADING_FOLLOW_USER:
      return {
        ...state,
        follow: payload.follow,
        loadingButtonFollow: false
      };
    case TRADING_UNFOLLOW_USER:
      return {
        ...state,
        follow: payload.follow,
        loadingButtonFollow: false
      };
    case TRADING_GET_PUBLISHED_DECKS_EDITED:
      return {
        ...state,
        publishedDeckEdits: payload
      };
    case TRADING_GET_PUBLISHED_LESSONS_EDITED:
      return {
        ...state,
        publishedLessonEdits: payload
      };
    case TRADING_GET_PUBLISHED_TOPICS_EDITED:
      return {
        ...state,
        publishedTopicEdits: payload
      };
    case TRADING_GET_PUBLISHED_EXAMS_EDITED:
      return {
        ...state,
        publishedExamEdits: payload
      };
    case TRADING_GET_PUBLISHED_GAMES_EDITED:
      return {
        ...state,
        publishedGameEdits: payload
      };
    case TRADING_CREATE_COMMENT:
      return {
        ...state,
        comments: [payload.comment, ...state.comments],
        loadingButtonComment: false
      };
    case TRADING_UPDATE_COMMENT:
      const newArray = state.comments.map((item, index) => {
        if (item._id === payload.comment._id) {
          return payload.comment;
        } else return item;
      });
      return {
        ...state,
        comments: newArray
      };
    case TRADING_DELETE_COMMENT:
      const filter = state.comments.filter(
        item => item._id !== payload.comment._id
      );
      return {
        ...state,
        comments: filter
      };

    case TRADING_GET_COMMENTS:
      return {
        ...state,
        comments: payload
      };
    case TRADING_LIKE_COMMENT:
      const newArray2 = state.comments.map((item, index) => {
        if (item._id === payload.comment._id) {
          return payload.comment;
        } else return item;
      });
      return {
        ...state,
        comments: newArray2
      };
    case SET_LOADING_BUTTON_PUBLISH:
      return {
        ...state,
        loadingButtonPublish: payload
      };
    case SET_LOADING_BUTTON_DOWNLOAD:
      return {
        ...state,
        loadingButtonDownload: payload
      };
    case SET_LOADING_BUTTON_RATING:
      return {
        ...state,
        loadingButtonRating: payload
      };
    case SET_LOADING_BUTTON_COMMENT:
      return {
        ...state,
        loadingButtonComment: payload
      };
    case SET_LOADING_BUTTON_PUBLISH_EDITED:
      return {
        ...state,
        loadingButtonPublishEdited: payload
      };
    case SET_LOADING_BUTTON_FOLLOW:
      return {
        ...state,
        loadingButtonFollow: payload
      };
    case SET_LOADING_BUTTON_REPORT_TRADING:
      return {
        ...state,
        loadingButtonReportTrading: payload
      };
    case REPORT_USER_TRADING:
      return {
        ...state,
        report: payload,
        loadingButtonReportTrading: false
      };
    case TRADING_ERROR:
      return {
        ...state,
        error: payload,
        loadingButtonDownload: false,
        loadingButtonComment: false,
        loadingButtonPublish: false,
        loadingButtonPublishEdited: false,
        loadingButtonReportTrading: false
      };
    case CLEAR_PUBLISHED_MODULE:
      return {
        ...state,
        lesson: "",
        topic: "",
        exam: "",
        game: "",
        deck: "",
        curriculum: ""
      };
    case LOGOUT:
      return {
        lessons: [],
        editedLessons: [],
        topics: [],
        editedTopics: [],
        exams: [],
        editedExams: [],
        games: [],
        editedGames: [],
        decks: [],
        editedDecks: [],
        curriculums: [],
        publishedLessons: [],
        publishedTopics: [],
        publishedExams: [],
        publishedGames: [],
        publishedDecks: [],
        publishedCurriculums: [],
        publishedLesson: "",
        publishedTopic: "",
        publishedExam: "",
        publishedGame: "",
        publishedDeck: "",
        publishedCurriculum: {
          title: "",
          description: "",
          userTutor: {
            avatar: {
              url: ""
            },
            screenName: ""
          },
          rating: [],
          curriculumList: [],
          type: "",
          messageBoard: [],
          level: "",
          date: ""
        },
        publishedLessonEdits: [],
        publishedTopicEdits: [],
        publishedExamEdits: [],
        publishedGameEdits: [],
        publishedDeckEdits: [],
        lesson: "",
        topic: "",
        exam: "",
        game: "",
        deck: "",
        curriculum: "",
        searchLessons: [],
        searchTopics: [],
        searchExams: [],
        searchGames: [],
        searchDecks: [],
        searchCurriculums: [],
        keywords: "",
        lessonsFilter: [],
        topicsFilter: [],
        examsFilter: [],
        gamesFilter: [],
        decksFilter: [],
        curriculumsFilter: [],
        publicProfile: {
          score: "",
          name: "",
          modules: [],
          screenName: "",
          description: "",
          avatar: {
            url: ""
          }
        },
        main: {
          topic: {
            title: "",
            date: "",
            userTutor: {
              screenName: "",
              avatar: {
                url: ""
              }
            }
          },
          lesson: {
            title: "",
            date: "",
            userTutor: {
              screenName: "",
              avatar: {
                url: ""
              }
            }
          },
          exam: {
            title: "",
            date: "",
            userTutor: {
              screenName: "",
              avatar: {
                url: ""
              }
            }
          },
          deck: {
            title: "",
            date: "",
            userTutor: {
              screenName: "",
              avatar: {
                url: ""
              }
            }
          },
          game: {
            title: "",
            date: "",
            userTutor: {
              screenName: "",
              avatar: {
                url: ""
              }
            }
          },
          curriculum: {
            title: "",
            date: "",
            userTutor: {
              screenName: "",
              avatar: {
                url: ""
              }
            }
          }
        },
        userModules: [],
        currentMonthScore: {
          topUsersMonthly: []
        },
        previousMonthScore: {
          topUsersMonthly: []
        },

        follow: {
          following: [],
          followed: []
        },
        followPublic: {
          following: [],
          followed: []
        },
        initialNumberOfPages: 1,
        initialPageNumber: 1,
        numberOfPages: 1,
        pageNumber: 1,
        paginationMode: "date",
        comments: [],
        filterPreferences: {
          date: "descending",
          level: "",
          rating: ""
        },
        error: {},
        loadingButtonPublish: false,
        loadingButtonDownload: false,
        loadingButtonRating: false,
        loadingButtonComment: false,
        loadingButtonPublishEdited: false,
        loadingButtonFollow: false,
        loadingButtonReportTrading: false,
        loadingGetMain: true
      };
    default:
      return state;
  }
}
