import React from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const PrivateRouteStudent = ({
  component: Component,
  students: { isAuthenticated, loadingAuth },
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated && !loadingAuth ? (
          <Redirect to="/connect/login" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
PrivateRouteStudent.propTypes = {
  students: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  students: state.students
});

export default withRouter(connect(mapStateToProps)(PrivateRouteStudent));
